common = require('../../common')
EditorChoice = require('../../editor_choice')
GetWriting = require('../../get_writing')
Title = require('../../titles/title')
Writing = require('../../writing')

module.exports = class DetailsTools extends React.Component
  displayName: 'DetailsTools'

  constructor: (props) ->
      super(props)
      @state = {
          mode: 'reading'
          projEditor: @props.project.editor
          motive: @props.project.motive
          title: @props.project.title
      }
      @titleWritingRef = React.createRef()
      @motiveWritingRef = React.createRef()
      @editorChoiceRef = React.createRef()

  toggleReadEditMode: =>
      if (@state.mode is 'editing')
          @updateProjectFields()
      else
          @setState({mode: 'editing'})

  updateProjectFields: =>
      data =
          projectid: @props.project.id
          project_motive: @motiveWritingRef.current.getValue()
          project_title: @titleWritingRef.current.getValue()
          editor: @editorChoiceRef.current.getEditor()

      common.submitProjectFields(data, (result) =>
          # FIXME: This should be gotten from result, not data.
          @setState({
              mode: 'reading'
              motive: data.project_motive
              title: data.project_title
              editor: data.editor
          })
      )

  updateTitle: (title) =>
      @setState({title: title})

  updateMotive: (motive) =>
      @setState({motive: motive})

  render: =>
      if @state.mode is 'editing'
          BigPicture =
              <div>
                <h3>Short description: </h3>
                <div className='proj_motive_CKEDitor'>
                  <span>
                    <GetWriting ref={@motiveWritingRef} key={@props.project.user_id}
                      defaultValue={@state.motive} rows='2' />
                  </span>
                </div>
              </div>
          EditorChoiceRow =
              <div className='row no-gutters'>
                <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  Editor:
                </div>
                <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                  <EditorChoice ref={@editorChoiceRef} editor={@state.projEditor} />
                </div>
              </div>
          TitleRow =
              <div className='proj_titleDiv'>
                <GetWriting ref={@titleWritingRef} key={@props.project.id}
                  defaultValue={@state.title} rows='2' />
              </div>
          SaveOrEditButton =
                <div className="proj_save_conference_div">
                  <button className="btn btn-secondary btn-txt-sm" onClick={@updateProjectFields}>Save
                  </button>
                </div>
      else
          # mode is 'reading'
          BigPicture =
              <div>
                <h3>Short description: </h3>
                <div className='proj_motive_CKEDitor'>
                  <span>
                    <Writing editor={@state.projEditor} writing={@state.motive} />
                  </span>
                </div>
              </div>
          TitleRow =
              <div className='text-left mb-1 pb-1 mt-1 pt-1'>
                <h2 className='big_em'>
                  <Title title={@state.title} editor={@state.projEditor}/>
                </h2>
              </div>
          SaveOrEditButton =
                <div className="proj_save_conference_div">
                  <button className="btn btn-secondary btn-txt-sm" onClick={@toggleReadEditMode}>Edit
                  </button>
                </div>

      <div className='pt-3 pb-3'>
          {EditorChoiceRow}
          {TitleRow}
          {BigPicture}
          {SaveOrEditButton}
      </div>
