common = require('./common')
Link = require('react-router-dom').Link
Title = require('./titles/title')

module.exports = class HeaderNotificationRoll extends React.Component
  displayName: 'HeaderNotificationRoll'

  constructor: (props) ->
      super(props)
      if @props.caller is 'bootstrap_dropdown'
          # Bootstrap dropdown items are really small for some reason.
          style = 'dropdown-item'
      else
          # we are in the page-view, not dropdown
          style = ''
      @state = {
          data: { logged_in_user: 'anonymous', verified: 'false', email: '' }
          logged_in_user: @props.logged_in_user
          line_style: style
      }

  render: =>

      if @props.notification.seen_at > 0
          check =
              <span>&#10004;</span>
      else
          check =
              <span>New: </span>

      sender_button =
          <Link to={ '/profile/' + @props.notification.sender_user_id }>
              {@props.notification.sender_username}&nbsp;
          </Link>

      if @props.notification.ptype is 'conference'
          project_link =
              <Link to={ '/conference/' + @props.notification.project_id + '/titles/' }>
                  <Title title={@props.notification.title}
                                editor={@props.notification.editor}/>
              </Link>
      else if @props.notification.ptype is 'lab group'
          project_link =
              <Link to={ '/lab_group/' + @props.notification.project_id + '/titles/' }>
                  <Title title={@props.notification.title}
                                editor={@props.notification.editor}/>
              </Link>
      else if @props.notification.ptype is 'project'
          project_link =
              <Link to={ '/project/' + @props.notification.project_id + '/titles/' }>
                  <Title title={@props.notification.title}
                                editor={@props.notification.editor}/>
              </Link>
      else if @props.notification.ptype is 'seminar'
          project_link =
              <Link to={ '/seminar/' + @props.notification.project_id + '/titles/' }>
                  <Title title={@props.notification.title}
                                editor={@props.notification.editor}/>
              </Link>
      else
          project_link =
              <Link to={ '/short_course/' + @props.notification.project_id + '/titles/' }>
                  <Title title={@props.notification.title}
                                editor={@props.notification.editor}/>
              </Link>


      if @props.notification.notification_type is 'role_invitation'
          if @props.notification.role is 'following'
              msg = (
                <span>
                  { check }
                  You are following&nbsp;{@props.notification.ptype}&nbsp;{ project_link }
                </span>
              )
          else if @props.notification.role is 'contributor'
              msg = (
                <span>
                { check }
                  { sender_button }
                  submitted a title you are coauthor on to &nbsp;
                  { project_link }
                </span>
              )
          else if @props.notification.role is 'invited'
              msg = (
                <span>
                  { check }
                  { sender_button }
                  invited you to attend a conference.
                  To confirm visit:&nbsp;
                  { project_link }
                </span>
              )
          else if @props.notification.role is 'bookmark'
              msg = (
                <span>
                  { check }
                  You bookmarked&nbsp;
                  { project_link }
                </span>
              )
          else
              msg = (
                <span>
                  { check }
                  { sender_button }
                  invited you to be a {@props.notification.ptype} {@props.notification.role}!
                  To confirm visit:&nbsp;
                  { project_link }
                </span>
              )
      else if @props.notification.notification_type is 'review_assigned'
          msg = (
            <span>
               { check }
               { sender_button }
               has assigned you to review a {@props.notification.ptype} paper.
               Visit:
               { project_link }
            </span>
          )
      else if @props.notification.notification_type is 'paper_assigned'
          msg = (
            <span>
               { check }
               { sender_button }
               has assigned a new paper to your {@props.notification.ptype} group.
               Visit:
               { project_link }
            </span>
          )
      else if @props.notification.notification_type is 'registered'
          msg = (
            <span>
              { check }
              Did you make a badge? You are registered for:
              { project_link }
            </span>
          )

      <div>
          <a className={@state.line_style}>{ msg }</a>
      </div>
