common = require('../common')
ShowDate = require('./show_date')
ClockRoll = require('./clock_roll')

module.exports = class SetDate extends React.Component
  displayName: 'SetDate'

  constructor: (props) ->
      super(props)
      # props are: datetime, duration
      @state = {
        show_deadline_setter: 'false'
        day: ''
        month: ''
        year: ''
        hour: ''
        minute: ''
        duration: ''
        error_msg: ''
        datetime: ''
        gmt_offset_txt: 'Choose'
        gmt_offset: ''
      }
      @theYear = React.createRef()
      @theMonth = React.createRef()
      @theDay = React.createRef()
      @theHour = React.createRef()
      @theMinute = React.createRef()
      @theDuration = React.createRef()

  formatDateTime: =>

      ms = Number(@props.datetime * 1000)

      datetime = new Date(ms)
      minute = datetime.getMinutes().toString()
      if minute.toString().length == 1
          minute = '0' + minute


      hour = datetime.getHours()
      if @props.gmt_offset
          browser_offset_mins = datetime.getTimezoneOffset()
          total_offset = browser_offset_mins + @props.gmt_offset
          hour = hour + total_offset/60
      hour = hour.toString()
      hour = '0' + hour if hour.length < 2


      time = hour + ':' + minute
      day = datetime.getDate().toString()
      if day.toString().length == 1
          day = '0' + day

      month = Number(datetime.getMonth().toString())+1
      if month.toString().length == 1
          month = '0' + month

      year = datetime.getFullYear().toString()
      date = year + '-' + month + '-' + day
      @setState({
          datetime: datetime
          year: year
          month: month
          day: day
          hour: hour
          minute: minute
          date: date
          time: time })
      return

  setTimeZone: (gmt_offset) =>

      if (gmt_offset)
          gmt_offset = gmt_offset.toString()
          switch (gmt_offset)
              when ('-5')
                  @setState({ gmt_offset_txt: 'EST (GMT-5)', gmt_offset: -5 })
              when '-6'
                  @setState({ gmt_offset_txt: 'CST (GMT-6)', gmt_offset: -6 })
              when '-7'
                  @setState({ gmt_offset_txt: 'MST (GMT-7)', gmt_offset: -7 })
              when '-8'
                  @setState({ gmt_offset_txt: 'PST (GMT-8)', gmt_offset: -8 })
              when '-9'
                  @setState({ gmt_offset_txt: 'AST (GMT-9)', gmt_offset: -9 })
              when '-10'
                  @setState({ gmt_offset_txt: 'HAST (GMT-10)', gmt_offset: -10 })

  componentDidMount: =>
      datetime = @props.datetime
      duration = @props.duration
      gmt_offset = 'Choose'
      if @props.gmt_offset
          gmt_offset = @props.gmt_offset
      @setState({
          datetime: datetime
          duration: duration
          gmt_offset: gmt_offset })
      if @props.datetime > 0
            @setState({ show_deadline_setter: 'false' })
      @setTimeZone(@props.gmt_offset/60)

  componentDidUpdate: (prevProps) =>
      if @props != prevProps
          datetime = @props.datetime
          duration = @props.duration
          gmt_offset = 'Choose'
          if @props.gmt_offset
              gmt_offset = @props.gmt_offset
          @setState({
              datetime: datetime
              duration: duration
              gmt_offset: gmt_offset })
          if @props.datetime > 0
                @setState({ show_deadline_setter: 'false' })
          else
              @setState({ show_deadline_setter: 'true' })
          @setTimeZone(@props.gmt_offset/60)


  turnOnDateSetter: =>
      if !(@state.year)
          @formatDateTime()
      @setState({ show_deadline_setter: 'true' })

  cancelEdit: =>
      @setState({ show_deadline_setter: 'false' })

  goToSaveManualDate: =>
      # date = @refs.date.value.split("-")
      # year = Math.floor(Number(date[0]))
      # month = Math.floor(Number(date[1]))-1
      # day = Math.floor(Number(date[2]))
      #
      # time = @refs.time.value.split(":")
      # hour = Math.floor(Number(time[0]))
      # minute = Math.floor(Number((time[1])))

      year = @theYear.current.value
      month = @theMonth.current.value
      month = @theMonth.current.value-1
      day = @theDay.current.value

      hour = @theHour.current.value
      minute = @theMinute.current.value

      # This is for when we based schedule off of user's local times
      # my_location = new Date()
      # UTC_offset = my_location.getTimezoneOffset()  # offset in mins

      #year = Math.floor(Number(@refs.year.value))
      #month = Math.floor(Number(@refs.month.value))-1
      #day = Math.floor(Number(@refs.day.value))
      #hour = Math.floor(Number(@refs.hour.value))
      #minute = Math.floor(Number(@refs.minute.value))
      duration = Math.floor(Number(@theDuration.current.value))

      @setState({
          year: year
          month: month
          day: day
          hour: hour
          minute: minute
          duration: duration
      })

      if (isNaN(day)) or (isNaN(month)) or (isNaN(year)) or (isNaN(hour))
          @setState({ error_msg: ' only numbers may be entered' })
          return
      if (day < 1) or (month < 0) or (year < 0) or (hour < 0)
          @setState({ error_msg: ' numbers must be positive!' })
          return
      if (day > 31)
          @setState({ error_msg: ' day too large' })
          return
      if (month > 11)
          @setState({ error_msg: ' month too large' })
          return
      if (hour > 23)
          @setState({ error_msg: ' hour too large' })
          return
      if (@state.gmt_offset is 'Choose')
          @setState({ error_msg: ' choose a timezone for the schedule' })
          return

      # note: standard UTC_offset given in minutes, so keep consistent
      # for offset here, which is given in hours
      data =
          minute: minute
          hour: hour
          day: day
          month: month
          year: year
          UTC_offset: @state.gmt_offset*60
          duration: duration

      @props.saveTime(data)

  goToSaveClock: (thisclock)=>

      data =
          clock_id: thisclock.id

      @props.saveTime(data)

  timeButtons: () =>
      if (@props.clocks)
          <div className='row no-gutters'>
              <span className='mr-1'>Choose a time slot:</span>
              <span>
                  {@props.clocks.map((clock) =>
                      <ClockRoll key={ clock.id }
                                 clock={ clock }
                                 onClick={ @goToSaveClock.bind(this,clock) } />
                  )}
              </span>
          </div>


  render: =>

      if @state.show_deadline_setter is 'true'
          <div className='container-fluid row no-gutters border border-danger border_rad_tenpx m-1 p-3'>

              <div>
                  Choose a time
              </div>

              <div className='row no-gutters'>
                  { @timeButtons() }
              </div>

              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  <label>Date (YYYY/MM/DD):&nbsp;&nbsp;</label>
                  <input ref={@theYear}
                         defaultValue={ @state.year }
                         className="medium_noborders_input_textbox"></input>
                  /
                  <input ref={@theMonth}
                         defaultValue={ @state.month }
                         className="small_noborders_input_textbox"></input>
                  /
                  <input ref={@theDay}
                         defaultValue={ @state.day }
                         className="small_noborders_input_textbox"></input>
              </div>

              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  <label>24-hour Time (HH/MM):&nbsp;&nbsp;</label>
                  <input ref={@theHour}
                         defaultValue={ @state.hour }
                         className="small_noborders_input_textbox"></input>
                  /
                  <input ref={@theMinute}
                         defaultValue={ @state.minute }
                         className="small_noborders_input_textbox"></input>
              </div>

              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  <label>Duration (mins):&nbsp;&nbsp;</label>
                  <input className='small_noborders_input_textbox'
                         type='text'
                         ref={@theDuration}
                         defaultValue={ @state.duration }></input>
              </div>

              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  <div className='row no-gutters'>
                  Schedule timezone:
                  <div className="dropdown ml-1">
                      <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          { @state.gmt_offset_txt }
                      </button>
                      <div className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton">
                          <a className="dropdown-item" type='button'
                              onClick={@setTimeZone.bind(this, '-5')}>
                              EST New York City (GMT-5)</a>
                          <a className="dropdown-item" type='button'
                              onClick={@setTimeZone.bind(this, '-6')}>
                              CST Chicago (GMT-6)</a>
                          <a className="dropdown-item" type='button'
                              onClick={@setTimeZone.bind(this, '-7')}>
                              MST Denver (GMT-7)</a>
                          <a className="dropdown-item" type='button'
                              onClick={@setTimeZone.bind(this, '-7')}>
                              MST Phoenix (GMT-7)</a>
                          <a className="dropdown-item" type='button'
                              onClick={@setTimeZone.bind(this, '-8')}>
                              PST Los Angeles (GMT-8)</a>
                          <a className="dropdown-item" type='button'
                              onClick={@setTimeZone.bind(this, '-9')}>
                              AST Anchorage (GMT-9)</a>
                          <a className="dropdown-item" type='button'
                              onClick={@setTimeZone.bind(this, '-10')}>
                              HAST Honolulu (GMT-10)</a>
                      </div>
                  </div>
                  </div>
              </div>

              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  <button className="btn btn-primary"
                          onClick={@goToSaveManualDate}>
                          save</button>
                  <button className="btn btn-primary"
                          onClick={@cancelEdit}>
                          cancel</button>
                  <span className='text-danger'>
                      { @state.error_msg }
                  </span>
              </div>

          </div>
      else
          <div className='container-fluid row no-gutters'>
              <span>
                  <ShowDate
                      datetime={@props.datetime}
                      duration={@props.duration}
                      timezone={@props.timezone}
                      gmt_offset={@props.gmt_offset} />
                  <button className="btn btn-primary ml-2"
                          onClick={@turnOnDateSetter}>
                          Edit</button>
              </span>
          </div>
