Link = require('react-router-dom').Link
common = require('../common')
ReviewerAddBox = require('./reviewer_add_box')
AreaChairSelectBox = require('./areachair_select_box')

module.exports = class AssignReviewersToAreaChairs extends React.Component
  displayName: 'AssignReviewersToAreaChairs'

  constructor: (props) ->
      super(props)
      @state = {
        mode : 'show assign button'
        reviewer_rjs : [ ]
        areachair_rjs : [ ]
        chosen_areachairid : ''
        chosen_areachair : ''
        reviewerlist : [ ]
        local_areachairs : [ ]
        local_jobs : [ ]
        return_message : ""
      }

  collapseToAssignButton: () =>
      @setState({ mode : 'show assign button' })

  goToShowAreaChairsAndReviewers: () =>

      data =
          projectid : @props.project.id

      common.getAreaChairsReviewersJobs(data, (result) =>
          @setState({ areachair_rjs : result['areachair_rjs'] })
          @setState({ reviewer_rjs : result['reviewer_rjs'] })
          @setState({ local_areachairs : result['areachair_rjs']})
          @setState({ local_jobs : result['job_js'] })
          @setState({ mode : 'viewing' })
      )

  componentDidMount: () =>
      # hi

  uniquifyReviewers: (revs) =>
      unique_revs = [ ]

      unique_ids = [ ]
      for rev in revs
        index = unique_ids.indexOf(rev.user_id)
        if index < 0
            unique_ids.push(rev.user_id)

      for rev in revs
          pos = unique_ids.indexOf(rev.user_id)
          if pos > -1
              unique_ids.splice(pos, 1)
              unique_revs.push(rev)

      return unique_revs


  assignReviewersToAreaChair: () =>
      if @state.reviewerlist.length > 0
          local_areachairs = @state.local_areachairs
          for local_areachair in local_areachairs
              if local_areachair.id is @state.chosen_areachairid
                for reviewer in @state.reviewerlist
                    local_areachair.reviewer_rolejunctions.push(reviewer)
                local_areachair.reviewers = @uniquifyReviewers(local_areachair.reviewer_rolejunctions)

          @setState({ local_areachairs : local_areachairs })

          reviewerids = [ ]
          for reviewer in @state.reviewerlist
              reviewerids.push(reviewer.id)

          data =
              chosen_areachairid : @state.chosen_areachairid
              reviewerids : reviewerids
              projectid : @props.project.id

          common.assignReviewersToAreaChair(data, (result) =>
              @setState({ chosen_areachairid : '' })
              @setState({ reviewerlist : [ ] })
              @setState({ chosen_areachair : '' })
          )

  chosenReviewer: (reviewer_rj) =>
      reviewerlist = @state.reviewerlist

      found = 0
      if @state.chosen_areachair.reviewer_rolejunctions.length > 0
          for rev in @state.chosen_areachair.reviewer_rolejunctions
              if reviewer_rj.user_id is rev.user_id
                  found = 1

      if found is 0
          reviewerlist.push(reviewer_rj)
          @setState({ reviewerlist : reviewerlist })

  chosenAreaChair: (areachair_rj) =>
      if areachair_rj.id != @state.chosen_areachairid
          # this means a new areachair was selected
          @setState({ chosen_areachairid : areachair_rj.id })
          @setState({ chosen_areachair : areachair_rj })
      else
          # this means the "save" btn was clicked on same areachair
          @assignReviewersToAreaChair()


  goToAutoAssignReviewersToAreaChairs: =>
      data =
          projectid : @props.project.id

      common.autoAssignReviewersToAreaChairs(data, (result) =>
          @setState({ return_message : result.res.return_message })
      )


  render: =>
      if @state.reviewer_rjs.length > 0
          reviewer_map =
              <div className="review_names_infoDiv">
                  reviewers:
                  <div className="review_Div">
                      {@state.reviewer_rjs.map((reviewer_rj) =>
                          <ReviewerAddBox
                              key={reviewer_rj.id}
                              reviewer={reviewer_rj}
                              reviewerlist={@state.reviewerlist}
                              onClick={@chosenReviewer.bind(this,reviewer_rj)} />
                      ,this)}
                  </div>
              </div>
      else
          reviewer_map =
            <div className="review_names_infoDiv">
              Invite some reviewers....
            </div>

      if @state.areachair_rjs.length > 0
          areachair_map =
              <div className="review_note_infoDiv">
                  area chairs:
                  {@state.areachair_rjs.map((areachair_rj) =>
                      <ConferenceAreaChairSelectBox
                          key={areachair_rj.id}
                          areachair={areachair_rj}
                          chosen_areachairid={@state.chosen_areachairid}
                          reviewers={@state.reviewerlist}
                          notes={@props.notes}
                          onClick={@chosenAreaChair.bind(this,areachair_rj)} />
                  ,this)}
              </div>
      else
          areachair_map =
            <div className='review_note_infoDiv'>
              Invite some area chairs....
            </div>

      if @state.mode is 'show assign button'
          <div>
              Assign reviewers to area chairs:&nbsp;
              <button className="btn btn-primary"
                                          onClick={@goToAutoAssignReviewersToAreaChairs}>
                                          Auto-assign</button>
              { @state.return_message }
          </div>
      else if @state.mode is 'viewing'
          <div>
              <button className="btn btn-secondary"
              onClick={@collapseToAssignButton}>all done</button>
              <br />
              { areachair_map }
              { reviewer_map }
          </div>
