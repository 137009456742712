Link = require('react-router-dom').Link
MakeFormattedPerms = require('./conference_tools/make_formatted_perms')
NameIDRoll = require('./name_id_roll')
ShowDate = require('./schedule/show_date')
SocialSharing = require('./social/social_sharing')
TagNoteRoll = require('./tags/tag_note_roll')
Title = require('./titles/title')
Writing = require('./writing')

module.exports = class ConferenceHeader extends React.Component
  displayName: 'ConferenceHeader'

  constructor: (props) ->
    super(props)

    mode = 'partial'
    if @props.mode
        mode = @props.mode

    @state = {
      mode: mode
      shareUrl: ''
      header_title: ''
      ptype: ''
    }

  setInfo: () =>
      if @props.project.ptype
          if @props.project.ptype is 'personal'
              ptype = 'project'
          shareUrl = 'https://zerodivzero.com/' + ptype + '/' + @props.project.id + '/titles'

          header_title = @props.project.ptype.split('_')
          capitalized_title = []
          for word in header_title
              word = word.charAt(0).toUpperCase() + word.substring(1)
              capitalized_title.push(word)
          capitalized_title = capitalized_title.join(' ')

          @setState({
              shareUrl: shareUrl
              header_title: capitalized_title
              ptype: ptype })


  componentDidMount: () =>
      @setInfo()

  componentDidUpdate: (prevProps) =>
      if @props.project != prevProps.project
          @setInfo()

  setViewMode: (mode) ->
      @setState({mode: mode})

  tagsListRow: () =>
    if (@props)
        if (@props.project.tags) and (@props.project.tags.length>0)
            Ltags = @props.project.tags.length
            <div className='row m-2'>
                <strong className='pointnine_em mr-2'>Sessions:</strong>
                {@props.project.tags.map((tag, ctr) =>
                    if ctr+1 < Ltags
                        comma=<span>&nbsp;|</span>
                    <TagNoteRoll key={tag.id}
                        tag={tag}
                        projectid={@props.project.id}
                        note_type='conference'
                        tag_type='conference'
                        comma={comma} />
                )}
            </div>

  titleRow: () =>
      if @props
          if @props.project.verified is 'true'
              verified_row =
                  <span className='text-success'>
                    &nbsp;&nbsp;
                    <i className="fa fa-check-circle"></i> verified
                  </span>
          else if @props.project.verified is 'false'
              verified_row =
                  <span className="text-danger">
                      &nbsp;&nbsp;
                      <i className="fa fa-times-circle"></i> not verified
                  </span>

        <div className='row m-1'>
            <span className='mt-2 mb-2 font-txt-lg-darkgrey'>
                <Title title={@props.project.title}
                    editor={@props.project.editor}
                    link={'/' + @props.project.ptype + '/' + @props.project.id + '/titles'} />
                <SocialSharing shareUrl={@state.shareUrl}
                    title={ @props.project.title }/>
                <span className='pointsixfive_em'>{verified_row}</span>
            </span>
        </div>

  createdByRow: () =>
      if @props

          if (@props.project.chairs) and (@props.project.chairs.length > 0)
              Lchairs = @props.project.chairs.length
              chairs_info =
                  <span>
                      {@props.project.chairs.map((chair, ctr) =>
                          if ctr+1 < Lchairs
                              comma=<span>,</span>
                          <NameIDRoll key={chair.user_id}
                              name={chair.user_name}
                              id={chair.user_id}
                              comma={comma} />
                      ,this)}
                  </span>
          else
              chairs_info = <span className='ml-1'>none</span>

          <div className='row m-1'>
            <strong className='pointnine_em mr-2'>Created by:</strong>
            <Link to={'/profile/' + @props.project.user_id}>
                {@props.project.user_name}
            </Link>
            &nbsp;&nbsp;
            <strong className='pointnine_em mr-2'>Chair(s):</strong>
            {chairs_info}
          </div>

  bigPicture: () =>
      if @props
            <div className='row m-2'>
              <strong className='pointnine_em mr-2'>Short description:</strong>
              <div className='proj_motive_CKEDitor'>
                  <Writing key='big_picture'
                      editor={@props.project.editor}
                      writing={@props.project.motive} />
              </div>
            </div>

  permsRow: () =>
      if @props
          <div className='row m-2'>
            <strong className='pointnine_em mr-2'>Permissions:</strong>
            <div className="">
                <MakeFormattedPerms perms={@props.project.perms} />
            </div>
          </div>

  allTheDates: () =>
      if @props
          <span>
            <div className='row m-2'>
                <strong className='pointnine_em mr-2'>Important Dates</strong>
            </div>
            <div className='row ml-2 mr-2'>
                <strong className='pointnine_em mr-2'>Submission:</strong>
                <ShowDate key='submission_deadline' datetime={@props.project.submission_deadline} />
                ,&nbsp;
                <strong className='pointnine_em mr-2'>Review:</strong>
                <ShowDate key='review_deadline' datetime={@props.project.review_deadline} />
            </div>
            <div className='row ml-2 mr-2'>
                <strong className='pointnine_em mr-2'>Registration:</strong>
                <ShowDate key='registration_deadline' datetime={@props.project.registration_deadline} />
                ,&nbsp;
                <strong className='pointnine_em mr-2'>Start date:</strong>
                <ShowDate key='start_date' datetime={@props.project.start_date} />
                ,&nbsp;
                <strong className='pointnine_em mr-2'>End date:</strong>
                <ShowDate key='end_date' datetime={@props.project.end_date} />
            </div>
          </span>

  linkToAbout: () =>
      <span className='btn-txt-sm'>
          (or visit the&nbsp;
          <Link to={'/' + @props.project.ptype + '/' + @props.project.id + '/about'}>
              <button className="btn btn-link mt-0 mb-0 pt-1 pb-1 mr-0 pr-1">
                  About
              </button>
          </Link><span className='ml-0'>page for more)</span>
      </span>


  render: =>

    if (@state.mode is 'full')
        <div className='container-fluid light_gray'>
              <br />
              <strong>{@state.header_title}</strong>
              { @titleRow() }
              { @createdByRow() }
              { @tagsListRow() }
              { @bigPicture() }
              { @allTheDates() }
              { @permsRow() }
              <button className="btn btn-link btn-txt-sm"
                  onClick={@setViewMode.bind(this, 'partial')}>
                  minimize
              </button>
              { @linkToAbout() }
              <br />
              <br />
        </div>
    else if @state.mode is 'partial'
        <div className='container-fluid light_gray'>
          <br />
          <strong>{@state.header_title}</strong>
          { @titleRow() }
          { @createdByRow() }
          { @tagsListRow() }
          <button className="btn btn-link ml-2"
              onClick={@setViewMode.bind(this, 'full')}>
              show more details...
          </button>
          <br />
          <br />
        </div>
      else if @state.mode is 'mini'
            <div className='container-fluid light_gray'>
              <br />
              <strong>{@state.header_title}</strong>
              { @titleRow() }
              { @createdByRow() }
              <br />
            </div>
