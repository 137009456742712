
module.exports = class ToolchestConfDetailsButton extends React.Component
  displayName: 'ToolchestConfDetailsButton'

  constructor: (props) ->
      super(props)
      # Expects: @props.
      # my_accepted_roles
      @state = { }

  render: =>

      if @props.tool_selected is 'details_tools'
          card_className = 'card light_green'
      else
          card_className = 'card light_gray'

      if ('creator' in @props.my_accepted_roles) or ('chair' in @props.my_accepted_roles)
          <div className={card_className}>
            <div className="card-body">
                <h2 className="card-title">
                    <button className='btn btn-primary'
                            onClick={ @props.onClick.bind(this, {tools: 'details_tools', show_this: 'details_tools'}) }>
                        details
                    </button>
                </h2>
                <p className="card-text">
                    Title, short description
                </p>
            </div>
          </div>
      else
          <div className={card_className}>
            <div className="card-body">
                <h2 className="card-title">
                    <button className='btn btn-primary'
                            onClick={ @props.onClick.bind(this, {tools: 'roles_error', show_this: 'false'}) }>
                        details
                    </button>
                </h2>
                <p className="card-text">
                    Title, short description
                </p>
            </div>
          </div>
