PageButton = require('./page_button')

module.exports = class Pagination extends React.Component
  displayName: 'Pagination'

  constructor: (props) ->
      super(props)
      @state = {
        page: ''
        page_btns: []
      }

  componentDidMount: =>
      page_btns = @state.page_btns

      if @props.lengthy+1 > 0
        for n in [1 ... @props.lengthy+1]
            page_btns.push({number: n})

      @setState({ page_btns: page_btns })

  componentDidUpdate: (prevProps) =>
      if @props.lengthy != prevProps.lengthy
        page_btns = []

        if @props.lengthy+1 > 0
          for n in [1 ... @props.lengthy+1]
              page_btns.push({number: n})

        @setState({ page_btns: page_btns })

  sendPage: (val) =>
      if val is 'previous'
          val = @props.page_num - 1
      if val is 'next'
          val = @props.page_num + 1
      @props.onClick(val)

  render: =>
      paging = @props.page_num

      if @props.page_num == 1
          previous_a =
              <li className="page-item disabled">
                  <a className="page-link">
                     <span className="text-muted">Previous</span></a>
              </li>
      else
          previous_a =
              <li className="page-item">
                  <a className="page-link"
                     onClick={@sendPage.bind(this,"previous")}>
                     <span className="text-primary">Previous</span></a>
              </li>

        if (@props.page_num >= @props.lengthy)
            next_a =
                <li className="page-item disabled">
                    <a className="page-link">
                       <span className="text-muted">Next</span></a>
                </li>
        else
            next_a =
                <li className="page-item">
                    <a className="page-link"
                       onClick={@sendPage.bind(this,"next")}>
                       <span className="text-primary">Next</span></a>
                </li>


      <nav aria-label="Page navigation">
        <ul className="pagination justify-content-center flex-wrap">
            { previous_a }
            {@state.page_btns.map( (page_btn) =>
              <PageButton key={page_btn.number} number={page_btn.number}
                          page_num={@props.page_num}
                          onClick={@sendPage.bind(this, page_btn.number)}/>
            , this)}
            { next_a }
        </ul>
      </nav>
