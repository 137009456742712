Link = require('react-router-dom').Link
common = require('./common')

module.exports = class AreaChairNameRollEditable extends React.Component
  displayName: 'AreaChairNameRollEditable'
  constructor: (props) ->
    super(props)
    @state = {
      areachair: @props.areachair
      mode: 'display name'
    }

  gotoUserProfile: () =>
      window.location = '/profile/' + this.props.areachair.user_id

  componentWillReceiveProps: (nextProps) =>
      if nextProps.areachair.name is 'Removed!'
        @setState({ mode : 'remove name' })

  render: =>
      if @state.mode is 'remove name'
          <div className='authornameroll_div'>Removed!</div>
      else if @props.areachair.user_id?
        <div className="btn-group"
             role="group"
             aria-label="Basic example">
          <button className="btn btn-primary btn-txt-sm">{@props.areachair.name}</button>
          <button className="btn btn-secondary btn-txt-sm"
                  onClick={@props.onClick}>
                  &nbsp;x&nbsp;</button>
        </div>
      else
          <div className="btn-group"
               role="group"
               aria-label="delete areachair">
            <button className="btn btn-secondary btn-txt-sm"
                    onClick={@gotoUserProfile}>{@props.areachair.name}</button>
            <button className="btn btn-danger btn-txt-sm"
                    onClick={@props.onClick}>
                    &nbsp;x&nbsp;</button>
          </div>
