common = require('../common')
Loading = require('../loading')

module.exports = class Emai extends React.Component
  displayName: 'Email Verification'

  constructor: (props) ->
      super(props)
      # expecting as props:
      # verified
      # email
      # type
      # if type='authorcontact' then also: authorcontactid
      # current_userid
      # userid (of user accessing the page)
      @state = {
        email_msg: ''
        check_msg: ''
        loading: 'false'
      }

  askToSendEmailCode: () =>
      @setState({loading: 'true'})

      if @props.type == 'user_account'
          data =
              userid: @props.userid

          common.emailVerificationCode(data, (result) =>
              @setState({loading: 'false'})
              if result is 'no_error'
                  @setState({email_msg: 'Email sent! If you do not see it soon, check your spam folder.'})
              else
                  @setState({email_msg: 'Email sent! If you do not see it soon, check your spam folder.'})
           )

      else if @props.type == 'authorcontact'
          data =
              email : @props.email
              authorcontactid: @props.authorcontactid

          common.emailAuthorContactVerificationCode(data, (result) =>
              @setState({loading: 'false'})
              if result.error
                  @setState({email_msg: result.error})
              else
                  @setState({email_msg: 'Email sent! If you do not see it soon, check your spam folder.'})
           )

  checkCode: () =>
      @setState({loading: 'true'})

      verif_code = @refs.verification_code.value
      verif_code = verif_code.replace(/\s/g, '') # remove whitespace

      if verif_code.length == 0
          @setState({check_msg: 'you must enter a code'})
          return

      if @props.type == 'user_account'
          data =
              verif_code: verif_code
              userid: @props.userid

      else if @props.type == 'authorcontact'
          data =
              verif_code: verif_code
              userid: @props.userid
              authorcontactid: @props.authorcontactid

      common.confirmEmailedCodeForUserAccount(data, (result) =>
          @setState({loading: 'false'})
          if result.error
              @setState({check_msg: result.error})
          else
              @setState({check_msg: 'Email confirmed!'})
              if @props.verifiedSubmitCreds?
                  @props.verifiedSubmitCreds()
      )

  loadingWheel: () =>
      if @state.loading is 'true'
          return(
              <span><Loading /></span>
          )

  render: =>

    if (@props.verified == 'false') and (@props.current_userid == @props.userid)
           <span>
             <div className='row no-gutters'>
               <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                 <span className="text-danger">
                       Verify your email:</span>
                 <button className='btn btn-primary btn-txt-sm'
                      onClick={@askToSendEmailCode}>
                      Click to send code to {@props.email}
                  </button>
                  { @loadingWheel() }
               </div>
             </div>
             <div className='row no-gutters'>
               <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                 {@state.email_msg}
               </div>
             </div>
             <div className='row no-gutters'>
               <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                 Then paste the code:
                 <input className='verif_code_input_textbox'
                        type="text"
                        ref="verification_code"></input>
                 <button className='btn btn-success btn-txt-sm' onClick={@checkCode}>
                         verify</button>
                 <span className="text-danger">{@state.check_msg}</span>
               </div>
             </div>
           </span>
    else if (@props.verified == 'false')
             <div className='row no-gutters'>
               <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                 <span className="text-danger">
                       This email has not been verified!</span>
               </div>
             </div>
    else
       <span></span>
