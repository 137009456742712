Link = require('react-router-dom').Link
common = require('./common')

module.exports = class NavGeneral extends React.Component
  displayName: 'NavGeneral'

  constructor: (props) ->
    super(props)
    # props include:
    # id, ptype, project, caller, subset, getThisSortType,userid, styling
    @state = {ptype: ''}

  componentDidMount: () =>
      ptype = @props.ptype
      if @props.ptype is 'personal'
          ptype = 'project'
      title_detail_page = 'false'
      if window.location.pathname.indexOf('/title/') > -1
          title_detail_page = 'true'
      @setState({ ptype: ptype, title_detail_page: title_detail_page })

  componentDidUpdate: (prevProps) =>
      if prevProps.ptype != @props.ptype
          ptype = @props.ptype
          if @props.ptype is 'personal'
              ptype = 'project'
          title_detail_page = 'false'
          if window.location.pathname.indexOf('/title/') > -1
              title_detail_page = 'true'
          @setState({ ptype: ptype, title_detail_page: title_detail_page })

  setSortTypeAllTitles: () =>
      if @state.title_detail_page is 'true'
          # called by an individual title
          window.location = '/' + @state.ptype + '/' + @props.id + '/titles'
      else if (@props.caller is 'titles')
        @props.getThisSortType('all')
      else
        # only conference_notes and conference_notes_program have this fn
        # passed as a prop, so need to load from there
        window.location = '/' + @state.ptype + '/' + @props.id + '/titles'

  setSortTypeAllProgramTitles: () =>
      if (@props.caller is 'program')
        @props.getThisSortType('scheduled')
      else
        # only conference_notes and conference_notes_program have this fn
        # passed as a prop, so need to load from there
        window.location = '/' + @state.ptype + '/' + @props.id + '/program'

  setSortTypeUserSub: () =>
      @props.getThisSortType('user_submissions')

  setSortTypeUserBookMs: () =>
      if window.location.pathname.indexOf('/title/') > -1
          # called by an individual title
          window.location = '/' + @state.ptype + '/' + @props.id + '/titles'
      else
          @props.getThisSortType('user_bookmarks')

  render: =>
      if @props.caller is 'titles'
          titles_btn = 'nav-item-button-strong'
      else
          titles_btn = 'nav-item-button'

      if @props.caller is 'program'
          program_btn = 'nav-item-button-strong'
      else
          program_btn = 'nav-item-button'

      if @props.caller is 'my_submissions'
          mysubmissions_btn = 'nav-item-button-strong'
      else
          mysubmissions_btn = 'nav-item-button'

      if @props.caller is 'my_bookmarks'
          mybookmarks_btn = 'nav-item-button-strong'
      else
          mybookmarks_btn = 'nav-item-button'

      if @props.caller is 'about'
          about_btn = 'nav-item-button-strong'
      else
          about_btn = 'nav-item-button'

      if @props.caller is 'tools'
          tools_btn = 'nav-item-button-strong'
      else
          tools_btn = 'nav-item-button'

      if @props.caller is 'register'
          register_btn = 'nav-item-button-strong'
      else
          register_btn = 'nav-item-button'

      if (@props.note) # a single title detailed view, just display a "back" btn
          if @props.caller is 'one_title'
              back_button =
                  <div className={@props.styling}>
                      <Link to={'/' + @state.ptype + '/' + @props.id + '/titles'}>
                          <button className={titles_btn}>back to titles</button>
                      </Link>
                  </div>
          else
              # @props.caller is 'one_program_title'
              back_button =
                  <div className={@props.styling}>
                      <Link to={'/' + @state.ptype + '/' + @props.id + '/program'}>
                          <button className={titles_btn}>back to program</button>
                      </Link>
                  </div>
      else
          program_titles =
              <div className={@props.styling}>
                  <Link to={'/' + @state.ptype + '/'  + @props.id + '/program'}>
                      <button className={program_btn}
                          onClick={@setSortTypeAllProgramTitles}>program</button>
                  </Link>
              </div>
          tools_button =
              <div className={@props.styling}>
                  <Link to={'/' + @state.ptype + '/' + @props.id + '/tools/'}>
                      <button className={tools_btn}>tools</button>
                  </Link>
              </div>
          about_button =
              <div className={@props.styling}>
                  <Link to={'/' + @state.ptype + '/'  + @props.id + '/about/'}>
                      <button className={about_btn}>about</button>
                  </Link>
              </div>
          register_button =
              <div className={@props.styling}>
                  <Link to={'/' + @state.ptype + '/'  + @props.id + '/register/'}>
                      <button className={register_btn}>register</button>
                  </Link>
              </div>

      if @props.subset is 'all'
          all_btn =
              <div className={@props.styling}>
                  <button className='nav-item-button'>
                      <strong>all titles</strong>
                  </button>
              </div>
      else
          all_btn =
              <div className={@props.styling}>
                  <button className='nav-item-button'
                          onClick={@setSortTypeAllTitles}>
                      all titles
                  </button>
              </div>


      if @props.subset is 'user_submissions'
          my_submissions_btn_titles =
              <div className={@props.styling}>
                  <button className='nav-item-button'>
                      <strong>my submissions</strong>
                  </button>
              </div>
      else
          my_submissions_btn_titles =
              <div className={@props.styling}>
                  <button className='nav-item-button text-left'
                          onClick={@setSortTypeUserSub}>
                      my submissions
                  </button>
              </div>

      if @props.subset is 'user_bookmarks'
          my_bookmarks_btn_titles =
              <div className={@props.styling}>
                  <button className='nav-item-button text-left'>
                      <strong>bookmarks</strong>
                  </button>
              </div>
      else
          my_bookmarks_btn_titles =
              <div className={@props.styling}>
                  <button className='nav-item-button text-left'
                          onClick={@setSortTypeUserBookMs}>
                      bookmarks
                  </button>
              </div>

      if @props.subset is 'user_submissions'
          my_submissions_btn_program =
              <div className={@props.styling}>
                  <button className='nav-item-button'>
                      <strong>my submissions</strong>
                  </button>
              </div>
      else
          my_submissions_btn_program =
              <div className={@props.styling}>
                  <button className='nav-item-button text-left'
                          onClick={@setSortTypeUserSub}>
                      my submissions
                  </button>
              </div>

      if @props.subset is 'user_bookmarks'
          my_bookmarks_btn_program =
              <div className={@props.styling}>
                  <button className='nav-item-button text-left'>
                      <strong>bookmarks</strong>
                  </button>
              </div>
      else
          my_bookmarks_btn_program =
              <div className={@props.styling}>
                  <button className='nav-item-button text-left'
                          onClick={@setSortTypeUserBookMs}>
                      bookmarks
                  </button>
              </div>

      if not (@props.id)
          <div>
          </div>
      else if (@state.title_detail_page is 'true')
          button_list =
            <div className='row'>
              { all_btn }
              {back_button}
              {about_button}
            </div>
      else if (@props.caller is 'titles') and (@state.ptype is 'conference')
          button_list =
            <div className='row'>
              { all_btn }
              <span className='pl-2'>
                { my_submissions_btn_titles }
                { my_bookmarks_btn_titles }
              </span>
              {back_button}
              {program_titles}
              {tools_button}
              {about_button}
              {register_button}
            </div>
      else if (@props.caller is 'titles') and (@state.ptype is 'project')
          button_list =
            <div className='row'>
              { all_btn }
              <span className='pl-2'>
                { my_bookmarks_btn_titles }
              </span>
              {back_button}
              {about_button}
            </div>
      else if (@props.caller is 'titles') and (@state.ptype is 'short_course')
          button_list =
            <div className='row'>
              { all_btn }
              <span className='pl-2'>
                { my_submissions_btn_titles }
                { my_bookmarks_btn_titles }
              </span>
              {back_button}
              {program_titles}
              {tools_button}
              {about_button}
            </div>
      else if (@props.caller is 'titles') and ((@state.ptype is 'lab_group') or (@state.ptype is 'seminar'))
          button_list =
            <div className='row'>
              { all_btn }
              <span className='pl-2'>
                { my_bookmarks_btn_titles }
              </span>
              {back_button}
              {tools_button}
              {about_button}
            </div>
      else if (@props.caller is 'program')
          button_list =
            <div className='row'>
              { all_btn }
              {back_button}
              {program_titles}
              <span className='pl-2'>
                { my_submissions_btn_program }
                { my_bookmarks_btn_program }
              </span>
              {tools_button}
              {about_button}
              {register_button}
            </div>
      else if (@props.caller is 'about') and ((@state.ptype is 'lab_group') or (@state.ptype is 'seminar'))
          button_list =
            <div className='row'>
              { all_btn }
              {back_button}
              {tools_button}
              {about_button}
            </div>
      else if (@props.caller is 'about') and (@state.ptype is 'project')
          button_list =
            <div className='row'>
              { all_btn }
              {back_button}
              {about_button}
            </div>
      else if (@props.caller is 'tools') and ((@state.ptype is 'lab_group') or (@state.ptype is 'seminar'))
          button_list =
            <div className='row'>
              { all_btn }
              {back_button}
              {tools_button}
              {about_button}
            </div>
      else
          button_list =
            <div className='row'>
              { all_btn }
              {back_button}
              {program_titles}
              {tools_button}
              {about_button}
              {register_button}
            </div>


      <div className='container-fluid'>
          {button_list}
      </div>
