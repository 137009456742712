common = require('../common')
ConferenceHeader = require('../conference_header')
ConferenceNoteReviews = require('./conference_note_reviews')
HeaderBar = require('../header_bar')
NoteTitleOnly = require('./note_title_only')
NavGeneral = require('../nav_general')
Pagination = require('../pagination')
RolesBarConf = require('../roles/roles_bar_conf')
UploadCSVButton = require('./upload_csv_button')

module.exports = class ConferenceIntegrations extends React.Component
  displayName: 'ConferenceIntegrations'

  constructor: (props) ->
      super(props)
      projectid = @props.match.params.projectId
      @state = {
          data: { logged_in_user: 'anonymous' }
          follow_btn_txt : 'follow?'
          instructions: ''
          lengthy: 1
          matching_notes: []
          missing_notes: []
          mode : 'loading'
          my_accepted_roles : [ ]
          notes: []
          notes_length: 0
          num_per_page: 10
          page_num: 1
          projectid : projectid
          project : { }
          roles : [ ]
          roleswords : [ ]
          rolestring : ''
          roles_error: ''
          send_these_noteids: []
          userid : ''
          wordlist: [ ]
      }
      @activeAreaRef = React.createRef()
      @handEntriesRef = React.createRef()

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        # window.scrollTo(0, elem.offsetTop)
        elem.scrollIntoView()

  componentDidMount: =>
      data =
          projectid: @state.projectid

      common.getConferenceAndRolesForUser(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid_conference' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })

          else

              my_accepted_roles = [ ]
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              if not (result['project']['surveyable_id'])
                  result['project']['surveyable_id'] = ''

              loaded_wordlist = [ ]
              if result.project.perms
                for perm in result.project.perms
                    loaded_wordlist.push(perm.word)

              @setState({
                  projectid: result.project.id,
                  project: result['project'],
                  userid: result['userid'],
                  roles: result['roles'],
                  my_accepted_roles : my_accepted_roles,
                  wordlist: loaded_wordlist,
                  submission_deadline: result['project']['submission_deadline'],
                  start_date: result['project']['start_date'],
                  end_date: result['project']['end_date'],
                  review_deadline: result['project']['review_deadline'],
                  registration_deadline: result['project']['registration_deadline'] })

              if result['project']
                  document.title = result['project']['title']
              else
                  document.title = 'ZeroDivZero'

              if result.userid
                  @setState({ mode: 'helm' })
              else
                  window.location = '/login/?' + window.location.href
      )

      common.getTotalNumberNotesInProject(data, (result) =>
        @setState({ notes_length: result.total})
      )

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()

  loadMatchingNotes: () =>
      matching_note_ids = []

      for note in @state.matching_notes
          matching_note_ids.push(note[0])

      data =
        projectid: @state.projectid
        panel_type: 'chair'
        noteids: matching_note_ids

      common.getTheseNotesForChairs(data, (result) =>

          my_accepted_roles = [ ]
          for role in result['roles']
              if role.role_accepted is 'true'
                  my_accepted_roles.push(role.role)

          @setState({
              notes: result['notes'],
              userid: result['userid'],
              roles: result['roles'],
              my_accepted_roles: my_accepted_roles,
              mode: 'show_matches' })

          L_matching_notes = @state.matching_notes.length
          lengthy = Math.ceil(L_matching_notes/@state.num_per_page)
          @setState({ lengthy: lengthy })
      )

  getThesePages: (info) =>
      Lstart = page_num*num_per_page-1
      Lend = page_num*(num_per_page+1)
      noteids = @state.matching_notes[Lstart: Lend]
      @setState({
          page_num: info
          send_these_noteids: noteids }, @loadMatchingNotes)

  sendHandEntries: =>
      hand_entries = @handEntriesRef.current.value
      if 'areachair' in @state.my_accepted_roles
          my_role='areachair'
      if 'chair' in @state.my_accepted_roles
          my_role='chair'

      data =
          projectid : @state.project.id
          hand_entries : hand_entries
          my_role: my_role

      common.getMatchingProjectTitlesForChairs(data, (result) =>
            parsed = result
            # parsed = JSON.parse(result)
            @theResults(parsed)
      )

  conferenceHeader: () =>
      if (@state.mode != 'invalid_conference') and (@state.mode != 'conference_deleted')
          <span>
              <ConferenceHeader project={@state.project}
                  userid={@state.userid}
                  roles={@state.roles}
                  wordlist={@state.wordlist} />
          </span>

  rolesBar: () =>
      if (@state.mode != 'invalid_conference') and (@state.mode != 'conference_deleted')
          <div className='row border'>
              <span className="ml-0 pl-2">
                My roles: <RolesBarConf project={@state.project}
                    my_accepted_roles={@state.my_accepted_roles}
                    roles={@state.roles}
                    userid={@state.userid}
                    view={'mini'}
                    setUpdatedRoles={@setUpdatedRoles}
                    getUserNoteDetails={@getUserNoteDetails} />
              </span>
          </div>

  paperListings: () =>
      if @state.mode is 'show_matches' and @state.notes and @state.project.tags and @state.roles
          <div className='row no-gutters'>
              {@state.notes.map((paper) =>
                  <ConferenceNoteReviews key={paper.id}
                      note={paper}
                      project={@state.project}
                      reviewq={@state.project.reviewq}
                      roles={@state.roles}
                      panel_type={'chair'}
                      spots={ @state.spots }
                      clocks={ @state.clocks }
                      places={ @state.places } />
              ,this)}
              <Pagination page_num={@state.page_num}
                  lengthy={@state.lengthy}
                  onClick={@getThesePages} />
          </div>
      else if !@state.notes
          <div>
              <i>Nothing here.... yet....</i>
          </div>

  theResults: (result) =>
      @setState({
        matching_notes: result.matching_notes[0]
        missing_notes: result.missing_notes
        mode: 'results_here' }, @scrollToHere(@activeAreaRef))

  missingNotes: () =>
    if @state.mode is 'show_missing'
          return(
              <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                  <ul>
                      {@state.missing_notes.map((paper) =>
                          <NoteTitleOnly key={paper}
                              title={paper} />
                      ,this)}
                  </ul>
              </div>
          )

  showMatches: () =>
      @setState({ mode: 'loading' }, @loadMatchingNotes())

  showMissing: () =>
      @setState({ mode: 'show_missing' })

  showPaperStatuses: () =>
      if @state.mode != 'helm'
          Lmatching = 0
          if @state.matching_notes
              Lmatching = @state.matching_notes.length
          Lmissing = 0
          if @state.missing_notes
              Lmissing = @state.missing_notes.length
          return(
              <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                  <div className='row no-gutters'
                      ref={@resultsRef} >
                      <span className='pt-2'>
                          Found: { Lmatching } submissions
                      </span>
                      <button className='btn btn-link ml-2'
                          onClick={@showMatches}>
                          view
                      </button>
                  </div>
                  <div className='row no-gutters'>
                      <span className='pt-2'>
                          Missing: {Lmissing }
                      </span>
                      <button className='btn btn-link ml-2'
                          onClick={@showMissing}>
                          view
                      </button>
                  </div>
              </div>
          )

  uploadHandEntries: () =>
      return(
          <div className="row no-gutters m-3">
            <textarea type="text"
                ref={@handEntriesRef}
                placeholder="Separate submission titles with a comma..."
                aria-label="titles list"
                cols="20" rows="2">
            </textarea>
            <div className="">
                <button className="btn btn-secondary"
                        onClick={@sendHandEntries}>Find</button>
            </div>
          </div>
      )

  uploadCSVButton: () =>
      return(
          <div className='row no-gutters m-3'>
              <UploadCSVButton
                  projectid={@state.project.id}
                  roletype={'titles_csv'}
                  upload_content={'titles_csv'}
                  theResults={@theResults} />
          </div>
          )

  render: =>

      conf_nav_vert =
          <div className='col-md-2 col-lg-2 border d-none d-md-block'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'tools'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'col-12'} />
          </div>
      conf_nav_horiz =
          <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'tools'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'mr-2'} />
          </div>

      <div>
          <HeaderBar />
          <div className='container-fluid pr-0 pl-0'>
              { @conferenceHeader() }
              <div className='row'>
                  {conf_nav_vert}
                  {conf_nav_horiz}
                  <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                      <div className='row no-gutters m-3'>
                          Neet to compare the current submissions to an outside
                          list? We will show you what has been submitted and what
                          is missing. Enter titles separated by a comma. Use the
                          % symbol as a wild card.
                      </div>
                      { @uploadHandEntries() }
                      <div className='row no-gutters m-3'>
                          Or upload your list of titles (separated by comma):
                      </div>
                      { @uploadCSVButton() }
                      <div className='row no-gutters m-3'
                          ref={@activeAreaRef}>
                          { @showPaperStatuses() }
                          { @missingNotes() }
                          { @paperListings() }
                      </div>
                  </div>
              </div>
          </div>
      </div>
