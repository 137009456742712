
module.exports = class TitleLink extends React.Component
  displayName: 'TitleLink'

  constructor: (props) ->
      super(props)
      @state = { title: @props.title }

  componentDidMount: () =>
      if @props.editor is 'MathJax'
          window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, @domElement])
      if (@props) and (@props.title.length < 1)
          title = "Untitled"
          @setState({ title: title })

  setDOMRef: (domElement) =>
    @domElement = domElement

  render: =>

    if @props.editor is 'MathJax'
      <button className="mathjax btn btn-link btn-lg btn-txt-lg"
              ref={@setDOMRef}
              onClick={@props.onClick}
              dangerouslySetInnerHTML={{__html: @state.title }}>
      </button>
    else
      <button className="btn btn-link btn-lg btn-txt-lg"
              onClick={@props.onClick}
              dangerouslySetInnerHTML={{__html: @state.title }}>
      </button>
