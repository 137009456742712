common = require('../common')
Dropzone = require('react-dropzone').default
Loading = require('../loading')
VideoPreviewsRoll = require('./video_previews_roll')

module.exports = class Videos extends React.Component
  displayName: 'Videos'

  constructor: (props) ->
    # expects props:
    # obj_type ('note'), user_can_edit ('true' or 'false')
    # projectid, objid
    super(props)
    @state = {
      mode: 'no_videos'
      videos: []
      vtt_mode: 'off'
      vtt_id: ''
      new_video_id: ''
      save_btn: 'false'
    }
    @newVideoRef = React.createRef()
    @captionRef = React.createRef()
    @linkRef = React.createRef()

  componentDidMount: =>

      data =
          obj_type: @props.obj_type
          id: @props.obj.id

      common.getVideos(data, (result) =>
          if @props.mode
              mode = @props.mode
          if (result) and (result.videos) and (result.videos.length > 0)
              if not @props.mode
                  mode = 'videos_exist'
              @setState({ videos: result.videos, mode: mode })
          else if (result)
              if not @props.mode
                  mode = 'no_videos'
              @setState({ videos: [], mode: mode })
          else
              if not @props.mode
                  mode = 'Could not communicate with server. Try again?'
              @setState({ mode: mode })
      )

  createPlaceholderVideo: () =>

      data =
          obj_type: @props.obj_type
          id: @props.obj.id

      common.createPlaceholderVideo(data, (result) =>
          new_video_id = result.video_id
          @setState({
              save_btn: 'true'
              new_video_id: new_video_id }, @scrollToHere('askId'))
      )

  setMode: (the_mode) =>
      @setState({ mode: the_mode })
      if the_mode is 'ask_for_videos'
          @createPlaceholderVideo()

  validURL: (link) =>
      if link.slice(0,8) is 'https://'
          link = link.slice(8)
      if link.slice(0,7) is 'http://'
          link = link.slice(7)

      regex = /^[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/

      if not regex.test(link)
          return false
      else
          return true

  @getURLParameters: (snippet) ->
      vars = {}
      snippet.replace(/[ ]+([^= ]+)=([^ ]*)/gi, (m, key, value) ->
          # TODO: why do we get ""???
          vars[key] = value
      )
      return vars

  processLink: (link) =>
      # this checks if it's a link (http + ...) or
      # embed code (contains < >).
      # If it's YouTube embed, then we pull out a link.
      if link.slice(0,4) is 'http'
          {}
      else
          params = Videos.getURLParameters(link)
          if link.match(/youtube/g)
              link = params['src']
              link = link.slice(1, link.length-1)
              # this returns something of the format:
              # www.youtube.com/embed/youtubeID
              # which we turn into:
              # https://www.youtube.com/watch?v=youtubeID
              pos = link.lastIndexOf('/')
              youtubeID = link.substring(pos + 1)
              link = 'https://www.youtube.com/watch?v=' + youtubeID
      return link

  sendVideoCode: () =>
      caption = @captionRef.current.value
      if caption.length > 140
          @setState({ err_msg: 'Max 140 characters for description.'})
          return

      link = @linkRef.current.value
      link = @processLink(link)
      if link.length > 1000
          @setState({ err_msg: 'Max 1000 characters.'})
          return

      #if not @validURL(link)
      #    @setState({ err_msg: 'Please enter a valid URL.'})
      #    return

      data =
          video_code: link
          video_id: @state.new_video_id
          caption: caption
          obj_type: @props.obj_type

      updated_videos = @state.videos
      url =
        caption: caption
        link: link
      vtt =
        id: @state.vtt_id
      local_video =
        id: @state.new_video_id
        url: url
        caption: data.caption
        vtt: vtt

      common.updateVideoLink(data, (result) =>
          # no response if just a video, a response if vtt also
          if result and result['vtt_body_blawb_id']
              vtt.body_blawb_id = result['vtt_body_blawb_id']
          updated_videos.push(local_video)
          @setState({ videos: updated_videos, mode: 'videos_exist', err_msg: '' })
          @setMode('show_videos')
      )

  removeVideoFromNote: (this_video) =>
      data =
        video_id : this_video.id

      vs = @state.videos
      vs_ids = []
      for v in vs
        vs_ids.push(v.id)
      index = vs_ids.indexOf(this_video.id)
      if index > -1
          vs.splice(index, 1)
          @setState({ videos: vs, mode: 'show_videos' })

      common.hideVideo(data, (result) =>
          # no result
      )

  videoPreviewsRoll: =>
      if ((@state.videos) and (@state.videos.length > 0))
          video_previews_roll =
              <span>
                  {@state.videos.map((video) =>
                      <VideoPreviewsRoll key={video.id}
                          video={video}
                          user_can_edit={@props.user_can_edit}
                          removeVideoFromNote={@removeVideoFromNote}
                          obj={@props.obj}
                          obj_type={@props.obj_type} />
                  )}
              </span>

  vttMode: (mode) =>
      if mode is 'show_dropzone'
          data =
              video_id: @state.new_video_id

          common.createPlaceholderVtt(data, (result) =>
              vtt_id = result.vtt_id
              @setState({ vtt_mode: mode, vtt_id: vtt_id })
          )
      else
          @setState({ vtt_mode: mode })

  uploadFiles: (files) =>
      if files.length is 0
          alert('No valid files selected.')
          @setState({ vtt_mode: 'off' })
      else
          @setState({ vtt_mode: 'loading' })

      new_video_id = @state.new_video_id
      common.submitVideoVtt(files, new_video_id, (rs) =>
          result = JSON.parse(rs)
          @setState({vtt_mode: 'file_exists', blawbid: 'blawbid'})
      )

  onDrop: (upload_files) =>
      @setState({files: upload_files})
      @uploadFiles(upload_files)

  scrollToHere: (this_id) =>
      # console.log("HI!!! ref=")
      # console.log(ref)
      # elem = ref.current
      if (this_id)
        # window.scrollTo(0, elem.offsetTop)
        document.getElementById(this_id).scrollIntoView();

  addVTT: () =>
      if @state.vtt_mode is 'off'
        return(
            <div className='row no-gutters'>
                <button className='btn btn-link mb-3'
                    onClick={@vttMode.bind(this,'show_dropzone')}>
                    Add a VTT file for accessbility?
                </button>
            </div>
        )
      else if @state.vtt_mode is 'show_dropzone'
          return(
              <div className='mb-3'>
                  <Dropzone className='container'
                            onDrop={@onDrop}
                            multiple={false}
                            accept="application/pdf,application/x-latex,application/x-tex,text/x-latex,text/x-tex,text/vtt,*.pdf,*.latex,*.tex,*.vtt">
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <p>
                                        Drag and drop VTT file or click to select a file
                                    </p>
                                </div>
                            )}
                  </Dropzone>
              </div>
          )
      else if @state.vtt_mode is 'loading'
          return (
              <div>
                  <Loading />
              </div>
          )
      else if @state.vtt_mode is 'file_exists'
          return(
              <div>
                  VTT file uploaded!
              </div>
          )

  askForLinks: =>

      if @state.save_btn is 'true'
          save_btn =
              <button className='btn btn-primary mr-3'
                      onClick={@sendVideoCode}>save</button>
      return(
          <div className='border border-primary border_rad_tenpx p-3 m-3'>
              { @videoPreviewsRoll }
              <br />
              <span id='askId'>Add a video?</span>
              <input ref={@linkRef} id="vid_link"
                className='headerInput'
                type="text"
                placeholder="Paste your YouTube link here"
                cols="40" rows="10"></input>
              <br />
              <div className='row no-gutters'>
                 <input className='headerInput'
                        type="text"
                        placeholder="Optional brief description"
                        ref={@captionRef}></input>
              </div>
              { @addVTT() }
              { save_btn }
              <button className='btn btn-primary'
                      onClick={@setMode.bind(this,'show_videos')}>cancel</button>
              <span className='text-danger'> &nbsp; {@state.err_msg}</span>
          </div>
      )

  buttonsAddHide: =>
      if (@props.user_can_edit is 'true')
          <span>
              <button className='btn btn-primary mr-3'
                  onClick={@setMode.bind(this,'ask_for_videos')}>
                  add new video
              </button>
              <button className='btn btn-primary'
                onClick={@setMode.bind(this,'videos_exist')}>hide</button>
          </span>
      else
          <span></span>

  showVideosExists: =>
      <span>
          <button className="btn btn-link btn-items mr-2"
              onClick={ @setMode.bind(this, 'show_videos') }>
              Video(s)
          </button>
      </span>

  showVideos: =>
      <div className='border border-primary border_rad_tenpx p-3 m-3'>
          Video(s) &nbsp; &nbsp;
          { @videoPreviewsRoll() }
          { @buttonsAddHide() }
      </div>


  render: =>

      if @state.mode is 'no_videos'
          <span></span>
      else if @state.mode is 'ask_for_videos'
          <span>
              {@askForLinks()}
          </span>
      else if @state.mode is 'videos_exist'
          <span>
              {@showVideosExists()}
          </span>
      else if @state.mode is 'show_videos'
          <span>
              {@showVideos()}
          </span>
      else
          <span>
          </span>
