common = require('../common')

module.exports = class SendStripeToken extends React.Component
  displayName: 'SendStripeToken'

  @getURLParameters: (urlPath) ->
      vars = {}
      urlPath.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) ->
          vars[key] = value
      )
      return vars

  constructor: (props) ->
      super(props)
      # expects props: amount, fee_id, projectid
      amount = Number(@props.amount)*100
      @state = {
        amount: amount
        mode: 'no_view'
      }

  checkForStripeToken: () =>
      if window.location.href.indexOf('?stripeToken') > -1
          @sendForm()

  componentDidMount: =>
      window.addEventListener('popstate', @checkForStripeToken())

  componentDidUpdate: (prevProps)=>
      if @props.fee_id != prevProps.fee_id
          window.addEventListener('popstate', @checkForStripeToken())

  sendForm: =>
      params = SendStripeToken.getURLParameters(window.location.href)
      stripeToken = params['stripeToken']
      stripeTokenType = params['stripeTokenType']
      stripeEmail = params['stripeEmail']
      stripeBillingName = params['stripeBillingName']
      stripeBillingAddressCountry = params['stripeBillingAddressCountry']
      stripeBillingAddressCountryCode = params['stripeBillingAddressCountryCode']
      stripeBillingAddressZip = params['stripeBillingAddressZip']
      stripeBillingAddressLine1 = params['stripeBillingAddressLine1']
      stripeBillingAddressState = params['stripeBillingAddressState']

      if stripeTokenType is 'card'
          charge_type = 'credit_card'
      else
          charge_type = null

      data =
          {
              stripeToken: stripeToken
              stripeTokenType: stripeTokenType
              stripeEmail: stripeEmail
              stripeBillingName: stripeBillingName
              stripeBillingAddressCountry: stripeBillingAddressCountry
              stripeBillingAddressCountryCode: stripeBillingAddressCountryCode
              stripeBillingAddressZip: stripeBillingAddressZip
              stripeBillingAddressLine1: stripeBillingAddressLine1
              stripeBillingAddressState: stripeBillingAddressState
              charge_type: charge_type
              amount: @state.amount
              currency: 'USD'
              fee_id: @props.fee_id
              projectid: @props.confid
          }

      common.sendStripePaymentInfo(data, (result) =>
          localStorage.removeItem('chosen_fee')
          if (result.msg is 'success')
              localStorage.removeItem('error_code')
              window.location = '/conference/' + @props.confid + '/register'
          else if (result.msg)
              localStorage.setItem('error_code', JSON.stringify(result.msg))
              window.location = '/conference/' + @props.confid + '/register'
          else
              # not sure, just try to reload the page
              window.location = '/conference/' + @props.confid + '/register'
      )


  render: =>
      <span></span>
