common = require('./common')

module.exports = class FollowButton extends React.Component
  displayName: 'FollowButton'

  constructor: (props) ->
      super(props)
      roles = @props.roles
      @state = {
          follow_btn_txt : 'follow?'
          roles: roles
      }

  componentDidMount: =>
      if @props.roles
        for role in @props.roles
            if role.role == 'following'
                if role.role_accepted == 'true'
                  @setState({ follow_btn_txt : 'following' })

  componentDidUpdate: (prevProps) =>
      if @props.roles
        if @props.roles != prevProps.roles
            for role in @props.roles
                if role.role == 'following'
                    if role.role_accepted == 'true'
                      @setState({ follow_btn_txt : 'following' })

  toggleConferenceRole: () =>
      data =
          projectid : @props.projectid
          rolestring : 'following'

      if @state.follow_btn_txt is 'follow?'
          @setState({ rolestring : 'following '})
          @setState({ follow_btn_txt : 'following' })
          data.rolestring = 'following'

      else if @state.follow_btn_txt is 'following'
          @setState({ follow_btn_txt : 'follow?'})
          @setState({ rolestring : 'unfollow' })
          data.rolestring = 'unfollow'

      common.addUserRoleToProject(data, (result) =>
          @props.setFollowRoles(result.roles)
          if data['roles']
            if  data['roles'].length > 0
              @setState({
                  follow_btn_txt : 'following',
                  roles: result.roles })
      )

  render: =>
    if @state.follow_btn_txt is 'following'
      <span></span>
    else
      <span>
          <button className="btn btn-secondary btn-txt-sm"
              onClick={@toggleConferenceRole}>
              {@state.follow_btn_txt}</button>
      </span>
