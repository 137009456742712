common = require('../common')

module.exports = class TagNoteRollEditable extends React.Component
  displayName: 'TagNoteRollEditable'

  constructor: (props) ->
      super(props)
      @state = {
        noteid : @props.note.id
        paperseriesid : @props.note.paperseries_id
        tag: @props.tag
        mode: 'display'
      }

  removeTagFromPaperSeries: () =>

      data =
        noteid : @props.note.id
        paperseriesid : @props.note.paperseries_id
        tagid : @props.tag.id

      common.removeProjectNoteTag(data, (result) =>
         @setState({ tag : '' })
         @setState({ mode : 'removed' })
      )

  render: =>

      if @state.mode is 'removed'
          <div className='authornameroll_div'>Removed!</div>
      else
          <div className="btn-group">
            <button className="btn btn-primary btn-txt-sm">
                    {@props.tag.word}&nbsp;</button>
            <button className="btn btn-danger btn-txt-sm"
                    onClick={@removeTagFromPaperSeries}>
                    &nbsp;x&nbsp;</button>
          </div>
