common = require('./common')

module.exports = class Loading extends React.Component
  displayName: 'Loading'

  render: =>

    <div>
        <i className="fa fa-cog fa-spin fa-3x fa-fw"></i>
    </div>
