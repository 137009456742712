common = require('../common')
Dropzone = require('react-dropzone').default
DocumentFrame = require('./document_frame')
Loading = require('../loading')

module.exports = class MaterialButton extends React.Component
    displayName: 'MaterialButton'

    constructor: (props) ->
        super(props)
        @state = {
            mode: 'get_meta',
            files: [],
            blawbcontent: '',
            blawbid: ''
            url: ''
            empty_submission: false
            err_msg: ''
            url_meta: {}
        }
        @captionRef = React.createRef()
        @linkRef = React.createRef()

    deleteUserMaterialItem: () =>
        # in backend, generate a new version of url without copying the url
        @setState({blawbid: '' })
        data = {url_id: @state.url.id}

        common.hideUrlItem(data, (result) =>
            updated_matl = @state.matl
            updated_matl.id = result.matl_id
            updated_matl.body_blawb_id = null
            @setState({url: updated_url})
        )

    #uploadUrlBlawb: () =>
    #    @setState({mode: 'get_meta'})

    changeToUploadStep: () =>
        caption = @captionRef.current.value
        if caption.length > 140
            @setState({ err_msg: 'Max 140 characters for description.'})
            return

        link = @linkRef.current.value
        if link.length > 140
            @setState({ err_msg: 'Max 140 characters for title'})
            return

        data =
            link: link
            ltype: 'note'
            id: @props.note.id
            caption: caption

        @setState({url_meta: data, mode: 'uploading'})

    onDrop: (upload_files) =>
        @setState({ files: upload_files })
        @uploadFiles(upload_files)

    uploadFiles: (files)=>
        if files.length is 0
            alert('No valid files selected.')
            @setState({ mode: 'reading' })
            return
        else
            @setState({ mode: 'loading' })

        data = @state.url_meta

        common.saveUrlLink(data, (result) =>
            new_url = result.url
            new_url.body_blawb_id = 'blawbid'
            common.submitUrlDocument(files, new_url.id, (rs) =>
                result = JSON.parse(rs)
                @setState({ mode: 'reading', blawbid: 'blawbid' }, @props.resetButtons({button_mode: 'show_buttons', new_url: new_url}))
            )

        )

    getMaterials: =>
        if @state.mode is 'get_meta'
            <span>
                <div className='row no-gutters'>
                   <input className='headerInput'
                          type="text"
                          placeholder='Title'
                          ref={@linkRef}></input>
                </div>
                <div className='row no-gutters'>
                   <input className='headerInput'
                          type="text"
                          placeholder="Brief description (up to 140 characters)"
                          ref={@captionRef}></input>
                </div>
                <button className='btn btn-primary mr-2'
                        onClick={@changeToUploadStep}>Next</button>
                <button className='btn btn-primary'
                        onClick={@props.resetButtons.bind(this,'show_buttons')}>Cancel</button>
                <span className='text-danger'> &nbsp; {@state.err_msg}</span>
            </span>
        else if @state.mode is 'uploading'
            <div>
                <Dropzone className='container'
                          onDrop={@onDrop}
                          multiple={false}
                          accept="application/pdf,application/x-latex,application/x-tex,text/x-latex,text/x-tex,application/x-ipynb+json,*.pdf,*.latex,*.tex,*.ipynb">
                          {({getRootProps, getInputProps}) => (
                              <div {...getRootProps({className: 'dropzone'})}>
                                  <input {...getInputProps()} />
                                  <p>
                                      Drag and drop pdf, LaTeX, ipynb document here, or click to select a file
                                  </p>
                              </div>
                          )}
                </Dropzone>
            </div>
        else if @state.mode is 'loading'
            <Loading />


    render: =>

        if (@props.user_is_author is 'true') or (@state.url.body_blawb_id) or (@state.blawbid)
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                { @getMaterials() }
            </div>
        else
            <span></span>
