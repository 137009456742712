common = require('../common')

module.exports = class AuthorNameRollEditable extends React.Component
  displayName: 'AuthorNameRollEditable'

  constructor: (props) ->
      super(props)
      @state = {
        noteid : @props.noteid
        # authorcontact: @props.authorcontact
        mode: 'display name'
      }
      @authorOrderRef = React.createRef()

  gotoUserProfile: () =>
      window.location = '/profile/' + this.props.authorcontact.user_id

  removeAuthorFromPaperSeries: () =>
      data =
        noteid : @props.noteid
        authorcontactid : @props.authorcontact.id

      common.removeProjectNoteAuthorContact(data, (result) =>
         @setState({ authorcontact : '' })
         @setState({ mode : 'remove name' })
      )

  updateAuthorOrder: () =>
      new_aorder = @authorOrderRef.current.value
      @props.onChange(this)

  fullName: () =>
      if (@props.authorcontact) and (@props.authorcontact.credit_as)
          return(
              <span>
                {@props.authorcontact.name},&nbsp;{@props.authorcontact.credit_as}
              </span>
          )
      else
          return(
              <span>
                {@props.authorcontact.name}
              </span>
          )

  authorDoesNotExist: () =>
      <div className="input-group mb-3 pad-right">
          <input type="text"
                 ref={@authorOrderRef}
                 onChange={@updateAuthorOrder}
                 className="aorder-form-control small_input_textbox nudge-down"
                 placeholder={@props.authorcontact.aorder + 1}
                 aria-label="author order"
                 aria-describedby="basic-addon2" />
          <div className="input-group-append">
              <button className="btn btn-secondary btn-txt-sm"
                      onClick={@gotoUserProfile}>
                      { @fullName() }&nbsp;</button>
              <button className="btn btn-danger btn-txt-sm"
                      onClick={@removeAuthorFromPaperSeries}>
                      &nbsp;x&nbsp;</button>
          </div>
      </div>

  editableAuthor: () =>
      <div className="input-group mb-3 pad-right">
          <div className="input-group-append same-line">
              <input type="text"
                     ref={@authorOrderRef}
                     onChange={@updateAuthorOrder}
                     className="aorder-form-control small_input_textbox nudge-down"
                     placeholder={@props.authorcontact.aorder + 1}
                     aria-label="author order"
                     aria-describedby="basic-addon2" />
              <button className="btn btn-primary btn-txt-sm"
                      onClick={@gotoUserProfile}>
                      { @fullName() }&nbsp;</button>
              <button className="btn btn-danger btn-txt-sm"
                      onClick={@removeAuthorFromPaperSeries}>
                      &nbsp;x&nbsp;</button>
          </div>
      </div>

  render: =>


      if @state.mode is 'remove name'
          <div className='authornameroll_div'>Removed!</div>
      else if @props.authorcontact.user_id
          <div className='authornameroll_div'>
              { @editableAuthor() }
          </div>
      else
          <div className='authornameroll_div'>
              { @authorDoesNotExist() }
          </div>
