Link = require('react-router-dom').Link
common = require('./common')
Writing = require('./writing')

module.exports = class AcceptedToConferenceRoll extends React.Component
  displayName: 'AcceptedToConferenceRoll'
  constructor: (props) ->
    super(props)
    @state = {
      editor: @props.conference_infolist[2],
      title: @props.conference_infolist[1],
      id: @props.conference_infolist[0]
    }

  render: =>
      <div className='authornameroll_div'>
          <Link to={'/conference/' + @state.id + '/titles'}>
              <button className='btn btn-success btn-txt-sm'>
                  <Writing editor={@state.editor} writing={@state.title} />
              </button>
          </Link>
      </div>
