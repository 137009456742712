
module.exports = class ShowDate extends React.Component
  displayName: 'ShowDate'

  constructor: (props) ->
      super(props)
      # props are: datetime, gmt_offset
      # optional: duration
      @state = {
        day: NaN
        month: NaN
        year: NaN
        date: ''
        formatted_datetime: ''
        timezone: ''
      }

  getDayName: (day) =>
      name = switch
          when day == 0 then "Sun"
          when day == 1 then "Mon"
          when day == 2 then "Tue"
          when day == 3 then "Wed"
          when day == 4 then "Thu"
          when day == 5 then "Fri"
          when day == 6 then "Sat"
      return name

  getTimeZone: (time) =>
      if @props.timezone
          return @props.timezone
      else
          timezone = time.split('(')[1]
          timezone = timezone.split(')')[0]
          timezone = timezone.split(" ")
          abbrev = []
          for initial in timezone
              abbrev.push(initial[0])
          return abbrev.join("")


  formatDateTime: =>

      # using UTC from the backend
      ms = Number(@props.datetime * 1000)

      #if @props.gmt_offset
      #    ms = ms + @props.gmt_offset*60*1000

      datetime = new Date(ms)
      time = datetime.toString() # full expression (what I used originally)
      timezone = @getTimeZone(time)
      date = datetime.getDate().toString()
      day = datetime.getDay()
      day = @getDayName(day)
      month = Number(datetime.getMonth().toString())+1
      year = datetime.getFullYear().toString()

      hour = datetime.getHours()
      if @props.gmt_offset
          browser_offset_mins = datetime.getTimezoneOffset()
          total_offset = browser_offset_mins + @props.gmt_offset
          hour = hour + total_offset/60
      hour = hour.toString()
      hour = '0' + hour if hour.length < 2

      minute = datetime.getMinutes().toString()
      minute = '0' + minute if minute.length < 2

      if @props.formatting is 'rows'
          formatted_time =
              <div>
                  <div>
                      {hour}:{minute}&nbsp;{timezone}
                  </div>
                  <div>
                      {day}
                  </div>
                  <div>
                      {year}/{month}/{date}
                  </div>
              </div>
      else
          # simple line format is the default
          formatted_time = day + ", " + hour + ":" + minute + ' ' + timezone + ", " + year + "/" + month + "/" + date

      @setState({
          formatted_datetime: formatted_time,
          date: date,
          minute: minute,
          hour: hour,
          day:day,
          month:month,
          year:year  })


  componentDidMount: =>
      if @props.datetime > 0
          @formatDateTime()

  componentDidUpdate: (prevProps) =>
    if @props.datetime != prevProps.datetime
      if @props.datetime > 0
          @formatDateTime()

  render: =>

      if (@props.datetime) and (@props.datetime > 0) and (@props.duration)
          conference_due_date =
              <span>
                  { @state.formatted_datetime }, { @props.duration } mins
              </span>

      else if (@props.datetime) and (@props.datetime > 0)
            conference_due_date =
                <span>
                    { @state.formatted_datetime }
                </span>
      else
            conference_due_date =
                <span>
                    Date/time TBD
                </span>

      <span>
        { conference_due_date }
      </span>
