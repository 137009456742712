TagProjectRollSendClick = require('../tags/tag_project_roll_send_click')
Link = require('react-router-dom').Link

module.exports = class RoleRollConference extends React.Component
  displayName: 'RoleRollConference'

  constructor: (props) ->
      super(props)

      local_tags = ''
      if @props.tags
          local_tags = @props.tags
      @state = {
          mode : ''
          role_tag_list : [ ]
          local_tags : local_tags
      }

  registerUserRoleWithTags: () =>
      myrole_updated = @props.myrole
      myrole_updated.role_tag_list = @state.role_tag_list

      @props.onClick(myrole_updated)
      @setState({ mode : '' })

      for tag in @state.local_tags
          if tag.word[tag.word.length-1] is '✓'
              tag.word = tag.word.substr(0,tag.word.length-2)

  registerUserRoleNoTags: () =>
      myrole_updated = @props.myrole

      @props.onClick(myrole_updated)
      @setState({ mode : '' })

  addThisTagToListForUser: (tag) =>

      tmp_tags = @state.local_tags
      list = @state.role_tag_list

      # first, check if the word is on the list, if so, remove it
      # otherwise add it

      pos = list.indexOf(tag)

      if pos > -1
          list = list.splice(0,pos)
          if tag.word[tag.word.length-1] is '✓'
              tag.word = tag.word.substr(0,tag.word.length-2)
      else
          for tmp_tag in tmp_tags
              if tmp_tag.id is tag.id
                  tmp_tag.word = tag.word + ' ✓'
          list.push(tag)

      @setState({ role_tag_list : list })
      @setState({ local_tags : tmp_tags })

  offerTags: =>
      @setState({ mode : 'show_tags_too'})

  showMySubmissions: =>
      # place holder

  render: =>

      rolename_button_with_tags =
          <button className="btn btn-danger btn-txt-sm" onClick={@offerTags}>
              {@props.myrole.role}?</button>

      rolename_button_no_tags =
          <button className="btn btn-danger btn-txt-sm" onClick={@registerUserRoleNoTags}>
              {@props.myrole.role}?</button>

      if @state.mode is 'show_tags_too'

          if @state.local_tags.length > 0
              tagslist =
                  <span>
                      {@state.local_tags.map((local_tag) =>
                          <TagProjectRollSendClick key={local_tag.id}
                              tag={local_tag}
                              onClick={@addThisTagToListForUser.bind(this,local_tag)} />
                      , this)}
                  </span>

          tag_choices =
            <div>
                Choose the best conference session for { @props.myrole.role }:
                { tagslist }
                <button className="btn btn-secondary btn-txt-sm"
                    onClick={@registerUserRoleWithTags}>
                    submit</button>
            </div>

      if (@props.myrole.role in ['chair', 'areachair', 'reviewer']) and (@state.local_tags.length > 0)
          registration_section =
              <div className='authornameroll_div'>
                  { rolename_button_with_tags }
                  { tag_choices }
              </div>
      else
          registration_section =
              <div className='authornameroll_div'>
                  { rolename_button_no_tags }
              </div>

      linkto = '/' + @props.ptype + '/'  + @props.myrole.project_id + '/helm'


      if @props.myrole.role is 'contributor'
          <div className='authornameroll_div'>
            <button className="btn btn-info btn-txt-sm" onClick={@showMySubmissions}>
                    {@props.myrole.role}&#10003;</button>
          </div>
      else if @props.myrole.role is 'creator'
          <div className='authornameroll_div'>
              <Link to={ linkto }>
                  <button className="btn btn-info btn-txt-sm">
                      {@props.myrole.role}&#10003;</button>
              </Link>
          </div>
      else if @props.myrole.role_accepted is 'true'
          <div className='authornameroll_div'>
            <button className="btn btn-info btn-txt-sm" onClick={@props.onClick}>
                    {@props.myrole.role}&#10003;</button>
          </div>
      else if @props.myrole.role_accepted is 'false'
          <span>
              { registration_section }
          </span>
