

module.exports = class TagProjectRollSendClick extends React.Component
  displayName: 'TagProjectRollSendClick'

  sendClick: () =>
      # we're going to send the click up
      @props.onClick(@props.tag)

  btnStyle: () =>
      style = 'btn btn-success btn-txt-sm'
      if @props.style
          style = @props.style
      return(style)


  render: =>

      <div className='authornameroll_div mr-1'>
          <button className={ @btnStyle() }
              onClick={@sendClick}>
              {@props.tag.word}
          </button>{@props.comma}
      </div>
