Link = require('react-router-dom').Link
common = require('../common')
ConferenceDirectUpload = require('../conference_direct_upload')
ConferenceNoteDetails = require('../conference_note_details')
CreateNoteMetadata = require('../create_title/create_note_metadata')
HeaderBar = require('../header_bar')
Header = require('./header')
Helmet = require('react-helmet').Helmet
Loading = require('../loading')
NavGeneral = require('../nav_general')
ProjectNoteDetails = require('../project_note_details')
RolesBarLab = require('../roles/roles_bar_lab')
RolesBarShortCourse = require('../roles/roles_bar_short_course')

module.exports = class LabGroupTitle extends React.Component
  displayName: 'LabGroupTitle'

  constructor: (props) ->
      super(props)
      @state = {
          paperseriesid : @props.match.params.paperseriesId
          note : ''
          conference : ''
          projectid : @props.match.params.projectId
          roles: []
          my_accepted_roles: []
          msg: ''
          perms_list: []
          userid: ''
          mode: 'loading'
          note_origin: ''
          projectid_origin: ''
      }
      @makeNewTitleRef = React.createRef()

  componentDidMount: =>

      if window.location.href.indexOf('project') > -1
          note_type = 'project'
      else if window.location.href.indexOf('lab_group') > -1
          note_type = 'lab_group'
      else if window.location.href.indexOf('conference') > -1
          note_type = 'conference'
      else if window.location.href.indexOf('seminar') > -1
          note_type = 'seminar'
      else
          note_type = 'short_course'

      data =
          projectid : @props.match.params.projectId
          paperseriesid : @props.match.params.paperseriesId
          note_type: note_type

      common.conferenceNote(data, (result) =>

          if result['error'] is 'conference not found'
              @setState({mode : 'invalid_conference'})
          else if result['error'] is 'conference deleted'
              @setState({mode : 'conference_deleted'})
          else

              conference = result['conference']
              # prevent loading this as a project or conference page
              # BUGS! Needs test for seminar and a paperseriesId
              # if conference.ptype != 'lab_group'
              #     window.location = '/' + conference.ptype + '/' + conference.id + '/title'

              perms_list = []
              if conference.perms
                for perm in conference.perms
                    perms_list.push(perm.word)

              my_accepted_roles = [ ]
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              if result['note']
                  page_title = result['note']['title']
              else
                  page_title = result['conference']['title']
              page_link = 'https://zerodivzero.com/' + result['conference']['ptype'] + '/' + result['conference']['id'] + '/title/' + result['note']['id']

              user_can_schedule = 'false'
              if (result['userid'] is conference.user_id)
                  user_can_schedule = 'true'

              @setState({
                conference : result['conference'],
                note: result['note'],
                roles: result['roles'],
                my_accepted_roles: my_accepted_roles,
                perms_list: perms_list,
                userid: result['userid'],
                mode: 'viewing',
                page_title: page_title,
                page_link: page_link,
                user_can_schedule: user_can_schedule
              })

              data =
                noteid : result['note'].id

              common.getNoteOrigin(data, (result) =>
                  projectid = result.projectid
                  note_type = result.note_type
                  if result.err_msg
                      @setState({msg: result.err_msg})
                  # note_origin is separate from note_type.
                  # it is the note_type of the original note where the
                  # submission came from.
                  @setState({
                    note_origin: note_type,
                    projectid_origin: projectid })
              )
      )

  componentDidUpdate: (prevProps) =>
      if @props.match.params.projectId != prevProps.match.params.projectId or \
         @props.match.params.paperseriesId != prevProps.match.params.paperseriesId
          window.location.reload()

  helmetInfo: () =>
      <span>
          <Helmet>
              <title>{@state.page_title}</title>
              <meta name="og:title" content={@state.page_title} />
              <meta name="og:url" content={@state.page_link} />
              <meta name="og:type" content="article:science" />
              <meta name="og:image" content='../../images/other_logos/wins_250_248.png' />
              <meta name="og:description" content={@state.note.motive} />
              <meta name="fb:app_id" content='695761137112146' />
          </Helmet>
      </span>

  projectHeader: () =>
      if (@state.mode != 'invalid_conference') and (@state.mode != 'conference_deleted') and (@state.conference)
          <span>
              <Header project={@state.conference}
                  userid={@state.userid}
                  roles={@state.roles}
                  wordlist={@state.perms_list} />
          </span>

  problemMsg: () =>
      if @state.note is 'removed'
          <div className='m-3'>
            <h2>
              Oops! Hello there.
              <br /> <br />
              This paper is no longer part of this event
              but it might still be in its
              <Link to={'/project/' + @state.projectid + '/title/' + @props.match.params.paperseriesId}> original project</Link>.
            </h2>
          </div>
      else if @state.mode is 'invalid_conference'
          <div>
              <br />
              <h2>Not finding anything... Try refreshing!</h2>
              If you continue to get this error, then maybe the URL is wrong?
          </div>
      else if @state.mode is 'conference_deleted'
          <div>
              <br />
              <h2>Uh oh... It appears the owner has deleted this page.</h2>
          </div>

  showPaper: () =>
      if @state.mode is 'loading'
          <Loading />
      else if (@state.mode is 'viewing') and (@state.note_origin is 'project')
          return(
              <div>
                  <ConferenceNoteDetails project={@state.conference}
                      note={@state.note}
                      roles={@state.roles}
                      login_id={@state.userid} />
              </div>
          )
      else if (@state.mode is 'viewing') and (@state.note_origin != 'conference')
          return (
              <div>
                  <ProjectNoteDetails project={@state.conference}
                      note={@state.note}
                      roles={@state.roles}
                      my_accepted_roles={@state.my_accepted_roles}
                      login_id={@state.userid} />
              </div>
          )

  setMode: (info) =>
      # in this case, new_note might be returned!
      if info is 'cancelled'
          @setState({ mode: 'viewing' })
      else if info.new_note
          @setState({
              new_note: info.new_note
              mode: 'direct_upload_submisson' })
      else
          @setState({ mode: info })

  makeNewPaper: () =>
      if @state.mode is 'create_note_metadata'
          <div ref={@makeNewTitleRef}>
              <CreateNoteMetadata project={@state.conference}
                  setMode={@setMode}
                  version={'new'}
                  user_can_schedule={@state.user_can_schedule}/>
          </div>
      else if @state.mode is 'direct_upload_submisson'
          <div>
              Just a few more choices to finish submission.
              <ConferenceDirectUpload
                  note={@state.new_note}
                  conference={@state.conference} />
          </div>

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  getUserNoteDetails: =>
      @setState({
          mode : 'create_note_metadata'}, @scrollToHere(@makeNewTitleRef) )

  rolesBar: () =>
      if @state.roles
          if (@state.conference.ptype is 'lab_group') or (@state.conference.ptype is 'seminar')
              <RolesBarLab project={@state.conference}
                  my_accepted_roles={@state.my_accepted_roles}
                  roles={@state.roles}
                  userid={@state.userid}
                  view={'full'}
                  setUpdatedRoles={@setUpdatedRoles}
                  getUserNoteDetails={@getUserNoteDetails} />
          else if @state.conference.ptype is 'short_course'
              <RolesBarShortCourse project={@state.conference}
                  my_accepted_roles={@state.my_accepted_roles}
                  roles={@state.roles}
                  userid={@state.userid}
                  view={'full'}
                  setUpdatedRoles={@setUpdatedRoles}
                  getUserNoteDetails={@getUserNoteDetails} />

  navVert: () =>
      if @state.conference
          <div className='col-md-2 col-lg-2 border d-none d-md-block'>
              <NavGeneral
                  id={@state.conference.id}
                  ptype={@state.conference.ptype}
                  caller={'titles'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'col-12'} />
          </div>

  navHoriz: () =>
      if @state.conference
          <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
              <NavGeneral
                  id={@state.conference.id}
                  ptype={@state.conference.ptype}
                  caller={'titles'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'mr-2'} />
          </div>




  render: =>


      <div>
          <HeaderBar />
          <div className='container-fluid pr-0 pl-0 border'>
              { @projectHeader() }
              <div className='row no-gutters'>
                  { @navVert() }
                  { @navHoriz() }
                  <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                      { @helmetInfo() }
                      { @rolesBar() }
                      { @problemMsg() }
                      { @showPaper() }
                      { @makeNewPaper() }
                  </div>
              </div>
          </div>
      </div>
