common = require('./common')
Link = require('react-router-dom').Link
HeaderNotificationRoll = require('./header_notification_roll')

module.exports = class HeaderNotifications extends React.Component
  displayName: 'HeaderNotifications'

  constructor: (props) ->
      super(props)
      @state = {
          unseen_count: 0
          notif_color: 'text-primary'
          msgs: [ ]
          page_num: 1
          num_per_page: 10
          sort_type: 'recent'
      }

  componentDidMount: =>
      data = {}
      common.getNumberUnseenNotifications(data, (result) =>
          @setState({ unseen_count: result.num_unseens })
      )
      @showNotifications()

  showNotifications: () =>
      data =
          sort_type: @state.sort_type
          num_per_page: @state.num_per_page
          page_num: 1

      common.getUserNotificationsPage(data, (result) =>
          @setState({ msgs: result['result']})
          )

  hideNotifications: () =>
      data =
          sort_type: @state.sort_type
          num_per_page: @state.num_per_page
          page_num: @state.page_num

      if (@state.unseen_count - @state.num_per_page > 0)
          unseen = @state.unseen_count - @state.num_per_page
      else
          unseen = 0
      @setState({unseen_count: unseen })

      if @state.msgs.length > 0

          common.markUserNotificationsSeen(data, (result) =>
              msgs = @state.msgs
              for msg in msgs
                  msg.seen_at = 1
              @setState({ msgs: msgs })
          )

  render: =>

      if @state.unseen_count == 0
        button_text =
          <span><i className="fa fa-bell-o" aria-hidden="true"></i></span>
      else
        button_text =
          <span>
              <i className="fa fa-bell" aria-hidden="true">
                  &nbsp;{ @state.unseen_count }
              </i>
          </span>


      notifications =
          <span>
              <div className="dropdown dropleft headerLogin"
                  onClick={@hideNotifications}>
                <button className="btn btn-link btn-link-header"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                    <span className={ @state.notif_color }>{button_text}</span>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {@state.msgs.map((msg) =>
                      <HeaderNotificationRoll key={msg.id}
                          notification={msg}
                          caller={'bootstrap_dropdown'} />
                    )}
                    <a className="dropdown-item">
                        <Link to={'/notifications/' + @props.userid}>
                            See more notifications...
                        </Link>
                    </a>
                </div>
              </div>
          </span>

      <span>
          { notifications }
      </span>
