
module.exports = class ConferenceSortTitlesNav extends React.Component
  displayName: 'ConferenceSortTitlesNav'


  setSortTypeAll: () =>
      @props.getThisSortType('all')

  setSortTypeUserSub: () =>
      @props.getThisSortType('user_submissions')

  setSortTypeUserBookMs: () =>
      @props.getThisSortType('user_bookmarks')




  render: =>

      if @props.subset is 'all'
          all_btn =
              <div className={@props.styling}>
                  <button className='nav-item-button'>
                      <strong>all titles</strong>
                  </button>
              </div>
      else
          all_btn =
              <div className={@props.styling}>
                  <button className='nav-item-button'
                          onClick={@setSortTypeAll}>
                      all titles
                  </button>
              </div>


      if @props.subset is 'user_submissions'
          my_submissions_btn =
              <div className={@props.styling}>
                  <button className='nav-item-button'>
                      <strong>my submissions</strong>
                  </button>
              </div>
      else
          my_submissions_btn =
              <div className={@props.styling}>
                  <button className='nav-item-button'
                          onClick={@setSortTypeUserSub}>
                      my submissions
                  </button>
              </div>

      if @props.subset is 'user_bookmarks'
          my_bookmarks_btn =
              <div className={@props.styling}>
                  <button className='nav-item-button'>
                      <strong>my bookmarks</strong>
                  </button>
              </div>
      else
          my_bookmarks_btn =
              <div className={@props.styling}>
                  <button className='nav-item-button'
                          onClick={@setSortTypeUserBookMs}>
                      my bookmarks
                  </button>
              </div>

      <div className='container-fluid'>
          { all_btn }
          { my_submissions_btn }
          { my_bookmarks_btn }
      </div>
