Link = require('react-router-dom').Link
common = require('../common')
AuthorNameRoll = require('../authorcontact/author_name_roll')
TagNoteRoll = require('../tags/tag_note_roll')
TitleLinkSmall = require('../titles/title_link_small')

module.exports = class ConferenceNoteAddBox extends React.Component
  displayName: 'ConferenceNoteAddBox'

  constructor: (props) ->
      super(props)
      @state = {
        mode : 'not selected'
        local_note : @props.note
        total_reviewers : [ ]
      }

  componentWillReceiveProps: (nextProps) =>
      if @props.note in nextProps.notelist
          @setState({ mode : 'selected' })
      else
          @setState({ mode : 'not selected' })

  selectedNoteId: () =>
      @props.onClick

  render: =>
      if @state.mode is 'not selected'
        divBG = 'review_mini_note_infoDiv'
        if @props.note.reviewers.length > 0
          reviewer_roll =
              <span>
                {@props.note.reviewers.map((reviewer) =>
                        <AuthorNameRoll key={reviewer.user_id} authorcontact={reviewer} />
                    )}
              </span>
        btn_txt = 'add'
        btn_bg = 'btn btn-dark btn-sm'
      else
        divBG = 'review_mini_note_infoDiv_selected'
        btn_txt = 'added'
        btn_bg = 'btn btn-success btn-sm'

      if @props.note.areachairs.length > 0
          areachair_roll =
              <span>
                  {@props.note.areachairs.map((areachair) =>
                      <AuthorNameRoll key={areachair.user_id} authorcontact={areachair} />
                  )}
              </span>
      else
          areachair_roll = <strong></strong>


      <div className={ divBG }>
        <div className='review_note_Div'>
            <Link to={ '/conference/' + @props.note.project_id + '/title/' + @props.note.paperseries_id }>
                <TitleLinkSmall title={@props.note.title}
                                editor={@props.note.editor} />
            </Link>
            <br />
            <strong>Tags: </strong>
            {@props.note.tags.map((tag) =>
                <TagNoteRoll key={tag.id} tag={tag} note_type='conference' />
            )}
            <br />
            <strong> Authors: </strong>
              {@props.note.authorcontacts.map((authorcontact) =>
                  <AuthorNameRoll key={authorcontact.id} authorcontact={authorcontact} />
              )}
            <br />
            <strong>Reviewers:</strong>{ reviewer_roll }
            <br />
            <strong>Area Chairs:</strong>
            { areachair_roll }
            <button className={ btn_bg }
                    onClick={ @selectedNoteId() }>{ btn_txt }</button>
        </div>
      </div>
