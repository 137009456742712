common = require('../../common')
Link = require('react-router-dom').Link
DetailsTools = require('./details_tools')
Header = require('../header')
NavGeneral = require('../../nav_general')
PermsTools = require('./perms_tools')
SessionsTools = require('./sessions_tools')
DeleteLabProject = require('./delete_lab_project')
EmailInvites = require('../../conference_tools/email_invites')
HeaderBar = require('../../header_bar')
HelmRoleMakerButton = require('../../roles/helm_rolemaker_button')
HelmSurveyButton = require('../../survey/helm_survey_button')
HelmSurveyTools = require('../../survey/helm_survey_tools')
Loading = require('../../loading')
Roster = require('./roster')
ToolchestDetailsButton = require('./toolchest_details_button')
ToolchestCreateOrDeleteButton = require('./toolchest_create_or_delete_button')
ToolchestPermsButton = require('./toolchest_perms_button')
ToolchestSessionsButton = require('./toolchest_sessions_button')

module.exports = class ToolChest extends React.Component
  displayName: 'ToolChest'

  constructor: (props) ->
      super(props)
      projectid = @props.match.params.projectId

      @state = {
          num_notes_in_conference: 0
          notes: []
          projectid : projectid
          project : { }
          userid : ''
          follow_btn_txt : 'follow?'
          userid : ''
          roles : []
          roleswords : []
          rolestring : ''
          my_accepted_roles : []
          wordlist: []
          mode : 'loading'
          data: { logged_in_user: 'anonymous' }
          login_id: ''

          registration_deadline: ''
          submission_deadline: ''
          review_deadline: ''
          start_date: ''
          end_date: ''

          show_this: 'false'
          instructions: ''
          roles_error: ''
          tool_selected: ''

          header_mode: ''
          ptype: ''
      }

  componentDidMount: =>
      data =
          projectid: @state.projectid

      common.getConferenceAndRolesForUser(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid_conference' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })

          else

              my_accepted_roles = [ ]
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              if not (result['project']['surveyable_id'])
                  result['project']['surveyable_id'] = ''

              loaded_wordlist = [ ]
              if result.project.perms
                for perm in result.project.perms
                    loaded_wordlist.push(perm.word)

              ptype = result['project']['ptype'].replace('_',' ')
              if result['project']['ptype'] == 'personal'
                  ptype = 'project'

              @setState({
                  projectid: result.project.id,
                  project: result['project'],
                  userid: result['userid'],
                  roles: result['roles'],
                  my_accepted_roles : my_accepted_roles,
                  wordlist: loaded_wordlist,
                  submission_deadline: result['project']['submission_deadline'],
                  start_date: result['project']['start_date'],
                  end_date: result['project']['end_date'],
                  review_deadline: result['project']['review_deadline'],
                  registration_deadline: result['project']['registration_deadline'],
                  ptype: ptype })

              if result['project']
                  document.title = result['project']['title']
              else
                  document.title = 'ZeroDivZero'

              if result.userid
                  @setState({ mode: 'helm' }, @scrollWindow.bind(this, 'active_area'))
              else
                  # window.location = '/login/?' + window.location.href
                  @setState({ mode: 'not_logged_in' })
      )

      common.getTotalNumberNotesInProject(data, (result) =>
        @setState({ num_notes_in_conference: result.total})
      )

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()

  scrollWindow: (ref_location) =>
      elem = @refs[ref_location]
      if (elem)
          elem.scrollIntoView()

  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  setUpdatedTags: (updated_tags) =>
      updated_project = @state.project
      updated_project.tags = updated_tags
      @setState({ project: updated_project })
      return

  setUpdatedPerms: (updated_perms) =>
      updated_wordlist = [ ]
      for perm in updated_perms
          updated_wordlist.push(perm.word)

      updated_project = @state.project
      updated_project.perms = updated_perms

      @setState({
          wordlist: updated_wordlist
          project:  updated_project })
      return

  setUpdatedDetails: (deets) =>
      updated_project = @state.project
      updated_project.title = deets.title
      updated_project.motive = deets.motive
      updated_project.editor = deets.editor
      @setState({ project: updated_project })

  toggleRolesError: =>
      if @state.roles_error is ''
          @setState({
              show_this: 'false'
              roles_error: 'You don\'t have the correct role(s) to use this tool.'
              instructions: ''}, @scrollWindow.bind(this, 'toolpanel'))

  toggleToolPanel: (option) =>
      if option.tools is 'roles_error'
          @toggleRolesError()
          return
      if @state.show_this is option.show_this
        # toggle panel closed
        @setState({ show_this: 'false', tool_selected: '' }, @scrollWindow.bind(this, 'toolpanel'))
      else
        # toggle panel open
        @setState({
            show_this: option.show_this
            instructions: ''
            roles_error: ''
            tool_selected: option.tools }, @scrollWindow.bind(this, 'toolpanel'))

  setMode: (mode_option) =>
      if mode_option is 'viewing'
          window.location = '/lab_group/' + @state.project.id + '/titles'
      else if mode_option is 'cancelled'
          @setState({ mode: 'helm' })
      else
          @setState({ mode: mode_option })

  render: =>

    details_button =
        <ToolchestDetailsButton
            my_accepted_roles={ @state.my_accepted_roles }
            tool_selected={ @state.tool_selected }
            onClick={@toggleToolPanel} />

    create_or_delete_button =
        <ToolchestCreateOrDeleteButton
            my_accepted_roles={ @state.my_accepted_roles }
            tool_selected={ @state.tool_selected }
            onClick={@toggleToolPanel} />

    survey_button =
        <HelmSurveyButton
             my_accepted_roles={ @state.my_accepted_roles }
             tool_selected={ @state.tool_selected }
             onClick={@toggleToolPanel} />

    rolemaker_button =
        <HelmRoleMakerButton
            my_accepted_roles={ @state.my_accepted_roles }
            tool_selected={ @state.tool_selected }
            onClick={@toggleToolPanel} />

    sessions_button =
        <ToolchestSessionsButton
            my_accepted_roles={ @state.my_accepted_roles }
            tool_selected={ @state.tool_selected }
            ptype={@state.ptype}
            onClick={@toggleToolPanel} />

    perms_button =
        <ToolchestPermsButton
            my_accepted_roles={ @state.my_accepted_roles }
            tool_selected={ @state.tool_selected }
            ptype={@state.ptype}
            onClick={@toggleToolPanel} />

    if @state.show_this is 'show_dates'
        tool_panel =
            <span>
                <strong>Important Dates</strong>
                <ul>
                  <li>
                    <SetConfDate type={'submission_deadline'}
                                 datetime={@state.submission_deadline}
                                 confid={@state.project.id} />
                  </li>
                  <li>
                    <SetConfDate type={'review_deadline'}
                                 datetime={@state.review_deadline}
                                 confid={@state.project.id} />
                  </li>
                  <li>
                    <SetConfDate type={'registration_deadline'}
                                 datetime={@state.registration_deadline}
                                 confid={@state.project.id} />
                  </li>
                  <li>
                    <SetConfDate type={'start_date'}
                                 datetime={@state.start_date}
                                 confid={@state.project.id} />
                  </li>
                  <li>
                    <SetConfDate type={'end_date'}
                                 datetime={@state.end_date}
                                 confid={@state.project.id} />
                  </li>
                </ul>
            </span>

    else if @state.show_this is 'rolemaker'
        tool_panel =
            <span>
                <strong>Invite people!</strong>
                <p className="instructions">
                  ZeroDivZero uses email addresses to find users on the website and send
                  them notifications about your conference. We <b>do not</b> send out actual emails.
                  Please send out emails to participants yourself! Thanks.
                </p>
                <ul>
                    <li>
                        <EmailInvites key={'invited'}
                            project={@state.project}
                            roletype={'invited'}
                            upload_content={'email_invites'} />
                    </li>
                    <li>
                        <EmailInvites key={'chair'}
                            project={@state.project}
                            roletype={'lab_lead'}
                            upload_content={'email_invites'} />
                    </li>
                  <li><Roster project={@state.project} /></li>
                </ul>
            </span>

    else if (@state.show_this is 'survey_take_only') or (@state.show_this is 'survey_make_or_take')
        tool_panel =
              <HelmSurveyTools
                  show_this= { @state.show_this }
                  project= { @state.project }
                  userid= { @state.userid}
                  onClick={@toggleToolPanel} />

    else if (@state.show_this is 'sessions_tools')
        tool_panel =
              <SessionsTools
                  show_this={ @state.show_this }
                  project={ @state.project }
                  tags={ @state.project.tags }
                  userid={ @state.userid }
                  setUpdatedTags={ @setUpdatedTags }
                  onClick={@toggleToolPanel}
                  ptype={@state.ptype} />

    else if (@state.show_this is 'perms_tools')
        tool_panel =
              <PermsTools
                  show_this={ @state.show_this }
                  projectid={ @state.project.id }
                  wordlist={ @state.wordlist }
                  userid={ @state.userid }
                  setUpdatedPerms={ @setUpdatedPerms }
                  onClick={@toggleToolPanel}
                  ptype={@state.ptype} />

    else if (@state.show_this is 'details_tools')
        tool_panel =
              <DetailsTools
                  show_this={ @state.show_this }
                  project={ @state.project }
                  my_accepted_roles={ @state.my_accepted_roles }
                  setUpdatedDetails={ @setUpdatedDetails }
                  onClick={@toggleToolPanel} />

    else if (@state.show_this is 'create_delete_tools')
        tool_panel =
              <DeleteLabProject
                  show_this={ @state.show_this }
                  project={ @state.project }
                  userid={ @state.userid }
                  onClick={@toggleToolPanel} />

    conf_nav_vert =
        <div className='col-md-2 col-lg-2 border d-none d-md-block'>
            <NavGeneral key={@state.project.id}
                id={@state.project.id}
                ptype={@state.project.ptype}
                caller={'tools'}
                userid={@state.userid}
                styling={'col-12'} />
        </div>
    conf_nav_horiz =
        <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
            <NavGeneral key={@state.project.id}
                id={@state.project.id}
                project={@state.project}
                ptype={@state.project.ptype}
                caller={'tools'}
                userid={@state.userid}
                styling={'mr-2'} />
        </div>

    if @state.mode is 'invalid_conference'
        problem_msg =
            <div>
                <br />
                <h2>We cannot find that {@state.ptype}... Try refreshing!</h2>
                If you continue to get this error, then maybe the {@state.ptype} number is wrong?
            </div>
    else if @state.mode is 'conference_deleted'
        problem_msg =
            <div>
                <br />
                <h2>Uh oh... It appears the owner has deleted this {@state.ptype}.</h2>
            </div>

    intro_panel =
        <div className="text-center pt-3 pb-3">
            <strong>
            Here are all the {@state.ptype} tools!
            </strong>
            <br />
            You may use these tools, based on your particular roles.
        </div>

    tools_header =
        <span>
            <Header project={@state.project}
                    userid={@state.userid}
                    roles={@state.roles}
                    wordlist={@state.wordlist}
                    header_mode={@state.header_mode} />
        </span>


    if @state.mode is 'loading'
        <div>
            <HeaderBar />
            <div className='container-fluid pr-0 pl-0 border'>
                {tools_header}
                <div className='row no-gutters'>
                    {conf_nav_vert}
                    {conf_nav_horiz}
                    <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                        <br />
                        <Loading />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    else if (@state.mode is 'helm') and (@state.roles)
        <div>
            <HeaderBar />
            <div className='container-fluid pr-0 pl-0 border'>
                {tools_header}
                <div className='row no-gutters'>
                    {conf_nav_vert}
                    {conf_nav_horiz}
                    <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>


                        <div className='proj_actions_Div'
                             ref='active_area'>
                            <div className='p-0 m-0 b-0'>
                                {@state.num_notes_in_conference} titles submitted!
                            </div>
                        </div>
                        { intro_panel }
                        <div className='proj_header bg-info p-3'>
                            <div className="card-deck mb-3">
                                { details_button }
                                { rolemaker_button }
                                { sessions_button }
                            </div>
                            <div className="card-deck mb-3">
                                { perms_button }
                                { survey_button }
                                { create_or_delete_button }
                            </div>
                        </div>
                        <div ref='toolpanel'
                            className='container-fluid no-borders proj_listing'>
                            { @state.instructions }
                            { tool_panel }
                            { @state.roles_error }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    else if (@state.mode is 'not_logged_in')
        <div>
            <HeaderBar />
            <div className='container-fluid pr-0 pl-0 border'>
                {tools_header}
                <div className='row no-gutters'>
                    {conf_nav_vert}
                    {conf_nav_horiz}
                    <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                        <div className='row bigger_em m-3'>
                            Are you logged in?
                        </div>
                        <div className='row m-3'>
                            To use the tools, you need to log in (log in buttons
                            are upper right).
                            And have a role in this {@state.ptype}.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    else
        <div className='container-fluid no-borders'>
            <HeaderBar />
            { problem_msg }
        </div>
