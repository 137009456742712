Link = require('react-router-dom').Link
common = require('../common')
Title = require('../titles/title')
ShowDate = require('../schedule/show_date')

module.exports = class FrontPageBubbleRoll extends React.Component
  displayName: 'FrontPageBubbleRoll'

  constructor: (props) ->
      super(props)
      project_link = '/' + @props.project.ptype + '/' + @props.project.id + '/titles'
      if @props.project.ptype is 'personal'
          project_link = '/project/' + @props.project.id + '/titles'
      @state = {
          project_link: project_link
          button_brand: ''
      }

  newlyUpdatedBadge: () =>
      millis = Date.now()
      current_time = Math.floor(millis / 1000)
      one_month_in_seconds = 2592000
      one_month_ago = current_time - one_month_in_seconds
      is_updated = ''
      is_new = ''
      if @props.project.submissions_updated_at > one_month_ago
          is_updated = 'Updated'
      if @props.project.created_at > one_month_ago
          is_new = 'New '
          if @props.project.submissions_updated_at > one_month_ago
            is_new = 'New, '
      return (
          <span className='text-success'>{is_new}{is_updated}</span>
      )

  componentDidMount: =>
      # TODO: get logo

      today = Number(new Date())
      deadline = Number(@props.project.submission_deadline*1000)

      past_deadline = false
      if (deadline > 0) and (today > deadline)
          past_deadline = true

      open_submission = false
      if '_open_submission_perm' in @props.project.perms
          open_submission = true

      is_conference = false
      if (@props.project.ptype is 'conference')
          is_conference = true

      invited = false
      if @props.project.user_invited is 'true'
          invited = true

      button_brand = ''
      if (is_conference) and (!past_deadline)
          if (open_submission)
              button_brand = 'please_submit'
          else if (!open_submission) and (invited)
              button_brand = 'please_submit'
          else
              # !open_submission and !invited
              button_brand = 'invitation_needed'
      @setState({ button_brand: button_brand })

  startSubmission: =>
      submission_link = '/' + @props.project.ptype + '/' + @props.project.id + '/titles?submit'
      if @props.logged_in is false
          window.location = '/login?' + submission_link
      else
          window.location = submission_link

  submissionButton: () =>
      if @state.button_brand is 'please_submit'
          return (
              <div className='row'>
                  <button className='btn btn-primary'
                      onClick={@startSubmission}>
                      submit
                  </button>
              </div>
          )
      else if @state.button_brand is 'invitation_needed'
          return (
              <div className='row'>
                  <button className='btn btn-secondary disabled'>
                      invite-only submission
                  </button>
              </div>
          )

  imgBubble: () =>
      if (@props.project.id is 'e82ff46dded74343ac5d723a7a71b45d') or (@props.project.id is '8abad05a9ce341d8a49b72944dacb53a') or (@props.project.id is '1f72de3e1c2c4cd895133527f693145c')
          return (
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                  <Link to={@state.project_link}>
                      <img className=''
                          src='../../images/other_logos/apslogo_100px.png'
                          alt='conf_logo'></img>
                  </Link>
              </div>
          )
      else if (@props.project.id is '825ab417af0a43cfa45802c78d2302bb') or (@props.project.id is 'b016c4c06fba40f9bb6eb4b5ff756ca2')
          return (
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                  <Link to={@state.project_link}>
                      <img className=''
                          src='../../images/other_logos/bowyer_fractal_round_100px.png'
                          alt='proj_logo'></img>
                  </Link>
              </div>
          )
      else if (@props.project.id is 'c6eec80404c14fae90c08c2370c0e697')
          return (
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                  <Link to={@state.project_link}>
                      <img className=''
                          src='../../images/other_logos/stars_round_100px.png'
                          alt='proj_logo'></img>
                  </Link>
              </div>
          )
      else if (@props.project.id is '388056bf599349e5a1ac46bf484e0501') or (@props.project.id is '4551b692a9194cabb8a331434d6f3f04')
          return (
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                  <Link to={@state.project_link}>
                      <img className=''
                          src='../../images/other_logos/caltech_history_underrep-100px.png'
                          alt='proj_logo'></img>
                  </Link>
              </div>
          )
      else if (@props.project.id is 'a5d712b4f9ea4072960b7768150886b5') or (@props.project.id is '10c0d67849fb4808ac84085af1d18dc0')
          return (
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                  <Link to={@state.project_link}>
                      <img className=''
                          src='../../images/other_logos/mathad.png'
                          alt='proj_logo'></img>
                  </Link>
              </div>
          )
      else if (@props.project.id is 'f130fc0294c64cad807ff803a5a23d6c') or (@props.project.id is '29eab20aac884cb6b34bf25f7324c70d')
          return (
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                  <Link to={@state.project_link}>
                      <img className=''
                          src='../../images/other_logos/wins_100_100_inBubble.png'
                          alt='proj_logo'></img>
                  </Link>
              </div>
          )
      else if (@props.project.id is '0e2521ebae514f03bfcce4bf041225e1') or (@props.project.id is '8a8de5199b6f49b5aa18ed96d952a1d4')
          return (
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                  <Link to={@state.project_link}>
                      <img className=''
                          src='../../images/other_logos/nasa_logo.png'
                          alt='proj_logo'></img>
                  </Link>
              </div>
          )
      else if (@props.project.id is 'aaac8c66007a4d23a7aa14857a3b778c') or (@props.project.id is '5cb241339f5940f3863e6e7c5b1f8ffa')
          return (
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                  <Link to={@state.project_link}>
                      <img className=''
                          src='../../images/other_logos/netSci_100_bubble.png'
                          alt='proj_logo'></img>
                  </Link>
              </div>
          )
      else if (@props.project.id is 'eec4a1dbbc0e403996892d2422490b1e') or (@props.project.id is '43867382f42348059c58053816931479')
          return (
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                  <Link to={@state.project_link}>
                      <img className=''
                          src='../../images/other_logos/TTU_astronight_logo.png'
                          alt='proj_logo'></img>
                  </Link>
              </div>
          )
      else if (@props.project.id is '85f850bf03e340ed95587656a7786a3a') or (@props.project.id is '72323fbb6164485db29aefb8e7964b8b')
          return (
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                  <Link to={@state.project_link}>
                      <img className=''
                          src='../../images/other_logos/short_course_topology_APS.png'
                          alt='proj_logo'></img>
                  </Link>
              </div>
          )
      else
          return (
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                  <Link to={@state.project_link}>
                      <img className=''
                          src='../../images/other_logos/empty-frame-100px.png'
                          alt='conf_logo'></img>
                  </Link>
              </div>
          )

  render: =>

      #if (@props.project.ptype is 'conference') and (@state.past_deadline is false)
      #        submission_info =
      #            <div className='row .pointnine_em'>
      #                <span className='mr-2'>Submit by:</span>
      #                <ShowDate key='submission_deadline' datetime={@props.project.submission_deadline} />
      #            </div>

      <div className='row mr-3 mt-3 mb-3 pt-3 pr-3 pb-3 border-bottom'>

          { @imgBubble() }

          <div className='col-xs-8 col-sm-8 col-md-8 col-lg-8'>
              <div className='row'>
                  { @newlyUpdatedBadge() }
              </div>
              <div className='row'>
                  <Link to={@state.project_link}>
                       <Title title={@props.project.title}
                          editor={@props.project.editor} />
                  </Link>
              </div>
              { @submissionButton() }
          </div>

      </div>
