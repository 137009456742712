common = require('./common')
ConferenceDirectUpload = require('./conference_direct_upload')
ConferenceHeader = require('./conference_header')
NavGeneral = require('./nav_general')
ConferenceNoteSummary = require('./conference_note_summary')
CreateNoteMetadata = require('./create_title/create_note_metadata')
HeaderBar = require('./header_bar')
Loading = require('./loading')
Pagination = require('./pagination')
RolesBarConf = require('./roles/roles_bar_conf')

module.exports = class ConferenceNotes extends React.Component
  displayName: 'ConferenceNotes'

  constructor: (props) ->
      super(props)
      projectid = @props.match.params.projectId
      @state = {
          projectid: projectid
          project: { }
          notes: []
          mode: 'loading'
          note: {title: '', abstract: '', motive: ''}
          userid: ''
          roles: []
          rolestring: ''
          my_accepted_roles: []
          wordlist: []
          notes_length: 0

          page_num: 1
          lengthy: 2
          num_per_page: 10

          subset: 'all'

          new_note: {}
      }
      @makeNewTitleRef = React.createRef()
      @notesMap = React.createRef()


  setUpdatedRoles: (what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  componentDidMount: =>
      data =
          page_num: 1
          num_per_page: @state.num_per_page
          projectid: @state.projectid
          subset: 'all'

      common.retrieveSubsetConferenceNotes(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid_conference' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })
          else
              my_accepted_roles = [ ]
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              project = result['project']
              loaded_wordlist = []
              if project.perms
                for perm in project.perms
                    loaded_wordlist.push(perm.word)

              @setState({
                  project: project,
                  notes: result['notes'],
                  userid: result['login_id'],
                  roles: result['roles'],
                  my_accepted_roles: my_accepted_roles,
                  wordlist: loaded_wordlist,
                  mode: 'viewing'
              })

              document.title = result['project']['title']

      )

      common.getTotalNumberNotesForConferenceSubset(data, (result) =>
        lengthy = Math.ceil(result.total/@state.num_per_page)
        @setState({lengthy: lengthy, notes_length: result.total})
      )

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  getThisSortType: (new_subset) =>
      @setState({subset: new_subset}, @getThesePages)

  getThesePages: (info) =>
      if not (info)
          info=1

      if not (@state.subset is 'all') and not (@state.userid)
          @setState({notes: []})
          return

      data =
          page_num: info
          num_per_page: @state.num_per_page
          projectid: @props.match.params.projectId
          subset: @state.subset

      common.retrieveSubsetConferenceNotes(data, (result) =>
          console.log("result=",result)
          if result['error'] is 'conference not found'
              @setState({mode : 'invalid project'})
          else if result['error'] is 'conference deleted'
              @setState({mode : 'conference_deleted'})
          else
            @setState(
              {notes: result['notes'], page_num: info},
              @scrollToHere(@notesMap))

          # elem = @notesMap.current
          # if (elem)
          #   window.scrollTo(0, elem.offsetTop)
      )

      common.getTotalNumberNotesForConferenceSubset(data, (result) =>
        lengthy = Math.ceil(result.total/@state.num_per_page)
        @setState({lengthy: lengthy, notes_length: result.total})
      )

  getUserNoteDetails: =>
      @setState({
          mode : 'create_note_metadata'}, @scrollToHere(@makeNewTitleRef) )

  setMode: (info) =>
      # in this case, new_note might be returned!
      if info is 'cancelled'
          @setState({ mode: 'viewing' })
      else if info.new_note
          @setState({
              new_note: info.new_note
              mode: 'direct_upload_submisson' })
      else
          @setState({ mode: info })


  makeNewPaper: () =>
      if @state.mode is 'create_note_metadata'
          <div ref={@makeNewTitleRef}>
              <CreateNoteMetadata project={@state.project}
                  setMode={@setMode}
                  version={'new'} />
          </div>
      else if @state.mode is 'direct_upload_submisson'
          <div>
              You have created a personal project and added a title.
              Just a few more choices to finish submission.
              <ConferenceDirectUpload
                  note={@state.new_note}
                  conference={@state.project} />
          </div>

  paperList: () =>
      if @state.mode is 'loading'
          <div>
              <div className='proj_listing'>
                <div className='note_noPapers'>
                  <br />
                  <Loading />
                  <br />
                  <br />
                </div>
              </div>
          </div>
      else if @state.mode is 'viewing'
          if @state.notes.length>0
              <div>
                  <span ref={@notesMap}>
                      {@state.notes.map((paper) =>
                          <ConferenceNoteSummary key={paper.id}
                          note={paper}
                          project={@state.project}
                          roles={@state.roles}
                          login_id={@state.userid}/>
                      ,this)}
                  </span>
                  <Pagination page_num={@state.page_num}
                      lengthy={@state.lengthy}
                      onClick={@getThesePages} />
              </div>
          else
              <div>
                  <div className='proj_listing'
                      ref={@notesMap}>
                    <div className='note_noPapers'>
                      <br />
                      <i>Nothing here.... yet....</i>
                      <br />
                      <br />
                    </div>
                  </div>
              </div>

  problemMsg: () =>
      if @state.mode is 'invalid_conference'
              <div>
                  <br />
                  <h2>We cannot find that conference... Try refreshing!</h2>
                  If you continue to get this error, then maybe the url is wrong?
              </div>
      else if @state.mode is 'conference_deleted'
              <div>
                  <br />
                  <h2>Uh oh... It appears the owner has deleted this conference.</h2>
              </div>

  rolesBar: () =>
      if @state.roles
          <div className='row no-gutters border'>
              <span className="ml-0 pl-2">
                {@state.notes_length} submissions
                <RolesBarConf project={@state.project}
                    my_accepted_roles={@state.my_accepted_roles}
                    roles={@state.roles}
                    userid={@state.userid}
                    view={'full'}
                    setUpdatedRoles={@setUpdatedRoles}
                    getUserNoteDetails={@getUserNoteDetails} />
              </span>
          </div>

  conferenceHeader: () =>
      if (@state.mode != 'invalid_conference') and (@state.mode != 'conference_deleted')
          <span>
              <ConferenceHeader
                  project={@state.project}
                  userid={@state.userid} />
          </span>


  render: =>

      conf_nav_vert =
          <div className='col-md-2 col-lg-2 border d-none d-md-block'>
              <NavGeneral
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'titles'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'col-12'} />
          </div>
      conf_nav_horiz =
          <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
              <NavGeneral
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'titles'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'mr-2'} />
          </div>


      <div>
          <HeaderBar />
          <div className='container-fluid pr-0 pl-0 border'>
              { @conferenceHeader() }
              <div className='row no-gutters'>
                  {conf_nav_vert}
                  {conf_nav_horiz}
                  <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                      { @rolesBar() }
                      { @problemMsg() }
                      { @paperList() }
                      { @makeNewPaper() }
                  </div>
              </div>
          </div>
      </div>
