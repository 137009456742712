AuthorNameRoll = require('../authorcontact/author_name_roll')
ConferenceProgramNoteSummary = require('../conference_program_note_summary')
ShowDate = require('./show_date')
ShowPlace = require('./show_place')
TagNoteRoll = require('../tags/tag_note_roll')
TitleLinkSmall = require('../titles/title_link_small')

module.exports = class NoteTimePlaceRoll extends React.Component
  displayName: 'NoteTimePlaceRoll'

  constructor: (props) ->
      super(props)
      # expects props of:
      # notes
      # project
      # roles
      # login_id
      @state = {
          mode: 'mini_view'
      }

  toggleView: () =>
      if @state.mode is 'mini_view'
          @setState({ mode: 'detail_view' })
      else
          @setState({ mode: 'mini_view' })

  sessionsCol: () =>
      if @props.show_sessions is 'true'
        return(
            <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                {@props.note.conference_tags.map((tag, pos) =>
                    if pos > 0
                        comma=<span>,&nbsp;</span>
                    <TagNoteRoll key={tag.id}
                                 tag={tag}
                                 tag_type={'conference'}
                                 note_type={@props.note.note_type}
                                 projectid={ @props.note.project_id }
                                 link_type={'no_link'}
                                 comma={comma}  />
                )}
            </div>
        )

  render: =>

      if (@props.note) and (@state.mode is 'mini_view')
          <div className='row border-bottom'>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                  <ShowDate key={ @props.note.clock.id }
                            datetime={ @props.note.clock.debut }
                            duration={ @props.note.clock.duration }
                            timezone={ @props.note.clock.timezone }
                            gmt_offset={ @props.note.clock.gmt_offset }
                            formatting={'rows'} />
              </div>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                  <ShowPlace key={ @props.note.place_id }
                            name={ @props.note.place.name }
                            lat={ @props.note.place.lat }
                            lon={ @props.note.place.lon } />
              </div>
              { @sessionsCol() }
              <div className='col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                  <TitleLinkSmall
                      title={@props.note.title}
                      editor={@props.note.editor}
                      onClick={@toggleView.bind(this)} />
              </div>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                  {@props.note.authorcontacts.map((authorcontact, pos) =>
                      if pos > 0
                          comma=<span>,&nbsp;</span>
                      <AuthorNameRoll key={authorcontact.id}
                          authorcontact={authorcontact}
                          link_type={'no_link'}
                          comma={comma} />
                  )}
              </div>
          </div>
      else if (@props.note) and (@state.mode is 'detail_view')
          <div className='row border-bottom'>
              <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  <button className='btn btn-danger'
                      onClick={@toggleView}>
                      Hide
                  </button>
              </div>
              <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                  <ConferenceProgramNoteSummary
                      note={@props.note}
                      project={@props.project}
                      roles={@props.roles}
                      login_id={@props.userid}/>
              </div>
          </div>
      else
          <span></span>
