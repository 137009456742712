
module.exports = class HelmSurveyButton extends React.Component
  displayName: 'HelmSurveyButton'

  constructor: (props) ->
      super(props)
      # Expects: @props.
      # my_accepted_roles
      @state = {

      }

  render: =>

      if @props.tool_selected is 'surveys'
          card_className = 'card light_green'
      else
          card_className = 'card light_gray'

      if ('creator' in @props.my_accepted_roles) or ('chair' in @props.my_accepted_roles)
          <div className={card_className}>
            <div className="card-body">
                <h2 className="card-title">
                    <button className='btn btn-primary'
                            onClick={ @props.onClick.bind(this, {tools: 'surveys', show_this: 'survey_make_or_take'} ) }>
                        survey tools
                    </button>
                </h2>
                <p className="card-text">
                  Create or complete a survey.
                </p>
            </div>
          </div>
      else 
          <div className={card_className}>
            <div className="card-body">
                <h2 className="card-title">
                    <button className='btn btn-primary'
                            onClick={ @props.onClick.bind(this, {tools: 'surveys', show_this: 'survey_take_only'} ) }>
                        survey tools
                    </button>
                </h2>
                <p className="card-text">
                  Complete the survey.
                </p>
            </div>
          </div>
