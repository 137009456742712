Link = require('react-router-dom').Link

module.exports = class PaperRollEditable extends React.Component
  displayName: 'PaperRollEditable'

  constructor: (props) ->
      super(props)
      @state = {
        noteid : @props.noteid
        note: @props.note
        mode: 'display name'
      }

  removeNoteJobJunction: =>
      @props.onClick(@props.note)

  render: =>

      if @props.note.title is 'Removed!'
          <div className='authornameroll_div'>Removed!</div>
      else
          <div className='paperlist_buttons'>
            <div className="btn-group"
                 role="group"
                 aria-label="delete paper">
                <button className="btn btn-primary btn-txt-sm">
                        {@props.note.title}&nbsp;</button>
                <button className="btn btn-danger btn-txt-sm" onClick={@removeNoteJobJunction}>&nbsp;x&nbsp;</button>
            </div>
          </div>
