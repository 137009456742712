Link = require('react-router-dom').Link
common = require('../common')
AuthorProfileNameRoll = require('../authorcontact/author_profile_name_roll')
AuthorUserNameRoll = require('../authorcontact/author_user_name_roll')

module.exports = class NameTag extends React.Component
  displayName: 'NameTag'

  constructor: (props) ->
    super(props)
    # expects props: registered, userid, projectid
    @state = {
      authorcontacts: ''
      nametag: ''
      nametag: {authorcontacts: ''}
      mode: 'show_badge'
    }

  componentDidMount: =>
      if @props.userid?
          data =
              userid: @props.userid
              projectid: @props.projectid

          common.getUserNameTag(data, (result) =>
              if (result) and (result.nametag)
                  @setState({
                      nametag: result.nametag,
                      mode: 'show_badge' })
              else
                  @setState({ mode: 'get_authorcontacts' }))

          data =
              userid: @props.userid
              verified: 'true'
              complete: 'true'

          common.getUserAuthorContactList(data, (result) =>
              @setState({
                  authorcontacts : result.authorcontacts}))

          if @props.registered is 'true'
              elem = @refs.nametags_section
              if (elem)
                  window.scrollTo(0, elem.offsetTop)

  chosenExistingAuthorContact: (id) =>
      data =
          authorcontactid : id
          projectid: @props.projectid

      common.saveNameTagAuthorContact(data, (result) =>
          if (result)
              @setState({
                  nametag: result.nametag,
                  mode: 'show_badge' })
          )

  chooseNewNameTag: =>
      @setState({ mode: 'get_authorcontacts' })

  openProfilePageinNewWindow: () =>
      window.open('/profile/' + @props.userid)

  reloadPage: () =>
      location.reload()

  render: =>
      if (@state.authorcontacts) and (@state.authorcontacts.length > 0)
          author_roll =
              <div>
                {@state.authorcontacts.map((authorcontact) =>
                    <AuthorUserNameRoll key={authorcontact.id}
                                        authorcontact={authorcontact}
                                        onClick={@chosenExistingAuthorContact.bind(this,authorcontact.id)}/>
                ,this)}
              </div>
      else
          author_roll =
              <div className='text-danger'>
                  <br />
                  <p>
                    Hm. It looks like you don't have a complete, verified
                    author record.
                  </p>
                  <ul>
                    <li>
                    First, let's make one on your &nbsp;
                    <button className='btn btn-primary btn-txt-sm'
                            onClick={@openProfilePageinNewWindow}>
                        profile page
                    </button>
                    </li>
                    <li>
                    Then come back here and
                    <button className='btn btn-primary btn-txt-sm ml-2 mr-2'
                            onClick={@reloadPage}>Reload</button>
                    to finish choosing your badge!
                    </li>
                  </ul>
              </div>

      if (@state.nametag.authorcontacts) and (@state.nametag.authorcontacts.length > 0)
          author_nametag_roll =
              <div>
                  {@state.nametag.authorcontacts.map((authorcontact) =>
                      <AuthorProfileNameRoll key={authorcontact.id}
                                             authorcontact={authorcontact}
                                             current_userid={ @props.userid }/>
                  ,this)}
              </div>

      edit_button =
          <button className="btn btn-primary btn-txt-sm"
                  onClick={@chooseNewNameTag}>Edit</button>

      if @state.mode is 'show_badge'
          show_badge_or_ask_to_choose =
              <div>
                  { author_nametag_roll }
                  { edit_button }
              </div>
      else if @state.mode is 'get_authorcontacts'
          show_badge_or_ask_to_choose =
              <div className='text-danger'>
                  Which author record would you like to appear on your badge?
                  { author_roll }
              </div>


      if @props.registered is 'true'
          <div className='proj_actions_Div'
               ref='nametags_section'>
            <div className='container-fluid no-border light-padding'>
                <div className='row no-gutters'>
                  <strong>My Conference Badge</strong>
                </div>
                { show_badge_or_ask_to_choose }
            </div>
          </div>
      else
          <span></span>
