common = require('./common')
Abstract = require('./abstract')
AuthorNameRoll = require('./authorcontact/author_name_roll')
Bookmark = require('./bookmark')
ConferenceUpdateSubmission = require('./conference_update_submission')
CreateNoteMetadata = require('./create_title/create_note_metadata')
ConferenceNoteReviews = require('./conference_tools/conference_note_reviews')
DocumentConferenceButton = require('./uploads/document_conference_button')
Loading = require('./loading')
Materials = require('./uploads/materials')
Motive = require('./motive')
NoteFullComments = require('./note_full_comments')
NoteReplications = require('./replication/note_replications')
PosterConferenceButton = require('./uploads/poster_conference_button')
ReplicationStudy = require('./replication/is_study')
SeminarScheduler = require('./schedule/seminar_scheduler')
SlidesConferenceButton = require('./uploads/slides_conference_button')
SocialSharing = require('./social/social_sharing')
# SubmittedConferenceItems = require('./submitted_conference_items')
TagNoteRoll = require('./tags/tag_note_roll')
TitleLarge = require('./titles/title_large')
VerticalVoteComponent = require('./social/vertical_vote_component')
Videos = require('./video/videos')

module.exports = class ConferenceNoteDetails extends React.Component
  displayName: 'ConferenceNoteDetails'

  constructor: (props) ->
    super(props)
    note = @props.note
    paper_submission = false
    if @props.note.body_blawb_id
        paper_submission = true
    console.log("@props=")
    console.log(@props)
    @state = {
        note: note
        data: {noteid: note.id, votevalue: note.myvote}
        mode: 'reading'
        user_can_edit: 'false'
        user_is_author: 'false'
        user_owns_project: 'false'
        user_can_schedule: 'false'
        login_id: ''
        ask_if_sure_before_withdrawing: 'false'
        msg: ''
        my_accepted_roles: ''

        show_update_options: false
        slides_submission: false
        poster_submission: false
        paper_submission: paper_submission

        modal_mode: 'first_step_choose_update_source'
        personal_note: {}
        personal_project: {}

        shareUrl : ''
        }
    @makeNewTitleRef = React.createRef()

  setFineGrainEditingPerms: () =>
      author_userids = []
      for author in @props.note.authorcontacts
          author_userids.push(author.user_id)

      # can schedule if the user is an organizer (aka chair)
      my_accepted_roles = [ ]
      for role in @props.roles
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)

      # Check first that the user is logged in!
      # Otherwise @props.login_id = null and if there's an author who does
      # not have an account, author_userids can have a null. So the non-logged
      # on user can edit
      if @props.login_id
          if (@props.login_id is @props.project.user_id)
              @setState({
                  user_can_edit: 'true',
                  user_can_schedule: 'true',
                  user_owns_project: 'true' })
          if (@props.login_id in author_userids)
              @setState({ user_can_edit: 'true' })
          if (@props.login_id is @props.note.user_id)
              @setState({ user_can_edit: 'true' })
          if 'chair' in my_accepted_roles
              @setState({
                  user_can_schedule: 'true'
                  user_can_edit: 'true' })
          @setState({ my_accepted_roles: my_accepted_roles })

  getShareUrl: () =>
        if @props.note.note_type is 'project'
            source_folder = 'project'
        else if @props.note.note_type is 'conference'
            source_folder = 'conference'
        else
            source_folder = 'lab_group'
        shareUrl = 'https://zerodivzero.com/' + source_folder + '/' + @props.note.project_id + '/title/' + @props.note.paperseries_id
        @setState({ shareUrl: shareUrl })

  componentDidMount: =>
      @setFineGrainEditingPerms()
      @getShareUrl()

  componentDidUpdate: (prevProps) =>
      if @props.login_id != prevProps.login_id
          @setFineGrainEditingPerms()
          @getShareUrl()

  toggleReadEditMode: () =>
      if @state.mode is 'editing'
        @setState({mode: 'reading'})
      else @setState({mode: 'editing'})

  askIfSureBeforeWithdrawal: () =>
      @setState({ask_if_sure_before_withdrawing: 'true'})

  deleteNote: () =>
      data =
        projectid : @props.note.project_id
        noteid : @state.note.id

      common.deleteProjectNote(data, (result) =>
          # The "?_t=..." appendage is necessary to load a non-cache version of the conference
          window.location = '/conference/'  + @props.note.project_id + '/titles' + '?_t=' + (new Date()).getTime()
      )

  visitOriginalProjectPaper: () =>
      data =
        noteid : @state.note.id

      common.getNoteOrigin(data, (result) =>
          projectid = result.projectid
          note_type = result.note_type
          if note_type is 'lab_group'
              @setState({msg: 'This is the only version'})
          else if result.err_msg
              @setState({msg: result.err_msg})
          else
              window.location = '/project/' + result.projectid + '/title/' + @props.note.paperseries_id
      )

  toggleUpdateOptions: () =>
      @setState({ show_update_options: true })

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  getUserNoteDetails: =>
      # Get the original project note and update that first before we update the
      # conference version
      data =
        noteid : @state.note.id

      common.getNoteOriginDetails(data, (result) =>
          document.getElementById("modal_close_button").click()
          @setState({
              personal_note: result.note
              personal_project: result.project
              mode : 'create_note_metadata'}, @scrollToHere(@makeNewTitleRef) )
      )

  openModal: () =>
      trigger_btn = document.getElementById("modal_open_button")
      if trigger_btn
          trigger_btn.click()

  setMode: (info) =>
      # in this case, new_note might be returned!
      if info is 'cancelled'
          @setState({ mode: 'reading' })
      else if info is 'viewing'
          @setState({
              mode: 'reading'
              modal_mode: 'step_two_choose_submission_items' }, @openModal)
      else
          @setState({ mode: info })

  callbackUpdatedNote: (updated_note )=>
      @setState({ note: updated_note })

  hasSubmitted: (item_info) =>
      if (item_info.submit_type is 'slideset') and (item_info.status is 'true')
          @setState({ slides_submission: true })
      else if (item_info.submit_type is 'slideset') and (item_info.status is 'false')
          @setState({ slides_submission: false })
      else if (item_info.submit_type is 'poster') and (item_info.status is 'true')
          @setState({ poster_submission: true })
      else if (item_info.submit_type is 'poster') and (item_info.status is 'false')
          @setState({ poster_submission: false })

  replicationsBar: =>
      if (@props.project.ptype is 'short_course') or (@props.project.ptype is 'seminar')
          <span></span>
      else
          <div className='row no-gutters'>
            <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
            <strong>Replications</strong>
            </div>
            <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                <NoteReplications note={@props.note} />
            </div>
          </div>

  scheduleThis: () =>
      # for conference this was moved to the chair tools
      user_can_schedule = @state.user_can_schedule
      if @props.note.note_type is 'conference'
          user_can_schedule = 'false'

      schedulable_notes = ['seminar', 'short_course', 'conference']
      if (@props.note.note_type in schedulable_notes)
          <div className='row'>
              <div className='col'>
                  <SeminarScheduler
                      note={@props.note}
                      user_can_edit={user_can_schedule} />
              </div>
          </div>

  acceptanceTrophy: () =>
      if @props.note.acceptance_time > 0
          return (
              <span>
                  <div className="btn-group-vertical">
                      <i className="fa fa-trophy text-success ml-1"></i>
                  </div>
              </span>
          )
      else if @props.note.acceptance_time < 0
          return (
              <span>
                  <div className="btn-group-vertical">
                      <i className="fa fa-times ml-1"></i>
                  </div>
              </span>
          )
      else
          return (
              <span>
                  <div className="btn-group-vertical">
                  </div>
              </span>
          )

  titleBar: () =>
      return(
          <div className='row no-gutters'>
              <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  <VerticalVoteComponent key={@props.note.id} note={@props.note}/>
              </div>
              <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                    <TitleLarge title={@props.note.title} editor={@props.note.editor} />
              </div>
          </div>
      )

  authorBar: () =>
      return (
          <div className='row no-gutters'>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                  <strong>Authors</strong>
              </div>
              <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                  {@props.note.authorcontacts.map((authorcontact) =>
                      <AuthorNameRoll key={authorcontact.id} authorcontact={authorcontact} />
                  )}
              </div>
          </div>
      )

  thisNoteReplicates: () =>
      if (@state.note.is_replication_study)
          return (
              <ReplicationStudy
                  replications={@state.note.is_replication_study}
                  rtype='paper'
                  editor={@state.note.editor}
                  mode='view' />
          )

  abstractBar: () =>
      return(
          <div className='row no-gutters mb-3 abstract_format'>
              <span>
                  <Abstract abstract={@props.note.abstract}
                      editor={@props.note.editor}/>
              </span>
          </div>
      )

  summaryBar: () =>
      return(
          <div className='row no-gutters align-items-start mb-3 mt-3'>
              <Motive
                  editor={@props.note.editor}
                  noteid={@props.note.id}
                  view_mode={'full'} />
          </div>
      )

  sessionsBar: () =>
      if @props.note.note_type is 'conference'
          session_type = <strong>Sessions</strong>
      else
          # note.note_type is 'lab_group'
          session_type = <strong>Topics</strong>

      return (
          <div className='row no-gutters'>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                  {session_type}
              </div>
              <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                  {@props.note.conference_tags.map((tag) =>
                      <TagNoteRoll key={tag.id} tag={tag} tag_type='conference' note_type='conference' projectid={@props.note.project_id} />
                  )}
              </div>
          </div>
      )

  showChairView: () =>

      notes_ids = [@props.note.id]

      data =
        projectid: @props.project.id
        panel_type: 'chair'
        noteids: notes_ids

      common.getTheseNotesForChairs(data, (result) =>

          my_accepted_roles = [ ]
          for role in result['roles']
              if role.role_accepted is 'true'
                  my_accepted_roles.push(role.role)

          @setState({
              note: result['notes'][0],
              userid: result['userid'],
              roles: result['roles'],
              my_accepted_roles: my_accepted_roles,
              mode: 'chair_view' })
      )

  tagsBar: () =>
      return (
          <div className='row no-gutters'>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                  <strong>Tags</strong>
              </div>
              <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                  {@props.note.tags.map((tag) =>
                      <TagNoteRoll key={tag.id} tag={tag} note_type='conference' projectid={@props.note.project_id} />
                  )}
              </div>
          </div>
      )

  withdrawButton: () =>
      if @state.ask_if_sure_before_withdrawing is 'false'
          return (
              <button className="btn btn-link btn-txt-sm text-right"
                      title="Delete your paper from the conference"
                      onClick={@askIfSureBeforeWithdrawal}>
                Withdraw
              </button>
          )
      else
          return (
              <button className="btn btn-danger"
                      title="Delete your paper from the conference"
                      onClick={@deleteNote}>
                Are you sure?
              </button>
          )

  chairButton: () =>
      if 'chair' in @state.my_accepted_roles
          return(
              <button className="float-right btn btn-link btn-txt-sm text-right"
                      title="Chair Tools"
                      onClick={@showChairView}>
                Chair Tools
              </button>
          )

  seeInProjectButton: () =>
      <button className="btn btn-link btn-txt-sm text-right"
              title="View the paper in the user's project"
              onClick={@visitOriginalProjectPaper}>
        In project
      </button>



  render: =>

      update_trigger_button =
          <button className="float-right btn btn-link btn-txt-sm text-right"
              id='modal_open_button'
              data-toggle="modal"
              data-target="#updateModal">
              Update
          </button>

      update_modal =
        <div className="modal fade"
            id="updateModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="updateModalLabel"
            aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button className="close"
                    data-dismiss="modal"
                    aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                  <ConferenceUpdateSubmission note={ @state.note }
                      project_tags={ @props.project.tags }
                      getUserNoteDetails={@getUserNoteDetails}
                      paper_submission={@state.paper_submission}
                      slides_submission={@state.slides_submission}
                      poster_submission={@state.poster_submission}
                      mode={@state.modal_mode} />
              </div>
              <div className="modal-footer">
                <button id='modal_close_button' className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

      if (@state.user_can_edit is 'true')
          delete_or_update_button =
              <div className='ml-1'>
                <div>
                  <Bookmark note={@props.note} roles={@props.roles} />
                  <SocialSharing shareUrl={@state.shareUrl}
                      title={ @props.note.title }/>
                </div>
                <div>
                  { @seeInProjectButton() }
                  {@state.msg}
                  <hr />
                  {update_trigger_button}
                  {update_modal}
                  <hr />
                  { @withdrawButton() }
                  <hr />
                  { @chairButton() }
                </div>
              </div>
      else
          delete_or_update_button =
              <div className='ml-1'>
                { @acceptanceTrophy() }
                { @seeInProjectButton() }
                {@state.msg}
              </div>

      if @state.mode is 'reading'
          <div className='note_infoDiv'>
            <div className='container-fluid'>

              <div className='row no-gutters'>
                  <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                      { @titleBar() }
                      { @scheduleThis() }
                      { @thisNoteReplicates() }
                      { @authorBar() }
                      <div className='row no-gutters'>
                          <DocumentConferenceButton note={@state.note} />
                          <SlidesConferenceButton note={@state.note}
                              hasSubmitted={ @hasSubmitted } />
                          <PosterConferenceButton note={@state.note}
                              hasSubmitted={ @hasSubmitted }/>
                      </div>
                      <div className='row no-gutters'>
                          <Materials obj={@props.note}
                              user_can_edit={@state.user_can_edit}
                              mode={''} />
                      </div>
                      { @abstractBar() }
                      { @summaryBar() }
                      { @sessionsBar() }
                      { @tagsBar() }
                      <Videos
                          obj={@state.note}
                          obj_type={'note_video'}
                          user_can_edit={@state.user_can_edit} />
                      { @replicationsBar() }
                  </div>
                  <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  </div>
                  <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                      {delete_or_update_button}
                  </div>
              </div>
              <NoteFullComments note={@state.note} />
            </div>
          </div>

      else if @state.mode is 'chair_view'
          <div className='note_infoDiv'>
            <div className='container-fluid'>
              <div className='row no-gutters'>
                  <ConferenceNoteReviews
                      note={@state.note}
                      project={@props.project}
                      reviewq={@props.project.reviewq}
                      roles={@props.roles}
                      panel_type={'chair'}
                      spots={ @state.spots } />
              </div>
              <NoteFullComments note={@state.note} />
            </div>
          </div>

      else if @state.mode is 'create_note_metadata' and @state.personal_project
          <div ref={@makeNewTitleRef}>
              <CreateNoteMetadata
                  updated_projectid={@state.personal_project.id}
                  project={@state.note.project_id}
                  note={@state.personal_note}
                  setMode={@setMode}
                  version={'update'}
                  callbackUpdatedNote={@props.callbackUpdatedNote}/>
          </div>
      else
          <span></span>
