common = require('../common')
Link = require('react-router-dom').Link
HeaderBar = require('../header_bar')
ProjectHeader = require('../project_header')
ProjectNoteSummary = require('../project_note_summary')
Pagination = require('../pagination')
RolesBarProject = require('../roles/roles_bar_project')
Loading = require('../loading')

module.exports = class TagSearchProjectResults extends React.Component
  displayName: 'TagSearchProjectResults'

  constructor: (props) ->
      super(props)
      # expects: @props.projectid, tagwordlist

      if @props.match.params.projectId
          projectid = @props.match.params.projectId
      else
          projectid = ''
      tagwords = @props.match.params.tagWordList
      @state = {
          tagwords: tagwords
          projectid: projectid

          page_num: 1
          num_per_page: 10
          lengthy: 2

          mode: 'loading'
          track_notes: []
          other_notes: []
          project: {}

          roles: []
          my_accepted_roles: []
      }

  componentDidMount: =>

      data =
        tagwordlist : @props.match.params.tagWordList
        projectid : @props.match.params.projectId
        page_num: 1
        num_per_page: @state.num_per_page

      common.getPaperSeriesWithTags(data, (result) =>
          if result['error'] is 'project not found'
              @setState({ mode : 'invalid_project' })
          else if result['error'] is 'project deleted'
              @setState({ mode : 'project_deleted' })
          else
              my_accepted_roles = [ ]
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              @setState({
                  project: result['project'],
                  track_notes: result['track_notes'],
                  other_notes: result['other_notes'],
                  userid: result['userid'],
                  roles: result['roles'],
                  my_accepted_roles: my_accepted_roles,
                  mode: 'viewing' })

          document.title = 'ZeroDivZero:search tags in project=' + @props.match.params.tagWordList

      )

      common.getTotalNumberPaperSeriesWithTags(data, (result) =>
        lengthy = Math.ceil(result.total_count/@state.num_per_page)
        @setState({ lengthy: lengthy})
      )

  componentDidUpdate: (prevProps) =>
      if (@props.match.params.tagWordList != prevProps.match.params.tagWordList)
          window.location.reload()

  getThesePages: (info) =>

      data =
          page_num: info
          num_per_page: @state.num_per_page
          projectid: @props.match.params.projectId

      common.getPaperSeriesWithTags(data, (result) =>
          if result['error'] is 'project not found'
              @setState({ mode : 'invalid project' })
          else if result['error'] is 'project deleted'
              @setState({ mode : 'project_deleted' })
          else
            @setState({ notes: result['notes'],  page_num: info })

          elem = @refs.notes_map
          window.scrollTo(0, elem.offsetTop)
      )

  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  render: =>

      if (@state.mode != 'invalid_project') or (@state.mode != 'project_deleted')
        if @state.mode is 'viewing'
          project_header =
              <ProjectHeader key={@state.project.id}
                             project={@state.project}
                             userid={@state.userid}
                             roles={@state.roles} />

      if @state.roles?
         roles_bar =
            <div className='proj_actions_Div'>
                 <RolesBarProject project={@state.project}
                           my_accepted_roles={@state.my_accepted_roles}
                           roles={@state.roles}
                           userid={@state.userid}
                           view={'full'}
                           setUpdatedRoles={@setUpdatedRoles}
                           getUserNoteDetails={@getUserNoteDetails} />
            </div>

      if (@state.mode is 'viewing') and (@state.other_notes) and (@state.other_notes.length>0)
          paper_list =
            <div>
                <span ref="notes_map">
                    {@state.other_notes.map((paper) =>
                        <ProjectNoteSummary key={paper.id}
                                               note={paper}
                                               project={@state.project}
                                               roles={@state.roles}
                                               login_id={@state.userid}/>
                    ,this)}
                </span>
                <Pagination page_num={@state.page_num}
                    lengthy={@state.lengthy}
                    onClick={@getThesePages} />
            </div>
      else if @state.mode is 'viewing'
          paper_list =
              <div>
                  <h1>No titles found.</h1>
              </div>

      if @state.mode is 'invalid_project'
          problem_msg =
          <div>
              <br />
              <h2>We cannot find that project... Try refreshing!</h2>
              If you continue to get this error, then maybe the project number is wrong?
          </div>
      else if @state.mode is 'project_deleted'
          <div>
              <br />
              <h2>Uh oh... It appears the owner has deleted this project.</h2>
          </div>

      if @state.mode is 'loading'
          loading = <div><Loading /></div>

      if @state.project.id
          site_search =
              <div className='mb-2 mt-2'>
                  <h2 className='p-0 m-0 text-secondary'>
                    Showing results for tag: "{ @state.tagwords }" in:
                        <Link to={ '/project/' + @state.project.id + '/titles' }>
                            <button className='btn btn-primary ml-2'>
                                { @state.project.title }
                            </button>
                        </Link>
                  </h2>
                  <h2 className='p-0 m-0 text-secondary'>
                    Search all
                    <Link to={ '/search/tags/' + @state.tagwords }>
                      <button className='btn btn-primary ml-2 mr-2'>
                        ZeroDivZero
                      </button>
                    </Link>
                     for "{@state.tagwords}"?
                  </h2>
              </div>


      <div>
          <HeaderBar />
          { site_search }
          { project_header }
          { roles_bar }
          { problem_msg }
          { paper_list }
          { loading }
      </div>
