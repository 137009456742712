
module.exports = class MakeFormattedPerms extends React.Component
  displayName: 'MakeFormattedPerms'

  constructor: (props) ->
    super(props)
    # expects perms (project.perms) or wordlist
    @state = {
      perms_text: ''
    }

  componentDidMount: =>
      if @props.perms
          wordlist = [ ]
          if @props.perms
              for perm in @props.perms
                  wordlist.push(perm.word)
          @makeFormattedPerms(wordlist)
      else if @props.wordlist
          @makeFormattedPerms(@props.wordlist)

  componentDidUpdate: (prevProps) =>
      if @props.perms
          if prevProps.perms != @props.perms
              wordlist = [ ]
              if @props.perms
                  for perm in @props.perms
                      wordlist.push(perm.word)
              @makeFormattedPerms(wordlist)
      else if prevProps.wordlist != @props.wordlist
          @makeFormattedPerms(@props.wordlist)

  makeFormattedPerms: (wordlist) =>

      formatted_perms = ''

      index = wordlist.indexOf('_open_project_perm')
      if index > -1
          formatted_perms = 'open access'
      else
          formatted_perms = 'private'

      index = wordlist.indexOf('_open_submission_perm')
      if index > -1
          formatted_perms = formatted_perms + ', open submission'
      else
          formatted_perms = formatted_perms + ', submission is by invitation only'

      index = wordlist.indexOf('_following_pre_read_perm')
      if index > -1
          formatted_perms = formatted_perms + ', papers may be read before acceptance'
      else
          formatted_perms = formatted_perms + ', papers visible after acceptance'

      index = wordlist.indexOf('_reviews_shared')
      if index > -1
          formatted_perms = formatted_perms + ', reviews will be visible to everyone'
      else
          formatted_perms = formatted_perms + ', reviews visible only to author and reviewer'

      index = wordlist.indexOf('_reveal_reviewer_name')
      if index > -1
          formatted_perms = formatted_perms + ', by default reviewer names will be revealed'
      else
          formatted_perms = formatted_perms + ', by default reviews will be anonymous'

      index = wordlist.indexOf('_reviewer_anon_choice')
      if index > -1
          formatted_perms = formatted_perms + ', but reviewers can choose to be anonymous or public'
      else
          formatted_perms = formatted_perms + ', reviewers do not have an individual choice to be anonymous or public'
      @setState({ perms_text : formatted_perms})

  render: =>

      if @state
          <span className="">{ @state.perms_text }</span>
      else
          <span></span>
