common = require('./common')
CreateNoteMetadata = require('./create_title/create_note_metadata')
HeaderBar = require('./header_bar')
Loading = require('./loading')
NavGeneral = require('./nav_general')
Pagination = require('./pagination')
ProjectHeader = require('./project_header')
ProjectNoteSummary = require('./project_note_summary')
RolesBarProject = require('./roles/roles_bar_project')

module.exports = class ProjectNotes extends React.Component
  displayName: 'ProjectNotes'

  constructor: (props) ->
      super(props)
      projectid = @props.match.params.projectId
      @state = {
          projectid: projectid
          project: {}
          notes: {}
          mode: 'loading'
          note: {}
          roles: []
          my_accepted_roles: []
          error_msg: ''
          creator: 'false'
          userid: ''

          page_num: 1
          lengthy: 2
          num_per_page: 10
          Lnotes: 0

          subset: 'all'
      }
      @makeNewTitleRef = React.createRef()
      @notesMap = React.createRef()

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  getUserNoteDetails: =>
      @setState({
          mode : 'create_note_metadata'}
          @scrollToHere(@makeNewTitleRef))

  componentDidMount: =>

      data =
        page_num: 1
        num_per_page: @state.num_per_page
        projectid: @props.match.params.projectId
        subset: 'all'

      common.projectNotes(data, (result) =>
          if result['error'] is 'project not found'
              @setState({ mode : 'invalid project' })
          else if result['error'] is 'project deleted'
              @setState({ mode : 'project_deleted' })
          else
              my_accepted_roles = []
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              if 'creator' in my_accepted_roles
                  @setState({ creator: 'true' })

              @setState({
                project: result['project'],
                notes: result['notes'],
                userid: result['userid'],
                roles: result['roles'],
                my_accepted_roles: my_accepted_roles,
                mode: 'viewing' })

          document.title = result['project']['title']
      )

      data =
        projectid: @props.match.params.projectId

      common.getTotalNumberNotesInProject(data, (result) =>
        Lnotes = result.total
        lengthy = Math.ceil(result.total/@state.num_per_page)
        @setState({ lengthy: lengthy, Lnotes: Lnotes})
      )

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()

  getThisSortType: (new_subset) =>
      @setState({subset: new_subset}, @getThesePages)

  getThesePages: (info) =>

      if not (info)
          info=1

      if not (@state.subset is 'all') and not (@state.userid)
          @setState({notes: []})
          return

      data =
          page_num: info
          num_per_page: @state.num_per_page
          projectid: @props.match.params.projectId
          subset: @state.subset

      common.projectNotes(data, (result) =>
          if result['error'] is 'project not found'
              @setState({ mode : 'invalid project' })
          else if result['error'] is 'project deleted'
              @setState({ mode : 'project_deleted' })
          else
            @setState({ notes: result['notes'],  page_num: info })
      )

  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  setMode: (mode_option) =>
      if mode_option is 'viewing'
          window.location.reload()
      else if mode_option is 'cancelled'
          @setState({ mode: 'viewing' })
      else
          @setState({ mode: mode_option })

  rolesBar: () =>
      if @state.roles
          roles_bar =
              <RolesBarProject project={@state.project}
                        my_accepted_roles={@state.my_accepted_roles}
                        roles={@state.roles}
                        userid={@state.userid}
                        view={'full'}
                        setUpdatedRoles={@setUpdatedRoles}
                        getUserNoteDetails={@getUserNoteDetails} />

      <span>
          <span>This project has { @state.Lnotes } titles! &nbsp;</span>
          <br />
          { roles_bar }
      </span>

  problemMsg: () =>
      if @state.mode is 'invalid project'
          <div>
              <br />
              <h2>We cannot find that project... Try refreshing!</h2>
              If you continue to get this error, then maybe the project number is wrong?
          </div>
      else if @state.mode is 'project_deleted'
          <div>
              <br />
              <h2>Uh oh... It appears the owner has deleted this project.</h2>
          </div>
      else if @state.mode is 'some other error'
          <div>
              <br />
              <h2>{@state.error_msg}</h2>
          </div>

  projectHeader: () =>
      <span>
        <ProjectHeader key={@state.project.user_id}
                       project={@state.project}
                       userid={@state.userid}
                       roles={@state.roles} />
      </span>

  makeNewPaper: () =>
      if @state.mode is 'create_note_metadata'
          <div ref={@makeNewTitleRef}>
              <CreateNoteMetadata project={@state.project}
                                  setMode={@setMode}
                                  version={'new'}/>
          </div>

  reorderTitles: (neworder) =>
      shifted_note = neworder.note
      direction = neworder.direction
      notes = @state.notes
      updated_notes = []

      # Is the paper right on the edge so a bump up or down puts it on a
      # new page?  Then simply reload to that page.

      min_note = (@state.page_num-1) * @state.num_per_page
      max_note = @state.page_num * @state.num_per_page - 1

      if (direction is 'up') and ((shifted_note.ordering-1) < min_note)
          @getThesePages(@state.page_num - 1)
          return
      else if (direction is 'down') and ((shifted_note.ordering+1) > max_note)
          @getThesePages(@state.page_num + 1)
          return


      if direction is 'up'
          # up means appears close to top, so lower ordering number
          new_pos = shifted_note.ordering - 1
          for note in notes
              if note.ordering is (new_pos)
                  updated_notes.push(shifted_note)
                  updated_notes.push(note)
              else if note.ordering is (new_pos + 1)
                  # skip, we swapped above
              else
                  updated_notes.push(note)
      else
          # direction is 'down'
          # down means appears close to end, so higher ordering number
          new_pos = shifted_note.ordering + 1
          for note in notes
              if note.ordering is (new_pos - 1)
                  # skip, we swapped above
              else if note.ordering is (new_pos)
                  updated_notes.push(note)
                  updated_notes.push(shifted_note)
              else
                  updated_notes.push(note)

      ctr = 0
      for note in updated_notes
          note.ordering = ctr++

      @setState({ notes: updated_notes })


  paperList: () =>

      if @state.creator is 'true'
          action_bar =
              <button className='btn btn-danger btn-lg'
                      onClick={@getUserNoteDetails}>Add a title</button>
      else
          action_bar =
              <span></span>

      if @state.mode is 'loading'
          <div className='note_noPapers'>
              <br />
              <Loading />
              <br />
              <br />
          </div>
      else if @state.mode is 'viewing'
          if @state.notes.length > 0
              <div>
                  <span ref='notesMap'>
                      {@state.notes.map((paper) =>
                          <ProjectNoteSummary
                              key={paper.id}
                              note={paper}
                              project_owner_userid={@state.project.user_id}
                              roles={@state.roles}
                              login_id={@state.userid}
                              reorderTitles={@reorderTitles}
                              Lnotes={@state.Lnotes} />
                      ,this)}
                      <Pagination page_num={@state.page_num}
                          lengthy={@state.lengthy}
                          onClick={@getThesePages} />
                  </span>
              </div>
          else if (@state.notes.length is 0)
              <div>
                  <div className='proj_listing'
                      ref={@notesMap}>
                    <div className='note_noPapers'>
                      <br />
                      <i>Nothing here.... yet....</i>
                      <br />
                      <br />
                    </div>
                  </div>
              </div>
          else
              <div className='note_noPapers'>
                <p className='mt-2'>
                  Get started!
                </p>
                <p>
                  <span className='font-weight-bold'>Definition: title</span>
                  <br />
                  <i className='ml-3'>
                   a <span className='font-weight-bold'>title</span> includes
                   a title, abstract, and summary. You also have the
                   option to upload a paper, slides, and/or poster.
                  </i>
                </p>
                <p>
                  <ul>
                    <li>
                      Click the "Add a title" button (below).
                    </li>
                    <li>
                      To submit your title to a conference,
                      navigate to the conference, click "submit", and follow
                      the instructions.
                    </li>
                  </ul>
                </p>
                <span className='mb-2 ml-2'>{ action_bar }</span>
                <br />
              </div>

  render: =>

      if @state.project
          conf_nav_vert =
              <div className='col-md-2 col-lg-2 border d-none d-md-block'>
                  <NavGeneral
                      id={@state.project.id}
                      ptype={@state.project.ptype}
                      caller={'titles'}
                      subset={@state.subset}
                      getThisSortType={@getThisSortType}
                      userid={@state.userid}
                      styling={'col-12'} />
              </div>
          conf_nav_horiz =
              <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
                  <NavGeneral
                      id={@state.project.id}
                      ptype={@state.project.ptype}
                      caller={'titles'}
                      subset={@state.subset}
                      getThisSortType={@getThisSortType}
                      userid={@state.userid}
                      styling={'mr-2'} />
              </div>

      <div>
          <HeaderBar />
          <div className='container-fluid pr-0 pl-0 border'>
              { @projectHeader() }
              <div className='row no-gutters'>
                  {conf_nav_vert}
                  {conf_nav_horiz}
                  <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                      { @rolesBar() }
                      { @problemMsg() }
                      { @paperList() }
                      { @makeNewPaper() }
                  </div>
              </div>
          </div>
      </div>
