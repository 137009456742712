common = require('../common')
Loading = require('../loading')

module.exports = class EmailVerificationForAccountMerging extends React.Component
  displayName: 'EmailVerificationForAccountMerging'

  constructor: (props) ->
      super(props)
      # expecting as props:
      # email
      # userid
      @state = {
        email_msg: ''
        check_msg: ''
        loading: 'false'
      }

  askToSendEmailCode: () =>
      @setState({loading: 'true'})

      data =
          conflict_email : @props.email

      common.EmailVerificationForAccountMerging(data, (result) =>
          @setState({loading: 'false'})
          if result.error
              @setState({email_msg: result.error})
          else
              @setState({email_msg: 'Email sent! If you do not see it soon, check your spam folder.'})
      )

  checkCode: () =>
      @setState({loading: 'true'})

      verif_code = @refs.verification_code.value
      verif_code = verif_code.replace(/\s/g, '') # remove whitespace

      if verif_code.length == 0
          @setState({check_msg: 'you must enter a code'})
          return

      data =
          verif_code: verif_code
          userid: @props.userid
          conflict_email: @props.email

      common.confirmEmailedCodeAndMergeAccounts(data, (result) =>
          @setState({loading: 'false'})
          if result.error is 'success'
              @setState({check_msg: 'Complete! Reloading...'})
              window.location.reload()
          else
              @setState({check_msg: result.error})
      )

  render: =>
    if @state.loading is 'true'
        loading = <span><Loading /></span>
    else
        loading = <span></span>

    user_verified =
        <span>
          <div className='row no-gutters text-center'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <span className="text-danger">
                    Verify your email:</span>
              <button className='btn btn-primary btn-txt-sm' onClick={@askToSendEmailCode}>
                      Email code to {@props.email}</button>
            </div>
          </div>
          <div className='row no-gutters text-center'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              {@state.email_msg}
            </div>
          </div>
          <div className='d-flex justify-content-center'>
                {loading}
          </div>
          <div className='row no-gutters text-center'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              Then paste the code:
              <input className='verif_code_input_textbox' type="text" ref="verification_code"></input>
              <button className='btn btn-success btn-txt-sm' onClick={@checkCode}>Verify</button>
              {@state.check_msg}
            </div>
          </div>
        </span>

    <div className='container-fluid no-borders'>
       {user_verified}
    </div>
