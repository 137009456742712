Link = require('react-router-dom').Link
common = require('../common')
ConferenceNoteSelectBox = require('./conference_note_select_box')
ReviewerAddBox = require('./reviewer_add_box')

module.exports = class AssignReviewersToConfPapers extends React.Component
  displayName: 'AssignReviewersToConfPapers'

  constructor: (props) ->
      super(props)
      @state = {
        mode : 'show assign button'
        reviewers : [ ]
        chosen_noteid : ''
        chosen_note : ''
        reviewerlist : [ ]
        local_notes : @props.notes
        project : @props.project
        return_message : ""
      }

  collapseToAssignButton: () =>
      @setState({ mode : 'show assign button' })

  goToShowPapersAndReviewers: () =>

      data =
          projectid : @props.project.id
          role: 'reviewer'

      common.conferenceGetRoleJunctions(data, (result) =>
          @setState({ reviewers : result.combined_rolejunctions })
          @setState({ mode : 'viewing' })
      )

      common.conferenceHelm((data) =>
          tmp_notes = data['notes']
          for note in tmp_notes
              note.current_mode = 'not selected'
          @setState({ local_notes : tmp_notes })
      , @props.project.id)

  componentDidMount: () =>
      # hi

  uniquifyReviewers: (revs) =>
      unique_revs = [ ]

      unique_ids = [ ]
      for rev in revs
        index = unique_ids.indexOf(rev.user_id)
        if index < 0
            unique_ids.push(rev.user_id)

      for rev in revs
          pos = unique_ids.indexOf(rev.user_id)
          if pos > -1
              unique_ids.splice(pos, 1)
              unique_revs.push(rev)

      return unique_revs


  assignReviewersToNote: () =>

      # console.log("whelp time to save and reviewlist is")
      # console.log(@state.reviewerlist)
      # console.log("and right now local_notes gives us")
      # console.log(@state.local_notes)
      # console.log("just as a check... @props.notes is giving us")
      # console.log(@props.notes)

      local_notes = @state.local_notes

      for local_note in local_notes
          local_note.current_mode = 'not selected'
      @setState({ local_notes : local_notes })

      if @state.reviewerlist.length > 0
          for local_note in local_notes
              if local_note.id is @state.chosen_noteid
                for reviewer in @state.reviewerlist
                    # console.log("1st local_note.reviewers is ")
                    # console.log(local_note.reviewers)
                    # console.log("Now I'm going to push ",reviewer.name)
                    local_note.reviewers.push(reviewer)
                    # console.log("after pushing, local_note.reviewers is ")
                    # console.log(local_note.reviewers)

                # TODO: there is some weirdness here
                # Shouldn't need _.uniq, but props & local are both changing
                # their reviewer lists -- by magic!
                # or maybe naming collisions?
                # I think I have discovered a bug in React
                # maybe updating version will take care of it.
                # in the end it does the right thing, but it bars errors to
                # the console.
                # Leaving my debug prints for future.

                local_note.reviewers = @uniquifyReviewers(local_note.reviewers)
                # console.log("and post uniquify we have local_note.reviewers=")
                # console.log(local_note.reviewers)

          # console.log("checking what we set local_notes to")
          # console.log(local_notes)
          @setState({ local_notes : local_notes })

          reviewerids = [ ]
          for reviewer in @state.reviewerlist
              reviewerids.push(reviewer.id)

          data =
              chosen_noteid : @state.chosen_noteid
              chosen_paperseriesid : @state.chosen_note.paperseries_id
              reviewerids : reviewerids
              projectid : @props.project.id

          common.assignReviewersToConferencePaperSeries(data, (result) =>
              @setState({ chosen_noteid : '' })
              @setState({ reviewerlist : [ ] })
              @setState({ chosen_note : '' })
          )

  goToAutoAssignAndShowPapersAndReviewers: =>

      data =
          reviewers_per_paper : @refs.num_reviewers_per_paper.value
          projectid : @props.project.id

      common.autoAssignReviewersToUnassignedConferencePaperSeries(data, (result) =>
          @setState({ return_message : result.res.return_message })
      )


  chosenReviewer: (reviewer) =>

      reviewerlist = @state.reviewerlist

      found = 0
      if @state.chosen_note.reviewers.length > 0
          for rev in @state.chosen_note.reviewers
              if reviewer.user_id is rev.user_id
                  found = 1

      if found is 0
          reviewerlist.push(reviewer)
          @setState({ reviewerlist : reviewerlist })

  chosenNote: (note) =>
      if note.id != @state.chosen_noteid
          # this means a new note was selected
          @setState({ chosen_noteid : note.id })
          @setState({ chosen_note : note })
          tmp_notes = @state.local_notes
          for tmp_note in tmp_notes
              if tmp_note.id is note.id
                  tmp_note.current_mode = 'selected'
              else if tmp_note.id is @state.chosen_noteid
                  tmp_note.current_mode = 'not selected'
          @setState({ local_notes : tmp_notes })
      else
          # this means the "save" btn was clicked on same note
          note.current_mode = 'not selected'
          @assignReviewersToNote()


  render: =>
      if @state.reviewers.length > 0
          reviewer_map =
              <div className="review_names_infoDiv">
                  <h2>reviewers:</h2>
                  {@state.reviewers.map((reviewer) =>
                      <ReviewerAddBox
                              key={reviewer.id}
                              reviewer={reviewer}
                              reviewerlist={@state.reviewerlist}
                              onClick={@chosenReviewer.bind(this,reviewer)} />
                  ,this)}
              </div>
      else
          reviewer_map =
            <div className="review_names_infoDiv">
                <div className='note_infoDiv'>
                  <h4> Invite some reviewers....</h4>
                </div>
            </div>

      if @state.local_notes.length?
          paper_map =
              <div className="review_note_infoDiv">
                  <h2>papers:</h2>
                  {@state.local_notes.map((paper) =>
                      <ConferenceNoteSelectBox
                                    key={paper.id}
                                    note={paper}
                                    chosen_noteid={@state.chosen_noteid}
                                    reviewers={@state.reviewerlist}
                                    onClick={@chosenNote.bind(this,paper)} />
                  ,this)}
            </div>
      else
          paper_map =
              <div className='review_note_infoDiv'>
                <h3>No submitted papers yet!</h3>
              </div>


      if @state.mode is 'show assign button'
          <div>
              Assign
                        <input className='small_input_textbox'
                              type="text"
                              id="num_reviewers_per_paper"
                              ref="num_reviewers_per_paper"
                              placeholder={ @state.project.reviewers_per_paper }></input>
                        papers per reviewer: <button className="btn btn-primary"
                                                    onClick={@goToAutoAssignAndShowPapersAndReviewers}>
                                                    Auto-assign</button>
              { @state.return_message }
          </div>
      else if @state.mode is 'viewing'
          if @props.project.id?
              <div>
                <div>
                Select a paper to edit
                <br />
                Add reviewers from the reviewers column
                <br />
                Remove reviewers by clicking the "x" next to their name.
                </div>
                <button className="btn btn-secondary"
                        onClick={@collapseToAssignButton}>
                        all done
                        </button>
                <br />
                { paper_map }
                { reviewer_map }
                <br />
                <button className="btn btn-secondary"
                        onClick={@collapseToAssignButton}>
                        all done
                        </button>
              </div>
      else
          <div>
              <h2>Loading...</h2>
          </div>
