common = require('../common')
DocumentFullFrame = require('../uploads/document_fullframe')
Loading = require('../loading')

module.exports = class VttViewer extends React.Component
    displayName: 'VttViewer'

    constructor: (props) ->
        super(props)
        @state = {
          noteid: ''
        }

    componentDidMount: =>

        vtt_id = @props.match.params.vttId
        @setState({ vtt_id: vtt_id })

    render: =>

        if @state.vtt_id
            filename = "/view_vtt_document/" + @state.vtt_id
            <DocumentFullFrame
                className="fullwindow_view_document_iframe"
                src={filename} />
        else
            <Loading />
