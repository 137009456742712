common = require('../common')
Loading = require('../loading')
Pagination = require('../pagination')
TheStudied = require('./the_studied')

module.exports = class NoteReplications extends React.Component
  displayName: 'NoteReplications'

  constructor: (props) ->
      super(props)
      # expects @props.note
      @state = {
          repl_count: ''
          avg_rec: ''
          replications: []
          mode: 'loading'
          err_msg: ''

          page_num: 1
          lengthy: 1
          num_per_page: 3
      }

  componentDidMount: =>
      data =
          paperseries_id: @props.note.paperseries_id
          ask: 'counts'

      common.getReplicationsOfNote(data, (result) =>
          if (result) and (result.replication)
              result = result.replication
              lengthy = Math.ceil(result.replication_count/@state.num_per_page)
              @setState({
                  repl_count: result.replication_count,
                  avg_rec: result.avg_recommend,
                  lengthy: lengthy,
                  mode: 'preview'})

          else if (result) and (result.replications_page)
              @setState({
                      replications: result.replications_page,
                      mode: 'view_page'
                  })

          else if (result) and (result.err_msg)
              @setState({
                  mode: 'err_msg'
                  err_msg: result['err_msg']})

          else
              @setState({ mode: 'no_replications' })
      )

  showRepPage: =>
      @setState({ mode: 'loading'})

      data =
          paperseries_id: @props.note.paperseries_id
          ask: 'page'
          page_num: 1
          num_per_page: @state.num_per_page
          sort_type: 'most_recent'

      common.getReplicationsOfNote(data, (result) =>

          if (result) and (result.replications_page)
              @setState({
                      replications: result.replications_page,
                      mode: 'view_page'
                  })

          else if (result) and (result.err_msg)
              @setState({
                  mode: 'err_msg'
                  err_msg: result.err_msg})
      )

  getThesePages: (info) =>

      data =
          page_num: info
          num_per_page: @state.num_per_page
          paperseries_id: @props.note.paperseries_id

      common.getReplicationsOfNote(data, (result) =>

          if (result) and (result.replications_page)
              @setState({
                      replications: result.replications_page,
                      page_num: info
                  })

          else if (result) and (result.err_msg)
              @setState({
                  mode: 'err_msg'
                  err_msg: result['err_msg']})

          elem = @refs.rep_map
          window.scrollTo(0, elem.offsetTop)
      )

  render: =>

      if @state.mode is 'loading'
          <span><Loading /></span>
      else if @state.mode is 'no_replications'
          <span>
              No replications
          </span>
      else if @state.mode is 'preview'
          <span>
              <button className='btn btn-link mr-0 pr-0'
                      onClick={@showRepPage}>
              { @state.repl_count } replications</button>
              , avg recommendation of { @state.avg_rec }%
          </span>
      else if @state.mode is 'view_page'
          <div>
              <span ref='rep_map'>
                  <TheStudied replications={@state.replications}
                      rtype={'paper'}
                      editor={@props.note.editor}
                      mode={'view'} />
              </span>
              <Pagination page_num={@state.page_num}
                  lengthy={@state.lengthy}
                  onClick={@getThesePages} />
          </div>
      else if @state.mode is 'err_msg'
          <span>{ @state.err_msg }</span>
      else
          # some unknown state
          <span></span>
