module.exports = class GetWritingManualResize extends React.Component
  displayName: 'GetWritingManualResize'

  constructor: (props) ->
      super(props)
      @textareaRef = React.createRef()

  updateText: (event) =>
      @autoResize(event)
      if @props.onChange
          @props.onChange(@textareaRef.current.value)
      false

  getValue: =>
      @textareaRef.current.value

  setValue: (value) =>
      @textareaRef.current.value = value

  resizingTextarea: () =>
      @refs.text_input.addEventListener('input', @autoResize(), false)

  autoResize: (event) =>
      @textareaRef.current.style.height = 'auto'
      @textareaRef.current.style.height = @textareaRef.current.scrollHeight+'px'
      # @refs.text_input.scrollTop = @refs.text_input.scrollHeight
      # window.scrollTo(window.scrollLeft,(@refs.text_input.scrollTop + @refs.text_input.scrollHeight))

  render: =>
      <span className="get_writing">
          <textarea className="autoresize"
                    ref={@textareaRef}
                    rows={@props.rows}
                    placeholder={@props.placeholder}
                    onChange={@updateText}
                    defaultValue={if @props.defaultValue then @props.defaultValue else ""}>
          </textarea>
      </span>
