common = require('../../common')
MakeFormattedPerms = require('./make_formatted_perms')

module.exports = class LabPermsTools extends React.Component
  displayName: 'LabPermsTools'

  constructor: (props) ->
      super(props)
      @state = {
          view_mode: 'reading'
          updated_wordlist: @props.wordlist
      }

  toggleReadEditMode: =>
      if (@state.view_mode is 'editing')
          @updateProjectFields()
      else
          @setState({ view_mode : 'editing' })

  updateProjectFields: =>
      data =
          projectid: @props.projectid
          updated_perm_tags : @state.updated_wordlist

      common.submitProjectFields(data, (result) =>
          @setState({ view_mode: 'reading' })
          @props.setUpdatedPerms(result.perms)
      )

  addPerm: (tagword) =>
      if tagword not in @state.updated_wordlist
          updated_wordlist = @state.updated_wordlist
          updated_wordlist.push(tagword)
          @setState({ updated_wordlist : updated_wordlist })

  subtractPerm: (tagword) =>
      if tagword in @state.updated_wordlist
          updated_wordlist = @state.updated_wordlist
          pos = updated_wordlist.indexOf(tagword)
          if pos > -1
              updated_wordlist.splice(pos,1)
              @setState({ updated_wordlist : updated_wordlist })

  render: =>

      if @state.updated_wordlist.indexOf('_open_project_perm') > -1
          open_project_row =
              <div>
                <button className="proj_lefttpad_btn" onClick={@addPerm.bind(this,"_open_project_perm")}>Yes</button>
                <button className="proj_rightpad_btn" onClick={@subtractPerm.bind(this,"_open_project_perm")}>No</button>
                Can anyone see this {@props.ptype}?
              </div>
      else
          open_project_row =
              <div>
                <button className="proj_rightpad_btn" onClick={@addPerm.bind(this,"_open_project_perm")}>Yes</button>
                <button className="proj_lefttpad_btn" onClick={@subtractPerm.bind(this,"_open_project_perm")}>No</button>
                Can anyone see this {@props.ptype}?
              </div>

      if @state.updated_wordlist.indexOf('_open_submission_perm') > -1
          open_submission_row =
              <div>
                <button className="proj_lefttpad_btn" onClick={@addPerm.bind(this,"_open_submission_perm")}>Yes</button>
                <button className="proj_rightpad_btn" onClick={@subtractPerm.bind(this,"_open_submission_perm")}>No</button>
                  Can anyone submit papers?
              </div>
      else
          open_submission_row =
              <div>
                <button className="proj_rightpad_btn" onClick={@addPerm.bind(this,"_open_submission_perm")}>Yes</button>
                <button className="proj_lefttpad_btn" onClick={@subtractPerm.bind(this,"_open_submission_perm")}>No</button>
                  Can anyone submit papers?
              </div>

      if @state.updated_wordlist.indexOf('_following_pre_read_perm') > -1
          open_preread_row =
              <div>
                <button className="proj_lefttpad_btn" onClick={@addPerm.bind(this,"_following_pre_read_perm")}>Yes</button>
                <button className="proj_rightpad_btn" onClick={@subtractPerm.bind(this,"_following_pre_read_perm")}>No</button>
                  Anyone can read submissions before acceptance?
              </div>
      else
          open_preread_row =
              <div>
                <button className="proj_rightpad_btn" onClick={@addPerm.bind(this,"_following_pre_read_perm")}>Yes</button>
                <button className="proj_lefttpad_btn" onClick={@subtractPerm.bind(this,"_following_pre_read_perm")}>No</button>
                  Anyone can read submissions before acceptance?
              </div>

      if @state.view_mode is 'editing'
          PermissionsRow =
              <div>
                <strong className='mb-3'>
                    Set {@props.ptype} permissions.
                </strong>
                <br />
                  { open_project_row }
                  { open_submission_row }
                  { open_preread_row }
              </div>
      else
          PermissionsRow =
              <div>
                <strong className='mb-3'>
                    {@props.ptype} permissions
                </strong>
                <div>
                    <MakeFormattedPerms key={Math.random()}
                        wordlist={@props.wordlist} />
                </div>
              </div>

      if (@state.view_mode is 'editing')
          toggle_word = 'save'
      else
          toggle_word = 'edit'

      <div className='m-3'>
          { PermissionsRow }
          <button className="btn btn-secondary btn-txt-sm"
                  onClick={@toggleReadEditMode}>
                  { toggle_word }
          </button>
      </div>
