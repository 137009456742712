common = require('../common')
ConferenceSubmissionUserOwnedLists = require('../conference_submission_user_owned_lists')
FollowButton = require('../follow_button')
RoleRollConference = require('./role_roll_conference')

module.exports = class RolesBar extends React.Component
  displayName: 'RolesBar'

  constructor: (props) ->
      super(props)
      # expects:
      # @props.project (including id, tags, submission_deadline)
      # @props.my_accepted_roles aka 'rolewords' in some modules
      # @props.roles
      @state = {
          past_deadline: false
          past_rebuttal_deadline: false
          can_submit: false
          has_submitted: false
          mode: 'view'
      }

  componentDidMount: =>
      today = Number(new Date())
      deadline = Number(@props.project.submission_deadline*1000)
      rebuttal_deadline = Number(@props.project.rebuttal_deadline*1000)

      if (deadline > 0) and (today > deadline)
          @setState({past_deadline: true})
      if (rebuttal_deadline > 0) and (today > deadline)
          @setState({past_rebuttal_deadline: true})

      # who can submit?
      if @props.project.perms
          permlist = []
          for perm in @props.project.perms
              permlist.push(perm.word)
          if '_open_submission_perm' in permlist
              @setState({can_submit: true})
          else if ('creator' in @props.my_accepted_roles) \
               or ('invited' in @props.my_accepted_roles) \
               or ('contributor'in @props.my_accepted_roles)
              @setState({can_submit: true})

      if 'contributor' in @props.my_accepted_roles
          @setState({ has_submitted: true })

  componentDidUpdate: (prevProps) =>
      if (@props.project.submission_deadline != prevProps.project.submission_deadline)
          today = Number(new Date())
          deadline = Number(@props.project.submission_deadline*1000)

          if (deadline > 0) and (today > deadline)
              @setState({past_deadline: true})

      if prevProps.my_accepted_roles != @props.my_accepted_roles
          # who can submit?
          permlist = []
          for perm in @props.project.perms
              permlist.push(perm.word)
          if '_open_submission_perm' in permlist
              @setState({can_submit: true})
          else if ('creator' in @props.my_accepted_roles) \
               or ('invited' in @props.my_accepted_roles)
              @setState({can_submit: true})
          else
              @setState({can_submit: false})
          if 'contributor' in @props.my_accepted_roles
              @setState({ has_submitted: true })
          else
              @setState({ has_submitted: false })

  submitToConference: =>
      if not @props.userid
          link = '/' + @props.project.ptype + '/' + @props.project.id + '/titles'
          window.location = '/login/?' + link

  setFollowRoles: (new_roles_are) =>
      @props.setUpdatedRoles(new_roles_are)

  updateRoleAcceptance: (rolejunction) =>
      rolejunctionid = rolejunction.id

      updated_roles = [ ]
      for role in @props.roles
        if role.id is rolejunctionid
          if role.role_accepted is 'true'
            role.role_accepted = 'false'
            updated_status = 'false'
          else
            role.role_accepted = 'true'
            updated_status = 'true'
        updated_roles.push(role)

      @props.setUpdatedRoles(updated_roles)

      tag_ids = [ ]
      if rolejunction.role_tag_list?
        for tag in rolejunction.role_tag_list
            tag_ids.push(tag.id)

      data =
          rolejunctionid: rolejunctionid
          role_accepted: updated_status
          tag_ids: tag_ids

      common.userUpdatesConferenceRole(data, (result) =>
          # no output!
          )

  showUploadTypes: (origin) =>
      if origin is 'new'
          @setState({ mode: 'ask_upload_type' })
      else
          # origin is 'udpate'
          @setState({ mode: 'show_update_instr' })

  updateTitleRequest: () =>
      if (not @state.past_rebuttal_deadline) and (@state.has_submitted) and (@props.getUserNoteDetails)
          update_a_title_request =
              <button className="btn btn-link btn-lg mr-3 ml-2"
                     onClick={@showUploadTypes.bind(this, 'update')}>Want to update your submission?</button>
      if (@state.mode is 'show_update_instr')
          update_a_title_request =
              <span>
                  &nbsp;Find your sumbission, click the title, then click "udpate".
              </span>

      <span>
          {update_a_title_request}
      </span>

  submitTitleProcess: () =>
      if (not @state.past_deadline) and (@state.can_submit) and (@props.getUserNoteDetails)
          submit_a_title_request =
              <span onClick={@submitToConference}>
                  <button className="btn btn-danger btn-lg mr-3"
                         onClick={@showUploadTypes.bind(this, 'new')}>Submit a title</button>
              </span>
          if (@state.mode is 'ask_upload_type')
              ask_upload_or_submit =
                  <span>
                          <button className="btn btn-primary btn-lg mr-2"
                               data-toggle="modal"
                               data-target="#exampleModalLong">
                               Submit from existing project
                          </button>
                      or
                      <button className="btn btn-primary ml-2"
                              onClick={@props.getUserNoteDetails}>
                          Upload directly</button>
                  </span>
      else if @state.past_deadline
          submit_a_title_request =
              <span className='pl-2'>(Conference submission deadline has passed)</span>
      else
          submit_a_title_request =
              <span className='pl-2'>Submission is by invitation only. Ask the organizers to invite you!</span>

      <span>
          {submit_a_title_request}
          {ask_upload_or_submit}
      </span>


  actionsBar: () =>

      if 'following' not in @props.my_accepted_roles
          follow_request =
                <span>
                    <FollowButton projectid={@props.project.id}
                        roles={@props.roles}
                        setFollowRoles={@setFollowRoles} />
                </span>

      <div>
          <span>Actions: &nbsp;</span>
          {follow_request}
          { @submitTitleProcess() }
          { @updateTitleRequest() }
      </div>

  userConfSubmissionList: () =>
      <div className="modal fade"
          id="exampleModalLong"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <span className=""
                  id="exampleModalLongTitle">Choose a project
              </span>
              <button type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

                <span className='onepointtwo_em'>
                    <ConferenceSubmissionUserOwnedLists
                        conference={@props.project}
                        userid={@props.userid} />
                    <br />
                    <br />
                </span>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">cancel</button>
            </div>
          </div>
        </div>
      </div>

  helpMsgRoles: () =>
      if @props.roles.length > @props.my_accepted_roles.length
          <span className="text-danger">
              You need to accept your conference roles to enable the appropriate tools.
          </span>


  render: =>

      if (@props.roles.length > 0) and (@props.view is 'full')
          <span>
              <div>
                  {@actionsBar()}
                  <span>My roles: &nbsp;</span>
                  {@props.roles.map((myrole) =>
                      <RoleRollConference key={myrole.id}
                            onClick={@updateRoleAcceptance.bind(this,myrole)}
                            myrole={myrole}
                            tags={@props.project.tags}
                            projectid={@props.project.id}/>
                  ,this)}
                  { @helpMsgRoles() }
              </div>
              <span>{ @userConfSubmissionList() }</span>
          </span>
      else if (@props.roles.length > 0) and (@props.view is 'mini')
          <span>
              {@props.roles.map((myrole) =>
                  <RoleRollConference key={myrole.id}
                        onClick={@updateRoleAcceptance.bind(this,myrole)}
                        myrole={myrole}
                        tags={@props.project.tags}
                        projectid={@props.project.id}
                        ptype={@props.project.ptype}/>
              ,this)}
              { @helpMsgRoles() }
          </span>
      else
          <span>
              <div>
                  { @actionsBar() }
                  My roles:  <i>none yet -- follow!</i>
                  { @helpMsgRoles() }
              </div>
              <span>{ @userConfSubmissionList() }</span>
          </span>
