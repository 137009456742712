common = require('../common')
GetSurveyList = require('./get_survey_list')
GetSurveyResponses = require('./get_survey_responses')
CreateOrEditSurvey = require('./create_or_edit_survey')

module.exports = class HelmSurveyButton extends React.Component
  displayName: 'HelmSurveyButton'

  constructor: (props) ->
      super(props)
      # Expects: @props.
      # show_this
      # project
      # userid
      @state = {
          mode: ''
      }

  toggleTakeSurveys: (mode)=>
      @setState({ mode: mode }, @scrollToPanel)

  scrollToPanel: =>
      elem = @refs.sub_menu
      y = elem.offsetTop + 5
      if (elem)
          window.scrollTo(0, y)

  componentDidMount: =>

      data =
          obj_type: 'project'
          id: @props.project.id

      common.getSurveyableId(data, (result) =>
          if (result['surveyable_id'])
              @setState({ surveyable_id: result['surveyable_id'] })
          else if (result['error'])
              @setState({ msg: 'show_error' })
          if @props.show_this is 'survey_take_only'
              @setState({ mode: 'survey_take_only' })
      )

  updateSurveyableId: (surveyable_id) =>
      # this is when a surveyable_id has been created for the first time
      if not @state.surveyable_id
          @setState({
              surveyable_id: surveyable_id
              mode: 'updatedSurveyableId' })
      return

  surveySays: () =>
      if @state.mode is 'take_surveys' or @state.mode is 'survey_take_only'
          return(
              <div className='proj_listing'
                   ref='sub_menu'>
                  <GetSurveyList
                      surveyable_id={ @state.surveyable_id }
                      userid={ @props.userid } />
              </div>
          )
      else if (@state.mode is 'make_survey') or (@state.mode is 'edit_survey') or (@state.mode is 'updatedSurveyableId')
          return(
              <div className='proj_listing'
                   ref='sub_menu'>
                  <CreateOrEditSurvey
                      projectid={ @props.project.id }
                      surveyable_id={ @state.surveyable_id }
                      do_this={ @state.mode }
                      updateSurveyableId={ @updateSurveyableId } />
              </div>
          )
      else if @state.mode is 'view_responses'
          return(
              <div className='proj_listing'
                   ref='sub_menu'>
                  <GetSurveyResponses
                      surveyable_id={ @state.surveyable_id } />
              </div>
          )


  render: =>

      if @props.show_this is 'survey_take_only'
          <div className='container-fluid no-borders '>
              <strong>My Survey</strong>
              <br />
              { @surveySays() }
          </div>
      else if (@props.show_this is 'survey_make_or_take')
          <div className='container-fluid no-borders '>
              <strong>My Surveys</strong>
              <br />
              As the creator or chair you can:
              <div className="card-deck">
                  <div className="card light_gray">
                    <div className="card-body">
                        <h2 className="card-title">
                            <button className='btn btn-primary'
                                    onClick={ @toggleTakeSurveys.bind(this,"take_surveys") }>
                                take surveys
                            </button>
                        </h2>
                        <p className="card-text">
                          Take the surveys you already created.
                        </p>
                    </div>
                  </div>
                  <div className="card light_gray">
                    <div className="card-body">
                        <h2 className="card-title">
                            <button className='btn btn-primary mr-3'
                                    onClick={ @toggleTakeSurveys.bind(this,"make_survey") }>
                                create survey
                            </button>
                        </h2>
                        <p className="card-text">
                          Make a new survey (limit 3).
                        </p>
                    </div>
                  </div>
                  <div className="card light_gray">
                    <div className="card-body">
                        <h2 className="card-title">
                            <button className='btn btn-primary'
                                    onClick={ @toggleTakeSurveys.bind(this,"edit_survey") }>
                                edit surveys
                            </button>
                        </h2>
                        <p className="card-text">
                          Or delete a survey.
                        </p>
                    </div>
                  </div>
                  <div className="card light_gray">
                    <div className="card-body">
                        <h2 className="card-title">
                            <button className='btn btn-primary'
                                    onClick={ @toggleTakeSurveys.bind(this,"view_responses") }>
                                view survey results
                            </button>
                        </h2>
                        <p className="card-text">
                          View how many and who answered what.
                        </p>
                    </div>
                  </div>
              </div>
              { @surveySays() }
          </div>
