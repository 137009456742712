common = require('../common')
Dropzone = require('react-dropzone').default
DocumentFrame = require('./document_frame')
Loading = require('../loading')

module.exports = class PosterButton extends React.Component
    displayName: 'PosterButton'

    constructor: (props) ->
        super(props)
        note = @props.note
        @state = {
            note: note,
            mode: 'reading',
            files: [],
            blawbcontent: '',
            blawbid: ''
            poster: ''
            empty_submission: false
        }

    componentDidMount: =>

        data =
            projectid: @props.note.project_id
            paperseriesid: @props.note.paperseries_id

        common.getPosterFromNoteInfo(data, (result) =>
            if result.poster
                empty_submission = false
                if result.poster.body_blawb_id is 'empty_submission'
                    empty_submission = true
                @setState({
                    poster: result.poster
                    empty_submission: empty_submission })
        )


    updateUserPoster: () =>
        # in backend, generate a new version of poster without copying the poster
        @setState({blawbid: '' })
        data =
            noteid: @props.note.id

        common.updateProjectPaperSeriesPoster(data, (result) =>
            updated_poster = @state.poster
            updated_poster.id = result.posterid
            updated_poster.body_blawb_id = null
            @setState({poster: updated_poster})
        )

    viewUserPoster: () =>
        @setState({mode: 'viewing'})

    uploadUserPoster: () =>
        # place keeper
        @setState({mode: 'uploading'})

    acceptedFiles: (hm) =>
        # console.log(hm)

    onDrop: (upload_files) =>
        @setState({ files: upload_files })
        @uploadFiles(upload_files)

    uploadFiles: (files)=>
        if files.length is 0
            alert('No valid files selected.')
            @setState({ mode: 'reading' })
            return
        else
            @setState({ mode: 'loading' })

        # using noteid enables project lookup as well as paperseriesid
        noteid = @props.note.id
        common.submitPaperSeriesPoster(files, noteid, (rs) =>
            result = JSON.parse(rs)
            @setState({ mode: 'reading', blawbid: 'blawbid' })
        )

    gotoHidePoster: () =>
        @setState({ mode: 'reading' })

    viewPosterinNewWindow: () =>
        window.open('/project/' + @props.note.project_id + '/title/' +
                    @props.note.paperseries_id + '/poster')

    render: =>

        if @props.user_is_author is 'true'
            delete_button =
                  <button className="btn btn-link btn-sm text-danger ml-1"
                      onClick={@updateUserPoster}>delete poster &nbsp;</button>
            upload_button =
                <span>
                    <button className='btn btn-link btn-items mr-2'
                        onClick={@uploadUserPoster}>upload poster (PDF or LaTeX)</button>
                </span>
        else
            delete_button = <span></span>
            upload_button = <span></span>


        if @state.mode is 'reading'
            if ((@state.poster.body_blawb_id) or (@state.blawbid)) and !(@state.empty_submission)
                poster_button =
                    <span>
                        <button className='btn btn-link btn-items mr-2'
                            onClick={@viewPosterinNewWindow}>Poster</button>
                        { delete_button }
                    </span>
            else
                poster_button =
                    <span>{ upload_button }</span>


        else if @state.mode is 'uploading'
            poster_button =
                <div>
                    <Dropzone className='container'
                              onDrop={@onDrop}
                              multiple={false}
                              accept="application/pdf,application/x-latex,application/x-tex,text/x-latex,text/x-tex,*.pdf,*.latex,*.tex">
                              {({getRootProps, getInputProps}) => (
                                  <div {...getRootProps({className: 'dropzone'})}>
                                      <input {...getInputProps()} />
                                      <p>
                                          Drag and drop PDF or LaTeX document here, or click to select a file
                                      </p>
                                  </div>
                              )}
                    </Dropzone>
                </div>

        else if @state.mode is 'viewing'
            filename = "/view_paper_poster/" + @props.note.id
            poster_button =
                <div>
                    <button className="btn btn-link btn-sm" onClick={@gotoHidePoster}>hide poster</button>
                    <DocumentFrame className="mini_view_document_iframe" src={filename} />
                    <button className="btn btn-link btn-sm" onClick={@gotoHidePoster}>hide poster</button>
                </div>
        else if @state.mode is 'loading'
            poster_button =
                <Loading />

        if (@props.user_is_author is 'true') or (@state.poster.body_blawb_id) or (@state.blawbid)
            <span className='mr-2'>
                {poster_button}
            </span>
        else
            <span></span>
