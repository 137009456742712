
module.exports = class ToolchestPermsButton extends React.Component
  displayName: 'ToolchestPermsButton'

  constructor: (props) ->
      super(props)
      # Expects: @props.
      # my_accepted_roles
      @state = { }

  render: =>

      if @props.tool_selected is 'perms_tools'
          card_className = 'card light_green'
      else
          card_className = 'card light_gray'

      <div className={card_className}>
        <div className="card-body">
            <h2 className="card-title">
                <button className='btn btn-primary'
                        onClick={ @props.onClick.bind(this, {tools: 'perms_tools', show_this: 'perms_tools'}) }>
                    permissions
                </button>
            </h2>
            <p className="card-text">
                Set the conference permissions.
            </p>
        </div>
      </div>
