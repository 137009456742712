
module.exports = class SortMyConferencesList extends React.Component
  displayName: 'SortMyConferencesList'

  sortBy: (sort_by_this) =>
      # conferences: all, creator, contributor, chair, areachair,
      # reviewer, following, bookmark
      # projects: all, creator, group (?), following, bookmark
      @props.getThisSortType(sort_by_this)


  makeButton: (selection) =>
      if @props.subset is selection
          <button className='nav-item-button'>
              <strong>{selection}</strong>
          </button>
      else
          <button className='nav-item-button'
                  onClick={@sortBy.bind(this, selection)}>
              {selection}
          </button>


  render: =>
      if @props.ptype is 'conference'
          <span>
              Sort by my role: &nbsp;
              { @makeButton('all') }
              { @makeButton('creator') }
              { @makeButton('contributor') }
              { @makeButton('chair') }
              { @makeButton('areachair') }
              { @makeButton('reviewer') }
              { @makeButton('following') }
              { @makeButton('bookmark') }
          </span>
      else if @props.ptype is 'project'
          <span>
              Sort by my role: &nbsp;
              { @makeButton('all') }
              { @makeButton('creator') }
              { @makeButton('following') }
              { @makeButton('bookmark') }
          </span>
      else
          <span>
              Sort by my role: &nbsp;
              { @makeButton('all') }
              { @makeButton('creator') }
              { @makeButton('contributor') }
              { @makeButton('following') }
              { @makeButton('bookmark') }
          </span>
