Link = require('react-router-dom').Link
common = require('../common')
HeaderBar = require('../header_bar')
GoogleLogin = require('./google_login')

# Global navigation for the app

module.exports = class LoginRedirect extends React.Component
  displayName: 'LoginRedirect'

  constructor: (props) ->
      super(props)
      @state = {
          redirect: ''
      }

  componentDidMount: =>

      document.title = 'ZeroDivZero login'

      redirect = '/'
      if @props.location.search
          redirect = @props.location.search.slice(1)
      @setState({ redirect: redirect })

      if localStorage
          localStorage.setItem('redirect', JSON.stringify(redirect))

  reload: () =>
    window.location = '/'

  reloadPage: () =>
    redirect = @state.redirect

    # some pages will just redirect here again, so we need to redirect to the base.
    words = redirect.split('/')
    Lwords = words.length
    if words.slice(Lwords-2, Lwords-1)[0] is 'tools'
      redirect = words.slice(0, Lwords-2).join('/')
    window.location.href = redirect

  render: =>

      <span>
        <br />
        <br />
        <div className='container-fluid'>
            <Link to="/">
                <button className='button-link-big-header clear_button'
                    onClick={@reload}>
                &nbsp;&nbsp;&divide;&nbsp;ZeroDivZero
                </button>
            </Link>
            <HeaderBar redirect={@state.redirect}
                       mode={'just_show_login_buttons'} />
            <div className='row no-gutters'>
                <div className="p-3 m-3">
                    <p>
                        <h1 className='font-weight-bold'>Welcome!</h1>
                    </p>
                    <p className='text text-primary'>
                        You will be redirected after successful login.
                    </p>
                </div>
            </div>
            <div className='row no-gutters p-3 m-3'>
                <button className='button-link clear_button'
                    onClick={@reloadPage}>
                    &larr; return
                </button>
            </div>
        </div>
      </span>
