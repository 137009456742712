AuthorNameRoll = require('../authorcontact/author_name_roll')

module.exports = class AnswerStatsRoll extends React.Component
  displayName: 'AnswerStatsRoll'

  constructor: (props) ->
      super(props)
      # uses Authorname roll which expects:
      #   @props.authorcontat.name
      #   @props.authorcontact.user_id
      @state = {

      }

  render: =>

      if @props.choice.mode is 'show_users'
          <div className='container fluid'>
              <div className='row no-gutters'>
                  { @props.choice_word }
              </div>
              <div className='row no-gutters'>
                      {@props.choice.users.map((user) =>
                          <AuthorNameRoll key={user.id} authorcontact={user} />
                      )}
              </div>
          </div>
      else
          # show aggregated votes
          <div className='container fluid'>
              <div className='row no-gutters'>
                  <button className='btn btn-primary btn-txt-sm mr-3'
                          onClick={ @props.onClick }>
                      { @props.choice.count} users
                  </button>
                  { @props.choice_word }
              </div>
          </div>
