common = require('../common')
Link = require('react-router-dom').Link
FrontPageBubbleRoll = require('./frontpage_bubble_roll')
Loading = require('../loading')

module.exports = class HelloWorldLeftPanel extends React.Component
  displayName: 'HelloWorldLeftPanel'

  constructor: (props) ->
      super(props)
      initial_tab = 'latest'
      @state = {
          projects: []
          logged_in: false
          initial_tab: initial_tab
          update_alerts: []
      }
      @confButtonRef = React.createRef()
      @seminarButtonRef = React.createRef()
      @shortCourseButtonRef = React.createRef()
      @labButtonRef = React.createRef()
      @personalButtonRef = React.createRef()

  getProjectBubbles: (ptype) =>
      data =
          ptype: ptype
          page_num: 1
          num_per_page: 5

      common.getFrontPageProjectsByPtype(data, (result) =>
          @setState({ projects: result.projects })
          )

  getProjectAlerts: () =>
      data =
          page_num: 1
          num_per_page: 5

      common.getFrontPageProjectNewUpdatesCount(data, (result) =>
          @setState({ update_alerts: result.updates_count })
          )

  setCurrentUser: (data) =>
      current_user = data['current_user']
      verified = data['verified']
      logged_in = false
      if (current_user) and (verified is 'true')
          logged_in = true
      @setState({logged_in: logged_in})

  componentDidMount: =>
      # pull out the search term from the URL
      # could be: latest, conference, project, lab_group, or seminar
      get_this = window.location.search.split('?')
      if get_this.length > 1
        get_this = get_this[1]
      else
        get_this = 'conference'
      @activateButton(get_this)
      callback = (data) => this.setCurrentUser(data)
      common.loginMonitor(callback)
      @getProjectAlerts()

  activateButton: (active_button) =>
      if active_button is 'conference'
          @confButtonRef.current.className='homepage-menu-button-focus'
          @seminarButtonRef.current.className='homepage-menu-button'
          @shortCourseButtonRef.current.className='homepage-menu-button'
          @labButtonRef.current.className='homepage-menu-button'
          @personalButtonRef.current.className='homepage-menu-button'
          @getProjectBubbles('conference')
      else if active_button is 'seminar'
          @confButtonRef.current.className='homepage-menu-button'
          @seminarButtonRef.current.className='homepage-menu-button-focus'
          @shortCourseButtonRef.current.className='homepage-menu-button'
          @labButtonRef.current.className='homepage-menu-button'
          @personalButtonRef.current.className='homepage-menu-button'
          @getProjectBubbles('seminar')
      else if active_button is 'short_course'
          @confButtonRef.current.className='homepage-menu-button'
          @seminarButtonRef.current.className='homepage-menu-button'
          @shortCourseButtonRef.current.className='homepage-menu-button-focus'
          @labButtonRef.current.className='homepage-menu-button'
          @personalButtonRef.current.className='homepage-menu-button'
          @getProjectBubbles('short_course')
      else if active_button is 'lab_group'
          @confButtonRef.current.className='homepage-menu-button'
          @seminarButtonRef.current.className='homepage-menu-button'
          @shortCourseButtonRef.current.className='homepage-menu-button'
          @labButtonRef.current.className='homepage-menu-button-focus'
          @personalButtonRef.current.className='homepage-menu-button'
          @getProjectBubbles('lab_group')
      else if active_button is 'personal'
          @confButtonRef.current.className='homepage-menu-button'
          @seminarButtonRef.current.className='homepage-menu-button'
          @shortCourseButtonRef.current.className='homepage-menu-button'
          @labButtonRef.current.className='homepage-menu-button'
          @personalButtonRef.current.className='homepage-menu-button-focus'
          @getProjectBubbles('personal')

  badgeCount: (project_type)=>
      for alert in @state.update_alerts
          if alert.ptype is project_type
              badge_count = alert.updates_count
      if badge_count > 0
          return (
              <sup class="badge badge-success">{badge_count}</sup>
          )

  render: =>


      <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <div className='m-3'>
                  <br />
                  <span className='homepage-border'>

                      <button className='homepage-menu-button-focus'
                          ref={@confButtonRef}
                          onClick={@activateButton.bind(this, 'conference')}>
                          Conferences {@badgeCount('conference')}
                      </button>
                      <button className='homepage-menu-button'
                          ref={@seminarButtonRef}
                          onClick={@activateButton.bind(this, 'seminar')}>
                          Seminars {@badgeCount('seminar')}
                      </button>
                      <button className='homepage-menu-button'
                          ref={@shortCourseButtonRef}
                          onClick={@activateButton.bind(this, 'short_course')}>
                          Short Courses {@badgeCount('short_course')}
                      </button>
                      <button className='homepage-menu-button'
                          ref={@labButtonRef}
                          onClick={@activateButton.bind(this, 'lab_group')}>
                          Lab Groups {@badgeCount('lab_group')}
                      </button>
                      <button className='homepage-menu-button'
                          ref={@personalButtonRef}
                          onClick={@activateButton.bind(this, 'personal')}>
                          Projects {@badgeCount('personal')}
                      </button>
                  </span>
                  <br />
                  <br />
                  <div className=''>
                      {@state.projects.map((project) =>
                          <FrontPageBubbleRoll key={project.id}
                              project={project}
                              logged_in={@state.logged_in} />
                        )}
                  </div>
              </div>
          </div>
      </div>
