
module.exports = class ConferenceFeeLineChoosable extends React.Component
  displayName: 'ConferenceFeeLineChoosable'

  render: =>
      <tr>
        <td>{ @props.fee.category } </td>
        <td>${ @props.fee.amount/100 } USD</td>
        <td><button className='btn btn-success'
                    onClick={@props.onClick}>select</button>
        </td>
      </tr>
