Link = require('react-router-dom').Link
common = require('./common')

module.exports = class AffiliationRole extends React.Component
  displayName: 'AffiliationRole'

  gotoTag: () =>
      # place holder

  render: =>
      <div className='authornameroll_div'>
        <button className='btn btn-primary btn-txt-sm'
                onClick={@gotoTag}>{@props.affiliation.name}</button>
      </div>
