
module.exports = class HelmDatesButton extends React.Component
  displayName: 'HelmDatesButton'

  constructor: (props) ->
      super(props)
      # Expects: @props.
      # my_accepted_roles
      @state = {

      }

  render: =>

      if @props.tool_selected is 'dates'
          card_className = 'card light_green'
      else
          card_className = 'card light_gray'

      if ('creator' in @props.my_accepted_roles) or ('chair' in @props.my_accepted_roles)
          <div className={card_className}>
            <div className="card-body">
                <h2 className="card-title">
                    <button className='btn btn-primary'
                            onClick={ @props.onClick.bind(this, {tools: 'dates', show_this: 'show_dates'}) }>
                        important dates
                    </button>
                </h2>
                <p className="card-text">
                    Set important dates like the submission date.
                </p>
            </div>
          </div>
