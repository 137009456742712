common = require('../common')
Loading = require('../loading')
SurveyOverviewRoll = require('./survey_overview_roll')
TakeSurvey = require('./take_survey')

module.exports = class GetSurveyList extends React.Component
  constructor: (props) ->
      super(props)
      @state = {
          mode: 'loading'
          surveys: []
          survey_id: ''
          msg: ''
      }

  componentDidMount: () =>
      if (@props.surveyable_id)
          data =
            surveyable_id: @props.surveyable_id
            add_ons: ['response_counts','user_progress']

          common.getSurveyList(data, (result) =>
              msg = 'There are a few surveys to fill out...'
              if result['survey_list'].length is 0
                  msg = 'No surveys yet!'
              @setState({
                    mode: 'show_survey_list'
                    surveys: result['survey_list']
                    msg: msg
              })
          )

      else
          @setState({
              mode: 'show_survey_list'
              surveys:[]
              msg: 'No surveys yet!'})

  loadSurvey: (survey) =>
      @setState({
          mode: 'take_survey',
          survey_id: survey.id
          })

  showSurveyList: (updated) =>
      surveys = @state.surveys
      for survey in surveys
          if survey.id == @state.survey_id
              survey.progress = updated.progress
              survey.user_counts['user_answer_count'] = updated.questions_answered

      @setState({
            mode: 'show_survey_list'
            surveys: surveys
            })

  render: =>
      if @state.mode is 'loading'
          <div>
              <Loading />
          </div>
      else if @state.mode is 'show_survey_list'
          <div>
              { @state.msg }
              { @state.surveys.map((survey) =>
                  <SurveyOverviewRoll key={ survey.id }
                              title={ survey.title }
                              reason={ survey.reason }
                              mode={ 'progress' }
                              progress={ survey.progress }
                              response_count={ survey.response_count }
                              user_counts={ survey.user_counts }
                              onClick={ @loadSurvey.bind(this, survey) } />
              , this)}
          </div>
      else
          <div className='border border-primary border_rad_tenpx p-3'>
              <TakeSurvey key={ @state.survey_id }
                          survey_id={ @state.survey_id }
                          showSurveyList={ @showSurveyList }
                          userid={ @props.userid }/>
          </div>
