common = require('../common')
GetDatePlace = require('./get_date_place')
TimePlace = require('./time_place')

module.exports = class SeminarScheduler extends React.Component
  displayName: 'SeminarScheduler'

  constructor: (props) ->
      super(props)
      @state = {
          clocks: []
          places: []
      }

  render: =>

      if (@props.user_can_edit is 'true')
          <span>
              <TimePlace spottable_id={ @props.note.spottable_id }
                  id={ @props.note.id }
                  projectid={ @props.note.project_id }
                  obj_type={ 'note' }
                  clocks={ @props.clocks }
                  places={ @props.places } />
          </span>
      else if @props.note.spottable_id
          <div>
              <span>
                  <GetDatePlace spottable_id={@props.note.spottable_id} />
              </span>
          </div>
      else if @props
          <div>
              <span className='text-danger'>
                  Date/time TBD
              </span>
          </div>
      else
          <span></span>
