Link = require('react-router-dom').Link
common = require('./common')

module.exports = class CreateProjectForm extends React.Component
  displayName: 'CreateProjectForm'

  constructor: (props) ->
      super(props)
      @state = {
          title: ''
          data: {title: ''}
          mode: 'get title'
          tags: []
          perms: []
          editor: 'MathJax'
          show_tease_msg: 'false'
          get_title: 'true'
          error_msg: ''
          show_tags: 'false'
          make_perms: 'false'
          recurs: 'yes' }
      @titleRef = React.createRef()
      @tagsRef = React.createRef()

  setInitialPerms: () =>
      if @props.ptype is 'personal'
          perms = ['_open_project_perm','_open_submission_perm']
          tags = []
      else if @props.ptype is 'lab_group'
          perms = ['_open_project_perm','_open_submission_perm','_following_pre_read_perm']
          tags = []
      else if @props.ptype is 'conference'
          perms = ['_open_project_perm','_open_submission_perm','_following_pre_read_perm','_reviews_shared']
          tags = []
      else if @props.ptype is 'seminar'
          perms = ['_open_project_perm','_open_submission_perm','_following_pre_read_perm']
          tags = []
      else
          # ptype = 'short_course'
          perms = ['_open_project_perm','_following_pre_read_perm']
          tags = []
      @setState({
          tags: tags
          perms: perms
          title: '' })

  componentDidMount: () =>
      @setInitialPerms()

  componentDidUpdate: (prevProps) =>
      if @props.ptype != prevProps.ptype
          @setInitialPerms()

  getPerms: () =>
      title = @titleRef.current.value
      if (@props.ptype is 'personal')
          @setState({ get_title: 'false', make_perms: 'true', title: title })
      else
          # if ptype is lab_group, conference, seminar, short_course
          @setState({ get_title: 'false', make_perms: 'true',  show_tags: 'true', title: title })

  yesRecurring: () =>
      @setState({ recurs : 'yes' })
      @refs.yes_recurring.style.backgroundColor='#A0EBB2'
      @refs.no_recurring.style.backgroundColor='#cccccc'

  noRecurring: () =>
      @setState({ recurs : 'no' })
      @refs.yes_recurring.style.backgroundColor='#cccccc'
      @refs.no_recurring.style.backgroundColor='#A0EBB2'

  yesOpenProject: () =>
      updated_perms = @state.perms
      # avoid duplicates
      index = updated_perms.indexOf('_open_project_perm')
      if index < 0
          updated_perms.push( '_open_project_perm' )
      @setState({perms : updated_perms})
      @refs.yes_open_project.style.backgroundColor='#A0EBB2'
      @refs.no_open_project.style.backgroundColor='#cccccc'

  noOpenProject: () =>
      updated_perms = @state.perms
      index = updated_perms.indexOf('_open_project_perm')
      if index > -1
          updated_perms.splice(index,1)
      @setState({perms : updated_perms})
      @refs.yes_open_project.style.backgroundColor='#cccccc'
      @refs.no_open_project.style.backgroundColor='#A0EBB2'

  yesOpenSubmission: () =>
      updated_perms = @state.perms
      # avoid duplicates
      index = updated_perms.indexOf('_open_submission_perm')
      if index < 0
          updated_perms.push( '_open_submission_perm' )
      @setState({ perms : updated_perms })
      @refs.yes_open_submission.style.backgroundColor='#A0EBB2'
      @refs.no_open_submission.style.backgroundColor='#cccccc'

  noOpenSubmission: () =>
      updated_perms = @state.perms
      index = updated_perms.indexOf('_open_submission_perm')
      if index > -1
          updated_perms.splice(index,1)
      @setState({ perms : updated_perms })
      @refs.yes_open_submission.style.backgroundColor='#cccccc'
      @refs.no_open_submission.style.backgroundColor='#A0EBB2'

  yesSpectatorPreRead: () =>
      updated_perms = @state.perms
      # avoid duplicates
      index = updated_perms.indexOf('_following_pre_read_perm')
      if index < 0
          updated_perms.push( '_following_pre_read_perm' )
      @setState({ perms : updated_perms })
      @refs.yes_following_preread.style.backgroundColor='#A0EBB2'
      @refs.no_following_preread.style.backgroundColor='#cccccc'

  noSpectatorPreRead: () =>
      updated_perms = @state.perms
      index = updated_perms.indexOf('_following_pre_read_perm')
      if index > -1
          updated_perms.splice(index,1)
      @setState({ perms : updated_perms })
      @refs.yes_following_preread.style.backgroundColor='#cccccc'
      @refs.no_following_preread.style.backgroundColor='#A0EBB2'

  yesRevealReviewerName: () =>
      updated_perms = @state.perms
      # avoid duplicates
      index = updated_perms.indexOf('_reveal_reviewer_name')
      if index < 0
          updated_perms.push( '_reveal_reviewer_name' )
      @setState({ perms : updated_perms })
      @refs.yes_reveal_name.style.backgroundColor='#A0EBB2'
      @refs.no_reveal_name.style.backgroundColor='#cccccc'

  noRevealReviewerName: () =>
      updated_perms = @state.perms
      index = updated_perms.indexOf('_reveal_reviewer_name')
      if index > -1
          updated_perms.splice(index,1)
      @setState({ perms : updated_perms })
      @refs.yes_reveal_name.style.backgroundColor='#cccccc'
      @refs.no_reveal_name.style.backgroundColor='#A0EBB2'

  yesChooseAnon: () =>
      updated_perms = @state.perms
      # avoid duplicates
      index = updated_perms.indexOf('_reviewer_anon_choice')
      if index < 0
          updated_perms.push( '_reviewer_anon_choice' )
      @setState({ perms : updated_perms })
      @refs.yes_choose_anon.style.backgroundColor='#A0EBB2'
      @refs.no_choose_anon.style.backgroundColor='#cccccc'

  noChooseAnon: () =>
      updated_perms = @state.perms
      index = updated_perms.indexOf('_reviewer_anon_choice')
      if index > -1
          updated_perms.splice(index,1)
      @setState({ perms : updated_perms })
      @refs.yes_choose_anon.style.backgroundColor='#cccccc'
      @refs.no_choose_anon.style.backgroundColor='#A0EBB2'

  yesShowReviews: () =>
      updated_perms = @state.perms
      # avoid duplicates
      index = updated_perms.indexOf('_reviews_shared')
      if index < 0
          updated_perms.push( '_reviews_shared' )
      @setState({ perms : updated_perms })
      @refs.yes_show_reviews.style.backgroundColor='#A0EBB2'
      @refs.no_show_reviews.style.backgroundColor='#cccccc'

  noShowReviews: () =>
      updated_perms = @state.perms
      index = updated_perms.indexOf('_reviews_shared')
      if index > -1
          updated_perms.splice(index,1)
      @setState({ perms : updated_perms })
      @refs.yes_show_reviews.style.backgroundColor='#cccccc'
      @refs.no_show_reviews.style.backgroundColor='#A0EBB2'

  yesLatex: () =>
      @setState({editor : 'MathJax'})
      @refs.yes_latex.style.backgroundColor='#A0EBB2'
      @refs.no_latex.style.backgroundColor='#cccccc'

  noLatex: () =>
      @setState({editor : 'SetInnerHTML'})
      @refs.yes_latex.style.backgroundColor='#cccccc'
      @refs.no_latex.style.backgroundColor='#A0EBB2'


  makeProject: () =>

      title = @titleRef.current.value
      if title.length < 1
          @setState({error_msg: 'Please enter a title.'})
          return

      if @state.show_tags is 'true'
          tags = @tagsRef.current.value

      if (@props.ptype is 'personal')
          data =
              title: title
              perms: @state.perms
              editor: @state.editor
              ptype: 'project'
      else
          data =
              title: title
              perms: @state.perms
              tags: tags
              series_status: @state.recurs
              editor: @state.editor
              ptype: @props.ptype

      common.createProjectForm(data, (result) =>
          @setState(data)
          id = result['res']['id']
          ptype = @props.ptype
          if ptype is 'personal'
            ptype = 'project'
          window.location = '/' + ptype + '/' + id + '/titles'
      )

  titleRow: () =>
      if @state.get_title is 'true'
          return(
              <div className="input-group">
                <input ref={@titleRef}
                    type="text"
                    className="form-control form-txt-lg"
                    placeholder="Enter your topic name..."
                    aria-label="project title"
                    aria-describedby="basic-addon2" >
                </input>
                <div className="input-group-append">
                  <button className="btn btn-primary" onClick={@getPerms}>
                      Start!
                  </button>
                </div>
              </div>
          )
      else
          return(
              <input ref={@titleRef}
                  type="text"
                  defaultValue={@state.title} />
          )

  tagsBox: () =>
      if @state.show_tags is 'true'
          if @state.ptype is 'conference'
              word = 'Sessions'
          else
              word = 'Topics'
          return(
            <div className='mt-3'>
                <strong>{word} (please separate with commas): </strong>
                <input className='headerInput'
                    type="text"
                    ref={@tagsRef}
                    placeholder="Session names to organize your conference (optional)..."  />
            </div>
          )

  saveButton: () =>
      if (@state.make_perms is 'true') and (@props.ptype != 'conference')
          return(
              <div className="mt-3">
                <button className="btn btn-primary" onClick={@makeProject}>Save</button>
              </div>
          )
      else if (@state.make_perms is 'true') and (@props.ptype is 'conference')
          return(
              <div className="mt-2">
                <button className="btn btn-primary" onClick={@makeProject}>Save</button>
              </div>
          )

  permsSelections: () =>
      if (@state.make_perms is 'true') and (@props.ptype != 'conference')
          return(
              <div>
                <div>
                  <button className="proj_open_btn" ref='yes_latex' onClick={@yesLatex}>Yes</button>
                  <button className="proj_rightpad_btn" ref='no_latex' onClick={@noLatex}>No</button>
                  Use LaTeX for title/description?
                </div>
              </div>
          )
      else if (@state.make_perms is 'true') and (@props.ptype is 'conference')
          return(
              <div>
                <div>
                  <button className="proj_open_btn" ref='yes_reveal_name' onClick={@yesRevealReviewerName}>Yes</button>
                  <button className="proj_rightpad_btn" ref='no_reveal_name' onClick={@noRevealReviewerName}>No</button>
                  Set the default to reveal reviewer names?
                </div>
                <div>
                  <button className="proj_open_btn" ref='yes_choose_anon' onClick={@yesChooseAnon}>Yes</button>
                  <button className="proj_rightpad_btn" ref='no_choose_anon' onClick={@noChooseAnon}>No</button>
                  ...but let each reviewer decide to be anonymous or public?
                </div>
                <div>
                  <button className="proj_open_btn" ref='yes_latex' onClick={@yesLatex}>Yes</button>
                  <button className="proj_rightpad_btn" ref='no_latex' onClick={@noLatex}>No</button>
                  Use LaTeX for title/description?
                </div>
              </div>
          )

  futurePermSelections: () =>
      <span>
          <div>
            <button className="proj_open_btn" ref='yes_open_conference' onClick={@yesOpenConference}>Yes</button>
            <button className="proj_rightpad_btn" ref='no_open_conference' onClick={@noOpenConference}>No</button>
            Open access? -- Cannot change this option right now, soon!
          </div>
          <div>
            <button className="proj_open_btn" ref='yes_open_submission' onClick={@yesOpenSubmission}>Yes</button>
            <button className="proj_rightpad_btn" ref='no_open_submission' onClick={@noOpenSubmission}>No</button>
            Can anyone submit papers? -- Cannot change this option right now, soon!
          </div>
          <div>
            <button className="proj_open_btn" ref='yes_following_preread' onClick={@yesSpectatorPreRead}>Yes</button>
            <button className="proj_rightpad_btn" ref='no_following_preread' onClick={@noSpectatorPreRead}>No</button>
            Anyone can read submissions before acceptance? -- Cannot change this option right now, soon!
          </div>
          <div>
            <button className="proj_open_btn" ref='yes_show_reviews' onClick={@yesShowReviews}>Yes</button>
            <button className="proj_rightpad_btn" ref='no_show_reviews' onClick={@noShowReviews}>No</button>
            Show reviews? -- Cannot change this option right now. Soon!
          </div>
      </span>

  getRightWord: () =>
      return(
          @props.ptype.replace(/_/g,' ')
      )



  render: =>

      if (@props.ptype != 'personal')
          <div className='col m-0 m-0 p-0 light_gray border_rad_tenpx'>
            { @props.random_text }
            <div className='col m-0 pr-2 pl-2'>
                <h2 className='pt-3'> Create a {@getRightWord()}... </h2>
                <br />
                { @titleRow() }
                { @tagsBox() }
                { @permsSelections() }
                { @saveButton() }
                <span className='text-danger'> &nbsp; { @state.error_msg }</span>
                <div>
                    These options can be changed later.
                </div>
            </div>
          </div>
      else if (@props.ptype is 'personal')
          <div className='col m-0 p-0 light_gray border_rad_tenpx'>
            {@props.random_text}
            <div className='col m-0 pr-2 pl-2'>
                <h2 className='pt-3'> Enter a topic... </h2>
                Organize your {@getRightWord()} with topics:
                <br />
                { @titleRow() }
                { @permsSelections() }
                { @saveButton() }
                <span className='text-danger'> &nbsp; {@state.error_msg}</span>
                <div>
                    These options can be changed later.
                </div>
            </div>
          </div>
      else
          <div>
              Something went wrong. Please try refreshing the page.
          </div>
