Link = require('react-router-dom').Link
ConferenceSummaryLine = require('../conference_summary_line')
Pagination = require('../pagination')
common = require('../common')

module.exports = class UserProfileProjectGroups extends React.Component
  displayName: 'UserProfileProjectGroups'

  constructor: (props) ->
      super(props)

      @state = {
        projects: []
        page_num: 1
        lengthy: 2
        num_per_page: 3
      }

  componentDidMount: =>

      data =
          page_num: 1
          num_per_page: @state.num_per_page
          userid: @props.userid
          subset: 'all'
          ptype: @props.ptype

      common.getTotalNumberConferencesByUserid(data, (result) =>
        lengthy = Math.ceil(result.total/@state.num_per_page)
        @setState({ lengthy: lengthy})
      )

      common.fetchUserConferences(data, (result) =>
          projects = result.results
          @setState({projects: projects})
      )


  getThesePages: (info) =>

      if not (info)
          info=1

      data =
          page_num: info
          num_per_page: @state.num_per_page
          userid: @props.userid
          ptype: @props.ptype

      common.fetchUserConferences(data, (result) =>
          projects = result.results
          @setState({projects: projects, page_num: info})
          elem = @refs.conference_list
          window.scrollTo(0, elem.offsetTop)
      )

  msgPhrase: () =>
      if @props.ptype is 'lab_group'
          <span>Not part of any lab groups yet.</span>
      else if @props.ptype is 'conference'
          <span>No conferences yet.</span>
      else if @props.ptype is 'personal'
          <span>No projects yet.</span>
      else if @props.ptype is 'seminar'
          <span>Has not participated in a seminar yet.</span>
      else if @props.ptype is 'short_course'
          <span>Has not enrolled in a short course yet.</span>


  render: =>

      if @state.projects.length == 0
          msg =
            <div>
                {@msgPhrase()}
            </div>
      else
          msg = <span></span>

      <div ref='conference_list'>
          { msg }
          {@state.projects.map( (project) =>
            <ConferenceSummaryLine key={project.id}
                project={project}
                userid={@props.userid} />
          )}
          <Pagination page_num={@state.page_num}
              lengthy={@state.lengthy}
              onClick={@getThesePages} />
      </div>
