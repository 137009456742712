module.exports = class EditorChoice extends React.Component
  displayName: 'EditorChoice'

  # If value is valid, return it; otherwise, force it to "MathJax"
  @coerceEditor: (value) ->
      if value? and value in ["MathJax", "SetInnerHTML"] then value else "MathJax"

  constructor: (props) ->
    super(props)
    @state = { editor: EditorChoice.coerceEditor(@props.editor) }

  getEditor: =>
      return @state.editor

  setEditor: (value) =>
      @setState({editor: EditorChoice.coerceEditor(value)})

  render: =>
      [mathjax_style, text_style] =
          if @state.editor == "MathJax"
              ["proj_open_btn", "proj_rightpad_btn"]
          else
              ["proj_rightpad_btn", "proj_open_btn"]

      <div>
        <button className={mathjax_style} onClick={@setEditor.bind(this, 'MathJax')}>MathJax</button>
        <button className={text_style} onClick={@setEditor.bind(this, 'SetInnerHTML')}>Text</button>
      </div>
