common = require('../common')
EditorChoice = require('../editor_choice')
GetWriting = require('../get_writing')
Title = require('../titles/title')
Writing = require('../writing')

module.exports = class ConferenceDetailsTools extends React.Component
  displayName: 'ConferenceDetailsTools'

  constructor: (props) ->
      super(props)
      # can receive a fn setUpdatedDetails from parent to update
      # these fields in the parent
      @state = {
          mode: 'reading'
          projEditor: @props.project.editor
          motive: @props.project.motive
          title: @props.project.title
      }
      @titleWritingRef = React.createRef()
      @motiveWritingRef = React.createRef()
      @editorChoiceRef = React.createRef()

  toggleReadEditMode: =>
      if (@state.mode is 'editing')
          @updateProjectFields()
      else
          @setState({mode: 'editing'})

  updateProjectFields: =>
      data =
          projectid: @props.project.id
          project_motive: @motiveWritingRef.current.getValue()
          project_title: @titleWritingRef.current.getValue()
          editor: @editorChoiceRef.current.getEditor()

      common.submitProjectFields(data, (result) =>
          @setState({
              mode: 'reading'
              motive: data.project_motive
              title: data.project_title
              editor: data.editor
          }, @props.setUpdatedDetails.bind(this, data))
      )

  updateTitle: (title) =>
      @setState({title: title})

  updateMotive: (motive) =>
      @setState({motive: motive})

  getBigPicture: () =>
      if @state.mode is 'editing'
          <div>
            <h3>Short description: </h3>
            <div className='proj_motive_CKEDitor'>
              <span>
                <GetWriting ref={@motiveWritingRef} key={@props.project.user_id}
                  defaultValue={@state.motive} rows='2' />
              </span>
            </div>
          </div>
      else
          <div>
            <h3>Short description: </h3>
            <div className='proj_motive_CKEDitor'>
              <span>
                <Writing editor={@state.projEditor} writing={@state.motive} />
              </span>
            </div>
          </div>

  titleRow: () =>
      if @state.mode is 'editing'
          <div className='proj_titleDiv'>
            <GetWriting ref={@titleWritingRef} key={@props.project.id}
              defaultValue={@state.title} rows='2' />
          </div>
      else
          <div className='text-left mb-1 pb-1 mt-1 pt-1'>
            <h2 className='big_em'>
              <Title title={@state.title} editor={@state.projEditor}/>
            </h2>
          </div>

  editorChoiceRow: () =>
      if @state.mode is 'editing'
          <div className='row no-gutters'>
            <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
              Editor:
            </div>
            <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
              <EditorChoice ref={@editorChoiceRef} editor={@state.projEditor} />
            </div>
          </div>

  saveOrEditButton: () =>
      if @state.mode is 'editing'
          <div className="proj_save_conference_div">
            <button className="btn btn-secondary btn-txt-sm"
                onClick={@updateProjectFields}>Save
            </button>
          </div>
      else
          <div className="proj_save_conference_div">
            <button className="btn btn-secondary btn-txt-sm"
                onClick={@toggleReadEditMode}>Edit
            </button>
          </div>



  render: =>

      <div className='pt-3 pb-3'>
          { @editorChoiceRow() }
          { @titleRow() }
          { @getBigPicture() }
          { @saveOrEditButton() }
      </div>
