common = require('../common')
Dropzone = require('react-dropzone').default
DocumentFrame = require('./document_frame')
Loading = require('../loading')

module.exports = class DocumentButton extends React.Component
    displayName: 'DocumentButton'

    constructor: (props) ->
        super(props)
        note = @props.note
        emtpy_submission = false
        if note.body_blawb_id is 'empty_submission'
            empty_submission = true
        @state = {
            note: note,
            mode: 'reading',
            files: [],
            blawbcontent: '',
            blawbid: '',
            document_filename: '/view_project_note_document/' + note.id
            empty_submission: empty_submission
        }

    updateUserDocument: () =>
        # in backendo, generate a new version of note without copying the document
        @setState({blawbid: ''})
        data = {noteid: @state.note.id}

        common.updateProjectNoteDocument(data, (result) =>
            updated_note = @state.note
            updated_note.id = result.noteid
            updated_note.body_blawb_id = null
            @setState({note: updated_note})
        )

    viewUserNoteDocument: () =>
        @setState({mode: 'viewing'})

    uploadUserNoteDocument: () =>
        @setState({mode: 'uploading'})

    onDrop: (upload_files) =>
        @setState({files: upload_files})
        @uploadFiles(upload_files)

    uploadFiles: (files) =>
        if files.length is 0
            alert('No valid files selected.')
            @setState({mode: 'reading'})
            return
        else
            @setState({mode: 'loading'})

        noteid = @state.note.id
        common.submitProjectNoteDocument(files, noteid, (rs) =>
            result = JSON.parse(rs)
            @setState({mode: 'reading', blawbid: 'blawbid'})
        )

    gotoHideDocument: () =>
        @setState({mode: 'reading'})

    viewDocumentinNewWindow: () =>
        window.open('/project/' + @state.note.project_id + '/title/' +
                    @state.note.paperseries_id + '/document')

    deleteBtn: () =>
        if @props.user_is_author is 'true'
            <button className="btn btn-link text-danger ml-1"
                onClick={@updateUserDocument}>delete document &nbsp;</button>

    uploadBtn: () =>
        if @props.user_is_author is 'true'
            <span>
                <button className='btn btn-link btn-items mr-2'
                   onClick={@uploadUserNoteDocument}>upload paper (PDF or LaTeX)</button>
                <span className='text-danger pointseven_em'>
                    &nbsp;(no copyright papers, use a preprint instead)
                </span>
            </span>


    render: =>

        if @state.mode is 'reading'
            if (@state.note.body_blawb_id) or (@state.blawbid)  and !(@state.empty_submission)
                document_button =
                    <span>
                        <button className='btn btn-link btn-items mr-2 same-line'
                            onClick={@viewDocumentinNewWindow}>
                            Paper
                        </button>
                        { @deleteBtn() }
                    </span>
            else
                document_button =
                    <span>{ @uploadBtn() }</span>

        else if @state.mode is 'uploading'
            document_button =
                <div>
                    <Dropzone className='container'
                              onDrop={@onDrop}
                              multiple={false}
                              accept="application/pdf,application/x-latex,application/x-tex,text/x-latex,text/x-tex,*.pdf,*.latex,*.tex">
                              {({getRootProps, getInputProps}) => (
                                  <div {...getRootProps({className: 'dropzone'})}>
                                      <input {...getInputProps()} />
                                      <p>
                                          Drag and drop PDF or LaTeX document here, or click to select a file
                                      </p>
                                  </div>
                              )}
                    </Dropzone>
                </div>
        else if @state.mode is 'viewing'
            filename = "/view_project_note_document/" + @state.note.id
            document_button =
                <div>
                    <button className="btn btn-link" onClick={@gotoHideDocument}>hide document</button>
                    <DocumentFrame className="mini_view_document_iframe" src={filename} />
                    <button className="btn btn-link" onClick={@gotoHideDocument}>hide document</button>
                </div>
        else if @state.mode is 'loading'
            document_button =
                <span>
                    <Loading />
                </span>

        if (@props.user_is_author is 'true') or (@state.note.body_blawb_id) or (@state.blawbid)
            <span className='mr-2'>{ document_button }</span>
        else
            <span></span>
