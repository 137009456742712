common = require('../common')
ReplicationRoll = require('./replication_roll')

module.exports = class TheStudied extends React.Component
  displayName: 'TheStudied'

  constructor: (props) ->
      super(props)
      # expects:
      # @props.replications
      # @props.editor
      @state = {
          err_msg: ''
      }

  render: =>


      if (@props.mode is 'view') and (@props.replications)
          <span>
              {@props.replications.map((replication) =>
                <ReplicationRoll key={replication.id}
                                 replication={replication}
                                 editor={@props.editor}/>
              )}
          </span>
      else
          <span></span>
