common = require('./common')
Link = require('react-router-dom').Link
TitleLink = require('./titles/title_link')
Motive = require('./motive')
RolesBarProject = require('./roles/roles_bar_project')
RoleRollProject = require('./roles/role_roll_project')

module.exports = class ProjectSummaryLine extends React.Component
  displayName: 'ProjectSummaryLine'

  constructor: (props) ->
      super(props)
      # expects @props.userid (among others)
      @state = {
        project: @props.project
        myroles: []
        my_accepted_roles: []
      }

  componentDidMount: () =>
      myroles = @props.project.roles
      my_accepted_roles = []
      for myrole in myroles
          myrole.id = myrole[0]
          myrole.role = myrole[1]
          myrole.role_accepted = myrole[2]
          if myrole.role_accepted is 'true'
              my_accepted_roles.push(myrole.role)
      @setState({myroles: myroles, my_accepted_roles: my_accepted_roles})

  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles})

  render: =>

      if @state.myroles
          roles_bar =
              <span>
                  <RolesBarProject project={@props.project} my_accepted_roles={@state.my_accepted_roles}
                    roles={@state.myroles} userid={@props.userid} view='mini'
                    setUpdatedRoles={@setUpdatedRoles} />
              </span>

      item_word = 'items'
      if @props.project.num_papers is 1
          item_word = 'item'

      <div className='container-fluid pr-0 pl-0 border'>
          <div className='note_Div'>
              {roles_bar}
              <br />
              <Link to={'/project/' + @props.project.id + '/titles'}>
                  <TitleLink title={@props.project.title} editor={@props.project.editor} />
              </Link>
              <div className='note_stats'>
                  {@props.project.num_papers}&nbsp;{item_word}
              </div>
          </div>
      </div>
