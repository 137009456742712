common = require('../common')
ConferenceSubmissionUserOwnedLists = require('../conference_submission_user_owned_lists')
FollowButton = require('../follow_button')
RoleRollConference = require('./role_roll_conference')

module.exports = class RolesBar extends React.Component
  displayName: 'RolesBar'

  constructor: (props) ->
      super(props)
      # expects:
      # @props.project (including id, tags, submission_deadline)
      # @props.my_accepted_roles aka 'rolewords' in some modules
      # @props.roles
      @state = {
          creator: false
          mode: 'view'
          can_submit: false
      }

  componentDidMount: =>
      if ('creator' in @props.my_accepted_roles) or ('chair' in @props.my_accepted_roles)
          @setState({creator: true})

      # who can submit?
      if @props.project.perms
          permlist = []
          for perm in @props.project.perms
              permlist.push(perm.word)
          if '_open_submission_perm' in permlist
              @setState({can_submit: true})
          else if ('creator' in @props.my_accepted_roles) \
               or ('invited' in @props.my_accepted_roles) \
               or ('contributor'in @props.my_accepted_roles)
              @setState({can_submit: true})

  componentDidUpdate: (prevProps) =>
      if prevProps.my_accepted_roles != @props.my_accepted_roles

          if ('creator' in @props.my_accepted_roles) or ('chair' in @props.my_accepted_roles)
              @setState({creator: true})
          else
              @setState({creator: false})

          # who can submit?
          permlist = []
          for perm in @props.project.perms
              permlist.push(perm.word)
          if '_open_submission_perm' in permlist
              @setState({can_submit: true})
          else if ('creator' in @props.my_accepted_roles) \
               or ('invited' in @props.my_accepted_roles)
              @setState({can_submit: true})
          else
              @setState({can_submit: false})

  submitToConference: =>
      if not @props.userid
          link = '/' + @props.project.ptype + '/' + @props.project.id + '/titles'
          window.location = '/login/?' + link

  showUploadTypes: =>
      @setState({mode: 'ask_upload_type'})

  setFollowRoles: (new_roles_are) =>
      @props.setUpdatedRoles(new_roles_are)

  updateRoleAcceptance: (rolejunction) =>
      rolejunctionid = rolejunction.id

      updated_roles = [ ]
      for role in @props.roles
        if role.id is rolejunctionid
          if role.role_accepted is 'true'
            role.role_accepted = 'false'
            updated_status = 'false'
          else
            role.role_accepted = 'true'
            updated_status = 'true'
        updated_roles.push(role)

      @props.setUpdatedRoles(updated_roles)

      tag_ids = [ ]
      if rolejunction.role_tag_list?
        for tag in rolejunction.role_tag_list
            tag_ids.push(tag.id)

      data =
          rolejunctionid: rolejunctionid
          role_accepted: updated_status
          tag_ids: tag_ids

      common.userUpdatesConferenceRole(data, (result) =>
          # no output!
          )


  render: =>

      user_conference_submission_list =
          <div className="modal fade"
              id="exampleModalLong"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <span className=""
                      id="exampleModalLongTitle">Choose a project
                  </span>
                  <button type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">

                    <span className='onepointtwo_em'>
                        <ConferenceSubmissionUserOwnedLists
                            conference={@props.project}
                            userid={@props.userid} />
                        <br />
                        <br />
                    </span>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>

      if @props.roles.length > @props.my_accepted_roles.length
          help_msg_on_roles =
              <span className="text-danger">
                  You need to accept your conference roles to enable the appropriate tools.
              </span>
      else
          help_msg_on_roles = <span></span>

      if 'following' not in @props.my_accepted_roles
          follow_request =
                <span>
                  <FollowButton projectid={@props.project.id} roles={@props.roles}
                    setFollowRoles={@setFollowRoles} />
                </span>

      if (@state.creator) and (@props.getUserNoteDetails)
          submit_a_title_request =
              <button className="btn btn-danger btn-lg mr-3"
                     onClick={@showUploadTypes}>Submit a title</button>
          if (@state.mode is 'ask_upload_type')
              ask_upload_or_submit =
                  <span>
                      <span onClick={@submitToConference}>
                          <button className="btn btn-primary mr-3 ml-2"
                                 data-toggle="modal"
                                 data-target="#exampleModalLong">
                                 Submit from existing project
                          </button>
                      </span>
                      or
                      <button className="btn btn-primary ml-2"
                              onClick={@props.getUserNoteDetails}>
                          Upload directly</button>
                  </span>
      else if (@state.can_submit)
          submit_a_title_request =
              <span onClick={@submitToConference}>
                  <button className="btn btn-danger btn-lg pl-1"
                         data-toggle="modal"
                         data-target="#exampleModalLong">
                         Submit a title
                  </button>
              </span>
      else if @props.project.ptype
          ptype = @props.project.ptype.replace(/_/g,' ')
          submit_a_title_request =
              <span className='pl-2'>Only {ptype} members may submit.</span>

      action_bar =
          <div>
              {follow_request}
              {submit_a_title_request}
              {ask_upload_or_submit}
          </div>

      roles_bar =
          if (@props.roles) and (@props.view is 'full')
              <span>
                  <div>
                    {action_bar}
                    <span>My roles: &nbsp;</span>
                    {@props.roles.map((myrole) =>
                        <RoleRollConference key={myrole.id}
                              onClick={@updateRoleAcceptance.bind(this,myrole)}
                              myrole={myrole}
                              tags={@props.project.tags}
                              projectid={@props.project.id}/>
                    ,this)}
                    {help_msg_on_roles}
                  </div>
                  <span>{user_conference_submission_list}</span>
              </span>
          else if (@props.roles) and (@props.view is 'mini')
              <span>
                  {@props.roles.map((myrole) =>
                      <RoleRollConference key={myrole.id}
                            onClick={@updateRoleAcceptance.bind(this,myrole)}
                            myrole={myrole}
                            tags={@props.project.tags}
                            projectid={@props.project.id}
                            ptype={@props.project.ptype}/>
                  ,this)}
                  {help_msg_on_roles}
              </span>
          else
              <span>
                  <div>
                      {action_bar}
                      My roles: <i>none yet -- follow!</i>
                      {help_msg_on_roles}
                  </div>
                  <span>{user_conference_submission_list}</span>
              </span>
