common = require('../common')
Helmet = require('react-helmet').Helmet
CreateNoteMetadata = require('../create_title/create_note_metadata')
Header = require('../lab_group/header')
NavGeneral = require('../nav_general')
ConferenceDirectUpload = require('../conference_direct_upload')
ConferenceSortTitlesNav = require('../sorting/conference_sort_titles_nav')
ConferenceNoteSummary = require('../conference_note_summary')
HeaderBar = require('../header_bar')
Loading = require('../loading')
Pagination = require('../pagination')
RolesBarShortCourse = require('../roles/roles_bar_short_course')

module.exports = class ShortCourseTitles extends React.Component
  displayName: 'ShortCourseTitles'

  constructor: (props) ->
      super(props)
      @makeNewTitleRef = React.createRef()
      @notesMapRef = React.createRef()

      projectid = @props.match.params.projectId
      @state = {
          projectid: projectid
          project: { }
          notes: []
          mode: 'loading'
          note: {title: '', abstract: '', motive: ''}
          userid: ''
          roles: []
          rolestring: ''
          my_accepted_roles: []
          notes_length: 0
          wordlist: []
          ptype: ''

          page_num: 1
          lengthy: 2
          num_per_page: 10

          subset: 'all'
      }

  organizeNotes: () =>

      sort_by = 'latest'
      notelist = @state.notes
      for note in notelist
          if note.clock
            note.datetime = note.clock.debut
          else
            note.datetime = 0

      if sort_by is 'latest'
          notelist = notelist.sort((a,b)=>-a.datetime+b.datetime)

      @setState({ notes: notelist })

  componentDidMount: =>
      data =
          page_num: 1
          num_per_page: @state.num_per_page
          projectid: @state.projectid
          subset: 'all'

      common.retrieveSubsetConferenceNotes(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid_conference' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })
          else
              my_accepted_roles = [ ]
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              project = result['project']
              ptype = project.ptype
              if ptype is 'personal'
                  ptype = 'project'

              page_link = 'https://zerodivzero.com/' + ptype + '/' + project.id + '/titles'

              loaded_wordlist = []
              if project.perms
                for perm in project.perms
                    loaded_wordlist.push(perm.word)

              @setState({
                  project: project
                  notes: result['notes']
                  userid: result['login_id']
                  roles: result['roles']
                  my_accepted_roles: my_accepted_roles
                  wordlist: loaded_wordlist
                  mode: 'viewing'
                  ptype: ptype
                  page_link: page_link
              }, @organizeNotes)

      )

      common.getTotalNumberNotesForConferenceSubset(data, (result) =>
        lengthy = Math.ceil(result.total/@state.num_per_page)
        @setState({lengthy: lengthy, notes_length: result.total})
      )

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()

  setUpdatedRoles: (what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  getUserNoteDetails: =>
      @setState({
          mode : 'create_note_metadata'}, @scrollToHere(@makeNewTitleRef) )

  setMode: (mode_option) =>
      if mode_option is 'viewing'
          window.location.reload()
      else if mode_option is 'cancelled'
          @setState({ mode: 'viewing' })
      else if mode_option.new_note
          @setState({
              new_note: mode_option.new_note
              mode: 'direct_upload_submisson' })
      else
          @setState({ mode: mode_option })

  getThisSortType: (new_subset) =>
      @setState({subset: new_subset}, @getThesePages)

  getThesePages: (info) =>
      if not (info)
          info=1

      if not (@state.subset is 'all') and not (@state.userid)
          @setState({notes: []})
          return

      data =
          page_num: info
          num_per_page: @state.num_per_page
          projectid: @props.match.params.projectId
          subset: @state.subset

      common.retrieveSubsetConferenceNotes(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({mode : 'invalid project'})
          else if result['error'] is 'conference deleted'
              @setState({mode : 'conference_deleted'})
          else
            @setState({notes: result['notes'], page_num: info})

          elem = @notesMapRef.current
          if (elem)
            window.scrollTo(0, elem.offsetTop)
      )

  render: =>

      conf_nav_vert =
          <div className='col-md-2 col-lg-2 border d-none d-md-block'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'titles'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'col-12'} />
          </div>
      conf_nav_horiz =
          <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  project={@state.project}
                  ptype={@state.project.ptype}
                  caller={'titles'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'mr-2'} />
          </div>

      if (@state.mode != 'invalid_conference') and (@state.mode != 'conference_deleted')
          lab_header =
              <span>
                  <Header
                      project={@state.project}
                      userid={@state.userid}
                      roles={@state.roles}
                      wordlist={@state.wordlist} />
                  <div className='proj_actions_Div'>
                      <span className="ml-0">
                        {@state.notes_length} titles submitted!
                      </span>
                      <RolesBarShortCourse
                          project={@state.project}
                          my_accepted_roles={@state.my_accepted_roles}
                          roles={@state.roles}
                          userid={@state.userid}
                          view={'full'}
                          setUpdatedRoles={@setUpdatedRoles}
                          getUserNoteDetails={@getUserNoteDetails} />
                  </div>
              </span>


      if @state.mode is 'invalid_conference'
          problem_msg =
              <div>
                  <br />
                  <h2>Hello there... Not finding anything. Try refreshing!</h2>
                  If you continue to get this error, then maybe the URL is wrong?
              </div>
      else if @state.mode is 'conference_deleted'
          problem_msg =
              <div>
                  <br />
                  <h2>Uh oh... It appears the owner has deleted this page.</h2>
              </div>
      if @state.mode is 'loading'
          paper_list =
              <div>
                  <div className='proj_listing'>
                    <div className='note_noPapers'>
                      <br />
                      <Loading />
                      <br />
                      <br />
                    </div>
                  </div>
              </div>
      else if @state.mode is 'create_note_metadata'
          upload_paper =
              <div ref={@makeNewTitleRef}>
                  <CreateNoteMetadata project={@state.project}
                                      setMode={@setMode}
                                      version={'new'}/>
              </div>
      else if @state.mode is 'direct_upload_submisson'
          upload_paper =
              <div>
                  Just a few more choices to finish submission.
                  <ConferenceDirectUpload
                      note={@state.new_note}
                      conference={@state.project} />
              </div>
      else if @state.mode is 'viewing'
          if @state.notes.length>0
              paper_list =
                  <div>
                      <span ref={@notesMapRef}>
                          {@state.notes.map((paper) =>
                              <ConferenceNoteSummary
                                  key={paper.id}
                                   note={paper}
                                   project={@state.project}
                                   roles={@state.roles}
                                   login_id={@state.userid}/>
                          ,this)}
                      </span>
                      <Pagination page_num={@state.page_num}
                          lengthy={@state.lengthy}
                          onClick={@getThesePages} />
                  </div>
          else
              paper_list =
                  <div>
                      <div className='proj_listing'>
                        <div className='note_noPapers'>
                          <br />
                          <i>Nothing here.... yet....</i>
                          <br />
                          <br />
                        </div>
                      </div>
                  </div>

      helmet_info =
          <Helmet>
              <title>{@state.project.title}</title>
              <meta name="og:title" content={@state.project.title} />
              <meta name="og:url" content={@state.page_link} />
              <meta name="og:type" content="article:science" />
              <meta name="og:image" content='../../images/other_logos/wins_250_248.png' />
              <meta name="og:description" content={@state.project.motive} />
              <meta name="fb:app_id" content='695761137112146' />
          </Helmet>


      <div>
          {helmet_info}
          <HeaderBar />
          <div className='container-fluid pr-0 pl-0 border'>
              {lab_header}
              <div className='row no-gutters'>
                  {conf_nav_vert}
                  {conf_nav_horiz}
                  <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                      {problem_msg}
                      {paper_list}
                      {upload_paper}
                  </div>
              </div>
          </div>
      </div>
