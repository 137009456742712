common = require('../../common')

module.exports = class DeleteLabProject extends React.Component
  displayName: 'DeleteLabProject'

  constructor: (props) ->
      super(props)
      @state = {
          show_caution: 'false'
          mode: 'show_sequel_maker'
      }

  deleteProject: () =>
      data =
        projectid: @props.project.id

      common.deleteProject(data, (result) =>
      )
      window.location = '/profile/' + @props.userid

  askIfSure: =>
      @setState({show_caution: 'true'})

  render: =>
      if @state.show_caution is 'true'
          delete_if_you_are_sure =
              <button className="btn btn-danger" onClick={@deleteProject}>
                Are you sure?
              </button>
      else
          delete_if_you_are_sure =
              <button className="btn btn-danger" onClick={@askIfSure}>
                delete lab group
              </button>

      <div className='p-3'>
          <span>
              If this lab group was a mistake or just a test:&nbsp;
              {delete_if_you_are_sure}
          </span>
      </div>
