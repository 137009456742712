Link = require('react-router-dom').Link
common = require('../common')
AreaChairSelectBox = require('./areachair_select_box')
NoteAddBox = require('./note_add_box')

module.exports = class AssignNotesToAreaChairs extends React.Component
  displayName: 'AssignNotesToAreaChairs'

  constructor: (props) ->
      super(props)
      @state = {
        mode : 'show assign button'
        reviewer_rjs : [ ]
        areachair_rjs : [ ]
        chosen_areachairid : ''
        chosen_areachair_rj : ''
        reviewerlist : [ ]
        local_areachairs : [ ]
        local_jobs : [ ]
        reviewers : [ ]   # added
        chosen_notes : ''
        notelist : [ ]
        local_notes : @props.notes
        return_message : ""
      }

  collapseToAssignButton: =>
      @setState({ mode : 'show assign button' })

  goToShowAreaChairsAndReviewers: =>

      data =
          projectid : @props.project.id

      common.getAreaChairsReviewersJobs(data, (result) =>
          @setState({ areachair_rjs : result['areachair_rjs'] })
          @setState({ reviewer_rjs : result['reviewer_rjs'] })
          @setState({ local_areachairs : result['areachair_rjs']})
          @setState({ local_jobs : result['job_js'] })
          @setState({ local_notes : result['notes']})
          @setState({ mode : 'viewing' })
      )

  componentDidMount: =>
        # hi

  assignNotesToAreaChair: =>
          paperseriesids = [ ]
          for note in @state.notelist
              paperseriesids.push(note.paperseries_id)

          data =
              chosen_areachair_rj_id : @state.chosen_areachair_rj.id
              paperseriesids : paperseriesids
              projectid : @props.project.id

          common.assignConferencePaperSeriesToAreaChair(data, (result) =>
              @setState({ chosen_areachairid : '' })
              @setState({ notelist : [ ] })
              @setState({ chosen_areachair_rj : '' })
          )

  chosenReviewer: (reviewer_rj) =>
      reviewerlist = @state.reviewerlist

      found = 0
      if @state.chosen_areachair_rj.reviewer_rolejunctions.length > 0
          for rev in @state.chosen_areachair_rj.reviewer_rolejunctions
              if reviewer_rj.user_id is rev.user_id
                  found = 1

      if found is 0
          reviewerlist.push(reviewer_rj)
          @setState({ reviewerlist : reviewerlist })

  chosenNote: (note) =>
      notelist = @state.notelist

      found = 0
      if @state.chosen_notes.length > 0
          for chosen_note in @state.chosen_notes
              if note.id is chosen_note.id
                  found = 1

      if found is 0
          note.areachairs.push(@state.chosen_areachair_rj)
          note.new_areachair = @state.chosen_areachair_rj
          notelist.push(note)
          @setState({ notelist : notelist })

  goToAutoAssignPapersToAreaChairs: =>
      data =
          projectid : @props.project.id

      common.autoAssignConferencePaperSeriesToAreaChairs(data, (result) =>
          @setState({ return_message : result.res.return_message })
      )

  chosenAreaChair: (areachair_rj) =>
      if areachair_rj.id != @state.chosen_areachairid
          # this means a new areachair was selected
          @setState({ chosen_areachairid : areachair_rj.id })
          @setState({ chosen_areachair_rj : areachair_rj })
      else
          # this means the "save" btn was clicked on same areachair
          @assignNotesToAreaChair()

  render: =>
      if @state.areachair_rjs.length > 0
          areachair_map =
              <div className="review_note_infoDiv">
                  area chairs:
                  {@state.areachair_rjs.map((areachair_rj) =>
                      <eAreaChairSelectBox
                          key={areachair_rj.id}
                          areachair={areachair_rj}
                          chosen_areachairid={@state.chosen_areachairid}
                          reviewers={@state.reviewerlist}
                          notes={@state.local_notes}
                          notelist={@state.notelist}
                          onClick={@chosenAreaChair.bind(this,areachair_rj)} />
                  ,this)}
              </div>
      else
          areachair_map =
            <div className='review_note_infoDiv'>
              Invite some area chairs....
            </div>

      if @state.local_notes.length?
          paper_map =
              <div className="review_note_infoDiv">
                  papers:
                  {@state.local_notes.map((paper) =>
                      <NoteAddBox
                            key={paper.id}
                            note={paper}
                            notelist={@state.notelist}
                            onClick={@chosenNote.bind(this,paper)} />
                  ,this)}
              </div>
      else
          paper_map =
              <div className='review_note_infoDiv'>
                <h3>No submitted papers yet!</h3>
              </div>

      if @state.mode is 'show assign button'
          <div>
              Assign papers to area chairs:&nbsp;
              <button className="btn btn-primary"
                                          onClick={@goToAutoAssignPapersToAreaChairs}>
                                          Auto-assign</button>
              { @state.return_message }
          </div>
      else if @state.mode is 'viewing'
          <div>
              <button className="btn btn-secondary"
                      onClick={@collapseToAssignButton}>all done</button>
              <br />
              { areachair_map }
              { paper_map }
          </div>
