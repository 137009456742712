common = require('../common')
Link = require('react-router-dom').Link
PaperRollEditable = require('../paper_roll_editable')
AuthorNameRoll = require('../authorcontact/author_name_roll')
AffiliationRoll = require('../affiliation_roll')
TagNoteRoll = require('../tags/tag_note_roll')

module.exports = class AreaChairSelectBox extends React.Component
  displayName: 'AreaChairSelectBox'

  constructor: (props) ->
      super(props)
      @state = {
        notes : @props.notes
        affiliations : [ ]
        mode : 'not selected'
        local_areachair : @props.areachair
        total_reviewers : [ ]
        areachair_old_notes : [ ]
        show_paper_roll : false
      }

  assignIdsToAffils: (affils) =>
      affil_objs = [ ]
      n = 0
      if affils
        for affil in affils
            obj = { }
            n = n+1
            obj.id = Math.floor(Math.random() * 10000000000000000)
            obj.name = affil
            affil_objs.push(obj)
        @setState({ affiliations : affil_objs })

  componentWillReceiveProps: (nextProps) =>
      if nextProps.chosen_areachairid is @props.areachair.id
          @setState({ mode : 'selected' })
      else
          @setState({ mode : 'not selected' })


  setCurrentAreaChairNotes: (notes) =>
      areachair_old_notes = [ ]

      for note in notes
          for areachair in note.areachairs
            if areachair.user_id is @props.areachair.user_id
                areachair_old_notes.push(note)

      @setState({ areachair_old_notes : areachair_old_notes })

  componentDidMount: () =>
      @assignIdsToAffils(@props.areachair.affiliations)

      # assuming we are sending the full note list...
      # extract which papers belongs to this areachair.
      @setCurrentAreaChairNotes(@props.notes)

  getFullListAreaChairPapers: =>
      @setState({ show_paper_roll : true })

      data =
          rolejunctionid : @props.areachair.id
          obj_category : 'paper'

      #common.getObjectsListFromJobJunctionsForOwner(data, (result) =>
      #    @setState({ areachair_old_notes : result.objects })
      #    @setState({ show_paper_roll : true })
      #)

  closeFullPaperList: =>
      @setState({ show_paper_roll : false })

  removeAreaChairOwnedJobJunction: (reviewer) =>
      data =
        rolejunctionid : @props.areachair.id
        obj_id : reviewer.id
        obj_category : 'reviewer'
        projectid : @props.areachair.project_id

      common.hideJobJunction(data, (result) =>
          #empty response
          tmp_areachair = @props.areachair
          for areachair_reviewer in tmp_areachair.reviewer_rolejunctions
              if areachair_reviewer.id is reviewer.id
                areachair_reviewer.name = 'Removed!'

          @setState({ areachair : tmp_areachair })
      )

  hidePaperJobJunction: (note) =>
      data =
        rolejunctionid : @props.areachair.id
        obj_id : note.paperseries_id
        obj_category : 'paper'
        projectid : @props.areachair.project_id

      common.hideJobJunction(data, (result) =>
          #empty response
          updated_notelist = @state.notes
          for list_note in updated_notelist
              list_note.title = "Removed!" if list_note.id == note.id
          @setCurrentAreaChairNotes(updated_notelist)
      )

  render: =>
      if @props.areachair.tags
          tags =
            <span>
              {@props.areachair.tags.map((tag) =>
                  <TagNoteRoll key={tag.id} tag={tag} note_type='conference' />
              )}
            </span>
      else
          tags =
            <strong>none</strong>


      if @props.areachair.affiliations
          affils =
            <span>
              {@state.affiliations.map((affiliation) =>
                  <AffiliationRoll key={affiliation.id} affiliation={affiliation} />
              )}
            </span>
      else
          affils =
            <strong>none</strong>

      if @state.mode is 'not selected'
        divBG = 'review_mini_note_infoDiv'
        if @props.areachair.reviewer_rolejunctions.length > 0
          reviewer_roll =
              <span>
                {@props.areachair.reviewer_rolejunctions.map((reviewer) =>
                        <AuthorNameRoll key={reviewer.user_id} authorcontact={reviewer} />
                    )}
              </span>
        btn_txt = 'select'
        btn_bg = 'btn btn-dark btn-sm'
      else
        divBG = 'review_mini_note_infoDiv_selected'
        btn_txt = 'save'
        btn_bg = 'btn btn-success btn-sm'

        if @props.reviewers.length is 0 and @props.areachair.reviewer_rolejunctions.length > 0
          reviewer_roll =
              <span>
                {@props.areachair.reviewer_rolejunctions.map((reviewer) =>
                    <AuthorNameRoll key={reviewer.user_id} authorcontact={reviewer} />
                    )}
              </span>

        else if @props.reviewers.length > 0
            all_reviewers = @props.areachair.reviewer_rolejunctions
            for reviewer in @props.reviewers
                    all_reviewers.push(reviewer)
            reviewer_roll =
                <span>
                  {all_reviewers.map((reviewer) =>
                          <AuthorNameRoll key={reviewer.user_id}
                                          authorcontact={reviewer} />
                      )}
                </span>
        else
            reviewer_roll = <strong></strong>

      closeFullPaperList = @closeFullPaperList

      paper_roll = if @state.show_paper_roll
        notes_interior = if @state.areachair_old_notes and @state.areachair_old_notes.length > 0
          notes_interior = @state.areachair_old_notes.map((note) =>
              <PaperRollEditable key={note.id} note={note} onClick={@hidePaperJobJunction.bind(this,note)}/>
            , this)
        else
          <i>None yet</i>

        <span>
          <button className="btn btn-link btn-sm" onClick={closeFullPaperList}>hide</button>
          <br />
          {notes_interior}
          <button className="btn btn-link btn-sm" onClick={closeFullPaperList}>hide</button>
        </span>
      else
        <span>
          <button className="btn btn-link btn-sm" onClick={@getFullListAreaChairPapers}>{@state.areachair_old_notes.length} papers</button>
        </span>

      if @props.notelist
        if @props.notelist.length > 0
          for note in @props.notelist
            if note.new_areachair.user_id is @props.areachair.user_id
              new_paper_roll =
                  <span>
                    <strong>New papers:</strong>
                    {@props.notelist.map((note) =>
                        <PaperRollEditable key={note.id}
                                    note={note}
                                    onClick={@hidePaperJobJunction.bind(this,note)}/>
                    ,this)}
                    <br />
                  </span>
      else
        new_paper_roll =
            <span>
            </span>



      if @props.areachair.user_id
        if @props.areachair.role_accepted is "true"
            <div className={ divBG }>
              <div className='review_note_Div'>
                  <Link to={ '/profile/' + @props.areachair.user_id }>
                      <button className="btn btn-link btn-sm">
                          {@props.areachair.name} </button>
                  </Link>
                  <button className={ btn_bg } onClick={@props.onClick}>{ btn_txt }</button>
                  <br />
                  <strong>Affiliations: </strong> { affils }
                  <br />
                  <strong>Tags:</strong> { tags }
                  <br />
                  <strong>Reviewers:</strong>{ reviewer_roll }
                  <br />
                  { new_paper_roll }
                  <strong>Currently assigned:</strong>
                  { paper_roll }
              </div>
            </div>
        else
            # user is registered, but role_accepted is "false"
            <div className={ divBG }>
              <div className='review_note_Div'>
                  <button className="btn btn-link btn-sm"
                          onClick={@gotoUser}>{@props.areachair.name} </button>
                  <br />
                  User has not accepted the area chair role!
              </div>
            </div>
      else
          # there is no user account
          <div className={ divBG }>
            <div className='review_note_Div'>
                <button className="btn btn-link btn-sm"
                        onClick={@gotoUser}>{@props.areachair.name} </button>
                <br />
                No registered account!
            </div>
          </div>
