common = require('../common')

module.exports = class VerticalVoteComponent extends React.Component
  displayName: 'VerticalVoteComponent'

  constructor: (props) ->
      super(props)
      note = @props.note
      @state = {
        note: @props.note
        data: {noteid: note.id, votevalue: note.myvote}
      }

  recordVote: () =>
      common.submitProjectNoteVote(@state.data, (response) =>
      )

  voteUp: () =>
      oldvote = @state.note.myvote
      updated_note = @state.note
      updated_data = @state.data

      if this.state.note.myvote?
          if @state.note.myvote is -1
            updated_note.myvote = 0
            updated_note.votescore = @state.note.votescore - oldvote + 0
            updated_data.votevalue = @state.note.myvote
          else if this.state.note.myvote is 0
            updated_note.myvote = 1
            updated_note.votescore = @state.note.votescore - oldvote + 1
            updated_data.votevalue = @state.note.myvote
      else
          updated_data.votevalue = 1
          updated_note.myvote = 1
          updated_note.votescore = @state.note.votescore + 1
      @setState({ note : updated_note })
      @setState({ data : updated_data })
      @recordVote()

  voteDown: () =>
      oldvote = @state.note.myvote
      updated_note = @state.note
      updated_data = @state.data

      if this.state.note.myvote?
          if @state.note.myvote is 0
            updated_note.myvote = -1
            updated_note.votescore = @state.note.votescore - oldvote - 1
            updated_data.votevalue = -1
          else if @state.note.myvote is 1
            updated_note.myvote = 0
            updated_note.votescore = @state.note.votescore - oldvote + 0
            updated_data.votevalue = 0
      else
          updated_data.votevalue = -1
          updated_note.myvote = -1
          updated_note.votescore = @state.note.votescore - 1
      @setState({ note : updated_note })
      @setState({ data : updated_data })
      @recordVote()

  render: =>

      if @state.note.myvote is 1
          top_button =
              <button className="btn btn-success btn-txt-sm btn-box" onClick={@voteUp}>&#x25B2;</button>
      else
          top_button =
              <button className="btn btn-light btn-txt-sm btn-box" onClick={@voteUp}>&#x25B2;</button>

      if @state.note.myvote is -1
          bottom_button =
              <button className="btn btn-success btn-txt-sm btn-box" onClick={@voteDown}>&#x25BC;</button>
      else
          bottom_button =
              <button className="btn btn-light btn-txt-sm btn-box" onClick={@voteDown}>&#x25BC;</button>


      <div className="btn-group-vertical"
           role="group"
           aria-label="voting-buttons">
          { top_button }
          <button className="btn btn-light btn-txt-sm text-center">
                  {this.state.note.votescore}</button>
          { bottom_button }
      </div>
