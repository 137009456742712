Link = require('react-router-dom').Link

module.exports = class Title extends React.Component
  displayName: 'Title'

  constructor: (props) ->
      super(props)
      @state = { title: @props.title }

  componentDidMount: () =>
    if @props.editor is 'MathJax'
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, @domElement])
      if (@props) and (@props.title.length < 1)
          title = "Untitled"
          @setState({ title: title })

  setDOMRef: (domElement) =>
    @domElement = domElement

  render: =>

    if @props.editor is 'MathJax' and @props.link
        <Link to={@props.link}>
            <span className="mathjax"
                  ref={@setDOMRef}
                  dangerouslySetInnerHTML={{__html: @props.title }}>
            </span>
        </Link>
    else if @props.editor is 'MathJax'
        <span className="mathjax"
              ref={@setDOMRef}
              dangerouslySetInnerHTML={{__html: @props.title }}>
        </span>
    else if @props.link
        <Link to={@props.link}>
            <span dangerouslySetInnerHTML={{__html: @props.title }}>
            </span>
        </Link>
    else
        <span dangerouslySetInnerHTML={{__html: @props.title }}>
        </span>
