common = require('../common')

module.exports = class TagProjectRollEditable extends React.Component
  displayName: 'TagProjectRollEditable'

  constructor: (props) ->
      super(props)
      @state = {
        tag: @props.tag
        mode: 'display name'
      }

  removeTagFromProject: () =>

      data =
        projectid : @props.projectid
        tagid : @props.tag.id

      common.removeProjectTag(data, (result) =>
         @setState({ tag : '' })
         @setState({ mode : 'remove_tag' })
      )

  render: =>

      if @state.mode is 'remove_tag'
          <div className='authornameroll_div'>Removed!</div>
      else
          <div className="btn-group">
            <button className="btn btn-primary btn-txt-sm">
                    {@props.tag.word}&nbsp;</button>
            <button className="btn btn-danger btn-txt-sm"
                    onClick={@removeTagFromProject}>
                    &nbsp;x&nbsp;</button>
          </div>
