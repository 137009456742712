TitleLinkSmall = require('./titles/title_link_small')

module.exports = class ConferenceSubmissionUserOwnedProjectLine extends React.Component
  displayName: 'ConferenceSubmissionUserOwnedProjectLine'

  render: =>
      <div className='row border'>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <TitleLinkSmall title={@props.project.title}
                        editor={@props.project.editor}
                        onClick={@props.onClick} />
          </div>
      </div>
