common = require('../common')
ShowDate = require('./show_date')

module.exports = class SetConfDate extends React.Component
  displayName: 'SetConfDate'

  constructor: (props) ->
      super(props)
      # props are: datetime, confid, type
      # types are 'registration_deadline', 'start_date', 'end_date',
      #            'submission_deadline', 'review_deadline', 'rebuttal_deadline'
      datetime = @props.datetime
      @state = {
        datetime: datetime
        show_deadline_setter: 'true'
        day: ''
        month: ''
        year: ''
        hour: ''
        error_msg: ''
        datetime_phrase: ''
      }

  componentDidMount: =>
      if @props.datetime > 0
            @setState({ show_deadline_setter: 'false' })

      datetime_phrase = @props.type.replace('_', ' ')
      @setState({ datetime_phrase: datetime_phrase })

  componentDidUpdate: (prevProps) =>
      if @props.datetime != prevProps.datetime
          if @props.datetime > 0
                @setState({ show_deadline_setter: 'false' })


  turnOnDateSetter: =>
      @setState({ show_deadline_setter: 'true' })

  cancelEdit: =>
      @setState({ show_deadline_setter: 'false' })

  goToSaveDate: =>

      year = Math.floor(Number(@refs.year.value))
      month = Math.floor(Number(@refs.month.value))-1
      day = Math.floor(Number(@refs.day.value))
      hour = Math.floor(Number(@refs.hour.value))

      my_location = new Date()
      UTC_offset = my_location.getTimezoneOffset()  # offset in mins

      if (isNaN(day)) or (isNaN(month)) or (isNaN(year)) or (isNaN(hour)) or
      (day<1) or (month< 0) or (year<=0) or (hour<0) or
      (day>31) or (month>11) or (hour>23)
          @setState({ error_msg: ' invalid date' })
          return

      data =
          hour: hour
          day: day
          month: month
          year: year
          UTC_offset: UTC_offset
          confid: @props.confid
          type: @props.type

      common.saveConferenceDateType( data, (result) =>
          if result.error
              @setState({ error_msg: result.error })
          else
              @setState({
                show_deadline_setter:'false'
                datetime: result.date_epoch_secs
                error_msg: '' })
      )


  render: =>

    conference_datetime =
        <span>
            <span>{ @state.datetime_phrase }: </span>
            <ShowDate key={Math.random()}
                      datetime={@state.datetime} />
            &nbsp;
            <button className="btn btn-primary"
                    onClick={@turnOnDateSetter}>
                    Edit</button>
        </span>

    if @state.show_deadline_setter is 'true'
        <div className='row no-gutters'>
          <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
            { @state.datetime_phrase }:
          </div>
          <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
            Year: <input className='small_input_textbox'
                         type='text'
                         ref='year'></input>
            Month: <input className='small_input_textbox'
                          type='text'
                          ref='month'></input>
            Day: <input className='small_input_textbox'
                        type='text'
                        ref='day'></input>
            Hour (0-23 hrs):
                  <input className='small_input_textbox'
                         type='text'
                         ref='hour'></input>
            <button className="btn btn-primary"
                    onClick={@goToSaveDate}>
                    save</button>
            <button className="btn btn-primary"
                    onClick={@cancelEdit}>
                    cancel</button>
            <span className='text-danger'>
                { @state.error_msg }
            </span>
          </div>
        </div>
    else
        <span>
          { conference_datetime }
        </span>
