common = require('../common')
MaterialUploadButton = require('./material_upload_button')
SaveUrl = require('../url/save_resource_urls')
UrlLinks = require('../url/url_links')
UrlLinkRoll = require('../url/url_link_roll')
UrlLinkRollEditable = require('../url/url_link_roll_editable')

module.exports = class Materials extends React.Component
    displayName: 'Materials'

    constructor: (props) ->
        super(props)

        ###################
        # This is because user_can_edit is boolean while
        # user_is_author is 'true'/'false' so need to harmonize
        user_is_author = 'false'
        if @props.user_can_edit
            user_is_author = 'true'
        else if @props.user_is_author is 'true'
            user_is_author = 'true'
        ###################

        obj = @props.obj

        # selected can be: show_buttons, links_selected, upload_selected

        @state = {
            project: obj.project_id
            user_is_author: user_is_author
            urls: []
            selected: 'show_buttons'
        }

    getLinks: () =>
        data =
            ltype: 'note'
            id: @props.obj.id

        common.getUrlLinks(data, (result) =>
            if (result) and (result.url_links)
                @setState({ urls: result.url_links })
        )

    componentDidMount: =>
        @getLinks()

    componentDidUpdate: (prevProps) =>
        if @props.obj.id != prevProps.obj.id
            @getLinks()

    uploadButton: =>
        <MaterialUploadButton note={@props.obj}
            user_is_author={@state.user_is_author}
            resetButtons={@openSelection} />

    openSelection: (updates) =>
        # expects updates.button_mode
        # optional: updates.new_url
        if updates.new_url
            updated_urls = @state.urls
            updated_urls.push(updates.new_url)
            @setState({ selected: updates.button_mode, urls: updated_urls })
        else
            @setState({ selected: updates.button_mode })

    linksComponent: =>
        <SaveUrl url_linkable_id={@props.obj.url_linkable_id}
            obj_type={'note'}
            obj={@props.obj}
            user_is_owner={@state.user_is_author}
            obj_id={@props.obj.id}
            resetButtons={@openSelection} />

    linksRollComponent: =>
        if (@state.urls)
            if (@state.urls.length > 0) and (@props.user_is_author is 'true') and (@props.mode is 'editing')
                return (
                    <span>
                        {@state.urls.map((url) =>
                            <UrlLinkRollEditable key={url.id}
                                url={url} note={@props.obj} />
                        )}
                    </span>
                )
            else if (@state.urls.length > 0)
                return (
                    <span>

                        {@state.urls.map((url) =>
                            <UrlLinkRoll key={url.id} url={url} note={@props.obj} />
                        )}
                    </span>
                )
        else
            return (
                <span>none</span>
            )

    askForUrlType: =>

        if (@props.user_is_author is 'true') and (@props.mode is 'editing')
            if (@state.selected is 'show_buttons')
                <div className='row no-gutters mb-3'>
                    <button className='btn btn-primary'
                        onClick={@openSelection.bind(this, {button_mode: 'links_selected'})}>
                        Add link
                    </button>
                    &nbsp;or&nbsp;
                    <button className='btn btn-primary'
                        onClick={@openSelection.bind(this, {button_mode: 'upload_selected'})}>
                        upload
                    </button>
                    &nbsp;(PDF, LaTeX)
                </div>
            else if (@state.selected is 'links_selected')
                <div className='row no-gutters'>
                    { @linksComponent() }
                </div>
            else if (@state.selected is 'upload_selected')
                <div className='row no-gutters'>
                    { @uploadButton() }
                </div>


    render: =>

        if (@props.mode is 'editing')
            <div className='row no-gutters'>
                <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                    <strong>Resources</strong>
                </div>
                <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                    { @askForUrlType() }
                    <div className='row no-gutters'>
                        { @linksRollComponent() }
                    </div>
                </div>
            </div>
        else if (@state.urls.length < 1)
            <span></span>
        else
            <div className='row no-gutters'>
                <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                    <strong>Resources</strong>
                </div>
                <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                    { @askForUrlType() }
                    <div className='row no-gutters'>
                        { @linksRollComponent() }
                    </div>
                </div>
            </div>
