
module.exports = class ToolchestCreateOrDeleteButton extends React.Component
  displayName: 'ToolchestCreateOrDeleteButton'

  constructor: (props) ->
      super(props)
      # Expects: @props.my_accepted_roles
      @state = { }

  render: =>

      if @props.tool_selected is 'create_delete_tools'
          card_className = 'card light_green'
      else
          card_className = 'card light_gray'

      if ('creator' in @props.my_accepted_roles) or ('chair' in @props.my_accepted_roles)
          <div className={card_className}>
            <div className="card-body">
                <h2 className="card-title">
                    <button className='btn btn-primary'
                            onClick={ @props.onClick.bind(this, {tools: 'create_delete_tools', show_this: 'create_delete_tools'}) }>
                        delete?
                    </button>
                </h2>
                <p className="card-text">
                    Was this just a test? You can delete it.
                </p>
            </div>
          </div>
      else
          <div className={card_className}>
            <div className="card-body">
                <h2 className="card-title">
                    <button className='btn btn-primary'
                            onClick={ @props.onClick.bind(this, {tools: 'roles_error', show_this: 'false'}) }>
                        what's next?
                    </button>
                </h2>
                <p className="card-text">
                    Set up a new lab group with a click! (Or delete this one).
                </p>
            </div>
          </div>
