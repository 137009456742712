common = require('../common')
AuthorContactForm = require('./authorcontact_form')
AuthorNameRollEditable = require('./author_name_roll_editable')
AuthorUserNameRoll = require('./author_user_name_roll')

module.exports = class GetNoteAuthorContacts extends React.Component
  displayName: 'GetNoteAuthorContacts'

  constructor: (props) ->
      super(props)
      @state = {
        note: @props.note
        mode: 'author_roll_only'
        form_mode: 'off'
        logged_in_user: 'anonymous'
        userid: ''
        author_email: ''
        user_authorcontacts: []
        hide_email: 1
        error_msg: ''
      }
      @newEmailRef = React.createRef()

  askIfYou: () =>
      @setState({mode: 'ask_self_or_not'})

  popAskForEmailofNewAuthor: () =>
      @setState({mode: 'ask_new_email'})

  popAuthorContactForm: (user_type) =>
      if user_type is 'non_user'
          @setState({
            form_mode: 'add_authorcontact'}, @setHideEmailPrefYes)
      else
          data =
              userid: @state.userid

          common.getUserAuthorContactList(data, (result) =>
              @setState({
                  user_authorcontacts: result.authorcontacts
                  mode: 'add_authorcontact'
                  form_mode: 'add_authorcontact' })
              )

  validateEmailAddress: (email) =>
      re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email))

  retrieveEmailsMatchingNewAuthor: () =>

      email = @newEmailRef.current.value
      email = email.toLowerCase()
      email = email.trim()
      validation = @validateEmailAddress(email)
      if not validation
          @setState({error_msg: 'Invalid email'})
          return
      @setState({ author_email: email })

      data =
          authoremail: email

      common.getAuthorContactsByEmail(data, (result) =>
          form_mode = 'off'
          form_mode = 'add_authorcontact'
          if result.authorcontacts.length is 0
              form_mode = 'add_authorcontact'
          @setState({
              user_authorcontacts: result.authorcontacts
              mode: 'show_contacts'
              form_mode: form_mode
              author_email: data.authoremail })
          )

  cancelAsking: () =>
      @setState({
          mode: 'author_roll_only'
          form_mode: 'off'
          author_emai: '' })

  addAuthorContact: (data) =>

      if data is 'turn_off'
          @cancelAsking()
      else
          data.user = 'unknown'
          data.noteid = @state.note.id
          data.aorder = @state.note.authorcontacts.length

          if @state.mode is 'add_authorcontact'
              data.user = 'self'
              data.user_id = @state.userid

          common.submitNewAuthorContactForProjectNote(data, (result) =>
              updated_note = @state.note
              data.id = Math.floor(Math.random() * 10000000000000000)
              updated_note.authorcontacts.push(data)

              @setState({
                  note: updated_note
                  mode: 'author_roll_only'
                  author_email: ''
                  error_msg: ''})
          )

  chosenExistingAuthorContact: (id) =>
      data =
        authorcontactid: id
        noteid: @props.note.id

      common.submitProjectNoteExistingAuthorContact(data, (result) =>
          @setState({note: result.note})
          @setState({mode: 'author_roll_only'})
          )

  componentDidMount: =>
      common.loginMonitor (data) =>
          current_user = data['current_user']
          whos_logged_in = 'anonymous'
          whos_logged_in = current_user if current_user
          @setState({logged_in_user: whos_logged_in})
          @setState({userid: data['userid']})

  updateAuthorOrder: (updated_ac, e) =>
      console.log("e=")
      console.log(e)
      new_aorder = e.authorOrderRef.current.value
      # checks
      if not (new_aorder)
          return
      if not (Number.isInteger(Number(new_aorder)))
          return
      if new_aorder < 1
          new_aorder = 1
      L = @state.note.authorcontacts.length
      if new_aorder > L
          new_aorder = L
      new_aorder = new_aorder - 1

      tmp_note = @state.note
      # remove ac from array's original location
      index = updated_ac.aorder
      tmp_note.authorcontacts.splice(index, 1)
      # insert it into the new location
      index = new_aorder
      tmp_note.authorcontacts.splice(index, 0, updated_ac)

      # reorder the authorcontacts
      #arr = new Array(L)
      #for ac in tmp_note.authorcontacts
      #      arr[ac.aorder] = ac

      # update the aorders to match the array!
      n = 0
      for ac in tmp_note.authorcontacts
          ac.aorder = n
          n++

      @setState({note: tmp_note})

      data =
        noteid: @props.note.id
        authorcontactid: updated_ac.id
        aorder: updated_ac.aorder

      common.updateAuthorOrder(data,(result) =>
        # no response
      )

  setHideEmailPrefYes: () =>
      @setState({hide_email: 1})

  setHideEmailPrefNo: () =>
      @setState({hide_email: 0})

  errorMsg: () =>
      return(
          <div className="text-danger">{@state.error_msg}</div>
      )

  authorRollEditable: () =>
      return(
          <span>
              {@state.note.authorcontacts.map((authorcontact) =>
                  <AuthorNameRollEditable
                      key={authorcontact.id}
                      authorcontact={authorcontact}
                      noteid={@props.note.id}
                      onChange={@updateAuthorOrder.bind(this, authorcontact)}/>
              ,this)}
          </span>
      )

  authorFields: () =>
      return(
          <div>
              <AuthorContactForm
                  mode={@state.form_mode}
                  addAuthorContact={@addAuthorContact}
                  popAuthorContactForm={@popAuthorContactForm.bind(this, 'user')}
                  error_msg={@state.error_msg} />
          </div>
      )

  authorNonUserFields: () =>
      return(
          <div>
              <AuthorContactForm
                  mode={@state.form_mode}
                  addAuthorContact={@addAuthorContact}
                  popAuthorContactForm={@popAuthorContactForm.bind(this, 'non_user')}
                  error_msg={@state.error_msg} />
          </div>
      )

  cancelButton: () =>
    <button className='btn btn-danger'
        onClick={@cancelAsking.bind(this)}>cancel</button>

  chooseSelfAuthorRoll: () =>
      return (
          <span>
              <div className="note_author_self">
                  Contact information for: {@state.logged_in_user} &nbsp;
                  Select a contact (you may repeat to add more contacts):
                  <br/>
                  {@state.user_authorcontacts.map((user_authorcontact) =>
                      <AuthorUserNameRoll
                          key={user_authorcontact.id}
                          authorcontact={user_authorcontact}
                          onClick={@chosenExistingAuthorContact.bind(this,user_authorcontact.id)}/>
                  ,this)}
              </div>
          </span>
      )

  chooseNonSelfAuthorRoll: () =>
      if @state.user_authorcontacts.length > 0
          return(
              <span>
                <div className="note_author_self">
                    Contact information for: {@state.author_email} &nbsp;
                    <br />
                    Select a contact (you may repeat to add more contacts):
                    <br/>
                    {@state.user_authorcontacts.map((user_authorcontact) =>
                        <AuthorUserNameRoll
                            key={user_authorcontact.id}
                            authorcontact={user_authorcontact}
                            onClick={@chosenExistingAuthorContact.bind(this,user_authorcontact.id)}/>
                    ,this)}
                </div>
                <div>
                    ...or enter new contact info for your co-author, starred (*) entries required:
                    { @authorNonUserFields() }
                </div>
              </span>
          )
      else
          return (
              <span>
                  <div className="note_author_self">
                      No contacts found for: {@state.author_email}. Please enter one.
                  </div>
                  <div>
                      { @authorNonUserFields() }
                  </div>
              </span>
          )


  render: =>

    if @state.mode is 'ask_self_or_not'
        <div>
          { @authorRollEditable() }
          <div className="note_author_self">
            Is this you?
            <button className='btn btn-secondary mr-1 ml-2'
                onClick={ @popAuthorContactForm.bind(this, 'user') }>
                Yes</button>
            <button className='btn btn-secondary'
                onClick={ @popAskForEmailofNewAuthor }>
                No</button>
          </div>
          { @cancelButton() }
        </div>
    else if @state.mode is 'ask_new_email'
        <div>
          { @authorRollEditable() }
          <input className="proj_author_input"
                 type="text"
                 ref={@newEmailRef}
                 placeholder="email:*"  />
            <br />
          <button className="btn btn-secondary"
              onClick={ @retrieveEmailsMatchingNewAuthor }>
              Search author
          </button>
          { @cancelButton() }
          { @errorMsg() }
        </div>
    else if @state.mode is 'show_contacts'
        <div>
            { @authorRollEditable() }
            { @chooseNonSelfAuthorRoll() }
        </div>
    else if @state.mode is 'add_authorcontact'
        <div>
            { @authorRollEditable() }
            { @chooseSelfAuthorRoll() }
            ...or enter a new personal author contact info, starred (*) entries required:
            { @authorFields() }
        </div>
    else if @state.mode is 'author_roll_only'
        <div>
            { @authorRollEditable() }
            <button className='btn btn-secondary'
                onClick={ @askIfYou }>
                Add an author</button>
            { @errorMsg() }
            <br />
        </div>
