Loading = require('../loading')
AuthorProfileNameRoll = require('../authorcontact/author_profile_name_roll')


module.exports = class NameTagsView extends React.Component
  displayName: 'NameTagsView'

  constructor: (props) ->
      super(props)
      # expects props: authorcontacts, userid
      @state = { }

  render: =>

      <span>
          {@props.authorcontacts.map((authorcontact) =>
              <AuthorProfileNameRoll key={authorcontact.id}
                                     authorcontact={authorcontact}
                                     current_userid={ @props.userid }/>
          ,this)}
      </span>
