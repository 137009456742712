common = require('../common')
ConferenceDirectUpload = require('../conference_direct_upload')
CreateNoteMetadata = require('../create_title/create_note_metadata')
ConferenceHeader = require('../conference_header')
ConferenceProgramArrayNotes = require('./conference_program_array_notes')
NavGeneral = require('../nav_general')
HeaderBar = require('../header_bar')
Loading = require('../loading')
NoteTimePlaceRoll = require('./note_time_place_roll')
Pagination = require('../pagination')
RolesBarConf = require('../roles/roles_bar_conf')
TagProjectRollSendClick = require('../tags/tag_project_roll_send_click')

module.exports = class ConferenceNotes extends React.Component
  displayName: 'ConferenceNotes'

  constructor: (props) ->
      super(props)
      projectid = @props.match.params.projectId
      @state = {
          projectid: projectid
          project: { }
          notes: []
          notes_in_spots: [[]]
          mode: 'loading'
          note: {title: '', abstract: '', motive: ''}
          userid: ''
          roles: []
          rolestring: ''
          my_accepted_roles: []
          wordlist: []
          notes_length: 0

          page_num: 1
          lengthy: 2
          num_per_page: 10

          sort_mode: 'all'
          session_ids: 'all'
          show_sessions: 'false'

          new_note: {}
      }
      @makeNewTitleRef = React.createRef()
      @activeAreaRef = React.createRef()


  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })


  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)


  componentDidMount: =>

      data =
          page_num: 1
          num_per_page: @state.num_per_page
          projectid: @state.projectid
          subset: 'scheduled'
          session_ids: @state.session_ids


      common.getScheduledConferenceNotes(data, (result) =>

          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid_conference' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })
          else

              my_accepted_roles = [ ]
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              project = result['project']
              loaded_wordlist = []
              if project.perms
                for perm in project.perms
                    loaded_wordlist.push(perm.word)

              show_sessions = @state.show_sessions
              if project.tags.length > 0
                  show_sessions = 'true'

              # notes_in_spots

              @setState({
                  project: project,
                  notes_in_spots: result['notes'],
                  userid: result['userid'],
                  roles: result['roles'],
                  my_accepted_roles: my_accepted_roles,
                  wordlist: loaded_wordlist,
                  mode: 'viewing'
                  show_sessions: show_sessions }, @scrollWindow)

              document.title = result['project']['title']

      )

      data =
          projectid: @state.projectid
          subset: 'scheduled'

      common.getTotalNumberNotesForConferenceSubset(data, (result) =>
        lengthy = Math.ceil(result.total/@state.num_per_page)
        @setState({ lengthy: lengthy, notes_length: result.total })
      )

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()


  getThesePages: (info) =>

      if not (info)
          info=1

      backend_subset = @state.subset
      if @state.subset is 'user_bookmarks'
          backend_subset = 'scheduled_user_bookmarks'
      else if @state.subset is 'user_submissions'
          backend_subset = 'scheduled_user_submissions'
      else
          backend_subset = 'scheduled'

      data =
          page_num: info
          num_per_page: @state.num_per_page
          projectid: @props.match.params.projectId
          session_ids: @state.session_ids
          subset: backend_subset

      common.getScheduledConferenceNotes(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid project' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })
          else
            @setState({ notes_in_spots: result['notes'],  page_num: info }, @scrollToHere(@activeAreaRef))
      )

      # need to recalc number of notes for this session
      data =
          projectid: @state.projectid
          subset: 'session_scheduled'
          session_ids: @state.session_ids

      common.getTotalNumberNotesForConferenceSubset(data, (result) =>
        lengthy = Math.ceil(result.total/@state.num_per_page)
        @setState({ lengthy: lengthy, notes_length: result.total })
      )

  getThisSortType: (new_subset) =>
      @setState({subset: new_subset}, @getThesePages)

  getTheseSessionPages: (tag) =>
      if tag is 'all'
        @setState({ session_ids: 'all' }, @getThesePages.bind(this, 1))
      else
        @setState({ session_ids: [tag.id] }, @getThesePages.bind(this, 1))

  getUserNoteDetails: =>
      @setState({
          mode : 'create_note_metadata'}, @scrollToHere(@makeNewTitleRef) )

  setMode: (info) =>
      # in this case, new_note might be returned!
      if info is 'cancelled'
          @setState({ mode: 'viewing' })
      else if info.new_note
          @setState({
              new_note: info.new_note
              mode: 'direct_upload_submisson' })
      else
          @setState({ mode: info })

  conferenceHeader: () =>
      if (@state.mode != 'invalid_conference') and (@state.mode != 'conference_deleted')
          <span>
              <ConferenceHeader project={@state.project}
                  userid={@state.userid}
                  roles={@state.roles}
                  wordlist={@state.wordlist}
                  mode={'mini'} />
          </span>

  navVertical: () =>
      <div className='col-md-2 col-lg-2 border d-none d-md-block'>
          <NavGeneral key={@state.project.id}
              id={@state.project.id}
              ptype={@state.project.ptype}
              caller={'program'}
              subset={@state.subset}
              getThisSortType={@getThisSortType}
              userid={@state.userid}
              styling={'col-12'} />
      </div>
  navHorizontal: () =>
      <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
          <NavGeneral key={@state.project.id}
              id={@state.project.id}
              ptype={@state.project.ptype}
              caller={'program'}
              subset={@state.subset}
              getThisSortType={@getThisSortType}
              userid={@state.userid}
              styling={'mr-2'} />
      </div>

  titleAndSorting: () =>
      if (@state.project.tags) and (@state.sort_mode is 'all')
            Ltags = @state.project.tags.length
            <span>
                <p className='m-3 text-center'>
                    <h1>Program</h1>
                </p>
                <div className='row m-3'>
                      Show session schedule:&nbsp;
                      <button className='btn btn-success btn-txt-sm mr-1'
                          onClick={@getTheseSessionPages.bind(this,'all')}>
                          complete</button>
                      {@state.project.tags.map((tag, ctr) =>
                          if ctr+1 < Ltags
                              comma=<span>&nbsp;|</span>
                          <TagProjectRollSendClick key={tag.id}
                              style={'btn btn-link mr-0 pr-0'}
                              tag={tag}
                              comma={comma}
                              onClick={@getTheseSessionPages.bind(this,tag)}/>
                      )}
                </div>
            </span>

  problemMsg: () =>
      if @state.mode is 'invalid_conference'
          <div>
              <br />
              <h2>We cannot find that conference... Try refreshing!</h2>
              If you continue to get this error, then maybe the conference number is wrong?
          </div>
      else if @state.mode is 'conference_deleted'
          <div>
              <br />
              <h2>Uh oh... It appears the owner has deleted this conference.</h2>
          </div>

  paperList: () =>
      if @state.show_sessions is 'true'
          sessions_col =
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                  <strong>Session</strong>
              </div>
      if @state.notes_in_spots
          return (
              <div className='container-fluid m-3'
                  ref={@activeAreaRef}>
                  <br />
                  <div className='row border-bottom'>
                      <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                          <strong>Time</strong>
                      </div>
                      <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                          <strong>Place</strong>
                      </div>
                      { sessions_col }
                      <div className='col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                          <strong>Talk</strong>
                      </div>
                      <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                          <strong>Authors</strong>
                      </div>
                  </div>
                  <span>
                      {@state.notes_in_spots.map((notes, index) =>
                          <ConferenceProgramArrayNotes
                              key={index}
                              notes={notes}
                              project={@state.project}
                              roles={@state.roles}
                              login_id={@state.userid}
                              show_sessions={@state.show_sessions} />
                      ,this)}
                  </span>
                  <Pagination page_num={@state.page_num}
                      lengthy={@state.lengthy}
                      onClick={@getThesePages} />
              </div>
          )

  uploadPaper: () =>
      if @state.mode is 'create_note_metadata'
              <div ref={@makeNewTitleRef}>
                  <CreateNoteMetadata project={@state.project}
                                      setMode={@setMode}
                                      version={'new'}/>
              </div>
      else if @state.mode is 'direct_upload_submisson'
              <div>
                  You have created a personal project and added a title.
                  Just a few more choices to finish submission.
                  <ConferenceDirectUpload
                      note={@state.new_note}
                      conference={@state.project} />
              </div>

  render: =>

      <div>
          <HeaderBar />
          <div className='container-fluid pr-0 pl-0'>
              { @conferenceHeader() }
              <div className='row no-gutters'>
                  { @navVertical() }
                  { @navHorizontal() }
                  <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'
                      ref={@activeAreaRef}>
                      { @titleAndSorting() }
                      { @problemMsg() }
                      { @paperList() }
                      { @uploadPaper() }
                  </div>
              </div>
          </div>
      </div>
