common = require('../common')
ConferenceHeader = require('../conference_header')
ConferenceNoteReviews = require('./conference_note_reviews')
HeaderBar = require('../header_bar')
Loading = require('../loading')
NavGeneral = require('../nav_general')
Pagination = require('../pagination')
RolesBarConf = require('../roles/roles_bar_conf')

module.exports = class ConferenceNotesPanel extends React.Component
  displayName: 'ConferenceNotesPanel'

  constructor: (props) ->
      super(props)
      projectid = @props.projectid
      @state = {
          projectid: projectid
          project: {}
          notes: {}
          note: {title: '', abstract: '', motive: ''}
          userid: ''
          roles: []
          my_accepted_roles: []
          mode: 'loading'
          choice1: 'all_review_stages'
          choice2: 'all_reviewer_staffing'
          choice3: 'all_acceptance_stages'
          notes_length: 0

          page_num: 1
          lengthy: 2
          num_per_page: 10

          clocks: []
          places: []

          new_note: {}
      }
      @activeAreaRef = React.createRef()

  sortedNotes:(notes) =>
      notes.sort (a,b) ->
          b.sort_order - a.sort_order
      @setState({ notes: notes })

  componentDidMount: =>

      data =
        num_per_page: @state.num_per_page
        page_num: 1
        sort_type: [@state.choice1, @state.choice2, @state.choice3]
        projectid: @props.projectid
        panel_type: @props.panel_type

      common.getConferenceHelmReviews(data, (result) =>

          @sortedNotes(result['notes'])

          my_accepted_roles = [ ]
          for role in result['roles']
              if role.role_accepted is 'true'
                  my_accepted_roles.push(role.role)

          @setState({
              project: result['project'],
              notes: result['notes'],
              userid: result['userid'],
              roles: result['roles'],
              notes_length: result['total_notes'],
              my_accepted_roles: my_accepted_roles,
              mode: 'viewing' })

          lengthy = Math.ceil(result['total_notes']/@state.num_per_page)
          @setState({ lengthy: lengthy })

          document.title = result['project']['title']
      )

  sortByChosenFormat: =>

      data =
          projectid : @state.projectid
          sort_type : [@state.choice1,@state.choice2,@state.choice3]
          page_num : 1
          num_per_page : @state.num_per_page
          panel_type: @props.panel_type

      common.getConferenceHelmReviews(data, (result) =>
          @sortedNotes(result['notes'])
      )

  getThesePages: (info) =>

      data =
          page_num: info
          num_per_page: @state.num_per_page
          projectid: @props.projectid
          sort_type : [@state.choice1,@state.choice2,@state.choice3]
          panel_type: @props.panel_type

      common.getConferenceHelmReviews(data, (result) =>
          @setState({ page_num: info })
          @sortedNotes(result['notes'])

          elem = @refs.notes_map
          if (elem)
              window.scrollTo(0, elem.offsetTop)
      )


  dontSortByReviews: =>

      @setState({ choice1: "all_review_stages" },
                @sortByChosenFormat.bind(this, @state.choice1))

  sortByReviewsComplete: =>

      @setState({ choice1: "fully_reviewed" },
                @sortByChosenFormat.bind(this, @state.choice1))


  sortByPartiallyReviewed: =>

      @setState({ choice1 : 'partially_reviewed'},
                @sortByChosenFormat.bind(this, @state.choice1))

  sortByNonReviewed: =>

      @setState({ choice1 : 'not_reviewed'},
                @sortByChosenFormat.bind(this, @state.choice1))

  sortByNoReviewersAssigned: =>

      @setState({ choice2 : 'no_reviewers'},
                @sortByChosenFormat.bind(this, @state.choice2))

  sortByNeedsReviewers: =>

      @setState({ choice2 : 'some_reviewers'},
                @sortByChosenFormat.bind(this, @state.choice2))


  sortByMaxReviewersAssigned: =>

      @setState({ choice2 : 'max_reviewers'},
                @sortByChosenFormat.bind(this, @state.choice2))

  dontSortByReviewers: =>

      @setState({ choice2 : 'all_reviewer_staffing'},
                @sortByChosenFormat.bind(this, @state.choice2))

  dontSortByAcceptance: =>

      @setState({ choice3: "all_acceptance_stages" },
                @sortByChosenFormat.bind(this, @state.choice3))

  acceptedPapers: =>

      @setState({ choice3 : 'accepted'},
                @sortByChosenFormat.bind(this, @state.choice3))

  pendingPapers: =>

      @setState({ choice3 : 'pending_decision'},
                @sortByChosenFormat.bind(this, @state.choice3))

  rejectedPapers: =>

      @setState({ choice3 : 'rejected'},
                @sortByChosenFormat.bind(this, @state.choice3))

  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  sortingRubric: () =>
      if @state.choice1 is 'all_review_stages'
           dont_sort_reviews_button =
               <button className='note_sort_bar_active' onClick={@dontSortByReviews}>
                   all</button>
      else
           dont_sort_reviews_button =
               <button className='note_sort_bar_inactive' onClick={@dontSortByReviews}>
                   all</button>
      if @state.choice1 is 'fully_reviewed'
           reviews_complete_button =
               <button className='note_sort_bar_active' onClick={@sortByReviewsComplete}>
                   reviewed</button>
      else
           reviews_complete_button =
               <button className='note_sort_bar_inactive' onClick={@sortByReviewsComplete}>
                   reviewed</button>
      if @state.choice1 is 'partially_reviewed'
           some_reviews_button =
               <button className='note_sort_bar_active' onClick={@sortByPartiallyReviewed}>
                       partially reviewed</button>
      else
           some_reviews_button =
               <button className='note_sort_bar_inactive' onClick={@sortByPartiallyReviewed}>
                       partially reviewed</button>
      if @state.choice1 is 'not_reviewed'
           no_reviews_button =
               <button className='note_sort_bar_active' onClick={@sortByNonReviewed}>
                       no reviews</button>
      else
           no_reviews_button =
               <button className='note_sort_bar_inactive' onClick={@sortByNonReviewed}>
                       no reviews</button>
      if @state.choice2 is 'no_reviewers'
           unassigned_reviewers_button =
               <button className='note_sort_bar_active' onClick={@sortByNoReviewersAssigned}>
                       no reviewers</button>
      else
           unassigned_reviewers_button =
               <button className='note_sort_bar_inactive' onClick={@sortByNoReviewersAssigned}>
                       no reviewers</button>
       if @state.choice2 is 'some_reviewers'
            needs_reviewers_button =
                <button className='note_sort_bar_active' onClick={@sortByNeedsReviewers}>
                        some reviewers</button>
       else
            needs_reviewers_button =
                <button className='note_sort_bar_inactive' onClick={@sortByNeedsReviewers}>
                        some reviewers</button>
        if @state.choice2 is 'max_reviewers'
             max_reviewers_button =
                 <button className='note_sort_bar_active' onClick={@sortByMaxReviewersAssigned}>
                         full reviewers</button>
        else
             max_reviewers_button =
                 <button className='note_sort_bar_inactive' onClick={@sortByMaxReviewersAssigned}>
                         full reviewers</button>
       if @state.choice2 is 'all_reviewer_staffing'
            all_reviewer_staffing_button =
                <button className='note_sort_bar_active' onClick={@dontSortByReviewers}>
                    all</button>
       else
            all_reviewer_staffing_button =
                <button className='note_sort_bar_inactive' onClick={@dontSortByReviewers}>
                    all</button>
      if @state.choice3 is 'all_acceptance_stages'
           dont_sort_by_acceptance_button =
               <button className='note_sort_bar_active' onClick={@dontSortByAcceptance}>
                       all</button>
      else
           dont_sort_by_acceptance_button =
               <button className='note_sort_bar_inactive' onClick={@dontSortByAcceptance}>
                       all</button>
      if @state.choice3 is 'accepted'
            accepted_button =
                <button className='note_sort_bar_active' onClick={@acceptedPapers}>
                        accepted</button>
      else
            accepted_button =
                <button className='note_sort_bar_inactive' onClick={@acceptedPapers}>
                        accepted</button>
      if @state.choice3 is 'pending_decision'
            pending_decision_button =
                <button className='note_sort_bar_active' onClick={@pendingPapers}>
                        pending decision</button>
      else
            pending_decision_button =
                <button className='note_sort_bar_inactive' onClick={@pendingPapers}>
                        pending decision</button>
      if @state.choice3 is 'rejected'
            rejected_button =
                <button className='note_sort_bar_active' onClick={@rejectedPapers}>
                        rejected</button>
      else
            rejected_button =
                <button className='note_sort_bar_inactive' onClick={@rejectedPapers}>
                        rejected</button>
      return(
          <div className='note_infoDiv'>
              <div className='container-fluid'>
                  <div className='row no-gutters'>
                      <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                        Sort by:
                      </div>
                      <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                          { reviews_complete_button }&nbsp;
                          { some_reviews_button }&nbsp;
                          { no_reviews_button }&nbsp;
                          { dont_sort_reviews_button }&nbsp;
                      </div>
                  </div>

                  <div className='row no-gutters'>
                      <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                      </div>
                      <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                        { max_reviewers_button }&nbsp;
                        { needs_reviewers_button }&nbsp;
                        { unassigned_reviewers_button }&nbsp;
                        { all_reviewer_staffing_button }&nbsp;
                      </div>
                  </div>
                  <div className='row no-gutters'>
                      <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                      </div>
                      <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                        { accepted_button }&nbsp;
                        { pending_decision_button }&nbsp;
                        { rejected_button }&nbsp;
                        { dont_sort_by_acceptance_button }&nbsp;
                      </div>
                  </div>
              </div>
          </div>
      )

  rolesBar: () =>
      if @state.roles.length
          <div className='row border no-gutters p-1'>
              <span className="ml-0 pl-2">
                My roles: <RolesBarConf project={@state.project}
                    my_accepted_roles={@state.my_accepted_roles}
                    roles={@state.roles}
                    userid={@state.userid}
                    view={'mini'}
                    setUpdatedRoles={@setUpdatedRoles}
                    getUserNoteDetails={@getUserNoteDetails} />
              </span>
          </div>


  paperListings: () =>
      if @state.notes and @state.project.tags
        if @state.notes.length > 0
          if @state.project.tags
            if @state.roles.length > 0
                      <div ref='notes_map'>
                          {@state.notes.map((paper) =>
                              <ConferenceNoteReviews key={paper.id}
                                  note={paper}
                                  project={@state.project}
                                  reviewq={@state.project.reviewq}
                                  roles={@state.roles}
                                  panel_type={@props.panel_type}
                                  spots={ @state.spots }
                                  clocks={ @state.clocks }
                                  places={ @state.places } />
                          ,this)}
                          <Pagination page_num={@state.page_num}
                              lengthy={@state.lengthy}
                              onClick={@getThesePages} />
                      </div>
            else
                # no roles
                <div ref='notes_map'>
                    {@state.notes.map((paper) =>
                        <ConferenceNoteReviews key={paper.id}
                            note={paper}
                            roles={@state.roles}
                            panel_type={@props.panel_type} />
                    ,this)}
                    <Pagination page_num={@state.page_num}
                        lengthy={@state.lengthy}
                        onClick={@getThesePages} />
                </div>
        else
            # no papers
            <div>
                <br />
                <i>Nothing here.... yet....</i>
                <br />
                <br />
                <br />
                <br />
            </div>

  render: =>

    conf_nav_vert =
        <div className='col-md-2 col-lg-2 border d-none d-md-block'>
            <NavGeneral key={@state.project.id}
                id={@state.project.id}
                ptype={@state.project.ptype}
                caller={'tools'}
                subset={@state.subset}
                getThisSortType={@getThisSortType}
                userid={@state.userid}
                styling={'col-12'} />
        </div>
    conf_nav_horiz =
        <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
            <NavGeneral key={@state.project.id}
                id={@state.project.id}
                ptype={@state.project.ptype}
                caller={'tools'}
                subset={@state.subset}
                getThisSortType={@getThisSortType}
                userid={@state.userid}
                styling={'mr-2'} />
        </div>

      if @state.mode is 'viewing'
          <div>
              <HeaderBar />
              <div className='container-fluid pr-0 pl-0'>
                  <HeaderBar />
                  <ConferenceHeader
                      project={@state.project}
                      userid={@state.userid}
                      roles={@state.roles} />
                  <div className='row no-gutters'>
                      {conf_nav_vert}
                      {conf_nav_horiz}
                      <div ref={@activeAreaRef} className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                          <div className='row border no-gutters'>
                              <span className='ml-1 p-1'>
                                  {@state.notes_length} papers for your { @props.panel_type } panel
                              </span>
                          </div>
                          { @rolesBar() }
                          <div className='row no-gutters'>
                              { @sortingRubric() }
                          </div>
                          <div className='row no-gutters'>
                              { @paperListings() }
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      else
          <div>
            <HeaderBar />
            <Loading />
          </div>
