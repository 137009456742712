Link = require('react-router-dom').Link
common = require('../common')

module.exports = class CreateReviewQs extends React.Component
  displayName: 'CreateReviewQs'

  constructor: (props) ->
      super(props)
      @state = {
        project : @props.project
        mode : 'reviewQ editing collapsed'
      }

  collapseToButton: () =>
      @setState({ mode : 'reviewQ editing collapsed' })

  getConfQs: =>
      @setState({ mode : 'creating reqviewQs'})

  submitReviewQs: () =>
      data =
        projectid : @props.project.id
        text : @refs.review_qs.value

      common.submitProjectReviewQs(data, (result) =>
            updated_project = @state.project
            updated_project.reviewq = data.text
            @setState({ project : updated_project })
            @setState({ mode : 'reviewQ editing collapsed' })
      )


  render: =>
    if @state.project.reviewq?
      default_txt = @state.project.reviewq
    else
      default_txt = ''

    if @state.mode is 'reviewQ editing collapsed'
      <div>
        <button className="btn btn-primary mr-1"
                onClick={@getConfQs}>Create review questions</button>
          Create the questions to address in evaluating papers.
      </div>
    else
      <div>
        Use a new line for each question:
        <textarea id="review_qs" ref="review_qs" rows="5" defaultValue={default_txt}></textarea>
        <div className='proj_block'>
          <div className='proj_inline_R'>
            <button className="btn btn-secondary btn-txt-sm" onClick={@submitReviewQs}>submit</button>
          </div>
          <div className='proj_inline_R'>
            <button className="btn btn-secondary btn-txt-sm" onClick={@collapseToButton}>hide</button>
          </div>
        </div>
      </div>
