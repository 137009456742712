common = require('../common')

module.exports = class ConferencePaymentsSummaryLine extends React.Component
  displayName: 'ConferencePaymentsSummaryLine'

  constructor: (props) ->
    super(props)
    # expects props: payment
    @state = { }

  render: =>
      <tr>
        <td>{ @props.payment.fee_category } </td>
        <td>${ @props.payment.fee_amount/100 } USD</td>
        <td>{ @props.payment.num_charges }</td>
        <td>${ @props.payment.total/100 } USD</td>
      </tr>
