common = require('../common')
SetDate = require('./set_date')
SetPlace = require('./set_place')

module.exports = class TimePlace extends React.Component
  displayName: 'TimePlace'

  constructor: (props) ->
      super(props)
      # props are:
      # spottable_id
      # id of object
      # obj_type of object ('note' or ...)
      # clocks and places (available)
      @state = {
          datetime: ''
          gmt_offset: ''
          duration: ''
          gmt_offset: ''
          timezone: ''
          place: {name: '', lat: '', lon: ''}
          time_error_msg: ''
          place_error_msg: ''
          t_alert: ''
      }

  componentDidMount: =>

      if @props.spottable_id
          data =
              spottable_id: @props.spottable_id

          common.getTimePlaceForSpottable(data, (result) =>
              console.log("initial retrieve, result.spot.clock.debut=")
              console.log(result.spot.clock.debut)
              # if the note has a spot, set it
              if (result.spot) and (result.spot.clock)
                  datetime = result.spot.clock.debut
                  duration = (result.spot.clock.fin - result.spot.clock.debut)/60
                  gmt_offset = result.spot.clock.gmt_offset
                  timezone = result.spot.clock.timezone
              else
                  datetime = ''
                  duration = ''
                  gmt_offset = ''
                  timezone = ''
                  t_alert = 'text-danger'
              place = {name: '', lat: '', lon: ''}
              if (result.spot) and (result.spot.place)
                  place.name = result.spot.place.name
                  place.lat = result.spot.place.lat
                  place.lon = result.spot.place.lon
                  t_alert = ''
              @setState({
                  datetime: datetime
                  duration: duration
                  gmt_offset: gmt_offset
                  timezone: timezone
                  place: place
                  time_error_msg: ''
                  place_error_msg: ''
                  t_alert: t_alert
                  })
          )
      else
          # if the note lacks a spot, show available times and places
          @setState({
              datetime: ''
              duration: ''
              gmt_offset: ''
              timezone: ''
              place: {name: '', lat: '', lon: ''}
              time_error_msg: ''
              place_error_msg: ''
              t_alert: 'text-danger'
              })

  saveTime: (data) =>

      data.id = @props.id
      data.obj_type = @props.obj_type
      data.projectid = @props.projectid

      common.saveTime( data, (result) =>
          if result.error
              @setState({ time_error_msg: result.error })
          else
              @setState({
                  datetime: result.clock.debut
                  gmt_offset: result.clock.gmt_offset
                  duration: data.duration
                  timezone: result.clock.timezone
                  time_error_msg: ''
                  })
      )

  savePlace: (data) =>

      data.id = @props.id
      data.obj_type = @props.obj_type
      data.projectid = @props.projectid

      common.savePlace( data, (result) =>
          if result.error
              @setState({ place_error_msg: result.error })
          else
              place = {name: result.name, lat: result.lat, lon: result.lon}
              @setState({ place: place })
      )

  render: =>

      <div className='row no-gutters'>
          <div className='row no-gutters'>
              <div className={ @state.t_alert }>
                  <SetDate
                      datetime={ @state.datetime }
                      duration={ @state.duration }
                      gmt_offset={ @state.gmt_offset }
                      timezone={ @state.timezone }
                      clocks={ @props.clocks }
                      saveTime={ @saveTime } />
                  <span className='text-danger'>
                      { @state.time_error_msg }
                  </span>
              </div>
          </div>
          <div className='row no-gutters'>
              <div className={ @state.t_alert }>
                  <SetPlace
                      name={ @state.place.name }
                      lat={ @state.place.lat }
                      lon={ @state.place.lon }
                      places={ @props.places }
                      savePlace={ @savePlace } />
                  <span className='text-danger'>
                      { @state.place_error_msg }
                  </span>
              </div>
          </div>
      </div>
