common = require('./common')
CreateNoteMetadata = require('./create_title/create_note_metadata')
HeaderBar = require('./header_bar')
Loading = require('./loading')
NavGeneral = require('./nav_general')
ProjectHeader = require('./project_header')
RolesBarProject = require('./roles/roles_bar_project')

module.exports = class ProjectAbout extends React.Component
  displayName: 'ProjectAbout'

  constructor: (props) ->
      super(props)
      projectid = @props.match.params.projectId
      @state = {
          projectid: projectid
          proj_about: ''
          mode: 'loading'
          openEditor: true
          editorState: ''
          editor: ''
          note_editor: 'MathJax'    # Default value for a new note
          project: ''
          userid: ''
          roles: []
          my_accepted_roles: []
          error_msg: ''
          creator: 'false'
      }

  componentDidMount: () =>
      common.fetchProject((data) =>
          if data['results'] is 'not found'
              @setState({ mode : 'invalid_conference' })
          else if data['results'] is 'deleted'
              @setState({ mode : 'deleted' })
          else
              my_accepted_roles = []
              for role in data['results'][0]['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              if 'creator' in my_accepted_roles
                @setState({ creator: 'true' })

              @setState({
                  project: data['results'][0],
                  proj_about: data['results'][0]['about'],
                  userid: data['userid'],
                  roles: data['results'][0]['roles'],
                  my_accepted_roles: my_accepted_roles,
                  mode: 'viewing'
                  }, @setDefaultTxt)

              document.title = data['results'][0].title

      , @props.match.params.projectId)

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()

  getThisSortType: (new_subset) =>
      @setState({subset: new_subset}, @getThesePages)

  updateProjectAbout: () =>
      about = @state.editor.getData()
      @setState({ proj_about : about })
      @toggleReadEditMode()

      data =
          projectid: @state.project.id
          project_about: about

      common.submitProjectAbout(data, (result) =>
        # nothing to see here
      )

  toggleReadEditMode: () =>
      if @state.mode is 'editing'
        @setState({ mode : 'viewing' })
        @closeCkEditor()
      else
        @setState({ mode : 'editing' })
        @openEditor()

  gotoToggleAbout: =>
      if @state.show_about is false
          @setState({ show_about : true })
      else
          @setState({ show_about : false })

  cancelUpdate: () =>
      @closeCkEditor()
      @setState({ mode : 'viewing' })

  closeCkEditor: () =>
      CKEDITOR.instances.project_about.destroy()
      @preview()

  preview: =>
      document.getElementById("project_about_txt").innerHTML= @state.editor.getData()
      # @refs.project_about_txt.innerHTML = @state.editor.getData()

  setDefaultTxt: () =>
      document.getElementById("project_about_txt").innerHTML = @state.proj_about

  openEditor: =>
      # @refs.project_about.contenteditable = true
      document.getElementById("project_about").setAttribute("contenteditable", "true")
      editor = CKEDITOR.replace('project_about')
      @setState({ editor : editor })
      @setState({ openEditor : true })
      editor.on('instanceReady', () =>
          editor.setData(@state.proj_about))
      editor.on('change', (e) =>
          @preview())

  closeEditor: () =>
      CKEDITOR.instances.fullarticle.destroy()
      @setDefaultTxt

  preview: =>
      # @refs.project_about_txt.innerHTML = @state.editor.getData()
      document.getElementById("project_about_txt").innerHTML = @state.editor.getData()

  getUserNoteDetails: =>
      @setState({mode : 'create_note_metadata'})

  setMode: (mode_option) =>
      if mode_option is 'viewing'
          # I don't think this ever gets called -- page location changes
          @setState({ mode: 'viewing' }, @setDefaultTxt)
      else if mode_option is 'cancelled'
          @setState({ mode: 'viewing'}, @setDefaultTxt)
      else
          @setState({ mode: mode_option })

  setUpdatedRoles: (what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  rolesBar: () =>
      if @state.roles
          roles_bar =
              <RolesBarProject project={@state.project}
                        my_accepted_roles={@state.my_accepted_roles}
                        roles={@state.roles}
                        userid={@state.userid}
                        view={'full'}
                        setUpdatedRoles={@setUpdatedRoles}
                        getUserNoteDetails={@getUserNoteDetails} />

      <span>
          { roles_bar }
      </span>

  projectHeader: () =>
      if (@state.mode != 'invalid project') or (@state.mode != 'deleted') or (@state.mode != 'some other error')
          <span>
              <ProjectHeader key={@state.project.user_id}
                             project={@state.project}
                             userid={@state.userid}
                             roles={@state.roles} />
              <div className='proj_actions_Div'>
                  { @rolesBar() }
              </div>
            </span>

  makeNewPaper: () =>
      if @state.mode is 'create_note_metadata'
          <div className='container-fluid border'>
              <CreateNoteMetadata project={@state.project}
                                  setMode={@setMode}
                                  version={'new'}/>
          </div>

  problemMsg: () =>
      if @state.mode is 'invalid project'
          <div>
              <br />
              <h2>We cannot find that project... Try refreshing!</h2>
              If you continue to get this error, then maybe the project number is wrong?
          </div>
      else if @state.mode is 'deleted'
          <div>
              <br />
              <h2>Uh oh... It appears the owner has deleted this project.</h2>
          </div>
      else if @state.mode is 'some other error'
          <div>
              <br />
              <h2>{@state.error_msg}</h2>
          </div>

  editingArea: () =>

      if @state.mode is 'viewing'
          EditorStyle =
            display : 'none'
          PreviewStyle =
            display : 'block'
          ButtonRow =
              if @state.creator is 'true'
                      <div className='col'>
                        <div className='float-right pr-2'>
                            <button className="btn btn-secondary" onClick={@toggleReadEditMode}>
                              Edit
                            </button>
                        </div>
                        <br />
                      </div>
      else
          EditorStyle =
            display : 'block'
          PreviewStyle =
              display : 'none'
          ButtonRow =
              <div className='proj_block'>
                <div className='proj_inline_L'>
                    <h2>About</h2>
                </div>
                <div className='proj_inline_R'>
                  <button className="btn btn-secondary" onClick={@cancelUpdate}>
                    cancel
                  </button>
                </div>
                <div className='proj_inline_R'>
                  <button className="btn btn-secondary" onClick={@updateProjectAbout}>
                    save
                  </button>
                </div>
              </div>

      AboutRow =
          <span>
              <textarea style={EditorStyle}
                        id="project_about"
                        ref="project_about"
                        defaultValue={@state.proj_about}
                        onChange={@changeHandler}>
              </textarea>
              <div style={PreviewStyle}
                  className='p-3'
                  id='project_about_txt'></div>
              <br />
              <div className='proj_save_conference_div'
                  style={EditorStyle}>
                  <button className="btn btn-secondary"
                          onClick={@updateProjectAbout}>Save</button>
              </div>
          </span>

      if (@state.mode is 'viewing') or (@state.mode is 'editing')
          <div>
            <div className='p-0 m-0'>
                <div className='row no-gutters p-3'>
                    { ButtonRow }
                </div>
                { AboutRow }
            </div>
          </div>
      else if @state.mode is 'loading'
          <Loading />


  render: =>

      if @state.project
          conf_nav_vert =
              <div className='col-md-2 col-lg-2 border d-none d-md-block'>
                  <NavGeneral
                      id={@state.project.id}
                      ptype={@state.project.ptype}
                      caller={'about'}
                      userid={@state.userid}
                      styling={'col-12'} />
              </div>
          conf_nav_horiz =
              <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
                  <NavGeneral
                      id={@state.project.id}
                      ptype={@state.project.ptype}
                      caller={'about'}
                      userid={@state.userid}
                      styling={'mr-2'} />
              </div>

      <div>
          <HeaderBar />
          <div className='container-fluid pr-0 pl-0 border'>
              { @projectHeader() }
              <div className='row no-gutters'>
                  {conf_nav_vert}
                  {conf_nav_horiz}
                  <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                      { @problemMsg() }
                      { @editingArea() }
                      { @makeNewPaper() }
                  </div>
              </div>
          </div>
      </div>
