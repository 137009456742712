pdfjs = require('pdfjs-dist')
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.bundle.js'

module.exports = class PDFCanvas extends React.Component
    @PDF_PROPS = ['data', 'httpHeaders', 'password', 'url', 'verbosity',
                  'withCredentials']

    # props may include anything from PDF_PROPS, which are passed directly to
    # getDocument. In addition, the following props are recognized:
    # * pageNumber: The page number to render. Defaults to 1.
    # * scale: The zoom scale (as a fraction). Defaults to 1.0.
    constructor: (props) ->
        super(props)
        @state = {
            fatal: null
        }
        @canvasRef = React.createRef()
        @pdfDoc = null
        @pageBeingRendered = null
    
    componentDidMount: =>
        args = {}
        for own key, value of @props
            if key in PDFCanvas.PDF_PROPS
                args[key] = value
        @pdfDoc = pdfjs.getDocument(args).promise.then(@pdfLoaded).catch(@pdfLoadFailed)
    
    pdfLoaded: (pdfDoc) =>
        @pdfDoc = pdfDoc
        if @props.pdfLoadedCallback?
            @props.pdfLoadedCallback(pdfDoc)
        @renderCurrentPage()
    
    pdfLoadFailed: (err) =>
        @setState({fatal: "Failed to load PDF document: " + err})
    
    getTotalPages: =>
        if @pdfDoc?
            @pdfDoc.numPages
        else
            null

    componentDidUpdate: (prevProps) =>
        if !(prevProps?) or prevProps.pageNumber != @props.pageNumber or prevProps.scale != @props.scale
            @renderCurrentPage()

    renderCurrentPage: =>
        if ! (@pageBeingRendered?)
            if ! (@props.pageNumber?)
                @pageBeingRendered = 1
            else if @props.pageNumber < 1 or @props.pageNumber > @getTotalPages()
                return
            else
                @pageBeingRendered = @props.pageNumber

            @pdfDoc
                .getPage(@pageBeingRendered)
                .then(@pageWasRetrievedForRender)
                .catch((err) =>
                    console.log(
                        "Failed to retrieve page #{String.valueOf(@pageBeingRendered)}: #{err}")
                    @renderCompleted)

    pageWasRetrievedForRender: (page) =>
        viewport = page.getViewport({scale: @props.scale ? 1.0})
        @canvasRef.current.height = viewport.height
        @canvasRef.current.width = viewport.width

        page
            .render({
                canvasContext: @canvasRef.current.getContext('2d')
                viewport: viewport
            })
            .promise    
            .catch((err) =>
                console.log(
                    "Failed to render page #{String.valueOf(page.pageNumer)}:", err))
            .finally(@renderCompleted)

    renderCompleted: =>
        if @props.pageNumber != @pageBeingRendered
            @pageBeingRendered = null
            @renderCurrentPage
        else
            @pageBeingRendered = null

    render: =>
        if @state.fatal
            <div className="error">{@state.fatal}</div>
        else
            <canvas ref={@canvasRef}></canvas>
