common = require('./common')
GetNoteAuthorContacts = require('./authorcontact/get_note_authorcontacts')
Link = require('react-router-dom').Link
TagProjectRollSendClick = require('./tags/tag_project_roll_send_click')
Loading = require('./loading')
Title = require('./titles/title')
TitleLinkSmall = require('./titles/title_link_small')

module.exports = class ConferenceSubmissionUserOwnedNoteLine extends React.Component
  displayName: 'ConferenceSubmissionUserOwnedNoteLine'

  constructor: (props) ->
      super(props)
      authors_exist = false
      num_authors = props.note.authorcontacts.length
      console.log("in conf_sub user owned note line")
      console.log("@props=")
      console.log(@props)
      console.log("num_authors=",num_authors)
      @state = {
          mode : ''
          added_tag_list : []
          num_authors: num_authors
          submit_paper: @props.submit_paper
          submit_slideset: @props.submit_slideset
          submit_poster: @props.submit_poster
          paper_btn_txt: 'paper?'
          slideset_btn_txt: 'slides?'
          poster_btn_txt: 'poster?'
          choose_article_reminder: 'false'
          choose_tag_reminder: 'false'
          reminder: ''
      }
      @assignSessionBox = React.createRef()

  addThisTagToListForUser: (tag) =>
      list = @state.added_tag_list

      # first, check if the word is on the list, if so, remove it
      # otherwise add it
      pos = list.indexOf(tag)

      if pos > -1
          list = list.splice(0,pos)
          if tag.word[tag.word.length-1] is '✓'
              tag.word = tag.word.substr(0,tag.word.length-2)
      else
          for tmp_tag in @props.conf.tags
              if tmp_tag.id is tag.id
                  tmp_tag.word = tag.word + ' ✓'
          list.push(tag)

      @setState({added_tag_list : list})

  registerUpdatedNoteTags: =>
      if ((@state.added_tag_list.length > 0) and (@props.note.note_type is 'short_course'))
          @setState({mode: 'ready for submission'})
      else if (@state.added_tag_list.length > 0)
          @setState({mode: 'choose article'})
      else
          @setState({choose_tag_reminder: 'true'})

  afterItemsChosen: =>
      if (@state.submit_paper is 'true') or (@state.submit_slideset is 'true') or (@state.submit_poster is 'true')
          @setState({mode : 'ready for submission'})
      else
          @setState({choose_article_reminder: 'true'})

  scrollWindow: (ref_location) =>
      elem = @refs[ref_location]
      if (elem)
          elem.scrollIntoView()
          window.scrollBy(0,-100)

  showTags: =>
      if @props.conf.tags.length > 0
          @setState({mode: 'show_tags' }, @scrollWindow(@assignSessionBox))
      else if @props.note.note_type is 'short_course'
          @setState({mode: 'ready for submission'})
      else
          @setState({mode: 'choose article'})

  mark_note_submission: =>
      if @state.submit_paper is 'false'
          @setState({paper_btn_txt: 'paper ✓', submit_paper: 'true'})
      else
          @setState({paper_btn_txt: 'paper?', submit_paper: 'false'})

  mark_slideset_submission: =>
      if @state.submit_slideset is 'false'
          @setState({slideset_btn_txt: 'slides ✓', submit_slideset: 'true'})
      else
          @setState({slideset_btn_txt: 'slides?', submit_slideset: 'false'})

  mark_poster_submission: =>
      if @state.submit_poster is 'false'
          @setState({ poster_btn_txt: 'poster ✓', submit_poster: 'true' })
      else
          @setState({ poster_btn_txt: 'poster?', submit_poster: 'false' })

  addAuthorsToNote: =>
      # assumes the note came from a personal project!
      # window.location =  '/project/' + @props.note.project_id + '/title/' + @props.note.paperseries_id + '?edit'
      @setState({ mode: 'add_authors' })

  getNumAuthors: () =>

      data =
        noteid: @props.note.id

      common.getNumNoteAuthors(data, (result) =>
          @setState({
            num_authors: result.length_authors
            reminder: 'Please add authors' })
      )

  chooseConfSessions: () =>

      if (@props.note.note_type != 'conference') or (@state.num_authors > 0)

          if @state.choose_tag_reminder is 'true'
              further_tag_instructions =
                  <span className="text-danger">Choose one!</span>
          else
              further_tag_instructions = <span></span>

      if @state.mode is 'show_tags'
          <span ref={@assignSessionBox}>
              Choose a session:
              <div>
                {@props.conf.tags.map((conf_tag) =>
                    <TagProjectRollSendClick key={conf_tag.id}
                        tag={conf_tag}
                        onClick={@addThisTagToListForUser.bind(this,conf_tag)} />
                , this)}
                &nbsp;
                <button className="btn btn-danger btn-txt-sm"
                        onClick={@registerUpdatedNoteTags}>
                        save</button>
                {further_tag_instructions}
              </div>
          </span>
      else if (@state.mode is 'ready for submission') or (@state.mode is 'choose article')
          if @state.added_tag_list.length > 0
              <span className='pointnine_em'
                  ref={@assignSessionBox}>
                  Your submission will be go to this session:
                  {@state.added_tag_list.map((added_tag) =>
                      <TagProjectRollSendClick
                          key={added_tag.id}
                          tag={added_tag} />
                  )}
              </span>

  chooseSubmissionType: () =>

      if @state.choose_article_reminder is 'true'
          further_submission_instructions =
              <span className="text-danger">Choose at least one!</span>

      if (@props.note.note_type != 'conference') or (@state.num_authors > 0)
          if @state.mode is 'choose article'
              return (
                  <div>
                      <span className='pointnine_em'>
                          What would you like to submit?
                      </span>
                      <div className='row no-gutters'>
                          <button className="btn btn-primary btn-txt-sm" onClick={@mark_note_submission}>
                              {@state.paper_btn_txt}
                          </button>
                          <button className="btn btn-primary btn-txt-sm" onClick={@mark_slideset_submission}>
                              {@state.slideset_btn_txt}
                          </button>
                          <button className="btn btn-primary btn-txt-sm" onClick={@mark_poster_submission}>
                              {@state.poster_btn_txt}
                          </button>
                          <button className="btn btn-danger btn-txt-sm" onClick={@afterItemsChosen}>
                                  save</button>
                          {further_submission_instructions}
                      </div>
                  </div>)
          else if @state.mode is 'ready for submission'

              note_txt = ''
              slidset_txt = ''
              poster_txt = ''

              if (@state.submit_paper is 'true') and ((@state.submit_slideset is 'true') or (@state.submit_poster is 'true'))
                  note_txt = 'paper, '
              else if (@state.submit_paper is 'true')
                  note_txt = 'paper'
              if (@state.submit_slideset is 'true') and (@state.submit_poster is 'true')
                  slideset_txt = 'slides, '
              else if  (@state.submit_slideset is 'true')
                  slideset_txt = ' slides'
              if @state.submit_poster is 'true'
                  poster_txt = 'poster'

          if (@state.submit_paper is 'false' and @state.submit_slideset is 'false' and @state.submit_poster is 'false')
              <span></span>
          else
              return (
                  <div>
                      <span className='pointeight_em'>
                          Submitting your: {note_txt} {slideset_txt} {poster_txt}
                      </span>
                  </div>)

  submissionButton: () =>
      if (@state.mode is 'ready for submission')
          child_data =
              noteid: @props.note.id
              submit_paper: @state.submit_paper
              submit_slideset: @state.submit_slideset
              submit_poster: @state.submit_poster
              added_tag_list: @state.added_tag_list

          <span className='pointeight_em'>Looks good! If you are happy:&nbsp;
              <button className="btn btn-primary"
                  onClick={@props.onClick.bind(this, child_data)}>
                  submit
              </button>
          </span>

      else if @state.mode is 'loading'
          submission_button =
              <Loading />

  titleRow: () =>
      if (@props.note.note_type != 'conference') or (@state.num_authors > 0)
          <TitleLinkSmall
              title={@props.note.title}
              editor={@props.note.editor}
              onClick={@showTags} />
      else
          <Title title={@props.note.title} editor={@props.note.editor}/>

  authorRow: () =>
      if (@props.note.note_type is 'conference') and not (@state.num_authors > 0)
          <span>
              <div className='row no-gutters pointeight_em'>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                    <GetNoteAuthorContacts note={@props.note} />
                </div>
              </div>
              <div className='row no-gutters pointnine_em'>
                  <button className='btn btn-primary btn-txt-sm'
                          onClick={ @getNumAuthors }>
                      finished adding authors
                  </button>
                  <span className='text-danger'>{ @state.reminder }</span>
              </div>
          </span>


  render: =>

      if (@props.note.note_type != 'conference') or (@state.num_authors > 0)
          <div className='note_infoDiv'>
              <div className='container'>
                  <div className='row no-gutters'>
                      <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                          { @titleRow() }
                          { @authorRow() }
                          { @chooseConfSessions() }
                          { @chooseSubmissionType() }
                      </div>
                      { @submissionButton() }
                  </div>
              </div>
          </div>
      else
          # no authors and tis a conference
          <div className='note_infoDiv'>
              <div className='container'>
                  <div className='row no-gutters'>
                      <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                          { @titleRow() }
                          { @authorRow() }
                      </div>
                  </div>
              </div>
          </div>
