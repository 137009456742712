common = require('../common')

module.exports = class AuthorContactForm extends React.Component
  displayName: 'AuthorContactForm'

  constructor: (props) ->
      super(props)
      # expects as props:
      @state = {
        format_error : ''
        hide_email: 1
        mode: 'off'
      }
      @nameRef = React.createRef()
      @creditAsRef = React.createRef()
      @emailRef = React.createRef()
      @yesHideEmailRef = React.createRef()
      @noHideEmailRef = React.createRef()
      @affilRef = React.createRef()
      @juris1Ref = React.createRef()
      @departmentRef = React.createRef()
      @cityRef = React.createRef()
      @juris2Ref = React.createRef()
      @countryRef = React.createRef()
      @postalCodeRef = React.createRef()

  validateEmailAddress: (email) =>
      re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email))

  validateAuthorContact: () =>

      regex_name = /^[0-9A-Za-z\s\-.,\x80-\x9B\x9D-\xA5\xB5-\xB7\xE0-\xED]+$/

      name = @nameRef.current.value
      if name.length < 1
          @setState({ format_error: 'Name cannot be empty'})
          return
      else if name.length > 30
          @setState({ format_error: 'Name must be <30 characters'})
          return
      if not regex_name.test(name)
          @setState({format_error: 'Name may contain: letters, numbers, period, comma'})
          return

      credit_as = @creditAsRef.current.value
      if credit_as.length > 30
          @setState({ format_error: 'Please keep your credit category brief, <30 characters'})
          return

      email = @emailRef.current.value
      email = email.toLowerCase()
      email = email.trim()
      validation = @validateEmailAddress(email)
      if not validation
          @setState({ format_error: 'Invalid email'})
          return

      affiliation = @affilRef.current.value
      if affiliation.length < 1
          @setState({ format_error: 'Affiliation cannot be empty'})
          return

      city = @cityRef.current.value
      if city.length < 1
          @setState({ format_error: 'City cannot be empty'})
          return

      country = @countryRef.current.value
      if country.length < 1
          @setState({ format_error: 'Country cannot be empty'})
          return

      data =
        name : name
        credit_as: credit_as
        email : email
        hide_email : @state.hide_email
        affiliation : affiliation
        jurisdiction1: @juris1Ref.current.value
        department: @departmentRef.current.value
        city : city
        jurisdiction2: @juris2Ref.current.value
        country : country
        postcode: @postalCodeRef.current.value

      @props.addAuthorContact(data)

  setHideEmailPrefYes: () =>
      @setState({ hide_email: 1})

  setHideEmailPrefNo: () =>
      @setState({ hide_email: 0})

  errMsg: () =>
      if @state.format_error
          <div className="text-danger">{ @state.format_error }</div>
      else if @props.error_msg
          <div className="text-danger">{ @props.error_msg }</div>

  render: =>

    if @props.mode is 'add_authorcontact'
        <div className="container-fluid no-border">
              <input className="proj_author_input"
                  type="text"
                  ref={@nameRef}
                  placeholder="Full name*"  />
              <input className="proj_author_input"
                  type="text"
                  ref={@creditAsRef}
                  placeholder="What role to credit you for? For example, experiment, theory, etc."  />
              <input className="proj_author_input"
                  type="text"
                  ref={@emailRef}
                  placeholder="Email*"  />

              <div>
                  <button className="proj_rightpad_btn"
                      ref={@yesHideEmailRef}
                      style={backgroundColor: if @state.hide_email then "#A0EBB2" else "#CCCCCC"}
                      onClick={@setHideEmailPrefYes}>Yes</button>
                  <button className="proj_open_btn"
                      ref={@noHideEmailRef}
                      style={backgroundColor: if @state.hide_email then "#CCCCCC" else "#A0EBB2"}
                      onClick={@setHideEmailPrefNo}>No</button>
                  Hide email?
              </div>

              <input className="proj_author_input"
                  type="text"
                  ref={@affilRef}
                  placeholder="Affiliation:*"  />
              <input className="proj_author_input"
                  type="text"
                  ref={@juris1Ref}
                  placeholder="Other:"  />
              <input className="proj_author_input"
                  type="text"
                  ref={@departmentRef}
                  placeholder="Department:"  />
              <input className="proj_author_input"
                  type="text"
                  ref={@cityRef}
                  placeholder="City:*"  />
              <input className="proj_author_input"
                  type="text"
                  ref={@juris2Ref}
                  placeholder="State/Other:"  />
              <input className="proj_author_input"
                  type="text"
                  ref={@countryRef}
                  placeholder="Country:*"  />
              <input className="proj_author_input"
                  type="text"
                  ref={@postalCodeRef}
                  placeholder="Postal Code:"  />
              <br />
              { @errMsg() }
              <button className='btn btn-primary mr-1'
                  onClick={@validateAuthorContact}>
                  Save contact
              </button>
              <button className='btn btn-primary'
                  onClick={@props.addAuthorContact.bind(this, 'turn_off')}>
                  cancel
              </button>
        </div>

    else
        <span></span>
