common = require('../common')
Loading = require('../loading')
HeaderBar = require('../header_bar')
Pagination = require('../pagination')
ProjectNoteSummary = require('../project_note_summary')

module.exports = class TagSearchResults extends React.Component
  displayName: 'TagSearchResults'

  constructor: (props) ->
      super(props)
      tagwords = @props.match.params.tagWordList
      @state = {
          tagwords: tagwords

          page_num: 1
          num_per_page: 10
          lengthy: 2

          mode: 'loading'
          track_notes: []
          other_notes: []
          project: {}
      }

  componentDidMount: =>

      data =
        tagwordlist : @props.match.params.tagWordList
        page_num: 1
        num_per_page: @state.num_per_page

      common.getPaperSeriesWithTags(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid_conference' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })
          else
              @setState({
                  track_notes: result['track_notes'],
                  other_notes: result['other_notes'],
                  userid: result['userid'],
                  mode: 'viewing' })
          document.title = 'ZeroDivZero: tags=' + @props.match.params.tagWordList
      )

      common.getTotalNumberPaperSeriesWithTags(data, (result) =>
        lengthy = Math.ceil(result.total_count/@state.num_per_page)
        @setState({ lengthy: lengthy})
      )

  getThesePages: (info) =>

      data =
          page_num: info
          num_per_page: @state.num_per_page
          projectid: @props.match.params.projectId

      common.getPaperSeriesWithTags(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid project' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })
          else
            @setState({ notes: result['notes'],  page_num: info })

          elem = @refs.notes_map
          window.scrollTo(0, elem.offsetTop)
      )

  render: =>

      if @state.mode is 'invalid_conference'
          problem_msg =
          <div>
              <br />
              <h2>We cannot find that conference... Try refreshing!</h2>
              If you continue to get this error, then maybe the conference number is wrong?
          </div>
      else if @state.mode is 'conference_deleted'
          <div>
              <br />
              <h2>Uh oh... It appears the owner has deleted this conference.</h2>
          </div>

      if @state.mode is 'loading'
          loading = <div><Loading /></div>

      if (@state.mode is 'viewing') and (@state.other_notes) and (@state.other_notes.length>0)
          other_paper_list =
            <div>
                <span ref="notes_map">
                    {@state.other_notes.map((paper) =>
                        <ProjectNoteSummary key={paper.id}
                            note={paper}
                            project={@state.project}
                            roles={@state.roles}
                            login_id={@state.userid}
                            show_motive={'false'}
                            show_origin={'true'} />
                    ,this)}
                </span>
                <Pagination page_num={@state.page_num}
                    lengthy={@state.lengthy}
                    onClick={@getThesePages} />
            </div>
      else if @state.mode is 'viewing'
          other_paper_list =
              <div>
                  <h1>None yet. We are growing!</h1>
              </div>

      site_search =
          <div className='mb-2 mt-2'>
              <h2 className='p-0 m-0 text-secondary'>
                Searching ZeroDivZero for tag: "{ @state.tagwords }"...
              </h2>
          </div>

      <div>
          <HeaderBar />
          { site_search }
          { problem_msg }
          { other_paper_list }
          { loading }
      </div>
