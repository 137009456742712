Link = require('react-router-dom').Link
common = require('../common')
NoteSessionRollEditable = require('../tags/note_session_roll_editable')
TagNoteRoll = require('../tags/tag_note_roll')
TagProjectRollSendClick = require('../tags/tag_project_roll_send_click')

module.exports = class ShowOrEditNoteSessions extends React.Component
  displayName: 'ShowOrEditNoteSessions'

  constructor: (props) ->
    super(props)
    # expects props:
    # note, session_tags from the project

    # STRONG WARNING TODO:
    # There is something fishy going on. When you edit a tag from
    # note.conference_tags or project.tags or you send a click and edit that...
    # all the tags with that same id are edited.

    # It's as if state is being set directly although I go out of my way to
    # not do that.

    # I suspect the bug is somewhere else (a parent component).
    # So if that is ever fixed, this script *will not work* anymore.

    @state = {
      note: @props.note
      session_tags: @props.session_tags
      mode: 'view'
      is_first_edit: true
    }

  editSessions: =>

      if @state.mode is 'view'
          note_tag_ids = []
          note_tags = @state.note.conference_tags
          for tag in note_tags
              note_tag_ids.push(tag.id)
          session_tags = @props.session_tags
          for tag in session_tags
              if tag.id in note_tag_ids
                  tag.word = tag.word + ' ✓'
          @setState({
              session_tags: session_tags
              mode: 'edit' })

      else
          # save the edit and change to non-editing mode
          tag_ids = []
          for tag in @state.note.conference_tags
              tag_ids.push(tag.id)

          data =
              note_tag_ids: tag_ids
              noteid: @state.note.id

          common.updateTagsForNote(data, (result) =>
              session_tags = @state.session_tags
              for tag in session_tags
                  if tag.word[tag.word.length-1] is '✓'
                      tag.word = tag.word.substr(0,tag.word.length-2)
              @setState({ mode: 'view' })
          )

  addThisTagToListForUser: (tag_obj) =>
      # PUZZLE: every action done to tag_obj carries over to all other
      # objects that have that tag. So also to note.conference_tags
      # and session_tags. It seems to directly set state without me doing anything.
      tag = tag_obj
      session_tags = @state.session_tags
      updated_note = @state.note
      note_tags = @state.note.conference_tags
      note_tag_ids = []
      for note_tag in note_tags
          note_tag_ids.push(note_tag.id)

      # first, check if the word is on the note_tags, if so, remove it
      # otherwise add it
      pos = note_tag_ids.indexOf(tag.id)

      if pos > -1
          note_tags.splice(pos, 1)
          for ptag in session_tags
              if (ptag.id is tag.id)
                    # remove the check
                    ptag.word = ptag.word.substr(0,tag.word.length-2)
      else
          note_tags.push(tag)
          for ptag in session_tags
              if (ptag.id is tag.id)
                  # add a check to selected session
                  ptag.word = ptag.word + ' ✓'


        updated_note.conference_tags = note_tags

        @setState({
            note: updated_note
            session_tags: session_tags })


  render: =>

      if @state.mode is 'view'
          # ask if user wants to edit
          sessions_options =
              <div>
                  {@state.note.conference_tags.map((tag) =>
                      <TagNoteRoll key={tag.id}
                          tag={tag}
                          tag_type='conference'
                          note_type='conference' />
                  )}
                  <button className="btn btn-primary btn-sm ml-1"
                      onClick={@editSessions}>
                      Edit</button>
              </div>
      else
          # allow the user to edit the sessions
          sessions_options =
              <div>
                  {@state.session_tags.map((conf_tag) =>
                      <TagProjectRollSendClick key={conf_tag.id}
                          tag={conf_tag}
                          onClick={@addThisTagToListForUser.bind(this, conf_tag)} />
                  )}
                  &nbsp;
                  <button className="btn btn-danger btn-txt-sm"
                      onClick={@editSessions}>
                      save</button>
              </div>
