Link = require('react-router-dom').Link

module.exports = class HelloWorldRightPanel extends React.Component
  displayName: 'HelloWorldRightPanel'

  constructor: ->
    super()


  render: =>

      <div className='m-3'>
          <br />
          <div className='row'>
              <Link to='/our_story'>
              <img className='logo_img'
                  src='../../images/clip_art/0div0_planetlogo_350_278px.png'
                  alt='ZeroDivZero'></img>
              </Link>
          </div>
          <div className='row mb-3'>
              <strong className='big_em'>
                  ZeroDivZero is for defining the unknown
              </strong>
          </div>
          <div className='row'>
              Make it known. Save and share the science from your event.
          </div>
      </div>
