HeaderBar = require('../header_bar')
Policies = require('../site_info/policies')
LeftPanel = require('./leftpanel')
RightPanel = require('./rightpanel')


module.exports = class FrontPage extends React.Component
  displayName: 'FrontPage'
  constructor: ->
    super()
    @state = {}

  componentDidMount: =>
      #

  render: =>

    <span>
      <HeaderBar />
      <div className='bg-offwhite p-3 border-top'>
          <div className='container-fluid'>

              <div className='row'>

                  <div className='col-xs-7 col-sm-7 col-md-7 col-lg-7'>
                      <LeftPanel />
                  </div>

                  <div className='col-xs-5 col-sm-5 col-md-5 col-lg-5'>
                      <RightPanel />
                  </div>

              </div>

          </div>
      </div>

      <br />

      <div className='container-fluid mt-3'>
          <div className='bg-white mt-3'>
              <Policies />
              <br />
          </div>
      </div>

    </span>
