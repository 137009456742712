HeaderBar = require('../header_bar')
Policies = require('./policies')

module.exports = class CookiePolicy extends React.Component
  displayName: 'CookiePolicy'

  componentDidMount: () =>
      document.title = 'ZeroDivZero cookie policy'

  render: =>

      <div>
        <HeaderBar />
        <div className="text-left">
            <h1 className="text-left">Cookie Policy</h1>
              <p className="text-left">
                We use "cookies" on this site. A cookie is a piece of data
                stored on a site visitor's hard drive to help us improve your
                access to our site and identify repeat visitors to our site.
                For instance, we use a cookie to identify you so you do
                not have to log in a password more than once, thereby saving
                time while on our site. Cookies can also enable us to track
                and target the interests of our users to enhance the experience
                on our site.
              </p>
              <h1>Help</h1>
              <p className="text-left">
                This cookie policy is a work-in-progress. Further suggestions
                can be sent to:
                help@0div0.com
              </p>
        </div>
        <Policies />
      </div>
