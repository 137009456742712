ConferenceSubmissionUserOwnedProjectLine = require('./conference_submission_user_owned_project_line')
ConferenceSubmissionUserOwnedNoteLine = require('./conference_submission_user_owned_note_line')
Pagination = require('./pagination')
common = require('./common')
Link = require('react-router-dom').Link
Loading = require('./loading')

module.exports = class ConferenceSubmissionUserOwnedLists extends React.Component
  displayName: 'ConferenceSubmissionUserOwnedLists'

  constructor: (props) ->
      super(props)
      mode = 'choose_project'
      if @props.mode
        mode = @props.mode
      @state = {
          projects: []
          selected_project: ''
          mode: mode
          project: []
          notes: []
          submit_paper: 'false'
          submit_slideset: 'false'
          submit_poster: 'false'

          page_num: 1
          lengthy: 2
          num_per_page: 10
      }
      @projListRef = React.createRef()

  chosenProject: (id) =>
      @setState({selected_project : id})

      data =
          page_num: 1
          num_per_page: @state.num_per_page
          projectid: id

      common.projectNotes(data, (result) =>
          @setState({
              project: result['project']
              notes: result['notes']
              mode: 'choose_note',
              page_num: 1
          })
      )

      data =
          projectid: id

      common.getTotalNumberNotesInProject(data, (result) =>
          lengthy = Math.ceil(result.total/@state.num_per_page)
          @setState({lengthy: lengthy, notes_length: result.total})
      )

  chosenNote: (info) =>
      added_tag_ids = [ ]

      for tag in info.added_tag_list
          added_tag_ids.push(tag.id)

      data =
          conferenceid : @props.conference.id
          noteid : info.noteid
          submit_paper : info.submit_paper
          submit_slides: info.submit_slideset
          submit_poster: info.submit_poster
          added_tag_ids: added_tag_ids

      @setState({ mode : 'loading' })

      common.submitUserTitleToConference(data, (result) =>

          # updated to also submit to lab_group!
          # the goto looks what the original project type is.

          goto = '/' + @props.conference.ptype + '/' + @props.conference.id + '/titles'
          loc = window.location
          loc = loc.href.replace(loc.origin, '')
          # sometimes an extra ? is inserted, so get rid of it if there
          n = loc.indexOf('?')
          if n != -1
              loc = loc.substring(0, n)

          if loc is goto
              window.location.reload()
          else
              window.location = goto
      )

  scrollHere: (ref) =>
      if (ref)
          elem = ref.current
          if (elem)
            window.scrollTo(0, elem.offsetTop)

  componentDidMount: =>

      common.loginMonitor (data) =>
          # because of race-conditions, need to get userid inside this component.
          userid = data['userid']

          data =
              page_num: 1
              num_per_page: 10
              userid: userid
              match_these_roles: ['creator','contributor']

          common.fetchUserProjects(data, (result) =>
              projects = result.results
              @setState({projects: projects}, @scrollHere.bind(this, @projListRef))
          )

          common.getTotalNumberProjectsByUserid(data, (result) =>
            lengthy = Math.ceil(result.total/@state.num_per_page)
            @setState({ lengthy: lengthy})
          )


  getTheseNotePages: (info) =>
      data =
          page_num: info
          num_per_page: @state.num_per_page
          projectid: @state.selected_project

      common.projectNotes(data, (result) =>
          @setState({ notes: result['notes'], page_num: info })
      )


  getTheseProjectPages: (info) =>
      if not (info)
          info=1

      data =
          page_num: info
          num_per_page: @state.num_per_page
          userid:  @props.userid
          match_these_roles: ['creator','contributor']

      common.fetchUserProjects(data, (result) =>
          projects = result.results
          @setState({
              projects: projects
              page_num: info}, @scrollHere.bind(this, @projListRef))
      )

  setChooseProjectMode: =>
      @setState({ mode: 'choose_project' })


  render: =>

      if (@state.mode is 'choose_project') and (@state.projects.length > 0)
          <div className='container-fluid' ref={@projListRef}>
              <div className='row' ref={@projListRef}>
                <strong>Pick one of your projects?</strong>
              </div>
              {@state.projects.map( (project) =>
                <ConferenceSubmissionUserOwnedProjectLine
                          key={project.id}
                          project={project}
                          onClick={@chosenProject.bind(this, project.id)}/>
              ,this)}
              <Pagination page_num={@state.page_num}
                          lengthy={@state.lengthy}
                          onClick={@getTheseProjectPages} />
          </div>
      else if (@state.mode is 'choose_project') and (@state.projects.length == 0)
          <div>
              <div className='container-fluid'>
                <br />
                <h2 className='row no-gutters m-3'>Pick one of your projects...</h2>
                <div className='row no-gutters m-3'>
                  Oops! You do not have any projects yet.
                </div>
                <div className='row no-gutters m-3'>
                  Please upload a paper to one of your&nbsp;
                    <Link to={ '/profile/' + @props.userid + '/projects' }>
                        projects
                    </Link>.
                </div>
                <div className="row no-gutters instructions m-3 p-3">
                  ZeroDivZero works a little differently than a single-use conference website.
                  Your profile page will show your entire conference history!
                  Here are the steps:
                      <ul>
                          <li>Create a project for your work (projects help organize your work)</li>
                          <li>Upload a title (paper, slides, or poster) to the project</li>
                          <li>Come back to this conference page and click "submit"</li>
                      </ul>
                </div>
              </div>
          </div>
      else if @state.mode is 'choose_note'
          <div>
              <button className='btn btn-link mr-2'
                  onClick={@setChooseProjectMode}>
                  &#9666; Projects
              </button>
              <div className='border-bottom'>
                Pick one of your titles...
              </div>
              {@state.notes.map( (note) =>
                <ConferenceSubmissionUserOwnedNoteLine key={note.id}
                    note={note}
                    conf={@props.conference}
                    submit_paper={@state.submit_paper}
                    submit_slideset={@state.submit_slideset}
                    submit_poster={@state.submit_poster}
                    onClick={@chosenNote}/>
              ,this)}
              <Pagination page_num={@state.page_num}
                  lengthy={@state.lengthy}
                  onClick={@getTheseNotePages} />

          </div>
      else if @state.mode is 'loading'
          <Loading />
