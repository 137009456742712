
module.exports = class ShowPlace extends React.Component
  displayName: 'ShowPlace'

  constructor: (props) ->
      super(props)
      # props are: name, lat, lon
      @state = {
      }

  LatLon: () =>
      if (@props.lat) and (@props.lon)
          lat = @props.lat/10000000
          lon = @props.lon/10000000
          <span>
              , GPS({lat}, {lon})
          </span>


  render: =>
      <span>
          { @props.name }{ @LatLon() }
      </span>
