common = require('../common')
Link = require('react-router-dom').Link
NoteCommentRoll = require('./note_comment_roll_0div0_editor')
EditorChoice = require('../editor_choice')
GetWriting = require('../get_writing_manual_resize')

module.exports = class NoteCommentsOnly extends React.Component
  displayName: 'NoteCommentsOnly'

  constructor: (props) ->
      super(props)
      @state = {
        note: @props.note
        data: {logged_in_user: 'anonymous', logged_in_userid: ''}
        comments:  []
        reviews: [ ]
      }
      @writingRef = React.createRef()
      @editorChoiceRef = React.createRef()

  sortCommentsAndReviews: () =>
      reviews = []
      comments = []
      for comment in @state.note.comments
          if (comment.ctype == 'formal') or (comment.ctype == 'formal_anon')
              reviews.push(comment)
          else
              comments.push(comment)
      @setState({reviews: reviews, comments: comments})

  componentDidMount: () =>
      common.loginMonitor (data) =>
          current_user = data['current_user']
          prefix = if current_user then current_user + " " else ""
          if current_user
              logged_in_userinfo =
                  logged_in_user: current_user
                  logged_in_userid: data['userid']
              @setState(data: logged_in_userinfo)
              @writingRef.current.placeholder = prefix + "leave a comment"
          @sortCommentsAndReviews()

  submitProjectNoteComment: () =>
      newcomment =
          noteid: @props.note.id
          text: @writingRef.current.getValue()
          editor: @editorChoiceRef.current.getEditor()

      common.submitProjectNoteComment(newcomment, (result) =>
          @writingRef.current.setValue("")
          @setState({note: result['note']})
          @sortCommentsAndReviews()
      )

  render: =>

      if @state.data
          comment_roll =
              <span>
                {@state.comments.map((comment) =>
                    <NoteCommentRoll key={comment.id} comment={comment} data={@state.data} />
                , this)}
              </span>

      editor_options =
          <EditorChoice ref={@editorChoiceRef} editor={@state.new_comment_editor}/>

      if !(@state.data.logged_in_user is 'anonymous')
          get_writing_line =
              <span>
                  <div className="d-flex flex-row bd-highlight">
                    <div className='bd-highlight'>
                      Comment: &nbsp;
                    </div>
                    <div className='bd-highlight'>
                      { editor_options }
                    </div>
                  </div>
                  <div className="d-flex flex-row bd-highlight">
                    <GetWriting ref={@writingRef} key={@state.note.id} rows='1' placeholder="leave a comment" defaultValue="" />
                    <span>
                        <button className='btn btn-success'
                                onClick={@submitProjectNoteComment}>submit</button>
                    </span>
                  </div>
              </span>

      current_user = @state.data.logged_in_user
      if !(@state.data.logged_in_user is 'anonymous')
        comment_form =
          <div className='row no-gutters'>
            <div className='col-sm-12'>
                {get_writing_line}
            </div>
          </div>
      else
          reply_window =
            <h4></h4>

      <span>
        <div className='row no-gutters'>
          <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
            <strong>{@props.note.comment_counts.informal} Comments:</strong>
          </div>
        </div>
        {comment_roll}
        {comment_form}
      </span>
