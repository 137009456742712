
ConferenceFeeLineChoosable = require('./conference_fee_line_choosable')

module.exports = class UserChooseRegistrationFee extends React.Component
  displayName: 'UserChooseRegistrationFee'

  render: =>

    if @props.fees.length > 0
        fee_list =
            <span>
                <table className="table">
                    <thead>
                    <tr>
                      <th scope="col">Category</th>
                      <th scope="col">Fee</th>
                      <th scope="col">Select?</th>
                    </tr>
                    </thead>
                    <tbody>
                        {@props.fees.map((fee) =>
                            <ConferenceFeeLineChoosable key={fee.id} fee={fee} onClick={@props.onClick.bind(this,fee)} />
                        ,this)}
                    </tbody>
                </table>
            </span>
    else
        fee_list =
            <div className="d-flex justify-content-center">
                <h2>
                  <span className='text-danger'>
                    Conference registration has not been set up.
                  </span>
                </h2>
            </div>

    <div>
        { fee_list }
    </div>
