common = require('../common')
Link = require('react-router-dom').Link
ReactShare = require('react-share')
FacebookIcon = require('react-share').FacebookIcon
FacebookShareButton = require('react-share').FacebookShareButton
FacebookShareCount = require('react-share').FacebookShareCount
TwitterIcon = require('react-share').TwitterIcon
TwitterShareButton = require('react-share').TwitterShareButton
TwitterShareCount = require('react-share').TwitterShareCount
LinkedinIcon = require('react-share').LinkedinIcon
LinkedinShareButton = require('react-share').LinkedinShareButton
LinkedinShareCount = require('react-share').LinkedinShareCount
RedditIcon = require('react-share').RedditIcon
RedditShareButton = require('react-share').RedditShareButton
RedditShareCount = require('react-share').RedditShareCount
EmailIcon = require('react-share').EmailIcon
EmailShareButton = require('react-share').EmailShareButton
EmailShareCount = require('react-share').EmailShareCount

module.exports = class SocialSharing extends React.Component
  displayName: 'SocialSharing'

  constructor: (props) ->
      super(props)


  render: =>

      buttons_row =
          <div className='row no-gutters'>

              <span className='mr-1'>
                  <FacebookShareButton url={@props.shareUrl}
                      quote={ @props.title }>
                      <FacebookIcon size={24} round={true} />
                  </FacebookShareButton>
              </span>
              <span className='mr-3'>
                  <FacebookShareCount url={@props.shareUrl} />
              </span>

              <span className='mr-3'>
                  <TwitterShareButton url={@props.shareUrl}
                      quote={ @props.title }>
                      <TwitterIcon size={24} round={true} />
                  </TwitterShareButton>
              </span>

              <span className='mr-3'>
                  <LinkedinShareButton url={@props.shareUrl}
                      quote={ @props.title }>
                      <LinkedinIcon size={24} round={true} />
                  </LinkedinShareButton>
              </span>

              <span className='mr-1'>
                  <RedditShareButton url={@props.shareUrl}
                      quote={ @props.title }>
                      <RedditIcon size={24} round={true} />
                  </RedditShareButton>
              </span>
              <span className='mr-3'>
                  <RedditShareCount url={@props.shareUrl} />
              </span>

              <span className='mr-3'>
                  <EmailShareButton url={@props.shareUrl}
                      quote={ @props.title }>
                      <EmailIcon size={24} round={true} />
                  </EmailShareButton>
              </span>

          </div>

      modal_trigger_button =
          <button type="button"
                  className="clear_button"
                  data-toggle="modal"
                  data-target="#socialModal">
            <i className='fa fa-share-alt' aria-hidden='true'></i>
          </button>

      modal_window =
          <div className="modal fade"
              id="socialModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="ShareButtons"
              aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="modal-title" id="socialModalLabel">Share!</h2>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  { buttons_row }
                </div>
              </div>
            </div>
          </div>

      <span>
          { modal_trigger_button }
          { modal_window }
      </span>
