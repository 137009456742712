common = require('../common')
PlaceRoll = require('./place_roll')
ShowPlace = require('./show_place')

module.exports = class SetPlace extends React.Component
  displayName: 'SetPlace'

  constructor: (props) ->
      super(props)
      # props are: name, lat, lon
      name = @props.name
      lat = @props.lat
      lon = @props.lon
      @state = {
        name: name
        lat: lat
        lon: lon
        show_setter: 'true'
        error_msg: ''
      }

  componentDidMount: =>
      @setState({
        name: @props.name
        lat: @props.lat
        lon: @props.lon
        show_setter: 'true'
        error_msg: ''
      })
      if @props.name
          @setState({ show_setter: 'false' })

  componentDidUpdate: (prevProps) =>
      if @props != prevProps
          @setState({
            name: @props.name
            lat: @props.lat
            lon: @props.lon
            show_setter: 'true'
            error_msg: ''
          })
          if @props.name
              @setState({ show_setter: 'false' })

  turnOnSetter: =>
      @setState({ show_setter: 'true' })

  cancelEdit: =>
      @setState({ show_setter: 'false' })

  convertGPStoInteger: (val) =>
      whole = Math.floor(val)*10000000
      fract = Math.abs(whole - Math.floor(val*10000000))
      val = whole + fract
      return(val)

  goToSaveNewPlace: =>

      placename = @refs.placename.value

      # convert the vals to whole number + 7 digit fractions

      lat = Number(@refs.lat.value).toFixed(7)
      lat = @convertGPStoInteger(lat)

      lon = Number(@refs.lon.value).toFixed(7)
      lon = @convertGPStoInteger(lon)

      #if (isNaN(day)) or (isNaN(month)) or (isNaN(year)) or (isNaN(hour)) or
      #(day<1) or (month< 0) or (year<=0) or (hour<0) or
      #(day>31) or (month>11) or (hour>23)
      #    @setState({ error_msg: ' invalid date' })
      #    return

      @setState({ show_setter: 'false' })

      data =
          name: placename
          lat: lat
          lon: lon

      @props.savePlace(data)

  goToSavePlace: (thisplace)=>

      data =
          place_id: thisplace.id

      @props.savePlace(data)

  thePlace: =>
      <span>
          <ShowPlace
              name={ @state.name }
              lat={ @state.lat }
              lon={ @state.lon } />
          <button className="btn btn-primary ml-2" onClick={@turnOnSetter}>
              Edit
          </button>
      </span>

  placeButtons: =>
      if (@props.places)
          <div className='row no-gutters'>
              <span className='mr-1'>Choose a location:</span>
              <span>
                  {@props.places.map((place) =>
                      <PlaceRoll key={ place.id }
                                 place={ place }
                                 onClick={ @goToSavePlace.bind(this,place) } />
                  )}
              </span>
          </div>


  render: =>

    if @state.show_setter is 'true'
        <div className='container-fluid row no-gutters border border-danger border_rad_tenpx m-1 p-3'>
            <div>Choose a place</div>
            <div className='row no-gutters'>
                { @placeButtons() }
            </div>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                Name (required): <input className='full_input_textbox'
                           type='text'
                           ref='placename'
                           defaultValue={ @state.name }></input>
            </div>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                If needed: Lat= <input className='GPS_input_textbox'
                              type='text'
                              ref='lat'
                              defaultValue={ @state.lat/10000000 }></input>
                Lon= <input className='GPS_input_textbox'
                            type='text'
                            ref='lon'
                            defaultValue={ @state.lon/10000000 }></input>
                <button className="btn btn-primary"
                        onClick={@goToSaveNewPlace}>
                        save</button>
                <button className="btn btn-primary"
                        onClick={@cancelEdit}>
                        cancel</button>
                <span className='text-danger'>
                    { @state.error_msg }
                </span>
            </div>
        </div>
    else
        <div className='container-fluid row no-gutters'>
          { @thePlace() }
        </div>
