Link = require('react-router-dom').Link

module.exports = class NameIDRoll extends React.Component
  displayName: 'NameIDRoll'

  # expects: @props.name, @props.id

  render: =>

      <span>
          <Link to={'/profile/' + @props.id}>
              {@props.name}
          </Link>
          {@props.comma}&nbsp;
      </span>
