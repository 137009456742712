common = require('../common')
Link = require('react-router-dom').Link
NoteCommentRoll = require('./note_comment_roll_0div0_editor')
VerticalVoteComponent = require('../social/vertical_vote_component')

module.exports = class NoteReviewsOnly extends React.Component
  displayName: 'NoteReviewsOnly'

  constructor: (props) ->
      super(props)
      @state = {
        note : @props.note
        data : { logged_in_user: 'anonymous', logged_in_userid: '' }
        comments :  [ ]
        reviews : [ ]
        new_comment_editor: 'MathJax'
        show_editor_options : 'false'
        submit_button_style : 'btn btn-success btn-sm'
      }

  sortCommentsAndReviews: () =>
      reviews = [ ]
      comments = [ ]
      for comment in @state.note.comments
          if (comment.ctype == 'formal') or (comment.ctype == 'formal_anon')
              reviews.push(comment)
          else
              comments.push(comment)
      @setState({ reviews : reviews })
      @setState({ comments : comments })


  componentDidMount: () =>
      common.loginMonitor (data) =>
          current_user = data['current_user']
          whos_logged_in = 'anonymous'
          whos_logged_in = current_user if current_user
          logged_in_userinfo =
              logged_in_user : whos_logged_in
              logged_in_userid : data['userid']
          @setState( data : logged_in_userinfo )
          @sortCommentsAndReviews()


  render: =>
      if @state.data
        if @state.reviews.length > 0
          review_roll =
              <span>
                {@state.reviews.map((review) =>
                    <NoteCommentRoll key={review.id} comment={review} data={@state.data} reviewq={@props.reviewq}/>
                , this)}
              </span>
        else
          review_roll =
              <span><i>Reviewers unassigned</i></span>


      <span>
        <div className='row no-gutters'>
          <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
            <strong>{@props.note.comment_counts.formal} Reviews:</strong>
          </div>
        </div>
        { review_roll }
      </span>
