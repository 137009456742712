common = require('../common')
EmailVerification = require('../account/email_verification')

module.exports = class AuthorProfileNameRollEditable extends React.Component
  displayName: 'AuthorProfileNameRollEditable'

  constructor: (props) ->
      super(props)
      @state = {
        authorcontact : @props.authorcontact
        mode : 'display name'
      }

  gotoUserProfile: () =>
      window.location ='/profile/' + this.props.authorcontact.user_id

  hideAuthorContact: () =>
      data =
        authorcontactid : @props.authorcontact.id

      common.hideUserOwnedAuthorContact(data, (result) =>
         @setState({ authorcontact : '' })
         @setState({ mode : 'remove name' })
      )

  authorContactCard: () =>
      if @props.authorcontact.affiliation
          affil =
              <span>{@props.authorcontact.affiliation},&nbsp;</span>
      if @props.authorcontact.department
          dept =
              <span>{@props.authorcontact.department},&nbsp;</span>
      if @props.authorcontact.city
          city =
              <span>{@props.authorcontact.city},&nbsp;</span>
      if @props.authorcontact.jurisdiction1
          juris1 =
              <span>{@props.authorcontact.jurisdiction1},&nbsp;</span>
      if @props.authorcontact.jurisdiction2
          juris2 =
              <span>{@props.authorcontact.jurisdiction2},&nbsp;</span>
      if @props.authorcontact.country
          country =
              <span>{@props.authorcontact.country},&nbsp;</span>
      if @props.authorcontact.postalcode
          postcode =
              <span>{@props.authorcontact.postalcode}&nbsp;</span>

      if @props.authorcontact.credit_as
          credit_as_row =
              <h3 className="card-subtitle mb-2 text-muted">{@props.authorcontact.credit_as}</h3>

      if @props.user_can_edit is 'true'
          delete_button =
              <div className="dropdown float-right">
                  <button className="btn btn-light dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                  </button>
                  <div className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton">
                      <a className='dropdown-item dropdown-menu-right text-danger'
                          href=""
                          onClick={@hideAuthorContact}>
                          delete
                      </a>
                  </div>
              </div>

      return(
          <div className="card mb-2">
              <div className="card-body">
                  <h2 className="card-title">
                      { @props.authorcontact.name }
                      { delete_button }
                  </h2>
                  { credit_as_row }
                  <p className="card-text">
                      {affil}{dept}{city}{juris1}{juris2}{country}{postcode}
                  </p>
              </div>
          </div>
      )

  verifiedSubmitCreds: () =>
      updated_authorcontact = @state.authorcontact
      updated_authorcontact.verified = 'true'
      @setState({ authorcontact: updated_authorcontact })


  render: =>

      if @props.authorcontact.email
          if (@state.authorcontact.user_id==@props.current_userid) and (@state.authorcontact.hide_email==1)
              email_row =
                  <span className='text-success'>{@props.authorcontact.email}</span>
          else if (@state.authorcontact.hide_email==1)
              email_row =
                  <span></span>
          else
              email_row =
                  <span>{@props.authorcontact.email}</span>


      if @state.mode is 'remove name'
          <div className='authornameroll_div'>Removed!</div>
      else
            <div className="row">
              <div className='col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                  { @authorContactCard() }
              </div>
              <div className='col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                  <EmailVerification userid={ @state.authorcontact.user_id }
                      current_userid={ @props.current_userid }
                      email={ @state.authorcontact.email}
                      verified={ @state.authorcontact.verified }
                      authorcontactid={ @props.authorcontact.id }
                      type={ 'authorcontact' }
                      verifiedSubmitCreds={@verifiedSubmitCreds} />
              </div>
            </div>
