PDFCanvas = require('./pdf_canvas')
uuid = require('uuid/v4')

module.exports = class PDFViewer extends React.Component
    # props are passed directly to PDFCanvas.
    constructor: (props) ->
        super(props)
        @state = {
            pageNumber: 1
            pageNumberEntry: '1'
            totalPages: null
            scale: 1.0
            zoomEntry: '100'   # Zoom == scale * 100
        }
        @canvasRef = React.createRef()
        @manualPageEntryRef = React.createRef()
        @manualZoomEntryRef = React.createRef()
        @id = uuid()

    pdfDidLoad: (pdfDoc) =>
        @setState({totalPages: pdfDoc.numPages, pageNumberEntry: '1'})

    firstPage: =>
        @setState({pageNumber: 1, pageNumberEntry: '1'})
    
    prevPage: =>
        if @state.pageNumber > 1
            pageNumber = @state.pageNumber - 1
            @setState({pageNumber: pageNumber, pageNumberEntry: String(pageNumber)})
    
    nextPage: =>
        if @state.totalPages? and @state.pageNumber < @state.totalPages
            pageNumber = @state.pageNumber + 1
            @setState({pageNumber: pageNumber, pageNumberEntry: String(pageNumber)})
    
    lastPage: =>
        if @state.totalPages? and @state.pageNumber < @state.totalPages
            @setState({pageNumber: @state.totalPages, pageNumberEntry: String(@state.totalPages)})

    zoomIn: =>
        if @state.scale <= 16.0
            scale = Math.round(@state.scale * 10.0) * 0.1 + 0.1
            @setState({scale: scale, zoomEntry: String(Math.floor(scale * 100.0))})
    
    zoomOut: =>
        if @state.scale > 0.25
            scale = Math.floor(@state.scale * 10.0) * 0.1 - 0.1
            @setState({scale: scale, zoomEntry: String(Math.floor(scale * 100.0))})
    
    updatePageNumberEntryFromEvent: (event) =>
        @setState({pageNumberEntry: event.target.value.trim()})
    
    inspectPageNumberEntryKey: (event) =>
        # If return was pressed, change the page number; do not submit any
        # other forms that may be on the page.
        if event.keyCode == 13
            @setPageNumberFromEntry()
            event.preventDefault()
    
    setPageNumberFromEntry: (event) =>
        if not @state.pageNumberEntry.match(/[0-9]+/)
            @setState({pageNumberEntry: String(@state.pageNumber)})
            return
        
        pageNumber = parseInt(@state.pageNumberEntry)
        if pageNumber < 1 or pageNumber > @state.totalPages
            @setState({pageNumberEntry: String(@state.pageNumber)})
            return
        
        @setState({pageNumber: pageNumber, pageNumberEntry: String(pageNumber)})
    
    updateZoomEntryFromEvent: (event) =>
        @setState({zoomEntry: event.target.value.trim()})
    
    inspectZoomEntryKey: (event) =>
        # If return was pressed, change the scale; do not submit any other
        # forms that may be on the page.
        if event.keyCode == 13
            @setScaleFromZoomEntry()
            event.preventDefault()
    
    setScaleFromZoomEntry: (event) =>
        if not @state.zoomEntry.match(/[0-9]+(\.[0-9]*)?/)
            @setState({zoomEntry: String(Math.floor(@state.scale * 100.0))})
            return
        
        zoom = Math.floor(parseFloat(@state.zoomEntry))
        if zoom < 20.0 or zoom > 1600.0
            @setState({zoomEntry: String(Math.floor(@state.scale * 100.0))})
            return
        
        @setState({scale: zoom * 0.01, zoomEntry: String(zoom)})
    
    render: =>
        <div className='container pdf-viewer'>
            <form className='pdf-controls'>
                <div className='row pdf-controls'>
                    <div className='col'>
                        <button type='button' className='btn btn-secondary' aria-label='First page' onClick={@firstPage}>&nbsp;&nbsp;&nbsp;<i className='fa fa-fast-backward'/>&nbsp;&nbsp;&nbsp;</button>
                        &nbsp;&nbsp;
                        <button type='button' className='btn btn-secondary' aria-label='Previous page' onClick={@prevPage}>&nbsp;&nbsp;&nbsp;<i className='fa fa-backward'/>&nbsp;&nbsp;&nbsp;</button>
                        &nbsp;&nbsp;&nbsp;
                        <span className='detail'>Page&nbsp;<input
                            ref={@manualPageEntryRef} type='text' size='2'
                            style={maxWidth: '50px'}
                            value={@state.pageNumberEntry}
                            onBlur={@setPageNumberFromEntry}
                            onChange={@updatePageNumberEntryFromEvent}
                            onKeyDown={@inspectPageNumberEntryKey}
                            aria-label='Current page' />&nbsp;of&nbsp;{@state.totalPages ? "..."}</span>
                        &nbsp;&nbsp;&nbsp;
                        <button type='button' className='btn btn-secondary' aria-label='Next page' onClick={@nextPage}>&nbsp;&nbsp;&nbsp;<i className='fa fa-forward'/>&nbsp;&nbsp;&nbsp;</button>
                        &nbsp;&nbsp;
                        <button type='button' className='btn btn-secondary' aria-label='Last page' onClick={@lastPage}>&nbsp;&nbsp;&nbsp;<i className='fa fa-fast-forward'/>&nbsp;&nbsp;&nbsp;</button>
                    </div>
                    <div className='col'>
                        <span className='detail'><span id={'zoomlabel-' + @id}>Zoom</span>&nbsp;<input
                            ref={@manualZoomEntryRef} type='text' size='4'
                            style={maxWidth: '75px'} value={@state.zoomEntry}
                            aria-labelledby={'zoomlabel-' + @id} onBlur={@setScaleFromZoomEntry}
                            onChange={@updateZoomEntryFromEvent}
                            onKeyDown={@inspectZoomEntryKey}/>%</span>
                        &nbsp;&nbsp;&nbsp;
                        <button type='button' className='btn btn-secondary' aria-label='Zoom out' onClick={@zoomOut}>&nbsp;&nbsp;&nbsp;<i className='fa fa-search-minus'/>&nbsp;&nbsp;&nbsp;</button>
                        &nbsp;&nbsp;
                        <button type='button' className='btn btn-secondary' aria-label='Zoom in' onClick={@zoomIn}>&nbsp;&nbsp;&nbsp;<i className='fa fa-search-plus'/>&nbsp;&nbsp;&nbsp;</button>
                    </div>
                </div>
            </form>
            <div className='row pdf-document'>
                <PDFCanvas ref={@canvasRef} scale={@state.scale}
                  pageNumber={@state.pageNumber} pdfLoadedCallback={@pdfDidLoad} {@props...} />
            </div>
        </div>
