common = require('../common')

module.exports = class SimpleVote extends React.Component
  displayName: 'SimpleVote'

  constructor: (props) ->
      super(props)
      note = @props.note
      @state = {
        note: @props.note
        data: {noteid: note.id, votevalue: note.myvote}
      }

  recordVote: () =>
      common.submitProjectNoteVote(@state.data, (response) =>
      )

  voteUp: () =>
      oldvote = @state.note.myvote
      updated_note = @state.note
      updated_data = @state.data

      if this.state.note.myvote?
          if @state.note.myvote is -1
            updated_note.myvote = 0
            updated_note.votescore = @state.note.votescore - oldvote + 0
            updated_data.votevalue = @state.note.myvote
          else if this.state.note.myvote is 0
            updated_note.myvote = 1
            updated_note.votescore = @state.note.votescore - oldvote + 1
            updated_data.votevalue = @state.note.myvote
      else
          updated_data.votevalue = 1
          updated_note.myvote = 1
          updated_note.votescore = @state.note.votescore + 1
      @setState({ note : updated_note })
      @setState({ data : updated_data })
      @recordVote()

  voteDown: () =>
      oldvote = @state.note.myvote
      updated_note = @state.note
      updated_data = @state.data

      if this.state.note.myvote?
          if @state.note.myvote is 0
            updated_note.myvote = -1
            updated_note.votescore = @state.note.votescore - oldvote - 1
            updated_data.votevalue = -1
          else if @state.note.myvote is 1
            updated_note.myvote = 0
            updated_note.votescore = @state.note.votescore - oldvote + 0
            updated_data.votevalue = 0
      else
          updated_data.votevalue = -1
          updated_note.myvote = -1
          updated_note.votescore = @state.note.votescore - 1
      @setState({ note : updated_note })
      @setState({ data : updated_data })
      @recordVote()

  plusButton: () =>
      if @state.note.myvote is 1
          <button
              className="btn btn-success btn-txt-sm btn-box border"
              onClick={@voteUp}>+
          </button>
      else
          <button className="btn btn-light btn-txt-sm btn-box border"
              onClick={@voteUp}>+
          </button>

  minusButton: () =>

      if @state.note.myvote is -1
          <button
              className="btn btn-success btn-txt-sm btn-box border"
              onClick={@voteDown}>-
          </button>
      else
          <button
              className="btn btn-light btn-txt-sm btn-box border"
              onClick={@voteDown}>-
          </button>


  render: =>

      if @props.vote_style is 'vertical'
          <div className="btn-group-vertical"
               role="group"
               aria-label="voting-buttons">
              { @plusButton() }
              <button className="btn btn-light btn-txt-sm text-center">
                      {this.state.note.votescore}</button>
              { @minusButton() }
          </div>
      else
          <span>
              { @plusButton() }
              { @minusButton() }
              <span className='ml-1'>
                  { @state.note.votescore }
              </span>
          </span>
