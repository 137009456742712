common = require('../common')
AssignConfPapersToAreaChairs = require('./assign_conf_papers_to_areachairs')
AssignConfPapersToReviewers = require('./assign_conf_papers_to_reviewers')
AssignReviewersToAreaChairs = require('./assign_reviewers_to_areachairs')
ConferenceDetailsTools = require('./conference_details_tools')
ConferenceDirectUpload = require('../conference_direct_upload')
ConferenceHeader = require('../conference_header')
CreateNoteMetadata = require('../create_title/create_note_metadata')
ConferencePermsTools = require('./conference_perms_tools')
ConferenceSessionsTools = require('./conference_sessions_tools')
CreateReviewQs = require('./create_review_qs')
CreateSequelConfOrDelete = require('./create_sequel_conf_or_delete')
EmailInvites = require('./email_invites')
Link = require('react-router-dom').Link
NavGeneral = require('../nav_general')
HeaderBar = require('../header_bar')
HelmDatesButton = require('../schedule/helm_dates_button')
HelmRoleMakerButton = require('../roles/helm_rolemaker_button')
HelmSurveyButton = require('../survey/helm_survey_button')
HelmSurveyTools = require('../survey/helm_survey_tools')
Loading = require('../loading')
RolesBarConf = require('../roles/roles_bar_conf')
Roster = require('./roster')
SetConfDate = require('../schedule/set_conf_date')
ToolchestAreaChairButton = require('./toolchest_areachair_button')
ToolchestChairButton = require('./toolchest_chair_button')
ToolchestConfDetailsButton = require('./toolchest_conf_details_button')
ToolchestCreateOrDeleteButton = require('./toolchest_create_or_delete_button')
ToolchestPermsButton = require('./toolchest_perms_button')
ToolchestReviewerButton = require('./toolchest_reviewer_button')
ToolchestSessionsButton = require('./toolchest_sessions_button')

module.exports = class ToolChest extends React.Component
  displayName: 'ToolChest'

  constructor: (props) ->
      super(props)
      projectid = @props.match.params.projectId
      @state = {
          notes_length: 0
          notes: []
          projectid : projectid
          project : { }
          userid : ''
          follow_btn_txt : 'follow?'
          userid : ''
          roles : [ ]
          roleswords : [ ]
          rolestring : ''
          my_accepted_roles : [ ]
          wordlist: [ ]
          mode : 'loading'
          data: { logged_in_user: 'anonymous' }
          login_id: ''

          registration_deadline: ''
          submission_deadline: ''
          review_deadline: ''
          start_date: ''
          end_date: ''

          show_this: 'intro'
          instructions: ''
          roles_error: ''
          tool_selected: ''

          header_mode: ''

          new_note: {}
      }
      @makeNewTitleRef = React.createRef()
      @activeAreaRef = React.createRef()

  componentDidMount: =>
      data =
          projectid: @state.projectid

      common.getConferenceAndRolesForUser(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid_conference' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })

          else

              my_accepted_roles = [ ]
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              if not (result['project']['surveyable_id'])
                  result['project']['surveyable_id'] = ''

              loaded_wordlist = [ ]
              if result.project.perms
                for perm in result.project.perms
                    loaded_wordlist.push(perm.word)

              @setState({
                  projectid: result.project.id,
                  project: result['project'],
                  userid: result['userid'],
                  roles: result['roles'],
                  my_accepted_roles : my_accepted_roles,
                  wordlist: loaded_wordlist,
                  submission_deadline: result['project']['submission_deadline'],
                  start_date: result['project']['start_date'],
                  end_date: result['project']['end_date'],
                  review_deadline: result['project']['review_deadline'],
                  registration_deadline: result['project']['registration_deadline'] })

              if result['project']
                  document.title = result['project']['title']
              else
                  document.title = 'ZeroDivZero'

              if result.userid
                  @setState({ mode: 'helm' }, @scrollWindow.bind(this, @activeAreaRef))
              else
                  window.location = '/login/?' + window.location.href
      )

      common.getTotalNumberNotesInProject(data, (result) =>
        @setState({ notes_length: result.total})
      )

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()

  scrollWindow: (ref_location) =>
      elem = @refs[ref_location]
      if (elem)
          elem.scrollIntoView()

  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  setUpdatedTags: (updated_tags) =>
      updated_project = @state.project
      updated_project.tags = updated_tags
      @setState({ project: updated_project })
      return

  setUpdatedPerms: (updated_perms) =>
      updated_wordlist = [ ]
      for perm in updated_perms
          updated_wordlist.push(perm.word)

      updated_project = @state.project
      updated_project.perms = updated_perms

      @setState({
          wordlist: updated_wordlist
          project:  updated_project })
      return

  toggleRolesError: =>
      if @state.roles_error is ''
          @setState({
              show_this: 'false'
              roles_error: 'You don\'t have the correct role(s) to use this tool.'
              instructions: ''}, @scrollWindow.bind(this, 'toolpanel'))

  toggleToolPanel: (option) =>
      if option.tools is 'roles_error'
          @toggleRolesError()
          return
      if @state.show_this is option.show_this
        # toggle panel closed
        @setState({ show_this: 'intro', tool_selected: '' }, @scrollWindow.bind(this, 'toolpanel'))
      else
        @setState({
            show_this: option.show_this
            instructions: ''
            roles_error: ''
            tool_selected: option.tools }, @scrollWindow.bind(this, 'toolpanel'))

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  getUserNoteDetails: =>
      @setState({
          mode : 'create_note_metadata'}, @scrollToHere(@makeNewTitleRef) )

  setMode: (info) =>
      # in this case, new_note might be returned!
      if info is 'cancelled'
          @setState({ mode: 'viewing' })
      else if info.new_note
          @setState({
              new_note: info.new_note
              mode: 'direct_upload_submisson' })
      else
          @setState({ mode: info })

  surveyButton: () =>
      if ('registered' in @state.my_accepted_roles) or ('contributor' in @state.my_accepted_roles) or ('invited' in @state.my_accepted_roles) or ('following' in @state.my_accepted_roles)
          <span>
              <HelmSurveyButton
                   my_accepted_roles={ @state.my_accepted_roles }
                   tool_selected={ @state.tool_selected }
                   onClick={@toggleToolPanel} />
          </span>

  surveyTools: () =>
      if (@state.show_this is 'survey_take_only') or (@state.show_this is 'survey_make_or_take')
          <span>
              <HelmSurveyTools
                  show_this= { @state.show_this }
                  project= { @state.project }
                  userid= { @state.userid }
                  onClick= { @toggleToolPanel } />
          </span>

  reviewerButton: () =>
      if ('reviewer' in @state.my_accepted_roles)
          <span>
              <ToolchestReviewerButton
                  my_accepted_roles={ @state.my_accepted_roles }
                  tool_selected={ @state.tool_selected }
                  onClick={@toggleToolPanel} />
          </span>
  reviewerTools: () =>
      if @state.show_this is 'reviewer_tools'
          <span>
              <strong>My Reviewer</strong>
              <br />
              <Link to={'/conference/' + @props.match.params.projectId + '/titles/reviewer_panel'}>
                  <button className="btn btn-primary">
                      Read your assignments and write your reviews
                  </button>
              </Link>
          </span>

  areaChairButton: () =>
      if ('areachair' in @state.my_accepted_roles)
          <span>
              <ToolchestAreaChairButton
                  my_accepted_roles={ @state.my_accepted_roles }
                  tool_selected={ @state.tool_selected }
                  onClick={@toggleToolPanel} />
          </span>
  areaChairTools: () =>
      if @state.show_this is 'areachair_tools'
          <span>
              <strong>My AreaChair</strong>
              <br />
              <Link to={'/conference/' + @props.match.params.projectId + '/titles/areachair_panel'}>
                  <button className="btn btn-primary">
                      Read your area chair assignments and reviews
                  </button>
              </Link>
          </span>

  chairButton: () =>
      if ('chair' in @state.my_accepted_roles)
          <span>
              <ToolchestChairButton
                  my_accepted_roles={ @state.my_accepted_roles }
                  tool_selected={ @state.tool_selected }
                  onClick={@toggleToolPanel} />
          </span>

  uploadUserPaperList: () =>


  chairTools: () =>
      if @state.show_this is 'chair_tools'
          <span>
              <strong>Chair Functions</strong>
              <ul>
                <li><CreateReviewQs project={@state.project} /></li>
                <li><AssignReviewersToAreaChairs project={@state.project} notes={@state.notes} /></li>
                <li><AssignConfPapersToAreaChairs project={@state.project} notes={@state.notes} /></li>
                <li><AssignConfPapersToReviewers project={@state.project} notes={@state.notes} /></li>
                <li>
                    <Link to={'/conference/' + @props.match.params.projectId + '/titles/chair_panel'}>
                        <button className="btn btn-primary">
                            Read reviews, accept/reject, tweak assignments
                        </button>
                    </Link>
                </li>
                <li>
                    <Link to={'/conference/' + @props.match.params.projectId + '/tools/integrations'}>
                        <button className='btn btn-primary'>
                            Compare against external lists
                        </button>
                    </Link>
                </li>
              </ul>
          </span>

  setUpdatedDetails: (deets) =>
      updated_project = @state.project
      updated_project.title = deets.project_title
      updated_project.motive = deets.project_motive
      updated_project.editor = deets.editor
      @setState({ project: updated_project })

  detailsButton: () =>
      if ('chair' in @state.my_accepted_roles) or ('creator' in @state.my_accepted_roles)
          <span>
              <ToolchestConfDetailsButton
                  my_accepted_roles={ @state.my_accepted_roles }
                  tool_selected={ @state.tool_selected }
                  onClick={@toggleToolPanel} />
          </span>
  setDetails: () =>
      if (@state.show_this is 'details_tools')
          <span>
              <ConferenceDetailsTools
                  show_this={ @state.show_this }
                  project={ @state.project }
                  my_accepted_roles={ @state.my_accepted_roles }
                  setUpdatedDetails={ @setUpdatedDetails }
                  onClick={@toggleToolPanel} />
          </span>

  datesButton: () =>
      if ('chair' in @state.my_accepted_roles) or ('creator' in @state.my_accepted_roles)
          <span>
              <HelmDatesButton
                  my_accepted_roles={ @state.my_accepted_roles }
                  tool_selected={ @state.tool_selected }
                  onClick={@toggleToolPanel} />
          </span>
  showDates: () =>
      if @state.show_this is 'show_dates'
          <span>
              <strong>Important Dates</strong>
              <ul>
                <li>
                  <SetConfDate type={'submission_deadline'}
                               datetime={@state.submission_deadline}
                               confid={@state.project.id} />
                </li>
                <li>
                  <SetConfDate type={'review_deadline'}
                               datetime={@state.review_deadline}
                               confid={@state.project.id} />
                </li>
                <li>
                  <SetConfDate type={'registration_deadline'}
                               datetime={@state.registration_deadline}
                               confid={@state.project.id} />
                </li>
                <li>
                  <SetConfDate type={'start_date'}
                               datetime={@state.start_date}
                               confid={@state.project.id} />
                </li>
                <li>
                  <SetConfDate type={'end_date'}
                               datetime={@state.end_date}
                               confid={@state.project.id} />
                </li>
              </ul>
          </span>

  roleMakerButton: () =>
      if ('chair' in @state.my_accepted_roles) or ('creator' in @state.my_accepted_roles)
          <span>
              <HelmRoleMakerButton
                  my_accepted_roles={ @state.my_accepted_roles }
                  tool_selected={ @state.tool_selected }
                  onClick={@toggleToolPanel} />
          </span>
  setRoles: () =>
      if @state.show_this is 'rolemaker'
          <span>
              <strong>Invite people!</strong>
              <p className="instructions">
                ZeroDivZero uses email addresses to find users on the website and send
                them notifications about your conference. We <b>do not</b> send out actual emails.
                Please send out emails to participants yourself! Thanks.
              </p>
              <ul>
                <li>
                    <EmailInvites key={'invited'}
                        project={@state.project}
                        roletype={'invited'}
                        upload_content={'email_invites'} />
                </li>
                <li>
                    <EmailInvites key={'chair'}
                        project={@state.project}
                        roletype={'chair'}
                        upload_content={'email_invites'} />
                </li>
                <li>
                    <EmailInvites key={'keynote'}
                        project={@state.project}
                        roletype={'keynote'}
                        upload_content={'email_invites'} />
                </li>
                <li>
                    <EmailInvites key={'reviewer'}
                        project={@state.project}
                        roletype={'reviewer'}
                        upload_content={'email_invites'} />
                </li>
                <li>
                    <EmailInvites key={'areachair'}
                        project={@state.project}
                        roletype={'areachair'}
                        upload_content={'email_invites'} />
                </li>
                <li>
                    <EmailInvites key={'chair'}
                        project={@state.project}
                        roletype={'chair'}
                        upload_content={'email_invites'} />
                </li>
                <li><Roster project={@state.project} /></li>
              </ul>
          </span>

  sessionsButton: () =>
      if ('chair' in @state.my_accepted_roles) or ('creator' in @state.my_accepted_roles)
          <span>
              <ToolchestSessionsButton
                  my_accepted_roles={ @state.my_accepted_roles }
                  tool_selected={ @state.tool_selected }
                  onClick={@toggleToolPanel} />
          </span>
  editSessions: () =>
      if (@state.show_this is 'sessions_tools')
          <span>
              <ConferenceSessionsTools
                  show_this={ @state.show_this }
                  project={ @state.project }
                  tags={ @state.project.tags }
                  userid={ @state.userid }
                  setUpdatedTags={ @setUpdatedTags }
                  onClick={@toggleToolPanel} />
          </span>

  permsButton: () =>
      if ('chair' in @state.my_accepted_roles) or ('creator' in @state.my_accepted_roles)
          <span>
              <ToolchestPermsButton
                  my_accepted_roles={ @state.my_accepted_roles }
                  tool_selected={ @state.tool_selected }
                  onClick={@toggleToolPanel} />
          </span>
  permsTools: () =>
      if (@state.show_this is 'perms_tools')
          <span>
              <ConferencePermsTools
                  show_this={ @state.show_this }
                  projectid={ @state.project.id }
                  wordlist={ @state.wordlist }
                  project={ @state.project }
                  userid={ @state.userid }
                  setUpdatedPerms={ @setUpdatedPerms }
                  onClick={@toggleToolPanel} />
          </span>

  createOrDeleteButton: () =>
      if ('chair' in @state.my_accepted_roles) or ('creator' in @state.my_accepted_roles)
          <span>
              <ToolchestCreateOrDeleteButton
                  my_accepted_roles={ @state.my_accepted_roles }
                  tool_selected={ @state.tool_selected }
                  onClick={@toggleToolPanel} />
          </span>
  createOrDelete: () =>
      if (@state.show_this is 'create_delete_tools')
          <span>
              <CreateSequelConfOrDelete
                  show_this={ @state.show_this }
                  project={ @state.project }
                  userid={ @state.userid }
                  onClick={@toggleToolPanel} />
          </span>

  introText: () =>
      if @state.show_this is 'intro'
          <div className="text-center">
              <strong>
              This is where all the conference tools are located!
              </strong>
              <br />
              You can see the tools appropriate for your roles.
          </div>


  render: =>

    if @state.mode is 'invalid_conference'
        problem_msg =
            <div>
                <br />
                <h2>We cannot find that conference... Try refreshing!</h2>
                If you continue to get this error, then maybe the conference number is wrong?
            </div>
    else if @state.mode is 'conference_deleted'
        problem_msg =
            <div>
                <br />
                <h2>Uh oh... It appears the owner has deleted this conference.</h2>
            </div>

    if (@state.mode != 'invalid_conference') and (@state.mode != 'conference_deleted')
        conference_header =
            <span>
                <ConferenceHeader project={@state.project}
                    userid={@state.userid}
                    roles={@state.roles}
                    wordlist={@state.wordlist} />
            </span>
        roles_bar =
            <div className='row border'>
                <span className="ml-0 pl-2">
                  My roles: <RolesBarConf project={@state.project}
                      my_accepted_roles={@state.my_accepted_roles}
                      roles={@state.roles}
                      userid={@state.userid}
                      view={'mini'}
                      setUpdatedRoles={@setUpdatedRoles}
                      getUserNoteDetails={@getUserNoteDetails} />
                </span>
            </div>

    conf_nav_vert =
        <div className='col-md-2 col-lg-2 border d-none d-md-block'>
            <NavGeneral key={@state.project.id}
                id={@state.project.id}
                ptype={@state.project.ptype}
                caller={'tools'}
                subset={@state.subset}
                getThisSortType={@getThisSortType}
                userid={@state.userid}
                styling={'col-12'} />
        </div>
    conf_nav_horiz =
        <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
            <NavGeneral key={@state.project.id}
                id={@state.project.id}
                ptype={@state.project.ptype}
                caller={'tools'}
                subset={@state.subset}
                getThisSortType={@getThisSortType}
                userid={@state.userid}
                styling={'mr-2'} />
        </div>


    if @state.mode is 'loading'
        <div>
            <HeaderBar />
            <div className='container-fluid pr-0 pl-0 border'>
                { conference_header }
                <div className='row no-gutters'>
                    {conf_nav_vert}
                    {conf_nav_horiz}
                    <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'
                        ref={@activeAreaRef}>
                        <br />
                        <Loading />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    else if @state.mode is 'create_note_metadata'
        upload_paper =
            <div ref={@makeNewTitleRef}>
                <CreateNoteMetadata
                    project={@state.project}
                    setMode={@setMode}
                    version={'new'}/>
            </div>
    else if @state.mode is 'direct_upload_submisson'
        upload_paper =
            <div>
                You have created a personal project and added a title.
                Just a few more choices to finish submission.
                <ConferenceDirectUpload
                    note={@state.new_note}
                    conference={@state.project} />
            </div>
    else if (@state.mode is 'helm') and (@state.roles)
          <div>
              <HeaderBar />
              <div className='container-fluid pr-0 pl-0'>
                  { conference_header }
                  <div className='row'>
                      {conf_nav_vert}
                      {conf_nav_horiz}
                      <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                          <div ref={@activeAreaRef} className='mb-3'>
                               { roles_bar }
                          </div>
                          <div className='row'>
                              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-3'>
                                  { @surveyButton() }
                              </div>
                              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                                  { @introText() }
                                  { @surveyTools() }
                              </div>
                          </div>
                          <div className='row'>
                              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-3'>
                                  { @reviewerButton() }
                              </div>
                              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                                  { @reviewerTools() }
                              </div>
                          </div>
                          <div className='row'>
                              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-3'>
                                  { @areaChairButton() }
                              </div>
                              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                                  { @areaChairTools() }
                              </div>
                          </div>
                          <div className='row'>
                              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-3'>
                                  { @chairButton() }
                              </div>
                              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                                  { @chairTools() }
                              </div>
                          </div>
                          <div className='row'>
                              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-3'>
                                  { @detailsButton() }
                              </div>
                              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                                  { @setDetails() }
                              </div>
                          </div>
                          <div className='row'>
                              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-3'>
                                  { @datesButton() }
                              </div>
                              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                                  { @showDates() }
                              </div>
                          </div>
                          <div className='row'>
                              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-3'>
                                  { @roleMakerButton() }
                              </div>
                              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                                  { @setRoles() }
                              </div>
                          </div>
                          <div className='row'>
                              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-3'>
                                  { @sessionsButton() }
                              </div>
                              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                                  { @editSessions() }
                              </div>
                          </div>
                          <div className='row'>
                              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-3'>
                                  { @permsButton() }
                              </div>
                              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                                  { @permsTools() }
                              </div>
                          </div>
                          <div className='row'>
                              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-3'>
                                  { @createOrDeleteButton() }
                              </div>
                              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                                  { @createOrDelete() }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    else
        <div>
            <HeaderBar />
            { problem_msg }
        </div>
