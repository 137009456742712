Link = require('react-router-dom').Link
SocialSharing = require('../social/social_sharing')

module.exports = class Policies extends React.Component
  displayName: 'Policies'

  constructor: (props) ->
      super(props)
      @state = {

      }


  render: =>

    shareUrl = 'https://zerodivzero.com'

    <div className='container-fluid'>
      <div className='d-flex justify-content-center'>
            <Link to="/privacy_policy">Privacy</Link>&nbsp;|&nbsp;
            <Link to="/cookie_policy">Cookies</Link>&nbsp;|&nbsp;
            <Link to="/terms_of_use">Terms</Link>&nbsp;|&nbsp;
            <Link to="/community_guidelines">Community Guidelines</Link>&nbsp;|&nbsp;
            <Link to="/our_story">About</Link>&nbsp;|&nbsp;
            Please share:
            <span className='text-primary'>
                <SocialSharing shareUrl={shareUrl}title={ 'ZeroDivZero' }/>
            </span>
      </div>
    </div>
