common = require('../../common')
TagNoteRoll = require('../../tags/tag_note_roll')
TagProjectRollEditable = require('../../tags/tag_project_roll_editable')

module.exports = class LabGroupTopicsTools extends React.Component
  displayName: 'LabGroupTopicsTools'

  constructor: (props) ->
      super(props)
      @state = {
          mode: 'reading'
      }

  toggleReadEditMode: () =>
      if @state.mode is 'editing'
        @updateProjectFields()
      else
        @setState({ mode : 'editing' })

  updateProjectFields: () =>
      tags = @refs.note_tags.value

      data =
          projectid: @props.project.id
          tags : tags

      common.submitProjectFields(data, (result) =>
          @props.setUpdatedTags(result.tags)
          @setState({ mode: 'reading' })
      )

  editTheTags: () =>
      if @state.mode is 'editing'
          if @props.tags
              return (
                  <div className='mb-3'>
                      <strong>Sessions: (please separate with commas) </strong>
                      {@props.tags.map((tag) =>
                          <TagProjectRollEditable
                              key={tag.id}
                              tag={tag} p
                              rojectid={@props.project.id} />
                      , this)}
                      <textarea id="note_tags"
                                ref="note_tags"
                                cols="40" rows="1"></textarea>
                  </div>
              )
        else
            return (
                <div className='mb-3'>
                    <strong>Sessions: (please separate with commas) </strong>
                    <textarea id="note_tags"
                              ref="note_tags"
                              cols="40" rows="1"></textarea>
                </div>
            )

  render: =>

      if (@props.tags) and (@props.tags.length>0)
          Ltags = @props.tags.length
          tags_list =
              <div className='mb-3'>
                  <strong>Current sessions: </strong>
                  {@props.tags.map((tag, ctr) =>
                      if ctr < Ltags
                          comma=<span>&nbsp;|</span>
                      <TagNoteRoll key={tag.id}
                                   tag={tag}
                                   projectid={@props.project.id}
                                   note_type={'conference'}
                                   tag_type={'conference'}
                                   comma={comma} />
                  )}
              </div>
      else
          tags_list =
              <div className='mb-3'>
                  <h3>Sessions: </h3> none
                </div>

      if (@state.mode is 'editing')
          toggle_word = 'save'
      else
          toggle_word = 'edit'

      <div className='m-3'>
          <strong className='mb-3'>
              Set up or edit your {@props.ptype} group topics
          </strong>
          <br />
          { tags_list }
          { @editTheTags() }
          <button className="btn btn-secondary btn-txt-sm"
                  onClick={@toggleReadEditMode}>
            { toggle_word }
          </button>
      </div>
