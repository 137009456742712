common = require('../common')
Loading = require('../loading')
PDFViewer = require('./pdf_viewer')
request = require('superagent')

module.exports = class DocumentIFrame extends React.Component
    displayName: "DocumentIFrame"

    @PDF_TYPES = ['application/pdf', 'text/pdf']
    @PRESIGNED_URL_TYPE = 'application/x-presigned-s3-url'

    constructor: (props) ->
        super(props)
        @state = {
            # If set, game over. Display this error message to the user and
            # require a reload. (Maybe fix this in the future.)
            fatal: null

            # The current URL we're downloading. This usually changes due to a
            # redirect into S3.
            documentURL: common.host + @props.src

            # The body of the document.
            documentBody: null

            # The content type of the document.
            contentType: null
        }

    render: =>
        if @state.fatal?
            <div className='error'>{@state.fatal}</div>
        else if @state.contentType in DocumentIFrame.PDF_TYPES
            <PDFViewer data={@state.documentBody}/>
        else if @state.contentType? and @state.contentType != DocumentIFrame.PRESIGNED_URL_TYPE
            <div className="error">Unable to handle content type {@state.contentType}</div>
        else
            <Loading />

    componentDidMount: =>
        @downloadDocument()

    downloadDocument: =>
        if @state.documentURL?
            req = request
                .get(@state.documentURL)
                .responseType('blob')
                .retry(5)

            if @state.contentType != DocumentIFrame.PRESIGNED_URL_TYPE
                req = req.withCredentials()
            else
                req = req.set('x-force-preflight', 'true')

            req
                .then(@documentWasDownloaded)
                .catch((err) =>
                    @setState({
                        fatal: 'Failed to retrieve document: Error getting document headers: ' + err
                    }))
        else
            @setState({
                fatal: 'A document was not provided'
            })

    documentWasDownloaded: (res) =>
        contentType = res.type
        if contentType == DocumentIFrame.PRESIGNED_URL_TYPE
            # Update the URL to point to the new location and redo the download
            url = res.header['s3-redirect-location']
            @setState({documentURL: url}, @downloadDocument)
        else
            # Download the body and render
            # Only Chrome supports res.body.asArrayBuffer(); use FileReader
            # interface instead.
            reader = new FileReader()
            reader.addEventListener('loadend', () =>
                @setState({documentBody: reader.result, contentType: contentType}))
            reader.readAsArrayBuffer(res.body)
