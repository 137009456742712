Link = require('react-router-dom').Link
common = require('../common')

module.exports = class ConferenceInvitedUsersRoll extends React.Component
  displayName: 'ConferenceInvitedUsersRoll'

  render: =>

      if @props.role.role_accepted is 'true'
          checkmark =
              <span>
                &#10003;
              </span>
      else
          checkmark = <span></span>


      if @props.role.user_id?
          <div className='authornameroll_div'>
              <Link to={ '/profile/' + @props.role.user_id }>
                  <button className="btn btn-primary btn-txt-sm">
                        {@props.role.name}{checkmark}</button>
              </Link>
          </div>
      else
          <div className='authornameroll_div'>
              <Link to={ '/profile/' + @props.role.user_id }>
                  <button className="btn btn-secondary btn-txt-sm">
                        {@props.role.identifier}{checkmark}</button>
              </Link>
          </div>
