

module.exports = class ReviewQRoll extends React.Component
  displayName: 'ReviewQRoll'

  render: =>

      <li>
        { @props.question }
      </li>
