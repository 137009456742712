common = require('../common')
DocumentFrame = require('./document_frame')


module.exports = class SlidesConferenceButton extends React.Component
    displayName: 'SlidesConferenceButton'

    constructor: (props) ->
        super(props)
        note = @props.note
        @state = {
            note: note
            mode: 'reading'
            blawbcontent: '',
            blawbid: ''
            slideset: ''
        }

    viewUserSlideSet: () =>
        @setState({mode: 'viewing'})

    viewSlideSetinNewWindow: () =>
      window.open('/conference/' + @state.note.project_id + '/title/' +
                  @state.note.paperseries_id + '/slides')

    gotoHideSlideSet: () =>
        @setState({ mode : 'reading' })

    componentDidMount: =>
        data =
            projectid: @props.note.project_id
            paperseriesid: @props.note.paperseries_id

        common.getSlideSetFromNoteInfo(data, (result) =>
            if result.slideset?
                @setState({ slideset: result.slideset })
                if (@props.hasSubmitted) and (result.slideset.body_blawb_id)
                    @props.hasSubmitted({submit_type: 'slideset', status:'true'})
                else if (@props.hasSubmitted)
                    @props.hasSubmitted({submit_type:'slideset', status:'false'})
        )

    render: =>
        if @state.mode is 'reading'
            if @state.slideset.body_blawb_id or @state.blawbid
                if @state.slideset.body_blawb_id is 'empty_submission'
                    slide_button =
                        <button className="btn btn-link btn-items mr-2">
                            Slides (not submitted yet)
                        </button>
                else
                    slide_button =
                        <button className="btn btn-link btn-items mr-2"
                            onClick={@viewSlideSetinNewWindow}>
                            Slides
                        </button>

        else if @state.mode is 'viewing'
            filename = "/view_paper_slideset/" + @state.note.id
            slide_button =
                <div>
                    <button className="btn btn-link btn-sm" onClick={@gotoHideSlideSet}>hide slides</button>
                    <DocumentFrame className="mini_view_document_iframe" src={filename} />
                    <button className="btn btn-link btn-sm" onClick={@gotoHideSlideSet}>hide slides</button>
                </div>

        if (@state.slideset.body_blawb_id) or (@state.blawbid)
            <span className='mr-2'>
                {slide_button}
            </span>
        else
            <span></span>
