Link = require('react-router-dom').Link
HeaderBar = require('../header_bar')
Policies = require('../site_info/policies')


module.exports = class HelloWorldMore extends React.Component
  displayName: 'HelloWorldMore'

  constructor: (props) ->
      super(props)
      @state = { }

  componentDidMount: =>
      document.title = 'ZeroDivZero more...'

  render: =>

      <span>
        <HeaderBar />
        <div className='container-fluid'>
          <div className="jumbotron">
              <div className='row'>
                  <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                      <h1 className='display-4 text-center'>
                          <img className='ml_40'
                           src='../../images/clip_art/just_planet_no_words_172_92px.png'
                           alt='logo'></img>
                      </h1>
                  </div>
                  <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  </div>
                  <div className='col-xs-8 col-sm-8 col-md-8 col-lg-8'>
                      <h1 className='display-4 align-middle'>
                           What we do
                      </h1>
                      <p className="lead">
                          Share the journey to your results.
                      </p>
                      <p>
                          From notes, to talk slides, to saving your conference
                          submissions, to preprints, and beyond.
                      </p>
                  </div>
              </div>
              <br />
              <hr className="my-4"></hr>
              <div className='row mb-3'>
                  <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 text-dark'>
                      <p className=''>
                          Neet to host something?
                          Current structures: Conferences, Seminars, Short
                          Courses, Lab Groups, Projects
                      </p>
                      <p className=''>
                          Want to save items along the way?
                          Current upload types: papers, slides, posters,
                          supporting documents (in PDF format)
                      </p>
                      <p className=''>
                          Social tools: reviews, comments
                      </p>
                      <p className=''>
                          And we would love to know if you have replicated
                          something!
                      </p>
                      <p className=''>
                          Are we missing anything? Let us know!
                      </p>
                  </div>
              </div>
              <hr className="mt-3"></hr>
              <br />
              <div className='row mb-3'>
                  <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2 ml-0 pl-0'>
                  </div>
                  <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  </div>
                  <div className='col-xs-8 col-sm-8 col-md-8 col-lg-8'>
                      <strong>We need contributers! Would you like to help? help@0div0.com</strong>
                  </div>
              </div>
              <br />
              <div className='row mt-3'>
                  <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2 ml-0 pl-0 mt-3'>
                      <img className='ml_40'
                          src='../../images/headshots/lyndie_100x100_bw.png'
                          alt='lyndie'></img>
                  </div>
                  <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  </div>
                  <div className='col-xs-8 col-sm-8 col-md-8 col-lg-8'>
                      <strong>Lyndie Chiou:</strong> Applied Physics and Physics.
                      Lyndie has worked in a number of science/tech jobs before building
                      ZeroDivZero.
                      She has also been honing her writing skills to translate
                      research into popular articles.
                  </div>
              </div>
              <br />
              <div className='row mt-3'>
                  <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2 ml-0 pl-0'>
                      <img className='ml_40'
                           src='../../images/headshots/dacut_100x100_bw.png'
                           alt='david_cuthbert'></img>
                  </div>
                  <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  </div>
                  <div className='col-xs-8 col-sm-8 col-md-8 col-lg-8'>
                      <strong>David Cuthbert</strong>: Electrical Engineering,
                      but mostly software these days.
                      Dave worked for many years as a solutions architect at AWS
                      before starting his own company, Ionosphere LLC, which
                      builds cool new things for the earth, space, and energy science communities.
                      Dave built the infrastructure for ZeroDivZero and
                      helps with web development as well.
                  </div>
              </div>
          </div>
        <br />
        <Policies />
        </div>
      </span>
