common = require('../common')
Dropzone = require('react-dropzone').default

module.exports = class UploadCSVButton extends React.Component
  displayName: 'UploadCSVListButton'

  constructor: (props) ->
      super(props)
      @state = {
        mode: 'button_view'
        files: []
        matching_notes: []
        missed_notes: []
      }

  goToListUpload: () =>
      @setState({ mode : "show_dropzone"})

  uploadUserCSV: () =>
      # place keeper
      @setState({mode: 'uploading'})

  onDrop: (upload_files) =>
      @setState({ files : upload_files })
      @.UploadFiles(upload_files)

  UploadFiles: (files)=>

      if files.length is 0
          alert('select a .csv type file')
          return

      projectid = @props.projectid
      roletype = @props.roletype

      if @props.upload_content is 'email_invites'
          common.sendAndSaveConferenceEmailFileInvites(files, projectid, roletype, (result) =>
              @setState({ mode: 'sent!' })
          )
      else if @props.upload_content is 'titles_csv'
          @setState({ mode: 'getting_results' })
          common.uploadExternalTitlesCSVForComparison(files, projectid, roletype, (result) =>
              parsed = JSON.parse(result)
              @props.theResults(parsed)
              @setState({ mode: 'button_view' })
          )

  cancelDropzone: () =>
      @setState({ mode : 'button_view' })

  buttonText: () =>
      if @props.upload_content is 'email_invites'
          return(
              <span>Upload a list of emails</span>
          )
      else if @props.upload_content is 'titles_csv'
          return(
              <span>Upload titles (.csv)</span>
          )

  render: =>
      if @state.mode is 'button_view'
          <button className="btn btn-primary"
                  onClick={@goToListUpload}>
                  Upload (.csv)</button>
      else if @state.mode is 'show_dropzone'
          <div>
              <Dropzone onDrop={@onDrop} multiple={false} accept=".csv">
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps({className: 'dropzone dz-clickable', size: '1000'})}>
                            <input {...getInputProps()} />
                            <div className='dz-message'>
                                Drag and drop CSV list here, or click to select a file
                            </div>
                        </div>
                    )}
              </Dropzone>
              <button className='btn btn-secondary'
                      onClick={@cancelDropzone}>
                      cancel</button>
          </div>
      else if @state.mode is 'sent!'
          <span>
              Sent! Need to upload another email list?
              <button className="btn btn-primary"
                      onClick={@goToListUpload}>
                      { @buttonText() }</button>
          </span>
      else if @state.mode is 'getting_results'
          <span>
              Hang on, researching this...
          </span>
