common = require('../common')

module.exports = class UpdateUser extends React.Component
  displayName: 'UpdateUser'

  constructor: (props) ->
      super(props)
      # @props.social_user_id
      # @props.mode
      @state = {
        name: @props.name
        username: ''
        password: ''
        msg: ''
        new_acct_error_msg: ''}

      @newUsernameRef = React.createRef()
      @newNameRef = React.createRef()
      @newPasswordRef = React.createRef()
      @newPasswordEyeRef = React.createRef()

  showNewPassword: () =>

      if @newPasswordRef.current.type is 'password'
          @newPasswordRef.current.type = 'text'
          @newPasswordEyeRef.current.className = 'fa fa-eye ml-3 mr-3'
      else
          @newPasswordRef.current.type = 'password'
          @newPasswordEyeRef.current.className = 'fa fa-eye-slash ml-3 mr-3'

  makePassword: () =>
      pwd = ''
      shuffle = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789.!'
      place = 0
      while (place < 8)
        pwd = pwd.concat(shuffle.charAt(Math.floor(Math.random() * shuffle.length)))
        place++
      @setState({ password: pwd })

  componentDidMount: =>
      @makePassword()

  submitNewCredentials: =>

    new_username = @newUsernameRef.current.value.toLowerCase()
    update = @newNameRef.current.value
    if update.length > 0
        new_name = update
    else
        # This could be left to be the default placeholder, in which case the
        # input is blank.
        new_name = @state.name
    update = @newPasswordRef.current.value
    if update.length > 0
        new_password = update
    else
        # This could be left to be the default placeholder, in which case the
        # input is blank.
        new_password = @state.password


    # ascii_foreign_letters = \x80-\x9B\x9D-\xA5\xB5-\xB7\xE0-\xED
    # reference: https://theasciicode.com.ar/extended-ascii-code/uppercase-slashed-zero-empty-set-ascii-code-157.html

    regex_name = /^[0-9A-Za-z\s\-.,\x80-\x9B\x9D-\xA5\xB5-\xB7\xE0-\xED]+$/
    regex_username = /^[0-9A-Za-z\-_.\x80-\x9B\x9D-\xA5\xB5-\xB7\xE0-\xED]+$/
    # usernames same as above, but no spaces or commas allowed

    @setState({ new_acct_error_msg: '' })
    if new_name.length < 4
        @setState({ new_acct_error_msg: 'Your real name is too short! At least 4 characters.'})
        return
    if new_name.length > 30
        @setState({ new_acct_error_msg: 'Your real name is too long! Maximum 30 characters.'})
        return
    if not regex_name.test(new_name)
        @setState({ new_acct_error_msg: 'Only letters, spaces, numbers or hyphens allowed for a real name.'})
        return
    if new_username.length < 4
        @setState({ new_acct_error_msg: 'Your username is too short! At least 4 characters.'})
        return
    if new_username.length > 20
        @setState({ new_acct_error_msg: 'Your username is too long! Maximum 20 characters.'})
        return
    if not regex_username.test(new_username)
        @setState({ new_acct_error_msg: 'Your username may only contain letters and numbers.'})
        return
    if new_password.length < 8
        @setState({ new_acct_error_msg: 'Your password is too short! At least 8 characters. Try a unique, easy-to-remember phrase?'})
        return

    data =
        name : new_name
        username : new_username
        password : new_password
        social_userid : @props.social_user.id
        mode: @props.mode

    common.createLocalAccountAfterSocialReg(data, (result) =>
      if (result.success) and (@props.redirect)
          window.location = @props.redirect
      else if (result.success)
          window.location = '/'
      else if (result.errors)
          @setState({ new_acct_error_msg: result.errors })
    )

  render: =>


      if @state.new_acct_error_msg is ''
          new_acct_error_msg = <span></span>
      else
          new_acct_error_msg =
              <span className="text-danger">
                  { @state.new_acct_error_msg }
              </span>


      if @props.mode is 'post_social_reg'
          <div>
                <div className='row no-gutters'>
                    Username:&nbsp;
                    <input className='form-control form-txt-lg border-danger'
                       placeholder="* Username"
                       type="text"
                       ref={@newUsernameRef}
                       name="new_username"></input>
                </div>
                <div className='row no-gutters'>
                    Password:&nbsp;
                    <div className="input-group pb-3">
                        <input className='form-control form-txt-lg border-danger'
                              placeholder={@state.password}
                              type="text"
                              ref={@newPasswordRef}
                              name="new_password"
                              aria-label="new password"
                              aria-describedby="basic-addon2">
                        </input>
                        <div className="input-group-append">
                            <button className='btn btn-outline-secondary'
                                    onClick={@showNewPassword}>
                                <span className="fa fa-eye ml-3 mr-3"
                                      ref={@newPasswordEyeRef}></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='row no-gutters'>
                    Full real name - so people can find your work:&nbsp;
                    <input className='form-control form-txt-lg border-danger'
                       placeholder={@state.name}
                       type="text"
                       ref={@newNameRef}
                       name="new_name"></input>
                </div>
                <div className='row no-gutters'>
                  <button className="btn btn-secondary"
                      onClick={@submitNewCredentials}>submit</button>
                  &nbsp;{ new_acct_error_msg }
                </div>

          </div>
      else
          <span></span>
