
module.exports = class UrlLinkRoll extends React.Component
  displayName: 'UrlLinkRoll'

  constructor: (props) ->
      super(props)
      if @props.url.body_blawb_id
          mode = 'blawb_view'
      else
          mode = 'url_link'
      @state = {
          mode: mode,
          files: [],
          blawbcontent: '',
          blawbid: ''
          url: ''
      }

  goToLink: () =>
      link = @props.url.link
      if link.slice(0,4) is 'http'
          window.open(link)
      else
          window.open('http://' + link)

  urlText: =>
      if (@props.url.link) and (@props.url.link.length > 50)
          url_text = @props.url.link.slice(0,50) + '...'
      else if (@props.url.link)
          url_text = @props.url.link

  captionText: =>
      if @props.url.caption.length > 0
          caption_text =
              <p className='p-0 m-0'>&#8212;{ @props.url.caption }</p>

  viewUrlBlawb: () =>
      @setState({mode: 'viewing'})

  minimizeUrl: () =>
      @setState({ mode: 'reading' })

  # ASSUMES URL IS ATTATCHED TO A NOTE
  # TODO: MAKE MORE GENERAL (FOR INSTANCE PROFILES)
  viewMaterialinNewWindow: () =>
      if @props.note
          window.open('/short_course/' + @props.note.project_id + '/title/' +
                      @props.note.paperseries_id + '/resource/' + @props.url.id)

  urlBlawb: =>
      <span>
          <button className="btn btn-link btn-sm m-0 p-0 mr-2"
              onClick={@viewMaterialinNewWindow}>
              {@props.url.link}
          </button>
          { @captionText() }
      </span>



  render: =>

      if @state.mode is 'url_link'
          <div className='row no-gutters'>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  <button className='btn btn-link btn-sm m-0 p-0 truncate'
                          onClick={@goToLink}>{ @urlText() }</button>
                  { @captionText() }
              </div>
          </div>

      else if @state.mode is 'blawb_view'
          <div>
              { @urlBlawb() }
          </div>

      else
          <span></span>
