common = require('../common')
Loading = require('../loading')

module.exports = class LostPassword extends React.Component
  displayName: 'LostPassword'

  constructor: (props) ->
      super(props)
      # optional @props.redirect
      show_password_reset = @props.show_password_reset
      @state = {
        show_password_reset: show_password_reset
        mode: show_password_reset
        msg: ''
        new_password: ''
      }

  componentDidUpdate: (prevProps) =>
      if @props.show_password_reset != prevProps.show_password_reset
          show_password_reset = @props.show_password_reset
          @setState({ mode: show_password_reset })

  emailResetCode: =>

      data =
        username: @props.username.toLowerCase()

      @setState({ mode: 'loading' })

      common.emailLostPwdVerificationCode( data, (result) =>
          @setState({ mode: 'sent' })
      )

  checkResetCode: =>

      user_entered_code = @refs.code_challenge.value
      if user_entered_code.length < 0
          @setState({ msg: 'Please enter the code from your email.'})
      user_entered_code = user_entered_code.replace(/\s/g, '') # remove whitespace

      if @refs.new_password.value.length < 8
          @setState({ msg: 'Your password is too short! At least 8 characters. Try a unique, easy-to-remember phrase?'})
          return

      data =
          username: @props.username
          verif_code: user_entered_code
          new_password: @refs.new_password.value

      @setState({ new_password: data.new_password })

      @setState({ mode: 'loading' })

      common.confirmEmailedCodeAndResetLostPassword(data, (result) =>

          if result.msg is 'correct'
              @submitCredentials()
          else
              @setState({ msg: result.msg, mode: 'sent' })
      )

  submitCredentials: =>

      username = @props.username
      password = @state.new_password

      form =
        username : username
        password : password

      common.localLogin(form,(result) =>
        if (result.success is true) and (@props) and (@props.redirect)
            window.location = @props.redirect
        else if (result.success) is true
            window.location = '/'
        else
            @setState({ msg : result.msg})
            if result.msg is 'Invalid password'
                @setState({ show_password_reset: 'true' })
      )

  showNewPassword: () =>

      if @refs.new_password.type is 'password'
          @refs.new_password.type = 'text'
          @refs.eye_newpassword.className = 'fa fa-eye ml-3 mr-3'
      else
          @refs.new_password.type = 'password'
          @refs.eye_newpassword.className = 'fa fa-eye-slash ml-3 mr-3'

  render: =>

    if @props.show_password_reset is 'true'
      if @state.mode is 'true'
        password_reset_request =
            <button className='btn btn-primary btn-sm' onClick={@emailResetCode}>
                Reset password
            </button>
      else if @state.mode is 'loading'
          password_reset_request =
              <span>
                <Loading />
              </span>
      else if @state.mode is 'sent'
          password_reset_request =
              <span>

                  <div className='row no-gutters'>
                      <span>Enter the code sent to your email:&nbsp;</span>
                      <input className='form-control form-txt-lg'
                             type="text"
                             placeholder="* Challenge code"
                             ref="code_challenge"></input>
                  </div>

                  <div className='row no-gutters'>
                      <span>Choose a new password:&nbsp;</span>

                      <div className="input-group pb-3">
                         <input className='form-control form-txt-lg border-danger'
                                placeholder='* Password (case sensitive)'
                                type="password"
                                ref="new_password"
                                aria-label="new_password"
                                aria-describedby="basic-addon2">
                         </input>
                         <div className="input-group-append">
                             <button className='btn btn-outline-secondary' onClick={@showNewPassword}>
                                <span className="fa fa-eye-slash ml-3 mr-3" ref='eye_newpassword'></span>
                             </button>
                         </div>
                      </div>
                      <div className='p2'>
                          <button className="btn btn-secondary btn-sm same-line" onClick={@checkResetCode}>Submit</button>
                      </div>

                  </div>

                  <div>
                      <div className='p2'>
                          <span className='text-danger'>
                              { @state.msg }
                          </span>
                      </div>
                  </div>

              </span>
      else
          password_reset_request = <span></span>

    <div>
      { password_reset_request }
    </div>
