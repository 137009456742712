common = require('../common')
DocumentFullFrame = require('./document_fullframe')
Loading = require('../loading')
MiniHeader = require('../mini_header')

module.exports = class ConferenceNoteDocument extends React.Component
    displayName: 'ConferenceNoteDocument'

    constructor: (props) ->
      super(props)
      @state = {noteid: ''}

    componentDidMount: =>
        data =
            projectid: @props.match.params.projectId
            paperseriesid: @props.match.params.paperseriesId

        common.getNoteidFromPaperSeriesAndProject(data, (result) =>
            @setState({noteid: result.noteid})
        )

    render: =>
        if @state.noteid.length > 0
            filename = "/view_project_note_document/" + @state.noteid
            <DocumentFullFrame className="fullwindow_view_document_iframe" src={filename} />
        else
            <Loading />
