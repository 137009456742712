common = require('../common')
Loading = require('../loading')
IsReplicationStudy = require('./is_study')

module.exports = class Replications extends React.Component
  displayName: 'Replications'

  constructor: (props) ->
      super(props)
      # expects:
      # @props.mode
      # @props.note
      # @props.rtype
      # @props.editor
      if @props.note.is_replication_study
          my_replications = @props.note.is_replication_study
          rep_study = 'yes'
          mode = 'edit'
      else
          my_replications = []
          rep_study = 'no'
          mode = 'ask'
      @state = {
          rep_study: rep_study
          mode: mode
          my_replications : my_replications
          err_msg: ''
          recommend: 'yes'
      }

  setYes: =>
      @setState({
          yes_style: { display: 'block' },
          no_style: {display: 'none'},
          rep_study: 'yes',
          }, @openLoader)

  setNo: =>
      @setState({
          yes_style: { display: 'none' },
          no_style: {display: 'block'},
          rep_study: 'no',
          }, @closeLoader)

  yesRecommend: =>
      @setState({ recommend: 'yes' })

  noRecommend: =>
      @setState({ recommend: 'no' })

  validURL: (link) =>

      if link.slice(0,8) is 'https://'
          link = link.slice(8)
      if link.slice(0,7) is 'http://'
          link = link.slice(7)

      regex = /^[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/

      if not regex.test(link)
          return false
      else
          return true

  saveReplication: () =>

      link = @refs.link.value
      if link.length > 260
          @setState({ err_msg: 'Max 260 characters for link.'})
          return

      if not @validURL(link)
          @setState({ err_msg: 'Please enter a valid URL.'})
          return

      title = @refs.title.value
      facts = @refs.facts.value

      data =
          paperseries_id: @props.note.paperseries_id
          rtype: @props.rtype
          link: link
          title: title
          facts: facts
          recommend: @state.recommend

      common.saveReplication(data, (result) =>
          updated_replications = @state.my_replications
          updated_replications.push(result.replication)
          @refs.link.value = ''
          @refs.title.value = ''
          @refs.facts.value = ''
          @setState({
              my_replications: updated_replications
              mode: 'view' })
      )


  openLoader: =>
      @setState({ mode: 'edit' })

  closeLoader: =>
      @setState({ mode: 'ask' })

  render: =>

      if @state.rep_study is 'yes'
          yes_no_choices =
              <div>
                  <button className="proj_open_btn"
                          onClick={@setYes}>Yes</button>
                  <button className="proj_rightpad_btn"
                          onClick={@setNo}>No</button>
              </div>
      else
          yes_no_choices =
              <div>
                  <button className="proj_rightpad_btn"
                          onClick={@setYes}>Yes</button>
                  <button className="proj_open_btn"
                          onClick={@setNo}>No</button>
              </div>

      if @state.recommend is 'yes'
          recommend_yes_no_buttons =
              <span>
                  <button className="proj_open_btn"
                          onClick={@yesRecommend}>Yes</button>
                  <button className="proj_rightpad_btn"
                          onClick={@noRecommend}>No</button>
              </span>
      else
          recommend_yes_no_buttons =
              <span>
                  <button className="proj_rightpad_btn"
                          onClick={@yesRecommend}>Yes</button>
                  <button className="proj_open_btn"
                          onClick={@noRecommend}>No</button>
              </span>

      add_button =
          <span>
              Is this a replication study?
              { yes_no_choices }
          </span>

      save_button =
          <span>
              <span>Look good? </span>
              <button className='btn btn-primary'
                      onClick={@saveReplication}>Add</button>
              <span>&nbsp;(Feel free to add more works to which your replications apply).</span>
          </span>

      create_new_replication =
          <div className='col border success rounded m-3'>
              <div className='row m-1 font-weight-bold'>
                  Add a link to the work you replicated...
              </div>
              <div className='row m-1'>
                 <input className='headerInput'
                        ref='link'
                        type='text'
                        placeholder='Enter the link (either on ZeroDivZero or offsite).'>
                 </input>
              </div>
              <div className='row m-1'>
                 <input className='headerInput'
                        ref='title'
                        type='text'
                        placeholder='If this is a non-ZeroDivZero work, enter the title.'>
                 </input>
              </div>
              <div className='row m-1'>
                  Do you recommend the work? &nbsp;
                  { recommend_yes_no_buttons }
              </div>
              <div className='row m-1'>
                  <textarea ref='facts'
                            cols='40'
                            rows='4'
                            placeholder='Briefly list what you replicated and the result. For example: Could not replicate Figure 2, but did replicate Figure 3.'></textarea>
              </div>
              { save_button }
              <div className='text-danger'>
                  { @state.err_msg }
              </div>
          </div>

      if @state.mode is 'view'
          ask_for_another_entry =
              <button className='btn btn-primary'
                      onClick={@openLoader}>Add another</button>

      if @state.mode is 'loading'
          <Loading />
      else if @state.mode is 'ask'
          <span>
              { add_button }
          </span>
      else if @state.mode is 'edit'
          <span>
              <IsReplicationStudy replications={@state.my_replications}
                                  rtype={ @props.rtype }
                                  editor={@props.editor}
                                  mode={@state.mode}/>
              { create_new_replication }
              <br />
          </span>
      else if @state.mode is 'view'
          <span>
              <IsReplicationStudy replications={@state.my_replications}
                                  rtype={ @props.rtype }
                                  editor={@props.editor}
                                  mode={@state.mode}/>
              { ask_for_another_entry }
              <br />
          </span>
