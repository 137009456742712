common = require('../common')
ConferenceDirectUpload = require('../conference_direct_upload')
CreateNoteMetadata = require('../create_title/create_note_metadata')
ConferenceHeader = require('../conference_header')
NavGeneral = require('../nav_general')
HeaderBar = require('../header_bar')
Loading = require('../loading')
RoleRollConference = require('../roles/role_roll_conference')
RolesBarConf = require('../roles/roles_bar_conf')
ScriptPayButton = require('../stripe/script_pay_button')
SendStripeToken = require('../stripe/send_stripe_token')
SetConfDate = require('../schedule/set_conf_date')
SetConferenceFees = require('./set_conference_fees')
ShowConferenceAccount = require('./show_conference_account')
UserChooseRegistrationFee = require('./user_choose_registration_fee')
NameTag = require('./name_tag')
GetAllNameTags = require('./get_all_nametags')

module.exports = class ConferenceRegister extends React.Component
  displayName: 'ConferenceRegister'

  constructor: (props) ->
      super(props)
      projectid = @props.match.params.projectId
      chosen_fee = ''
      previous_charge_err = ''
      if localStorage
          chosen_projectid = localStorage.getItem('projectid')
          chosen_projectid = JSON.parse(chosen_projectid)
          if chosen_projectid == projectid
              chosen_fee = localStorage.getItem('chosen_fee')
              chosen_fee = JSON.parse(chosen_fee)
              previous_charge_err = localStorage.getItem('error_code')
              if previous_charge_err
                  previous_charge_err = JSON.parse(previous_charge_err)
      @state = {
          projectid: projectid
          mode: 'loading'
          roles: []
          project: {}
          userid: ''
          my_accepted_roles: []
          is_conference_creator: 'false'

          registration_deadline: ''
          start_date: ''
          registered_already: 'false'
          past_registration_deadline: 'true'
          past_start_date: 'false'

          show_dates: 'false'
          show_fee_toolbar: 'false'
          show_conference_account: 'false'
          show_reg: 'false'
          show_badge: 'false'
          show_all_badges: 'false'
          roles_error: ''

          stripe_token: ''
          fees: []
          chosen_fee: chosen_fee
          previous_charge_err: previous_charge_err

          new_note: {}
      }
      @makeNewTitleRef = React.createRef()
      @activeAreaRef = React.createRef()

  componentDidMount: =>

      data =
          projectid: @state.projectid

      common.getConferenceAndRolesForUser(data, (result) =>

          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid_conference' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })
          else

              project = result['project']
              loaded_wordlist = []
              if project.perms
                for perm in project.perms
                    loaded_wordlist.push(perm.word)

              @setState({
                  projectid: result.project.id,
                  project: project,
                  userid: result['userid'],
                  roles: result['roles'],
                  wordlist: loaded_wordlist,
                  registration_deadline: result['project']['registration_deadline'],
                  start_date: result['project']['start_date'] })

              my_accepted_roles = [ ]
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)
              @setState({ my_accepted_roles : my_accepted_roles })
              if 'creator' in my_accepted_roles
                  @setState({ is_conference_creator: 'true' })
              if 'registered' in my_accepted_roles
                  @setState({ registered_already: 'true' })

              today = Number(new Date())
              registration_deadline = Number(result['project'].registration_deadline*1000)
              start_date = Number(result['project'].start_date*1000)

              if (registration_deadline > 0) and (today < registration_deadline)
                  @setState({ past_registration_deadline: 'false' })

              if (start_date > 0) and (today > start_date)
                  @setState({ past_start_date: 'true' })

              document.title = result['project']['title']

              @setState({ mode: 'viewing' })

              elem = @activeAreaRef.current
              if (elem)
                  window.scrollTo(0, elem.offsetTop)
      )

      common.getFeesForConferenceRegistration(data, (result) =>
          fees = result['fees']
          fees.amount = fees.amount/100
          @setState({ fees: fees })
      )

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()

  showFeeChoice: (chosen_fee) =>
      @setState({ chosen_fee: chosen_fee })
      localStorage.setItem('chosen_fee', JSON.stringify(chosen_fee))
      localStorage.setItem('projectid', JSON.stringify(@state.project.id))


  scrollToActive: =>
      elem = @activeAreaRef.current
      if (elem)
          window.scrollTo(0, elem.offsetTop)

  turnOffAllPanels: =>
      @setState({
          show_dates: 'false'
          show_fee_toolbar: 'false'
          show_conference_account: 'false'
          show_reg: 'false'
          show_badge: 'false'
          show_all_badges: 'false'
          roles_error: '' })

  toggleDateToolbar: =>
      if @state.show_dates is 'true'
          @setState({ show_dates: 'false' })
      else
          @turnOffAllPanels()
          @setState({ show_dates: 'true' }, @scrollToActive)

  toggleFeeToolbar: =>
      if @state.show_fee_toolbar is 'true'
          @setState({ show_fee_toolbar: 'false' })
      else
          @turnOffAllPanels()
          @setState({ show_fee_toolbar: 'true' }, @scrollToActive)

  toggleShowAccount: =>
      if @state.show_conference_account is 'true'
          @setState({ show_conference_account: 'false' })
      else
          @turnOffAllPanels()
          @setState({ show_conference_account: 'true' }, @scrollToActive)

  toggleShowReg: =>
      if @state.show_reg is 'true'
          @setState({ show_reg: 'false' })
      else
          @turnOffAllPanels()
          @setState({ show_reg: 'true' }, @scrollToActive)

  toggleShowYourBadge: =>
      if @state.show_badge is 'true'
          @setState({ show_badge: 'false' })
      else
          @turnOffAllPanels()
          @setState({ show_badge: 'true' }, @scrollToActive)

  toggleShowBadging: =>
      if @state.show_all_badges is 'true'
          @setState({ show_all_badges: 'false' })
      else
          @turnOffAllPanels()
          @setState({ show_all_badges: 'true' }, @scrollToActive)

  toggleRegError: =>
      @turnOffAllPanels()
      @setState({
          roles_error: 'Register first!'}, @scrollToActive)

  toggleRolesError: =>
      @turnOffAllPanels()
      @setState({
          roles_error: 'You don\'t have the correct role(s) to use this tool.'}, @scrollToActive)


  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  getUserNoteDetails: =>
      @setState({
          mode : 'create_note_metadata'}, @scrollToHere(@makeNewTitleRef) )

  setMode: (info) =>
      # in this case, new_note might be returned!
      if info is 'cancelled'
          @setState({ mode: 'viewing' })
      else if info.new_note
          @setState({
              new_note: info.new_note
              mode: 'direct_upload_submisson' })
      else
          @setState({ mode: info })

  render: =>

      if (@state.mode != 'invalid_conference') and (@state.mode != 'conference_deleted')
          conference_header =
              <span>
                  <ConferenceHeader key={@state.project.id}
                                    project={@state.project}
                                    userid={@state.userid}
                                    roles={@state.roles}
                                    wordlist={@state.wordlist} />
              </span>
          roles_bar =
              <div className='row no-gutters border'>
                  <span className="ml-0 pl-2">
                    {@state.notes_length} submissions
                    <RolesBarConf project={@state.project}
                        my_accepted_roles={@state.my_accepted_roles}
                        roles={@state.roles}
                        userid={@state.userid}
                        view={'full'}
                        setUpdatedRoles={@setUpdatedRoles}
                        getUserNoteDetails={@getUserNoteDetails} />
                  </span>
              </div>

      conf_nav_vert =
          <div className='col-md-2 col-lg-2 border d-none d-md-block'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'register'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'col-12'} />
          </div>
      conf_nav_horiz =
          <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'register'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'mr-2'} />
          </div>

      if @state.is_conference_creator is 'true'
          dates_button =
              <div className="card light_gray">
                <div className="card-body">
                    <h2 className="card-title">
                        <button className='btn btn-primary'
                                onClick={@toggleDateToolbar}>important dates
                        </button>
                    </h2>
                    <p className="card-text">
                      Set important dates for registration.
                    </p>
                </div>
              </div>
      else
          dates_button =
              <div className="card light_gray">
                <div className="card-body">
                    <h2 className="card-title">
                        <button className='btn btn-primary'
                                onClick={@toggleRolesError}>important dates
                        </button>
                    </h2>
                    <p className="card-text">
                      Set important dates for registration.
                    </p>
                </div>
              </div>

      if @state.is_conference_creator is 'true'
          make_fees_button =
              <div className="card light_gray">
                <div className="card-body">
                    <h2 className="card-title">
                        <button className='btn btn-primary'
                                onClick={@toggleFeeToolbar}>set registration fees
                        </button>
                    </h2>
                    <p className="card-text">
                      Set important dates for registration.
                    </p>
                </div>
              </div>
      else
          make_fees_button =
              <div className="card light_gray">
                <div className="card-body">
                    <h2 className="card-title">
                        <button className='btn btn-primary'
                                onClick={@toggleRolesError}>set registration fees
                        </button>
                    </h2>
                    <p className="card-text">
                      Set important dates for registration.
                    </p>
                </div>
              </div>

      if @state.is_conference_creator is 'true'
          send_fees_button =
              <div className="card light_gray">
                <div className="card-body">
                    <h2 className="card-title">
                        <button className='btn btn-primary'
                                onClick={@toggleShowAccount}>
                            send payments
                        </button>
                    </h2>
                    <p className="card-text">
                      Transfer payments to the conference bank account.
                    </p>
                </div>
              </div>
      else
          send_fees_button =
              <div className="card light_gray">
                <div className="card-body">
                    <h2 className="card-title">
                        <button className='btn btn-primary'
                                onClick={@toggleRolesError}>send payments
                        </button>
                    </h2>
                    <p className="card-text">
                      Transfer payments to the conference bank account.
                    </p>
                </div>
              </div>

      if @state.registered_already is 'true'
          registration_note =
              <h2>You are registered</h2>
      else
          registration_note =
              <span>Time to register you...</span>


      registration_button =
          <div className="card light_gray">
            <div className="card-body">
                <h2 className="card-title">
                    <button className='btn btn-primary'
                            onClick={@toggleShowReg}>
                        register!
                    </button>
                </h2>
                <p className="card-text">
                  { registration_note }
                </p>
            </div>
          </div>

      if @state.registered_already is 'true'
          nametag_button =
              <div className="card light_gray">
                <div className="card-body">
                    <h2 className="card-title">
                        <button className='btn btn-primary'
                               onClick={@toggleShowYourBadge}>
                            badge
                        </button>
                    </h2>
                    <p className="card-text">
                      Make your conference badge
                    </p>
                </div>
              </div>
      else
          nametag_button =
              <div className="card light_gray">
                <div className="card-body">
                    <h2 className="card-title">
                        <button className='btn btn-primary'
                                onClick={@toggleRegError}>badge
                        </button>
                    </h2>
                    <p className="card-text">
                      After you have registered, make your conference badge
                    </p>
                </div>
              </div>

      if @state.is_conference_creator is 'true'
          download_nametags_button =
              <div className="card light_gray">
                <div className="card-body">
                    <h2 className="card-title">
                        <button className='btn btn-primary'
                                onClick={@toggleShowBadging}>
                            badge
                        </button>
                    </h2>
                    <p className="card-text">
                      Download all conference badges
                    </p>
                </div>
              </div>
      else
          download_nametags_button =
              <div className="card light_gray">
                <div className="card-body">
                    <h2 className="card-title">
                        <button className='btn btn-primary'
                                onClick={@toggleRolesError}>badge
                        </button>
                    </h2>
                    <p className="card-text">
                      Download all conference badges
                    </p>
                </div>
              </div>




      if @state.show_dates is 'true'
        conference_datetime_toolbar =
          <div className='proj_actions_Div'>
            <div className='container-fluid no-border light-padding'>
                <div className='row no-gutters'>
                  <strong>Creator Panel - Important Dates</strong>
                </div>
                <SetConfDate key={'registration_deadline'}
                             type={'registration_deadline'}
                             datetime={@state.registration_deadline}
                             confid={@state.project.id} />
                <br />
                <SetConfDate key={'start_date'}
                             type={'start_date'}
                             datetime={@state.start_date}
                             confid={@state.project.id} />
                <br />
                <button className='btn btn-primary'
                        onClick={@toggleDateToolbar}>
                        hide</button>
            </div>
          </div>

      if @state.show_fee_toolbar is 'true'
          conference_make_fees_toolbar =
            <div className='proj_actions_Div'>
              <div className='container-fluid no-border light-padding'>
                  <SetConferenceFees fees={ @state.fees }
                                     confid={ @state.project.id } />
                  <button className='btn btn-primary'
                          onClick={@toggleFeeToolbar}>
                          hide</button>
              </div>
            </div>


      if  @state.registered_already is 'true'
          registration_info =
              <div className='container-fluid no-border light-padding'>
                  <div className='row no-gutters'>
                    <strong>Conference Registration Panel</strong>
                  </div>
                  <div className='row no-gutters'>
                    <h2>You are registered</h2>
                  </div>
              </div>
      else
          registration_info =
              <div className='container-fluid no-border light-padding'>
                  <strong>Conference Registration Panel</strong>
                  <UserChooseRegistrationFee
                      fees={ @state.fees }
                      onClick={ @showFeeChoice.bind(@state.chosen_fee) }/>
              </div>

      if @state.show_badge is 'true'
          nametag_toolbar =
              <NameTag registered={@state.registered_already}
                       userid={@state.userid}
                       projectid={@state.projectid}/>


      if !(@state.userid)
          checkout =
              <p className="text-center text-danger">
                  See top bar to create a ZeroDivZero account (then complete registration).
              </p>
      else if (@state.project) and (@state.chosen_fee)
          checkout =
              <div className="d-flex justify-content-center">
                  <ScriptPayButton title={@state.project.title}
                                   amount={@state.chosen_fee.amount}
                                   fee_id={@state.chosen_fee.id}
                                   confid={ @state.project.id } />
              </div>


      if (@state.chosen_fee) and
         (@state.chosen_fee.amount > 1) and
         (@state.registered_already is 'false') and
         (@state.fees) and
         (@state.fees.length>0)
              payment_time =
                  <div>
                    <div className="d-flex justify-content-center">
                      Your choice: &nbsp; <span className="font-weight-bold">{@state.chosen_fee.category}</span> &nbsp; for &nbsp; <span className="font-weight-bold">${@state.chosen_fee.amount/100}</span>.
                    </div>
                    { checkout }
                  </div>

      if @state.show_reg is 'true'
          registration_toolbar =
              <div className='proj_actions_Div'>
                  { registration_info }
                  { payment_time }
                  { charge_error }
              </div>

      if (@state.past_start_date is 'true') and (@state.show_conference_account is 'true')
          send_money_to_conference_toolbar =
              <div className='proj_actions_Div'>
                <div className='container-fluid no-border light-padding'>
                    <div className='row no-gutters'>
                      <strong>Creator Panel - Request Payment</strong>
                    </div>
                    <ShowConferenceAccount project={@state.project} />
                    <button className="btn btn-success btn-text-sm"
                            onClick={@sendConfMoney}>
                            Send to conference bank
                    </button>
                </div>
              </div>
      else if (@state.past_start_date is 'false') and (@state.show_conference_account is 'true')
          send_money_to_conference_toolbar =
              <div className='proj_actions_Div'>
                <div className='container-fluid no-border light-padding'>
                    <div className='row no-gutters'>
                      <strong>Creator Panel - Request Payment</strong>
                    </div>
                    <ShowConferenceAccount project={@state.project} />
                    <span className='text-danger'>
                      You can request payment after your conference has started.
                    </span>
                    <br />
                    <button className="btn btn-secondary btn-text-sm">
                            Send to conference bank
                    </button>
                </div>
              </div>
      else if (@state.fees.length > 0) and (@state.show_conference_account is 'true')
          send_money_to_conference_toolbar =
              <div className='proj_actions_Div'>
                <div className='container-fluid no-border light-padding'>
                    <div className='row no-gutters'>
                      <strong>Creator Panel - Request Payment</strong>
                    </div>
                    <button className="btn btn-primary btn-txt-sm"
                            onClick={@toggleShowAccount}>
                            Show payments summary
                    </button>
                </div>
              </div>

      if @state.mode is 'invalid_conference'
          problem_msg =
              <div>
                  <br />
                  <h2>We cannot find that conference... Try refreshing!</h2>
                  If you continue to get this error, then maybe the conference number is wrong?
              </div>
      else if @state.mode is 'conference_deleted'
          problem_msg =
              <div>
                  <br />
                  <h2>Uh oh... It appears the owner has deleted this conference.</h2>
              </div>

      if (@state.previous_charge_err)
          charge_error =
              <div className='text-danger'>
                  There was a problem: { @state.previous_charge_err }. Try again?
              </div>

      if @state.show_all_badges is 'true'
          get_all_nametags =
              <div className='proj_actions_Div'>
                  <div className='container-fluid no-border light-padding'>
                      <div className='row no-gutters'>
                        <strong>Creator Panel - Name Tags</strong>
                      </div>
                      <GetAllNameTags is_conference_creator={ @state.is_conference_creator }
                                      projectid={ @state.projectid } />
                  </div>
              </div>

      if (@state.chosen_fee)
          stripe_send_payment_listener =
              <SendStripeToken amount={@state.chosen_fee.amount}
                               fee_id={@state.chosen_fee.id}
                               confid={ @state.project.id } />


      if (@state.mode is 'viewing') and (@state.project.tags)
              <div>
                  <HeaderBar />
                  <div className='container-fluid pr-0 pl-0 border'>
                      {conference_header}
                      <div className='row no-gutters'>
                          {conf_nav_vert}
                          {conf_nav_horiz}
                          <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                              { roles_bar }
                              <div className="card-deck p-3">
                                  { registration_button }
                                  { nametag_button }
                                  { dates_button }
                                  { make_fees_button }
                                  { send_fees_button }
                                  { download_nametags_button }
                              </div>
                              <div ref={@activeAreaRef}>
                                  { registration_toolbar }
                                  { nametag_toolbar }
                                  { conference_datetime_toolbar }
                                  { conference_make_fees_toolbar }
                                  { send_money_to_conference_toolbar }
                                  { get_all_nametags }
                                  { @state.roles_error }
                              </div>
                              { stripe_send_payment_listener }
                          </div>
                      </div>
                  </div>
              </div>
      else if (@state.mode is 'conference_deleted') or (@state.mode is 'invalid_conference')
          <div>
              <HeaderBar />
              <span ref={@activeAreaRef}>
              { problem_msg }
              </span>
          </div>
      else if @state.mode is 'create_note_metadata'
          upload_paper =
              <div ref={@makeNewTitleRef}>
                  <CreateNoteMetadata project={@state.project}
                                      setMode={@setMode}
                                      version={'new'}/>
              </div>
      else if @state.mode is 'direct_upload_submisson'
          upload_paper =
              <div>
                  You have created a personal project and added a title.
                  Just a few more choices to finish submission.
                  <ConferenceDirectUpload
                      note={@state.new_note}
                      conference={@state.project} />
              </div>
      else
          <div>
              <HeaderBar />
              {conference_header}
              <div className='proj_listing'
                   ref={@activeAreaRef}>
                <div className='note_noPapers'>
                  <br />
                  <Loading />
                  <br />
                </div>
              </div>
          </div>
