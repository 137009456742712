

module.exports = class SimpleList extends React.Component
  displayName: 'SimpleList'

  render: =>

      <div className='pointseven_em'>
        * { @props.item }
      </div>
