common = require('./common')
ConferenceDirectUpload = require('./conference_direct_upload')
CreateNoteMetadata = require('./create_title/create_note_metadata')
ConferenceHeader = require('./conference_header')
NavGeneral = require('./nav_general')
HeaderBar = require('./header_bar')
Loading = require('./loading')
RolesBarConf = require('./roles/roles_bar_conf')

module.exports = class ConferenceAbout extends React.Component
  displayName: 'ConferenceAbout'

  constructor: (props) ->
      super(props)
      projectid = @props.match.params.projectId
      @state = {
        projectid: projectid
        proj_about: ''
        editor_mode: 'reading'
        window_mode: 'loading'
        openEditor: true
        editorState: ''
        editor: ''
        project: ''
        userid: ''
        roles: []
        my_accepted_roles: []
        wordlist: []

        new_note: {}
        submit_mode: ''
      }
      @makeNewTitleRef = React.createRef()
      @aboutRef = React.createRef()
      @activeAreaRef = React.createRef()


  updateConferenceData: (data) =>
      if data['results'] is 'not found'
          #@setState({ mode : 'invalid_conference' })
      else if data['results'] is 'deleted'
          #@setState({ mode : 'conference_deleted' })
      else
          my_accepted_roles = []
          for role in data['results'][0]['roles']
              if role.role_accepted is 'true'
                my_accepted_roles.push(role.role)

          project = data['results'][0]
          loaded_wordlist = []
          if project.perms
            for perm in project.perms
                loaded_wordlist.push(perm.word)

          @setState({
              project: project,
              userid: data['userid'],
              roles: data['results'][0]['roles'],
              my_accepted_roles: my_accepted_roles,
              wordlist: loaded_wordlist,
              proj_about: data['results'][0]['about'],
              window_mode: 'viewing'
              }, @setDefaultTxt)

          if data['results'][0]
              document.title = data['results'][0]['title']
          else
              document.title = 'ZeroDivZero'

  componentDidMount: () =>
      callback = (data) => @updateConferenceData(data)
      common.fetchProject(callback, @props.match.params.projectId)

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()

  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  updateProjectAbout: () =>
      about = @state.editor.getData()
      @setState({ proj_about : about },@toggleReadEditMode())

      data =
          projectid: @state.project.id
          project_about: about

      common.submitProjectAbout(data, (result) =>
        # nothing to see here
      )

  toggleReadEditMode: () =>
      if @state.editor_mode is 'editing'
        @setState({ editor_mode : 'reading' })
        @closeCkEditor()
      else
        @setState({ editor_mode : 'editing' })
        @openEditor()

  gotoToggleAbout: =>
      if @state.show_about is false
          @setState({ show_about : true })
      else
          @setState({ show_about : false })

  cancelUpdate: () =>
      @closeCkEditor()
      @setState({ editor_mode : 'reading' })

  closeCkEditor: () =>
      CKEDITOR.instances.project_about.destroy()
      # @preview()
      @setDefaultTxt

  preview: =>
      document.getElementById("project_about_txt").innerHTML= @state.editor.getData()
      # @refs.project_about_txt.innerHTML = @state.editor.getData()

  setDefaultTxt: () =>
      document.getElementById("project_about_txt").innerHTML = @state.proj_about
      elem = @activeAreaRef.current
      if (elem)
          window.scrollTo(0, elem.offsetTop)

  openEditor: =>
      # @refs.project_about.contenteditable = true
      document.getElementById("project_about").setAttribute("contenteditable", "true")
      editor = CKEDITOR.replace('project_about')
      @setState({editor: editor, openEditor : true})
      editor.on('instanceReady', () =>
          editor.setData(@state.proj_about))
      editor.on('change', (e) =>
          @preview()
      )

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  getUserNoteDetails: =>
      @setState({
          submit_mode : 'create_note_metadata'
          editor_mode: ''
          window_mode: 'viewing'}, @scrollToHere(@makeNewTitleRef) )

  setMode: (info) =>
      # in this case, new_note might be returned!
      if info is 'cancelled'
          @setState({
              submit_mode: ''
              editor_mode: 'reading'
              window_mode: 'viewing' })
      else if info.new_note
          @setState({
              new_note: info.new_note
              submit_mode: 'direct_upload_submisson'
              editor_mode: ''
              window_mode: 'viewing' })
      else
          @setState({
              submit_mode: info
              editor_mode: 'reading'
              window_mode: 'viewing' })

  conferenceHeader: () =>
      if (@state.mode != 'invalid_conference') and (@state.mode != 'conference_deleted')
          <span>
              <ConferenceHeader key={@state.project.id}
                                project={@state.project}
                                userid={@state.userid}
                                roles={@state.roles}
                                wordlist={@state.wordlist} />
              <div className='proj_actions_Div'>
                  <RolesBarConf project={@state.project}
                      my_accepted_roles={@state.my_accepted_roles}
                      roles={@state.roles}
                      userid={@state.userid}
                      view={'full'}
                      setUpdatedRoles={@setUpdatedRoles}
                      getUserNoteDetails={@getUserNoteDetails} />
              </div>
          </span>

  aboutRow: () =>
      if (@state.editor_mode is 'reading') and (@state.submit_mode is '')
          EditorStyle =
            display : 'none'
          PreviewStyle =
            display : 'block'
          ButtonRow =
              if ('chair' in @state.my_accepted_roles) or ('creator' in @state.my_accepted_roles)
                  <div className='col'>
                    <div className='float-right pr-2'>
                        <button className="btn btn-secondary" onClick={@toggleReadEditMode}>
                          Edit
                        </button>
                    </div>
                    <br />
                  </div>
              else
                  <span>
                  </span>
      else if (@state.editor_mode is 'editing') and (@state.submit_mode is '')
          EditorStyle =
            display : 'block'
          PreviewStyle =
              display : 'none'
          ButtonRow =
              <div className='proj_block'>
                <div className='proj_inline_L'>
                    <h2>About</h2>
                </div>
                <div className='proj_inline_R'>
                  <button className="btn btn-secondary" onClick={@cancelUpdate}>
                    cancel
                  </button>
                </div>
                <div className='proj_inline_R'>
                  <button className="btn btn-secondary" onClick={@updateProjectAbout}>
                    save
                  </button>
                </div>
              </div>
      if (@state.editor_mode is 'reading') or (@state.editor_mode is 'editing')
          AboutRow =
              <span>
                  <textarea style={EditorStyle}
                            id="project_about"
                            ref={@aboutRef}
                            defaultValue={@state.proj_about}
                            onChange={@changeHandler}>
                  </textarea>
                  <div style={PreviewStyle}
                        className='p-3'
                       id='project_about_txt'></div>
                  <br />
                  <div className="proj_save_conference_div"
                      style={EditorStyle}>
                      <button className="btn btn-secondary"
                              onClick={@updateProjectAbout}>Save</button>
                  </div>
              </span>

      <div>
        <div className='p-0 m-0'>
          <div className='row no-gutters p-3'>
              { ButtonRow }
          </div>
          { AboutRow }
        </div>
      </div>


  render: =>


      conf_nav_vert =
          <div className='col-md-2 col-lg-2 border d-none d-md-block'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'about'}
                  userid={@state.userid}
                  styling={'col-12'} />
          </div>
      conf_nav_horiz =
          <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'about'}
                  userid={@state.userid}
                  styling={'mr-2'} />
          </div>

      if @state.submit_mode is 'create_note_metadata'
          upload_paper =
              <div ref={@makeNewTitleRef}>
                  <CreateNoteMetadata project={@state.project}
                                      setMode={@setMode}
                                      version={'new'}/>
              </div>
      else if @state.submit_mode is 'direct_upload_submisson'
          upload_paper =
              <div>
                  You have created a personal project and added a title.
                  Just a few more choices to finish submission.
                  <ConferenceDirectUpload
                      note={@state.new_note}
                      conference={@state.project} />
              </div>


      if (@state.window_mode is 'viewing')
          <div>
            <HeaderBar />
            <div className='container-fluid pr-0 pl-0 border'>
                { @conferenceHeader() }
                <div className='row no-gutters'>
                    {conf_nav_vert}
                    {conf_nav_horiz}
                    <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                      { @aboutRow() }
                      { upload_paper }
                    </div>
                </div>
            </div>
          </div>
      else if @state.window_mode is 'loading'
          <Loading />
      else
          <div>
              { @conferenceHeader() }
              <h1 ref={@activeAreaRef}>This conference does not exist or has been deleted.</h1>
          </div>
