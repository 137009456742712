Link = require('react-router-dom').Link
HeaderBar = require('../header_bar')
Policies = require('../site_info/policies')


module.exports = class HelloWorldMore extends React.Component
  displayName: 'HelloWorldMore'

  constructor: (props) ->
      super(props)
      @state = { }

  componentDidMount: =>
      document.title = 'ZeroDivZero more...'

  render: =>

      oui = <span>&#10004;</span>
      non = <span>&#10006;</span>

      <span>
        <HeaderBar />
        <div className='container-fluid'>
          <div className="jumbotron pt-2 pb-2">
              <h1>How does <span className='text-success'>ZeroDivZero</span> compare?</h1>
              <p className="lead text-primary">
                  Modern research conferences
              </p>
              <p>
                ZeroDivZero was built to be an informative, fast (and fun!)
                conference hosting platform. The closest comparison would be
                if a conference software site (like EasyChair, CMT, or
                OpenReview) was combined with Nature or Science journals.
              </p>
              <p>
                Our goal is
                to make it simple to setup conferences, preserve content,
                and intuitive for you to search and discover whatever topics
                interest you.
              </p>
              <hr className="my-4"></hr>
              <p>
                  Really, the best method to see how we are different is to play
                  around with the site. However, here is a comparison chart
                  highlighting some of our capabilities.
              </p>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">ZeroDivZero</th>
                <th scope="col">EasyChair</th>
                <th scope="col">CMT</th>
                <th scope="col">OpenReview</th>
                <th scope="col">PubPeer</th>
                <th scope="col">arXiv</th>
                <th scope="col">Mendeley</th>
                <th scope="col">ResearchGate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Conference platform</th>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
              </tr>
              <tr>
                <th scope="row">Papers, talks, posters</th>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ oui }</td>
              </tr>
              <tr>
                <th scope="row">Site searchable?</th>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ oui }</td>
              </tr>
              <tr>
                <th scope="row">Permanent</th>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
              </tr>
              <tr>
                <th scope="row">Review structure</th>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
              </tr>
              <tr>
                <th scope="row">Public profile page</th>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ oui }</td>
              </tr>
              <tr>
                <th scope="row">Submission dashboard</th>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
              </tr>
              <tr>
                <th scope="row">Collect Registration</th>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
              </tr>
              <tr>
                <th scope="row">LaTeX</th>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ non }</td>
              </tr>
              <tr>
                <th scope="row">Social tools</th>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ non }</td>
                <td>{ oui }</td>
                <td>{ non }</td>
                <td>{ oui }</td>
                <td>{ oui }</td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />
        <Policies />
      </span>
