Link = require('react-router-dom').Link
http = require 'http'
module.exports = class PythonCodeRunner extends React.Component
  displayName: 'PythonCodeRunner'

  constructor: (props) ->
    super(props)
    @state = {
        data: { no_state_yet: '' }
    }

  outf: (text) =>
      mypre = @refs.output
      mypre.innerHTML = mypre.innerHTML + text

  builtinRead: (x) =>
      if (Sk.builtinFiles == undefined ||
      Sk.builtinFiles["files"][x] == undefined)
          throw "File not found: '" + x + "'"
      Sk.builtinFiles["files"][x]

  runit: () =>
      prog = @refs.yourcode.value
      mypre = @refs.output
      mypre.innerHTML = ''
      Sk.pre = "output"
      Sk.configure({output:@outf, read:@builtinRead})
      (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = 'mycanvas'
      myPromise = Sk.misceval.asyncToPromise(=>
          Sk.importMainWithBody("<stdin>", false, prog, true)
      )
      myPromise.then((mod) =>
                        console.log('success')
      ,
      (err) =>
                        console.log(err.toString())
      )

    render: =>
      <div className='proj_listing'>
        <p>0div0 Python Code Runner</p>
        <textarea id="yourcode" ref="yourcode" cols="40" rows="10" defaultValue={"print 'Hello World'"}>
        </textarea>
        <br />
        <button onClick={@runit}>Run</button>
        <pre id="output" ref="output"></pre>
      </div>
