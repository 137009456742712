common = require('./common')
Link = require('react-router-dom').Link
Motive = require('./motive')
TitleLink = require('./titles/title_link')
RolesBarConf = require('./roles/roles_bar_conf')

module.exports = class ConferenceSummaryLine extends React.Component
  displayName: 'ConferenceSummaryLine'

  constructor: (props) ->
      super(props)
      # expects @props.userid (among others)
      @state = {
        project: @props.project
        myroles : [ ]
        my_accepted_roles: []
        ptype: ''
      }

  componentDidMount: () =>
      myroles = @props.project.roles
      my_accepted_roles = []
      for myrole in myroles
          myrole.id = myrole[0]
          myrole.role = myrole[1]
          myrole.role_accepted = myrole[2]
          if myrole.role_accepted is 'true'
              my_accepted_roles.push(myrole.role)

      ptype = @props.project.ptype
      if @props.project.ptype is 'personal'
          ptype = 'project'

      @setState({
          myroles: myroles
          my_accepted_roles: my_accepted_roles
          ptype: ptype })

  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  render: =>

    roles_bar =
      <span>
          <RolesBarConf project={@props.project}
                    my_accepted_roles={@state.my_accepted_roles}
                    roles={@state.myroles}
                    userid={@props.userid}
                    view={'mini'}
                    setUpdatedRoles={@setUpdatedRoles} />
      </span>

    <div className='note_infoDiv'>
        <div className='note_Div'>
              { roles_bar }
              <div>
                  <Link to={ '/' + @state.ptype + '/' + @props.project.id + '/titles' }>
                      <TitleLink title={@props.project.title}
                                editor={@props.project.editor} />
                  </Link>
              </div>
              <div className='note_stats'>
                Submissions: {@props.project.num_papers}
              </div>
          </div>
      </div>
