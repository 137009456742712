Link = require('react-router-dom').Link
common = require('./common')

module.exports = class ReviewerNameRollEditable extends React.Component
  displayName: 'ReviewerNameRollEditable'

  constructor: (props) ->
      super(props)
      @state = {
        reviewer: @props.reviewer
        mode: 'display name'
      }


  componentWillReceiveProps: (nextProps) =>
      if nextProps.reviewer.name is 'Removed!'
        @setState({ mode : 'remove name' })

  render: =>

      if @state.mode is 'remove name'
          <div className='authornameroll_div'>Removed!</div>
      else if @props.reviewer.user_id?
        <div className="btn-group"
             role="group"
             aria-label="Basic example">
          <button className="btn btn-primary btn-txt-sm">{@props.reviewer.name}</button>
          <button className="btn btn-secondary btn-txt-sm"
                  onClick={@props.onClick}>
                  &nbsp;x&nbsp;</button>
        </div>
      else
          <div className="btn-group"
               role="group"
               aria-label="delete reviewer">
            <button className="btn btn-secondary btn-txt-sm">
                {@props.reviewer.name}</button>
            <button className="btn btn-danger btn-txt-sm"
                    onClick={@props.onClick}>
                    &nbsp;x&nbsp;</button>
          </div>
