common = require('../common')
Loading = require('../loading')
SurveyRoll = require('./survey_roll')

module.exports = class TakeSurvey extends React.Component
  displayName: 'TakeSurvey'

  constructor: (props) ->
      super(props)
      @state = {
          mode: 'loading'
          survey: []
          title: ''
          reason: ''

          user_choices: []
      }

  getSurveyDeets: () =>
      data =
        survey_id: @props.survey_id

      common.getSurvey(data, (result) =>

          @setState({
            mode: 'get_answers'
            survey: result['full_survey']
            title: result['full_survey']['survey']['title']
            reason: result['full_survey']['survey']['reason']
            })
      )

  saveUserAnswers: () =>

      user_choices = []

      survey = @state.survey
      questions_answered = 0
      for ask in survey.asks

        old_len = user_choices.length

        for choice in ask.choices
          if choice.selected == 'true'
            user_choices.push(choice.id)

        if (user_choices.length - old_len > 0)
            # if user picked any choices, they answered this question
            questions_answered = questions_answered + 1

      data =
        user_choices: user_choices
        survey_id: @state.survey.survey.id
        userid: @props.userid

      common.saveUserSurveyAnswers(data, (result) =>

          if (result['msg'] == 'success')
              @setState({ save_result: 'Saved!'})
              updated =
                  progress: 'answered'
                  questions_answered: questions_answered
              @props.showSurveyList(updated)
          else
              @setState({ save_result: result['error_msg'] })
      )

  componentDidMount: () =>
      if (@props.survey_id)
          @getSurveyDeets()

  componentDidUpdate: (prevProps) =>
      if (@props.survey_id != prevProps.survey_id)
        @getSurveyDeets()

  saveChoiceSelection: (user_choice) =>
      survey = @state.survey
      for ask in survey.asks
        for choice in ask.choices
          if choice == user_choice
            if choice.selected is 'true'
              choice.selected = 'false'
            else
              choice.selected = 'true'
      @setState({ survey: survey })


  render: =>

      if @state.mode is 'loading'
          <div>
              <Loading />
          </div>
      else
          <div>
              <h2>{ @state.title }</h2>
              <strong>{ @state.reason }</strong>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  { @state.survey.asks.map((item) =>
                      <SurveyRoll key={ Math.random() }
                                  item={item}
                                  mode={ 'get_answers' }
                                  saveChoiceSelection={ @saveChoiceSelection } />
                  , this)}
              </div>
              <button className='btn btn-primary'
                      onClick={@saveUserAnswers}>
                      Save
              </button>
          </div>
