common = require('../common')
ShowPlace = require('./show_place')

module.exports = class PlaceRoll extends React.Component
  displayName: 'PlaceRoll'

  constructor: (props) ->
      super(props)
      # expects props: place
      @state = {

      }

  render: =>

      <button className='btn btn-primary btn-txt-sm'
              onClick={@props.onClick} >
          <ShowPlace key={@props.place.id}
                     name={ @props.place.name }
                     lat={ @props.place.lat }
                     lon={ @props.place.lon } />
      </button>
