common = require('../common')
ChoiceRoll = require('./choice_roll')
CommonAsks = require('./common_asks')
SurveyOverviewRoll = require('./survey_overview_roll')
SurveyRoll = require('./survey_roll')

module.exports = class CreateOrEditSurvey extends React.Component
  displayName: 'CreateOrEditSurvey'

  # TODO: NEED TO VALIDATE FIELDS BEFORE SENDING
  # TODO: make sure we haven't hit the limit of 3 surveys.

  constructor: (props) ->
      super(props)
      # expects as props:
      # projectid
      # surveyable_id
      # do_this ('make_survey' or 'edit_survey')
      # updateSurveyableId -- if saving a survey
      survey_mode = 'show_button' # when in doubt, ask
      if @props.do_this is 'make_survey'
          survey_mode = 'build_survey'
      else if @props.do_this is 'edit_survey'
          survey_mode = 'edit_survey'
      else if @props.do_this is 'updatedSurveyableId'
          survey_mode = 'saved'
      @state = {
        ask_for_question: 'true'
        get_responses: 'false'
        QandAs: []
        answer_num: 0
        choices_so_far: []
        current_question: ''
        survey_mode: survey_mode

        surveys: []
        title: ''
        reason: ''
        survey_id: ''

        msg: ''
        input_err_msg: ''
      }
      @refs = {

      }

  componentDidMount: () =>
      if @state.survey_mode is 'edit_survey'
          @getSurveyList('edit_survey')

  componentDidUpdate: (prevProps) =>
      if @props.do_this != prevProps.do_this

          survey_mode = 'show_button' # when in doubt, ask
          if @props.do_this is 'make_survey'
              survey_mode = 'build_survey'
          else if @props.do_this is 'edit_survey'
              survey_mode = 'edit_survey'
          else if @props.do_this is 'updatedSurveyableId'
              survey_mode = 'saved'
          @setState({
              ask_for_question: 'true'
              get_responses: 'false'
              QandAs: []
              answer_num: 0
              choices_so_far: []
              current_question: ''
              survey_mode: survey_mode
              surveys: []
              title: ''
              reason: ''
              survey_id: '' })

          if survey_mode is 'edit_survey'
              @getSurveyList('edit_survey')

  saveOrUpdateSurvey: =>
      # check to see if the user saved the last question or just hit "save survey"
      ask = @refs.newQ.value
      if (@state.QandAs.length is 0)
          @addToQandAs()
      else if (ask)
          lastQ = @state.QandAs[@state.QandAs.length-1]
          last_ask = lastQ.ask
          if ask != last_ask
              @addToQandAs()

      title = @refs.title.value
      if title.length is 0
          @missingValueError('Hang on... What is your survey title?')
          return
      reason = @refs.reason.value
      if @state.QandAs.length is 0
          return

      data =
        QandAs : @state.QandAs
        belongs_to : 'conference'
        projectid : @props.projectid
        title: title
        reason: reason
        stype: 'questions'
        survey_id: @state.survey_id

      if @state.survey_id is '' # means it's brand new
          common.saveSurvey(data, (result) =>
              if (result['surveyable_id'])
                  @setState({ survey_mode: 'saved' })
                  @props.updateSurveyableId(result['surveyable_id'])
              else if (result['error'])
                  @setState({
                    msg: result['error']
                    survey_mode: 'show_error' })
              else
                  @setState({ survey_mode: 'unknown_error' })
          )
      else
          # editing an existing survey
          common.updateSurvey(data, (result) =>
              if (result['msg'] is 'success')
                  @setState({ survey_mode: 'saved' })
              else if (result['error'])
                  @setState({
                    msg: result['error']
                    survey_mode: 'show_error' })
              else
                  @setState({ survey_mode: 'unknown_error' })
          )

  scrollWindow: =>
      elem = @refs.active_area
      if (elem)
          window.scrollTo(0, elem.offsetTop)

  showSurveyMaker: =>
      @setState({ survey_mode: 'build_survey' })

  addToSurveyByShortID: (question) =>
      current_QandAs = @state.QandAs
      len = current_QandAs.length
      current_QandAs.push({
          'aorder': len
          'ask': question.ask
          'atype': 'multiple_choice'
          'choices': question.choices,
          'id': question.id })
      @setState({
          Qnum: len + 1
          QandAs: current_QandAs
          ask_for_question: 'true'
          get_responses: 'false'
          choices_so_far: []
          current_question: '' }, @scrollWindow)

  missingValueError: (option) =>
      @setState({ input_err_msg: option})

  addToQandAs: =>
      current_QandAs = @state.QandAs
      len = current_QandAs.length
      ask = @refs.newQ.value
      if ask.length is 0
          @missingValueError('Oops! What is the question?')
          return
      choices = @state.choices_so_far
      if choices.length is 0
          @missingValueError('Enter a few choices for this question.')
          return
      current_QandAs.push({
          'aorder': len
          'ask': ask
          'atype': 'multiple_choice'
          'choices': choices })
      @refs.newQ = ''
      @setState({
          Qnum: len + 1
          QandAs: current_QandAs
          ask_for_question: 'true'
          get_responses: 'false'
          choices_so_far: []
          current_question: '' }, @scrollWindow)

  clearChoiceForm: =>
      @refs.choice.value = ''

  addToResponses: =>
      choices_so_far = @state.choices_so_far
      new_choice = @refs.choice.value
      choices_so_far.push(new_choice)
      @setState({
            choices_so_far: choices_so_far
        }, @clearChoiceForm )

  removeResponse: (choice) =>
      choices_so_far = @state.choices_so_far
      index = choices_so_far.indexOf(choice)
      if index > -1
          choices_so_far.splice(index, 1)
          @setState({ choices_so_far: choices_so_far })

  removeQuestion: (question) =>
      current_QandAs = @state.QandAs

      if (question.ask_id)
          ask_id = question.ask_id

          current_QandA_ids = []
          for item in current_QandAs
              current_QandA_ids.push(item.ask_id)
          index = current_QandA_ids.indexOf(ask_id)
          if index > -1
              current_QandAs.splice(index, 1)
      else
          # new, unsaved question, so no id yet, go by text
          current_QandA_asks = []
          for item in current_QandAs
              current_QandA_asks.push(item.ask)
          index = current_QandA_asks.indexOf(question.ask)
          if index > -1
              current_QandAs.splice(index, 1)

      # redo aorder for updated QandAs
      aorder = 0
      for item in current_QandAs
          item.aorder = aorder
          aorder = aorder + 1

      @setState({ QandAs: current_QandAs })


  getResponses: =>
      @setState({
          ask_for_question: 'false'
          get_responses: 'true'
          current_question: @refs.newQ.value })

  getSurveyList: (mode) =>

      # clear stuff from previous locally saved surveys, to avoid making a
      # duplicate survey
      @setState({
                ask_for_question: 'true'
                get_responses: 'false'
                QandAs: []
                answer_num: 0
                choices_so_far: []
                current_question: ''
                survey_mode: 'show_button'

                surveys: []
                title: ''
                reason: ''
                survey_id: ''
                })

      if (@props.surveyable_id)
          data =
            surveyable_id: @props.surveyable_id
            add_ons: 'response_counts'

          common.getSurveyList(data, (result) =>
              msg = ''
              if result['survey_list'].length is 0
                  msg = 'No surveys yet!'
              @setState({
                    survey_mode: mode
                    surveys: result['survey_list']
                    msg: msg
                    })
          )

      else
          @setState({
              survey_mode: mode
              surveys:[]
              msg: 'No surveys yet!'})

  loadThisSurvey: (survey) =>
      # Load the survey
      # Recreate QandAs array
      # put mode back into "build_survey"
      # communicate to backend this is an updated survey, not a new one!
      # Also: dont allow editing of protected asks

      data =
        survey_id: survey.id

      common.getSurvey(data, (result) =>
          current_QandAs = []
          len = 0

          asks = result['full_survey'].asks
          for ask in asks
            choice_contents = []
            for choice in ask.choices
              choice_contents.push(choice.content)
            current_QandAs.push(
                {
                    'aorder': len
                    'ask': ask.question
                    'ask_id': ask.id
                    'atype': 'multiple_choice'
                    'choices': choice_contents })
            len = len+1

          @setState({
              survey_mode: 'build_survey'
              Qnum: len + 1
              QandAs: current_QandAs
              ask_for_question: 'true'
              get_responses: 'false'
              choices_so_far: []
              current_question: ''
              title: survey.title
              reason: survey.reason
              survey_id: result['full_survey']['survey']['id'] }, @setRefsAndScroll)
      )

  deleteSurvey: (survey) =>
      data =
        survey_id: survey.id

      common.deleteSurvey(data, (result) =>
            updated_surveys = @state.surveys
            index = updated_surveys.indexOf(survey)
            if index > -1
                updated_surveys.splice(index, 1)
                @setState({ surveys: updated_surveys })
        )

  setRefsAndScroll: =>
    @refs.title.value = @state.title
    @refs.reason.value = @state.reason
    @scrollWindow

  render: =>
      if @state.ask_for_question is 'true'
          question =
              <span>
                  <div className="input-group">
                    <input type="text"
                           className="form-control form-txt-lg ml-3"
                           ref="newQ"
                           placeholder="Type a question..."
                           aria-label="new-question"
                           aria-describedby="basic-addon2">
                    </input>
                    <div className="input-group-append">
                      <button className="btn btn-primary btn-txt-lg mr-3"
                              onClick={@getResponses}>&nbsp;&nbsp;+&nbsp;&nbsp;</button>
                    </div>
                  </div>
              </span>

      if @state.ask_type is 'true'
          question_type =
              <div>
              </div>

      if @state.choices_so_far.length > 0
          current_responses =
              <div className='row no-gutters'>
                  <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                      <strong>&nbsp;</strong>
                  </div>
                  <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                      { @state.choices_so_far.map((choice) =>
                          <ChoiceRoll key={Math.random()}
                                      choice_word={choice}
                                      mode={'editable'}
                                      onClick={@removeResponse.bind(this, choice)} />
                      , this)}
                  </div>
              </div>


      if @state.get_responses is 'true'
          ask_one_response =
                <div className='container fluid'>
                    { current_responses }
                    <div className='row no-gutters'
                         ref='active_area'>
                      <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                          <strong>Answer:</strong>
                      </div>
                      <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                          <div className="input-group">
                            <input type="text"
                                   className="form-control form-txt-lg"
                                   ref='choice'
                                   placeholder="Type a possible response..."
                                   aria-label="new-response"
                                   aria-describedby="basic-addon2">
                            </input>
                            <div className="input-group-append">
                              <button className="btn btn-primary btn-txt-lg mr-3"
                                     onClick={@addToResponses}>&nbsp;&nbsp;+&nbsp;&nbsp;</button>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
          question =
              <div>
                  <div className="input-group">
                    <input type="text"
                           className="form-control form-txt-lg"
                           ref="newQ"
                           defaultValue={ @state.current_question }
                           aria-label="new-question">
                    </input>
                  </div>
              </div>

      if @state.QandAs.length > 0
          saved_QandAs =
                <div className='row no-gutters mb-3'>
                    <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                        <strong>&nbsp;</strong>
                    </div>
                    <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                        { @state.QandAs.map((question) =>
                            <SurveyRoll key={Math.random()}
                                        question={question}
                                        mode={'editable'}
                                        onClick={@removeQuestion.bind(this, question)} />
                        , this)}
                    </div>
                </div>
      else
          saved_QandAs =
              <div className='row no-gutters'>
                  Enter a question. For each question, give a list of
                  possible answers.
              </div>

      if @state.survey_mode is 'build_survey'
          <div>
              <div className='container fluid'>

                  <div className='row no-gutters'>
                      <CommonAsks addToSurveyByShortID={ @addToSurveyByShortID }/>
                  </div>

                  <div className='row no-gutters'>
                    <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                        <strong>Title:</strong>
                    </div>
                    <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                        <h2>
                          <textarea ref="title"
                                    cols="40" rows="2"
                                    placeholder={'Survey title'}></textarea>
                        </h2>
                    </div>
                  </div>

                  <div className='row no-gutters'>
                    <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                        <strong>Description:</strong>
                    </div>
                    <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                        <h2>
                          <textarea ref="reason"
                                    cols="40" rows="2"
                                    placeholder={'The reason for your survey'}></textarea>
                        </h2>
                    </div>
                  </div>

                  { saved_QandAs }

                  <div className='row no-gutters'>
                    <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                        <strong>Question { @state.QandAs.length + 1 }:</strong>
                    </div>
                    <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                        { question }
                    </div>
                  </div>

                  { ask_one_response }

                  <br />
                  <button className='btn btn-primary'
                          onClick={@addToQandAs}>
                      Next question
                  </button>

                  <button className='btn btn-danger'
                          onClick={@saveOrUpdateSurvey}>
                      All done, save survey
                  </button><strong className='text-danger ml-2'>{ @state.input_err_msg }</strong>

              </div>
          </div>
      else if @state.survey_mode is 'show_button'
          <div>
              As the creator or chair, you can:&nbsp;
              <button className='btn btn-primary mr-2'
                      onClick={@showSurveyMaker}>
                  Build a new survey
              </button>
              <button className='btn btn-primary'
                      onClick={ @getSurveyList.bind(this, 'edit_survey') }>
                  Edit a survey
              </button>
          </div>
      else if @state.survey_mode is 'edit_survey'
          <div>
              <div>
                  <strong>Pick a survey to edit</strong>
              </div>
              { @state.msg }
              { @state.surveys.map((survey) =>
                  <SurveyOverviewRoll key={survey.id}
                              title={survey.title}
                              reason={survey.reason}
                              mode={'response_overview'}
                              surveyid={survey.id}
                              deleteSurvey={ @deleteSurvey.bind(this, survey) }
                              response_count={survey.response_count}
                              onClick={@loadThisSurvey.bind(this, survey)} />
              , this)}
          </div>
      else if @state.survey_mode is 'saved'
          <div>
              Good job, your survey is ready!
          </div>
      else
          <div>
              { @state.msg }
          </div>
