
module.exports = class Abstract extends React.Component
  displayName: 'Abstract'

  componentDidMount: () =>
    if @props.editor is 'MathJax'
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, @domElement])

  componentDidUpdate: (prevProps) =>
    if @props.abstract != prevProps.abstract
        if @props.editor is 'MathJax'
          window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, @domElement])

  setDOMRef: (domElement) =>
    @domElement = domElement

  render: =>
      if @props.editor is 'MathJax'
        <span className='mathjax new_lines_enabled'
              ref={@setDOMRef}
              dangerouslySetInnerHTML={{__html: @props.abstract }}>
        </span>
      else
        <span className='new_lines_enabled'
              dangerouslySetInnerHTML={{__html: @props.abstract }}>
        </span>
