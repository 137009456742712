common = require('./common')
Link = require('react-router-dom').Link
EditorChoice = require('./editor_choice')
GetWriting = require('./get_writing')
SocialSharing = require('./social/social_sharing')
TagNoteRoll = require('./tags/tag_note_roll')
Title = require('./titles/title')
Writing = require('./writing')

module.exports = class ProjectHeader extends React.Component
  displayName: 'ProjectHeader'

  constructor: (props) ->
      super(props)

      pword = @props.project.ptype
      if @props.project.ptype is 'personal'
          pword = 'project'

      @state = {
          header_title: @props.project.title
          header_motive: @props.project.motive
          proj_editor: @props.project.editor
          mode: 'reading'
          loaded_wordlist: []
          header_wordlist: []
          perms_text: ''
          openEditor: true
          filtered_tags: []
          show_tags: true
          user_can_edit: 'false'
          show_caution: 'false'
          view_mode: 'preview'
          pword: pword
      }
      @titleWritingRef = React.createRef()
      @motiveWritingRef = React.createRef()
      @editorChoiceRef = React.createRef()

  cancelUpdate: () =>
      @setState({mode: 'reading'})

  updateProjectFields: () =>
      title = @titleWritingRef.current.getValue()
      motive = @motiveWritingRef.current.getValue()
      editor = @editorChoiceRef.current.getEditor()

      data =
          projectid: @props.project.id
          project_title: title
          project_motive: motive
          updated_perm_tags: @state.header_wordlist
          editor: editor

      common.submitProjectFields(data, (result) =>
          loaded_wordlist = [ ]
          if result.perms
              for perm in result.perms
                  loaded_wordlist.push(perm.word)
          @setState({
              loaded_wordlist: loaded_wordlist
              header_wordlist: loaded_wordlist
              perms: result.perms
              header_title: title
              header_motive: motive
              mode: 'reading'
              tags: result.tags
              proj_editor: editor
          })
          @makeFormattedPerms(loaded_wordlist)
      )

  deleteProject: () =>
      data =
        projectid: @props.project.id

      common.deleteProject(data, (result) =>
      )
      window.location = '/profile/' + @props.project.user_id

  setShowTags: (value) ->
      @setState({show_tags: Boolean(value)})

  setViewMode: (value) ->
      @setState({ view_mode: value })

  setMode: (value) ->
      @setState({mode: value})
      if value is 'editing'
          @setState({ mode: value, view_mode: 'full' })

  addPerm: (tagword) =>
      if tagword not in @state.header_wordlist
          updated_wordlist = @state.header_wordlist
          updated_wordlist.push(tagword)
          @setState({header_wordlist: updated_wordlist})

  subtractPerm: (tagword) =>
      if tagword in @state.header_wordlist
          updated_wordlist = @state.header_wordlist
          pos = updated_wordlist.indexOf(tagword)
          updated_wordlist.splice(pos,1)
          @setState({header_wordlist: updated_wordlist})

  makeFormattedPerms: (wordlist) =>
      formatted_perms = ''
      index = wordlist.indexOf('_open_project_perm')
      if index > -1
          formatted_perms = 'open project'
      else
          formatted_perms = 'private project'

      @setState({perms_text: formatted_perms})

  makefilteredTags: =>
      filtered_tags = []
      for tag in @props.project.tags
          if tag.word.substr(0,1) != '_'
              filtered_tags.push(tag)
      @setState({filtered_tags: filtered_tags})

  componentDidMount: () =>
      loaded_wordlist = [ ]
      if @props.project.perms
        @makefilteredTags()
        for perm in @props.project.perms
            loaded_wordlist.push(perm.word)
      @setState({loaded_wordlist: loaded_wordlist})
      @setState({header_wordlist: loaded_wordlist})
      # Autosize(document.getElementById('project_motive'))
      # Autosize(document.getElementById('project_title'))
      @makeFormattedPerms(loaded_wordlist)

      if @props.userid == @props.project.user_id
          @setState({user_can_edit: 'true'})
      else if @props.roles?
          rolewords = []
          for role in @props.roles
              rolewords.push(role.role)
          if 'chair' in rolewords
              @setState({user_can_edit: 'true'})

  componentDidUpdate: (prevProps) =>
      if @props.userid != prevProps.userid
        if @props.userid == @props.project.user_id
            @setState({user_can_edit: 'true'})

  openProjPerm: =>
      styles = {display:'none'}
      if  '_open_project_perm' in @state.header_wordlist
          styles = {display:'block'}
      return styles

  closedProjPerm: =>
      styles = {display:'none'}
      if  '_open_project_perm' not in @state.header_wordlist
          styles = {display:'block'}
      return styles

  openSubmPerm: =>
      styles = {display:'none'}
      if  '_open_submission_perm' in @state.header_wordlist
          styles = {display:'block'}
      return styles

  closedSubmPerm: =>
      styles = {display:'none'}
      if  '_open_submission_perm' not in @state.header_wordlist
          styles = {display:'block'}
      return styles

  askIfSure: =>
      @setState({show_caution: 'true'})

  renderTagsList: =>
      if @state.mode != 'reading'
          <span></span>
      else
          # if @state.mode is 'reading'
          if @props.project.tags and @state.filtered_tags.length > 0
              Ltags = @props.project.tags.length
              if @state.show_tags
                  <div className='row m-2'>
                      <strong className='pointnine_em mr-2'>Tags: </strong>
                      {@state.filtered_tags.map((tag, ctr) =>
                          if ctr+1 < Ltags
                              comma=<span>,</span>
                          <TagNoteRoll key={tag.id}
                              tag={tag}
                              projectid={@props.project.id}
                              note_type='project'
                              comma={comma} />
                        )}
                      &nbsp;-&nbsp;
                      <button className="btn btn-link btn-txt-sm"
                          onClick={@setShowTags.bind(this, false)}>hide</button>
                  </div>
              else
                  <div className='row m-2'>
                      <strong className='pointnine_em mr-2'>Tags: </strong>
                      <button className="btn btn-link btn-txt-sm"
                          onClick={@setShowTags.bind(this, true)}>show</button>
                  </div>
          else
              <div className='row m-2'>
                  <strong className='pointnine_em mr-2'>Tags: </strong> none
              </div>

  renderButtonRow: =>
      if @state.mode is 'reading' and @state.user_can_edit is 'true'
          <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                <span className='float-left'>
                    <strong>Project</strong> created by: &nbsp;
                    <Link to={'/profile/' + @props.project.user_id}>
                        <button className='btn btn-link'>{@props.project.user_name}</button>
                    </Link>
                </span>
                <span className="float-right">
                    <button className="btn btn-secondary btn-txt-sm" onClick={@setMode.bind(this, 'editing')}>
                          Edit
                    </button>
                </span>
              </div>
          </div>
      else if @state.mode is 'reading'
          <div className='row m-1'>
              <div className='proj_inline_L'>
                  <strong>Project</strong> created by: &nbsp;
                  <Link to={'/profile/' + @props.project.user_id}>
                      <button className='btn btn-link'>{@props.project.user_name}</button>
                  </Link>
              </div>
              <div className='proj_inline_R'></div>
              <br />
          </div>
      else
          # @state.mode is 'editing'
          if @state.show_caution is 'true'
              delete_if_you_are_sure =
                  <button className="btn btn-danger btn-txt-sm ml-3" onClick={@deleteProject}>
                      Are you sure?
                  </button>
          else
              delete_if_you_are_sure =
                  <button className="btn btn-danger btn-txt-sm ml-3" onClick={@askIfSure}>
                      delete project
                  </button>

          <div className='row m-2'>
              <div className='proj_inline_L'>
                  Project
              </div>
              <div className='proj_inline_R'>
                  {delete_if_you_are_sure}
              </div>
              <div className='proj_inline_R'>
                  <button className="btn btn-secondary btn-txt-sm" onClick={@cancelUpdate}>
                      cancel
                  </button>
              </div>
              <div className='proj_inline_R'>
                  <button className="btn btn-secondary btn-txt-sm" onClick={@updateProjectFields}>
                      save
                  </button>
              </div>
          </div>

  renderEditorChoiceRow: =>
      if @state.mode is 'reading'
          <span></span>
      else
          <div className='row ml-0 mr-2 mt-2 mb-0'>
              <EditorChoice ref={@editorChoiceRef} editor={@state.proj_editor}/>
          </div>

  renderTitleRow: =>
      projUrl = 'https://zerodivzero.com/' + @state.pword + '/' + @props.project.id + '/titles'

      if @state.mode is 'reading'
          <div className='row m-1'>
              <span className='mt-2 mb-2 font-txt-lg-darkgrey'>
                  <Title title={@state.header_title}
                      editor={@state.proj_editor}/>
                  <SocialSharing shareUrl={projUrl}
                      title={ @state.header_title }/>
              </span>
          </div>
      else
          <div className='row m-1'>
              <strong className='pointnine_em mr-2'>Project title:</strong>
              <GetWriting ref={@titleWritingRef}
                  defaultValue={@state.header_title}
                  rows='2' />
          </div>

  renderPermissionsRow: =>
      if @state.mode is 'reading'
          <div className='row ml-2 mr-2'>
              <strong className='pointnine_em mr-2'>Permissions:</strong>
              <span className="">{@state.perms_text}</span>
          </div>
      else
          <span>
          <div className='row mr-2 ml-2'>
              <strong className='pointnine_em mr-2'>Permissions settings:</strong>
          </div>
          <div className='row mr-2 ml-2'>
              <div style={@openProjPerm()}>
                  <button className="proj_open_btn"
                      onClick={@addPerm.bind(this,"_open_project_perm")}>
                      Yes
                  </button>
                  <button className="proj_rightpad_btn"
                      onClick={@subtractPerm.bind(this,"_open_project_perm")}>
                      No
                  </button>
                  Can anyone see this project?
              </div>
              <div style={@closedProjPerm()}>
                  <button className="proj_rightpad_btn" onClick={@addPerm.bind(this,"_open_project_perm")}>Yes</button>
                  <button className="proj_open_btn" onClick={@subtractPerm.bind(this,"_open_project_perm")}>No</button>
                  Can anyone see this project?
              </div>
          </div>
          </span>

  bigPicture: () =>
      if (@state.mode is 'reading') and (@state.user_can_edit is 'false') and !(@state.header_motive)
          <span></span>
      else if (@state.mode is 'reading') and (@state.user_can_edit is 'true') and !(@state.header_motive)
          <div className='row m-2'>
              <strong className='pointnine_em mr-2'>Short description:</strong>
              Add something!
          </div>
      else if (@state.mode is 'reading')
          <div className='proj_motive_CKEDitor m-2'>
              <Writing editor={@state.proj_editor}
                  writing={@state.header_motive} />
          </div>
      else
          # @state.mode is 'editing'
          <span>
              <div className='row m-2'>
                  <strong className='pointnine_em mr-2'>Short description:</strong>
              </div>
              <div className='m-2'>
                  <div className='proj_motive_CKEDitor'>
                      <GetWriting ref={@motiveWritingRef}
                          defaultValue={@state.header_motive}
                          rows='2' />
                  </div>
              </div>
          </span>

  saveButton: () =>
      if @state.mode is 'editing'
          <div className="m-2">
              <button className="btn btn-secondary btn-txt-sm"
                      onClick={@updateProjectFields}>Save</button>
          </div>

  linkToAbout: () =>
      <span className='btn-txt-sm'>
          (or visit the&nbsp;
          <Link to={'/' + @state.pword + '/' + @props.project.id + '/about'}>
              <button className="btn btn-link mt-0 mb-0 pt-1 pb-1 mr-0 pr-1">
                  About
              </button>
          </Link><span className='ml-0'>page for more)</span>
      </span>


  render: =>

      if @state.view_mode is 'full'
          <div className='container-fluid light_gray'>
              <br />
              { @renderButtonRow() }
              { @renderEditorChoiceRow() }
              { @renderTitleRow() }
              { @bigPicture() }
              { @renderTagsList() }
              { @renderPermissionsRow() }
              { @saveButton() }
              <button className="btn btn-link btn-txt-sm mr-0 pr-1"
                  onClick={@setViewMode.bind(this, 'preview')}>
                  minimize
              </button>
              { @linkToAbout() }
              <br />
              <br />
          </div>
      else
          <div className='container-fluid light_gray'>
              <br />
              { @renderButtonRow() }
              { @renderEditorChoiceRow() }
              { @renderTitleRow() }
              { @bigPicture() }
              { @saveButton() }
              <button className="btn btn-link ml-2"
                  onClick={@setViewMode.bind(this, 'full')}>
                  more details...
              </button>
              <br />
              <br />
          </div>
