common = require('./common')
Link = require('react-router-dom').Link
AcceptedToConferenceRoll = require('./accepted_to_conference_roll')
NoteClickToShowDetails = require('./note_click_to_show_details')
ProjectItems = require('./project_items')
TitleButton = require('./titles/title_button')

module.exports = class ProjectNoteSummary extends React.Component
  displayName: 'ProjectNoteSummary'

  constructor: (props) ->
      super(props)
      note = @props.note
      show_motive = 'preview'
      if @props.show_motive
          show_motive = @props.show_motive
      show_origin = 'false'
      if @props.show_origin
          show_origin = @props.show_origin
      @state = {
        data: {noteid: note.id, votevalue: note.myvote}
        user_is_author: 'false'
        show_motive: show_motive
        show_origin: show_origin
      }

  componentDidMount: =>
      author_userids = []
      for author in @props.note.authorcontacts
          author_userids.push(author.user_id)

      if (@props.login_id == @props.project_owner_userid) or (@props.login_id in author_userids)
          @setState({ user_is_author: 'true' })

  componentDidUpdate: (prevProps) =>
      if @props.login_id != prevProps.login_id

        author_userids = []
        for author in @props.note.authorcontacts
            author_userids.push(author.user_id)

        if (@props.login_id == @props.project.user_id) or (@props.login_id in author_userids)
            @setState({ user_is_author: 'true' })

  moveThis: (direction) =>

      if (direction is 'up') and (@props.note.ordering is 0)
          return
      if (direction is 'down') and (@props.note.ordering is @props.Lnotes-1)
          return

      data =
        noteid: @props.note.id
        direction: direction

      common.updateNoteOrderingForProject(data, (result) =>
            neworder = {direction: direction, note: @props.note}
            @props.reorderTitles(neworder)
            if result.result.outcome is 'success'
                return
            else
                console.log("error msg=", result)
        )

  reorderTitle: () =>
      if (@state.user_is_author is 'true')
          if (@props.Lnotes is @props.note.ordering+1)
              <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  <div className='row no-gutters'>
                      <button
                          onClick={@moveThis.bind(this, 'up')}>
                          &uarr;
                      </button>
                  </div>
                  <div className='row no-gutters'>
                  </div>
              </div>
          else if (@props.note.ordering is 0)
              <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  <div className='row no-gutters'>
                      <button
                          onClick={@moveThis.bind(this, 'down')}>
                          &darr;
                      </button>
                  </div>
                  <div className='row no-gutters'>
                  </div>
              </div>
          else
              <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  <div className='row no-gutters'>
                      <button
                          onClick={@moveThis.bind(this, 'up')}>
                          &uarr;
                      </button>
                  </div>
                  <div className='row no-gutters'>
                      <button
                          onClick={@moveThis.bind(this, 'down')}>
                          &darr;
                      </button>
                  </div>
              </div>

  titleButton: () =>
      <Link to={ '/' + @props.note.note_type + '/' + @props.note.project_id + '/title/' + @props.note.paperseries_id }>
        <TitleButton title={@props.note.title}
                     editor={@props.note.editor} />
      </Link>

  render: =>


      if (@props.note.accepting_conferences) and (@props.note.accepting_conferences.length > 0)
          acceptance_trophy =
              <span>
                  <div className="btn-group-vertical">
                  <button className="btn btn-link">
                      <i className="fa fa-trophy text-success"></i></button>
                  </div>
              </span>

          acceptance_line =
              <div className='row no-gutters'>
                  Accepted to:&nbsp;
                  {@props.note.accepting_conferences.map((conference_infolist) =>
                        <AcceptedToConferenceRoll key={conference_infolist[0]}
                                  conference_infolist={conference_infolist} />
                  )}
              </div>

      if @state.user_is_author is 'true'
          instructions_to_authors =
              <div>
                  Click title to make edits
              </div>
      else
          instructions_to_authors =
              <span></span>

      if @state.show_origin is 'true'
          if @props.note.note_type is 'conference'
              project_items =
                  <span>
                      <bold>Conference</bold> &nbsp; <ProjectItems note={@props.note} />
                  </span>
          if @props.note.note_type is 'project'
              project_items =
                  <span>
                      <bold>Personal Project</bold> &nbsp; <ProjectItems note={@props.note} />
                  </span>


      <div className='container-fluid note_infoDiv'>
          <div>
              <div className='row no-gutters'>
                  { project_items }
              </div>

              <div className='row no-gutters'>
                  { @reorderTitle() }
                  <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                      { @titleButton() }
                      { instructions_to_authors }
                  </div>
                  <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                        { acceptance_trophy }
                  </div>
              </div>
              { acceptance_line }
          </div>

        <NoteClickToShowDetails key={@props.note.project_id}
            note={@props.note}
            roles={@props.roles}
            reviewq={@props.reviewq}
            user_is_author={@state.user_is_author}
            show_motive={@state.show_motive} />

      </div>
