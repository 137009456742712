common = require('./common')
CreateNoteMetadata = require('./create_title/create_note_metadata')
HeaderBar = require('./header_bar')
Loading = require('./loading')
NavGeneral = require('./nav_general')
ProjectHeader = require('./project_header')
ProjectNoteDetails = require('./project_note_details')
RolesBarProject = require('./roles/roles_bar_project')

module.exports = class ProjectNote extends React.Component
  displayName: 'ProjectNote'

  constructor: (props) ->
      super(props)
      @state = {
          paperseriesid:@props.match.params.paperseriesId,
          note : '',
          project: @props.match.params.projectId
          roles: []
          userid: ''
          mode: 'loading'
          my_accepted_roles: []
          err_msg: ''
      }
      @makeNewTitleRef = React.createRef()

  componentDidMount: =>
      data =
          projectid : @props.match.params.projectId
          paperseriesid : @props.match.params.paperseriesId

      common.projectNote(data, (result) =>

          if result.err_msg
              @setState({
                err_msg: 'Hm. I am having trouble finding this. Ask for help@0div0.com. Send the URL and a quick note about what you were trying to do.'
                mode: 'view_error'
                })
              return

          my_accepted_roles = []
          for role in result['roles']
              if role.role_accepted is 'true'
                  my_accepted_roles.push(role.role)

          @setState({
              project: result['project'],
              note: result['note'],
              roles: result['roles'],
              my_accepted_roles: my_accepted_roles
              userid: result['userid'],
              mode: 'viewing'
          })

          if result['note']['title']
              document.title = result['note']['title']
          else
              document.title = result['project']['title']
      )

  componentDidUpdate: (prevProps) =>
      if @props.match.params.projectId != prevProps.match.params.projectId or \
         @props.match.params.paperseriesId != prevProps.match.params.paperseriesId
          window.location.reload()

  setMode: (mode_option) =>
      if mode_option is 'viewing'
          # I don't think this ever gets called -- page location changes
          @setState({ mode: 'viewing' }, @setDefaultTxt)
      else if mode_option is 'cancelled'
          @setState({ mode: 'viewing'}, @setDefaultTxt)
      else
          @setState({ mode: mode_option })

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  getUserNoteDetails: =>
      @setState({
          mode : 'create_note_metadata'}, @scrollToHere(@makeNewTitleRef) )

  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  projectHeader: () =>
      <span>
        <ProjectHeader key={@state.project.user_id}
                       project={@state.project}
                       userid={@state.userid}
                       roles={@state.roles} />
      </span>

  rolesBar: () =>
      if @state.roles
          roles_bar =
              <RolesBarProject project={@state.project}
                  my_accepted_roles={@state.my_accepted_roles}
                  roles={@state.roles}
                  userid={@state.userid}
                  view={'full'}
                  setUpdatedRoles={@setUpdatedRoles}
                  getUserNoteDetails={@getUserNoteDetails} />

      <span>
          { roles_bar }
      </span>

  problemMsg: () =>
      if @state.note is 'removed'
          <div>
            <div className='proj_listing'>
              <br />
              <h1>This title has been deleted</h1>
              <br />
            </div>
          </div>
      else if @state.mode is 'some other error'
          <div>
              <br />
              <h2>{@state.error_msg}</h2>
          </div>

  makeNewPaper: () =>
      if @state.mode is 'create_note_metadata'
          <div ref={@makeNewTitleRef}
              className='container-fluid border'>
              <CreateNoteMetadata project={@state.project}
                  setMode={@setMode}
                  version={'new'}/>
          </div>

  showPaper: () =>
      if @state.mode is 'loading'
          <Loading />
      else if @state.note.id and @state.mode != 'create_note_metadata'
          <div>
            <ProjectNoteDetails key={@state.note.id}
                note={@state.note}
                roles={@state.roles}
                login_id={@state.userid}
                project={@state.project} />
          </div>

  errMsg: () =>
    if @state.mode is 'view_error'
        <div className='row no-gutters mt-3 mr-3'>
            {@state.err_msg}
        </div>


  render: =>


      if @state.project
          nav_vert =
              <div className='col-md-2 col-lg-2 border d-none d-md-block'>
                  <NavGeneral
                      id={@state.project.id}
                      ptype={@state.project.ptype}
                      caller={'titles'}
                      subset={@state.subset}
                      getThisSortType={@getThisSortType}
                      userid={@state.userid}
                      styling={'col-12'} />
              </div>
          nav_horiz =
              <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
                  <NavGeneral
                      id={@state.project.id}
                      ptype={@state.project.ptype}
                      caller={'titles'}
                      subset={@state.subset}
                      getThisSortType={@getThisSortType}
                      userid={@state.userid}
                      styling={'mr-2'} />
              </div>

      <div>
          <HeaderBar />
          <div className='container-fluid pr-0 pl-0 border'>
              { @projectHeader() }
              <div className='row no-gutters'>
                  {nav_vert}
                  {nav_horiz}
                  <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                      { @rolesBar() }
                      { @problemMsg() }
                      { @showPaper() }
                      { @makeNewPaper() }
                      { @errMsg() }
                  </div>
              </div>
          </div>
      </div>
