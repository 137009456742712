common = require('./common')
ConferenceSubmissionUserOwnedNoteLine = require('./conference_submission_user_owned_note_line')

module.exports = class RolesBar extends React.Component
  displayName: 'ConferenceDirectUpload'

  constructor: (props) ->
      super(props)
      @state = {
          submit_paper: 'false'
          submit_slideset: 'false'
          submit_poster: 'false'
      }

  componentDidMount: =>
      document.getElementById("myCheck").click()

  chosenNote: (info) =>

      added_tag_ids = [ ]

      for tag in info.added_tag_list
          added_tag_ids.push(tag.id)

      data =
          conferenceid : @props.conference.id
          noteid : info.noteid
          submit_paper : info.submit_paper
          submit_slides: info.submit_slideset
          submit_poster: info.submit_poster
          added_tag_ids: added_tag_ids

      @setState({ mode : 'loading' })

      common.submitUserTitleToConference(data, (result) =>

          # updated to also submit to lab_group!
          # the goto looks what the original project type is.

          goto = '/' + @props.conference.ptype + '/' + @props.conference.id + '/titles'
          loc = window.location
          loc = loc.href.replace(loc.origin, '')
          # sometimes an extra ? is inserted, so get rid of it if there
          n = loc.indexOf('?')
          if n != -1
              loc = loc.substring(0, n)

          if loc is goto
              window.location.reload()
          else
              window.location = goto
      )

  finalizeButton: () =>
      <button className="btn btn-primary btn-lg mr-2"
           data-toggle="modal"
           data-target="#finalStepModalLong"
           id="myCheck" >
           Choose options and submit
      </button>

  finalStepDirectUpload: () =>
      <div className="modal fade"
          id="finalStepModalLong"
          tabindex="-1"
          role="dialog"
          aria-labelledby="FinalizeSingleClickSubmission"
          aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <span className=""
                  id="exampleModalLongTitle">Choose options
              </span>
              <button type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

                This is the correct paper (click):
                <span className='onepointtwo_em'>
                    <ConferenceSubmissionUserOwnedNoteLine
                        note={@props.note}
                        conf={@props.conference}
                        submit_paper={@state.submit_paper}
                        submit_slideset={@state.submit_slideset}
                        submit_poster={@state.submit_poster}
                        onClick={@chosenNote} />
                </span>

            </div>
            <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal">cancel
              </button>
            </div>
          </div>
        </div>
      </div>


  render: =>

      <div className='row no-gutters m-3'>
          <div className='text-center'>
              { @finalizeButton() }
          </div>
          { @finalStepDirectUpload() }
      </div>
