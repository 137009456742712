common = require('../common')
SurveyRoll = require('./survey_roll')

module.exports = class CommonAsks extends React.Component
  displayName: 'CommonAsks'

  constructor: (props) ->
      super(props)
      @state = {
          QandAs: []
      }

  componentDidMount: =>

      # these correspond to: gender, career stage, ethnicity
      # front/backend short_ids are harmonized
      short_ids = {'6b6c', '59c1', '870d'}

      data =
          short_ids: short_ids

      common.getAsksAndChoices(data, (result) =>

          QandAs = []
          # this is so survey_roll => choice_roll can be reused (which uses the wording for choice)
          for old_ask in result['QandAs']
            choices = []
            for old_choice in old_ask.choices
              choices.push(old_choice.content)
            ask =
                id: old_ask.id
                short_id: old_ask.short_id
                ask: old_ask.ask
                aorder: old_ask.aorder
                atype: old_ask.atype
                choices: choices
            QandAs.push(ask)
          @setState({ QandAs: QandAs })
      )

  removeThisCommonAsk: (question) =>
      QandAs = @state.QandAs
      index = QandAs.indexOf(question)
      if index > -1
          QandAs.splice(index, 1)
          @setState({ QandAs: QandAs })

      @props.addToSurveyByShortID(question)


  render: =>

      if @state.QandAs.length > 0
          common_QandAs =
                <div className='row no-gutters mb-3'>
                    { @state.QandAs.map((question) =>
                        <SurveyRoll key={Math.random()}
                                    question={question}
                                    mode={'common'}
                                    onClick={ @removeThisCommonAsk.bind(this, question) } />
                    , this)}
                </div>

      <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
          <p>
          Pick one of these common survey questions or make a new survey!
          </p>
          { common_QandAs }
          <p>
          The advantage of using existing questions is we can prefill
          answers for users. They might change their answers, of course.
          </p>
      </div>
