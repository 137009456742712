
module.exports = class TitleButton extends React.Component
  displayName: 'TitleButton'

  constructor: (props) ->
      super(props)
      @state = { title: @props.title }

  componentDidMount: () =>
      if @props.editor is 'MathJax'
          window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, @domElement])
      if (@props) and (@props.title.length < 1)
          title = "Untitled"
          @setState({ title: title })

  setDOMRef: (domElement) =>
    @domElement = domElement

  render: =>

      if @props.editor is 'MathJax'
        <span className="mathjax">
          <button className="btn btn-info btn-lg text-left btn-txt-lg"
                  ref={@setDOMRef}
                  onClick={@props.onClick}
                  dangerouslySetInnerHTML={{__html: @props.title }}>
          </button>
        </span>
      else
          <button className="btn btn-info btn-lg text-left btn-txt-lg"
                  onClick={@props.onClick}
                  dangerouslySetInnerHTML={{__html: @props.title }}>
          </button>
