
module.exports = class ConferenceFeeLineDeletable extends React.Component
  displayName: 'ConferenceFeeLineDeletable'

  render: =>
      <tr>
          <td>{ @props.fee.category } </td>
          <td>${ @props.fee.amount/100 } USD</td>
          <td><button className='btn btn-danger'
                      onClick={@props.onClick}>delete</button>
          </td>
      </tr>
