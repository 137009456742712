Link = require('react-router-dom').Link
Loading = require('../loading')
LocalLogin = require('./local_login')
HeaderBar = require('../header_bar')
common = require('../common')
UpdateUser = require('./update_user')

module.exports = class GoogleCallback extends React.Component
  displayName: 'GoogleCallback'

  constructor: (props) ->
    super(props)

    @state = {
        mode: 'waiting'
        social_user: {}
        name: ''
        username: ''
        password: ''
        msg: ''
        redirect: '/'
    }

  componentDidMount: () =>
      # Delete me.
      data = {}

      if localStorage
          redirect = localStorage.getItem('redirect')
          redirect = JSON.parse(redirect)
          localStorage.removeItem('redirect')
          @setState({ redirect: redirect })

        @setState({ redirect: redirect })
      common.googleOauth2callback(data, (result) =>
        if result.msg is 'no_local_user'
            @setState({
                mode: 'is_this_new_account'
                name: result.social_user.name
                username: result.social_user.username
                password: ''
                social_user: result.social_user })
        else if result.msg is 'logged_in'
            # send props up!
            # go back to starting point
            # history = window.history
            # history.go(-3)
            window.location = @state.redirect
        else
            @setState({ mode: 'show_msg', msg: result.msg })
      )

  isThisNewAccountMode: =>
      @setState({ mode: 'is_this_new_account' })

  makeNewLocalAccount: =>
      @setState({ mode: 'create_new_account' })

  addNewLoginToExistingAccount: =>
      @setState({ mode: 'show_local_login' })


  render: =>

      if @state.mode is 'waiting'
          <div>
            <HeaderBar />
            <br />
            <Loading />
          </div>
      else if @state.mode is 'is_this_new_account'
          <div>
            <HeaderBar />
            <br />
            <div className='container-fluid'>
                <div className='row no-gutters'>
                    Is this a new account? Or should we add this login method
                    to an existing account?
                </div>
                <br />
                <div className='row no-gutters'>
                    <div className='mx-auto'>
                        <button className='btn btn-outline-primary'
                            onClick={@makeNewLocalAccount}>
                            New</button>
                        &nbsp; &nbsp;
                        <button className='btn btn-outline-primary'
                            onClick={@addNewLoginToExistingAccount}>
                            Existing</button>
                    </div>
                </div>
            </div>
          </div>
      else if @state.mode is 'create_new_account'
          <div>
            <HeaderBar />
            <br />
            <div className='container-fluid'>
                <button className='btn btn-primary'
                    onClick={@isThisNewAccountMode}>&larr;&nbsp;Back</button>
                <br />
                <br />
                <div className='row no-gutters'>
                    We want to create a local user account as well,
                    just in case you need to log in manually.
                </div>
                <UpdateUser name={@state.name}
                    mode={'post_social_reg'}
                    social_user={@state.social_user} />
                <br />
                <button className='btn btn-primary'
                    onClick={@isThisNewAccountMode}>&larr;&nbsp;Back</button>
            </div>
          </div>
      else if @state.mode is 'show_local_login'
          <div>
            <HeaderBar />
            <br />
            <div className='container-fluid'>
                <div className='row no-gutters mb-3'>
                    <button className='btn btn-primary'
                        onClick={@isThisNewAccountMode}>&larr;&nbsp;Back</button>
                </div>
                <div className='row no-gutters'>
                    Login with your existing account (one time) so we can connect this login method to you.
                </div>
                <LocalLogin mode={'link_social_account'}
                    redirect={@state.redirect}
                    social_id=(@state.social_user.id)/>
            </div>
          </div>
      else
          <div>
            <HeaderBar />
            <br />
            <h1>{@state.msg}</h1>
          </div>
