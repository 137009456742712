Link = require('react-router-dom').Link
common = require('../common')
UploadCSVButton = require('./upload_csv_button')

module.exports = class EmailInvites extends React.Component
  displayName: 'EmailInvites'

  constructor: (props) ->
      super(props)
      @state = {
        mode : 'display'
      }
      @handEmailsRef = React.createRef()

  goToEmailTypeQuery: =>
      @setState({ mode : 'type query' })

  goToHandEntry: =>
      @setState({ mode : 'hand entry' })

  sendHandInvites: =>
      hand_emails = @handEmailsRef.current.value

      roletype = @props.roletype
      if @props.roletype is 'lab_lead'
          roletype = 'chair'

      data =
          projectid : @props.project.id
          hand_emails : hand_emails
          roletype : roletype

      common.sendAndSaveConferenceEmailHandInvites(data, (result) =>
            @setState({ mode : 'done! now more?' })
      )

  displayButtonText: () =>
      if @props.roletype is 'invited'
          return(
              <span>Invitations</span>
          )
      else if @props.roletype is 'chair'
          return(
              <span>Invite chairs</span>
          )
      else if @props.roletype is 'areachair'
          return(
              <span>Invite area chairs</span>
          )
      else if @props.roletype is 'keynote'
          return(
              <span>Invite keynotes</span>
          )
      else if @props.roletype is 'reviewer'
          return(
              <span>Invite reviewers</span>
          )
      else if @props.roletype is 'lab_lead'
          return(
              <span>Invite leads</span>
          )

  buttonText: () =>
      return(
        <span>Invite more?</span>
      )


  render: =>

    if @state.mode is 'display'
      <div>
          <button className="btn btn-primary"
                  onClick={@goToEmailTypeQuery}>
                  { @displayButtonText() }</button>
      </div>
    else if @state.mode is 'type query'
      <div>
          <button className="btn btn-primary"
                  onClick={@goToHandEntry}>
                  Enter emails</button>
          <UploadCSVButton key={@props.project.id}
                                 projectid={@props.project.id}
                                 roletype={@props.roletype}
                                 upload_content={'email_invites'} />
      </div>
    else if @state.mode is 'hand entry'
      <div>
        <input type="text"
               ref={@handEmailsRef}
               placeholder="Separate emails with a comma..."  />
        <button className="btn btn-secondary"
                onClick={@sendHandInvites}>Invite</button>
      </div>
    else if @state.mode is 'done! now more?'
      <div>
          Invitation(s) being sent!
          <button className="btn btn-primary"
                  onClick={@goToEmailTypeQuery}>
                  { @buttonText() }</button>
      </div>
