common = require('../common')
ConferenceNotesPanel = require('./conference_notes_panel')

module.exports = class ConferenceNotesChairPanel extends React.Component
  displayName: 'ConferenceNotesChairPanel'

  constructor: (props) ->
    super(props)
    projectid = @props.match.params.projectId
    @state = {
      projectid: projectid
      roles: []
      rolewords: []
    }

  componentDidMount: =>

      data =
          projectid: @props.match.params.projectId

      common.getUserRolesInProject(data, (result) =>
          @setState({ roles: result['rolejunctions'] })

          rolewords =  []
          for role in result['rolejunctions']
              rolewords.push(role.role)
          @setState({ rolewords: rolewords })
      )

  render: =>

      if (@state.projectid) and ('chair' in @state.rolewords)
        <span>
          <ConferenceNotesPanel projectid={@state.projectid}
                                panel_type={"chair"} />
        </span>
      else
        <span></span>
