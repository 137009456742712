HeaderBar = require('./header_bar')


module.exports = class NotFound extends React.Component
  displayName: 'NotFound'

  componentDidMount: () =>
      document.title = 'ZeroDivZero page not found'

  render: =>

    <div>
      <HeaderBar />
      <div className='header-div'>
        <div className='container-fluid'>
          <div className='row no-gutters'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <h1>Not found! Try again?</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
