common = require('../common')

module.exports = class UrlLinkRollEditable extends React.Component
  displayName: 'UrlLinkRollEditable'

  constructor: (props) ->
      super(props)
      if @props.url.body_blawb_id
          mode = 'blawb_view'
      else
          mode = 'url_link'
      @state = {
          mode: mode,
          delete_state: 'show_delete'
          files: [],
          blawbcontent: '',
          blawbid: ''
          url: ''
      }

  goToLink: () =>
      link = @props.url.link
      pre_link = link.slice(0,4)
      if pre_link is 'http'
          window.open(link)
      else
          window.open('http://' + link)

  removeLinkFromUser: () =>
      data =
        url_id : @props.url.id

      common.hideLink(data, (result) =>
         @setState({ mode: 'removed' })
      )

  deleteButton: =>
      if @state.delete_state is 'show_delete'
          <button className="btn btn-danger btn-txt-sm ml-2"
                  onClick={@askIfSureBeforeDelete}>
                  delete
          </button>
      else
          <button className="btn btn-danger btn-txt-sm ml-2"
                  onClick={@removeLinkFromUser}>
                  Are you sure?
          </button>

  askIfSureBeforeDelete: () =>
      @setState({ delete_state: 'ask_if_sure'})

  urlText: =>
      if (@props.url.link) and (@props.url.link.length > 50)
          return @props.url.link.slice(0,50) + '...'
      else if (@props.url.link)
          return @props.url.link

  captionText: =>
      if @props.url.caption.length > 0
          caption_text =
              <p className='p-0 m-0'>&#8212;{ @props.url.caption }</p>

  viewUrlBlawb: () =>
      @setState({mode: 'viewing'})

  minimizeUrl: () =>
      @setState({ mode: 'reading' })

  # ASSUMES URL IS ATTATCHED TO A NOTE
  # TODO: MAKE MORE GENERAL (FOR INSTANCE PROFILES)
  viewMaterialinNewWindow: () =>
      if @props.note
          window.open('/short_course/' + @props.note.project_id + '/title/' +
                      @props.note.paperseries_id + '/resource/' + @props.url.id)

  urlBlawb: =>
      <span>
          <button className="btn btn-link btn-sm m-0 p-0 mr-2"
              onClick={@viewMaterialinNewWindow}>
              {@props.url.link}
          </button>
          { @captionText() }&nbsp;
          { @deleteButton() }
      </span>

  render: =>

      if @state.mode is 'removed'
          <div>Removed!</div>

      else if @state.mode is 'url_link'
          <div className='row no-gutters'>
              <span className='d-inline-block'>
                  <button className='btn btn-link btn-sm m-0 p-0 truncate'
                          onClick={@goToLink}>{ @urlText() }</button>
              </span>
              <span className='d-inline-block'>
                  { @deleteButton() }
              </span>
              { @captionText() }
          </div>

      else if @state.mode is 'blawb_view'
          <div>
              { @urlBlawb() }
          </div>

      else
          <span></span>
