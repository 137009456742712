common = require('./common')
Loading = require('./loading')
TagProjectRollSendClick = require('./tags/tag_project_roll_send_click')

module.exports = class ConferenceUpdateSubmission extends React.Component
  displayName: 'ConferenceUpdateSubmission'

  constructor: (props) ->
      super(props)

      mode = 'first_step_choose_update_source'
      if @props.mode
          mode = @props.mode

      @state = {
          mode: mode
          update_note_loading: 'false'
          update_slideset_loading: 'false'
          update_poster_loading: 'false'
          paper_word: 'paper'
          slides_word: 'slides'
          poster_word: 'poster'
          sessions: @props.sessions
      }

  componentDidMount: ->
      paper_word = 'paper'
      if @props.paper_submission
        paper_word = 'paper ✓'
      slides_word = 'slides'
      if @props.slides_submission
        slides_word = 'slides ✓'
      poster_word = 'poster'
      if @props.poster_submission
        poster_word = 'poster ✓'

      sessions = @props.project_tags
      note_conf_tag_ids = []
      for ntag in @props.note.conference_tags
          note_conf_tag_ids.push(ntag.id)
      for session in sessions
          if session.id in note_conf_tag_ids
              if session.word[session.word.length-1] != '✓'
                  session.word = session.word + ' ✓'

      @setState({
        paper_word: paper_word
        slides_word: slides_word
        poster_word: poster_word
        sessions: sessions})


  componentDidUpdate: (prevProps) =>

      if prevProps != @props
          paper_word = 'paper'
          if @props.paper_submission
            paper_word = 'paper ✓'
          slides_word = 'slides'
          if @props.slides_submission
            slides_word = 'slides ✓'
          poster_word = 'poster'
          if @props.poster_submission
            poster_word = 'poster ✓'

          sessions = @props.project_tags
          note_conf_tag_ids = []
          for ntag in @props.note.conference_tags
              note_conf_tag_ids.push(ntag.id)
          for session in sessions
              if session.id in note_conf_tag_ids
                  if session.word[session.word.length-1] != '✓'
                      session.word = session.word + ' ✓'

          @setState({
            paper_word: paper_word
            slides_word: slides_word
            poster_word: poster_word
            sessions: sessions})


  updateNoteAndItems: () =>

      word = @state.paper_word
      submit_paper = "false"
      if word[word.length-1] is '✓'
          submit_paper = "true"
          @setState({update_note_loading: 'true'})

      word = @state.slides_word
      submit_slides = "false"
      if word[word.length-1] is '✓'
          submit_slides = "true"
          @setState({update_slides_button: 'true'})

      word = @state.poster_word
      submit_poster = "false"
      if word[word.length-1] is '✓'
          submit_poster = "true"
          @setState({update_poster_button: 'true'})

      current_session_choices = []
      for session in @state.sessions
          if session.word[session.word.length-1] is '✓'
              current_session_choices.push(session.id)

      data =
        conferenceid: @props.note.project_id
        noteid: @props.note.id
        submit_paper: submit_paper
        submit_slides: submit_slides
        submit_poster: submit_poster
        session_ids: current_session_choices

      @setState({ mode: 'loading' })

      common.updateUserTitleInConference(data, (result) =>
          window.location.reload('/conference/' + @props.note.project_id + '/title/' + @props.note.paperseries_id)
      )

  copyEditsOver: =>
      @setState({ mode: 'step_two_choose_submission_items' })

  chooseSessions: =>
      @setState({ mode: 'third_step_choose_sessions' })

  toggleCheck: (word_state) =>
      word = word_state
      if word[word.length-1] is '✓'
          word = word.substr(0, word.length-2)
      else
        word = word + ' ✓'

      switch (word_state)
          when @state.paper_word
            @setState({ paper_word: word })
          when @state.slides_word
            @setState({ slides_word: word })
          when @state.poster_word
            @setState({ poster_word: word })

  toggleThisSession: (chosen_tag_obj) =>
      word = chosen_tag_obj.word
      sessions = @state.sessions
      for session in sessions
          if session.word is word
              if word[word.length-1] is '✓'
                  session.word = word.substr(0, word.length-2)
              else
                  session.word = word + ' ✓'
      @setState({ sessions: sessions })


  render: ->

    if @props.update_note_loading is "true"
        update_note_button = <Loading />
    else
        update_note_button =
          <button className="btn btn-primary btn-txt-sm mr-2"
              onClick={@toggleCheck.bind(this, @state.paper_word)}>
            {@state.paper_word}
          </button>

    if @props.update_slideset_loading is "true"
        update_slides_button = <Loading />
    else
        update_slides_button =
          <button className="btn btn-primary btn-txt-sm mr-2"
              onClick={@toggleCheck.bind(this, @state.slides_word)}>
            {@state.slides_word}
          </button>

    if @props.update_poster_loading is "true"
        update_poster_button = <Loading />
    else
        update_poster_button =
          <button className="btn btn-primary btn-txt-sm mr-2"
              onClick={@toggleCheck.bind(this, @state.poster_word)}>
            {@state.poster_word}
          </button>

    if @state.mode is 'step_two_choose_submission_items'
      button_choices =
        <div>
            <p>Copy over your updated title, abstract, summary, tags, authors and...</p>
            {update_note_button}
            {update_slides_button}
            {update_poster_button}
            <button className='btn btn-primary'
                onClick={@chooseSessions}>
                Save</button>
        </div>

    if @state.mode is 'third_step_choose_sessions'
        button_choices =
          <div>
          <p>Do you want to update your session choices?</p>
          <span>
              {@state.sessions.map((session) =>
                  <TagProjectRollSendClick key={session.id}
                      tag={session}
                      onClick={@toggleThisSession.bind(this, session)} />
              )}
          </span>
          <button className='btn btn-primary'
              onClick={@updateNoteAndItems}>
              Save</button>
          </div>

    if @state.mode is 'first_step_choose_update_source'
        button_choices =
          <span>
              <div className='m2'>
                  <button className='btn btn-primary'
                      onClick={ @copyEditsOver }>
                      Copy edits
                  </button> from the original personal project?
              </div>
              <br />
              <div className='m2'>
                  <button className='btn btn-primary'
                      onClick={ @props.getUserNoteDetails }>
                      Edit from here?
                  </button> Also updates your personal project version.
              </div>
          </span>

    if @state.mode is 'loading'
        button_choices = <Loading />



    <span>{ button_choices }</span>
