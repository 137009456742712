common = require('../common')
FollowButton = require('../follow_button')
RoleRollProject = require('./role_roll_project')

module.exports = class RolesBarProject extends React.Component
  displayName: 'RolesBarProject'

  constructor: (props) ->
      super(props)
      # expects:
      # @props.project (including id, tags, submission_deadline)
      # @props.my_accepted_roles aka 'rolewords' in some modules
      # @props.roles
      # @props.userid
      @state = {
          creator: 'false'
          mode: 'view'
      }

  componentDidMount: =>
      if 'creator' in @props.my_accepted_roles
          @setState({creator: 'true'})

  componentDidUpdate: (prevProps) =>
      if prevProps.my_accepted_roles != @props.my_accepted_roles
          if 'creator' in @props.my_accepted_roles
              @setState({creator: 'true'})

  setFollowRoles: (new_roles_are) =>
      @props.setUpdatedRoles(new_roles_are)

  updateRoleAcceptance: (rolejunction) =>
      rolejunctionid = rolejunction.id

      updated_roles = [ ]
      for role in @props.roles
        if role.id is rolejunctionid
          if role.role_accepted is 'true'
            role.role_accepted = 'false'
            updated_status = 'false'
          else
            role.role_accepted = 'true'
            updated_status = 'true'
        updated_roles.push(role)

      @props.setUpdatedRoles(updated_roles)

      tag_ids = [ ]
      if rolejunction.role_tag_list?
        for tag in rolejunction.role_tag_list
            tag_ids.push(tag.id)

      data =
          rolejunctionid: rolejunctionid
          role_accepted: updated_status
          tag_ids: tag_ids


      common.userUpdatesConferenceRole(data, (result) =>
          # no output!
          )

  actionsBar: () =>

      if 'following' not in @props.my_accepted_roles
          follow_request =
                <span>
                  <FollowButton projectid={@props.project.id} roles={@props.roles}
                    setFollowRoles={@setFollowRoles} />
                </span>

      if (@state.creator is 'true') and (@props.getUserNoteDetails)
          upload_a_title_request =
              <button className="btn btn-danger btn-lg"
                  onClick={@props.getUserNoteDetails}>
                  Add a title
              </button>

      if (follow_request) or (upload_a_title_request)
          <div>
              <span>Actions: &nbsp;</span>
              {follow_request}
              {upload_a_title_request}
          </div>


  render: =>

      if @props.roles.length > @props.my_accepted_roles.length
          help_msg_on_roles =
              <span className="text-danger">
                  You need to accept your roles to enable the appropriate tools.
              </span>
      else
          help_msg_on_roles = <span></span>

      if (@props.roles) and (@props.roles.length>0) and (@props.view is 'full')
          <span>
            { @actionsBar() }
            <span>My roles: &nbsp;</span>
            {@props.roles.map((myrole) =>
                <RoleRollProject key={myrole.id}
                      onClick={@updateRoleAcceptance.bind(this,myrole)}
                      myrole={myrole}
                      tags={@props.project.tags}
                      projectid={@props.project.id}
                      ptype={@props.project.ptype}/>
            ,this)}
            {help_msg_on_roles}
          </span>
      else if (@props.roles) and (@props.view is 'mini')
          <span>
              {@props.roles.map((myrole) =>
                  <RoleRollProject key={myrole.id}
                        onClick={@updateRoleAcceptance.bind(this,myrole)}
                        myrole={myrole}
                        tags={@props.project.tags}
                        projectid={@props.project.id}
                        ptype={@props.project.ptype}/>
              ,this)}
              {help_msg_on_roles}
          </span>
      else
          <div>
              { @actionsBar() }
              My roles: <i>none yet -- follow!</i>
              {help_msg_on_roles}
          </div>
