
module.exports = class ChoiceRoll extends React.Component
  displayName: 'ChoiceRoll'

  render: =>

      if @props.mode is 'editable'
          <div className='row no-gutters'>
              <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  <i className='fa fa-circle-o text-success'></i>
              </div>
              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                  { @props.choice_word }
              </div>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2 text-danger'>
                  <i className='fa fa-minus-circle'
                      onClick={@props.onClick}></i>
              </div>
          </div>
      else if (@props.choice) and (@props.choice.selected is 'true')
          <span className='m-3'>
              <span className='mr-1'
                    onClick={@props.onClick}>
                  <i className='fa fa-dot-circle-o text-success'></i>
              </span>
              { @props.choice_word }
          </span>
      else
          <span className='m-3'>
              <span className='mr-1'
                    onClick={@props.onClick}>
                  <i className='fa fa-circle-o text-success'></i>
              </span>
              { @props.choice_word }
          </span>
