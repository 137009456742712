common = require('../common')

module.exports = class GoogleLogin extends React.Component
  displayName: 'GoogleLogin'

  constructor: (props) ->
      super(props)
      base_style = 'btn btn-outline-primary'
      gbutton_style = base_style
      if @props and @props.style
          gbutton_style = base_style + ' ' + @props.style
      @state = {
          config:'',
          isAuthenticated: false,
          user: null,
          token: ''
          gbutton_style: gbutton_style}

  componentDidMount: =>
        # config = '../config_login.json'
        # xmlhttp = new XMLHttpRequest()
        # xmlhttp.open("GET", config, false)
        # xmlhttp.send()
        # if (xmlhttp.status is 200)
        #     config = xmlhttp.responseText
        # config = JSON.parse(config)
        # @setState({ config: config })

  logout: () =>
      this.setState({isAuthenticated: false, token: '', user: null})

  storeCurrentLocation: =>
      # skip if there is already a redirect set
      if localStorage
          redirect = localStorage.getItem('redirect')
          if !(redirect)
              redirect = location.pathname
              localStorage.setItem('redirect', JSON.stringify(redirect))
      return

  getGoogleResponse: () =>
      # tokenBlob = new Blob(
      #     [JSON.stringify({access_token: response.accessToken}, null, 2)],
      #     {type : 'application/json'})
      # options = {
      #     method: 'POST',
      #     body: tokenBlob,
      #     mode: 'cors',
      #     cache: 'default'}
      # fetch('http://localhost:4000/api/v1/auth/google', options).then(r => {
      #     const token = r.headers.get('x-auth-token');
      #     r.json().then(user => {
      #         if (token) {
      #             this.setState({isAuthenticated: true, user, token})

      data = {}

      common.testGoogleLogin(data, (result) =>

          @storeCurrentLocation()

          if (result.msg) and (result.msg = 'anonymous')
              common.authorizeGoogleUser(data, (auth_result) =>
                  window.location = auth_result['authorization_url']
              )
          else if (result.msg) and (result.msg = 'logged_on')
              window.location.reload()
          else
              console.log(result.msg)
      )

  onFailure: (error) =>
      console.log(error)

  googleLogin: =>
      <span>
          <button onClick={@getGoogleResponse}
              className={@state.gbutton_style}>
            <svg aria-hidden="true"
                className="svg-icon native iconGoogle"
                width="18" height="18" viewBox="0 0 18 18">
                <path d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z" fill="#4285F4"></path>
                <path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z" fill="#34A853"></path>
                <path d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z" fill="#FBBC05"></path>
                <path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z" fill="#EA4335"></path>
            </svg>
            &nbsp;Login
          </button>
      </span>

  render: =>

      if @state.isAuthenticated
          content =
              <span></span>
      else
          content =
              <span>
                  {@googleLogin()}
              </span>

      <span>
          { content }
      </span>
