common = require('../common')
Loading = require('../loading')
IsReplicationStudyRoll = require('./is_replication_study_roll')
IsReplicationStudyRollEditable = require('./is_replication_study_roll_editable')

module.exports = class IsStudy extends React.Component
  displayName: 'IsStudy'

  constructor: (props) ->
      super(props)
      # expects:
      # @props.replications
      # @props.editor
      @state = {
          err_msg: ''
      }

  render: =>


      if (@props.mode is 'view') and (@props.replications)
          replication_study =
              <span>
                  {@props.replications.map((replication) =>
                    <IsReplicationStudyRoll key={replication.id}
                                     replication={replication}
                                     editor={@props.editor}/>
                  )}
              </span>
      else if (@props.replications)
          replication_study =
              <span>
                  {@props.replications.map((replication) =>
                    <IsReplicationStudyRollEditable key={replication.id}
                                     replication={replication}
                                     editor={@props.editor}/>
                  )}
              </span>
      else
          replication_study = <span></span>

      <div className='row border border border-success border_rad_tenpx m-3'>
        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
            <strong className='text-success'>
                Replication study
            </strong>
            { replication_study }
        </div>
      </div>
