Link = require('react-router-dom').Link
common = require('../common')
ConferenceInvitedUsersRoll = require('./conference_invited_users_roll')

module.exports = class Roster extends React.Component
  displayName: 'Roster'

  constructor: (props) ->
      super(props)
      @state = {
        mode : 'roster_collapsed'
        roles : [ ]
        contributors : [ ]
        inviteds : [ ]
        keynotes : [ ]
        areachairs : [ ]
        chairs : [ ]
        creator : [ ]
        reviewers : [ ]
        msg: ''
      }

  downloadRoster: =>

      @setState({ msg: 'Your download will start soon' })

      data =
        projectid : @props.project.id

      common.downloadAllConferenceRoles(data, (result) =>
          @setState({ msg: 'Download complete!' })
          )


  goToViewRoster: =>
      data =
        projectid : @props.project.id

      common.conferenceRetrieveAllRoles(data, (result) =>
          @setState({ roles : result.combined_rolejunctions })

          creator = [ ]
          chairs = [ ]
          areachairs = [ ]
          keynotes = [ ]
          inviteds = [ ]
          contributors = [ ]
          reviewers = [ ]

          for role in @state.roles
            if role.role is 'creator'
                creator.push(role)
            if role.role is 'chair'
                chairs.push(role)
            if role.role is 'areachair'
                areachairs.push(role)
            if role.role is 'keynote'
                keynotes.push(role)
            if role.role is 'invited'
                inviteds.push(role)
            if role.role is 'contributor'
                contributors.push(role)
            if role.role is 'reviewer'
                reviewers.push(role)


          @setState({ creator : creator })
          @setState({ chairs : chairs })
          @setState({ areachairs : areachairs })
          @setState({ keynotes : keynotes })
          @setState({ inviteds : inviteds })
          @setState({ contributors : contributors })
          @setState({ reviewers : reviewers })

          @setState({ mode : 'roster_show '})

      )

  goToHideRoster: =>
      @setState({ mode : 'roster_collapsed' })

  turnOnCreator: =>
      styles={
        display:'none'
      }
      if @state.creator.length > 0
          styles={
            display:'block'
          }
      return styles

  turnOnChairs: =>
      styles={
        display:'none'
      }
      if @state.chairs.length > 0
          styles={
            display:'block'
          }
      return styles

  turnOnAreaChairs: =>
      styles={
        display:'none'
      }
      if @state.areachairs.length > 0
          styles={
            display:'block'
          }
      return styles

  turnOnReviewers: =>
      styles={
        display:'none'
      }
      if @state.reviewers.length > 0
          styles={
            display:'block'
          }
      return styles

  turnOnKeynotes: =>
      styles={
        display:'none'
      }
      if @state.keynotes.length > 0
          styles={
            display:'block'
          }
      return styles

  turnOnInviteds: =>
      styles={
        display:'none'
      }
      if @state.inviteds.length > 0
          styles={
            display:'block'
          }
      return styles

  turnOnContributors: =>
      styles={
        display:'none'
      }
      if @state.contributors.length > 0
          styles={
            display:'block'
          }
      return styles


  render: =>

    if @state.mode is 'roster_collapsed'
        <div>
            <div>
                <button className="btn btn-primary"
                    onClick={@goToViewRoster}>
                    View attendees
                </button>
                See who has RSVPd.
            </div>
            <div className='mt-2'>
                <button className="btn btn-primary"
                    onClick={@downloadRoster}>
                    Download
                </button>
                CSV of attendees names, emails, and submission titles
                <div>{ @state.msg }</div>
                <form method="POST" action="/api/download_all_conference_roles">
                    <input type="hidden" name="projectid" value={@props.project.id} />
                    <button type="submit" className='btn btn-primary'>
                            Download
                    </button>
                    CSV of attendees names, emails, and submission titles
                </form>
            </div>
        </div>
    else if @state.mode is 'roster_show'
        <div>
            <button className="btn btn-primary"
                    onClick={@goToHideRoster}>Hide attendees</button>
            <div>
                Legend:
                  <div className='pl-3'>
                      <button className='btn btn-primary btn-txt-sm'>&#10003; checkmark indicates user exists, has accepted their roles</button>
                  </div>
                  <div className='pl-3'>
                      <button className='btn btn-primary btn-txt-sm'>user exists, but has not accepted their roles</button>
                  </div>
                  <div className='pl-3'>
                      <button className='btn btn-secondary btn-txt-sm'>user does not exist</button>
                  </div>
                </div>
                <div className='proj_listing'>
                    <div style={@turnOnCreator()}>
                        Conference creator:
                        {@state.creator.map((role) =>
                            <ConferenceInvitedUsersRoll key={role.id} role={role} />
                        )}
                    </div>
                    <div style={@turnOnChairs()}>
                        Conference chairs:
                        {@state.chairs.map((role) =>
                            <ConferenceInvitedUsersRoll key={role.id} role={role} />
                        )}
                    </div>
                    <div style={@turnOnAreaChairs()}>
                        Area chairs:
                        {@state.areachairs.map((role) =>
                            <ConferenceInvitedUsersRoll key={role.id} role={role} />
                        )}
                    </div>
                    <div style={@turnOnReviewers()}>
                        Reviewers:
                        {@state.reviewers.map((role) =>
                            <ConferenceInvitedUsersRoll key={role.id} role={role} />
                        )}
                    </div>
                    <div style={@turnOnKeynotes()}>
                        Keynotes:
                        {@state.keynotes.map((role) =>
                            <ConferenceInvitedUsersRoll key={role.id} role={role} />
                        )}
                    </div>
                    <div style={@turnOnInviteds()}>
                        Invited:
                        {@state.inviteds.map((role) =>
                            <ConferenceInvitedUsersRoll key={role.id} role={role} />
                        )}
                    </div>
                    <div style={@turnOnContributors()}>
                        Paper contributors:
                        {@state.contributors.map((role) =>
                            <ConferenceInvitedUsersRoll key={role.id} role={role} />
                        )}
                    </div>
            </div>
        </div>
    else
          <div>
              Something went wrong. Try reloading!
          </div>
