common = require('../common')
DocumentButton = require('../uploads/document_button')
EditorChoice = require('../editor_choice')
GetNoteAuthorContacts = require('../authorcontact/get_note_authorcontacts')
Loading = require('../loading')
PosterButton = require('../uploads/poster_button')
Replications = require('../replication/add_edit_view_replications')
Materials = require('../uploads/materials')
Scheduler = require('../schedule/seminar_scheduler')
SlidesButton = require('../uploads/slides_button')
TagNoteRollEditable = require('../tags/tag_note_roll_editable')
TagProjectRollSendClick = require('../tags/tag_project_roll_send_click')
UserChoosesAProject = require('./user_chooses_a_project')
Videos = require('../video/videos')

module.exports = class CreateNoteMetadata extends React.Component
  displayName: 'CreateNoteMetadata'

  constructor: (props) ->
      super(props)
      # expecting: @props.project, @props.setMode
      # @props.version = 'update' or 'new'
      # if you include @props.version = 'new' then also include @props.note
      @state = {
          added_tag_list: []
          ask_delete: 'true'
          error_msg: ''
          mode: 'loading'
          note: {}
          note_editor: ''
          project: @props.project
          project_tags: @props.project.tags
          project_title: ''
          project_id: ''
          userid: ''
      }
      # these are for uploading directly to a conference:
      # project_title: if creating a new title & new project
      # project_id: if creating a new title in a pre-existing project
      @titleRef = React.createRef()
      @abstractRef = React.createRef()
      @motiveRef = React.createRef()
      @editorChoiceRef = React.createRef()
      @tagsRef = React.createRef()

  componentDidMount: () =>

      if @props.version is 'update'
          note=@props.note
          note_editor=@props.note.editor
      else
          note = {}
          note_editor = 'MathJax'
      @setState({ note: note })

      if (@props.version is 'new') and
        ((@props.project.ptype is 'lab_group') or
            (@props.project.ptype is 'seminar') or
            (@props.project.ptype is 'short_course'))
          data =
              projectid: @props.project.id

          common.startProjectBlankNote(data, (result) =>
              if result.error
                @printError(result['error'])
              else
                new_note = result.note
                new_note.tags = []
                new_note.authorcontacts = []
                new_note.title = ''
                new_note.note_type = @props.project.ptype
                userid = result.userid
                @setState({
                  mode: 'create_note_metadata',
                  note: new_note,
                  userid: userid})
          )
      else if (@props.version is 'new') and (@props.project.ptype is 'personal')
          data =
              projectid: @props.project.id

          common.startProjectBlankNote(data, (result) =>
              if result.error
                @printError(result['error'])
              else
                new_note = result.note
                new_note.tags = []
                new_note.authorcontacts = []
                new_note.title = ''
                new_note.note_type = 'project'
                @setState({mode: 'create_note_metadata', note: new_note})
          )
      else if (@props.version is 'new') and (@props.project.ptype is 'conference')
          # make sure to start the note in a user-owned project!
          # next step after saving will be to submit it to the conference
          data = {}

          common.startProjectBlankNote(data, (result) =>
              if result.error
                @printError(result['error'])
              else
                new_note = result.note
                new_note.tags = []
                new_note.authorcontacts = []
                new_note.title = ''
                new_note.note_type = 'project'
                @setState({mode: 'get_folder', note: new_note})
          )
      else
          if @state.note.conference_tags
              for ctag in @state.note.conference_tags
                  @addThisTagToListForUser(ctag)
          @setState({mode: 'create_note_metadata'})


  updateMetaData: () =>
      @setState({error_msg: ''})
      title = @titleRef.current.value
      abstract = @abstractRef.current.value
      motive = @motiveRef.current.value
      tags = @tagsRef.current.value

      # # if we are creating a new user project too
      # project_title = ""
      # if @projectTitleRef.current
      #     project_title = @projectTitleRef.current.value
      #     if (project_title.length < 1)
      #       @setState({error_msg: 'Please enter a name for the project.'})
      #       return

      added_tag_ids = [ ]

      for tag in @state.added_tag_list
          added_tag_ids.push(tag.id)

      if (title.length < 4)
          @setState({error_msg: 'Please enter a proper title.'})
          return

      if (abstract.length < 4)
          @setState({error_msg: 'Your abstract will help people in your field find your work, write a good one.'})
          return

      if (motive.length < 4)
          @setState({error_msg: 'Your work is important and so is your summary.'})
          return

      updated_projectid = ''
      if @props.updated_projectid
          updated_projectid = @props.updated_projectid
      if @state.updated_projectid
          updated_projectid = @state.updated_projectid

      data =
          noteid: @state.note.id
          note_title: title
          note_abstract: abstract
          note_motive: motive
          note_tags: tags
          note_editor: @editorChoiceRef.current.getEditor()
          add_project_tag_ids: added_tag_ids
          update_project_id: updated_projectid
          add_project_title: @state.project_title

      if @props.version is 'new'
          common.submitProjectNoteMetadata(data, (result) =>
              if result.error
                  console.log("THERE WAS AN ERROR:")
                  console.log(result.error)
              if @props.project.ptype is 'personal'
                  @props.setMode('viewing')
                  window.location = '/project/' + @props.project.id + '/titles'
              #else if (@props.project.ptype is 'seminar')
              #    @props.setMode('viewing')
              #    window.location = '/seminar/' + @props.project.id + '/titles'
              # else if (@props.project.ptype is 'short_course')
              #     @props.setMode('viewing')
              #     window.location = '/short_course/' + @props.project.id + '/titles'
              # else if (@props.project.ptype is 'lab_group')
              #     @props.setMode('viewing')
              #     window.location = '/lab_group/' + @props.project.id + '/titles'
              else
                  # ptype = 'conference' or 'short_course'
                  # don't reload because user still has more to do (choose sessions, etc.)
                  new_note = {}
                  new_note.id = @state.note.id
                  new_note.title = title
                  new_note.tags = result.tags
                  new_note.authorcontacts = result.authorcontacts
                  if @props.project.ptype is 'conference'
                      new_note.note_type = 'conference'
                  else if @props.project.ptype is 'short_course'
                      new_note.note_type = 'short_course'
                  else if @props.project.ptype is 'personal'
                      new_note.note_type = 'project'
                  else if @props.project.ptype is 'seminar'
                      new_note.note_type = 'seminar'
                  else
                      new_note.note_type = 'lab_group'
                  @props.setMode({ new_note })
          )
      else
          common.submitProjectNoteMetadata(data, (result) =>
            updated_note = @state.note
            updated_note.abstract = abstract
            updated_note.motive = motive
            updated_note.title = title
            updated_note.id = result.noteid
            updated_note.tags = result.tags
            # sidenote: authorcontacts and comments are reloaded
            # because they are modified in another module which does not
            # communicate the results back up. Could possibly eliminate
            # re-sending the comments.
            updated_note.authorcontacts = result.authorcontacts
            updated_note.comments = result.comments
            if @props.callbackUpdatedNote
                @props.callbackUpdatedNote(updated_note)
            @setState({note: updated_note}, @props.setMode('viewing'))
          )

  askIfSure: () =>
      @setState({ask_delete: 'false'})

  deleteNote: () =>
      data =
        noteid : @state.note.id
      common.deleteProjectNote(data, (result) =>
          if @props.version is 'new'
              @props.setMode('cancelled')
          else
              note_type = @state.note.note_type
              if note_type is 'lab_group'
                  ptype = 'lab_group'
              else if note_type is 'project'
                  ptype = 'project'
              else if note_type is 'conference'
                  ptype = 'conference'
              else
                  ptype = 'short_course'
              window.location = '/' + ptype + '/' + @state.note.project_id + '/titles'
      )

  setModeViewing: () =>
      @props.setMode('viewing')


  addThisTagToListForUser: (tag) =>
      list = @state.added_tag_list

      # first, check if the word is on the list, if so, remove it
      # otherwise add it

      tmp_tags = @state.project_tags
      already_chosen = false

      pos = 0
      for tmp_tag in tmp_tags
          if tmp_tag.id is tag.id
              pos_in_list = list.indexOf(tag)
              if pos_in_list > -1
                  # OLD
                  # toggle check to off
                  tmp_tag.word = tmp_tag.word.substr(0, tmp_tag.word.length-2)
                  list = list.splice(0, pos_in_list)
              else
                  # NEW
                  # toggle check to on
                  tmp_tag.word = tag.word + ' ✓'
                  list.push(tag)

      @setState({
          added_tag_list : list
          proj_tags: tmp_tags
          })

  instructions: () =>
      if (@props.version is 'new')
          <div className='row no-gutters'>
            Start your title with a few basic details. You will be able to
            update everything as needed.
          </div>

  closeFolderChoices: (choice) =>
      note = @state.note
      note.project_id = choice.project_id

      if choice.project_id != @state.note.project_id
          data =
              noteid: @state.note.id
              updated_projectid: choice.project_id
          common.updateNoteProjectChoice(data, (result) =>
              @setState({
                project_title: choice.project_title
                project_id: choice.project_id
                updated_projectid: choice.project_id
                note: note
                mode: 'create_note_metadata'
              })
              )
      else
          @setState({
            project_title: choice.project_title
            project_id: choice.project_id
            mode: 'create_note_metadata'
          })

  editFolderName:() =>
      @setState({ mode: 'get_folder' })

  getFolderName: () =>
      if (@props.version is 'new') and (@props.project.ptype is 'conference')
          if @state.mode is 'get_folder'
              <span>
                  <UserChoosesAProject
                      closeFolderChoices={@closeFolderChoices}
                      deleteNote={@deleteNote}
                      editFolderName={@editFolderName}
                      mode={'ask_what_type'} />
              </span>
          else
              <span>
                  <UserChoosesAProject
                      project_title={@state.project_title}
                      closeFolderChoices={@closeFolderChoices}
                      deleteNote={@deleteNote}
                      editFolderName={@editFolderName}
                      mode={'show_folder'} />
              </span>

  chooseEditor: () =>
      <div className='row no-gutters'>
        <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
            <strong>Editor</strong>
        </div>
        <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
          <EditorChoice ref={@editorChoiceRef} editor={@state.note_editor}/>
        </div>
      </div>

  askIfReplication: () =>
      <div className='row no-gutters'>
        <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
            <strong>Replication</strong>
        </div>
        <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
          <Replications note={@state.note} rtype='paper' editor={@state.note_editor}
            mode={@state.mode} />
        </div>
      </div>

  enterTitle: () =>
      <div className='row no-gutters'>
        <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
          <strong>Title</strong>
        </div>
        <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
          <h2>
            <textarea ref={@titleRef} cols="40" rows="2" defaultValue={@state.note.title}></textarea>
          </h2>
        </div>
      </div>

  enterAuthors: () =>
      <div className='row no-gutters'>
        <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
          <strong>Authors</strong>
        </div>
        <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
          <GetNoteAuthorContacts note={@state.note} />
        </div>
      </div>

  uploadMaterials: () =>
      <span>
          <DocumentButton note={@state.note} user_is_author='true' />
          <SlidesButton note={@state.note} user_is_author='true' />
          <PosterButton note={@state.note} user_is_author='true' />
          <Materials obj={@state.note}
              user_is_author={'true'}
              mode={'editing'} />
          <Videos obj={@state.note}
              obj_type={'note_video'}
              mode={'show_videos'}
              user_can_edit={'true'} />
      </span>

  enterAbstract: () =>
      <div className='row no-gutters'>
        <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
          <strong> Abstract</strong>
        </div>
        <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
          <textarea ref={@abstractRef} id="note_abstract"
            placeholder="Briefly summarize the contents for someone in
                         your field. Provide context, aims, conclusions,
                         and significance."
            cols="40" rows="10" defaultValue={@state.note.abstract}></textarea>
        </div>
      </div>

  enterSummary: () =>
      <div className='row no-gutters align-items-start'>
        <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
          <strong> Summary</strong>
        </div>
        <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
          <textarea ref={@motiveRef} cols="40" rows="10"
            placeholder="Please write in a blog-like style for the
                general public interested in your research but not
                familiar with field-specific jargon."
            defaultValue={@state.note.motive}></textarea>
        </div>
      </div>




  addTagsSection: () =>
      # This is for lab topic tags
      if (@props.project.ptype is 'lab_group') and (@state.project_tags) and (@state.project_tags.length > 0)
          choose_project_tags =
              <div className='row no-gutters'>
                  <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                      <strong>Lab Topics</strong>
                  </div>
                  <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                      {@state.project_tags.map((proj_tag) =>
                          <TagProjectRollSendClick key={proj_tag.id}
                                        tag={proj_tag}
                                        onClick={@addThisTagToListForUser.bind(this,proj_tag)} />
                      , this)}
                  </div>
              </div>

      if (@state.note.tags) and (@state.note.tags.length > 0)
          current_tag_map =
              <span>
                  {@state.note.tags.map((tag) =>
                    <TagNoteRollEditable key={tag.id} tag={tag} note={@state.note} />
                  , this)}
              </span>

      <span>
          <div className='row no-gutters'>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                  <strong>Tags</strong>
              </div>
              <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                  {current_tag_map}
                  <textarea ref={@tagsRef} cols="40" rows="1"
                    placeholder="Separate with commas..."></textarea>
              </div>
          </div>
          <span>
              {choose_project_tags}
          </span>
      </span>

  saveDeleteCancelRow: () =>
      save_button =
          <span>
            <button className='btn btn-primary mr-1'
                onClick={@updateMetaData}>Save</button>
            <span className='text-danger'>{@state.error_msg}</span>
          </span>

      if (@props.version is 'new') and (@state.ask_delete is 'true')
          cancel_new_note_button =
              <button
                  className="btn btn-danger mr-1"
                  onClick={@askIfSure}>
                  Cancel
              </button>
      else if (@props.version is 'new')
          cancel_new_note_button =
              <span>
                  <span className='text-danger'>Are you sure?&nbsp;</span>
                  <button
                      className="btn btn-danger mr-1"
                      onClick={@deleteNote}>
                      yes, cancel
                  </button>
              </span>

      if (@props.version is 'update') and (@state.ask_delete is 'true')
          delete_button =
              <button className="btn btn-danger mr-1"
                  onClick={@askIfSure}>Delete
              </button>
      else if (@props.version is 'update')
          delete_button =
              <span>
                    <span className='text-danger'>Are you sure?&nbsp;</span>
                  <button className="btn btn-danger mr-1" onClick={@deleteNote}>
                      yes, delete</button>
              </span>

      if (@props.version != 'new')
          cancel_editing_button =
              <button
                  className="btn btn-danger mr-1 float-right"
                  onClick={@setModeViewing}>
                  Cancel
              </button>

      <div className='row no-gutters mb-2'>
        {save_button}
        {cancel_new_note_button}
        {cancel_editing_button}
        {delete_button}
      </div>

  scheduleThis: () =>
      if @props.user_can_schedule is 'true'
          schedulable_notes = ['seminar', 'short_course', 'conference']
          if (@state.note.note_type in schedulable_notes)
              <div className='row no-gutters align-items-start'>
                  <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                      <div className='row no-gutters'>
                          Do you want to add this to the schedule? You can edit/change at any time.
                      </div>
                      <div className='row no-gutters'>
                          <Scheduler
                              note={@state.note}
                              user_can_edit={'true'} />
                      </div>
                  </div>
              </div>

  render: =>

      if @state.mode is 'loading'
          <Loading />
      else if @state.mode is 'get_folder'
          <div className='note_infoDiv'>
              <div className='container-fluid'>
                  { @getFolderName() }
              </div>
          </div>
      else
          <div className='note_infoDiv'>
            <div className='container-fluid'>
                { @saveDeleteCancelRow() }
                { @instructions() }
                { @getFolderName() }
                { @chooseEditor() }
                { @askIfReplication() }
                { @enterTitle() }
                { @enterAuthors() }
                { @uploadMaterials() }
                { @enterAbstract() }
                { @enterSummary() }
                { @addTagsSection() }
                { @scheduleThis() }
                <br />
                <div className='row no-gutters'>
                    { @saveDeleteCancelRow() }
                </div>

            </div>
          </div>
