ConferenceSubmissionUserOwnedProjectLine = require('../conference_submission_user_owned_project_line')
Pagination = require('../pagination')
common = require('../common')
Link = require('react-router-dom').Link
Loading = require('../loading')

module.exports = class UserChoosesAProject extends React.Component
  displayName: 'UserChoosesAProject'

  constructor: (props) ->
      super(props)
      mode = 'ask_what_type'
      if @props.mode
        mode = @props.mode
      @state = {
          error_msg: ''
          mode: mode
          project: {}
          projects: []
          project_id: ''
          selected_project: ''

          page_num: 1
          lengthy: 2
          num_per_page: 10
      }
      @projListRef = React.createRef()
      @projectTitleRef = React.createRef()

  chosenProject: (project) =>
      @props.closeFolderChoices({
        project_title: project.title
        project_id : project.id})

  scrollHere: (ref) =>
      if (ref)
          elem = ref.current
          if (elem)
            window.scrollTo(0, elem.offsetTop)


  componentDidMount: =>

      common.loginMonitor (data) =>
          # need to get userid from login rather than parent
          userid = data['userid']

          data =
              page_num: 1
              num_per_page: 10
              userid: userid
              match_these_roles: ['creator','contributor']

          common.fetchUserProjects(data, (result) =>
              projects = result.results
              @setState({
                  projects: projects
                  userid: userid}, @scrollHere.bind(this, @projListRef))
          )

          common.getTotalNumberProjectsByUserid(data, (result) =>
            lengthy = Math.ceil(result.total/@state.num_per_page)
            @setState({ lengthy: lengthy})
          )

  getTheseProjectPages: (info) =>
      if not (info)
          info=1

      data =
          page_num: info
          num_per_page: @state.num_per_page
          userid:  @state.userid
          match_these_roles: ['creator','contributor']

      common.fetchUserProjects(data, (result) =>
          projects = result.results
          @setState({
              projects: projects
              page_num: info}, @scrollHere.bind(this, @projListRef))
      )

  showUserProjects: () =>
    @setState({ mode: 'choose_project'})

  showChoices: () =>
      if (@state.projects.length == 0)
          <span>
              <div className='row no-gutters mt-3'>
                      <textarea ref={@projectTitleRef} cols="40" rows="1"
                        placeholder="Name a new project!"></textarea>
              </div>
          </span>
      else
          <span>
              <br />
              <div className='row no-gutters'>
                  Existing project?
                  <button className='btn btn-primary ml-3 p-1'
                      onClick={@showUserProjects}>
                      select
                  </button>
              </div>
              <div className='row no-gutters mt-1'>
                  or
              </div>
              <div className='row no-gutters mt-1'>
                      <textarea ref={@projectTitleRef} cols="40" rows="1"
                        placeholder="Name a new project!"></textarea>
              </div>
          </span>

  closeFolderChoices: () =>
      # if we are creating a new user project too
      project_title = ""
      if @projectTitleRef.current
          project_title = @projectTitleRef.current.value
          if (project_title.length < 1) and !(@state.project_id)
            @setState({error_msg: 'Please enter a name for the project.'})
            return
      @setState({
        mode: 'show_folder'},
        @props.closeFolderChoices.bind(this, {
          project_title: project_title, project_id:''}))

  render: =>

      if @state.mode is 'show_folder'
          <div className='row no-gutters'>
            <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                <strong>Folder</strong>
            </div>
            <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
              { @props.project_title }
              <button className='btn btn-link ml-3'
                  onClick={ @props.editFolderName }>
                  edit
              </button>
            </div>
          </div>
      else if @state.mode is 'ask_what_type'
        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
            <div className='row no-gutters'>
                <strong>Choose a project</strong>
            </div>
            <div className='row no-gutters'>
            Project folders help to organize your submissions. You can create a
            new one or choose an existing project.
            </div>
            { @showChoices() }
            <button className='btn btn-link'
                onClick={ @props.deleteNote }>
                &larr; Go Back
            </button>
            <button className='btn btn-link'
                onClick={ @closeFolderChoices }>
                Next &rarr;
            </button>
            <div className='row no-gutters'>
                {@state.error_msg}
            </div>
        </div>
      else if (@state.mode is 'choose_project') and (@state.projects.length > 0)
          <div className='container-fluid' ref={@projListRef}>
              <div className='row' ref={@projListRef}>
                <strong>Pick one of your projects?</strong>
              </div>
              {@state.projects.map( (project) =>
                <ConferenceSubmissionUserOwnedProjectLine
                          key={project.id}
                          project={project}
                          onClick={@chosenProject.bind(this, project)}/>
              ,this)}
              <Pagination page_num={@state.page_num}
                          lengthy={@state.lengthy}
                          onClick={@getTheseProjectPages} />
          </div>
      else if @state.mode is 'loading'
          <Loading />
