common = require('../common')
Loading = require('../loading')

module.exports = class DocumentIFrame extends React.Component
    displayName: "DocumentIFrame"

    constructor: (props) ->
        super(props)
        @state = {
            contentType: null
            body: null
            os: null
            pdf_s3_link: ''
        }
        @iFrameRef = React.createRef()

    getOS: (res) =>
        userAgent = window.navigator.userAgent
        platform = window.navigator.platform
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Darwin']
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
        iosPlatforms = ['iPhone', 'iPad', 'iPod']

        android_regex = /Android/
        linux_regex = /Linux/

        if macosPlatforms.indexOf(platform) > -1
            os = 'MacOS'
        else if iosPlatforms.indexOf(platform) > -1
            os = 'iOS'
        else if windowsPlatforms.indexOf(platform) > -1
            os = 'Windows'
        else if android_regex.test(userAgent)
            os = 'Android'
        else if (not os) and (linux_regex.test(platform))
            os = 'Linux'
        else
            os = null

        # if (os is 'MacOS') or (os is 'iOS')
        #    window.location = res.header.location

        if (os is 'MacOS') or (os is 'iOS')
            os = 'Apple'

        # for now, Android PDFs are having trouble displaying, set os='apple' for all for now
        os = 'Apple'

        @setState({ os: os, pdf_s3_link: res.header.location })

        return os

    showS3Page: () =>
        window.location = @state.pdf_s3_link

    documentLoaded: (res) =>
        os = @getOS(res)


        reader = new FileReader()
        if res.type in ["application/pdf", "text/pdf"]
            reader.addEventListener("loadend", () =>
                @setState({contentType: "application/pdf", body: reader.result}))
            reader.readAsDataURL(res.body)
        else
            reader.addEventListener("loadend", () =>
                @setState({contentType: res.type, body: reader.result}))
            reader.readAsText(res.body)

        if os is 'Apple'
            @showS3Page()

    componentDidMount: () =>
        if @props.src
            filepath = @props.src
            if filepath.indexOf('slideset') != -1
                common.readDocument(@props.src, @documentLoaded)
            else if filepath.indexOf('poster') != -1
                common.readDocument(@props.src, @documentLoaded)
            else
                common.readDocument(@props.src, @documentLoaded)


    render: () =>

        if (@state.body) and (@state.os) and (@state.os is not 'Apple')
            if @state.contentType in ["application/pdf", "application/x-presigned-s3-url"]
                <embed type="application/pdf" width="100%" height="100%" className={@props.className} src={@state.body}></embed>
            else if @state.contentType == "text/pdf"
                <div dangerouslySetInnerHTML={{__html: @state.body}}></div>
            else if @state.contentType in["application/json"]
                <embed type="application/x-ipynb+json" width="100%" height="100%" className={@props.className} src={@state.body}></embed>
            else
                <div className="error">Unable to handle content type {@state.contentType}</div>
        else if (@state.body) and (@state.os) and (@state.os is 'Apple')
            # @state.os is 'Apple'
            <div>
                <br />
                <strong>Loading S3 link directly. Link will expire, so please share the original listing, not this url.</strong>
            </div>
        if (@state.body)
            # just assume it will open correctly even though we don't know the os
            if @state.contentType in ["application/pdf", "application/x-presigned-s3-url"]
                <embed type="application/pdf" width="100%" height="100%" className={@props.className} src={@state.body}></embed>
            else if @state.contentType == "text/pdf"
                <div dangerouslySetInnerHTML={{__html: @state.body}}></div>
            else if @state.contentType in["application/x-ipynb+json"]
                <embed type="application/x-ipynb+json" width="100%" height="100%" className={@props.className} src={@state.body}></embed>
            else
                <div className="error">Unable to handle content type {@state.contentType}</div>
        else
            <Loading />
