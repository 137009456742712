Link = require('react-router-dom').Link

module.exports = class IsReplicationStudyRoll extends React.Component
  displayName: 'IsReplicationStudyRoll'

  constructor: (props) ->
      super(props)
      if @props.replication.replication
          replication = @props.replication.replication
          repl_jn = @props.replication.replicate_junction
      else
          replication = @props.replication[0]
          repl_rj = @props.replication[1]
      if replication.replicable_id
          link_mode = 'ZDZ'
          link = replication.replicable_url
      else
          link_mode = 'offsite'
          link = replication.replicable_url
      @state = {
          replication: replication
          link_mode: link_mode
          link: link
      }

  componentDidMount: =>

      if @props.editor is 'MathJax'
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, @domElement])

  setDOMRef: (domElement) =>
    @domElement = domElement


  goToLink: () =>
      link = @state.link
      if link.slice(0,4) is 'http'
          window.open(link)
      else
          window.open('http://' + link)

  render: =>

      if @props.editor is 'MathJax'
          just_the_facts =
              <span className='mathjax new_lines_enabled'
                    ref={@setDOMRef}
                    dangerouslySetInnerHTML={{__html: @state.replication.facts }}>
              </span>
      else
          just_the_facts =
              <span className='new_lines_enabled'
                    dangerouslySetInnerHTML={{__html: @state.replication.facts }}>
              </span>

      if @state.replication.recommend is 1
          recommendation = 'Yes'
      else
          recommendation = 'No'

      if @state.link_mode is 'ZDZ'
          title_text =
              <Link to={@state.link}>
                  <span>{ @state.replication.title }</span>
              </Link>
      else
          title_text =
              <span>{ @state.replication.title }</span>
          if @state.link.length > 50
              url_text = @state.link.slice(0,50) + '...'
          else
              url_text = @state.link
          offsite_link_row =
              <div className='row no-gutters'>
                  <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                      Offsite Link:
                  </div>
                  <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                      <button className='btn btn-link btn-sm m-0 p-0 truncate'
                              onClick={@goToLink}>{ url_text }</button>
                  </div>
              </div>

      <div className='col border rounded success m-1'>
          <div className='row no-gutters'>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  Replicated:&nbsp;{ title_text }
              </div>
          </div>
          { offsite_link_row }
          <div className='row no-gutters'>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  Facts:&nbsp;{ just_the_facts }
              </div>
          </div>
          <div className='row no-gutters'>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  Overall recommended? { recommendation }
              </div>
          </div>
      </div>
