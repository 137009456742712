Link = require('react-router-dom').Link
common = require('./common')
NoteCommentRoll = require('./comments/note_comment_roll_0div0_editor')
VerticalVoteComponent = require('./social/vertical_vote_component')
EditorChoice = require('./editor_choice')
GetWriting = require('./get_writing_manual_resize')

module.exports = class NoteFullComments extends React.Component
  displayName: 'NoteFullComments'

  constructor: (props) ->
    super(props)
    @state = {
      note: @props.note
      data: {logged_in_user: 'anonymous', logged_in_userid: ''}
      comments:  [ ]
      reviews: [ ]
      default_txt: ''
    }
    @writingRef = React.createRef()
    @editorChoiceRef = React.createRef()

  sortCommentsAndReviews: () =>
    reviews = [ ]
    comments = [ ]
    for comment in @state.note.comments
      if (comment.ctype == 'formal') or (comment.ctype == 'formal_anon')
        reviews.push(comment)
      else
        comments.push(comment)
    @setState({reviews: reviews})
    @setState({comments: comments})

  componentDidMount: () =>
    common.loginMonitor (data) =>
      current_user = data['current_user']
      prefix = if current_user then current_user + ' ' else ''
      logged_in_userinfo =
        logged_in_user: if current_user then current_user else 'anonymous'
        logged_in_userid: data['userid']
      @setState({
        data: logged_in_userinfo
        default_txt: prefix + 'leave a new comment...'
      })
      @sortCommentsAndReviews()

  submitProjectNoteComment: () =>
    newcomment =
      noteid: @props.note.id
      text: @writingRef.current.getValue()
      editor: @editorChoiceRef.current.getEditor()

    common.submitProjectNoteComment(newcomment, (result) =>
      @writingRef.current.setValue("")
      @setState({
        default_txt: '',
        note: result['note']
      }, @sortCommentsAndReviews)
    )

  render: =>
    if (@state.data)
      if @state.comments.length > 0
        comment_roll =
          <span>
              <div className='row no-gutters'>
                <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                  <strong>Comments</strong>
                </div>
              </div>
              <div>
                {@state.comments.map((comment) =>
                  <NoteCommentRoll key={comment.id} comment={comment} data={@state.data} />
                , this)}
              </div>
          </span>
      else
        comment_roll =
        <div className='row no-gutters'>
          <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
            <div className='row no-gutters'>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                <strong>Comments</strong>
              </div>
              <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                none
              </div>
            </div>
          </div>
        </div>

      if @state.reviews.length > 0
        review_roll =
          <span>
            <div className='row no-gutters'>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                <strong>Reviews</strong>
              </div>
            </div>
            <div>
              {@state.reviews.map((review) =>
                <NoteCommentRoll key={review.id} comment={review} data={@state.data} reviewq={@props.reviewq}/>
              , this)}
            </div>
          </span>
      else
        review_roll =
          <span>
            <div className='row no-gutters'>
              <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                <div className='row no-gutters'>
                  <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                    <strong>Reviews</strong>
                  </div>
                  <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                    none
                  </div>
                </div>
              </div>
            </div>
          </span>
    else
      comment_roll = null
      review_roll = null

    editor_options =
      <EditorChoice ref={@editorChoiceRef} />

    get_writing_line =
      <span>
        <div className="d-flex flex-row bd-highlight">
          <div className='bd-highlight'>
            Comment: &nbsp;
          </div>
          <div className='bd-highlight'>
            {editor_options}
          </div>
        </div>
        <div className="d-flex flex-row bd-highlight">
          <GetWriting ref={@writingRef} key={@state.note.id} rows='1'
            placeholder={@state.default_txt} defaultValue="" />
          <span>
              <button className='btn btn-success'
                      onClick={@submitProjectNoteComment}>submit</button>
          </span>
        </div>
      </span>

    current_user = @state.data.logged_in_user
    if (@state.data.logged_in_user != 'anonymous')
      comment_form =
          <div className='row no-gutters'>
            <div className='col-sm-12'>
              {get_writing_line}
            </div>
          </div>

    <span>
      {review_roll}
      {comment_roll}
      {comment_form}
    </span>
