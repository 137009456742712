common = require('../common')

module.exports = class VideoPreviewRoll extends React.Component
  displayName: 'VideoPreviewRoll'

  constructor: (props) ->
      super(props)
      # expects props:
      # video
      # fn removeVideoFromNote
      # user_can_edit
      @state = {
      }

  embedVideo: () =>
      if @props.video.url.link.length > 50
          video_text = @props.video.url.link.slice(0,50) + '...'
      else
          video_text = @props.video.url.link

      embed = 'false'
      embed_link = @props.video.url.link

      if (@props.video.url.link.includes("youtube")) or (@props.video.url.link.includes("youtu.be"))
          embed = 'true'
          pos1 = embed_link.lastIndexOf('watch?v=')
          if pos1 > 0
            pos1 = pos1 + 8
          else
            # link is of the form: https://youtu.be/youtubeID or https://youtu.be/sgGAl2ZAxiA?t=236
            pos1 = embed_link.lastIndexOf('/') + 1
          pos2 = embed_link.lastIndexOf('&')
          if pos2 < 0
            pos2 = embed_link.length + 1
          youtubeID = embed_link.substring(pos1, pos2)
          embed_link = 'https://www.youtube.com/embed/' + youtubeID

      if embed is 'true'
          <div>
              <div>
                  <iframe width="560" height="315"
                      src={embed_link}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>
                  </iframe>
              </div>
              <div>
                  <button className='btn btn-link btn-sm m-0 p-0 truncate'
                          onClick={@goToLink}>{ video_text }
                  </button>
              </div>
          </div>
      else
          <button className='btn btn-link btn-sm m-0 p-0 truncate'
                  onClick={@goToLink}>{ video_text }
          </button>

  goToLink: () =>
      link = @props.video.url.link
      if link.slice(0,4) is 'http'
          window.open(link)
      else
          window.open('http://' + link)

  tellParentToDelete: () =>
        @props.removeVideoFromNote(@props.video)

  deleteButton: () =>
      if (@props.user_can_edit is 'true')
          return(
              <button className="btn btn-danger"
                      onClick={@tellParentToDelete}>
                      delete video</button>
          )


  viewDocumentinNewWindow: () =>
      url = "/project/" + @props.obj.project_id + "/title/" + @props.obj.paperseries_id + "/video/" + @props.video.id + "/vtt/" + @props.video.vtt.id
      window.open(url)

  vttFile: () =>
      if @props.video.vtt
        if (@props.video.vtt.id) and (@props.video.vtt.body_blawb_id)
            return(
                <span>
                    VTT file included:
                    <button className='btn btn-link mr-2 ml-2 same-line'
                        onClick={@viewDocumentinNewWindow}>
                        open
                    </button>
                </span>
            )

  render: =>

      if @props.video.url.caption.length > 0
          caption_text =
              <p className='p-0 m-0'>{ @props.video.url.caption }</p>

      if @state.mode is 'removed'
          <div className='row no-gutters pb-3 pt-3 border-top'>
              Removed!
          </div>
      else
          <div className='row no-gutters pb-3 pt-3 border-top'>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  {@embedVideo()}
                  { caption_text }
              </div>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  { @vttFile() }
              </div>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  { @deleteButton() }
              </div>
          </div>
