Link = require('react-router-dom').Link

module.exports = class TagNoteRoll extends React.Component
  displayName: 'TagNoteRoll'

  # to limit search to within a project, send @props.projectid
  # send link_type to specify 'no_link' or 'underline_link'

  render: =>

      if (@props.tag_type is 'conference')
          button_classname = 'text-success btn-txt-sm btn_link_align mr-0 ml-0 pr-0 pl-0'
      else if (@props.tag_type is 'lab_group')
          button_classname = 'text-success btn-txt-sm btn_link_align mr-0 ml-0 pr-0 pl-0'
      else
          button_classname = 'text-primary btn-txt-sm btn_link_align mr-0 ml-0 pr-0 pl-0'



      if @props.projectid # search inside a project/conference/lab_group
          if @props.note_type is 'project'
              tag_button =
                  <Link to={ '/search/project/' + @props.projectid + '/tags/' + @props.tag.word }>
                      <span className={ button_classname }>{@props.tag.word}</span>
                  </Link>
          else if @props.note_type is 'lab_group'
              tag_button =
                  <Link to={ '/search/lab_group/' + @props.projectid + '/tags/' + @props.tag.word }>
                      <span className={ button_classname }>{@props.tag.word}</span>
                  </Link>
          else
              tag_button =
                  <Link to={ '/search/conference/' + @props.projectid + '/tags/' + @props.tag.word }>
                      <span className={ button_classname }>{@props.tag.word}</span>
                  </Link>
      else
          # search across ZeroDivZero
          tag_button =
              <Link to={ '/search/tags/' + @props.tag.word }>
                  <span className={ button_classname }>{@props.tag.word}</span>
              </Link>

      if @props.link_type is 'no_link'
          tag_button =
                <span>
                    {@props.tag.word}
                </span>


      <span className='mr-0 pr-0 ml-0 pl-0'>
          {tag_button}{@props.comma}&nbsp;
      </span>
