HeaderBar = require '../header_bar'
Policies = require './policies'

module.exports = class CommunityGuidelines extends React.Component
  displayName: 'CommunityGuidelines'

  componentDidMount: =>
      document.title = 'ZeroDivZero community guidelines'

  render: =>
      <div>
        <HeaderBar />
        <div className="text-left">
          <h1 className="text-left">Community Guidelines</h1>
          <p className="text-left">
            Welcome to ZeroDivZero!
          </p>
          <p className="text-left">
            We have developed some community guidelines (still a work-in-progress)
            to keep the ZeroDivZero website as helpful and fun as possible.
          </p>
          <h2 className="text-left">Suspendable Offenses</h2>
          <p className="text-left">
            The following activities are never ok on ZeroDivZero:
            <ul>
              <li>Hate speech.</li>
              <li>Insults and threats.</li>
              <li>Sexually explicit content.</li>
              <li>Advertising or promoting services on ZeroDivZero.</li>
              <li>Other sorts of spammy behavior, for instance, sending mass
                  invites for your conference without regard for the interest
                  of other users.</li>
            </ul>
          </p>
          <h2 className="text-left">Tone and Attitude</h2>
          <p className="text-left">
            <ul>
              <li>Assume other users are behaving with good intentions, even
                  if it might not appear that way.</li>
              <li>Be polite and respectful.</li>
            </ul>
          </p>
          <h2 className="text-left">Have more suggestions for this page?</h2>
          <p className="text-left">
            help@0div0.com
          </p>
        </div>
        <Policies />
      </div>
