common = require('./common')
Abstract = require('./abstract')
AcceptedToConferenceRoll = require('./accepted_to_conference_roll')
AuthorNameRoll = require('./authorcontact/author_name_roll')
Bookmark = require('./bookmark')
CreateNoteMetadata = require('./create_title/create_note_metadata')
DocumentButton = require('./uploads/document_button')
Materials = require('./uploads/materials')
Motive = require('./motive')
NoteFullComments = require('./note_full_comments')
NoteReplications = require('./replication/note_replications')
PosterButton = require('./uploads/poster_button')
ReplicationStudy = require('./replication/is_study')
SeminarScheduler = require('./schedule/seminar_scheduler')
SocialSharing = require('./social/social_sharing')
SlidesButton = require('./uploads/slides_button')
TagNoteRoll = require('./tags/tag_note_roll')
TitleLarge = require('./titles/title_large')
VerticalVoteComponent = require('./social/vertical_vote_component')
Videos = require('./video/videos')

module.exports = class ProjectNoteDetails extends React.Component
  displayName: 'ProjectNoteDetails'

  constructor: (props) ->
    super(props)
    note = @props.note
    @state = {
      note: note
      data: {noteid: note.id, votevalue: note.myvote}
      mode: 'viewing'
      note_editor: @props.note.editor
      roles: @props.roles
      user_can_edit: 'false'
      user_owns_project: 'false'
      user_can_schedule: 'false'
      shareUrl : ''
    }

  setFineGrainEditingPerms: () =>
      author_userids = []
      for author in @props.note.authorcontacts
          author_userids.push(author.user_id)

      # can schedule if the user is an organizer (aka chair)
      rolewords = []
      for role in @props.roles
          rolewords.push(role.role)

      # Check first that the user is logged in!
      # Otherwise @props.login_id = null and if there's an author who does
      # not have an account, author_userids can have a null. So the non-logged
      # on user can edit
      if @props.login_id
          if (@props.login_id is @props.project.user_id)
              @setState({
                  user_can_edit: 'true',
                  user_can_schedule: 'true',
                  user_owns_project: 'true' })
          if (@props.login_id in author_userids)
              @setState({ user_can_edit: 'true' })
          if (@props.login_id is @props.note.user_id)
              @setState({ user_can_edit: 'true' })
          if 'chair' in rolewords
              @setState({ user_can_schedule: 'true', user_can_edit: 'true' })

  getShareUrl: () =>
        if @props.note.note_type is 'project'
            source_folder = 'project'
        else if @props.note.note_type is 'conference'
            source_folder = 'conference'
        else
            source_folder = 'lab_group'
        shareUrl = 'https://zerodivzero.com/' + source_folder + '/' + @props.note.project_id + '/title/' + @props.note.paperseries_id
        @setState({ shareUrl: shareUrl })

  componentDidMount: =>
      @getShareUrl()
      @setFineGrainEditingPerms()


  componentDidUpdate: (prevProps) =>
      if @props.login_id != prevProps.login_id
        @getShareUrl()
        @setFineGrainEditingPerms()

  toggleReadEditMode: () =>
    if @state.mode is 'editing'
      @setState({ mode: 'viewing' })
    else @setState({ mode: 'editing' })

  cancelEditing: () =>
    @setState({ mode : 'viewing' })

  # updateMetaData: () =>
  #   note = @state.note
  #   note.title = @refs.note_title.value
  #   note.abstract = @refs.note_abstract.value
  #   note.motive = @refs.note_motive.value
  #   note.tags = @refs.note_tags.value
  #
  #   data =
  #     noteid: @state.note.id
  #     note_title: note.title
  #     note_abstract: note.abstract
  #     note_motive: note.motive
  #     note_tags: note.tags
  #     note_editor : @state.note_editor
  #
  #   common.submitProjectNoteMetadata(data, (result) =>
  #     updated_note = note
  #     updated_note.id = result.noteid
  #     updated_note.tags = result.tags
  #     # sidenote: authorcontacts and comments are reloaded
  #     # because they are modified in another module which does not
  #     # communicate the results back up. Could possibly eliminate
  #     # re-sending the comments.
  #     updated_note.authorcontacts = result.authorcontacts
  #     updated_note.comments = result.comments
  #     @setState({ note: updated_note })
  #     @toggleReadEditMode()
  #   )

  setEditor: (choice) =>
      if choice in ['MathJax', 'SetInnerHTML']
          @setState[{note_editor: choice}]

  setMode: (mode_option) =>
      if mode_option is 'viewing'
          window.location.reload()
      else if mode_option is 'cancelled'
          @setState({ mode: 'viewing' })
      else
          @setState({ mode: mode_option })

  replicationsBar: =>
      if (@props.project.ptype is 'short_course') or (@props.project.ptype is 'seminar')
          <span></span>
      else
          <div className='row no-gutters'>
            <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
            <strong>Replications</strong>
            </div>
            <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                <NoteReplications key={Math.random()}
                                  note={@props.note} />
            </div>
          </div>

  allMaterialsBar: =>
      <div>
          <DocumentButton
              note={@props.note}
              user_is_author={'false'} />

          <SlidesButton
              note={@props.note}
              user_is_author={'false'} />

          <PosterButton
              note={@props.note}
              user_is_author={'false'} />

          <div>
              <Materials
                  obj={@props.note}
                  user_is_author={@state.user_is_author}
                  mode={''} />
          </div>

          <Videos
              obj={@state.note}
              obj_type={'note_video'}
              user_is_author={'false'} />
      </div>

  programScheduler: () =>
      schedulable_notes = ['seminar', 'short_course', 'conference']
      if (@props.note.note_type in schedulable_notes)
          <div className='row'>
              <div className='col'>
                  <SeminarScheduler note={@props.note}
                      user_can_edit={@state.user_can_schedule} />
              </div>
          </div>

  acceptanceInfo: () =>
      if (@state.note.accepting_conferences) and (@state.note.accepting_conferences.length > 0)
          <div className='row no-gutters'>
              <span>
                  <div className="btn-group-vertical">
                  <button className="btn btn-link">
                      <i className="fa fa-trophy text-success"></i></button>
                  </div>
              </span>
              Accepted to:&nbsp;
              {@props.note.accepting_conferences.map((conference_infolist) =>
                    <AcceptedToConferenceRoll key={conference_infolist[0]}
                              conference_infolist={conference_infolist} />
              )}
          </div>

  deleteOrUpdateButton: () =>
    if @state.user_can_edit is 'true'
        <span>
            <Bookmark note={@props.note}
                      roles={@props.roles} />
            <SocialSharing shareUrl={@state.shareUrl}
                title={ @props.note.title }/>
            <button className="btn btn-success btn-sm" onClick={@toggleReadEditMode}>
              Edit
            </button>
        </span>
    else
        <span>
            <Bookmark note={@props.note}
                      roles={@props.roles} />
            <SocialSharing shareUrl={@state.shareUrl}
                title={ @props.note.title }/>
        </span>

  thisNoteReplicates: () =>
    if (@props.note.is_replication_study)
        <ReplicationStudy
            replications={@state.note.is_replication_study}
            rtype={'paper'}
            editor={@state.note.editor}
            mode={'view'}/>

  sessionsBar: () =>
      if @props.note.note_type is 'lab_group'
          <div className='row no-gutters'>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                  <strong>Topics: </strong>
              </div>
              <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                  {@props.note.conference_tags.map((tag) =>
                      <TagNoteRoll key={tag.id} tag={tag} tag_type='conference' note_type='conference' projectid={@props.note.project_id} />
                  )}
              </div>
          </div>



  render: =>

    if @state.mode is 'viewing'
      <div className='note_infoDiv'>
        <div className='container-fluid'>
          <div className='row no-gutters'>
              <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  <VerticalVoteComponent key={@props.note.id} note={@props.note}/>
              </div>
              <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
                  <TitleLarge title={@props.note.title}
                              editor={@props.note.editor} />
              </div>
              <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                  { @deleteOrUpdateButton() }
              </div>
              { @acceptanceInfo() }
          </div>

          { @programScheduler() }
          { @thisNoteReplicates() }

          <div className='row no-gutters'>
            <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
              <strong>Authors</strong>
            </div>
            <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
              {@props.note.authorcontacts.map((authorcontact) =>
                <AuthorNameRoll key={authorcontact.id} authorcontact={authorcontact} />
              )}
            </div>
          </div>

          { @allMaterialsBar() }

          <div className='row no-gutters mb-3 abstract_format'>
              <span>
                  <Abstract abstract={@props.note.abstract}
                      editor={@props.note.editor}/>
              </span>
          </div>

          <div className='row no-gutters align-items-start mb-3 mt-3'>
              <Motive
                  editor={@props.note.editor}
                  noteid={@props.note.id}
                  view_mode={'full'} />
          </div>

          <div className='row no-gutters'>
            <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
              <strong>Tags</strong>
            </div>
            <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
              {@props.note.tags.map((tag) =>
                <TagNoteRoll key={tag.id}
                             tag={tag}
                             note_type={'project'}
                             projectid={ @state.note.project_id }  />
              )}
            </div>
          </div>

          { @sessionsBar() }

          { @replicationsBar() }

          <NoteFullComments key={@props.note.commentable_id}
                            note={@state.note} />
        </div>
      </div>


    else if @state.mode is 'editing'
        <CreateNoteMetadata project={@props.project}
                            setMode={@setMode}
                            version='update'
                            note={@props.note} />

    else
        <span></span>
