common = require('../common')
ConferenceFeeLineDeletable = require('./conference_fee_line_deletable')

module.exports = class SetConferenceFees extends React.Component
  displayName: 'SetConferenceFees'

  constructor: (props) ->
      super(props)
      fees = @props.fees
      # props are: fees, confid
      @state = {
        fees: fees
        show_fee_setter: 'false'
        error_msg: ''
      }

  componentDidMount: =>
    if @props.fees.length == 0
          @setState({ show_fee_setter: 'true' })

  componentDidUpdate: (prevProps) =>
      if @props.fees != prevProps.fees
        if @props.fees.length == 0
              @setState({ show_fee_setter: 'true' })

  turnOnFeeSetter: =>
      @setState({ show_fee_setter: 'true' })

  cancelEdit: =>
      @setState({ show_fee_setter: 'false' })

  goToSaveFee: =>
      amount = Number(@refs.amount.value)
      if (isNaN(amount)) or (amount<0)
          @setState({ error_msg: ' invalid fee' })
          return

      category = @refs.category.value.toLowerCase().trim()
      if (category.length == 0)
          @setState({ error_msg: ' invalid category' })
          return

      upmarked_amount = Math.round(Number(amount*1.1))
      if upmarked_amount - amount < 0
          upmarked_amount = upmarked_amount + 1
      our_charge = upmarked_amount - amount
      amount = upmarked_amount

      data =
          confid: @props.confid
          amount: amount
          our_charge: our_charge
          category: category

      common.saveConferenceFeeType( data, (result) =>
          if result.error
              @setState({ error_msg: result.error })
          else
            current_fees = @state.fees
            current_fees.push(result['fee'])
            @setState({ show_fee_setter:'false' })
      )

  deleteFeeRow: (fee) =>

      data =
          fee_id: fee.id

      common.hideFeeFromConference(data, (result) =>
         fees = @state.fees
         pos = fees.indexOf(fee)
         fees.splice(pos,1)
         @setState({ fees: fees })
      )

  render: =>

      if @state.show_fee_setter is 'true'
        add_fee_line =
            <tr>
                <td>
                    <input className='medium_input_textbox'
                           type='text'
                           ref='category'></input>
                </td>
                <td>
                    $<input className='medium_input_textbox'
                            type='text'
                            ref='amount'></input>
                </td>
                <td>
                    <button className="btn btn-primary"
                            onClick={@goToSaveFee}>
                            save</button>
                    <button className="btn btn-primary"
                            onClick={@cancelEdit}>
                            cancel</button>
                </td>
            </tr>
        error_line =
            <span className='text-danger'>
                { @state.error_msg }
            </span>



      <div>
          <strong>Creator Panel - Set Categories and Fees</strong>
          <br />
          <p className='instructions'>
              <span className='text-danger'>
                Example category names: member, student, industry, general, etc.
              </span>
              <br />
              Attendees who register here will receive the roles 'registered' and the category name.
              <br />
              ZeroDivZero adds a 10% fee to cover payment processing and service fees.
          </p>
          <table className="table">
              <thead>
                  <tr>
                      <th scope="col">Category</th>
                      <th scope="col">Amount (USD)</th>
                      <th scope="col">Delete?</th>
                  </tr>
              </thead>
                  <tbody>
                      {@state.fees.map((fee) =>
                          <ConferenceFeeLineDeletable
                              key={fee.id}
                              fee={fee}
                              onClick={ @deleteFeeRow.bind(this,fee) } />
                      ,this)}
                      { add_fee_line }
                  </tbody>
          </table>
          <button className="btn btn-primary"
                  onClick={@turnOnFeeSetter}>
                  Add/Edit</button> &nbsp; { error_line }
      </div>
