
module.exports = class NoteTitleOnly extends React.Component
  displayName: 'NoteTitleOnly'

  constructor: (props) ->
      super(props)
      @state = {
      }

  render: =>

      <li>
          {@props.title}
      </li>
