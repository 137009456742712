Loading = require('../loading')
common = require('../common')
AuthorProfileNameRoll = require('../authorcontact/author_profile_name_roll')
NameTagsView = require('./nametags_view')
Pagination = require('../pagination')

module.exports = class ConferenceRegister extends React.Component
  displayName: 'ConferenceRegister'

  constructor: (props) ->
    super(props)
    # expects props: projectid
    @state = {
      mode: ''
      nametags: ''
      msg: ''

      length_items: 0
      page_num: 1
      lengthy: 2
      num_per_page: 10
    }

  componentDidMount: =>
      #

  getBadgesForPreview: =>
      data =
          projectid: @props.projectid
          download: 'false'
          page_num: 1
          num_per_page: @state.num_per_page

      common.getPageNameTagsForConference(data, (result) =>

          if (result) and (result.msg)
              @setState({ msg: result.msg})
          else if (result)
              @setState({ nametags: result.nametags, mode: 'show_badges'})
          else
              @setState({ msg: 'There was an error. Try again?' })
        )

      data =
          projectid: @props.projectid

      common.getTotalNumberNameTags(data, (result) =>
          if (result.total)
              lengthy = Math.ceil(result.total/@state.num_per_page)
              @setState({ lengthy: lengthy, length_items: result.total })
          else
              @setState({ lengthy: 0, length_items: 0 })
      )

  getThesePages: (info) =>

      data =
          page_num: info
          num_per_page: @state.num_per_page
          projectid: @props.projectid

      common.getPageNameTagsForConference(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid project' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })
          else
            @setState({ nametags: result.nametags,  page_num: info })

          elem = @refs.badges
          window.scrollTo(0, elem.offsetTop)
      )

  closeBadgeView: =>
      @setState({ mode: '' })


  downloadBadges: =>

      data =
          projectid: @props.projectid

      common.downloadAllNameTagsForConference(data, (result) =>

          console.log(result)

          if (result) and (result.msg)
              @setState({ msg: result.msg })

          common.deleteUserNameTagFlaskFolder (result) =>
              # This deletes the user's folder containing nametag info
              # Basically, cleanup
      )

  render: =>

      if (@state.mode is 'show_badges') and (@state.length_items == 0)
          visual_badges =
              <div>
                  No badges created yet.
              </div>

      else if (@state.mode is 'show_badges')
          visual_badges =
              <div ref='badges'>
                  <br />
                  <strong>Here are the current name tags:</strong>
                  <div>
                      <span>
                          {@state.nametags.map((nametag) =>
                              <NameTagsView key={nametag.id}
                                            authorcontacts={nametag.authorcontacts}
                                            userid={ @props.userid }/>
                          ,this)}
                      </span>
                      <Pagination page_num={@state.page_num}
                          lengthy={@state.lengthy}
                          onClick={@getThesePages} />
                      <button className='btn btn-primary'
                              onClick={@closeBadgeView}>close</button>
                  </div>
              </div>



      <div>
          <button className='btn btn-primary btn-txt-sm'
                  onClick={@getBadgesForPreview}>
                  View</button> or
          <form method="POST" action="/api/download_all_nametags_for_conference">
            <input type="hidden" name="projectid" value={@props.projectid} />
            <button type="submit" className='btn btn-primary btn-txt-sm mr-2 ml-2'>
                    Download</button>
          </form>
          conference badges
          <br />
          { @state.msg }
          <span>
          { visual_badges }
          </span>
      </div>
