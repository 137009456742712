common = require('../common')
Loading = require('../loading')
UrlLinkRoll = require('./url_link_roll')
UrlLinkRollEditable = require('./url_link_roll_editable')

module.exports = class UrlLinks extends React.Component
  displayName: 'UrlLinks'

  constructor: (props) ->
      super(props)
      # expects
      # url_linkable_id from an object
      # obj_type (user, note, etc)
      # current_userid
      # obj_id
      @state = {
          mode: 'loading'
          urls : []
          err_msg: ''
      }
      @captionRef = React.createRef()
      @linkRef = React.createRef()


  getLinks: () =>
      data =
          ltype: @props.obj_type
          id: @props.obj_id

      common.getUrlLinks(data, (result) =>
          if (result) and (result.url_links)
              @setState({ urls: result.url_links, mode: 'reading' })
          else if (result)
              @setState({ urls: [], mode: 'reading' })
      )


  componentDidMount: =>
      @getLinks()

  componentDidUpdate: (prevProps) =>
      if @props.obj_id != prevProps.obj_id
          @getLinks()


  validURL: (link) =>

      if link.length < 5
          return false
      if link.slice(0,8) is 'https://'
          link = link.slice(8)
      if link.slice(0,7) is 'http://'
          link = link.slice(7)

      regex = /^[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/

      if not regex.test(link)
          return false
      else
          return true

  sendLink: () =>

      caption = @captionRef.current.value
      if caption.length > 140
          @setState({ err_msg: 'Max 140 characters for description.'})
          return

      link = @linkRef.current.value
      if link.length > 260
          @setState({ err_msg: 'Max 260 characters for link.'})
          return

      if not @validURL(link)
          @setState({ err_msg: 'Please enter a valid URL.'})
          return

      data =
          link: link
          ltype: @props.obj_type
          id: @props.obj_id
          caption: caption

      common.saveUrlLink(data, (result) =>
          updated_links = @state.urls
          local_link =
              id: result.result
              link: data.link
              caption: data.caption

          updated_links.push(local_link)
          @setState({ urls: updated_links, mode: 'reading', err_msg: '' })
      )


  openLinkUploader: () =>
      @setState({ mode: 'open_loader' })

  render: =>

      if (@state.urls) and (@state.urls.length > 0) and (@props.user_is_owner is 'true')
          url_link_roll =
              <span>
                  {@state.urls.map((url) =>
                      <UrlLinkRollEditable key={url.id}
                          url={url} note={@props.obj} />
                  )}
              </span>
          add_button =
              <button className='btn btn-primary btn-txt-sm'
                      onClick={@openLinkUploader}>Add link</button>
      else if (@state.urls) and (@state.urls.length > 0)
          url_link_roll =
              <span>
                  {@state.urls.map((url) =>
                      <UrlLinkRoll key={url.id} url={url} note={@props.obj} />
                  )}
              </span>
      else if (@props.user_is_owner is 'true')
          url_link_roll = <span>none &nbsp;</span>
          add_button =
              <button className='btn btn-primary btn-txt-sm'
                      onClick={@openLinkUploader}>Add link</button>
      else
          url_link_roll = <span>none</span>


      if @state.mode is 'loading'
          <div>
              <Loading />
          </div>
      else if (@state.mode is 'reading')
          <div>
              { url_link_roll }
              { add_button }
          </div>
      else if (@state.mode is 'open_loader')
          <div>
              { url_link_roll }
              <div className='row no-gutters'>
                 <input className='headerInput'
                        type="text"
                        placeholder='Enter your link...'
                        ref={@linkRef}></input>
              </div>
              <div className='row no-gutters'>
                 <input className='headerInput'
                        type="text"
                        placeholder="Optional brief description"
                        ref={@captionRef}></input>
              </div>
              <button className='btn btn-primary'
                      onClick={@sendLink}>Save</button>
              <span className='text-danger'> &nbsp; {@state.err_msg}</span>
          </div>
      else
          <span></span>
