Link = require('react-router-dom').Link
common = require('./common')
HeaderBar = require('./header_bar')
HeaderNotificationRoll = require('./header_notification_roll')
Loading = require('./loading')
Pagination = require('./pagination')
Policies = require('./site_info/policies')

module.exports = class Notifications extends React.Component
  displayName: 'Notifications'

  constructor: (props) ->
      super(props)
      @state = {
        data: { logged_in_user: 'anonymous' }
        userid : ''
        notifications: [ ]
        sort_type: 'recent'

        page_num: 1
        num_per_page: 20
        lengthy: 2
      }
      @topOfNotifsRef = React.createRef()

  componentDidMount: =>
      document.title = 'ZeroDivZero personal notifications'

      data =
          page_num: 1
          num_per_page: @state.num_per_page
          sort_type: @state.sort_type

      common.getTotalNumberNotifications(data, (result) =>
        lengthy = Math.ceil(result.notification_count/@state.num_per_page)
        @setState({ lengthy: lengthy})
      )

      common.getUserNotificationsPage(data, (result) =>
          @setState({ notifications: result['result'] })

          data =
              sort_type: @state.sort_type
              num_per_page: @state.num_per_page
              page_num: 1

          if @state.notifications.length > 0
              common.markUserNotificationsSeen(data, (result) =>
                  notifications = @state.notifications
                  for msg in notifications
                      msg.seen_at = 1
                  @setState({ notifications: notifications })
              )
          )

  getThesePages: (info) =>
      if typeof info is 'undefined'
          info = 1

      data =
          page_num: info
          num_per_page: @state.num_per_page
          sort_type: @state.sort_type

      common.getUserNotificationsPage(data, (result) =>
          @setState({ notifications: result['result'], page_num: info })
          elem = @topOfNotifsRef.current
          window.scrollTo(0, elem.offsetTop)

          data =
              sort_type: @state.sort_type
              num_per_page: @state.num_per_page
              page_num: @state.page_num

          if @state.notifications.length > 0
              common.markUserNotificationsSeen(data, (result) =>
                  notifications = @state.notifications
                  for msg in notifications
                      msg.seen_at = 1
                  @setState({ notifications: notifications })
              )
          )

  toggleSortChoice: =>
      if @state.sort_type is 'recent_unseen'
          @setState({ sort_type: 'recent' }, @getThesePages)
      else
          @setState({ sort_type: 'recent_unseen' }, @getThesePages)

  render: =>
    if @state.sort_type is 'recent_unseen'
        select_options_buttons =
            <span>
                <button className='btn btn-success'>unseen</button>
                <button className='btn btn-secondary' onClick={@toggleSortChoice}>recent</button>
            </span>
    else
        # @state.sort_type is 'recent'
        select_options_buttons =
            <span>
                <button className='btn btn-secondary' onClick={@toggleSortChoice}>unseen</button>
                <button className='btn btn-success'>recent</button>
            </span>


    if @state.notifications.length?
      if @state.notifications.length is 0 and @state.sort_type is 'recent_unseen'
        <div ref={@topOfNotifsRef}>
            <HeaderBar />
            <br />
            <div className='container-fluid'>
                <h2 className='mt-3'>Your notifications</h2>
                <br />
                Show: &nbsp; { select_options_buttons }
                <br />
                <br />
                <h2>No unseen notifications...</h2>
            </div>
            <div className='container-fluid mt-3'>
                <div className='bg-white mt-3'>
                    <Policies />
                    <br />
                </div>
            </div>
        </div>
      else if @state.notifications.length is 0 and @state.sort_type is 'recent'
        <div ref={@topOfNotifsRef}>
            <HeaderBar />
            <br />
            <div className='container-fluid'>
                <h2 className='mt-3'>Your notifications</h2>
                <br />
                Show: &nbsp; { select_options_buttons }
                <br />
                <br />
                <h2>No notifications yet...</h2>
            </div>
            <div className='container-fluid mt-3'>
                <div className='bg-white mt-3'>
                    <Policies />
                    <br />
                </div>
            </div>
        </div>
      else
        <div ref={@topOfNotifsRef}>
            <HeaderBar />
            <br />
            <div className='container-fluid'>
                <h2 className='mt-3'>Your notifications</h2>
                <br />
                Show: &nbsp; { select_options_buttons }
                <br />
                <br />
                {@state.notifications.map((msg) =>
                    <HeaderNotificationRoll key={msg.id}
                        notification={msg} />
                )}
                <Pagination page_num={@state.page_num}
                    lengthy={@state.lengthy}
                    onClick={@getThesePages} />
            </div>
            <div className='container-fluid mt-3'>
                <div className='bg-white mt-3'>
                    <Policies />
                    <br />
                </div>
            </div>
        </div>
    else
        # notifications does not exist, so still loading
        <div ref={@topOfNotifsRef}>
            <HeaderBar />
            <br />
            <div className='container-fluid'>
                <Loading />
            </div>
            <div className='container-fluid mt-3'>
                <div className='bg-white mt-3'>
                    <Policies />
                    <br />
                </div>
            </div>
        </div>
