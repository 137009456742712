Link = require('react-router-dom').Link
common = require('./common')
RouteHandler = require('react-router-dom').RouteHandler
EmailVerification = require('./account/email_verification')
HeaderNotifications = require('./header_notifications')
ManualLoginForms = require('./account/manual_login_forms')
GoogleLogin = require('./account/google_login')

# Global navigation for the app

module.exports = class HeaderBar extends React.Component
    constructor: (props) ->
        super(props)
        # accepts @props.redirect to specify location to load post login
        # accepts @props.mode to choose the mode to show
        mode = 'show_login_options'
        if (@props) and (@props.mode)
            mode = @props.mode
        @state = {
            data: { logged_in_user: 'anonymous', verified: 'false', email: '' }
            userid : ''
            provider : ''
            mode : mode
            manual_login_mode: 'manual_login'
            verified: ''
        }

    manualLogin: (thisMode) =>
        @setState({ manual_login_mode: thisMode, mode: 'manual_login' })

    logout: () =>
        common.logoutReset (result) =>
            if result.success
                window.location = '/'

    profileClicked: () =>
        window.location = '/profile/' + userid

    setCurrentUser: (data) =>
        current_user = data['current_user']
        whos_logged_in = 'anonymous'
        whos_logged_in = current_user if current_user
        verified = data['verified']
        email = data['email']
        @setState({
            data: {
                logged_in_user: whos_logged_in,
                verified: verified,
                email: email
            }
        })
        @setState({ userid: data['userid'] })

        if data['userid']
            @setState({ mode : 'show_account' })

    minimizeLogin: =>
        @setState({ mode: 'show_login_options' })

    componentDidMount: =>
        callback = (data) => this.setCurrentUser(data)
        common.loginMonitor(callback)

    reload: () =>
      window.location = '/'

    render: =>
        home_menu =
            <Link to="/">
                <button className='button-link-big-header clear_button'
                    onClick={@reload}>
                &nbsp;&nbsp;&divide;&nbsp;ZeroDivZero
                </button>
            </Link>


        help_menu =
            <Link to="/help">
                <button className="btn btn-link btn-link-header">
                    Help
                </button>
            </Link>

        logout_menu =
            <Link to="/">
                <button className="btn btn-link btn-link-header"
                        onClick={@logout}>
                        Logout
                </button>
            </Link>

        if @state.mode is 'show_account'
            logged_in_user_menu =
                <span>
                    <Link to={'/profile/' + @state.userid}>
                        <button className='btn btn-link btn-link-header'>
                        { @state.data.logged_in_user  }
                        </button>
                    </Link>
                </span>
            header_notifications_menu =
                <HeaderNotifications userid={@state.userid} />
            projects_menu =
                <Link to={'/profile/' + @state.userid + '/projects'}>
                    <button className="btn btn-link btn-link-header">
                        My Projects
                    </button>
                </Link>
            conferences_menu =
                <Link to={'/profile/'  + @state.userid + '/conferences'}>
                    <button className="btn btn-link btn-link-header">
                        My Conferences
                    </button>
                </Link>
            lab_groups_menu =
                <Link to={'/profile/'  + @state.userid + '/lab_groups'}>
                    <button className="btn btn-link btn-link-header">
                        My Labs
                    </button>
                </Link>
            seminars_menu =
                <Link to={'/profile/'  + @state.userid + '/seminars'}>
                    <button className="btn btn-link btn-link-header">
                        My Seminars
                    </button>
                </Link>
            short_courses_menu =
                <Link to={'/profile/'  + @state.userid + '/short_courses'}>
                    <button className="btn btn-link btn-link-header">
                        My Short Courses
                    </button>
                </Link>

        if (@props) and (@props.redirect)
            manual_login_forms =
                <ManualLoginForms mode={@state.manual_login_mode}
                                  redirect={@props.redirect}
                                  minimizeLogin={@minimizeLogin} />
        else
            manual_login_forms =
                <ManualLoginForms mode={@state.manual_login_mode}
                    minimizeLogin={@minimizeLogin}/>

        # Shouldn't need this -- just here as insurance -- might remove later
        account_verification_form =
            <EmailVerification verified={ @state.verified }
                                email={ @state.email }
                                userid={ @state.userid }
                                current_userid={ @state.userid }
                                type={ 'user_account' }
                                verifiedSubmitCreds={@verifiedSubmitCreds} />

        result = switch @state.mode
            when 'show_login_options'
                <div className='container-fluid'>
                    <div className='row no-gutters'>
                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                            <span className='float-left'>
                                { home_menu }
                            </span>
                            <span className="float-right">

                                <button className="btn btn-outline-primary ml-1 mb-3"
                                        onClick={@manualLogin.bind(this, "manual_login")}>Log in</button>
                                <button className="btn btn-primary ml-1 mb-3"
                                        onClick={@manualLogin.bind(this, "new_account_login")}>Sign Up</button>
                                <GoogleLogin style='ml-1 mb-3'/>

                            </span>
                        </div>
                    </div>
                </div>
            when 'just_show_login_buttons'
                <div className='container-fluid'>
                    <button className="btn btn-outline-primary ml-1 mb-3"
                            onClick={@manualLogin.bind(this, "manual_login")}>Log in</button>
                    <button className="btn btn-primary ml-1 mb-3"
                            onClick={@manualLogin.bind(this, "new_account_login")}>Sign Up</button>
                    <GoogleLogin style='ml-1 mb-3'/>
                </div>
            when 'manual_login'
                <div className='container-fluid'>
                    <div className='headerDiv'>
                    <div className='headerNav'>
                        { home_menu }
                    </div>
                    { manual_login_forms }
                    </div>
                </div>
            when 'show_account'
                <div className='container-fluid'>
                    <div className='row no-gutters'>
                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                            { home_menu }
                            { account_verification_form }
                        </div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                            { logged_in_user_menu }|
                            { projects_menu }|
                            { lab_groups_menu }|
                            { conferences_menu }|
                            { seminars_menu }|
                            { short_courses_menu }|
                            { help_menu }|
                            { header_notifications_menu }|
                            { logout_menu }
                        </div>
                    </div>
                </div>
            else
                <div className='container-fluid'>
                    <div className='headerDiv'>
                        <div className='headerName'>
                            { home_menu }
                        </div>
                    Invalid state {@state.mode}
                    </div>
                </div>
        return result
