DocumentFrame = require('./document_frame')

module.exports = class DocumentConferenceButton extends React.Component
    displayName: 'DocumentConferenceButton'

    constructor: (props) ->
        super(props)
        note = @props.note
        @state = {
            note: note
            mode: 'reading'
        }

    viewUserNoteDocument: () =>
        @setState({mode: 'viewing'})

    viewDocumentinNewWindow: () =>
        window.open('/conference/' + @props.note.project_id + '/title/' + @props.note.paperseries_id + '/document')

    hideDocument: () =>
        @setState({mode: 'reading'})

    render: =>
        # If the document exists at all!
        if (@state.mode is 'reading') and (@state.note.body_blawb_id)
              if @state.note.body_blawb_id is 'empty_submission'
                  document_button =
                      <button className="btn btn-link btn-items mr-2">
                          Paper (not submitted yet)
                      </button>
              else
                  document_button =
                      <button className="btn btn-link btn-items mr-2"
                          onClick={@viewDocumentinNewWindow}>
                          Paper
                      </button>

        else if @state.mode is 'viewing'
            filename = "/view_project_note_document/" + @state.note.id
            document_button =
                <div>
                    <button className="btn btn-link" onClick={@hideDocument}>hide document</button>
                    <DocumentFrame className="mini_view_document_iframe" src={filename} />
                    <button className="btn btn-link" onClick={@hideDocument}>hide document</button>
                </div>

        if (@state.note.body_blawb_id)
            <span className='mr-2'>
                {document_button}
            </span>
        else
            <span></span>
