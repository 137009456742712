Link = require('react-router-dom').Link
common = require('./common')
Abstract = require('./abstract')
AuthorNameRoll = require('./authorcontact/author_name_roll')
DocumentButton = require('./uploads/document_button')
DocumentConferenceButton = require('./uploads/document_conference_button')
Materials = require('./uploads/materials')
Motive = require('./motive')
NoteCommentsOnly = require('./comments/note_comments_only')
NoteReplications = require('./replication/note_replications')
NoteReviewsOnly = require('./comments/note_reviews_only')
PosterButton = require('./uploads/poster_button')
PosterConferenceButton = require('./uploads/poster_conference_button')
ReplicationStudy = require('./replication/is_study')
SlidesButton = require('./uploads/slides_button')
SlidesConferenceButton = require('./uploads/slides_conference_button')
SocialBar = require('./social/social_bar')
TagNoteRoll = require('./tags/tag_note_roll')
Videos = require('./video/videos')

module.exports = class NoteClickToShowDetails extends React.Component
  displayName: 'NoteClickToShowDetails'

  constructor: (props) ->
      super(props)

      show_abstract = 'preview'
      if @props.show_abstract
          show_abstract = @props.show_abstract

      show_motive = 'preview'
      if @props.show_motive
          show_motive = @props.show_motive

      preview_size = 500
      if preview_size > @props.note.abstract.length
        show_abstract = 'true'

      @state = {
        data: {noteid: @props.note.id, votevalue: @props.note.myvote}
        show_comments : 'false'
        show_abstract : show_abstract
        show_motive : show_motive
        note: @props.note
        show_reviews : 'false'
        user_is_author: 'false'
        preview_size: preview_size
      }

  gotoShowAbstract: () =>
      @setState({ show_abstract : 'true' })

  gotoHideAbstract: () =>
      # set it to the original version of show_abstract
      # if @props.show_abstract
      #     @setState({ show_abstract: @props.show_abstract })
      # else
      #     @setState({ show_abstract: 'preview' })
      @setState({ show_abstract: 'preview' })

  showTheComments: () =>
      # This is to avoid race conditions.
      @setState( show_comments : 'true' )

  gotoHideComments: () =>
      @setState( show_comments: 'false' )

  gotoToggleComments: () =>
      if @state.show_comments is 'false'
          data =
              projectid : @props.note.project_id
              noteid : @props.note.id
              ctype : 'informal'

          common.getNoteComments(data, (result) =>
              addCommentsToNote = @props.note
              addCommentsToNote.comments = result['comments']
              @setState({ note: addCommentsToNote }, @showTheComments())
          )
      else
          @gotoHideComments()

  revealReviews: =>
      @setState({ show_reviews: 'true' })

  gotoHideReviews: () =>
      @setState( show_reviews: 'false')

  gotoToggleReviews: () =>
      if @state.show_reviews is 'false'
          data =
              projectid : @props.note.project_id
              noteid : @props.note.id
              ctype : 'formal'

          common.getNoteComments(data, (result) =>
              addCommentsToNote = @props.note
              addCommentsToNote.comments = result['comments']
              @setState({ note: addCommentsToNote }, @revealReviews())
          )
      else
          @gotoHideReviews()

  replicationsInfo: () =>
      if ((@props.note.note_type != 'seminar') and (@props.note.note_type != 'short_course'))
          <div className='row no-gutters mb-2'>
                <NoteReplications note={@props.note} />
          </div>

  authorSection: () =>

      if (@props.note.note_type is 'seminar') or (@props.note.note_type is 'short_course')
          author_word = 'Speaker(s)'
      else
          author_word = 'Author(s)'

      if @props.note.authorcontacts.length > 0
          <div className='row no-gutters mb-2'>
              {author_word}:&nbsp;
              {@props.note.authorcontacts.map((authorcontact) =>
                  <AuthorNameRoll key={authorcontact.id} authorcontact={authorcontact} />
              )}
          </div>
      else if @props.note.note_type is 'project'
          <div className='row no-gutters mb-2 text-danger'>
              Authors must be added before submitting to a conference.
              Click the title, click edit, and add your authors!
          </div>


  abstractSection: () =>
      if @state.preview_size < @props.note.abstract.length
          hide_abstract_button =
            <span>
                &nbsp;
                <button className='btn btn-link'
                    onClick={@gotoHideAbstract}>hide</button>
            </span>

      if (@state.show_abstract is 'true') and (@props.note.abstract.length > 0)
          <span>
              <Abstract abstract={@props.note.abstract}
                  editor={@props.note.editor}/>
              { hide_abstract_button }
          </span>
      else if (@state.show_abstract is 'preview') and (@props.note.abstract.length > 0)
          <span>
              <Abstract
                  abstract={@props.note.abstract.substr(0, @state.preview_size)}
                  editor={@props.note.editor}/>
              <button className='btn btn-link'
                  onClick={@gotoShowAbstract}>
                  ...(more)
              </button>
          </span>

  motiveSection: () =>
          <span>
              <Motive
                  editor={@props.note.editor}
                  noteid={@props.note.id}
                  view_mode={@state.show_motive} />
          </span>

  commentsSection: () =>
      if @state.show_comments is 'true'
          <span>
              <NoteCommentsOnly note={@state.note}
                  reviewq={@props.reviewq}
                  ask_for_comments={'true'}/>
              <div className='row no-gutters'>
                  <button className='btn btn-link'
                      onClick={@gotoHideComments}>hide</button>
              </div>
          </span>

  reviewsSection: () =>
      if @state.show_reviews is 'true'
          <span>
              <NoteReviewsOnly note={@state.note} reviewq={@props.reviewq}/>
              <div className='row no-gutters'>
                <button className='btn btn-link'
                    onClick={@gotoHideReviews}>hide</button>
              </div>
          </span>

  tagsBar: () =>

      Ltags = @props.note.tags.length

      tags_list =
          <span>
              {@props.note.tags.map((tag, ctr) =>
                  if ctr+1 < Ltags
                      comma=<span>,</span>
                  <TagNoteRoll key={tag.id}
                      tag={tag}
                      note_type={@props.note.note_type}
                      projectid={@state.note.project_id}
                      comma={comma} />
              )}
          </span>

      if Ltags > 0
          <div className='row no-gutters mb-2'>
              { tags_list }
          </div>

  sessionsBar: () =>

      Lsessions = @props.note.conference_tags.length

      if (@props.note.note_type is 'lab_group')
              <span></span>


  materials_list: =>
      if @props.note.note_type is 'conference'
          <div>
              <span>
                  <DocumentConferenceButton note={@props.note} />
              </span>
              <span>
                  <SlidesConferenceButton note={@props.note} />
              </span>
              <span>
                  <PosterConferenceButton note={@props.note} />
              </span>
              <div>
                  <Materials obj={@props.note}
                      user_is_author={@state.user_is_author}
                      mode={''} />
              </div>
          </div>
      else if @props.note.note_type is 'short_course'
          # course materials rather than publications
          <div>
              <span>
                  <DocumentButton note={@props.note}
                      user_is_author={@state.user_is_author} />
              </span>
              <span>
                  <SlidesButton note={@props.note}
                      user_is_author={@state.user_is_author} />
              </span>
              <span>
                  <PosterButton note={@props.note}
                      user_is_author={@state.user_is_author} />
              </span>
              <Materials obj={@props.note}
                  user_is_author={@state.user_is_author}
                  mode={''} />
          </div>
      else
          # if hote_type is project, seminar, lab course
          <div>
              <span>
                  <DocumentButton note={@props.note}
                      user_is_author={@state.user_is_author} />
              </span>
              <span>
                  <SlidesButton note={@props.note}
                      user_is_author={@state.user_is_author} />
              </span>
              <span>
                  <PosterButton note={@props.note}
                      user_is_author={@state.user_is_author} />
              </span>
              <div>
                  <Materials obj={@props.note}
                      user_is_author={@state.user_is_author}
                      mode={''} />
              </div>
          </div>


  render: =>

      if @props.note.is_replication_study
          this_note_replicates =
              <ReplicationStudy replications={@state.note.is_replication_study}
                  rtype={'paper'}
                  editor={@props.note.editor}
                  mode={'view'}/>


      <div>

          <div className='row no-gutters mb-2'>
              { this_note_replicates }
          </div>

          { @authorSection() }

          { @materials_list() }

          <div className='row no-gutters mb-2'>
              <Videos obj={@props.note}
                  obj_type={'note_video'}
                  user_is_author={@state.user_is_author} />
          </div>

          <div className='row no-gutters mb-3 abstract_format'>
                  { @abstractSection() }
          </div>

          <div className='row no-gutters align-items-start mb-3 mt-3'>
                  { @motiveSection() }
          </div>

          { @sessionsBar() }
          { @tagsBar() }

          { @replicationsInfo() }

          <SocialBar
              note={@props.note}
              roles={@props.roles}
              gotoToggleComments={@gotoToggleComments}
              gotoToggleReviews={@gotoToggleReviews}  />

          { @reviewsSection() }
          { @commentsSection() }

      </div>
