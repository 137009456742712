Link = require('react-router-dom').Link
common = require('../../common')
InvitedUsersRoll = require('./invited_users_roll')

module.exports = class Roster extends React.Component
  displayName: 'Roster'

  constructor: (props) ->
      super(props)
      @state = {
        mode : 'roster collapsed'
        roles : [ ]
        contributors : [ ]
        inviteds : [ ]
        areachairs : [ ]
        chairs : [ ]
        creator : [ ]
      }


  goToViewRoster: =>
      data =
        projectid : @props.project.id

      common.conferenceRetrieveAllRoles(data, (result) =>
          @setState({ roles : result.combined_rolejunctions })

          creator = [ ]
          chairs = [ ]
          areachairs = [ ]
          inviteds = [ ]
          contributors = [ ]

          for role in @state.roles
            if role.role is 'creator'
                creator.push(role)
            if role.role is 'lead'
                chairs.push(role)
            if role.role is 'invited'
                inviteds.push(role)
            if role.role is 'contributor'
                contributors.push(role)


          @setState({ creator : creator })
          @setState({ chairs : chairs })
          @setState({ areachairs : areachairs })
          @setState({ inviteds : inviteds })
          @setState({ contributors : contributors })

          @setState({ mode : 'roster expanded '})

      )

  goToHideRoster: =>
      @setState({ mode : 'roster collapsed' })

  turnOnCreator: =>
      styles={
        display:'none'
      }
      if @state.creator.length > 0
          styles={
            display:'block'
          }
      return styles

  turnOnChairs: =>
      styles={
        display:'none'
      }
      if @state.chairs.length > 0
          styles={
            display:'block'
          }
      return styles

  turnOnAreaChairs: =>
      styles={
        display:'none'
      }
      if @state.areachairs.length > 0
          styles={
            display:'block'
          }
      return styles

  turnOnReviewers: =>
      styles={
        display:'none'
      }
      if @state.reviewers.length > 0
          styles={
            display:'block'
          }
      return styles

  turnOnInviteds: =>
      styles={
        display:'none'
      }
      if @state.inviteds.length > 0
          styles={
            display:'block'
          }
      return styles

  turnOnContributors: =>
      styles={
        display:'none'
      }
      if @state.contributors.length > 0
          styles={
            display:'block'
          }
      return styles


  render: =>

    if @state.mode is 'roster collapsed'
        <div>
            <button className="btn btn-primary"
                    onClick={@goToViewRoster}>View group members</button>
        </div>
    else
        <div>
            <button className="btn btn-primary"
                    onClick={@goToHideRoster}>Hide</button>
            <div>
                Legend:
                  <div className='pl-3'>
                      <button className='btn btn-primary btn-txt-sm'>&#10003; checkmark indicates user exists, has accepted their roles</button>
                  </div>
                  <div className='pl-3'>
                      <button className='btn btn-primary btn-txt-sm'>user exists, but has not accepted their roles</button>
                  </div>
                  <div className='pl-3'>
                      <button className='btn btn-secondary btn-txt-sm'>user does not exist</button>
                  </div>
                </div>
                <div className='proj_listing'>
                    <div style={@turnOnCreator()}>
                        creator:
                        {@state.creator.map((role) =>
                            <InvitedUsersRoll key={role.id} role={role} />
                        )}
                    </div>
                    <div style={@turnOnChairs()}>
                        lead(s):
                        {@state.chairs.map((role) =>
                            <InvitedUsersRoll key={role.id} role={role} />
                        )}
                    </div>
                    <div style={@turnOnInviteds()}>
                        members:
                        {@state.inviteds.map((role) =>
                            <InvitedUsersRoll key={role.id} role={role} />
                        )}
                    </div>
                    <div style={@turnOnContributors()}>
                        contributors:
                        {@state.contributors.map((role) =>
                            <InvitedUsersRoll key={role.id} role={role} />
                        )}
                    </div>
            </div>
        </div>
