common = require('../common')
Link = require('react-router-dom').Link
HeaderBar = require('../header_bar')
Loading = require('../loading')
SimpleList = require('./simple_list')

module.exports = class CommandCentralFounder extends React.Component
  displayName: 'CommandCentralFounder'

  constructor: (props) ->
      super(props)
      @state = {
          data: { logged_in_user: 'anonymous', verified: 'false', email: '' }
          userid : ''
          show_command_central: false
          result_sitemap_build: ''
          results_conf_verif: ''
          results_delete: ''
      }

  buildSiteMap: () =>

      data =
          userid: @state.userid

      common.buildSiteMap(data, (result) =>
          @setState({
              result_sitemap_build: result['result'] })

          document.open("text/xml","/my_sitemap.xml")
          document.write(result['xml_rows'])
          document.close()

      )

  checkConfVerification: () =>

      data =
          confid: @refs.confid.value

      common.checkConfVerification(data, (result) =>
          console.log("checkConfVerification=")
          console.log(result)
          @setState({
              results_conf_verif: result['result'] })
      )

  toggleVerifyConference: () =>

      data =
          confid: @refs.confid.value

      common.toggleVerifyConference(data, (result) =>
          @setState({
              results_conf_verif: result['result'] })
      )

  indexTitle: () =>

      data =
          titleid: @refs.titleid.value
          projectid: @refs.projectid.value

      common.indexTitle(data, (result) =>
          @setState({
              results_index: result['result'] })
      )

  deleteOrphanS3Files: () =>

      data =
          userid: @state.userid

      common.deleteOrphanS3Files(data, (result) =>
          @setState({results_delete: result['result']})
      )


  componentDidMount: =>

      document.title = 'ZeroDivZero Command Central'

      founder_userids = [
          '140a6a836f8c4a6a8cbf2868799d6903',
          'e95df917ad3846419789efd5d7caeca5'
      ]

      common.loginMonitor (data) =>
          current_user = data['current_user']
          whos_logged_in = 'anonymous'
          whos_logged_in = current_user if current_user
          verified = data['verified']
          email = data['email']
          @setState({
              data: { logged_in_user: whos_logged_in, verified: verified, email: email }
              userid: data['userid']
          })

          if data['userid'] in founder_userids
              @setState({ show_command_central: true })
          else
              window.location = '/'

  verifyProject: () =>
      if @state.results_conf_verif is 'false'
          verification_toggle =
              <span>
                  --&nbsp; Set verification to:&nbsp;
                  <button className='btn btn-success'>
                      false
                  </button>
                  <button className='btn btn-secondary'
                          onClick={@toggleVerifyConference}>
                      true
                  </button>
              </span>
      else if @state.results_conf_verif is 'true'
          verification_toggle =
              <span>
                  --&nbsp; Set verification to:&nbsp;
                  <button className='btn btn-secondary'
                          onClick={@toggleVerifyConference}>
                      false
                  </button>
                  <button className='btn btn-success'>
                      true
                  </button>
              </span>
      if @state.results_conf_verif
          conf_verif_results =
              <span className="text-success">
                  Currently set to: { @state.results_conf_verif }
              </span>
      return (
          <div class='col'>
              <strong>Verify a conference</strong>
              <div className='row no-gutters p-3 m-3'>
                  <div className="input-group pb-3">
                     <input className='form-control form-txt-lg'
                            placeholder='conference id'
                            type="text"
                            ref="confid"
                            name="confid"
                            aria-label="conference id"
                            aria-describedby="basic-addon2">
                     </input>
                     <div className="input-group-append">
                         <button className='btn btn-primary'
                                 onClick={@checkConfVerification}>
                              <span className='mr-2 ml-2'>check</span>
                         </button>
                     </div>
                  </div>
              </div>
              <div className='row no-gutters'>
                  { conf_verif_results } { verification_toggle }
              </div>
          </div>
      )

  submitForSearchIndex: () =>
      indexing_results =
          <div className="text-success wrap_text pointseven_em">
              { JSON.stringify(@state.results_index) }
          </div>
      return (
          <div class='col'>
              <strong>Submit a title for search indexing</strong>
              <div className='row no-gutters p-3 m-3'>
                  <div className="input-group pb-3">
                     <input className='form-control form-txt-lg'
                            placeholder='title id'
                            type="text"
                            ref="titleid"
                            name="titleid"
                            aria-label="titleid id"
                            aria-describedby="basic-addon2">
                     </input>
                     <input className='form-control form-txt-lg'
                            placeholder='project id'
                            type="text"
                            ref="projectid"
                            name="projectid"
                            aria-label="project id"
                            aria-describedby="basic-addon2">
                     </input>
                     <div className="input-group-append">
                         <button className='btn btn-primary'
                                 onClick={@indexTitle}>
                              <span className='mr-2 ml-2'>index</span>
                         </button>
                     </div>
                  </div>
              </div>
              <div className='row no-gutters'>
                  { indexing_results }
              </div>
          </div>
      )

  buildXMLMap: () =>
      sitemap_results =
          <span className="text-success">
              { @state.result_sitemap_build }
          </span>
      return (
          <div class='col'>
              <strong>Manually build an xml-map</strong>
              <div className='row no-gutters p-3 m-3'>
                  <span className='text-danger'>
                      Caution: Entire basis for
                      an xml map is written to this window!&nbsp;&nbsp;</span>
                  { sitemap_results }
              </div>
              <div className='row no-gutters p-3 m-3'>
                  <ul>
                    <li>1st: <button className='btn btn-primary mr-3'
                                     onClick={@buildSiteMap}>Generate</button>
                        the xml map.</li>
                    <li>2nd: save the html page</li>
                    <li>3rd: open in an editor, delete html tags and replace ',' with '\n'</li>
                  </ul>
              </div>
          </div>
      )

    showDevS3: () =>
        if @state.results_delete.length > 0
            delete_old_s3_results =
                <div className="text-success wrap_text pointseven_em">
                    {@state.results_delete.map((s3_address) =>
                        <SimpleList key={s3_address} item={s3_address} />
                    ,this)}
                </div>
        return (
            <div class='col'>
                <strong>Show developer s3 uploads</strong>
                <div className='row no-gutters p-3 m-3'>
                    To be run LOCALLY. This just shows a list of local
                    (developer) uploads -- not a general site cleanup.
                    Eventually, it will be a clean-up tool (dev uploads
                    > 1 month old).
                </div>
                <div className='row no-gutters p-3 m-3'>
                    <button className='btn btn-primary mr-2'
                            onClick={@deleteOrphanS3Files}>
                         <span>show</span>
                    </button>
                </div>
                <div className='row no-gutters p-3 m-3'>
                    { delete_old_s3_results }
                </div>
            </div>
        )

  render: =>

      if @state.show_command_central
          <div className='container-fluid m-3 p-3'>
              <HeaderBar />
              <br />
              <div className='thick_border border_rad_tenpx'>

                  <div className='bg-info border border_rad_top_tenpx'>
                      <h1 className='text-white '>Founder Command Central</h1>
                  </div>
                  <br />
                  { @verifyProject() }
                  <br />
                  <br />
                  { @submitForSearchIndex() }
                  <br />
                  { @buildXMLMap() }
                  <br />
                  { @showDevS3() }
                  <br />
                  <div className='row no-gutters p-3 m-3'>
                      <strong>Working on something?</strong>
                      <div className='row no-gutters p-3 m-3'>
                          <Link to='/command_central/founder/sandbox'>
                              <button className='btn btn-primary m-2'>
                                   <span>Sandbox</span>
                              </button>
                          </Link>
                      </div>
                  </div>
                  <br />
              </div>
          </div>
      else
          <div>
          </div>
