common = require('./common')
Link = require('react-router-dom').Link
Loading = require('./loading')

module.exports = class MiniHeader extends React.Component
    displayName: 'MiniHeader'

    constructor: (props) ->
        super(props)
        # noteid: @props.noteid
        # paperseriesid: @props.paperseriesid
        @state = {
          project_title: ''
          note_title: ''
          ptype: ''
          projectid: ''
        }

    componentDidMount: =>
        data =
            noteid: @props.noteid

        common.getProjectAndPaperTitlesFromNoteId(data, (result) =>
            @setState({
                project_title: result.project_title,
                note_title: result.note_title,
                ptype: result.ptype,
                projectid: result.projectid})
            document.title = result.note_title
        )

    render: =>
        if @state.ptype is 'conference'
            linkto_ptype =
                <Link to={'/conference/' + @state.projectid + '/titles/'}>
                    <button className='btn btn-link ml-3 text-truncate' onClick={@goToProject}>
                            {@state.project_title}</button>
                </Link>
        else
            linkto_ptype =
                <Link to={'/project/' + @state.projectid + '/titles/'}>
                    <button className='btn btn-link ml-3 text-truncate' onClick={@goToProject}>
                            {@state.project_title}</button>
                </Link>

        if @state.ptype is 'conference'
            linkto_note =
                <span>:
                    <Link to={'/conference/' + @state.projectid + '/title/' + @props.paperseriesid}>
                        <button className='btn btn-link ml-3 text-truncate' onClick={@goToProjectNote}>
                                {@state.note_title}</button>
                    </Link>
                </span>
        else
            linkto_note =
                <span>:
                    <Link to={'/project/' + @state.projectid + '/title/' + @props.paperseriesid}>
                        <button className='btn btn-link ml-3 text-truncate'
                                onClick={@goToProjectNote}>
                                {@state.note_title}</button>
                    </Link>
                </span>


        if @props.noteid
            <div className='container'>
                <div className='row text-truncate'>
                    <Link to="/">
                        <button className='btn btn-outline-primary pr-3 pl-3'>
                          &nbsp;&nbsp;&divide;&nbsp;ZeroDivZero
                        </button>
                    </Link>
                    {linkto_ptype}
                    {linkto_note}
                </div>
            </div>
        else
            <div>
            </div>
