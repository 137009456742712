common = require('./common')

module.exports = class ProjectItems extends React.Component
  displayName: 'ProjectItems'

  constructor: (props) ->
      super(props)
      @state = {
        poster: []
        slideset: []
        urls: []
      }

  componentDidMount: =>

      data =
          projectid: @props.note.project_id
          paperseriesid: @props.note.paperseries_id

      common.getPosterFromNoteInfo(data, (result) =>
          if result.poster?
              @setState({ poster: result.poster })
      )

      common.getSlideSetFromNoteInfo(data, (result) =>
          if result.slideset?
              @setState({ slideset: result.slideset })
      )

      if not (@props.note.videoable_id)
          @setState({ video_count: 0 })
      else
          data =
            videoable_id: @props.note.videoable_id
          common.countVideos(data, (result) =>
              if result.video_count?
                  @setState({ video_count: result.video_count })
          )

      data =
          ltype: 'note'
          id: @props.note.id

      common.getUrlLinks(data, (result) =>
          if (result) and (result.url_links)
              @setState({ urls: result.url_links })
      )


  render: =>

      if @props.note.body_blawb_id
          paper_flag =
              <span className="proj_perm_tags mr-2">Paper</span>
      if @state.poster.body_blawb_id
          poster_flag =
              <span className="proj_perm_tags mr-2">Poster</span>
      if @state.slideset.body_blawb_id
          slideset_flag =
              <span className="proj_perm_tags mr-2">Slides</span>
      if @state.video_count > 0
          video_flag =
              <span className="proj_perm_tags mr-2">Video</span>
      if (@state.urls) and (@state.urls.length > 0)
          resources_flag =
              <span className="proj_perm_tags mr-2">Resources</span>
      if (not paper_flag) and (not poster_flag) and (not slideset_flag)
          abstract_flag =
              <span className="proj_perm_tags mr-2">Abstract</span>

      submission_type_info =
          <span>
            {abstract_flag}
            {paper_flag}
            {slideset_flag}
            {poster_flag}
            {video_flag}
            {resources_flag}
          </span>

      <span>
          { submission_type_info }
      </span>
