common = require('./common')
Link = require('react-router-dom').Link
AuthorNameRoll = require('./authorcontact/author_name_roll')
Bookmark = require('./bookmark')
NoteClickToShowDetails = require('./note_click_to_show_details')
GetDatePlace = require('./schedule/get_date_place')
TagNoteRoll = require('./tags/tag_note_roll')
TitleButton = require('./titles/title_button_bold')
VerticalVoteComponent = require('./social/vertical_vote_component')

module.exports = class ConferenceNoteSummary extends React.Component
  displayName: 'ConferenceNoteSummary'

  constructor: (props) ->
      super(props)
      @state = {
        data: {noteid: @props.note.id, votevalue: @props.note.myvote}
        user_is_author: 'false'
        user_can_edit: 'false'
        show_motive: 'none'
     }

  componentDidMount: =>
      author_userids = []
      for author in @props.note.authorcontacts
          author_userids.push(author.user_id)

      # if title was submitted by someone other than the author
      if @props.note.user_id
          author_userids.push(@props.note.user_id)

      # EDIT HERE!!! NEED TO SEND PROJECT?
      user_is_author = 'false'
      if @props.login_id and @props.login_id in author_userids
          user_is_author = 'true'

      rolewords = []
      user_can_edit = 'false'
      for role in @props.roles
          if 'chair' is role.role
              user_can_edit = 'true'

      show_motive = 'preview'
      if @props.show_motive
          show_motive = @props.show_motive

      @setState({
          user_is_author: user_is_author
          user_can_edit: user_can_edit
          show_motive: show_motive })



  scheduling: =>
      show_schedules = ['seminar', 'short_course', 'conference']
      if @props.note.note_type in show_schedules
          if @props.note.spottable_id
              <div className='ml-3 mt-3 mb-3 text-danger'>
                  <span>
                      <GetDatePlace spottable_id={@props.note.spottable_id} />
                  </span>
              </div>
          else
              <div className='ml-3 mt-3 mb-3 text-danger'>
                  <span className='text-danger'>
                      Date/time TBD
                  </span>
              </div>

  acceptanceTrophy: () =>
      if @props.note.acceptance_time > 0
          <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
              <div className="btn-group-vertical">
                  <i className="fa fa-trophy text-success"></i>
              </div>
          </div>
      else if @props.note.acceptance_time < 0
          <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
              <div className="btn-group-vertical">
                  <i className="fa fa-times"></i>
              </div>
          </div>
      else
          <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
              <div className="btn-group-vertical">
              </div>
          </div>

  sessionsList: () =>
      Ltags = @props.note.conference_tags.length
      if Ltags > 0
          <div className='row no-gutters'>
              {@props.note.conference_tags.map((tag, ctr) =>
                  if ctr+1 < Ltags
                      comma=<span>,</span>
                  <TagNoteRoll key={tag.id}
                      tag={tag}
                      tag_type={'conference'}
                      note_type={@props.note.note_type}
                      projectid={ @props.note.project_id }
                      comma={comma} />
              )}
          </div>

  titleButton: =>
      <Link to={'/' + @props.note.note_type + '/' + @props.note.project_id + '/title/' + @props.note.paperseries_id}>
        <TitleButton title={@props.note.title}
                     editor={@props.note.editor} />
      </Link>

  authorInstructions: () =>
      if (@state.user_is_author is 'true') or (@state.user_can_edit is 'true')
              <div className='pointnine_em'>
                  Click title to update your submission
              </div>
      else
              <span></span>




  render: =>

      <div className='container-fluid border note_infoDiv'>

          { @sessionsList() }

          <div className='row no-gutters'>
              <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                    { @titleButton() }
                    { @authorInstructions() }
              </div>
              {@acceptanceTrophy()}
              {@scheduling()}
          </div>

          <NoteClickToShowDetails
              note={@props.note}
              reviewq={@props.project.reviewq}
              roles={@props.roles}
              show_motive={@state.show_motive}
              show_abstract={'preview'} />

      </div>
