common = require('../common')

module.exports = class ConferenceSequel extends React.Component
  displayName: 'ConferenceSequel'

  constructor: (props) ->
      super(props)
      @state = {
          show_caution: 'false'
          mode: 'show_sequel_maker'
          err_msg: ''
      }

  deleteProject: () =>
      data =
        projectid: @props.project.id

      common.deleteProject(data, (result) =>
      )
      window.location = '/profile/' + @props.userid

  createSequel: () =>
      title = @refs.conference_title.value
      if title.length < 1
          @setState({error_msg: 'Please enter a title.'})
          return

      new_userid = @refs.new_userid.value
      if new_userid.length < 1
          new_userid = @props.project.user_id

      data =
        projectid: @props.project.id
        title: title
        new_userid: new_userid

      common.createSequelConference(data, (result) =>
        if result.new_projectid
            window.location = '/conference/' + result['new_projectid'] + '/titles'
        else if result.error
            @setState({mode:'show_err', err_msg: result.error})
        else
            @setState({mode:'show_err', err_msg: 'Something went wrong, please send an email to us.'})
        )

  askIfSure: =>
      @setState({show_caution: 'true'})

  showErr: =>
      if @state.mode is 'show_err'
          <div>
              { @state.err_msg }
          </div>


  render: =>
      if @state.show_caution is 'true'
          delete_if_you_are_sure =
              <button className="btn btn-danger" onClick={@deleteProject}>
                Are you sure?
              </button>
      else
          delete_if_you_are_sure =
              <button className="btn btn-danger" onClick={@askIfSure}>
                delete conference
              </button>

      title_row =
          <div>
            <input className="form-control form-txt-lg"
                   type="text"
                   ref="conference_title"
                   placeholder="Enter the next conference's name..."
                   aria-label="Conference title"
                   aria-describedby="basic-addon2">
            </input>
            If you want to update the conference creator, you can find
            the correct user ID by looking at their profile page.
            <input className="form-control form-txt-lg"
                   type="text"
                   ref="new_userid"
                   placeholder="Enter a new user ID or leave blank."
                   aria-label="Conference creator user id or leave blank to keep the same"
                   aria-describedby="basic-addon2">
            </input>
            <div className="input-group-append">
              <button className="btn btn-primary" onClick={@createSequel}>Start!</button>
            </div>
          </div>


      sequel_maker =
          <div className='col m-0 p-2 light_gray border_rad_tenpx'>
            {@props.random_text}
            <div className='col m-0 pr-2 pl-2'>
                <br />
                {title_row}
                <span className='text-danger'> &nbsp; {@state.error_msg}</span>
                <div>
                    Options can be changed later.
                </div>
            </div>
          </div>

      <div className='p-3'>
          <strong>You can set up the next conference right away!</strong>
          <br />
          <br />
          The advantage of setting up the conference as a recurring series is:
          <li>Your new conference is <strong>automatically verified</strong>.</li>
          <li>Keep your <strong>previous settings</strong>.</li>
          <li>Keep your conference <strong>contacts</strong>.</li>
          <li>Create a <strong>history</strong> for your conference.</li>
          <br />
          {sequel_maker}
          <br />
          { @showErr() }
          <br />
          <span>
              Or if this conference was a mistake or just a test:&nbsp;
              {delete_if_you_are_sure}
          </span>
      </div>
