common = require('../common.coffee')
HeaderBar = require('../header_bar')
Loading = require('../loading')
UrlLinks = require('../url/url_links')
UserProfileProjectGroups = require('./user_profile_project_groups')
UserProfileAuthorContacts = require('../authorcontact/user_profile_authorcontacts')


module.exports = class Profile extends React.Component
  displayName: 'Profile'

  constructor: (props) ->
      super(props)
      @state = {
          userid : @props.match.params.userid
          user : ''
          current_userid: ''
          mode: 'loading'
          user_is_owner: 'false'
      }

  setProfileData: (data) =>
      if data.user
          user_is_owner = 'false'
          if (data.user.id == data.current_userid)
              user_is_owner = 'true'
          @setState({
              user : data.user,
              current_userid: data.current_userid,
              user_is_owner: user_is_owner,
              mode: 'reading' })
          document.title = data.user.name
      else
          @setState({ mode: 'no_user' })
          document.title = 'ZeroDivZero'


  componentDidMount: =>
      callback = (data) => this.setProfileData(data)
      common.getProfileInfo(callback, @props.match.params.userid)
      @forceUpdate() # wondering if component lifecycle method should be used?

  componentDidUpdate: () =>
      if @props.match.params.userid != @state.userid
          window.location.reload()

  nameWord: () =>
      if (@state.user) and (@state.user.id == @state.current_userid)
            return (
                <span>My</span>
            )
      else if (@state.user)
          return (
              <span>{@state.user.name}'s</span>
          )

  emailRow: () =>
      if (@state.user) and (@state.user.id == @state.current_userid)
            return (
                <span className='text-success'>
                    {@state.user.email}
                </span>
            )

  totalEmailRow: () =>
      if (@state.user) and (@state.user.id == @state.current_userid)
            return (
                <div className='row no-gutters'>
                    <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                      Email:
                    </div>
                    <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                      { @emailRow() }&nbsp;
                      <span className='bg-success text-white'>
                          Green fields are hidden, except to you.
                      </span>
                    </div>
                </div>
            )

  okToDelete: () =>
      if (@state.user) and (@state.user.id == @state.current_userid)
            return (
                <p className='text-secondary'>
                  It's ok to have multiple addresses. It's ok to delete your addresses. Deleting them here does not remove them from your titles.
                </p>
            )


  render: =>

      if @state.mode is 'loading'
          <div>
              <HeaderBar />
              <Loading />
          </div>
      else if @state.mode is 'reading'
            <div>
              <HeaderBar />
              <div className='container-fluid'>
                  <h2>Profile</h2>

                  <div className='row no-gutters'>
                    <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                      Name:
                    </div>
                    <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                      {@state.user.name}
                    </div>
                  </div>

                  <div className='row no-gutters'>
                    <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                      User:
                    </div>
                    <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                      {@state.user.nickname}
                    </div>
                  </div>

                  <div className='row no-gutters'>
                    <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                      ID:
                    </div>
                    <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                      {@state.user.id}
                    </div>
                  </div>

                  { @totalEmailRow() }

                  <div className='row no-gutters'>
                    <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                      Links:
                    </div>
                    <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                      <UrlLinks url_linkable_id={@state.user.url_linkable_id}
                                obj_type={'user'}
                                user_is_owner={@state.user_is_owner}
                                obj_id={@props.match.params.userid} />
                    </div>
                  </div>
              </div>

              <div className='container-fluid'>
                  <div className="profile_section_div mt-0">
                      <div>
                        <h2>{@nameWord()} Addresses...</h2>
                        { @okToDelete() }
                      </div>
                        <UserProfileAuthorContacts
                            key={ @state.userid }
                            user={ @state.user }
                            current_userid={ @state.current_userid }/>
                  </div>
              </div>

              <div className='container-fluid'>
                  <div className="profile_section_div">
                    <h2>{@nameWord()} Projects...</h2>
                    <UserProfileProjectGroups key={ 'personal' }
                                  user={ @state.user }
                                  userid={ @props.match.params.userid }
                                  ptype={ 'personal' } />
                  </div>

                  <div className="profile_section_div">
                    <h2>{@nameWord()} Conferences...</h2>
                    <UserProfileProjectGroups
                        key={ 'conference' }
                        user={ @state.user }
                        userid={ @props.match.params.userid }
                        ptype={ 'conference' } />
                  </div>

                  <div className="profile_section_div">
                    <h2>{@nameWord()} Lab Groups...</h2>
                    <UserProfileProjectGroups
                        key={ 'lab_group' }
                        user={ @state.user }
                        userid={ @props.match.params.userid }
                        ptype={ 'lab_group' }/>
                  </div>

                  <div className="profile_section_div">
                    <h2>{@nameWord()} Seminars...</h2>
                    <UserProfileProjectGroups
                        key={ 'seminar' }
                        user={ @state.user }
                        userid={ @props.match.params.userid }
                        ptype={ 'seminar' }/>
                  </div>

                  <div className="profile_section_div">
                    <h2>{@nameWord()} Short Courses...</h2>
                    <UserProfileProjectGroups
                        key={ 'short_course' }
                        user={ @state.user }
                        userid={ @props.match.params.userid }
                        ptype={ 'short_course' }/>
                  </div>

              </div>

          </div>
      else if @state.mode is 'no_user'
          <div>
            <HeaderBar />
            <div className='container-fluid'>
                <h2 className='m-3 p-3'>Sorry, this author has not opened an account yet!</h2>
            </div>
          </div>
