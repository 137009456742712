Link = require('react-router-dom').Link
common = require('./common')
HeaderBar = require('./header_bar')
# ProjectHeaderCKEditor = require('./project_header_ckeditor')
ProjectHeader = require('./project_header')
PythonCodeRunner = require('./python_code_runner.cjsx')

module.exports = class UserOwnedProjectCode extends React.Component
  displayName: 'UserOwnedProjectCode'

  constructor: (props) ->
      super(props)
      projectid = @props.match.params.projectId
      @state = {
          data: { projectid:projectid, project: { } }
      }

  componentDidMount: =>
      common.projectCode((data) =>
          @setState({data: { project: data.project } })
          document.title = data.project.title
      , @state.data.projectid)

  render: =>
      p=@state.data.project
      <div>
          <HeaderBar />
          <ProjectHeader key={Math.random()}
                         project={@state.data.project}
                         userid={@state.userid}
                         roles={@state.roles} />
          <div className='proj_listing'>
              Project code scripts go here along with relevant fields
          </div>
          <br />
          <PythonCodeRunner></PythonCodeRunner>
      </div>
