common = require('../common')
Link = require('react-router-dom').Link
EditorChoice = require('../editor_choice')
Writing = require('../writing')
GetWriting = require('../get_writing_manual_resize')

MODE_READING = 'reading'
MODE_EDIT_REPLY = 'edit my reply'

module.exports = class NoteCommentReplyRoll extends React.Component
  displayName: 'NoteCommentReplyRoll'

  constructor: (props) ->
      super(props)
      @state = {
          reply: @props.reply
          mode: MODE_READING
      }
      @editorChoiceRef = React.createRef()
      @getWritingRef = React.createRef()
      @displayWritingRef = React.createRef()

  editExistingReply: () =>
      @setState({mode: MODE_EDIT_REPLY})

  cancelEditMode: () =>
      @setState({mode: MODE_READING})

  submitEditedReply: () =>
      data =
          commentseriesid: @props.reply.commentseries_id
          text: @getWritingRef.current.getValue()
          editor: @editorChoiceRef.current.getEditor()

      common.editNoteComment(data, (result) =>
          updated_reply = @state.reply
          updated_reply.content = data.text
          @setState({
              reply: updated_reply
              mode: MODE_READING
          })
          @editorChoiceRef.current.setEditor(updated_reply.editor)
      )

  deleteReply: () =>
      data =
          commentseriesid: @props.reply.commentseries_id

      common.hideNoteComment(data, (result) =>
          deleted_reply = @props.reply
          deleted_reply.content = "[removed]"
          deleted_reply.name = "[removed]"
          deleted_reply.user_id = ""
          @setState({
              reply: deleted_reply
              mode: MODE_READING
          })
      )

  updateReply: (user_reply_text) =>
      @setState({user_reply: user_reply_text})

  render: =>
      if @props.data.logged_in_userid is @props.reply.user_id
          edit_option =
              <button className='btn btn-primary btn-txt-sm' 
                  onClick={@editExistingReply}> Edit </button>
      else
          edit_option =
              <span></span>

      [editDisplay, viewDisplay] =
          if @state.mode is MODE_EDIT_REPLY
              ["block", "none"]
          else
              ["none", "block"]

      <div className='comment_block'>
        <div style={display: editDisplay}>
          <div className='container-fluid no-border'>
            <div className="d-flex flex-row bd-highlight">
              <div className='bd-highlight'>
                Reply: &nbsp;
              </div>
              <div className='bd-highlight'>
                <EditorChoice ref={@editorChoiceRef} choice={@state.reply.editor}/>
              </div>
            </div>
            <div className="d-flex flex-row bd-highlight">
              <div className='input-group'>
                <GetWriting ref={@getWritingRef}
                            key={@state.reply.commentseries_id}
                            rows='1' placeholder=""
                            defaultValue={@state.reply.content ? ""}/>
                <span className='input-group-btn'>
                  <button className='btn btn-success btn-txt-sm'
                      onClick={@cancelEditMode}>cancel</button>
                </span>
                <span className='input-group-btn'>
                  <button className='btn btn-success btn-txt-sm'
                      onClick={@submitEditedReply}>submit</button>
                </span>
                <span className='input-group-btn'>
                  <button className='btn btn-success btn-txt-sm'
                      onClick={@deleteReply}>delete</button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style={display: viewDisplay}>
          <div className="d-flex flex-row bd-highlight pr-1">
            <div className='bd-highlight'>
              <Link to={'/profile/' + @props.reply.user_id}>
                <button className='btn btn-success btn-txt-sm'>{@props.reply.name}</button>
              </Link>
            </div>
            <div className='bd-highlight'>
              <Writing ref={@displayWritingRef} editor={@state.reply.editor} writing={@state.reply.content ? ""} />
            </div>
            <div className='bd-highlight'>{edit_option}</div>
          </div>
        </div>
      </div>
