NoteTimePlaceRoll = require('./note_time_place_roll')

module.exports = class ConferenceNotes extends React.Component
  displayName: 'ConferenceNotes'

  constructor: (props) ->
      super(props)

  render: =>
      <span>
          {@props.notes.map((note) =>
              <NoteTimePlaceRoll
                  key={note.id}
                  note={note}
                  project={@props.project}
                  roles={@props.roles}
                  login_id={@props.userid}
                  show_sessions={@props.show_sessions} />
          ,this)}
      </span>
