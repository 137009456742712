HeaderBar = require('../header_bar')
Policies = require('./policies')

module.exports = class TermsOfUse extends React.Component
  displayName: 'TermsOfUse'

  componentDidMount: () =>
      document.title = 'ZeroDivZero terms of use'

  render: =>

      <div>
        <HeaderBar />
        <div className="text-left">

            <h1>Terms of Use</h1>

            <p className="text-left">
              Welcome to the ZeroDivZero website. The following terms and
              conditions of use govern all use of the ZeroDivZero website, all
              related applications, and all content, services and products
              available at or through the website (collectively, the "Website").
              The Website is owned and operated by 0div0 Inc. The Website is
              offered subject to your acceptance without modification of all of
              the terms and conditions contained herein and all other operating
              rules, policies (including, without limitation, the privacy policy)
              and procedures that may be published from time to time on this
              Site by 0div0 Inc. (collectively, the “Agreement”).
            </p>

            <p className="text-left">
              Please read this Agreement carefully before accessing or using
              the Website. By accessing or using any part of the Website, each
              user ("You", "Your" or "User") agree to the terms and conditions
              of this Agreement. If You do not agree to all the terms and
              conditions of this Agreement, You should not access the Website
              or use any services made available via the Website. The Website
              is available only to individuals who are at least 13 years old.
            </p>

            <p className="text-left">
              Your ZeroDivZero Account and Site. If You create an account on the
              Website, You are responsible for maintaining the security of Your
              account, and You are fully responsible for all activities that
              occur under the account and any other actions taken in connection
              with the account. You must not post content to Your account in a
              misleading or unlawful manner, including in a manner intended to
              trade on the name or reputation of others. 0div0 Inc. may, at its
              discretion, change or remove any description or keyword that it
              considers inappropriate or unlawful. You must immediately notify
              0div0 Inc. of any unauthorized uses of Your account or any other
              breaches of security.  0div0 Inc. will not be liable for any acts
              or omissions by You, including any damages of any kind incurred
              as a result of such acts or omissions.
            </p>

            <p className="text-left">
              Responsibility of Contributors. If You post material to the
              Website, or otherwise make (or allow any third party to make)
              material available by means of the Website (any such material,
              “Content”), You are entirely responsible for the content of, and
              any harm resulting from, that Content. That is the case regardless
              of whether the Content in question constitutes text, graphics,
              an audio file, or computer software. By making Content available,
              You represent and warrant that:

              <ul>
                <li>the Content does not contain or install any viruses, worms,
                    malware, Trojan horses or other harmful or destructive content;
                </li>

                <li>the Content is not spam, is not machine- or randomly-generated,
                    and does not contain unethical or unwanted commercial content
                    designed to drive traffic to third party sites or boost the
                    search engine rankings of third party sites, or to further
                    unlawful acts (such as phishing) or mislead recipients as to
                    the source of the material (such as spoofing);
                </li>

                <li>the Content is not libelous or defamatory, does not contain
                    threats or incite violence towards individuals or entities,
                    and does not violate the privacy or publicity rights of any
                    third party;
                </li>

                <li>Your login is not named in a manner that misleads Your
                    readers into thinking that You are another person or company.
                    For example, Your login name is not the name of a person
                    other than Yourself or company other than Your own;
                </li>

                <li>Your content is not getting advertised via unwanted electronic
                    messages such as spam links on newsgroups, email lists,
                    journals and web sites, and similar unsolicited promotional
                    methods.
                </li>
              </ul>
            </p>

            <p className="text-left">
              By submitting Content to ZeroDivZero.com for inclusion on the Website,
              You grant ZeroDivZero.com a world-wide, royalty-free, and non-exclusive
              license to reproduce, modify, adapt and publish the Content solely
              for the purpose of displaying, distributing and promoting Your works.
              You represent and warrant that (a) You own and control all of the
              rights to the Content that You post or you otherwise have the right
              to post such Content to the Site; (b) the Content is accurate and
              not misleading; and (c) use and posting of the Content You supply
              does not violate these Terms of Use and will not violate any
              rights of or cause injury to any person or entity. If You delete
              Content,  0div0 Inc. will use reasonable efforts to remove it from
              the Website, but You acknowledge that caching or references to the
              Content may not be made unavailable immediately.
            </p>

            <p className="text-left">
              Without limiting any of those representations or warranties,
              0div0 Inc. has the right (though not the obligation), in
              0div0 Inc.'s sole discretion, to terminate or deny access to and
              use of the Website to any individual or entity for any reason. In
              the event of any such termination, 0div0 Inc. will have no
              obligation to provide a refund of any amounts previously paid.
            </p>

            <p className="text-left">
              Responsibility of Website Visitors. 0div0 Inc. has not reviewed,
              and cannot review, all of the material, including computer software,
              posted to the Website, and cannot therefore be responsible for
              that material’s content, use or effects. By operating the Website,
              0div0 Inc. does not represent or imply that it endorses the
              material posted to the Website, or that it believes such material
              to be accurate, useful or non-harmful. You are responsible for
              taking precautions as necessary to protect Yourself and Your
              computer systems from viruses, worms, Trojan horses, and other
              harmful or destructive content. The Website may contain content
              that is offensive, indecent, or otherwise objectionable, as well
              as content containing technical inaccuracies, typographical
              mistakes, and other errors. The Website may also contain material
              that violates the privacy or publicity rights, or infringes the
              intellectual property and other proprietary rights, of third
              parties, or the downloading, copying or use of which is subject
              to additional terms and conditions, stated or unstated.
              0div0 Inc. disclaims any responsibility for any harm resulting
              from the use by visitors of the Website, or from any downloading
              by those visitors of content posted to the Website.
            </p>

            <p className="text-left">
              Use. You agree not to use the Website or the 0div0 Inc. Service
              for commercial purpose without prior authorization which may be
              withheld in 0div0 Inc.'s' sole discretion, or for any illegal,
              unlawful, inappropriate or unauthorized purpose or activity. You
              further agree not to threaten, abuse, solicit, spam, harass,
              stalk, impersonate or intimidate other 0div0 Inc.'s Users and not
              to collect or store any location or other personal information
              about any ZeroDivZero Users that You obtain from the Website.
            </p>

            <p className="text-left">
              Content Posted on Other Websites. We have not reviewed, and cannot
              review, all of the material, including computer software, made
              available through the websites and webpages to which ZeroDivZero.com
              links, and that link to ZeroDivZero.com. 0div0 Inc. does not have any
              control over those non-0div0 Inc. websites and webpages, and is
              not responsible for their contents or their use. By providing a
              link to a non-0div0 Inc. website or webpage, 0div0 Inc. does not
              represent or imply that it endorses such website or webpage. You
              are responsible for taking precautions as necessary to protect
              Yourself and Your computer systems from viruses, worms, Trojan
              horses, and other harmful or destructive content. 0div0 Inc.
              disclaims any responsibility for any harm resulting from Your
              use of non-0div0 Inc. websites and webpages.
            </p>

            <p className="text-left">
              Copyright Infringement and DMCA Policy. 0div0 Inc respects the
              intellectual property rights of others, and requests that users of
              the Website and Service do the same. 0div0 Inc.
              retains the rights to all content that it creates, including
              copyrightable works, trademarks, service marks and patentable
              inventions.  ZeroDivZero.com's users retain the rights to all
              their content, including copyrightable works, trademarks, service
              marks and patentable inventions.
            </p>

            <p className="text-left">
              Intellectual Property. This Agreement does not transfer to You any
              0div0 Inc or third party intellectual property, and all right,
              title and interest in and to such property will remain (as between
              the parties) solely with 0div0 Inc. All trademarks, service marks,
              graphics and logos used in connection with ZeroDivZero.com, or the
              Website are trademarks or registered trademarks of 0div0 Inc, or
              0div0 Inc's licensors. Other trademarks, service marks, graphics
              and logos used in connection with the Website may be the trademarks
              of other third parties. Your use of the Website grants You no
              right or license to reproduce or otherwise use any 0div0 Inc. or
              third-party trademarks.
            </p>

            <p className="text-left">
              Changes. 0div0 Inc. reserves the right, at its sole discretion,
              to modify or replace any part of this Agreement. It is Your
              responsibility to check this Agreement periodically for changes.
              Notice of material changes to these Terms and Conditions of Use
              will be posted at the beginning of these Terms and Conditions of
              Use. Your continued use of or access to the Website following the
              posting of any changes to this Agreement constitutes acceptance
              of those changes. 0div0 Inc. may also, in the future, offer new
              services and/or features through the Website (including, the
              release of new tools and resources). Such new features and/or
              services shall be subject to the terms and conditions of this Agreement.
            </p>

            <p className="text-left">
              Termination. 0div0 Inc. may terminate Your access to all or any
              part of the Website at any time, with or without cause, with or
              without notice, effective immediately. If You wish to terminate
              this Agreement or Your ZeroDivZero.com account, You may simply
              discontinue using the Website. All provisions of this Agreement
              which by their nature should survive termination shall survive
              termination, including, without limitation, ownership provisions,
              warranty disclaimers, indemnity and limitations of liability.
            </p>

            <p className="text-left">
              Disclaimer of Warranties. The Website is provided “as is.”
              0div0 Inc. and its suppliers and licensors hereby disclaim all
              warranties of any kind, express or implied, including, without
              limitation, the warranties of merchantability, fitness for a
              particular purpose and non-infringement. Neither 0div0 Inc. nor
              its suppliers and licensors makes any warranty that the Website
              will be error free or that access thereto will be continuous or
              uninterrupted. You understand that You download from, or otherwise
              obtain content or services through, the Website at Your own
              discretion and risk.
            </p>

            <p className="text-left">
              Limitation of Liability. In no event will 0div0 Inc., or its
              suppliers or licensors be liable with respect to any subject
              matter of this Agreement under any contract, negligence, strict
              liability or other legal or equitable theory for: (i) any special,
              incidental or consequential damages; (ii) the cost of procurement
              or substitute products or services; (iii) for interruption of use
              or loss or corruption of data; or (iv) for any amounts that exceed
              the fees paid by You to 0div0 Inc. under this Agreement during
              the twelve (12) month period prior to the cause of action. 0div0
              Inc. shall have no liability for any failure or delay due to
              matters beyond their reasonable control. The foregoing shall not
              apply to the extent prohibited by applicable law.
            </p>

            <p className="text-left">
              General Representation and Warranty. You represent and warrant
              that (i) your use of the Website will be in strict accordance with
              the 0div0 Inc. Privacy Policy, with this Agreement and with all
              applicable laws and regulations (including without limitation any
              local laws or regulations in Your country, state, city, or other
              governmental area, regarding online conduct and acceptable content,
              and including all applicable laws regarding the transmission of
              technical data exported from the United States or the country in
              which You reside) and (ii) Your use of the Website will not
              infringe or misappropriate the intellectual property rights of any
              third party.
            </p>

            <p className="text-left">
              Indemnification. You agree to indemnify and hold harmless 0div0 Inc.,
              our contractors, and licensors, and their respective directors,
              officers, employees and agents from and against any and all claims
              and expenses, including attorneys’ fees, arising out of Your use
              of the Website, including but not limited to Your violation this
              Agreement.
            </p>

            <p className="text-left">
              Miscellaneous. This Agreement constitutes the entire agreement
              between 0div0 Inc. and You concerning the subject matter hereof,
              and may be modified only by a written amendment signed by an
              authorized executive of 0div0 Inc. or by the posting by on
              ZeroDivZero.com of a revised version of these Terms and Conditions
              of Use. Except to the extent applicable law, if any, provides
              otherwise, this Agreement, any access to or use of the Website
              will be governed by the laws of the state of California, U.S.A.,
              excluding its conflict of law provisions, and the proper venue for
              any disputes arising out of or relating to any of the same will be
              the state and federal courts located within 60 miles of San
              Francisco, California. The prevailing party in any action or
              proceeding to enforce this Agreement shall be entitled to costs
              and attorneys’ fees. If any part of this Agreement is held invalid
              or unenforceable, that part will be construed to reflect the
              parties’ original intent, and the remaining portions will remain
              in full force and effect. A waiver by either party of any term or
              condition of this Agreement or any breach thereof, in any one
              instance, will not waive such term or condition or any subsequent
              breach thereof. You may not assign Your rights under this Agreement;
              0div0 Inc. may assign its rights under this Agreement without
              condition. This Agreement will be binding upon and will inure to
              the benefit of the parties, their successors and permitted assigns.
            </p>

            <p className="text-left">
              Please don't hesitate to contact us with further questions at:
              help@0div0.com
            </p>

        </div>
        <Policies />
      </div>
