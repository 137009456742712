common = require('../common')
Loading = require('../loading')
SurveyOverviewRoll = require('./survey_overview_roll')
SurveyRoll = require('./survey_roll')
UnknownError = require('../unknown_error')

module.exports = class GetSurveyResponses extends React.Component
  displayName: 'GetSurveyResponses'

  constructor: (props) ->
      super(props)
      @state = {
          mode: 'loading'
          surveys: []
          survey_id: ''
          full_survey: {}
          msg: ''
      }

  componentDidMount: () =>

      if (@props.surveyable_id)
          data =
            surveyable_id: @props.surveyable_id
            add_ons: 'response_counts'


          common.getSurveyList(data, (result) =>
              msg = 'Click to see the statistics and details of responses...'
              if result['survey_list'].length is 0
                  msg = 'No surveys yet!'
              # for survey in result['survey_list']
              #     survey.progress = 'response_overview'
              @setState({
                    mode: 'show_survey_list'
                    surveys: result['survey_list']
                    msg: msg
                    })
          )

      else
          @setState({
              mode: 'show_survey_list'
              surveys:[]
              msg: 'No surveys yet!'})

  showAggregatedAnswers: (survey) =>

      data =
          survey_id: survey.id

      common.getAggregatedSurveyAnswers(data, (result) =>

          full_survey = result['full_survey']
          for question in full_survey
              for choice in question.choices
                    choice.users = []
                    choice.mode = 'show_aggregated'

          @setState({
              mode: 'show_aggregated',
              survey_id: survey.id,
              full_survey: full_survey
              })
          )

  getUserList: (choice) =>
      choice_id = choice.id
      data =
          choice_id: choice_id
          survey_id: @state.survey_id

      common.getUsersWhoAnsweredThisChoice(data, (result) =>
          full_survey = @state.full_survey
          users = result['users']
          for user in users
              user.user_id = user.id #so we can resuse AuthorNameRoll
          for question in full_survey
              for choice in question.choices
                if choice_id is choice.id
                    choice.users = users
                    choice.mode = 'show_users'

          @setState({
                mode: 'show_users'
                full_survey: full_survey
                })
      )

  setModeSurveyOverView: =>
      @setState({ mode: 'show_survey_list' })


  render: =>

      if @state.mode is 'loading'
          <div>
              <Loading />
          </div>
      else if @state.mode is 'show_survey_list'
          <div>
              { @state.msg }
              { @state.surveys.map((survey) =>
                  <SurveyOverviewRoll key={survey.id}
                              title={survey.title}
                              reason={survey.reason}
                              mode={'response_overview'}
                              response_count={survey.response_count}
                              onClick={@showAggregatedAnswers.bind(this, survey)} />
              , this)}
          </div>
      else
            <div>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                    { @state.full_survey.map((item) =>
                        <SurveyRoll key={ Math.random() }
                                    item={ item }
                                    mode={ @state.survey_mode }
                                    getUserList={ @getUserList } />
                    , this)}
                </div>
                <button className='btn btn-primary'
                        onClick={@setModeSurveyOverView}>Back to surveys</button>
            </div>
