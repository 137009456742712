common = require('../common')
AuthorProfileNameRoll = require('./author_profile_name_roll')
AccountMergingTool = require('../account/account_merging_tool')
AuthorContactForm = require('./authorcontact_form')

module.exports = class UserProfileAuthorContacts extends React.Component
  displayName: 'UserProfileAuthorContacts'

  constructor: (props) ->
      super(props)
      # expects as props:
      # current_userid
      @state = {
        user : @props.user
        mode : 'author_roll_only'
        authorcontacts : [ ]
        error_msg : ''

        offer_acct_merging_tool: 'false'
        conflict_email: ''
        hide_email: 0
      }

  popAuthorContactForm: () =>
      @setState({ mode: 'add_authorcontact'})

  addAuthorContact: (data) =>

      if data is 'turn_off'
        @setState({mode: 'author_roll_only'})
      else
          common.submitNewUserOwnedAuthorContact(data, (result) =>
              @setState({ mode: 'author_roll_only' })
              acs = @state.authorcontacts
              if result.authorcontact
                  acs.push(result['authorcontact'])
                  @setState({
                      authorcontacts : acs
                      mode: 'author_roll_only' })
              else if result.msg
                  @setState({
                      error_msg: result['msg']
                      offer_acct_merging_tool: 'true'
                      conflict_email: email })
                  return
              )

  componentDidMount: =>
      data =
          userid: @props.user.id
          complete: 'true'
      if data.userid?
          common.getUserAuthorContactList(data, (result) =>
              if result.authorcontacts
                  @setState({ authorcontacts : result.authorcontacts })
              )

  componentDidUpdate: () =>
      if @props.user.id isnt @state.user.id
          @setState({ user : @props.user })
          data =
              userid: @props.user.id
              complete: 'true'

          common.getUserAuthorContactList(data, (result) =>
              if result.authorcontacts
                  @setState({ authorcontacts : result.authorcontacts })
              )

  setHideEmailPrefYes: () =>
      @setState({ hide_email: 1})
      @refs.yes_hide_email.style.backgroundColor='#A0EBB2'
      @refs.no_hide_email.style.backgroundColor='#cccccc'

  setHideEmailPrefNo: () =>
      @setState({ hide_email: 0})
      @refs.yes_hide_email.style.backgroundColor='#cccccc'
      @refs.no_hide_email.style.backgroundColor='#A0EBB2'

  addRecordButton: () =>
      if (@props.current_userid == @props.user.id)
          <span>
            <button className='btn btn-secondary'
                    onClick={@popAuthorContactForm}>Add a new author record</button>
          </span>

  authorRoll: () =>
      if (@props.current_userid == @props.user.id)
          return(
              <span>
                {@state.authorcontacts.map((authorcontact) =>
                    <AuthorProfileNameRoll key={authorcontact.id}
                        authorcontact={authorcontact}
                        current_userid={ @props.current_userid }
                        user_can_edit={'true'}/>
                )}
              </span>
              )
      else
          return(
              <span>
                {@state.authorcontacts.map((authorcontact) =>
                    <AuthorProfileNameRoll key={authorcontact.id}
                                           authorcontact={authorcontact}
                                           current_userid={ @props.current_userid }/>
                )}
              </span>
          )

  errorMsg: () =>
      <div className="text-danger">{ @state.error_msg }</div>

  accountMergeTool: () =>
      if @state.offer_acct_merging_tool is 'true'
          <span>
              <AccountMergingTool email={ @state.conflict_email }
                                  userid={ @props.current_userid }/>
          </span>


  render: =>


    if @state.mode is 'add_authorcontact'
        <div className="container-fluid no-border">
            { @authorRoll() }
            ...starred (*) entries required:
            <AuthorContactForm
                mode={@state.mode}
                addAuthorContact={@addAuthorContact}
                popAuthorContactForm={@popAuthorContactForm}
                error_msg={@state.error_msg} />
        </div>

    else if @state.mode is 'author_roll_only'
        <div className="container-fluid no-border">
            { @addRecordButton() }
            { @authorRoll() }
            <div>
            { @errorMsg() }
            { @accountMergeTool() }
            </div>
        </div>
