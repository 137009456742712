
module.exports = class PageNumber extends React.Component
  displayName: 'PageNumber'

  constructor: (props) ->
      super(props)
      @state = {
      }

  render: =>
    if @props.number is @props.page_num
        current_page = 'selected'
    else
        current_page = 'not_selected'

    if current_page is 'selected'
        <li className="page-item disabled">
            <a className="page-link"
               tabIndex="-1"
               onClick={@props.onClick}>
               <span className="text-muted">{@props.number}</span></a>
        </li>
    else
        <li className="page-item">
            <a className="page-link"
               onClick={@props.onClick}>
               <span className="text-primary">{@props.number}</span></a>
        </li>
