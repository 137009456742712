
module.exports = class Writing extends React.Component
  displayName: 'Writing'

  componentDidMount: () =>
    if @props.editor is 'MathJax'
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, @domElement])

  setDOMRef: (domElement) =>
    @domElement = domElement

  render: =>
    if @props.editor is 'MathJax'
      <span className='mathjax new_lines_enabled'
            ref={@setDOMRef}
            dangerouslySetInnerHTML={{__html: @props.writing }}>
      </span>
    else
      <span className='new_lines_enabled'
            dangerouslySetInnerHTML={{__html: @props.writing }}>
      </span>
