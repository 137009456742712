Link = require('react-router-dom').Link
common = require('../common')
EmailVerification = require('./email_verification')
GoogleLogin = require('./google_login')
LostPassword = require('./lost_password')

module.exports = class ManualLoginForms extends React.Component
  displayName: 'ManualLoginForms'

  constructor: (props) ->
      super(props)

      @usernameRef = React.createRef()
      @passwordRef = React.createRef()
      @passwordEyeRef = React.createRef()
      @newUsernameRef = React.createRef()
      @newEmailRef = React.createRef()
      @newNameRef = React.createRef()
      @newPasswordRef = React.createRef()
      @newPasswordEyeRef = React.createRef()


      mode = ''
      if @props.mode
          mode = @props.mode

      @state = {
        userid : ''
        username : ''
        password : ''
        new_name : ''
        new_username : ''
        new_password : ''
        new_email : ''
        login_error_msg : ''
        mode : mode
        new_acct_error_msg: ''
        show_password_reset: 'false'
        verified: ''
      }

  manualLogin: () =>
      @setState({ mode : 'manual_login' })

  submitCredentials: =>

      # @state.username,password could exist b/c of new account registration
      if (@state.username.length > 3) and (@state.password.length > 7)
          username = @state.username
          password = @state.password
      else
          # submitting for a pre-existing account
          username = @usernameRef.current.value.toLowerCase()
          if username.length < 4
              @setState({ login_error_msg: 'Your username is too short! At least 4 characters.'})
              return
          if username.length > 20
              @setState({ login_error_msg: 'Your username is too long! Maximum 20 characters.'})
              return
          regex_username = /^[0-9A-Za-z\-_.\x80-\x9B\x9D-\xA5\xB5-\xB7\xE0-\xED]+$/
          if not regex_username.test(username)
              @setState({ login_error_msg: 'Your username may only contain letters and numbers.'})
              return
          @setState({ username: username }) # @setState for LostPassword

          password = @passwordRef.current.value
          if password.length < 8
              @setState({ login_error_msg: 'Your password is too short! At least 8 characters. Try a unique, easy-to-remember phrase?'})
              return

      form =
        username : username
        password : password

      common.localLogin(form, (result) =>
          if (result.success is true) and (@props) and (@props.redirect)
              window.location = @props.redirect
          else if (result.success is true)
              window.location.reload()
          else
              @setState({ login_error_msg : result.errors})
              if result.errors is 'Invalid password'
                  @setState({ show_password_reset: 'true' })
              if result.errors is 'Verify email'
                  @setState({ login_error_msg : 'Your conference invitations are saved using your email until you have opened an account, so we want to make sure we have the right person.'})
                  @setState({ verified: 'false', email: result.email, userid: result.userid })
      )

  showNewAcctForm: =>
    @setState({ mode : 'new_account_login' })

  validateEmailAddress: (email) =>
      re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email))

  submitNewCredentials: =>

    new_username = @newUsernameRef.current.value.toLowerCase()
    new_email = @newEmailRef.current.value.toLowerCase()
    new_email = new_email.trim()
    new_name = @newNameRef.current.value
    new_password = @newPasswordRef.current.value

    @setState({
        name: new_name
        username: new_username
        password: new_password
        })


    # ascii_foreign_letters = \x80-\x9B\x9D-\xA5\xB5-\xB7\xE0-\xED
    # reference: https://theasciicode.com.ar/extended-ascii-code/uppercase-slashed-zero-empty-set-ascii-code-157.html

    regex_name = /^[0-9A-Za-z\s\-.,\x80-\x9B\x9D-\xA5\xB5-\xB7\xE0-\xED]+$/
    regex_username = /^[0-9A-Za-z\-_.\x80-\x9B\x9D-\xA5\xB5-\xB7\xE0-\xED]+$/
    # usernames same as above, but no spaces or commas allowed

    @setState({ new_acct_error_msg: '' })
    if new_name.length < 4
        @setState({ new_acct_error_msg: 'Your real name is too short! At least 4 characters.'})
        return
    if new_name.length > 30
        @setState({ new_acct_error_msg: 'Your real name is too long! Maximum 30 characters.'})
        return
    if not regex_name.test(new_name)
        @setState({ new_acct_error_msg: 'Only letters, spaces, numbers or hyphens allowed for a real name.'})
        return
    if new_username.length < 4
        @setState({ new_acct_error_msg: 'Your username is too short! At least 4 characters.'})
        return
    if new_username.length > 20
        @setState({ new_acct_error_msg: 'Your username is too long! Maximum 20 characters.'})
        return
    if not regex_username.test(new_username)
        @setState({ new_acct_error_msg: 'Your username may only contain letters and numbers.'})
        return
    if new_password.length < 8
        @setState({ new_acct_error_msg: 'Your password is too short! At least 8 characters. Try a unique, easy-to-remember phrase?'})
        return
    validation = @validateEmailAddress(new_email)
    if not validation
        @setState({ new_acct_error_msg: 'Invalid email.'})
        return

    form =
        name : new_name
        username : new_username
        password : new_password
        email : new_email

    common.localAccountRegistration(form, (result) =>
      if result.success
          @setState({
              userid: result.userid
              verified: 'false'
              email: new_email })
          # @requestEmailVerification()
          # @submitCredentials()
      else if result.errors
          @setState({ new_acct_error_msg: result.errors })
    )

  verifiedSubmitCreds: () =>
        @submitCredentials()

  showNewPassword: () =>

      if @newPasswordRef.current.type is 'password'
          @newPasswordRef.current.type = 'text'
          @newPasswordEyeRef.current.className = 'fa fa-eye ml-3 mr-3'
      else
          @newPasswordRef.current.type = 'password'
          @newPasswordEyeRef.current.className = 'fa fa-eye-slash ml-3 mr-3'

  showPassword: () =>

      if @passwordRef.current.type is 'password'
          @passwordRef.current.type = 'text'
          @passwordEyeRef.current.className = 'fa fa-eye ml-3 mr-3'
      else
          @passwordRef.current.type = 'password'
          @passwordEyeRef.current.className = 'fa fa-eye-slash ml-3 mr-3'


  render: =>

      if @state.new_acct_error_msg is ''
          new_acct_error_msg = <span></span>
      else
          new_acct_error_msg =
              <span className="text-danger">
                  { @state.new_acct_error_msg }
              </span>

      if @state.login_error_msg is ''
          login_error_msg = <span></span>
      else
          login_error_msg =
              <span className="text-danger">
                  { @state.login_error_msg }
              </span>

      account_verification_form =
          <EmailVerification verified={ @state.verified }
                             email={ @state.email }
                             userid={ @state.userid }
                             current_userid={ @state.userid }
                             type={ 'user_account' }
                             verifiedSubmitCreds={@verifiedSubmitCreds} />

      if (@props) and (@props.redirect)
          lost_password_form =
              <LostPassword username={@state.username}
                            show_password_reset={@state.show_password_reset}
                            redirect={@props.redirect} />
      else
          lost_password_form =
              <LostPassword username={@state.username}
                            show_password_reset={@state.show_password_reset}/>


      close_button =
          <div className='clearfix'>
              <button className='btn btn-link clear_button text-secondary float-right'
                  onClick={@props.minimizeLogin}>
                  <i className="fa fa-times-circle-o fa-lg"></i>
              </button>
          </div>


      if @state.mode is 'manual_login'
          <div>
            <br />
            <div className='container-fluid'>
                { close_button }
                <div className='row no-gutters'>
                   <input ref={@usernameRef}
                          className='form-control form-txt-lg'
                          type="text"
                          placeholder="* Username"
                          name="username"></input>
                </div>
                <div className="input-group pb-3">
                   <input className='form-control form-txt-lg'
                          placeholder='* Password (case sensitive)'
                          type="password"
                          ref={@passwordRef}
                          name="password"
                          aria-label="password"
                          aria-describedby="basic-addon2">
                   </input>
                   <div className="input-group-append">
                       <button className='btn btn-outline-secondary'
                               onClick={@showPassword}>
                           <span className="fa fa-eye-slash ml-3 mr-3"
                                 ref={@passwordEyeRef}></span>
                       </button>
                   </div>
                </div>
                <div className='row no-gutters'>
                    <button className="btn btn-secondary mr-2"
                        onClick={@submitCredentials}>
                        Submit
                    </button>
                    <button className="btn btn-secondary mr-2"
                        onClick={@showNewAcctForm}>
                        Need to register a new account?
                    </button>
                    <GoogleLogin />
                </div>
                <div className='row no-gutters'>
                    { login_error_msg }
                </div>
                <div>
                  { account_verification_form }
                </div>
                { lost_password_form }
            </div>
          </div>

      else if @state.mode is 'new_account_login'
          <div>
            <br />
            <div className='container-fluid'>
                { close_button }
                <div className='row no-gutters'>
                  <input className='form-control form-txt-lg border-danger'
                      placeholder='* Unique username'
                      type="text"
                      ref={@newUsernameRef}
                      name="new_username"></input>
                </div>
                <div className='row no-gutters'>
                    <div className="input-group pb-3">
                        <input className='form-control form-txt-lg border-danger'
                            placeholder='* Password (case sensitive)'
                            type="password"
                            ref={@newPasswordRef}
                            name="new_password"
                            aria-label="new password"
                            aria-describedby="basic-addon2">
                        </input>
                        <div className="input-group-append">
                            <button className='btn btn-outline-secondary'
                                    onClick={@showNewPassword}>
                                <span className="fa fa-eye-slash ml-3 mr-3"
                                      ref={@newPasswordEyeRef}></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='row no-gutters'>
                    <input className='form-control form-txt-lg border-danger pb-0 mb-0'
                        placeholder='* Email'
                        type="email"
                        ref={@newEmailRef}
                        name="new_email"></input>
                    <div className='text-danger pt-0 mt-0 pb-1'>
                        Your email will be kept private. We will not share it or contact you without permission.
                    </div>
                </div>
                <div className='row no-gutters'>
                    <input className='form-control form-txt-lg border-danger'
                        placeholder='* Full real name - so people can find your work'
                        type="text"
                        ref={@newNameRef}
                        name="new_name"></input>
                </div>
                <div className='row no-gutters'>
                    <button className="btn btn-secondary mr-2"
                        onClick={@submitNewCredentials}>
                        Submit
                    </button>
                    <button className="btn btn-secondary mr-2"
                        onClick={@manualLogin}>
                        Oops I already have an account!
                    </button>
                    <GoogleLogin />
                </div>
                <div className='row no-gutters'>
                    { new_acct_error_msg }
                </div>
                <div>
                  { account_verification_form }
                </div>
            </div>
          </div>
