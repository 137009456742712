common = require('../common')
Link = require('react-router-dom').Link

module.exports = class AuthorNameRoll extends React.Component
  displayName: 'AuthorNameRoll'

  render: =>

      if (@props.authorcontact) and (@props.authorcontact.credit_as)
          full_name =
              <span>
                {@props.authorcontact.name},&nbsp;{@props.authorcontact.credit_as}
              </span>
      else
          full_name =
              <span>
                {@props.authorcontact.name}
              </span>


      if @props.authorcontact.name is "Removed!"
          <span></span>
      if @props.link_type is 'no_link'
            <div className='authornameroll_div'>
                { @props.comma }{ full_name }
            </div>
      else if @props.authorcontact.user_id?
          <div className='authornameroll_div'>
              <Link to={'/profile/' + @props.authorcontact.user_id}>
                  <button className='btn btn-link btn-txt-sm'>
                    { full_name }</button>
              </Link>
          </div>
      else
          <div className='authornameroll_div'>
              <Link to={'/profile/' + @props.authorcontact.user_id}>
              <button className='btn btn-link btn-link-dark btn-txt-sm'>
                  { full_name }</button>
              </Link>
          </div>
