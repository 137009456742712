common = require('../common')
DocumentFullFrame = require('./document_fullframe')
Loading = require('../loading')

module.exports = class UrlResourceDocument extends React.Component
    displayName: 'UrlResourceDocument'

    constructor: (props) ->
      super(props)
      @state = {url_id: ''}

    componentDidMount: =>
        data =
            projectid: @props.match.params.projectId
            url_id: @props.match.params.urlId

        @setState({ url_id: data.url_id })

        #common.getNoteidFromPaperSeriesAndProject(data, (result) =>
        #    @setState({noteid: result.noteid})
        #)

    render: =>
        if @state.url_id.length > 0
            filename = "/view_url_document/" + @state.url_id
            <DocumentFullFrame
                className="fullwindow_view_document_iframe"
                src={filename} />
        else
            <Loading />
