Link = require('react-router-dom').Link
HeaderBar = require('./header_bar')

module.exports = class Help extends React.Component
  displayName: 'Help'

  constructor: (props) ->
      super(props)
      @state = {
        contributorInfo : false
        participation : false
        hostInfo : false
        moreQs : false
        verification : false
        showWhyFee : false
        expandAll : false
      }

  componentDidMount: () =>
      document.title = 'ZeroDivZero help'

  expandAllTabs: () =>
      @setState({ expandAll : true })
      @setState({ contributorInfo : true })
      @setState({ participation : true })
      @setState({ hostInfo : true })
      @setState({ moreQs : true })
      @setState({ verification : true })
      @setState({ showWhyFee : true })

  hideAllTabs: () =>
      @setState({ expandAll : false })
      @setState({ contributorInfo : false })
      @setState({ participation : false })
      @setState({ hostInfo : false })
      @setState({ moreQs : false })
      @setState({ verification : false })
      @setState({ showWhyFee : false })

  showHowItWorksContributors: () =>
      if @state.contributorInfo is false
        @setState({ contributorInfo : true })
      else
        @setState({ contributorInfo : false })

  showWhyFees: () =>
      if @state.showWhyFee is false
        @setState({ showWhyFee : true })
      else
        @setState({ showWhyFee : false })

  showHowItWorksHosts: () =>
      if @state.hostInfo is false
        @setState({ hostInfo : true })
      else
        @setState({ hostInfo : false })

  showGetMoreHelp: () =>
      if @state.moreQs is false
        @setState({ moreQs : true })
      else
        @setState({ moreQs : false })

  showHowItWorksParticipate: () =>
      if @state.participation is false
          @setState({ participation : true })
        else
          @setState({ participation : false })

  showVerification: () =>
      if @state.verification is false
          @setState({ verification : true })
        else
          @setState({ verification : false })

  render: =>

    if @state.contributorInfo is true
        how_it_works_for_contributors =
            <div className='row no-gutters bg-white p-2 border_rad_tenpx'>
                <ul>
                  <li>Click the "My Projects" tab at the top
                  </li>
                  <li>Either click a project you have already started or
                      enter a topic in the dialog box to create a new project
                      and click "Start!"
                  </li>
                  <li>Click "Add a title" to start your new title.
                  </li>
                  <li>A dialog will ask you to fill in a title, authors, abstract,
                      popular summary, and tags. Click the "Start my new title!" button
                      when you are finished.
                  </li>
                  <li>Upload your paper, slides, and/or poster using the upload links.
                  </li>
                  <li>Navigate to the conference to which you wish to submit. You may already have a link or
                      you can search for a conference that is a good match for your research.
                  </li>
                  <li>Click the "Submit a title" button
                  </li>
                  <li>A dialog will guide you through choosing your project and
                     then the appropriate title from your project to submit to the
                     conference.
                  </li>
              </ul>
            </div>

    if @state.hostInfo is true
        how_it_works_for_conference_hosts =
            <div className='row no-gutters bg-white p-2 border_rad_tenpx'>
                <ul>
                  <li>Click "My conferences" (top navigation bar).
                  </li>
                  <li>Scroll to the "Create a conference..." dialogue box.
                  </li>
                  <li>Use this dialogue to start a new conference and decide the permissions levels.
                  </li>
                  <li>The conference site will load. This link can now be shared.
                  </li>
                  <li>To set more important options go to the "tools" tab in the conference navigation.
                  </li>
                  <li>The tools tab is the panel which contains all the
                      conference tools except for registration.
                  </li>
                  <li>Click the registration tab to set up registration.
                  </li>
              </ul>
            </div>


    if @state.participation is true
        how_it_works_for_participating_in_conference =
            <div className='row no-gutters bg-white p-2 border_rad_tenpx'>
              There are several ways to participate in a conference:
              <ul>
                <li><strong>Following</strong> : you will be able to read papers and make comments.
                </li>
                <li><strong>Submit a paper</strong> : after you have submitted your paper, you will
                    receive a review and your paper will be accepted or rejected.
                    Either way, you will keep the reviews and comments which will
                    be visible in the personal version of your paper (in your
                    project folder). You can track your paper's progress in Tools &#8594; Contributor's Panel
                </li>
                <li><strong>Reviewer</strong> : You will be assigned papers to review!
                    You can see which ones in Tools  &#8594; Reviewer Panel.
                </li>
                <li><strong>Area chair</strong> : You will have area chair controls
                    in Tools &#8594; Area Chair Panel. You will be able to assign/edit
                    reviewers for papers.
                </li>
                <li><strong>Chair</strong> : You will have chair controls in Tools &#8594; Chair Panel.
                </li>
                <li><strong>Creator</strong> : Ideally, the conference creator is
                    one of the conference chairs. The creator will have quite a bit
                    of power over the conference since the creator assigns all the roles.
                    Go to Tools &#8594; Creator Panel to see the controls.
                </li>
              </ul>
            </div>

    if @state.showWhyFee is true
        how_do_we_keep_the_site_alive =
            <span className='bg-white p-2 border_rad_tenpx'>
                There are a couple of reasons:
                <ul>
                  <li><strong>Spam</strong> : To stop spam submissions to your conference.
                  </li>
                  <li><strong>Keep us alive</strong> : The fee also pays for the
                      platform so we can keep building and growing the site.
                  </li>
                </ul>
                <strong>Thank you!</strong>&nbsp; We hope the fee is not prohibitive.
            </span>


    if @state.verification is true
        verification_procedure =
            <div className='row no-gutters bg-white p-2 border_rad_tenpx'>
              <p>Conference verification is useful for assuring users that your
                 conference is legitimate and has historical significance.
              </p>
              <p>At the moment, verification is limited to conferences with historical
                 significance. Once your conference has been verified, it will not
                 need to be re-verified for subsequent meetings, the verification
                 will carry over.
              </p>
              <p>To have your conference verified, please email the following information to: help@0div0.com:
              </p>
              <ul>
                <li>Your conference's name and acronym and how many years it has been running.
                </li>
                <li>The link to your conference on ZeroDivZero.
                </li>
                <li>Your conference's past and present information website. If there are none,
                    please explain.
                </li>
                <li>Organizer's webpage and phone number.
                </li>
                <li>Where is your conference venue?
                </li>
                <li>What are the dates for this year's conference?
                </li>
              </ul>
            </div>


    if @state.moreQs is true
        more_questions =
            <div className='row no-gutters bg-white p-2 border_rad_tenpx'>
                We encourage you to send us an email: help@0div0.com.
                We'll make sure the right person sees it.
            </div>

    #if @state.expandAll is false
    #    expand_hide_button =
    #      <div className='row no-gutters'>
    #        <button className='btn btn-secondary'
    #                onClick={@expandAllTabs}>
    #                expand all
    #                </button>
    #      </div>
    #else
    #    expand_hide_button =
    #      <div className='row no-gutters'>
    #        <button className='btn btn-secondary'
    #                onClick={@hideAllTabs}>
    #                hide all
    #                </button>
    #      </div>
    expand_hide_button = <span></span>

    <div>
      <HeaderBar />
      <br />
      <div className='bg-offwhite p-3'>
          <div className='container-fluid'>
            <div className='row no-gutters m-3'>
                 <span className='display-4 ml-2'>Have a question?</span>
            </div>
          </div>
      </div>
      <div className='bg-white p-3'>
          <div className='container-fluid'>
            <div className='row no-gutters m-3'>
                { expand_hide_button }
            </div>
            <div className='row no-gutters m-3'>
              <button className='btn btn-link btn-txt-lg' onClick={@showHowItWorksContributors}>
                      How to submit to a conference...
              </button>
            </div>
            { how_it_works_for_contributors }
            <div className='row no-gutters m-3'>
              <button className='btn btn-link btn-txt-lg' onClick={@showHowItWorksParticipate}>
                      Other ways to participate in a conference...
              </button>
            </div>
            { how_it_works_for_participating_in_conference }
            <div className='row no-gutters m-3'>
              <button className='btn btn-link btn-txt-lg' onClick={@showHowItWorksHosts}>
                      How to host a conference...
              </button>
            </div>
            { how_it_works_for_conference_hosts }
            <div className='row no-gutters m-3'>
              <button className='btn btn-link btn-txt-lg' onClick={@showVerification}>
                      How do I have my conference verified?
              </button>
            </div>
            { verification_procedure }
            <div className='row no-gutters m-3'>
              <button className='btn btn-link btn-txt-lg' onClick={@showGetMoreHelp}>
                      Question not answered or have a suggestion?
              </button>
              <br />
            </div>
            { more_questions }
          </div>
      </div>
    </div>
