Link = require('react-router-dom').Link
common = require('../common')
Abstract = require('../abstract')
AreaChairAddBox = require('./areachair_add_box')
AreaChairNameRollEditable = require('../areachair_name_roll_editable')
AuthorNameRoll = require('../authorcontact/author_name_roll')
Bookmark = require('../bookmark')
DocumentConferenceButton = require('../uploads/document_conference_button')
Motive = require('../motive')
NoteCommentsOnly = require('../comments/note_comments_only')
NoteReviewsOnly = require('../comments/note_reviews_only')
PosterConferenceButton = require('../uploads/poster_conference_button')
ReviewerNameRollEditable = require('../reviewer_name_roll_editable')
ReviewerAddBox = require('./reviewer_add_box')
ShowOrEditNoteSessions = require('./show_or_edit_note_sessions')
SlidesConferenceButton = require('../uploads/slides_conference_button')
# SubmittedConferenceItems = require('../submitted_conference_items')
TagNoteRoll = require('../tags/tag_note_roll')
TimePlace = require('../schedule/time_place')
TitleButton = require('../titles/title_button')
VerticalVoteComponent = require('../social/vertical_vote_component')

module.exports = class ConferenceNoteReviews extends React.Component
  displayName: 'ConferenceNoteReviews'

  constructor: (props) ->
    super(props)
    # expects props:
    # clocks, places
    @state = {
      data: {noteid: @props.note.id, votevalue: @props.note.myvote}
      show_comments : 'false'
      show_abstract : 'false'
      note: @props.note

      reviewerlist : [ ]
      show_possible_reviewers: 'false'
      reviewers: [ ]
      show_add_reviewers_button: 'true'

      areachairlist : [ ]
      show_possible_areachairs: 'false'
      areachairs: [ ]
      show_add_areachairs_button: 'true'

      rolewords: []

      project: @props.project
    }

  gotoShowAbstract: () =>
      @setState({ show_abstract : 'true' })

  gotoHideAbstract: () =>
      @setState({ show_abstract : 'false' })

  gotoShowComments: () =>

      data =
          projectid : @state.note.project_id
          noteid : @state.note.id
          ctype : 'informal'

      common.getNoteComments(data, (result) =>
          addCommentsToNote = @state.note
          addCommentsToNote.comments = result['comments']
          @setState({ note: addCommentsToNote, show_comments : 'true' })
      )

  chosenReviewer: (reviewer) =>

      reviewerlist = @state.reviewerlist

      found = 0
      if @state.note.reviewers.length > 0
          for rev in @state.note.reviewers
              if reviewer.user_id is rev.user_id
                  found = 1

      if found is 0
          reviewerlist.push(reviewer)
          @setState({ reviewerlist : reviewerlist })

  assignReviewersToNote: () =>

      local_note = @state.note

      if @state.reviewerlist.length > 0
        for reviewer in @state.reviewerlist
          local_note.reviewers.push(reviewer)
        @setState({ note : local_note })

        reviewerids = [ ]
        for reviewer in @state.reviewerlist
            reviewerids.push(reviewer.id)

        data =
            chosen_noteid : @state.note.id
            chosen_paperseriesid : @state.note.paperseries_id
            reviewerids : reviewerids
            projectid : @state.note.project_id

        common.assignReviewersToConferencePaperSeries(data, (result) =>
            @setState({ reviewerlist : [ ] })
            @setState({ show_add_reviewers_button: 'true' })
            @setState({ show_possible_reviewers: 'false' })
        )

  chosenAreaChair: (areachair) =>

      areachairlist = @state.areachairlist

      found = 0
      if @state.note.areachairs.length > 0
          for rev in @state.note.areachairs
              if areachair.user_id is rev.user_id
                  found = 1

      if found is 0
          areachairlist.push(areachair)
          @setState({ areachairlist : areachairlist })

  assignAreaChairsToNote: () =>

        local_note = @state.note

        if @state.areachairlist.length > 0
          for areachair in @state.areachairlist
            local_note.areachairs.push(areachair)
          @setState({ note : local_note })

          areachairids = [ ]
          for areachair in @state.areachairlist
              areachairids.push(areachair.id)

          data =
              chosen_noteid : @state.note.id
              chosen_paperseriesid : @state.note.paperseries_id
              areachairids : areachairids
              projectid : @state.note.project_id

          common.assignAreaChairsToConferencePaperSeries(data, (result) =>
              @setState({ areachairlist : [] })
              @setState({ show_add_areachairs_button: 'true' })
              @setState({ show_possible_areachairs: 'false' })
          )

  gotoHideComments: () =>
      @setState( show_comments: 'false' )

  updatePaperAcceptanceTime: (acceptance_choice) =>

      data =
          projectid : @state.note.project_id
          acceptance_choice : acceptance_choice
          noteid : @state.note.id

      common.updateConferenceNoteAcceptanceTime(data, (result) =>
          #no result
      )

  acceptPaper: () =>
      updated_note = @state.note
      updated_note.acceptance_time = 1000
      @setState({ note: updated_note })
      @updatePaperAcceptanceTime("accepted")

  rejectPaper: () =>
      updated_note = @state.note
      updated_note.acceptance_time = -1
      @setState({ note: updated_note })
      @updatePaperAcceptanceTime("rejected")

  editAcceptanceTime: () =>
      updated_note = @state.note
      updated_note.acceptance_time = 0
      @setState({ note: updated_note })
      @updatePaperAcceptanceTime("zero")

  removeReviewerFromPaperSeries: (reviewer) =>
      data =
        noteid: @props.note.id
        userid: reviewer.user_id
        role: 'reviewer'

      common.removeThisJobFromConferencePaperSeries(data, (result) =>
          #empty response
          tmp_note = @state.note
          for note_reviewer in tmp_note.reviewers
              if reviewer.user_id is note_reviewer.user_id
                note_reviewer.name = 'Removed!'

          @setState({ note : tmp_note })
      )

  removeAreaChairFromPaperSeries: (areachair) =>
      data =
        noteid: @props.note.id
        userid: areachair.user_id
        role: 'areachair'

      common.removeAreaChairFromConferencePaperSeries(data, (result) =>
          #empty response
          tmp_note = @state.note
          for note_areachair in tmp_note.areachairs
              if areachair.user_id is note_areachair.user_id
                note_areachair.name = 'Removed!'

          @setState({ note : tmp_note })
      )


  okToShowTheAreaChairList: =>
      @setState({ show_possible_areachairs: 'true' })
      @setState({ show_add_areachairs_button: 'false' })

  okToShowTheReviewerList: =>
      @setState({ show_possible_reviewers: 'true' })
      @setState({ show_add_reviewers_button: 'false' })

  showReviewers: =>

      if @state.show_possible_reviewers is 'true'
          @setState({ show_possible_reviewers: 'false' })
      else
          data =
              projectid: @props.note.project_id
              role: 'reviewer'

          common.conferenceGetRoleJunctions(data, (result) =>
              @setState({ reviewers : result.combined_rolejunctions })
              @setState({ reviewers : result.combined_rolejunctions },@okToShowTheReviewerList)
          )

  showAreaChairs: =>

      if @state.show_possible_areachairs is 'true'
          @setState({ show_possible_areachairs: 'false' })
      else
          data =
              projectid: @props.note.project_id
              role: 'areachair'

          common.conferenceGetRoleJunctions(data, (result) =>
              @setState({ areachairs : result.combined_rolejunctions })
              @setState({ areachairs : result.combined_rolejunctions },@okToShowTheAreaChairList)
          )

  render: =>

      if @state.show_abstract is 'true'
          abstract_section =
              <span>
                  <Abstract abstract={@props.note.abstract}
                            editor={@props.note.editor} /> <br/>
                  <button className='btn btn-link btn-sm'
                          onClick={@gotoHideAbstract}>
                          hide</button>
              </span>
      else
          abstract_section =
              <span>
                  <button className="btn btn-link btn-sm"
                          onClick={@gotoShowAbstract}>
                          show</button>
              </span>

      if @state.show_comments is 'true'
          comments_section =
              <span>
                  <NoteCommentsOnly note={@state.note}
                      reviewq={@props.reviewq}/>
                    <div className='row no-gutters mt-1'>
                      <button className='btn btn-link btn-sm'
                              onClick={@gotoHideComments}>
                              hide</button>
                    </div>
              </span>
      else
          comments_section =
              <div className='row no-gutters mt-1'>
                  <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                      <strong>{@props.note.comment_counts.informal} Comments</strong>
                  </div>
                  <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                    <button className="btn btn-link btn-sm"
                        onClick={@gotoShowComments}>
                        show</button>
                  </div>
              </div>

      reviews_section =
          <span>
            <NoteReviewsOnly note={@state.note}
                reviewq={@props.reviewq}/>
          </span>

      if (@props.panel_type is 'areachair') or (@props.panel_type is 'chair')
          trophy_edit_button =
              <button className="btn btn-secondary btn-sm"
                      onClick={@editAcceptanceTime}>
                      edit</button>


      if @state.note.acceptance_time > 0
          acceptance_trophy =
              <span>
                  <div className="btn-group-vertical">
                      <i className="fa fa-trophy text-success"></i>
                      { trophy_edit_button }
                  </div>
              </span>
      else if @state.note.acceptance_time < 0
          acceptance_trophy =
              <span>
                  <div className="btn-group-vertical">
                      <i className="fa fa-times"></i>
                      { trophy_edit_button }
                  </div>
              </span>
      else if (@props.panel_type is 'chair')
          acceptance_trophy =
              <span>
                  <div className="btn-group-vertical">
                      <button className="btn btn-secondary btn-sm"
                              onClick={@acceptPaper}>
                              accept</button>
                      <button className="btn btn-secondary btn-sm"
                              onClick={@rejectPaper}>
                              reject</button>
                  </div>
              </span>

      if (@state.note.acceptance_time > 0) and ((@props.panel_type is 'areachair') or (@props.panel_type is 'chair'))
          scheduler =
                <div className='row no-gutters mt-1'>
                    <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                        <strong>Time/Place</strong>
                    </div>
                    <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                        <TimePlace spottable_id={ @state.note.spottable_id }
                                   id={ @state.note.id }
                                   tmp_title={ @state.note.title }
                                   projectid={ @state.note.project_id }
                                   obj_type={ 'note' }
                                   clocks={ @props.clocks }
                                   places={ @props.places } />
                    </div>
                </div>

      if (@props.panel_type is 'areachair') or (@props.panel_type is 'chair')

          if @state.show_add_reviewers_button is 'true'
              add_reviewers_button =
                  <button className="btn btn-primary btn-sm ml-1"
                          onClick={@showReviewers}>
                  Add</button>
          else
              add_reviewers_button =
                  <button className="btn btn-primary btn-sm ml-1"
                          onClick={@assignReviewersToNote}>
                  Save</button>

          if @state.note.reviewers.length > 0
              reviewer_roll_editable =
                  <span>
                    {@state.note.reviewers.map((reviewer) =>
                            <ReviewerNameRollEditable key={reviewer.user_id}
                                      reviewer={reviewer}
                                      onClick={@removeReviewerFromPaperSeries.bind(this,reviewer)} />
                        )}
                  </span>
          else
              reviewer_roll_editable = <span></span>

          if @state.show_possible_reviewers is 'true'
              available_reviewers_list =
                  <span>
                    {@state.reviewers.map((reviewer) =>
                        <ReviewerAddBox
                                key={reviewer.id}
                                reviewer={reviewer}
                                reviewerlist={@state.reviewerlist}
                                onClick={@chosenReviewer.bind(this,reviewer)} />
                    ,this)}
                    <button className="btn btn-primary btn-sm"
                            onClick={@showReviewers}>
                            Hide</button>
                  </span>

          reviewer_options =
              <span>
                  <div className='row no-gutters mt-1'>
                      <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                      <strong>Edit reviewers</strong>
                      </div>
                      <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                          { reviewer_roll_editable }
                          { add_reviewers_button }
                      </div>
                  </div>
                  { available_reviewers_list }
              </span>


          if @state.note.areachairs.length > 0
              areachair_roll_editable =
                  <span>
                    {@state.note.areachairs.map((areachair) =>
                            <AreaChairNameRollEditable key={areachair.user_id}
                                      areachair={areachair}
                                      onClick={@removeAreaChairFromPaperSeries.bind(this,areachair)} />
                        )}
                  </span>
          else
              areachair_roll_editable = <span></span>

          if @state.show_add_areachairs_button is 'true'
              add_areachairs_button =
                  <button className="btn btn-primary btn-sm ml-1"
                          onClick={@showAreaChairs}>
                  Add</button>
          else
              add_areachairs_button =
                  <button className="btn btn-primary btn-sm ml-1"
                          onClick={@assignAreaChairsToNote}>
                  Save</button>

          if @state.show_possible_areachairs is 'true'
              available_areachairs_list =
                  <span>
                    {@state.areachairs.map((areachair) =>
                        <AreaChairAddBox
                                key={areachair.id}
                                areachair={areachair}
                                areachairlist={@state.areachairlist}
                                onClick={@chosenAreaChair.bind(this,areachair)} />
                    ,this)}
                    <button className="btn btn-primary btn-sm"
                            onClick={@showAreaChairs}>
                            Hide</button>
                  </span>
            else
                available_areachairs_list =
                    <span></span>

          areachair_options =
              <span>
                  <div className='row no-gutters mt-1'>
                      <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                      <strong>Edit area chairs</strong>
                      </div>
                      <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                          { areachair_roll_editable }
                          { add_areachairs_button }
                      </div>
                  </div>
                  { available_areachairs_list }
              </span>

      else
          areachair_options =
              <span></span>
          reviewer_options =
              <span></span>



      <div className='note_infoDiv'>

          <div className='container-fluid'>

            <div className='row no-gutters mt-1'>
                <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                    <VerticalVoteComponent note={@props.note}/>
                </div>
                <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                    <Link to={ '/conference/' + @props.note.project_id + '/title/' + @props.note.paperseries_id }>
                      <TitleButton title={@props.note.title}
                                  editor={@props.note.editor} />
                    </Link>
                </div>
                <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                    { acceptance_trophy }
                    <Bookmark note={@state.note}
                              roles={@props.roles} />
                </div>
            </div>

          { scheduler }

            <div className='row no-gutters mt-1'>
                <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                    <strong>Authors</strong>
                </div>
                <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                    {@state.note.authorcontacts.map((authorcontact) =>
                        <AuthorNameRoll key={authorcontact.id} authorcontact={authorcontact} />
                    )}
                </div>
            </div>

            <div className='row no-gutters mt-1'>
                <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                    <strong>Abstract</strong>
                </div>
                <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                    { abstract_section }
                </div>
            </div>

            <div className='row no-gutters mt-1'>
                <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                    <strong>Sessions</strong>
                </div>
                <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                    <ShowOrEditNoteSessions note={@props.note}
                        session_tags={@props.project.tags}/>
                </div>
            </div>

            <div className='row no-gutters mt-1'>
                <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                    <strong>Tags</strong>
                </div>
                <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                    {@state.note.tags.map((tag) =>
                        <TagNoteRoll key={tag.id} tag={tag} note_type='conference' />
                    )}
                </div>
            </div>

          <DocumentConferenceButton note={@state.note} />
          <SlidesConferenceButton note={@state.note} />
          <PosterConferenceButton note={@state.note} />

          { areachair_options }
          { reviewer_options }
          { reviews_section }
          { comments_section }

          </div>

      </div>
