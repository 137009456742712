common = require('../common')
Link = require('react-router-dom').Link
NoteCommentReplyRoll = require('./note_comment_reply_roll_0div0_editor')
ReviewQRoll = require('./reviewq_roll')
EditorChoice = require('../editor_choice')
Writing = require('../writing')
GetWriting = require('../get_writing_manual_resize')

module.exports = class NoteCommentRoll0div0Editor extends React.Component
  displayName: 'NoteCommentRoll0div0Editor'

  constructor: (props) ->
      super(props)
      @state = {
          comment: @props.comment
          username: @props.name
          mode: 'reading'
          # The type of comment -- MathJax or SetInnerHTML. We need to preserve
          # this when setting the default.
          commentEditor: @props.comment.editor
      }
      @replyWritingRef = React.createRef()
      @replyEditorChoiceRef = React.createRef()
      @editWritingRef = React.createRef()
      @editEditorChoiceRef = React.createRef()

  popReplyWindow: () =>
      @setState({mode: 'replying'})

  submitReply: () =>
      newreply =
          commentseriesid: @props.comment.commentseries_id
          text: @replyWritingRef.current.getValue()
          editor: @replyEditorChoiceRef.current.getEditor()

      common.submitProjectNoteReply(newreply, (result) =>
          updated_comment = @state.comment
          if updated_comment.replies
              updated_comment.replies.push(result['reply'])
          else
              updated_comment.replies = [result['reply']]
          @setState({comment: updated_comment, mode: 'reading'})
      )

  cancelEditMode: () =>
      @setState({mode: 'reading'})

  editExistingComment: () =>
      @setState({mode: 'edit my comment'})

  submitEditedComment: () =>
      data =
          commentseriesid: @props.comment.commentseries_id
          text: @editWritingRef.current.getValue()
          editor: @editEditorChoiceRef.current.getEditor()

      common.editNoteComment(data, (result) =>
          updated_comment = @state.comment
          updated_comment.content = data.text
          @setState({
              comment: updated_comment
              mode: 'reading'
              commentEditor: updated_comment.editor
          })
      )

  deleteComment: () =>
      data =
          commentseriesid: @props.comment.commentseries_id

      common.hideNoteComment(data, (result) =>
          deleted_comment = @props.comment
          deleted_comment.content = "[removed]"
          deleted_comment.name = "[removed]"
          deleted_comment.user_id = ""
          @setState({
              comment: deleted_comment
              mode: 'reading'
          })
      )

  render: =>
      if @state.comment.replies
          comment_replies =
              <span>
              {@state.comment.replies.map((reply) =>
                  <NoteCommentReplyRoll key={reply.id} reply={reply} data={@props.data} />
              , this)}
              </span>

      if @state.mode is 'replying'
          reply_form =
            <div className='container-fluid no-border'>
              <div className="d-flex flex-row bd-highlight">
                <div className='bd-highlight'>
                  Reply: &nbsp;
                </div>
                <div className='bd-highlight'>
                  <EditorChoice ref={@replyEditorChoiceRef} />
                </div>
              </div>
              <div className="d-flex flex-row mb-3 bd-highlight">
                <div className='bd-highlight input-group'>
                  <GetWriting ref={@replyWritingRef} key={@state.comment.commentseries_id}
                    rows='1' placeholder="" />
                </div>
                <div className='ml-auto bd-highlight'>
                  <button className='btn btn-success btn-txt-sm'
                      onClick={@submitReply}>submit</button>
                </div>
              </div>
            </div>


      if @props.data.logged_in_userid is @state.comment.user_id
          edit_option =
              <button className='btn btn-primary btn-txt-sm'
                  onClick={@editExistingComment}> Edit </button>

      if (@state.comment.ctype is 'formal') or (@state.comment.ctype is 'formal_anon')
        if @props.reviewq
            Q_array = @props.reviewq.split("\n")

      if @state.comment.ctype is 'formal_anon'
          anon_or_public_default_text =
              <span>
                  Please use these questions for your <strong>anonymous</strong> review.
              </span>
      else if @state.comment.ctype is 'formal'
          anon_or_public_default_text =
              <span>
                  Please use these questions for your <strong>public</strong> review.
              </span>

      if (@state.comment.ctype is 'formal') or (@state.comment.ctype is 'formal_anon')
          if Q_array
              default_txt =
                  <span>
                    <div>
                      {anon_or_public_default_text}
                    </div>
                    <div>
                        <ul>
                            {Q_array.map((Q) =>
                                <ReviewQRoll key={Math.random()} question={Q}/>
                            , this)}
                        </ul>
                    </div>
                  </span>
          else
              default_txt =
                  <span>
                  </span>
      else
          default_txt = ""


      if (@state.comment.ctype is 'formal') or (@state.comment.ctype is 'formal_anon')
          delete_button = <span></span>
      else
          delete_button =
              <button className='btn btn-success btn-sm btn-txt-sm'
                  onClick={@deleteComment}>&nbsp;delete&nbsp;
              </button>

      if @state.mode is 'edit my comment'
          comment_form =
            <div className='container-fluid no-border'>
              <div className='row no-gutters'>
                 {default_txt}
              </div>
              <div className="d-flex flex-row bd-highlight">
                <div className='bd-highlight'>
                  Comment: &nbsp;
                </div>
                <div className='bd-highlight'>
                  <EditorChoice ref={@editEditorChoiceRef} editor={@state.commentEditor}/>
                </div>
              </div>
              <div className="d-flex flex-row bd-highlight">
                <GetWriting ref={@editWritingRef} key={@state.comment.id}
                  rows='1' placeholder="" defaultValue={@state.comment.content} />
                <span>
                  <button className='btn btn-success btn-txt-sm'
                      onClick={@cancelEditMode}>&nbsp;cancel&nbsp;
                  </button>
                  <button className='btn btn-success btn-txt-sm'
                      onClick={@submitEditedComment}>&nbsp;submit&nbsp;
                  </button>
                  {delete_button}
                </span>
              </div>
            </div>
      else
          comment_form =
            <div className="d-flex flex-row bd-highlight no-border">
              <div className='bd-highlight no-border'>
                <Link to={'/profile/' + @props.comment.user_id}>
                  <button className='btn btn-success btn-txt-sm'>{@state.comment.name}</button>
                </Link>
              </div>
              <div className='bd-highlight no-border'>
                <Writing editor={@state.comment.editor} writing={@state.comment.content} />
              </div>
              <div className='bd-highlight no-border'>
                <button className='btn btn-primary btn-txt-sm ml-2' onClick={@popReplyWindow}>Reply</button>
                {edit_option}
              </div>
            </div>

      # if a comment has been deleted & there are no replies, then don't display
      if @state.comment.hidden is 1
          if typeof @state.comment.replies is 'undefined'
              comment_form = null

      <div className='no-border'>
        {comment_form}
        {reply_form}
        {comment_replies}
      </div>
