common = require('../common')
DocumentFrame = require('./document_frame')


module.exports = class PosterConferenceButton extends React.Component
    displayName: 'PosterConferenceButton'

    constructor: (props) ->
        super(props)
        note = @props.note
        @state = {
            note: note
            mode: 'reading'
            blawbcontent: '',
            blawbid: ''
            poster: ''
        }

    viewUserPoster: () =>
        @setState({mode: 'viewing'})

    viewPosterinNewWindow: () =>
      window.open('/conference/' + @state.note.project_id + '/title/' +
                  @state.note.paperseries_id + '/poster')

    gotoHidePoster: () =>
        @setState({ mode : 'reading' })

    componentDidMount: =>
        data =
            projectid: @props.note.project_id
            paperseriesid: @props.note.paperseries_id

        common.getPosterFromNoteInfo(data, (result) =>
            if result.poster?
                @setState({ poster: result.poster })
                if (@props.hasSubmitted) and (result.poster.body_blawb_id)
                    @props.hasSubmitted({submit_type: 'poster', status:'true'})
                else if (@props.hasSubmitted)
                    @props.hasSubmitted({submit_type:'poster', status:'false'})
        )

    render: =>
        if @state.mode is 'reading'
            if @state.poster.body_blawb_id or @state.blawbid
                if @state.poster.body_blawb_id is 'empty_submission'
                    poster_button =
                        <button className="btn btn-link btn-items mr-2">
                            Poster (not submitted yet)
                        </button>
                else
                    poster_button =
                        <button className="btn btn-link btn-items mr-2"
                            onClick={@viewPosterinNewWindow}>
                            Poster
                        </button>

        else if @state.mode is 'viewing'
            filename = "/view_paper_poster/" + @state.note.id
            poster_button =
                <div>
                    <button className="btn btn-link btn-sm" onClick={@gotoHidePoster}>hide poster</button>
                    <DocumentFrame className="mini_view_document_iframe" src={filename} />
                    <button className="btn btn-link btn-sm" onClick={@gotoHidePoster}>hide poster</button>
                </div>

        if @state.poster.body_blawb_id or @state.blawbid
            <span className='mr-2'>
                {poster_button}
            </span>
        else
            <span></span>
