common = require('../common')
EmailVerificationForAccountMerging = require('./email_verification_for_account_merging')

module.exports = class AccountMergingTool extends React.Component
  displayName: 'AccountMergingTool'

  constructor: (props) ->
    super(props)
    # expects as props: email, userid
    @state = {}

  render: =>
      <span>
        <p className="text-center text-danger">
            Did you already open a separate account with the email: { @props.email }?
        </p>
        <p className="text-center">
            Time to merge this account with your former account.
        </p>
        <p className="text-center">
            Verify that you own this email and we can merge this account and your former account.
        </p>
        <div className="d-flex justify-content-center">
            <EmailVerificationForAccountMerging userid={ @props.userid } email={ @props.email} />
        </div>
      </span>
