common = require('../common')
Loading = require('../loading')
ConferencePaymentsSummaryLine = require('./conference_payments_summary_line')

module.exports = class ShowConferenceAccount extends React.Component
  displayName: 'ShowConferenceAccount'

  constructor: (props) ->
    super(props)
    # expects props: project
    @state = {
      mode: 'loading'
      payments: []
    }

  componentDidMount: =>
      data =
          projectid: @props.project.id

      common.getConferenceRegistrationRevenue(data, (result) =>
          @setState({
              payments: result.payments
              mode: 'viewing' })
      )

  render: =>
    if (@state.mode is 'viewing') and (@state.payments.length > 0)
        <div>
            <table className="table">
                <thead>
                <tr>
                  <th scope="col">Category</th>
                  <th scope="col">Fee</th>
                  <th scope="col">Registrations</th>
                  <th scope="col">Total</th>
                </tr>
                </thead>
                <tbody>
                    {@state.payments.map((payment) =>
                        <ConferencePaymentsSummaryLine
                            key={Math.random()}
                            payment={payment} />
                    ,this)}
                </tbody>
            </table>
        </div>
    else if (@state.mode is 'viewing') #and @state.payments.length = 0
        <div>
            No payments yet.
        </div>
    else
      # @state.mode is 'loading'
      <div className='d-flex justify-content-center'>
        <br />
        <Loading />
      </div>
