common = require('./common')

module.exports = class UnknownError extends React.Component
    displayName: 'UnknownError'

    constructor: (props) ->
        super(props)
        # expects props.component that names the buggy component
        # should I get userid too?
        # eventually this will record the event for debugging purposes
        @state = {}

    render: =>
          <div>
              <p>
                  There was an error. Try reloading the page?
              </p>
              <p>
                  If this problem persists, contact: help@0div0.com and provide
                  the steps that lead to the error. We will fix it!
              </p>
          </div>
