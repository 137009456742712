
module.exports = class Title extends React.Component
  displayName: 'Title'

  constructor: (props) ->
      super(props)
      @state = { title: @props.title }

  componentDidMount: () =>
      if @props.editor is 'MathJax'
          window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, @domElement])
      if (@props) and (@props.title.length < 1)
          title = "Untitled"
          @setState({ title: title })


  setDOMRef: (domElement) =>
    @domElement = domElement

  render: =>

    if @props.editor is 'MathJax'
      <span className="mathjax btn-txt-lg"
            ref={@setDOMRef}
            dangerouslySetInnerHTML={{__html: @props.title }}>
      </span>
    else
      <span dangerouslySetInnerHTML={{__html: @props.title }}>
      </span>
