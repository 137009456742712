common = require('../common')
SimpleVote = require('./simple_vote')
Bookmark = require('../bookmark')
SocialSharing = require('./social_sharing')

module.exports = class VerticalVoteComponent extends React.Component
  displayName: 'VerticalVoteComponent'

  constructor: (props) ->
      super(props)
      note = @props.note
      @state = {
        note: @props.note
        data: {noteid: note.id, votevalue: note.myvote}
        shareUrl: ''
      }

  componentDidMount: () =>
      # need to figure out what type of share this is... Maybe this should
      # be punted to the SocialSharing child?
      shareUrl = 'https://zerodivzero.com/' + @props.note.note_type + '/' + @props.note.project_id + '/title/' + @props.note.paperseries_id
      @setState({ shareUrl: shareUrl })

  commentCounts: () =>
      <span>
          {@props.note.comment_counts.informal}&nbsp;
          <button className="btn btn-link p-0 m-0"
              onClick={@props.gotoToggleComments}>Comments</button>
      </span>

  reviewCounts: () =>
      if (@props.note.note_type != 'seminar') and (@props.note.note_type != 'short_course') 
          <span>
              {@props.note.comment_counts.formal}&nbsp;
              <button className="btn btn-link p-0 m-0"
                  onClick={@props.gotoToggleReviews}>Reviews</button>,&nbsp;
          </span>

  render: =>

      <div>
          <SimpleVote
              vote_style='simple'
              note={@props.note} />
          <span className='mr-2, ml-1'>Votes,</span>
          &nbsp;
          { @commentCounts() },
          &nbsp;
          { @reviewCounts() }
          <Bookmark note={@props.note}
              roles={@props.roles} />
          Bookmark,
          <SocialSharing shareUrl={@state.shareUrl}
              title={ @props.note.title }/>
          Share
      </div>
