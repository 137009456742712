Link = require('react-router-dom').Link
common = require('./common')
HeaderBar = require('./header_bar')
ConferenceDirectUpload = require('./conference_direct_upload')
ConferenceHeader = require('./conference_header')
ConferenceNoteDetails = require('./conference_note_details')
CreateNoteMetadata = require('./create_title/create_note_metadata')
Loading = require('./loading')
NavGeneral = require('./nav_general')
RolesBarConf = require('./roles/roles_bar_conf')

module.exports = class ConferenceNote extends React.Component
  displayName: 'ConferenceNote'

  constructor: (props) ->
      super(props)
      @state = {
          paperseriesid : @props.match.params.paperseriesId
          note : ''
          conference : ''
          projectid : @props.match.params.projectId
          roles: []
          my_accepted_roles: []
          wordlist: []
          userid: ''
          mode: 'loading'

          new_note: {}
      }
      @makeNewTitleRef = React.createRef()

  componentDidMount: =>
      data =
          projectid : @props.match.params.projectId
          paperseriesid : @props.match.params.paperseriesId
          note_type: 'conference'

      common.conferenceNote(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({mode : 'invalid_conference'})
          else if result['error'] is 'conference deleted'
              @setState({mode : 'conference_deleted'})
          else if result['note'] is 'removed'
              @setState({mode: 'withdrawn'})
          else
              my_accepted_roles = []
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              conference = result['conference']
              loaded_wordlist = []
              if conference.perms
                for perm in conference.perms
                    loaded_wordlist.push(perm.word)

              @setState({
                conference : conference,
                note: result['note'],
                roles: result['roles'],
                my_accepted_roles: my_accepted_roles,
                wordlist: loaded_wordlist,
                userid: result['userid'],
                mode: 'viewing'
              })

              if result['note']
                  document.title = result['note']['title']
              else
                  document.title = result['conference']['title']
      )

  componentDidUpdate: (prevProps) =>
      if @props.match.params.projectId != prevProps.match.params.projectId or \
         @props.match.params.paperseriesId != prevProps.match.params.paperseriesId
          window.location.reload()

  problemMsg: () =>
      if @state.note is 'removed'
          <div>
              <h2>
                Oops! Hello there.
                <br /> <br />
                This paper is no longer here
                but it might still be in its
                <Link to={'/project/' + @state.conference.id + '/title/' + @props.match.params.paperseriesId}> original project</Link>.
              </h2>
          </div>
      else if @state.mode is 'invalid_conference'
          problem_msg =
              <div>
                  <br />
                  <h2>We cannot find that conference... Try refreshing!</h2>
                  If you continue to get this error, then maybe the conference number is wrong?
              </div>
      else if @state.mode is 'conference_deleted'
          problem_msg =
              <div>
                  <br />
                  <h2>Uh oh... It appears the owner has deleted this conference.</h2>
              </div>
      else if @state.mode is 'some other error'
          <div>
              <br />
              <h2>{@state.error_msg}</h2>
          </div>

  projectHeader: () ->
      <span>
          <ConferenceHeader key={@state.conference.id}
                            project={@state.conference}
                            userid={@state.userid}
                            roles={@state.roles} />
      </span>

  openModal: () =>
      trigger_btn = document.getElementById("modal_open_button")
      if trigger_btn
          trigger_btn.click()

  setMode: (info) =>
      # in this case, new_note might be returned!
      if info is 'cancelled'
          @setState({ mode: 'viewing' })
      else if info.new_note
          @setState({
              new_note: info.new_note
              mode: 'direct_upload_submisson' })
      else
          @setState({ mode: info })

  makeNewPaper: () =>
      if @state.mode is 'create_note_metadata'
          <div ref={@makeNewTitleRef}>
              <CreateNoteMetadata project={@state.conference}
                  setMode={@setMode}
                  version={'new'} />
          </div>
      else if @state.mode is 'direct_upload_submisson'
          <div>
              You have created a personal project and added a title.
              Just a few more choices to finish submission.
              <ConferenceDirectUpload
                  note={@state.new_note}
                  conference={@state.conference} />
          </div>

  showPaper: () =>
      if @state.mode is 'loading'
          <Loading />
      else if @state.note.id and @state.mode != 'create_note_metadata'
          <div>
            <ConferenceNoteDetails project={@state.conference}
                 note={@state.note}
                 roles={@state.roles}
                 login_id={@state.userid} />
          </div>

  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  getUserNoteDetails: =>
      @setState({
          mode : 'create_note_metadata'}, @scrollToHere(@makeNewTitleRef) )

  rolesBar: () =>
      if @state.roles
          roles_bar =
              <RolesBarConf project={@state.conference}
                  my_accepted_roles={@state.my_accepted_roles}
                  roles={@state.roles}
                  userid={@state.userid}
                  view={'full'}
                  setUpdatedRoles={@setUpdatedRoles}
                  getUserNoteDetails={@getUserNoteDetails} />

      <span>
          { roles_bar }
      </span>

  render: =>

      if @state.conference
          nav_vert =
              <div className='col-md-2 col-lg-2 border d-none d-md-block'>
                  <NavGeneral
                      id={@state.conference.id}
                      ptype={@state.conference.ptype}
                      caller={'titles'}
                      subset={@state.subset}
                      getThisSortType={@getThisSortType}
                      userid={@state.userid}
                      styling={'col-12'} />
              </div>
          nav_horiz =
              <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
                  <NavGeneral
                      id={@state.conference.id}
                      ptype={@state.conference.ptype}
                      caller={'titles'}
                      subset={@state.subset}
                      getThisSortType={@getThisSortType}
                      userid={@state.userid}
                      styling={'mr-2'} />
              </div>

      if @state.mode is 'withdrawn'
          <div>
              <HeaderBar />
              <div className='container-fluid border'>
                  <div className='p-3 m-3'>
                      This paper was withdrawn. We do not know why.
                  </div>
              </div>
          </div>
      else
          <div>
              <HeaderBar />
              <div className='container-fluid pr-0 pl-0 border'>
                  { @projectHeader() }
                  <div className='row no-gutters'>
                      {nav_vert}
                      {nav_horiz}
                      <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                          { @rolesBar() }
                          { @problemMsg() }
                          { @showPaper() }
                          { @makeNewPaper() }
                      </div>
                  </div>
              </div>
          </div>
