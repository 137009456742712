
module.exports = class SurveyOverviewRoll extends React.Component
  displayName: 'SurveyOverviewRoll'

  constructor: (props) ->
      super(props)
      @state = {
        delete_stage: '1st'
      }

  askIfSure: =>
      @setState({ delete_stage: '2nd' })


  render: =>

      # if the deleteSurvey fn was passed as props, turn on delete_button
      if (@props.deleteSurvey) and (@state.delete_stage is '1st')
          delete_button =
              <i className='fa fa-minus-circle text-danger p-3'
                 onClick={@askIfSure}></i>
      else if (@props.deleteSurvey) and (@state.delete_stage is '2nd')
          delete_button =
              <button className='btn btn-danger mr-3'
                      onClick={@props.deleteSurvey}>
                  Delete survey: are you sure?
              </button>

      if (@props.response_count)
            response_bit =
                <div className='d-flex justify-content-center'>
                    <small>{ @props.response_count } people answered</small>
                </div>

      if (@props.mode is 'progress')
          if @props.progress is 'answered'
              <div className='border border-primary border_rad_tenpx p-3'>
                  { response_bit }
                  <div className='d-flex justify-content-center'>
                      <button className='btn btn-success'
                              onClick={@props.onClick}>
                          { @props.title }&nbsp;✓
                      </button>
                  </div>
                  <div className='d-flex justify-content-center'>
                      You answered: {@props.user_counts['user_answer_count']} out of {@props.user_counts['asks_count']}
                  </div>
                  <div className='d-flex justify-content-center'>
                      { @props.reason }
                  </div>
              </div>
          else if @props.progress is 'not_answered'
              <div className='border border-primary border_rad_tenpx p-3'>
                  { response_bit }
                  <div className='d-flex justify-content-center'>
                      <button className='btn btn-primary d-flex justify-content-center'
                              onClick={@props.onClick}>
                          { @props.title }
                      </button>
                  </div>
                  <div className='d-flex justify-content-center'>
                      { @props.reason }
                  </div>
              </div>

      else if @props.mode is 'response_overview'
            <div className='clearfix'>
                <div className='d-inline-flex bd-highlight_rad_tenpx p-3'>
                    { delete_button }
                    <button className='btn btn-primary d-flex justify-content-center'
                            onClick={@props.onClick}>
                        { @props.title }
                    </button> -- { @props.response_count } response(s)
                </div>
              </div>

      else
            <div>
                Something went wrong. Try reloading?
            </div>
