common = require('../common')
Header = require('./header')
NavGeneral = require('../nav_general')
HeaderBar = require('../header_bar')
Loading = require('../loading')
# RolesBarLab = require('../roles/roles_bar_lab')

module.exports = class LabGroupAbout extends React.Component
  displayName: 'LabGroupAbout'

  constructor: (props) ->
    super(props)
    projectid = @props.match.params.projectId
    @state = {
      projectid: projectid
      proj_about: ''
      mode: 'reading'
      window_mode: 'loading'
      openEditor: true
      editorState: ''
      editor: ''
      project: ''
      userid: ''
      roles: []
      wordlist: []
      my_accepted_roles: []
      ptype: ''
      ptype_word: ''
    }
    @activeAreaRef = React.createRef()

  updateConferenceData: (data) =>
      if data['results'] is 'not found'
          #@setState({ mode : 'invalid_conference' })
      else if data['results'] is 'deleted'
          #@setState({ mode : 'conference_deleted' })
      else
          my_accepted_roles = []
          for role in data['results'][0]['roles']
              if role.role_accepted is 'true'
                my_accepted_roles.push(role.role)

          project = data['results'][0]
          loaded_wordlist = []
          if project.perms
            for perm in project.perms
                loaded_wordlist.push(perm.word)

          @setState({
              project: project,
              userid: data['userid'],
              roles: data['results'][0]['roles'],
              my_accepted_roles: my_accepted_roles,
              wordlist: loaded_wordlist,
              proj_about: data['results'][0]['about'],
              window_mode: 'viewing'
              }, @setDefaultTxt)

          if data['results'][0]
              document.title = data['results'][0]['title']
          else
              document.title = 'ZeroDivZero'

  setPType: () =>
      if @props.match.url.indexOf('project') > -1
          ptype = 'project'
      else if @props.match.url.indexOf('lab_group') > -1
          ptype = 'lab_group'
      else if @props.match.url.indexOf('conference') > -1
          ptype = 'conference'
      else if @props.match.url.indexOf('seminar') > -1
          ptype = 'seminar'
      else
          ptype = 'short_course'
      ptype_word = ptype.replace(/_/g, ' ')
      @setState({ ptype: ptype, ptype_word: ptype_word })


  componentDidMount: () =>
      callback = (data) => @updateConferenceData(data)
      common.fetchProject(callback, @props.match.params.projectId)
      @setPType()

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()

  setUpdatedRoles:(what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  updateProjectAbout: () =>
      about = @state.editor.getData()
      @setState({ proj_about : about },@toggleReadEditMode())

      data =
          projectid: @state.project.id
          project_about: about

      common.submitProjectAbout(data, (result) =>
        # nothing to see here
      )

  toggleReadEditMode: () =>
      if @state.mode is 'editing'
        @setState({ mode : 'reading' })
        @closeCkEditor()
      else
        @setState({ mode : 'editing' })
        @openEditor()

  gotoToggleAbout: =>
      if @state.show_about is false
          @setState({ show_about : true })
      else
          @setState({ show_about : false })

  cancelUpdate: () =>
      @closeCkEditor()
      @setState({ mode : 'reading' })

  closeCkEditor: () =>
      CKEDITOR.instances.project_about.destroy()
      # @preview()
      @setDefaultTxt

  preview: =>
      document.getElementById("project_about_txt").innerHTML= @state.editor.getData()
      # @refs.project_about_txt.innerHTML = @state.editor.getData()

  setDefaultTxt: () =>
      document.getElementById("project_about_txt").innerHTML = @state.proj_about
      elem = @activeAreaRef.current
      if (elem)
          window.scrollTo(0, elem.offsetTop)

  openEditor: =>
      # @refs.project_about.contenteditable = true
      document.getElementById("project_about").setAttribute("contenteditable", "true")
      editor = CKEDITOR.replace('project_about')
      @setState({editor: editor, openEditor : true})
      editor.on('instanceReady', () =>
          editor.setData(@state.proj_about))
      editor.on('change', (e) =>
          @preview()
      )

  setMode: (mode_option) =>
      if mode_option is 'viewing'
          window.location = '/' + @state.ptype + '/' + @state.project.id + '/titles'
      else if mode_option is 'cancelled'
          @setState({ window_mode: 'viewing', mode: 'reading' })
      else
          @setState({ window_mode: mode_option })

  render: =>

      nav_vert =
          <div className='col-md-2 col-lg-2 border d-none d-md-block'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'about'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'col-12'} />
          </div>
      nav_horiz =
          <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'about'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'mr-2'} />
          </div>

      if @state.mode is 'reading'
          EditorStyle =
            display : 'none'
          PreviewStyle =
            display : 'block'
          ButtonRow =
              if ('chair' in @state.my_accepted_roles) or ('creator' in @state.my_accepted_roles)
                  <div>
                      <button className="btn btn-secondary" onClick={@toggleReadEditMode}>
                        Edit
                      </button>
                    <br />
                  </div>
      else
          EditorStyle =
            display : 'block'
          PreviewStyle =
              display : 'none'
          ButtonRow =
              <div className='proj_block'>
                  <button className="btn btn-secondary" onClick={@cancelUpdate}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" onClick={@updateProjectAbout}>
                    Save
                  </button>
              </div>

      AboutRow =
          <div>
            <div className='proj_motive_CKEDitor'>
              <textarea style={EditorStyle}
                        id="project_about"
                        defaultValue={@state.proj_about}
                        onChange={@changeHandler}>
              </textarea>
              <div className='row m-3'>
                  <div className='col'>
                      <div style={PreviewStyle}
                           id='project_about_txt'>
                      </div>
                  </div>
              </div>
            </div>

          </div>

      lab_header =
          <span>
              <Header key={@state.project.id}
                      project={@state.project}
                      userid={@state.userid}
                      roles={@state.roles}
                      wordlist={@state.wordlist} />
          </span>

      if @state.window_mode is 'viewing'
          <div>
            <HeaderBar />
            <div className='container-fluid pr-0 pl-0 border'>
                { lab_header }
                <div className='row no-gutters'>
                    {nav_vert}
                    {nav_horiz}
                    <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10 border'
                        ref={@activeAreaRef}>
                        <div className='m-2'>
                            { ButtonRow }
                        </div>
                        { AboutRow }
                        <div className='m-2' style={EditorStyle}>
                            { ButtonRow }
                        </div>
                    </div>
                </div>
            </div>
          </div>
      else if @state.window_mode is 'loading'
          <Loading />
      else
          <div>
              <HeaderBar />
              <h1 ref={@activeAreaRef}>
                  This {@state.ptype_word} does not exist or has been deleted.
              </h1>
          </div>
