Autosize = require('autosize')


module.exports = class GetWriting extends React.Component
  displayName: 'GetWriting'

  constructor: (props) ->
      super(props)
      @textareaRef = React.createRef()

  componentDidMount: () =>
    # might need the .focus, not sure
    # @refs.text_input.focus()
    # Autosize(document.getElementById('text_input'))
    # Autosize(document.querySelector('textarea'))
    Autosize(@refs.text_input)

  updateText: (event) =>
      if @props.onChange?
          @props.onChange(event.target.value)

  getValue: =>
      @textareaRef.current.value

  setValue: (value) =>
      @textareaRef.current.value = value

  render: =>
      <span className="get_writing no-border">
          <textarea ref={@textareaRef} rows={@props.rows}
            defaultValue={@props.defaultValue ? ""} placeholder={@props.placeholder ? ""}
            onChange={@updateText}>
          </textarea>
      </span>
