common = require('../common')
HeaderBar = require('../header_bar')
Policies = require('../site_info/policies')
RightPanel = require('../front_page/rightpanel')

module.exports = class Unsubscribe extends React.Component
  displayName: 'Unsubscribe'

  constructor: (props) ->
      super(props)
      @state = {}

  render: =>

    <span>
      <HeaderBar />
      <div className='bg-offwhite p-3 border-top'>
          <div className='container-fluid'>

              <div className='row'>

                  <div className='col-xs-7 col-sm-7 col-md-7 col-lg-7'>
                      <div className='row mt-3 mb-3'>
                          <h2>Subscription Info</h2>
                      </div>
                      <div className='row'>
                          Currently we do not send emails (other than this verification).
                      </div>
                  </div>

                  <div className='col-xs-5 col-sm-5 col-md-5 col-lg-5'>
                      <RightPanel />
                  </div>

              </div>

          </div>
      </div>

      <br />

      <div className='container-fluid mt-3'>
          <div className='bg-white mt-3'>
              <Policies />
              <br />
          </div>
      </div>

    </span>
