common = require('../common')
Link = require('react-router-dom').Link
AffiliationRoll = require('../affiliation_roll')
TagNoteRoll = require('../tags/tag_note_roll')

module.exports = class AreaChairAddBox extends React.Component
  displayName: 'AreaChairAddBox'

  constructor: (props) ->
    super(props)
    @state = {
      affiliations: []
      mode: 'not selected'
    }

  assignIdsToAffils: (affils) =>
      affil_objs = [ ]
      n = 0
      if affils
        for affil in affils
            obj = { }
            n = n+1
            obj.id = Math.floor(Math.random() * 10000000000000000)
            obj.name = affil
            affil_objs.push(obj)
        @setState({ affiliations : affil_objs })

  componentDidMount: () =>
      @assignIdsToAffils(@props.areachair.affiliations)

  componentWillReceiveProps: (nextProps) =>
      if @props.areachair in nextProps.areachairlist
          @setState({ mode : 'selected' })
      else
          @setState({ mode : 'not selected' })

  render: =>
      if @props.areachair.tags
          tags =
            <span>
              {@props.areachair.tags.map((tag) =>
                  <TagNoteRoll key={tag.id} tag={tag} note_type='conference'/>
              )}
            </span>
      else
          tags =
            <strong>none</strong>

      if @props.areachair.affiliations
          affils =
            <span>
              {@state.affiliations.map((affiliation) =>
                  <AffiliationRoll key={affiliation.id} affiliation={affiliation} />
              )}
            </span>
      else
          affils =
            <strong>none</strong>


      if @props.areachair.user_id
          if @props.areachair.role_accepted is 'true'
              if @state.mode is 'not selected'
                  divBG = 'review_mini_note_infoDiv'
                  btn_for_registered_user =
                    <button className='btn btn-dark btn-sm'
                            onClick={ @props.onClick }>add</button>
              else
                  divBG = 'review_mini_note_infoDiv_selected'
                  btn_for_registered_user =
                    <button className='btn btn-success btn-sm'
                            onClick={ @props.onClick }>added</button>
          else
              # if role_accepted is 'false'
              divBG = 'review_mini_note_notRegistered'
              btn_for_registered_user =
                <span> please remind this areachair to attend the conference! </span>
      else
          divBG = 'review_mini_note_notRegistered'
          btn_for_registered_user =
            <span> please remind this areachair to open an account
                   and attend the conference! </span>


      <div className='review_Div'>
        <div className={ divBG }>
            <Link to={ '/profile/' + @props.areachair.user_id }>
                <button className="btn btn-link">
                        {@props.areachair.name.toString()} </button>
            </Link>
            { btn_for_registered_user }
            <div>
            <strong>Affiliations: </strong> { affils }
            </div>
            <div>
            <strong>Tags:</strong> { tags }
            </div>
            <div>
            Reviewing: { @props.areachair.num_review_jobjunctions } papers
            </div>
        </div>
      </div>
