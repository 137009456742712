common = require('../common')
ShowDate = require('./show_date')
ShowPlace = require('./show_place')

module.exports = class GetDatePlace extends React.Component
  displayName: 'GetDatePlace'

  constructor: (props) ->
      super(props)
      # props are:
      # spottable_id
      # id of object
      # obj_type of object ('note' or ...)
      # clocks and places (available)
      @state = {
          datetime: ''
          duration: ''
          gmt_offset: ''
          timezone: ''
          place: {name: '', lat: '', lon: ''}
          time_error_msg: ''
          place_error_msg: ''
          t_alert: ''
      }

  getTimeDateInfo: () =>

      if @props.spottable_id
          data =
              spottable_id: @props.spottable_id

          common.getTimePlaceForSpottable(data, (result) =>
              # if the note has a spot, set it
              if (result.spot) and (result.spot.clock)
                  datetime = result.spot.clock.debut
                  duration = (result.spot.clock.fin - result.spot.clock.debut)/60
                  gmt_offset = result.spot.clock.gmt_offset
                  timezone = result.spot.clock.timezone
              else
                  datetime = ''
                  duration = ''
                  gmt_offset = ''
                  timezone = ''
                  t_alert = 'text-danger'
              place = {name: '', lat: '', lon: ''}
              if (result.spot) and (result.spot.place)
                  place.name = result.spot.place.name
                  place.lat = result.spot.place.lat
                  place.lon = result.spot.place.lon
                  t_alert = ''
              @setState({
                  datetime: datetime
                  duration: duration
                  gmt_offset: gmt_offset
                  timezone: timezone
                  place: place
                  time_error_msg: ''
                  place_error_msg: ''
                  t_alert: t_alert
                  })
          )
      else
          # if the note lacks a spot, show available times and places
          @setState({
              datetime: ''
              duration: ''
              gmt_offset: ''
              timezone: ''
              place: {name: '', lat: '', lon: ''}
              time_error_msg: ''
              place_error_msg: ''
              t_alert: 'text-danger'
              })


  componentDidMount: =>
      @getTimeDateInfo()

  componentDidUpdate: (prevProps) =>
      if @props.spottable_id != prevProps.spottable_id
          @getTimeDateInfo()

  theDateTime: () =>
      <span>
          <ShowDate
              datetime={@state.datetime}
              duration={@state.duration}
              gmt_offset={@state.gmt_offset}
              timezone={@state.timezone} />
      </span>

  thePlace: () =>
      <span>
          <ShowPlace
              name={ @state.place.name }
              lat={ @state.place.lat }
              lon={ @state.place.lon } />
      </span>


  render: =>

      <span>
          <div className='row'>
              <span className={ @state.t_alert }>
                  { @theDateTime() } { @state.time_error_msg }
              </span>
          </div>
          <div className='row'>
              <span className={ @state.t_alert }>
                  { @thePlace() } { @state.place_error_msg }
              </span>
          </div>
      </span>
