ChoiceRoll = require('./choice_roll')
AnswerStatsRoll = require('./answer_stats_roll')


module.exports = class SurveyRoll extends React.Component
  displayName: 'SurveyRoll'

  # expects @props.mode is 'editable' or 'take_survey'

  render: =>

      if @props.mode is 'editable'
          <span>
              <div className='row no-gutters'>
                  <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                      { @props.question.ask }
                  </div>
                  <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2 text-danger'>
                      <i className='fa fa-minus-circle'
                          onClick={@props.onClick}></i>
                  </div>
              </div>
              <div className='row no-gutters'>
                  <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                      <strong>&nbsp;</strong>
                  </div>
                  <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                      <span className='d-flex align-content-end flex-wrap'>
                          { @props.question.choices.map((choice) =>
                              <ChoiceRoll key={Math.random()}
                                          mode='static'
                                          choice_word={choice} />
                          , this)}
                      </span>
                  </div>
              </div>
          </span>
      else if @props.mode is 'common'
          <button className='btn btn-outline-primary btn-txt-sm border_rad_tenpx p-3'
                onClick={@props.onClick} >
                { @props.question.ask }
          </button>
      else if @props.mode is 'get_answers'
          <span>
              <div className='row no-gutters'>
                  <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                      { @props.item.question }
                  </div>
                  <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                      <span className='text text-secondary btn-txt-sm'>id:&nbsp;{ @props.item.short_id }</span>
                  </div>
              </div>
              <div className='row no-gutters'>
                  <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                      <strong>&nbsp;</strong>
                  </div>
                  <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                      <div className='d-flex align-content-end flex-wrap'>
                          { @props.item.choices.map((choice) =>
                              <ChoiceRoll key={Math.random()}
                                          mode='static'
                                          choice={choice}
                                          choice_word={choice.content}
                                          onClick={@props.saveChoiceSelection.bind(this, choice)} />
                          , this)}
                      </div>
                  </div>
              </div>
          </span>
      else
          <span>
              <div className='row no-gutters'>
                  { @props.item.ask.question }
              </div>
              <div className='row no-gutters'>
                  <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                      <strong>&nbsp;</strong>
                  </div>
                  <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                      <div className='d-flex align-content-end flex-wrap'>
                          { @props.item.choices.map((choice) =>
                              <AnswerStatsRoll key={Math.random()}
                                               choice={choice}
                                               choice_word={choice.content}
                                               onClick={@props.getUserList.bind(this, choice)} />
                          , this)}
                      </div>
                  </div>
              </div>
          </span>
