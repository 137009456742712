Link = require('react-router-dom').Link

module.exports = class ReplicationRoll extends React.Component
  displayName: 'ReplicationRoll'
  # THESE ARE THE LIST OF REPLICATIONS DISPLAYED NEXT TO A PARTICULAR NOTE
  # ON ZDZ SO 'OFFSITE' IS NOT NEEDED.... YET.....

  constructor: (props) ->
      super(props)
      replication = @props.replication[0]
      repl_rj = @props.replication[1]
      title = repl_rj.title
      if repl_rj.study_link
          link_mode = 'ZDZ'
          link = repl_rj.study_link
      else
          link_mode = 'offsite'
          link = replication.replicable_url
      @state = {
          replication: replication
          link_mode: link_mode
          link: link
          title: title
      }

  componentDidMount: =>

      if @props.editor is 'MathJax'
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, @domElement])

  setDOMRef: (domElement) =>
    @domElement = domElement


  goToLink: () =>
      link = @state.link
      if link.slice(0,4) is 'http'
          window.open(link)
      else
          window.open('http://' + link)


  render: =>

      if @props.editor is 'MathJax'
          just_the_facts =
              <span className='mathjax new_lines_enabled'
                    ref={@setDOMRef}
                    dangerouslySetInnerHTML={{__html: @state.replication.facts }}>
              </span>
      else
          just_the_facts =
              <span className='new_lines_enabled'
                    dangerouslySetInnerHTML={{__html: @state.replication.facts }}>
              </span>

      if @state.replication.recommend is 1
          recommendation = 'Yes'
      else
          recommendation = 'No'


      <div className='row border rounded success m-1'>

          <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
              Source:
          </div>
          <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
              <Link to={@state.link}>
                      { @state.title }
              </Link>
          </div>

          <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
              Facts:
          </div>
          <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
              { just_the_facts }
          </div>

          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              Overall recommended? { recommendation }
          </div>
      </div>
