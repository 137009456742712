
module.exports = class RolesBar extends React.Component
  displayName: 'ChildSandbox'

  constructor: (props) ->
      super(props)
      @state = {
        child: @props.obj
      }

  changeID: () =>
      child = @props.obj
      child.id = Math.ceil(10000*Math.random()).toString()
      @setState({ child: child })


  render: =>

      <div>
          child.id=<button className='button' onClick={@changeID}>
              {@state.child.id}
          </button>
      </div>
