common = require('../common')
Dropzone = require('react-dropzone').default
DocumentFrame = require('./document_frame')
Loading = require('../loading')

module.exports = class SlidesButton extends React.Component
    displayName: 'SlidesButton'

    constructor: (props) ->
        super(props)
        note = @props.note
        @state = {
            note: note,
            mode: 'reading',
            files: [],
            blawbcontent: '',
            blawbid: ''
            slideset: ''
            empty_submission: false
        }

    componentDidMount: =>
        data =
            projectid: @state.note.project_id
            paperseriesid: @props.note.paperseries_id

        common.getSlideSetFromNoteInfo(data, (result) =>
            if result.slideset?
                empty_submission = false
                if result.slideset.body_blawb_id is 'empty_submission'
                    empty_submission = true
                @setState({
                    slideset: result.slideset
                    empty_submission: empty_submission })
        )

    deleteUserSlideSet: () =>
        # in backend, generate a new version of slideset without copying the slides
        @setState({blawbid: '' })
        data = {noteid: @state.note.id}

        common.updateProjectPaperSeriesSlideSet(data, (result) =>
            updated_slideset = @state.slideset
            updated_slideset.id = result.slidesetid
            updated_slideset.body_blawb_id = null
            @setState({slideset: updated_slideset})
        )

    viewUserSlideSet: () =>
        @setState({mode: 'viewing'})

    uploadUserSlideSet: () =>
        # place keeper
        @setState({mode: 'uploading'})

    onDrop: (upload_files) =>
        @setState({ files: upload_files })
        @uploadFiles(upload_files)

    uploadFiles: (files)=>
        if files.length is 0
            alert('No valid files selected.')
            @setState({ mode: 'reading' })
            return
        else
            @setState({ mode: 'loading' })

        # using noteid enables project lookup as well as paperseriesid
        noteid = @state.note.id
        common.submitPaperSeriesSlideSet(files, noteid, (rs) =>
            result = JSON.parse(rs)
            @setState({ mode: 'reading', blawbid: 'blawbid' })
        )

    gotoHideSlideSet: () =>
        @setState({ mode: 'reading' })

    viewSlideSetinNewWindow: () =>
        window.open('/project/' + @state.note.project_id + '/title/' +
                    @state.note.paperseries_id + '/slides')

    render: =>

        if @props.user_is_author is 'true'
            delete_button =
              <button className="btn btn-link btn-sm text-danger ml-1"
                  onClick={@deleteUserSlideSet}>delete slides &nbsp;</button>
            upload_button =
                <span>
                    <button className='btn btn-link btn-items mr-2'
                        onClick={@uploadUserSlideSet}>upload slides (PDF, LaTeX)</button>
                </span>
        else
            delete_button = <span></span>
            upload_button = <span></span>


        if @state.mode is 'reading'
            if ((@state.slideset.body_blawb_id) or (@state.blawbid)) and !(@state.empty_submission)
                slide_button =
                    <span>
                        <button className="btn btn-link btn-items mr-2"
                            onClick={@viewSlideSetinNewWindow}>
                            Slides
                        </button>
                        { delete_button }
                    </span>
            else
                slide_button =
                    <span>{ upload_button }</span>

        else if @state.mode is 'uploading'
            slide_button =
                <div>
                    <Dropzone className='container'
                              onDrop={@onDrop}
                              multiple={false}
                              accept="application/pdf,application/x-latex,application/x-tex,text/x-latex,text/x-tex,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,*.pdf,*.latex,*.tex,*.ppt,*.pptx">
                              {({getRootProps, getInputProps}) => (
                                  <div {...getRootProps({className: 'dropzone'})}>
                                      <input {...getInputProps()} />
                                      <p>
                                          Drag and drop PDF or LaTeX document here, or click to select a file
                                      </p>
                                  </div>
                              )}
                    </Dropzone>
                </div>
        else if @state.mode is 'viewing'
            filename = "/view_paper_slideset/" + @state.note.id
            slide_button =
                <div>
                    <button className="btn btn-link btn-sm"
                            onClick={@gotoHideSlideSet}>hide slides</button>
                    <DocumentFrame className="mini_view_document_iframe" src={filename} />
                    <button className="btn btn-link btn-sm"
                            onClick={@gotoHideSlideSet}>hide slides</button>
                </div>
        else if @state.mode is 'loading'
            slide_button =
                <Loading />

        if (@props.user_is_author is 'true') or (@state.slideset.body_blawb_id) or (@state.blawbid)
            <span className='mr-2'>
                {slide_button}
            </span>
        else
            <span></span>
