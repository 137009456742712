common = require('../common')
Loading = require('../loading')

module.exports = class SaveResourceUrls extends React.Component
  displayName: 'SaveResourceUrls'

  # This version requires url handling from a parent (materials)
  # For self-contained urls, use save_url

  constructor: (props) ->
      super(props)
      # expects
      # url_linkable_id from an object
      # obj_type (user, note, etc)
      # current_userid
      # obj_id
      # resetButtons
      # urls
      @state = {
          mode: 'open_loader'
          err_msg: ''
      }
      @captionRef = React.createRef()
      @linkRef = React.createRef()


  validURL: (link) =>

      if link.length < 5
          return false
      if link.slice(0,8) is 'https://'
          link = link.slice(8)
      if link.slice(0,7) is 'http://'
          link = link.slice(7)

      regex = /^[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/

      if not regex.test(link)
          return false
      else
          return true

  sendLink: () =>

      caption = @captionRef.current.value
      if caption.length > 140
          @setState({ err_msg: 'Max 140 characters for description.'})
          return

      link = @linkRef.current.value
      if link.length > 260
          @setState({ err_msg: 'Max 260 characters for link.'})
          return

      if not @validURL(link)
          @setState({ err_msg: 'Please enter a valid URL.'})
          return

      data =
          link: link
          ltype: @props.obj_type
          id: @props.obj_id
          caption: caption

      common.saveUrlLink(data, (result) =>
          local_link =
              id: result.url.id
              link: data.link
              caption: data.caption
          @setState({ mode: '', err_msg: '' }, @props.resetButtons({button_mode: 'show_buttons', new_url: local_link}))
      )

  render: =>


      if (@state.mode is 'open_loader')
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <div className='row no-gutters'>
                 <input className='headerInput'
                        type="text"
                        placeholder='Enter your link...'
                        ref={@linkRef}></input>
              </div>
              <div className='row no-gutters'>
                 <input className='headerInput'
                        type="text"
                        placeholder="Optional brief description"
                        ref={@captionRef}></input>
              </div>
              <button className='btn btn-primary mr-2'
                      onClick={@sendLink}>Save</button>
              <button className='btn btn-primary'
                      onClick={@props.resetButtons.bind(this,'show_buttons')}>Cancel</button>
              <span className='text-danger'> &nbsp; {@state.err_msg}</span>
          </div>
      else
          <span></span>
