
module.exports = class MakeFormattedPerms extends React.Component
  displayName: 'MakeFormattedPerms'

  constructor: (props) ->
    super(props)
    @state = {
      perms_text: ''
    }

  componentDidMount: =>
      @makeFormattedPerms(@props.wordlist)

  componentDidUpdate: (prevProps) =>
      if prevProps.wordlist != @props.wordlist
          @makeFormattedPerms(@props.wordlist)

  makeFormattedPerms: (wordlist) =>

      formatted_perms = ''

      index = wordlist.indexOf('_open_project_perm')
      if index > -1
          formatted_perms = 'open access'
      else
          formatted_perms = 'private'


      index = wordlist.indexOf('_open_submission_perm')
      if index > -1
          formatted_perms = formatted_perms + ', open submission'
      else
          formatted_perms = formatted_perms + ', submission is by invitation only'


      index = wordlist.indexOf('_following_pre_read_perm')
      if index > -1
          formatted_perms = formatted_perms + ', titles may be read before acceptance'
      else
          formatted_perms = formatted_perms + ', titles may be read after acceptance'

      @setState({ perms_text : formatted_perms})



  render: =>

      if @state
          <span className="">{ @state.perms_text }</span>
      else
          <span></span>
