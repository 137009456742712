common = require('./common')

module.exports = class Bookmark extends React.Component
  displayName: 'Bookmark'

  constructor: (props) ->
    super(props)
    @state = {
      roles: @props.roles
      paper_bookmarked: 'false'
    }

  componentDidMount: =>
      if @props.roles
        for role in @props.roles
          if (role.role == 'bookmark') and (role.jobs)
            for job in role.jobs
              if (job.obj_category == 'paper') and (job.obj_id == @props.note.paperseries_id)
                  @setState({ paper_bookmarked: 'true' })

  componentDidUpdate: (prevProps) =>
        if (@props.roles != prevProps.roles)
          for role in @props.roles
            if (role.role == 'bookmark') and (role.jobs)
              for job in role.jobs
                if (job.obj_category == 'paper') and (job.obj_id == @props.note.paperseries_id)
                    @setState({ paper_bookmarked: 'true' })

  toggleBookmark: () =>
    data =
        rolestring : 'bookmark'
        obj_category : 'paper'
        obj_id : @props.note.paperseries_id
        role_accepted : 'true'
        projectid : @props.note.project_id

    if @state.paper_bookmarked == 'true'
        @setState({ paper_bookmarked: 'false' })
        common.deleteUserJobForRole(data, (result) =>
              # no response
        )
    else
        @setState({ paper_bookmarked: 'true' })
        common.addUserJobForRole(data, (result) =>
              # no response
        )

  render: =>

      if @state.paper_bookmarked is 'false'
          bookmark_class = 'text-muted'
      else
          bookmark_class = 'text-success'

      <span>
        <button className="btn btn-light btn-txt-sm btn-box" onClick={@toggleBookmark}>
          <i className={'fa fa-bookmark ' + bookmark_class}></i>
        </button>
      </span>
