common = require('../common')
ShowDate = require('./show_date')

module.exports = class TimeRoll extends React.Component
  displayName: 'TimeRoll'

  constructor: (props) ->
      super(props)
      # expects props: clock
      @state = {

      }

  render: =>

      <button className='btn btn-primary btn-txt-sm'
              onClick={@props.onClick} >
          <ShowDate key={@props.clock.id}
                    datetime={@props.clock.debut} />
      </button>
