Link = require('react-router-dom').Link
common = require('../common')
EmailVerification = require('./email_verification')
LostPassword = require('./lost_password')

module.exports = class ManualLoginForms extends React.Component
  displayName: 'ManualLoginForms'

  constructor: (props) ->
      super(props)
      @usernameRef = React.createRef()
      @passwordRef = React.createRef()
      @passwordEyeRef = React.createRef()
      mode = ''
      if @props.mode
          mode = @props.mode
      @state = {
        userid : ''
        username : ''
        password : ''
        login_error_msg : ''
        mode : mode
        show_password_reset: 'false'
        verified: ''
      }

  manualLogin: () =>
      @setState({ mode : 'manual_login' })

  tieSocialAndLocal: () =>

      data =
        social_id: @props.social_id

      common.linkSocialAndLocalAccounts(data, (result) =>
          window.location = @props.redirect
      )

  submitCredentials: =>

      # @state.username,password could exist b/c of new account registration
      if (@state.username.length > 3) and (@state.password.length > 7)
          username = @state.username
          password = @state.password
      else
          # submitting for a pre-existing account
          username = @usernameRef.current.value.toLowerCase()
          if username.length < 4
              @setState({ login_error_msg: 'Your username is too short! At least 4 characters.'})
              return
          if username.length > 20
              @setState({ login_error_msg: 'Your username is too long! Maximum 20 characters.'})
              return
          regex_username = /^[0-9A-Za-z\-_.\x80-\x9B\x9D-\xA5\xB5-\xB7\xE0-\xED]+$/
          if not regex_username.test(username)
              @setState({ login_error_msg: 'Your username may only contain letters and numbers.'})
              return
          @setState({ username: username }) # @setState for LostPassword

          password = @passwordRef.current.value
          if password.length < 8
              @setState({ login_error_msg: 'Your password is too short! At least 8 characters. Try a unique, easy-to-remember phrase?'})
              return

      form =
        username : username
        password : password

      common.localLogin(form, (result) =>

          if (result.success is true) and (@props.mode is 'link_social_account')
              @tieSocialAndLocal()
          else if (result.success is true)
              window.location = @props.redirect
          else
              @setState({ login_error_msg : result.errors})
              if result.errors is 'Invalid password'
                  @setState({ show_password_reset: 'true' })
              if result.errors is 'Verify email'
                  @setState({ login_error_msg : 'Your conference invitations are saved using your email until you have opened an account, so we want to make sure we have the right person.'})
                  @setState({ verified: 'false', email: result.email, userid: result.userid })
      )

  validateEmailAddress: (email) =>
      re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email))

  verifiedSubmitCreds: () =>
        @submitCredentials()

  showPassword: () =>

      if @passwordRef.current.type is 'password'
          @passwordRef.current.type = 'text'
          @passwordEyeRef.current.className = 'fa fa-eye ml-3 mr-3'
      else
          @passwordRef.current.type = 'password'
          @passwordEyeRef.current.className = 'fa fa-eye-slash ml-3 mr-3'

  accountVerificationForm: () =>
      return(
          <EmailVerification verified={ @state.verified }
                             email={ @state.email }
                             userid={ @state.userid }
                             current_userid={ @state.userid }
                             type={ 'user_account' }
                             verifiedSubmitCreds={@verifiedSubmitCreds} />
      )

  loginErrorMsg: () =>
      if @state.login_error_msg is ''
          return(
              <span></span>
          )
      else
          return(
              <span className="text-danger">
                  { @state.login_error_msg }
              </span>
          )

  lostPasswordFrom: () =>
      if (@props) and (@props.redirect)
          return(
              <LostPassword username={@state.username}
                            show_password_reset={@state.show_password_reset}
                            redirect={@props.redirect} />
          )

      else
          return(
              <LostPassword username={@state.username}
                            show_password_reset={@state.show_password_reset}/>
          )

  render: =>

      <div>
        <br />
        <div>
            <div className='row no-gutters'>
               <input ref={@usernameRef}
                      className='form-control form-txt-lg'
                      type="text"
                      placeholder="* Username"
                      name="username"></input>
            </div>
            <div className="input-group pb-3">
               <input className='form-control form-txt-lg'
                      placeholder='* Password (case sensitive)'
                      type="password"
                      ref={@passwordRef}
                      name="password"
                      aria-label="password"
                      aria-describedby="basic-addon2">
               </input>
               <div className="input-group-append">
                   <button className='btn btn-outline-secondary'
                           onClick={@showPassword}>
                       <span className="fa fa-eye-slash ml-3 mr-3"
                             ref={@passwordEyeRef}></span>
                   </button>
               </div>
            </div>
            <div className='row no-gutters'>
                <button className="btn btn-secondary mr-2"
                    onClick={@submitCredentials}>Submit</button>
            </div>
            <div className='row no-gutters'>
                { @loginErrorMsg() }
            </div>
            <div>
              { @accountVerificationForm() }
            </div>
            { @lostPasswordFrom() }
        </div>
      </div>
