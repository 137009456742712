common = require('./common')

module.exports = class Motive extends React.Component
  displayName: 'Motive'

  constructor: (props) ->
      super(props)
      # view_mode can be 'preview' or 'full'
      # if it is 'preview', need 'noteid' too
      if (@props.view_mode) and (@props.view_mode is 'preview')
          view_mode = @props.view_mode
          full_motive: ''
      else if view_mode is 'full'
          view_mode = 'full'
          full_motive: @props.motive
      else
          view_mode = @props.view_mode
      @state = {
          view_mode: view_mode
          motive: @props.motive
          full_motive: ''
          preview_amount: 0.0
          Lmotive: 0
      }

  getMotive: (view_mode) =>
      data =
          noteid: @props.noteid
          motive_length: view_mode

      common.getMotive(data, (result) =>
          Lmotive = result['motive'].length
          preview_amount = @state.preview_amount
          if @state.preview_amount == 0.0
              # only need to update preview amount 1 time
              preview_amount = result['ratio_download']
          @setState({
              motive: result['motive'],
              preview_amount: preview_amount
              view_mode: view_mode
              Lmotive: Lmotive }, @applyMathJax)
          )

  applyMathJax: () =>
      if @props.editor is 'MathJax'
          window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, @domElement])

  componentDidMount: () =>
      if (@props.noteid)
          @getMotive(@state.view_mode)

  componentDidUpdate: (prevProps) =>
      if @props.noteid != prevProps.noteid
          @getMotive(@state.view_mode)

  setDOMRef: (domElement) =>
    @domElement = domElement

  previewMotive: () =>
      preview_size = 400
      preview_motive = @state.motive.substr(0, preview_size)
      Lmotive = preview_motive.length
      @setState({
          motive: preview_motive
          view_mode: 'preview'
          Lmotive: Lmotive }, @applyMathJax)

  showMoreButton: () =>
      if (@state.view_mode is 'preview') and (@state.preview_amount < 1.0)
          <button className='btn btn-link p-0 m-0 ml-3'
              onClick={@getMotive.bind(this,'full')}>
              ...more
          </button>
      else if !isNaN(@props.view_mode)
          <button className='btn btn-link p-0 m-0 ml-3'
              onClick={@getMotive.bind(this,'full')}>
              ...more
          </button>

  showLessButton: () =>
      if (@state.view_mode is 'full') and (@props.view_mode != 'full')
          <button className='btn btn-link p-0 m-0 ml-3'
              onClick={@previewMotive}>
              ...less
          </button>

  clickToShowSummary: () =>
      if @state.view_mode is 'none'
          <button className='btn btn-link p-0 m-0'
              onClick={@previewMotive}>
              Summary
          </button>

  missingMotive: () =>
      if !(@state.motive)
          <span></span>

  mathjaxMotive: () =>
      if @props.editor is 'MathJax' and @state.motive and @state.view_mode != 'none'
          <span>
              <span className='mathjax new_lines_enabled font-weight-bolder h3'
                    ref={@setDOMRef}
                    dangerouslySetInnerHTML={{__html: @state.motive.substr(0, 1) }}>
              </span>
              <span className='mathjax new_lines_enabled'
                    ref={@setDOMRef}
                    dangerouslySetInnerHTML={{__html: @state.motive.substr(1, @state.Lmotive) }}>
              </span>
              {@showMoreButton()}
              {@showLessButton()}
          </span>

  textMotive: () =>
      if @props.editor is 'SetInnerHTML' and @state.motive and @state.view_mode != 'none'
          <span>
              <span className='new_lines_enabled font-weight-bolder h3'
                    dangerouslySetInnerHTML={{__html: @state.motive.substr(0, 1) }}>
              </span>
              <span className='new_lines_enabled'
                    dangerouslySetInnerHTML={{__html: @state.motive.substr(1, @state.Lmotive) }}>
              </span>
              {@showMoreButton()}
              {@showLessButton()}
          </span>



  render: =>

      <span>
          {@missingMotive()}
          {@clickToShowSummary()}
          {@mathjaxMotive()}
          {@textMotive()}
      </span>
