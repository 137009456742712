common = require('./common')
Link = require('react-router-dom').Link
Bookmark = require('./bookmark')
NoteClickToShowDetails = require('./note_click_to_show_details')
ShowDate = require('./schedule/show_date')
ShowPlace= require('./schedule/show_place')
TagNoteRoll = require('./tags/tag_note_roll')
TitleButton = require('./titles/title_button')
VerticalVoteComponent = require('./social/vertical_vote_component')

module.exports = class ConferenceProgramNoteSummary extends React.Component
  displayName: 'ConferenceProgramNoteSummary'

  constructor: (props) ->
      super(props)
      show_motive = 'true'
      if @props.show_motive
          show_motive = @props.show_motive
      duration = (@props.note.clock.fin - @props.note.clock.debut)/60
      @state = {
        data: {noteid: @props.note.id, votevalue: @props.note.myvote}
        user_is_author: 'false'
        show_motive: show_motive
        duration: duration
      }

  componentDidMount: =>
      author_userids = []
      for author in @props.note.authorcontacts
          author_userids.push(author.user_id)

      # EDIT HERE!!! NEED TO SEND PROJECT?
      if @props.login_id and @props.login_id in author_userids
          @setState({user_is_author: 'true'})

  render: =>
      title_button =
          <Link to={'/' + @props.note.note_type + '/' + @props.note.project_id + '/title/' + @props.note.paperseries_id}>
            <TitleButton title={@props.note.title}
                         editor={@props.note.editor} />
          </Link>


      if @state.user_is_author is 'true'
          instructions_to_authors =
              <div>
                  Click title to update your submission
              </div>
      else
          instructions_to_authors =
              <span></span>

      if @props.note.acceptance_time > 0
          acceptance_trophy =
              <span>
                  <div className="btn-group-vertical">
                      <i className="fa fa-trophy text-success"></i>
                  </div>
              </span>
      else if @props.note.acceptance_time < 0
          acceptance_trophy =
              <span>
                  <div className="btn-group-vertical">
                      <i className="fa fa-times"></i>
                  </div>
              </span>
      else
          acceptance_trophy =
              <span>
                  <div className="btn-group-vertical">
                  </div>
              </span>

      sessions_list =
          <span>
              {@props.note.conference_tags.map((tag, ctr) =>
                  if ctr+1 < @props.note.conference_tags.length
                      comma=<span>,</span>
                  <TagNoteRoll key={tag.id}
                      tag={tag}
                      tag_type={'conference'}
                      note_type={@props.note.note_type}
                      projectid={ @props.note.project_id }
                      comma={comma} />
              )}
          </span>

      <div className='note_infoDiv'>

          <div className='container-fluid'>

            <div className='row no-gutters mt-3'>
                {sessions_list}
            </div>

            <div className='row no-gutters'>
                <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1 d-none d-md-block'>
                    <VerticalVoteComponent key={@props.note.id} note={@props.note}/>
                </div>
                <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                      {title_button}
                      {instructions_to_authors}
                </div>
                <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1'>
                    {acceptance_trophy}
                    <Bookmark note={@props.note} roles={@props.roles} />
                </div>
            </div>

            <div className='row no-gutters'>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 small text-success'>
                    <ShowDate
                        datetime={@props.note.clock.debut}
                        duration={@state.duration} />
                    , &nbsp;
                    <ShowPlace
                        name={@props.note.place.name}
                        lat={@props.note.place.lat}
                        lon={@props.note.place.lon} />
                </div>
            </div>

          </div>

          <NoteClickToShowDetails
              note={@props.note}
              reviewq={@props.project.reviewq}
              roles={@props.roles}
              show_motive={@state.show_motive}
              show_abstract={'preview'} />

      </div>
