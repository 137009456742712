common = require('../common')
Link = require('react-router-dom').Link
VerticalVoteComponent = require('../social/vertical_vote_component')
AuthorNameRoll = require('../authorcontact/author_name_roll')
ReviewerNameRollEditable = require('../reviewer_name_roll_editable')
TagNoteRoll = require('../tags/tag_note_roll')
TitleLinkSmall = require('../titles/title_link_small')

module.exports = class ConferenceNoteSelectBox extends React.Component
  displayName: 'ConferenceNoteSelectBox'

  constructor: (props) ->
      super(props)
      @state = {
        total_reviewers : [ ]
        note : @props.note
        reviewers : @props.reviewers
      }

  removeReviewerFromPaperSeries: (reviewer) =>
      data =
        noteid: @props.note.id
        userid: reviewer.user_id
        role: 'reviewer'

      common.removeThisJobFromConferencePaperSeries(data, (result) =>
          #empty response
          tmp_note = @state.note
          for note_reviewer in tmp_note.reviewers
              if reviewer.user_id is note_reviewer.user_id
                note_reviewer.name = 'Removed!'

          @setState({ note : tmp_note })
      )

  selectedNoteId: () =>
      @props.onClick


  render: =>
      if @props.note.current_mode is 'not selected'
        divBG = 'review_mini_note_infoDiv'
        if @props.note.reviewers.length > 0
          reviewer_roll =
              <span>
                {@props.note.reviewers.map((reviewer) =>
                        <AuthorNameRoll key={reviewer.user_id} authorcontact={reviewer} />
                    )}
              </span>
        btn_txt = 'select'
        btn_bg = 'btn btn-dark btn-sm'
      else
        divBG = 'review_mini_note_infoDiv_selected'
        btn_txt = 'save'
        btn_bg = 'btn btn-success btn-sm'

        if @state.reviewers.length is 0 and @state.note.reviewers.length > 0
            reviewer_roll =
                <span>
                  {@state.note.reviewers.map((reviewer) =>
                          <ReviewerNameRollEditable key={reviewer.user_id}
                                    reviewer={reviewer}
                                    onClick={@removeReviewerFromPaperSeries.bind(this,reviewer)} />
                      )}
                </span>
        else if @state.reviewers.length > 0
            all_reviewers = @state.note.reviewers
            for reviewer in @state.reviewers
                all_reviewers.push(reviewer)
            reviewer_roll =
                <span>
                  {all_reviewers.map((reviewer) =>
                          <ReviewerNameRollEditable key={reviewer.user_id}
                                    reviewer={reviewer}
                                    onClick={@removeReviewerFromPaperSeries.bind(this,reviewer)} />
                      )}
                </span>
        else
            reviewer_roll = <strong></strong>


      <div className={ divBG }>
            <Link to={ '/conference/' + @props.note.project_id + '/title/' + @props.note.paperseries_id }>
                <TitleLinkSmall title={@props.note.title}
                                editor={@props.note.editor} />
            </Link>
            <br />
            <strong>Tags: </strong>
            {@props.note.tags.map((tag) =>
                <TagNoteRoll key={tag.id} tag={tag} note_type='conference' />
            )}
            <br />
            <strong> Authors: </strong>
              {@props.note.authorcontacts.map((authorcontact) =>
                  <AuthorNameRoll key={authorcontact.id} authorcontact={authorcontact} />
              )}
            <br />
            <strong>Reviewers:</strong>{ reviewer_roll }
            <button className={ btn_bg }
                onClick={ @selectedNoteId() }>{ btn_txt }</button>
      </div>
