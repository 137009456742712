Link = require('react-router-dom').Link
Loading = require('../loading')
ConferenceSummaryLine = require('../conference_summary_line')
ProjectSummaryLine = require('../project_summary_line')
CreateProject = require('../create_project_form')
HeaderBar = require('../header_bar.cjsx')
Pagination = require('../pagination')
MyProfileSort = require('../sorting/myprofile_sort')
common = require('../common')

module.exports = class MyProfileLabs extends React.Component
  displayName: 'MyProfileLabs'

  constructor: (props) ->
      super(props)
      @state = {
          projects: [ ]
          ptype: ''
          ptype_word: ''

          page_num: 1
          lengthy: 2
          num_per_page: 10
          subset: 'all'

          loaded: false
      }
      @projectListRef = React.createRef()


  getTheProjects: =>
      if @props.match.path.indexOf('project') > -1
          ptype = 'personal'
      else if @props.match.path.indexOf('lab_group') > -1
          ptype = 'lab_group'
      else if @props.match.path.indexOf('conference') > -1
          ptype = 'conference'
      else if @props.match.path.indexOf('seminar') > -1
          ptype = 'seminar'
      else
          ptype = 'short_course'
      ptype_word = ptype.replace(/_/g, ' ')
      if ptype_word is 'personal'
          ptype_word = 'project'
      @setState({ptype: ptype, ptype_word: ptype_word})

      data =
          page_num: 1
          num_per_page: @state.num_per_page
          userid: @props.match.params.userid
          subset: 'all'
          ptype: ptype

      updateTotalConferences = (result) =>
          lengthy = Math.ceil(result.total/@state.num_per_page)
          @setState({ lengthy: lengthy})
      common.getTotalNumberConferencesByUserid(data, updateTotalConferences)
      updateUserConferences = (result) =>
          projects = result.results
          @setState({
            projects: projects
            loaded: true })
          document.title = 'ZeroDivZero'
      common.fetchUserConferences(data, updateUserConferences)

  componentDidMount: =>
      @getTheProjects()

  componentDidUpdate: (prevProps) =>
      if @props.match.path != prevProps.match.path
          @getTheProjects()

  getThesePages: (info) =>

      if not (info)
          info=1

      data =
          page_num: info
          num_per_page: @state.num_per_page
          userid:  @props.match.params.userid
          subset: @state.subset
          ptype: @state.ptype

      common.fetchUserConferences(data, (result) =>
          projects = result.results
          @setState({ projects: projects, page_num: info })
          elem = @projectListRef.current
          window.scrollTo(0, elem.offsetTop)
      )

      if info is 1
          common.getTotalNumberConferencesByUserid(data, (result) =>
            lengthy = Math.ceil(result.total/@state.num_per_page)
            @setState({ lengthy: lengthy})
          )

  getThisSortType: (new_subset) =>
      @setState({ subset: new_subset }, @getThesePages)

  createProjectButton: =>
      <button type="button"
              className="btn btn-outline-primary ml-3"
              data-toggle="modal"
              data-target="#createProjectModal">
        + new {@state.ptype_word}
      </button>

  projectSummaryLine: =>
    if @state.ptype is 'personal'
        <span>
            {@state.projects.map( (project) =>
              <ProjectSummaryLine key={project.id}
                  project={project}
                  userid={@props.match.params.userid} />
            )}
        </span>
    else
        <span>
            {@state.projects.map( (project) =>
                <ConferenceSummaryLine key={project.id}
                   project={project}
                   userid={@props.match.params.userid}/>
            )}
        </span>


  render: =>
    
    create_project_modal =
          <div className="modal fade"
              id="createProjectModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="CreateProject"
              aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content light_gray">
                <div className="modal-body">
                    <CreateProject ptype={@state.ptype}/>
                </div>
              </div>
            </div>
          </div>

    if not (@state.loaded)
        msg =
              <Loading />
    else if @state.projects.length == 0
        msg =
          <div className='row no-gutters m-2'>
              <br />
              None yet.
          </div>
    else
        msg = <span></span>

    sort_notes_bar =
        <div className='border pl-2 pt-0 pb-0'>
            <MyProfileSort
                subset={@state.subset}
                getThisSortType={@getThisSortType}
                ptype={@state.ptype} />
        </div>

    <div>
      <HeaderBar />
      <br />
      <div className='container-fluid'>
          <div className='row no-gutters mb-3'>
              <h2 className='ml-3 mr-3 pt-2'
                  ref={@projectListRef}>My {@state.ptype_word}s</h2>
                  { @createProjectButton() }
                  { create_project_modal }
          </div>
          { sort_notes_bar }
          { msg }
          { @projectSummaryLine() }
          <Pagination page_num={@state.page_num}
                      lengthy={@state.lengthy}
                      onClick={@getThesePages} />
      </div>
    </div>
