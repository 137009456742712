HeaderBar = require('../header_bar')
Policies = require('./policies')

module.exports = class PrivacyPolicy extends React.Component
  displayName: 'PrivacyPolicy'

  componentDidMount: () =>
      document.title = 'ZeroDivZero privacy policy'

  render: =>

      <div>
        <HeaderBar />
        <div className="text-left">
            <h1 className="text-left">Privacy Policy</h1>
              <p className="text-left">
                ZeroDivZero does not share its users' data with outside sources.
              </p>
              <p className="text-left">
                However, the information you put in your profile, the papers and projects
                you share, the conferences you participate in, etc. These can
                possibly be viewed by others, depending on your privacy settings
                and the privacy settings of the conferences and projects.
              </p>
              <p className="text-left">
                ZeroDivZero has implemented some privacy controls, including
                the ability to hide your email. As ZeroDivZero develops, it
                will add more features to control who can see your projects and
                papers.
              </p>
              <p className='text-left'>
                We use Stripe for payment, analytics, and other business services.
                Stripe collects identifying information about the devices that
                connect to its services. Stripe uses this information to operate
                and improve the services it provides to us, including for fraud
                detection. You can learn more about Stripe and read its privacy
                policy at
                <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.
              </p>
              <h2 className="text-left">Help</h2>
              <p>
                This privacy policy is a work-in-progress. Please send your
                suggestions to:help@0div0.com
              </p>
            </div>
        <Policies />
      </div>
