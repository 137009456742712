common = require('../common')

module.exports = class AuthorUserNameRoll extends React.Component
  displayName: 'AuthorUserNameRoll'

  constructor: (props) ->
      super(props)
      @state = {
        contact_string : ''
      }

  componentDidMount: =>
      assembled = @props.authorcontact.name +
                    (if not @props.authorcontact.credit_as
                        ''
                    else
                        ', ' + @props.authorcontact.credit_as) +
                    (if not @props.authorcontact.email
                        ''
                    else
                        ', ' + @props.authorcontact.email) +
                    (if not @props.authorcontact.affiliation
                        ''
                    else
                        ', ' + @props.authorcontact.affiliation) +
                    (if not @props.authorcontact.department
                        ''
                    else
                        ', ' + @props.authorcontact.department) +
                    (if not @props.authorcontact.city
                        ''
                    else
                        ', ' + @props.authorcontact.city) +
                    (if not @props.authorcontact.jurisdiction1
                        ''
                    else
                        ', ' + @props.authorcontact.jurisdiction1) +
                    (if not @props.authorcontact.jurisdiction2
                        ''
                    else
                        ', ' + @props.authorcontact.jurisdiction2) +
                    (if not @props.authorcontact.country
                        ''
                    else
                        ', ' + @props.authorcontact.country) +
                    (if not @props.authorcontact.postalcode
                        ''
                    else
                        ', ' + @props.authorcontact.postalcode)

      @setState({ contact_string: assembled })


  render: =>

      if @props.authorcontact.user_id
          <div className='authornameroll_div'>
              <button onClick={@props.onClick}
                  className='btn btn-primary btn-txt-sm'>
                  {@state.contact_string}</button>
              <br />
          </div>
      else
          <div className='authornameroll_div'>
                <button onClick={@props.onClick}
                    className='btn btn-secondary btn-txt-sm'>
                    {@state.contact_string}</button>
                <br />
          </div>
