common = require('../common')

module.exports = class ScriptPayButton extends React.Component
  displayName: 'ScriptPayButton'

  constructor: (props) ->
      super(props)
      stripe_script = 'https://checkout.stripe.com/checkout.js'
      amount = Number(@props.amount)*100
      @state = {
        amount: amount
        script_string: stripe_script
      }

  componentDidMount: =>
      @loadScript(@state.script_string)

  componentDidUpdate: (prevProps) =>
      if @props.amount != prevProps.amount
          amount = Number(@props.amount)*100
          @loadScript(@state.script_string)


  loadScript: (src) =>
      amount = @state.amount/100
      script = document.createElement('script')
      script.setAttribute('src', src)
      script.setAttribute('class','stripe-button')
      # script.setAttribute('data-key','pk_test_6ym25diTcLiurDqjvvcPc6Wf')
      script.setAttribute('data-key','pk_live_0MKH2TkpznSGnlUhorH9RcnF')
      script.setAttribute('data-amount', amount)
      script.setAttribute('data-name','0div0 Inc.')
      data_descrip = @props.title
      script.setAttribute('data-description', data_descrip)
      script.setAttribute('data-image','https://stripe.com/img/documentation/checkout/marketplace.png')
      script.setAttribute('data-locale','auto')
      script.setAttribute('data-zip-code','true')
      script.setAttribute('data-billing-address','true')
      script.type = 'text/javascript'
      document.getElementById('stripe_script').appendChild(script)
      script.src = src

  render: =>

    <span>
      <form id='stripe_form'>
          <div id='stripe_script'></div>
      </form>
    </span>
