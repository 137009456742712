Link = require('react-router-dom').Link
MakeFormattedPerms = require('../lab_group/tools/make_formatted_perms')
NameIDRoll = require('../name_id_roll')
ShowDate = require('../schedule/show_date')
SocialSharing = require('../social/social_sharing')
TagNoteRoll = require('../tags/tag_note_roll')
Title = require('../titles/title')
Writing = require('../writing')

module.exports = class LabHeader extends React.Component
  displayName: 'LabHeader'

  constructor: (props) ->
    super(props)

    @state = {
      mode: 'partial'
      sharUrl: ''
      header_title: ''
      ptype: ''
    }

  setInfo: () =>
      ptype = @props.project.ptype
      if @props.project.ptype is 'personal'
          ptype = 'project'

      shareUrl = 'https://zerodivzero.com/' + ptype + '/' + @props.project.id + '/titles'

      if @props.project.ptype is 'lab_group'
          header_title = 'Lab Group'
      else if @props.project.ptype is 'seminar'
          header_title = 'Seminar'
      else
          header_title = 'Short Course'

      @setState({
          shareUrl: shareUrl
          header_title: header_title
          ptype: ptype })


  componentDidMount: () =>
      @setInfo()

  componentDidUpdate: (prevProps) =>
      if @props.project != prevProps.project
          @setInfo()

  setViewMode: (mode) ->
      @setState({mode: mode})

  tagsListRow: () =>
      if (@props)
          if (@props.project.tags) and (@props.project.tags.length>0)
              Ltags = @props.project.tags.length
              <div className='row m-2'>
                  <strong className='pointnine_em mr-2'>Topics:</strong>
                  {@props.project.tags.map((tag, ctr) =>
                      if ctr+1 < Ltags
                          comma=<span>,</span>
                      <TagNoteRoll key={tag.id}
                                   tag={tag}
                                   projectid={@props.project.id}
                                   note_type={'lab_group' }
                                   tag_type={'lab_group'}
                                   comma={comma} />
                  )}
              </div>
          else
              <div className='row m-2'>
                  <strong className='pointnine_em mr-2'>Topics:</strong>none
              </div>

  linkToAbout: () =>
      <span className='btn-txt-sm'>
          (or visit the&nbsp;
          <Link to={'/' + @state.ptype + '/' + @props.project.id + '/about'}>
              <button className="btn btn-link mt-0 mb-0 pt-1 pb-1 mr-0 pr-1">
                  About
              </button>
          </Link><span className='ml-0'>page for more)</span>
      </span>

  titleRow: () =>
      if (@props)
          if @props.project.verified is 'true'
              verified_row =
                  <span className='text-success'>
                    &nbsp;&nbsp;
                    <i className="fa fa-check-circle"></i> verified
                  </span>
          else if @props.project.verified is 'false'
              verified_row =
                  <span className="text-danger">
                      &nbsp;&nbsp;
                      <i className="fa fa-times-circle"></i> not verified
                  </span>

          <div className='row m-1'>
              <span className='mt-2 mb-2 font-txt-lg-darkgrey'>
                  <Title title={@props.project.title}
                      editor={@props.project.editor}/>
                  <SocialSharing shareUrl={@state.shareUrl}
                      title={ @props.project.title }/>
                  <span className='pointsixfive_em'>{verified_row}</span>
              </span>
          </div>

  bigPicture: () =>
      if @props
          <div className='row m-2'>
            <strong className='pointnine_em mr-2'>Short description:</strong>
            <div className='proj_motive_CKEDitor'>
                <Writing key='big_picture'
                    editor={@props.project.editor}
                    writing={@props.project.motive} />
            </div>
          </div>

  createdByRow: () =>
      if (@props.project.chairs) and (@props.project.chairs.length > 0)
          Lchairs = @props.project.chairs.length
          chairs_info =
              <span>
                  {@props.project.chairs.map((chair, ctr) =>
                      if ctr+1 < Lchairs
                          comma=<span>,</span>
                      <NameIDRoll key={chair.user_id}
                          name={chair.user_name}
                          id={chair.user_id}
                          comma={comma} />
                  ,this)}
              </span>
      else
          chairs_info = <span className='ml-1'>none</span>

      if @props
          <div className='row m-1'>
              <strong className='pointnine_em mr-2'>Created by:</strong>
              <Link to={'/profile/' + @props.project.user_id}>
                  {@props.project.user_name}
              </Link>
              &nbsp;&nbsp;
              <strong className='pointnine_em mr-2'>Organizer(s):</strong>
              {chairs_info}
          </div>

  permsRow: () =>
      <div className='row m-2'>
        <strong className='pointnine_em mr-2'>Permissions:</strong>
        <div className="">
            <MakeFormattedPerms wordlist={@props.wordlist} />
        </div>
      </div>


  render: =>

    if (@state.mode is 'full')
        <div className='container-fluid light_gray'>
            <br />
            <strong>{@state.header_title}</strong>
            { @titleRow() }
            { @createdByRow() }
            { @tagsListRow()  }
            { @bigPicture() }
            { @permsRow() }
            <button className="btn btn-link btn-txt-sm"
                onClick={@setViewMode.bind(this, 'partial')}>
                minimize
            </button>
            { @linkToAbout() }
            <br />
            <br />
        </div>
    else if @state.mode is 'partial'
        <div className='container-fluid light_gray'>
            <br />
            <strong>{@state.header_title}</strong>
            { @titleRow() }
            { @createdByRow() }
            { @tagsListRow() }
            { @bigPicture() }
            <button className="btn btn-link ml-2"
                onClick={@setViewMode.bind(this, 'full')}>
                more details...
            </button>
            <br />
            <br />
        </div>
    else
        <span></span>
