# Load css first thing. It gets injected in the <head> in a <style> element by
# the Webpack style-loader.
# require '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
require '../../public/main.css'
# require '../../assets/twitter_mods.css'
require '../../assets/customizations.css'

React = require 'react'
ReactDOM = require 'react-dom'
# {ReactRouter, Router, Route, Switch} = require 'react-router'
{BrowserRouter, Route, Link, Switch} = require 'react-router-dom';
{Helmet} = require 'react-helmet';

# Assign React to Window so the Chrome React Dev Tools will work.
window.React = React

# Require route components.
App = require './app'
CommandCentralFounder = require './command_central/founder'
CommunityGuidelines = require './site_info/community_guidelines'
ConferenceAbout = require './conference_about'
ConferenceTools = require './conference_tools/toolchest'
ConferenceNote = require './conference_note'
ConferenceNoteDocument = require './uploads/conference_note_document'
ConferenceNotePoster = require './uploads/conference_note_poster'
ConferenceNotes = require './conference_notes'
ConferenceNotesAreaChairPanel = require './conference_tools/conference_notes_areachair_panel'
ConferenceNotesChairPanel = require './conference_tools/conference_notes_chair_panel'
ConferenceNotesReviewerPanel = require './conference_tools/conference_notes_reviewer_panel'
ConferenceNoteSlideSet = require './uploads/conference_note_slideset'
ConferenceProgram = require './schedule/conference_program'
ConferenceRegister = require './registration/conference_register'
ConferenceIntegrations = require './conference_tools/integrations'
# ConferenceMySubmissions = requrie './conference_my_submissions'
# ConferenceMyBookmarks = require './conference_my_bookmarks'
CookiePolicy = require './site_info/cookie_policy'
GoogleCallback = require './account/google_callback'
FrontPage = require './front_page/front_page'
Help = require './help'
LabGroupAbout = require './lab_group/about'
LabGroupTitle = require './lab_group/title'
LabGroupTitles = require './lab_group/titles'
LabGroupTools = require './lab_group/tools/toolchest'
LoginRedirect = require './account/login_redirect'
More = require './front_page/more'
NotFound = require './not_found'
Notifications = require './notifications'
OurStory = require('./front_page/our_story')
PrivacyPolicy = require './site_info/privacy_policy'
Profile = require './profile/profile'
MyProfileProjects = require './profile/my_profile_projects'
ProjectAbout = require './project_about'
ProjectCode = require './project_code'
ProjectData = require './project_data'
ProjectDesign = require './project_design'
ProjectFigures = require './project_figures'
ProjectNote = require './project_note'
ProjectNoteDocument = require './uploads/project_note_document'
ProjectNotePoster = require './uploads/project_note_poster'
ProjectNotes = require './project_notes'
ProjectNoteSlideSet = require './uploads/project_note_slideset'
Sandbox = require './command_central/sandbox'
ShortCourseTitle = require './lab_group/title'
ShortCourseTitles = require './short_course/titles'
TagSearchResults = require './tags/tag_search_results'
TagSearchConferenceResults = require './tags/tag_search_conference_results'
TagSearchLabGroupResults = require './tags/tag_search_lab_group_results'
TagSearchProjectResults = require './tags/tag_search_project_results'
TagSearchResults = require './tags/tag_search_results'
TermsOfUse = require './site_info/terms_of_use'
UrlResourceDocument = require './uploads/url_resource_document'
Unsubscribe = require './account/unsubscribe'
VttViewer = require './video/vtt_viewer'
# Just storing these here in case I decide to use them.
# <Route exact name="myconferencesubmissions" component={MyConferenceSubmissions} path="/conference/:projectId/my_submissions/:nickname" />
# <Route exact name="myconferencebookmarks" component={MyConferenceBookmarks} path="/conference/:projectId/my_bookmarks/:nickname" />



router = (
    <BrowserRouter>
      <Switch>
        <Route exact name="google_callback" component={GoogleCallback} path="/google_callback" />
        <Route exact name="loginredirect" component={LoginRedirect} path="/login" />
        <Route exact name="front_page" component={FrontPage} path="/" />
        <Route exact name="help" component={Help} path="/help" />
        <Route exact name="more" component={More} path="/more" />
        <Route exact name="ourstory" component={OurStory} path="/our_story" />
        <Route exact name="cookie_policy" component={CookiePolicy} path="/cookie_policy" />
        <Route exact name="terms_of_use" component={TermsOfUse} path="/terms_of_use" />
        <Route exact name="community_guidelines" component={CommunityGuidelines} path="/community_guidelines" />
        <Route exact name="privacy_policy" component={PrivacyPolicy} path="/privacy_policy" />
        <Route exact name="unsubscribe" component={Unsubscribe} path="/unsubscribe/:userEmail/:challenge" />

        <Route exact name="profile" component={Profile} path="/profile/:userid" />
        <Route exact name="myprofileprojects" component={MyProfileProjects} path="/profile/:userid/projects" />
        <Route exact name="myprofileconferences" component={MyProfileProjects} path="/profile/:userid/conferences" />
        <Route exact name="myprofilelabs" component={MyProfileProjects} path="/profile/:userid/lab_groups" />
        <Route exact name="myprofileseminars" component={MyProfileProjects} path="/profile/:userid/seminars" />
        <Route exact name="myprofileshortcourses" component={MyProfileProjects} path="/profile/:userid/short_courses" />

        <Route exact name="projectnotes" component={ProjectNotes} path="/project/:projectId" />
        <Route exact name="projectnotes" component={ProjectNotes} path="/project/:projectId/titles" />
        <Route exact name="projectnote" component={ProjectNote} path="/project/:projectId/title/:paperseriesId" />
        <Route exact name="projectfigures" component={ProjectFigures} path="/project/:projectId/figures" />
        <Route exact name="projectdesign" component={ProjectDesign} path="/project/:projectId/design" />
        <Route exact name="projectdata" component={ProjectData} path="/project/:projectId/data" />
        <Route exact name="projectcode" component={ProjectCode} path="/project/:projectId/code" />
        <Route exact name="projectabout" component={ProjectAbout} path="/project/:projectId/about" />
        <Route exact name='projectnotedocument' component={ProjectNoteDocument} path="/project/:projectId/title/:paperseriesId/document" />
        <Route exact name='projectnoteslideset' component={ProjectNoteSlideSet} path="/project/:projectId/title/:paperseriesId/slides" />
        <Route exact name='projectnoteposter' component={ProjectNotePoster} path="/project/:projectId/title/:paperseriesId/poster" />

        <Route exact name="conferencenotes" component={ConferenceNotes} path="/conference/:projectId" />
        <Route exact name="conferencenotes" component={ConferenceNotes} path="/conference/:projectId/titles" />
        <Route exact name='conferencenotedocument' component={ConferenceNoteDocument} path="/conference/:projectId/title/:paperseriesId/document" />
        <Route exact name="conferenceprogram" component={ConferenceProgram} path="/conference/:projectId/program" />
        <Route exact name="conferencenote" component={ConferenceNote} path="/conference/:projectId/title/:paperseriesId" />
        <Route exact name="conferencetools" component={ConferenceTools} path="/conference/:projectId/tools" />
        <Route exact name="conferenceabout" component={ConferenceAbout} path="/conference/:projectId/about" />
        <Route exact name="conferenceregister" component={ConferenceRegister} path="/conference/:projectId/register" />
        <Route exact name="conferencenoteschairpanel" component={ConferenceNotesChairPanel} path="/conference/:projectId/titles/chair_panel" />
        <Route exact name="conferencenotesareachairpanel" component={ConferenceIntegrations} path="/conference/:projectId/tools/integrations" />
        <Route exact name="conferenceintegrations" component={ConferenceNotesAreaChairPanel} path="/conference/:projectId/titles/areachair_panel" />
        <Route exact name="conferencenotesreviewerpanel" component={ConferenceNotesReviewerPanel} path="/conference/:projectId/titles/reviewer_panel" />
        <Route exact name='conferencenoteslideset' component={ConferenceNoteSlideSet} path="/conference/:projectId/title/:paperseriesId/slides" />
        <Route exact name='conferencenoteposter' component={ConferenceNotePoster} path="/conference/:projectId/title/:paperseriesId/poster" />

        <Route exact name='labgrouptitles' component={LabGroupTitles} path="/lab_group/:projectId" />
        <Route exact name='labgrouptitles' component={LabGroupTitles} path="/lab_group/:projectId/titles" />
        <Route exact name='labgroupabout' component={LabGroupAbout} path="/lab_group/:projectId/about" />
        <Route exact name='labgrouptools' component={LabGroupTools} path="/lab_group/:projectId/tools" />
        <Route exact name='labgrouptitle' component={LabGroupTitle} path="/lab_group/:projectId/title/:paperseriesId" />

        <Route exact name='seminartitles' component={LabGroupTitles} path="/seminar/:projectId" />
        <Route exact name='seminartitles' component={LabGroupTitles} path="/seminar/:projectId/titles" />
        <Route exact name='seminarprogram' component={ConferenceProgram} path="/seminar/:projectId/program" />
        <Route exact name='seminarabout' component={LabGroupAbout} path="/seminar/:projectId/about" />
        <Route exact name='seminartools' component={LabGroupTools} path="/seminar/:projectId/tools" />
        <Route exact name='seminartitle' component={LabGroupTitle} path="/seminar/:projectId/title/:paperseriesId" />
        <Route exact name='seminardocument' component={ConferenceNoteDocument} path="/seminar/:projectId/title/:paperseriesId/document" />
        <Route exact name='seminarslideset' component={ConferenceNoteSlideSet} path="/seminar/:projectId/title/:paperseriesId/slides" />
        <Route exact name='seminarposter' component={ConferenceNotePoster} path="/seminar/:projectId/title/:paperseriesId/poster" />
        <Route exact name="seminarregister" component={ConferenceRegister} path="/seminar/:projectId/register" />

        <Route exact name='shortcoursetitles' component={ShortCourseTitles} path="/short_course/:projectId" />
        <Route exact name='shortcoursetitles' component={ShortCourseTitles} path="/short_course/:projectId/titles" />
        <Route exact name='shortcourseprogram' component={ConferenceProgram} path="/short_course/:projectId/program" />
        <Route exact name='shortcourseabout' component={LabGroupAbout} path="/short_course/:projectId/about" />
        <Route exact name='shortcoursetools' component={LabGroupTools} path="/short_course/:projectId/tools" />
        <Route exact name='shortcoursetitle' component={ShortCourseTitle} path="/short_course/:projectId/title/:paperseriesId" />
        <Route exact name='urlresourcedocument' component={UrlResourceDocument} path="/short_course/:projectId/title/:paperseriesId/resource/:urlId" />
        <Route exact name='shortcourseslideset' component={ConferenceNoteSlideSet} path="/short_course/:projectId/title/:paperseriesId/slides" />
        <Route exact name='shortcourseposter' component={ConferenceNotePoster} path="/short_course/:projectId/title/:paperseriesId/poster" />
        <Route exact name="shortcourseregister" component={ConferenceRegister} path="/short_course/:projectId/register" />

        <Route exact name="notifications" component={Notifications} path="/notifications/:userId" />

        <Route exact name='tagsearchconferenceresults' component={TagSearchConferenceResults} path="/search/conference/:projectId/tags/:tagWordList" />
        <Route exact name='tagsearchprojectresults' component={TagSearchProjectResults} path="/search/project/:projectId/tags/:tagWordList" />
        <Route exact name='tagsearchlabgroupresults' component={TagSearchLabGroupResults} path="/search/lab_group/:projectId/tags/:tagWordList" />
        <Route exact name='tagsearchresults' component={TagSearchResults} path="/search/tags/:tagWordList" />

        <Route exact name='vttviewer' component={VttViewer} path="/project/:projectId/title/:objId/video/:videoId/vtt/:vttId" />

        <Route exact name='commandcentralfounder' component={CommandCentralFounder} path="/command_central/founder" />
        <Route exact name='sandbox' component={Sandbox} path="/command_central/founder/sandbox" />
        <Route name='notfound' component={NotFound} path="*" />
      </Switch>
    </BrowserRouter>
)

ReactDOM.render(router, document.getElementById 'app')
