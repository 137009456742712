common = require('../common')
SandboxChild = require('./sandbox_child')

module.exports = class RolesBar extends React.Component
  displayName: 'Sandbox'

  constructor: (props) ->
      super(props)
      id = 'my_original_ID'
      @state = {
          data: { logged_in_user: 'anonymous', verified: 'false', email: '' }
          userid : ''
          show_command_central: false
          obj: { id: 'obj_id', another_thing: 'hi!'}
      }

  componentDidMount: =>

      document.title = 'Sandbox'

      founder_userids = [
          '140a6a836f8c4a6a8cbf2868799d6903',
          'e95df917ad3846419789efd5d7caeca5'
      ]

      common.loginMonitor (data) =>
          current_user = data['current_user']
          whos_logged_in = 'anonymous'
          whos_logged_in = current_user if current_user
          verified = data['verified']
          email = data['email']
          @setState({
              data: { logged_in_user: whos_logged_in, verified: verified, email: email }
              userid: data['userid']
          })

          if data['userid'] in founder_userids
              @setState({ show_command_central: true })
          else
              window.location = '/'

  render: =>

      <div className='container p-3 m-3'>
          <p>Here is an obj.id: <strong>{@state.obj.id}</strong></p>
          <p>Send the obj to this child component and then set something else to inherit its value.</p>
          <p>Then update that value locally.</p>
          <p>Does that affect the original ID value?</p>
          <p><SandboxChild obj={@state.obj} /></p>
      </div>
