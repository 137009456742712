common = require('../common')
Helmet = require('react-helmet').Helmet
ConferenceDirectUpload = require('../conference_direct_upload')
ConferenceNoteSummary = require('../conference_note_summary')
ConferenceSortTitlesNav = require('../sorting/conference_sort_titles_nav')
CreateNoteMetadata = require('../create_title/create_note_metadata')
Header = require('./header')
HeaderBar = require('../header_bar')
Loading = require('../loading')
NavGeneral = require('../nav_general')
Pagination = require('../pagination')
RolesBarLab = require('../roles/roles_bar_lab')

module.exports = class LabGroupTitles extends React.Component
  displayName: 'LabGroupTitles'

  constructor: (props) ->
      super(props)
      @makeNewTitleRef = React.createRef()
      @notesMapRef = React.createRef()

      projectid = @props.match.params.projectId
      @state = {
          projectid: projectid
          project: { }
          notes: []
          mode: 'loading'
          note: {title: '', abstract: '', motive: ''}
          userid: ''
          roles: []
          rolestring: ''
          my_accepted_roles: []
          user_can_schedule: 'false'
          notes_length: 0
          wordlist: []

          page_num: 1
          lengthy: 2
          num_per_page: 10

          subset: 'all'
      }

  organizeNotes: () =>

      sort_by = 'latest'
      notelist = @state.notes
      for note in notelist
          if note.clock
            note.datetime = note.clock.debut
          else
            note.datetime = 0

      if sort_by is 'latest'
          notelist = notelist.sort((a,b)=>-a.datetime+b.datetime)

      @setState({ notes: notelist })

  componentDidMount: =>

      data =
          page_num: 1
          num_per_page: @state.num_per_page
          projectid: @state.projectid
          subset: 'all'

      common.retrieveSubsetConferenceNotes(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({ mode : 'invalid_conference' })
          else if result['error'] is 'conference deleted'
              @setState({ mode : 'conference_deleted' })
          else
              my_accepted_roles = [ ]
              for role in result['roles']
                  if role.role_accepted is 'true'
                      my_accepted_roles.push(role.role)

              # prevent loading this as a project or conference page
              project = result['project']
              # if (project.ptype != 'lab_group') or (project.ptype != 'seminar')
              #     if project.ptype is 'personal'
              #         project.ptype = 'project'
              #     window.location = '/' + project.ptype + '/' + project.id + '/titles'

              user_can_schedule = 'false'
              if (result['login_id']is project.user_id)
                  user_can_schedule = 'true'

              loaded_wordlist = []
              if project.perms
                for perm in project.perms
                    loaded_wordlist.push(perm.word)

              page_link = 'https://zerodivzero.com/' + project.ptype + '/' + project.id + '/titles'

              @setState({
                  project: project,
                  notes: result['notes'],
                  userid: result['login_id'],
                  roles: result['roles'],
                  my_accepted_roles: my_accepted_roles,
                  wordlist: loaded_wordlist,
                  user_can_schedule: user_can_schedule,
                  mode: 'viewing',
                  page_link: page_link
              }, @organizeNotes)

      )

      common.getTotalNumberNotesForConferenceSubset(data, (result) =>
        lengthy = Math.ceil(result.total/@state.num_per_page)
        @setState({lengthy: lengthy, notes_length: result.total})
      )

  componentDidUpdate: () =>
      if @props.match.params.projectId != @state.projectid
          window.location.reload()

  setUpdatedRoles: (what_to_update) =>
      my_accepted_roles = []
      for role in what_to_update
          if role.role_accepted is 'true'
              my_accepted_roles.push(role.role)
      @setState({
          roles: what_to_update,
          my_accepted_roles: my_accepted_roles })

  scrollToHere: (ref) =>
      elem = ref.current
      if (elem)
        window.scrollTo(0, elem.offsetTop)

  getUserNoteDetails: =>
      @setState({
          mode : 'create_note_metadata'}, @scrollToHere(@makeNewTitleRef) )

  setMode: (mode_option) =>
      if mode_option is 'viewing'
          window.location.reload()
      else if mode_option is 'cancelled'
          @setState({ mode: 'viewing' })
      else if mode_option.new_note
          @setState({
              new_note: mode_option.new_note
              mode: 'direct_upload_submisson' })
      else
          @setState({ mode: mode_option })

  getThisSortType: (new_subset) =>
      @setState({subset: new_subset}, @getThesePages)

  getThesePages: (info) =>
      if not (info)
          info=1

      if not (@state.subset is 'all') and not (@state.userid)
          @setState({notes: []})
          return

      data =
          page_num: info
          num_per_page: @state.num_per_page
          projectid: @props.match.params.projectId
          subset: @state.subset

      common.retrieveSubsetConferenceNotes(data, (result) =>
          if result['error'] is 'conference not found'
              @setState({mode : 'invalid project'})
          else if result['error'] is 'conference deleted'
              @setState({mode : 'conference_deleted'})
          else
            @setState({notes: result['notes'], page_num: info})

          elem = @notesMapRef.current
          if (elem)
            window.scrollTo(0, elem.offsetTop)
      )

  createSubmission: () =>
      if @state.mode is 'create_note_metadata'
          <div ref={@makeNewTitleRef}>
              <CreateNoteMetadata project={@state.project}
                  setMode={@setMode}
                  version={'new'}
                  user_can_schedule={@state.user_can_schedule} />
          </div>
      else if @state.mode is 'direct_upload_submisson'
          <div>
              Just a few more choices to finish submission.
              <ConferenceDirectUpload
                  note={@state.new_note}
                  conference={@state.project} />
          </div>

  paperList: () =>
      if @state.mode is 'loading'
          <div>
              <div className='proj_listing'>
                <div className='note_noPapers'>
                  <br />
                  <Loading />
                  <br />
                  <br />
                </div>
              </div>
          </div>
      else if @state.mode is 'viewing'
          if @state.notes.length>0
              <div>
                  <span ref={@notesMapRef}>
                      {@state.notes.map((paper) =>
                          <ConferenceNoteSummary key={paper.id}
                               note={paper}
                               project={@state.project}
                               roles={@state.roles}
                               login_id={@state.userid}/>
                      ,this)}
                  </span>
                  <Pagination page_num={@state.page_num}
                      lengthy={@state.lengthy}
                      onClick={@getThesePages} />
              </div>
          else
              <div>
                  <div className='proj_listing'>
                    <div className='note_noPapers'>
                      <br />
                      <i>Nothing here.... yet....</i>
                      <br />
                      <br />
                    </div>
                  </div>
              </div>

  problemMsg: () =>
      if @state.mode is 'invalid_conference'
          <div>
              <br />
              <h2>We cannot find anything... Try refreshing!</h2>
              If you continue to get this error, then maybe the url is wrong?
          </div>
      else if @state.mode is 'conference_deleted'
          <div>
              <br />
              <h2>Uh oh... It appears the owner has deleted this.</h2>
          </div>

  theHeader: () =>
      if (@state.mode != 'invalid_conference') and (@state.mode != 'conference_deleted')
          <span>
              <Header key={@state.project.id}
                      project={@state.project}
                      userid={@state.userid}
                      roles={@state.roles}
                      wordlist={@state.wordlist} />
              <div className='proj_actions_Div'>
                  <span className="ml-0">
                    {@state.notes_length} titles submitted!
                  </span>
                  <RolesBarLab project={@state.project}
                            my_accepted_roles={@state.my_accepted_roles}
                            roles={@state.roles}
                            userid={@state.userid}
                            view={'full'}
                            setUpdatedRoles={@setUpdatedRoles}
                            getUserNoteDetails={@getUserNoteDetails} />
              </div>
          </span>

  helmetInfo: () =>
      <Helmet>
          <title>{@state.project.title}</title>
          <meta name="og:title" content={@state.project.title} />
          <meta name="og:url" content={@state.page_link} />
          <meta name="og:type" content="article:science" />
          <meta name="og:image" content='../../images/other_logos/wins_250_248.png' />
          <meta name="og:description" content={@state.project.motive} />
          <meta name="fb:app_id" content='695761137112146' />
      </Helmet>


  render: =>
      conf_nav_vert =
          <div className='col-md-2 col-lg-2 border d-none d-md-block'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  ptype={@state.project.ptype}
                  caller={'titles'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'col-12'} />
          </div>
      conf_nav_horiz =
          <div className='col-xs-12 col-sm-12 border d-block d-md-none'>
              <NavGeneral key={@state.project.id}
                  id={@state.project.id}
                  project={@state.project}
                  ptype={@state.project.ptype}
                  caller={'titles'}
                  subset={@state.subset}
                  getThisSortType={@getThisSortType}
                  userid={@state.userid}
                  styling={'mr-2'} />
          </div>

      <div>
          { @helmetInfo() }
          <HeaderBar />
          <div className='container-fluid pr-0 pl-0 border'>
              { @theHeader() }
              <div className='row no-gutters'>
                  {conf_nav_vert}
                  {conf_nav_horiz}
                  <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                      { @problemMsg() }
                      { @paperList() }
                      { @createSubmission() }
                  </div>
              </div>
          </div>
      </div>
