request = require 'superagent'

common =
    host: '/api'

    manualLogin: (path, form, callback) ->
        request
            .post(@host + path)
            .send(form)
            .withCredentials()
            .end (err,res) ->
                callback(res.body)
    localSignUp: (path, form, callback) ->
        request
            .post(@host + path)
            .send(form)
            .withCredentials()
            .end (err,res) ->
                callback(res.body)
    logout: (path, callback) ->
        request
            .get(@host + path)
            .withCredentials()
            .end (err,res) ->
                callback(res.body)
    pay: (path, data, callback) ->
        request
            .post(@host + path)
            .withCredentials()
            .send(data)
            .end (err, res) ->
                if err || !res.ok
                    console.error err
                msg = res.body
                if msg != null
                  if msg['error'] is 'redirect_auth_failure'
                        window.location = '/login/?' + window.location.href
                  else
                        callback(res.body)
    fetchPage: (path, callback) ->
        request
            .get(@host + path)
            .withCredentials()
            .end (err, res) ->
                if err || !res.ok
                    console.error err
                if res.body?
                    msg = res.body
                    if msg['error'] is 'redirect_auth_failure'
                        window.location = '/login/?' + window.location.href
                    else
                        callback(res.body)
                else
                    callback(res)
    post: (path, data, callback) ->
        request
            .post(@host + path)
            .withCredentials()
            .send(data)
            .end (err, res) ->
                if err || !res.ok
                    console.error err
                msg = res.body
                if msg != null
                    if msg['error'] is 'redirect_auth_failure'
                        window.location = '/login/?' + window.location.href
                    else
                        callback(res.body)
    readDocument: (path, callback) ->
        request
            .get(@host + path)
            .withCredentials()
            .accept("application/pdf,text/plain,text/x-latex,text/x-tex,text/vtt,application/x-ipynb+json,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation")
            .responseType("blob")
            .end (err, res) ->
                if err || !res.ok
                    console.error err
                if res.body?
                    msg = res.body
                    if msg['error'] is 'redirect_auth_failure'
                        window.location = '/login/?' + window.location.href
                callback(res)
    uploadFile: (path, files, callback) ->
        formdata = new FormData()
        for key of files
            if files.hasOwnProperty(key) and files[key] instanceof File
                formdata.append key, files[key]
        request
            .post(@host + path)
            .withCredentials()
            .send(formdata)
            .end (err, res) ->
                if res?
                    if res.text != null
                        callback(res.text)
    downloadFile: (path, data, callback) ->
        request
            .post(@host + path)
            .withCredentials()
            .send(data)
            .end (err, res) ->
                #console.log("err=")
                #console.log(err)
                #console.log('res=')
                #console.log(res)
                if err || !res.ok
                    console.error err
                msg = res.text
                if msg != null
                    if msg['error'] is 'redirect_auth_failure'
                        window.location = '/login/?' + window.location.href
                  else
                        callback(res.text)

    # login
    loginMonitor: (callback) ->
        @fetchPage('/login_monitor', callback)
    submitUserInfo: (user, callback) ->
        @post('/submit_user_info', user, callback)
    oauth: (callback, provider) ->
        console.log("!!!!and provider=")
        console.log(provider)
        provider = 'facebook'
        @post('/authorize/'+provider, callback)
    oauthCallback: (callback, provider) ->
        console.log("!!!!oauth callback provider=")
        console.log(provider)
        @fetchPage('/callback/'+provider, callback)
    localLogin: (form, callback) ->
        @manualLogin('/local_login', form, callback)
    localAccountRegistration: (form, callback) ->
        @localSignUp('/local_account_registration', form, callback)
    logoutReset: (callback) ->
        @logout('/logout_reset', callback)
    authorizeGoogleUser: (callback, data) ->
        @post('/authorize_google_user', callback, data)
    testGoogleLogin: (callback, data) ->
        @post('/test_google_login', callback, data)
    googleOauth2callback: (callback, data) ->
        @post('/google_oauth2_callback', callback, data)
    createLocalAccountAfterSocialReg: (callback, data) ->
        @post('/create_local_account_after_social_reg', callback, data)
    linkSocialAndLocalAccounts: (callback, data) ->
        @post('/link_social_and_local_accounts', callback, data)

    # project pages
    fetchUserProjects: (callback, data) ->
        @post('/fetch_user_projects', callback, data)
    getTotalNumberProjectsByUserid: (callback, data) ->
        @post('/get_total_number_projects_by_userid', callback, data)
    fetchProject: (callback, projectid) ->
        @fetchPage('/fetch_project/'+projectid, callback)
    submitProjectFields: (callback, data) ->
        @post('/submit_project_fields', callback, data)
    submitProjectAbout: (callback, data) ->
        @post('/submit_project_about', callback, data)
    projectFigures: (callback, projectid) ->
        @fetchPage('/project_figures/'+projectid, callback)
    projectNotes: (callback, data) ->
        @post('/project_notes', callback, data)
    projectDesign: (callback, projectid) ->
        @fetchPage('/project_design/'+projectid, callback)
    projectData: (callback, projectid) ->
        @fetchPage('/project_data/'+projectid, callback)
    projectCode: (callback, projectid) ->
        @fetchPage('/project_code/'+projectid, callback)
    # createAutoProject: (callback) ->
    #     @fetchPage('/create_auto_project', callback)
    # createProjectForm: (title, callback) ->
    #     @post('/create_project_form/', title, callback)
    deleteProject: (callback, data) ->
        @post('/delete_project', callback, data)
    getFrontPageProjectsByPtype: (callback, data) ->
        @post('/get_frontpage_projects_by_ptype', callback, data)
    getFrontPageProjectNewUpdatesCount: (callback, data) ->
        @post('/get_frontpage_project_new_updates_count', callback, data)
    updateNoteOrderingForProject: (callback, data) ->
        @post('/update_note_ordering_for_project', callback, data)
    getMotive: (callback, data) ->
        @post('/get_motive', callback, data)

    # conference pages
    createProjectForm: (title, callback) ->
        @post('/create_project_form/', title, callback)
    fetchUserConferences: (callback, data) ->
        @post('/fetch_user_conferences', callback, data)
    getTotalNumberConferencesByUserid: (callback, data) ->
        @post('/get_total_number_conferences_by_userid', callback, data)
    getTotalNumberNotesForConferenceSubset: (callback, data) ->
        @post('/get_total_number_notes_for_conference_subset', callback, data)
    submitUserTitleToConference: (callback, data) ->
        @post('/submit_user_title_to_conference', callback, data)
    conferenceNote: (callback, data) ->
        @post('/conference_note', callback, data)
    getConferenceAndRolesForUser: (callback, data) ->
        @post('/get_conference_and_roles_for_user', callback, data)
    sendAndSaveConferenceEmailHandInvites: (callback, data) ->
        @post('/send_and_save_conference_email_hand_invites',callback, data)
    sendAndSaveConferenceEmailFileInvites: (files, projectid, roletype, callback) ->
        @uploadFile('/send_and_save_conference_email_file_invites/'+projectid+'/'+roletype, files, callback)
    uploadExternalTitlesCSVForComparison: (files, projectid, roletype, callback) ->
        @uploadFile('/upload_external_titles_csv_for_comparison/'+projectid+'/'+roletype, files, callback)
    getMatchingProjectTitlesForChairs: (callback, data) ->
        @post('/get_matching_project_titles_for_chairs',callback, data)
    conferenceRetrieveAllRoles: (callback, data) ->
        @post('/conference_retrieve_all_roles', callback, data)
    userUpdatesConferenceRole: (callback, data) ->
        @post('/user_updates_conference_role', callback, data)
    conferenceGetRoleJunctions: (callback, data) ->
        @post('/conference_get_reviewers', callback, data)
    assignReviewersToConferencePaperSeries: (callback, data) ->
        @post('/assign_reviewers_to_conference_paperseries', callback, data)
    assignAreaChairsToConferencePaperSeries: (callback, data) ->
        @post('/assign_areachairs_to_conference_paperseries', callback, data)
    autoAssignReviewersToUnassignedConferencePaperSeries: (callback, data) ->
        @post('/auto_assign_reviewers_to_unassigned_conference_paperseries', callback, data)
    submitProjectReviewQs: (callback, data) ->
        @post('/submit_project_reviewQs', callback, data)
    assignReviewersToAreaChair: (callback, data) ->
        @post('/assign_reviewers_to_areachair', callback, data)
    autoAssignReviewersToAreaChairs: (callback, data) ->
        @post('/auto_assign_reviewers_to_areachairs', callback, data)
    assignConferencePaperSeriesToAreaChair: (callback, data) ->
        @post('/assign_conference_paperseries_to_areachair', callback, data)
    autoAssignConferencePaperSeriesToAreaChairs: (callback, data) ->
        @post('/auto_assign_conference_paperseries_to_areachairs', callback, data)
    getConferenceHelmReviews: (callback, data) ->
        @post('/get_conference_helm_reviews', callback, data)
    getTheseNotesForChairs: (callback, data) ->
        @post('/get_these_notes_for_chairs', callback, data)
    getNoteComments: (callback, data) ->
        @post('/get_note_comments', callback, data)
    updateConferenceNoteAcceptanceTime: (callback, data) ->
        @post('/update_conference_note_acceptance_time', callback, data)
    removeThisJobFromConferencePaperSeries: (callback, data) ->
        @post('/remove_thisjob_from_conference_paperseries', callback, data)
    removeAreaChairFromConferencePaperSeries: (callback, data) ->
        @post('/remove_areachair_from_conference_paperseries', callback, data)
    getNoteOrigin: (callback, data) ->
        @post('/get_note_origin', callback, data)
    getNoteOriginDetails: (callback, data) ->
        @post('/get_note_origin_details', callback, data)
    retrieveSubsetConferenceNotes: (callback, data) ->
        @post('/retrieve_subset_conference_notes', callback, data)
    saveConferenceSubmissionDate: (callback, data) ->
        @post('/save_conference_submission_date', callback, data)
    saveConferenceDateType: (callback, data) ->
        @post('/save_conference_date_type', callback, data)
    getUserNameTag: (callback, data) ->
        @post('/get_user_nametag', callback, data)
    saveNameTagAuthorContact: (callback, data) ->
        @post('/save_nametag_authorcontact', callback, data)
    getPageNameTagsForConference: (callback, data) ->
        @post('/get_page_nametags_for_conference', callback, data)
    downloadAllNameTagsForConference: (callback, data) ->
        @downloadFile('/download_all_nametags_for_conference', callback, data)
    downloadAllConferenceRoles: (callback, data) ->
        @downloadFile('/download_all_conference_roles', callback, data)
    getTotalNumberNameTags: (callback, data) ->
        @post('/get_total_number_nametags_for_conf', callback, data)
    deleteUserNameTagFlaskFolder: (callback) ->
        @fetchPage('/delete_user_nametag_flask_folder', callback)

    # JobJunction work pages
    getObjectsListFromJobJunctionsForOwner: (callback, data) ->
        @post('/get_objects_list_from_jobjunctions_for_owner', callback, data)
    getAreaChairsReviewersJobs: (callback, data) ->
        @post('/get_reviewer_jobjunctions_for_each_areachair_in_list', callback, data)

    # specific note pages
    projectNote: (callback, data) ->
        @post('/project_note', callback, data)
    #startNewProjectNoteWithMetadata: (callback, data) ->
    #    @post('/start_new_project_note_with_metadata', callback, data)
    startProjectBlankNote: (callback, data) ->
        @post('/start_project_blank_note', callback, data)
    updateNoteProjectChoice: (callback, data) ->
        @post('/update_note_project_choice', callback, data)
    submitProjectNoteMetadata: (callback, data) ->
        @post('/submit_project_note_metadata', callback, data)
    submitProjectNoteVote: (callback, data) ->
        @post('/submit_project_note_vote', callback, data)
    deleteProjectNote: (callback, data) ->
        @post('/delete_project_note', callback, data)
    getTotalNumberNotesInProject: (callback, data) ->
        @post('/get_total_number_notes_in_project', callback, data)

    # Document, Slide, Poster, Material pages
    viewProjectNoteDocument: (noteid, callback) ->
        @readDocument('/view_project_note_document/'+noteid, callback)
    updateProjectNoteDocument: (callback, data) ->
        @post('/update_project_note_document', callback, data)
    submitProjectNoteDocument: (files, noteid, callback) ->
        @uploadFile('/submit_project_note_document/'+noteid, files, callback)
    getNoteidFromPaperSeriesAndProject: (callback, data) ->
        @post('/get_noteid_from_paperseries_and_project', callback, data)
    #  noteid enables project & paperseries lookups
    getSlideSetFromNoteInfo: (callback, data) ->
        @post('/get_slideset_from_note_info', callback, data)
    submitPaperSeriesSlideSet: (files, noteid, callback) ->
        @uploadFile('/submit_paperseries_slideset/'+noteid, files, callback)
    viewProjectSlideSet: (noteid, callback) ->
        @readDocument('/view_paper_slideset/'+noteid, callback)
    updateProjectPaperSeriesSlideSet: (callback, data) ->
        @post('/update_project_paperseries_slideset', callback, data)
    getPosterFromNoteInfo: (callback, data) ->
        @post('/get_poster_from_note_info', callback, data)
    submitPaperSeriesPoster: (files, noteid, callback) ->
        @uploadFile('/submit_paperseries_poster/'+noteid, files, callback)
    viewProjectPoster: (noteid, callback) ->
        @readDocument('/view_paper_poster/'+noteid, callback)
    updateProjectPaperSeriesPoster: (callback, data) ->
        @post('/update_project_paperseries_poster', callback, data)
    updateUserTitleInConference: (callback, data) ->
        @post('/update_user_title_in_conference', callback, data)
    getProjectAndPaperTitlesFromNoteId: (callback, data) ->
        @post('/get_project_and_paper_titles_from_noteId', callback, data)
    # materials uploaded to site
    submitUrlDocument:  (files, new_url_id, callback) ->
        @uploadFile('/submit_url_blawb/'+new_url_id, files, callback)
    viewUrlDocument: (url_id, callback) ->
        @readDocument('/view_url_document/'+url_id, callback)


    # author components
    submitNewAuthorContactForProjectNote: (callback, data) ->
        @post('/submit_new_authorcontact_for_project_note', callback, data)
    removeProjectNoteAuthorContact: (callback, data) ->
        @post('/remove_project_note_authorcontact', callback, data)
    getUserAuthorContactList: (callback, data) ->
        @post('/get_user_authorcontacts_list', callback, data)
    submitProjectNoteExistingAuthorContact: (callback, data) ->
        @post('/submit_project_note_existing_authorcontact', callback, data)
    submitNewUserOwnedAuthorContact: (callback, data) ->
        @post('/submit_new_user_owned_authorcontact', callback, data)
    hideUserOwnedAuthorContact: (callback, data) ->
        @post('/hide_user_owned_authorcontact', callback, data)
    getAuthorContactsByEmail: (callback, data) ->
        @post('/get_authorcontacts_by_email', callback, data)
    updateAuthorOrder: (callback, data) ->
        @post('/update_author_order', callback, data)
    getNumNoteAuthors: (callback, data) ->
        @post('/get_num_note_authors', callback, data)

    # tag components
    removeProjectNoteTag: (callback, data) ->
        @post('/remove_project_note_tag', callback, data)
    removeProjectTag: (callback, data) ->
        @post('/remove_project_tag', callback, data)
    updateTagsForNote: (callback, data) ->
        @post('/udpate_tags_for_note', callback, data)
    removeNoteTag: (callback, data) ->
        @post('/remove_note_tag', callback, data)

    # roles/jobjubctions
    addUserRoleToProject: (callback, data) ->
        @post('/add_user_role_to_project', callback, data)
    hideJobJunction: (callback, data) ->
        @post('/hide_jobjunction', callback, data)
    addUserJobForRole: (callback, data) ->
        @post('/add_user_job_for_role_in_project', callback, data)
    deleteUserJobForRole: (callback, data) ->
        @post('/delete_user_job_for_role_in_project', callback, data)
    getUserRolesInProject: (callback, data) ->
        @post('/get_user_roles_in_project', callback, data)

    # comment components
    submitProjectNoteComment: (callback, newcomment) ->
        @post('/submit_project_note_comment', callback, newcomment)
    submitProjectNoteReply: (callback, newreply) ->
        @post('/submit_project_note_reply', callback, newreply)
    editNoteComment: (callback, data) ->
        @post('/edit_note_comment', callback, data)
    hideNoteComment: (callback, data) ->
        @post('/hide_note_comment', callback, data)

    # user profile pages
    getProfileInfo: (callback, userid) ->
        @fetchPage('/get_profile_info/'+userid, callback)

    # notifications pages
    getUserNotificationsPage: (callback, data) ->
        @post('/get_user_notifications_page', callback, data)
    markUserNotificationsSeen: (callback, data) ->
        @post('/mark_user_notifications_seen', callback, data)
    getNumberUnseenNotifications: (callback, data) ->
        @post('/get_number_unseen_notifications', callback, data)
    getTotalNumberNotifications: (callback, data) ->
        @post('/get_total_number_notifications', callback, data)

    # verifications
    emailVerificationCode: (callback, data) ->
        @post('/email_verification_code', callback, data)
    EmailVerificationForAccountMerging: (callback, data) ->
        @post('/email_verification_for_account_merging', callback, data)
    emailLostPwdVerificationCode: (callback, data) ->
        @post('/email_lost_pwd_verification_code', callback, data)
    emailAuthorContactVerificationCode: (callback, data) ->
        @post('/email_authorcontact_verification_code', callback, data)
    confirmEmailedCodeForUserAccount: (callback, data) ->
        @post('/confirm_emailed_code_for_user_account', callback, data)
    confirmEmailedCodeAndResetLostPassword: (callback, data) ->
        @post('/confirm_emailed_code_and_reset_lost_password', callback, data)
    confirmEmailedCodeAndMergeAccounts: (callback, data) ->
        @post('/confirm_emailed_code_and_merge_accounts', callback, data)

    # payments
    sendStripePaymentInfo: (callback, data) ->
        @pay('/send_stripe_payment_info', callback, data)
    getFeesForConferenceRegistration: (callback, data) ->
        @post('/get_fees_for_conference_registration', callback, data)
    saveConferenceFeeType: (callback, data) ->
        @post('/save_conference_fee_type', callback, data)
    hideFeeFromConference: (callback, data) ->
        @post('/hide_fee_from_conference', callback, data)
    getConferenceRegistrationRevenue: (callback, data) ->
        @post('/get_conference_registration_revenue', callback, data)

    # tags search
    getPaperSeriesWithTags: (callback, data) ->
        @post('/get_paperseries_with_tags', callback, data)
    getTotalNumberPaperSeriesWithTags: (callback, data) ->
        @post('/get_total_number_paperseries_with_tags', callback, data)

    # url & video links
    getUrlLinks: (callback, data) ->
        @post('/get_url_links', callback, data)
    saveUrlLink: (callback, data) ->
        @post('/save_url_link', callback, data)
    hideLink: (callback, data) ->
        @post('/hide_link', callback, data)
    getVideos: (callback, data) ->
        @post('/get_videos', callback, data)
    createPlaceholderVideo: (callback, data) ->
        @post('/create_placeholder_video', callback, data)
    createPlaceholderVtt: (callback, data) ->
        @post('/create_placeholder_vtt_for_video', callback, data)
    # saveVideoLink: (callback, data) ->
    #     @post('/save_video_link', callback, data)
    updateVideoLink: (callback, data) ->
        @post('/update_video_link', callback, data)
    submitVideoVtt: (files, new_video_id, callback) ->
        @uploadFile('/submit_video_vtt/'+new_video_id, files, callback)
    viewVttDocument: (vtt_id, callback) ->
        @readDocument('/view_vtt_document/'+vtt_id, callback)
    hideVideo: (callback, data) ->
        @post('/hide_video', callback, data)
    countVideos: (callback, data) ->
        @post('/count_videos', callback, data)

    #Spottable
    saveTime: (callback, data) ->
        @post('/save_time', callback, data)
    savePlace: (callback, data) ->
        @post('/save_place', callback, data)
    getTimePlaceForSpottable: (callback, data) ->
        @post('/get_time_place_for_spottable', callback, data)
    getScheduledConferenceNotes: (callback, data) ->
        @post('/get_scheduled_conference_notes', callback, data)
    getAllSpotsForProject: (callback, data) ->
        @post('/get_all_spots_for_project', callback, data)

    # replications
    saveReplication: (callback, data) ->
        @post('/save_replication', callback, data)
    hideReplication: (callback, data) ->
      @post('/hide_replication', callback, data)
    getReplicationsOfNote: (callback, data) ->
      @post('/get_replications_of_note', callback, data)

    # surveys
    saveSurvey: (callback, data) ->
        @post('/save_survey', callback, data)
    getSurveyableId: (callback, data) ->
        @post('/get_surveyable_id', callback, data)
    updateSurvey: (callback, data) ->
        @post('/update_survey', callback, data)
    getSurveyList: (callback, data) ->
        @post('/get_survey_list', callback, data)
    getSurvey: (callback, data) ->
        @post('/get_survey', callback, data)
    getAsksAndChoices: (callback, data) ->
        @post('/get_asks_and_choices', callback, data)
    saveUserSurveyAnswers: (callback, data) ->
        @post('/save_user_survey_answers', callback, data)
    getAggregatedSurveyAnswers: (callback, data) ->
        @post('/get_aggreagated_survey_answers', callback, data)
    getUsersWhoAnsweredThisChoice: (callback, data) ->
        @post('/get_users_who_answered_this_choice', callback, data)
    deleteSurvey: (callback, data) ->
        @post('/delete_survey', callback, data)

    # next in conference series
    createSequelConference: (callback, data) ->
        @post('/create_sequel_conference', callback, data)

    # command central
    buildSiteMap: (callback, data) ->
        @post('/build_sitemap', callback, data)
    checkConfVerification: (callback, data) ->
        @post('/check_conference_verification', callback, data)
    toggleVerifyConference: (callback, data) ->
        @post('/toggle_conference_verification', callback, data)
    deleteOrphanS3Files: (callback, data) ->
        @post('/delete_orphan_s3_files', callback, data)
    indexTitle: (callback, data) ->
        @post('/index_title', callback, data)



module.exports = common
